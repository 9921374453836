import React from 'react';
import styled from 'styled-components';

type Props = {
  text: string;
}

const VarifyingCard: React.FC<Props> = (props) => {
  const { text } = props;
  return (
    <StyledVarifyingCard>
      <StyledText>{text}</StyledText>
    </StyledVarifyingCard>
  );
};

export default VarifyingCard;

const StyledVarifyingCard = styled.div`
  max-width: 460px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 50px 40px;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
`;

const StyledText = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;
