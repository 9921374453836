import React from 'react';
import styled, { keyframes } from 'styled-components';

const spWaveAf = keyframes`
from { 
  transform: scale(0.5,0.5);
  opacity: 0;
}
to { 
  transform: scale(1,1);
  opacity: 1;
}
`;

const spWaveBe = keyframes`
from { 
  transform: scale(1,1);
  opacity: 1;
}
to { 
  transform: scale(1.5,1.5);
  opacity: 0;
}
`;

const Loader = props => {
  const {className} = props;
  return (
    <StyledWrapper className={className}>
      <StyledLoader {...props} />
    </StyledWrapper>
  );
};

export default Loader;

const StyledWrapper = styled.div`
  margin: 30px 0;
`;

const StyledLoader = styled.div`
  & {
    width: ${props => (props.loaderSize ? props.loaderSize : '60px')};
    height: ${props => (props.loaderSize ? props.loaderSize : '60px')};
    clear: both;
    margin: 20px auto;
    border-radius: 50%;
    position: relative;
    opacity: 1;
  }

  &:before {
    content: '';
    border: 1px solid #0fafa9;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    transform: scale(1, 1);
    opacity: 1;
    -webkit-animation: ${spWaveBe} 0.6s infinite linear;
    animation: ${spWaveBe} 0.6s infinite linear;
  }
  &:after {
    content: '';
    border: 1px solid #0fafa9;
    border-radius: 50%;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0px;
    transform: scale(0, 0);
    opacity: 0;
    -webkit-animation: ${spWaveAf} 0.6s infinite linear;
    animation: ${spWaveAf} 0.6s infinite linear;
  }
`;
