import React, { useState, useEffect, ReactNode } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { throttle } from 'lodash';

import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const useWindowPosition: () => number = () => {
  const [position, setPosition] = useState<number>(window.scrollY);
  const changePosition = throttle(() => setPosition(window.scrollY), 500);
  useEffect(() => {
    window.addEventListener('scroll', changePosition);
    return () => window.removeEventListener('scroll', changePosition);
  });
  return position;
};

type HeaderProps = {
  children?: ReactNode;
  hamburgerMenu?: ReactNode;
  isOpenHamburger?: boolean;
  isScrolled?: boolean;
  isWidthDynamic: boolean;
};

type StyledComponentProps = {
  isScrolled: boolean;
  isTop?: boolean;
  isWidthDynamic: boolean;
};

const HeaderWrapper: React.FC<HeaderProps> = (props) => {
  const { children, hamburgerMenu, isOpenHamburger, isScrolled, isWidthDynamic } = props;
  const windowPosition: number = useWindowPosition();
  const location = useLocation();
  const isTop = ['/', '/enterprise'].includes(location.pathname);

  return (
    <>
      <StyledHeaderWrapper
        isScrolled={!!windowPosition && !isOpenHamburger && isTop}
        isTop={isTop}
        isWidthDynamic={isWidthDynamic}
      >
        <StyledHeaderContainer isScrolled={!!windowPosition && !isOpenHamburger && isTop}>
          {children}
        </StyledHeaderContainer>
      </StyledHeaderWrapper>
      {hamburgerMenu}
    </>
  );
};

const StyledHeaderWrapper = styled.header<StyledComponentProps>(
  (props) => `
  position: ${props.isTop ? 'fixed' : 'sticky'};
  top: ${props.isScrolled ? '30px' : '0'};
  width: 100%;
  min-width: ${props.isWidthDynamic ? 'none' : '1280px'};
  z-index: 500;
  transition: top 0.5s;
  background-color: ${props.isTop ? 'transparent' : COLOR_DEFINITIONS.BG.WHITE};
  ${mediaQuery.lessThan('minTablet')`
    width: 100vw;
    padding: 0;
    min-width: ${props.isWidthDynamic ? 'none' : '1280px'};
  `.join('')};
  ${mediaQuery.lessThan('sp')`
  top: ${props.isScrolled ? '30px' : '0'};
    `.join('')}
`
);

const StyledHeaderContainer = styled.div<{ isScrolled: boolean }>(
  (props) =>
    `
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 80px);
    min-height: 96px;
    margin: 0 auto;
    border-radius: 20px;
    transition: background-color 0.5s, box-shadow 0.5s;
    ${mediaQuery.greaterThan('basePCWidth')`
    padding: 0 40px;
    `.join('')}
    ${mediaQuery.lessThan('basePCWidth')`
    padding: 0 20px;
    `.join('')}
    ${mediaQuery.lessThan('sp')`
    min-height: 60px;
    `.join('')}
    ${
      props.isScrolled
        ? `
       box-shadow: rgba(0,0,0,0.1) 0 0 8px;
       background-color: ${COLOR_DEFINITIONS.BG.WHITE};
      `
        : ''
    }
  }
`
);

export default HeaderWrapper;
