import React, { useReducer, useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Validation from '@meettry/company/utils/validation';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import {
  CompanyName,
  PostalCode,
  StreetAddress,
  PhoneNumber,
  Url,
  CorporateLogo
} from '@meettry/company/component/atoms/input';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CORPORATE_INFO, UPDATE_ORG } from '@meettry/ui-components/queries/corporateInfo';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import EnterpriseSettingBase from '@meettry/ui-components/components/organisms/EnterpriseSettingBase';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { useLoginUser as useLoginUserHook } from '@meettry/ui-components/hooks/useLoginUser';

// MEMO(minami) 開発環境によってpathを切り分け
const getUploadProfileImagePath = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  return isDevelopment
    ? `http://${process.env.REACT_APP_APIHOST}/upload/organization_logo_image`
    : `https://${process.env.REACT_APP_APIHOST}/upload/organization_logo_image`;
};

type CorporateInfoDataType = {
  id: string;
  name: string;
  phone: string;
  postCode: string;
  address: string;
  url: string;
  logoImageThumbnail: {
    url: string;
  };
};

type CorporateInfoType = {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  data: CorporateInfoDataType | null;
};

export type InputCompanyProps = {
  companyName: string;
  corporateLogo: string;
  corporateLogoFile: any;
  postalCode: Array<string>;
  streetAddress: string;
  phoneNumber: Array<string>;
  url: string;
};

const initialState: InputCompanyProps = {
  companyName: '',
  corporateLogo: '',
  corporateLogoFile: null,
  postalCode: ['', ''],
  streetAddress: '',
  phoneNumber: ['', '', ''],
  url: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;
const CHANGE_TEXT_NUMBER = 'CHANGE_TEXT_NUMBER' as const;
const SET_IMAGE_FILE = 'SET_IMAGE_FILE' as const;

type InputAction = {
  type: typeof CHANGE_TEXT | typeof CHANGE_TEXT_NUMBER | typeof SET_IMAGE_FILE;
  text: string;
  item: keyof InputCompanyProps;
  stateArray: 'postalCode' | 'phoneNumber' | null;
  num: number;
  file?: any;
};

const changeInput: React.Reducer<InputCompanyProps, InputAction> = (
  state: InputCompanyProps,
  action: InputAction
) => {
  switch (action.type) {
    case CHANGE_TEXT:
      return { ...state, [action.item]: action.text };
    case CHANGE_TEXT_NUMBER:
      if (!action.stateArray) return state;
      const itemCopy = state[action.stateArray];
      itemCopy[action.num] = action.text;
      return { ...state, [action.item]: itemCopy };
    case SET_IMAGE_FILE:
      if (!!action.file) {
        return { ...state, corporateLogoFile: action.file };
      }
      return state;
    default:
      return state;
  }
};

const CorporateInfoTable: React.FC<CorporateInfoType> = ({ setState, data }) => {
  const billCorporateInfo = [
    { heading: '企業名', data: data ? data.name : '', type: 'name' },
    { heading: '企業ロゴ', data: data?.logoImageThumbnail?.url ?? '', type: 'image' },
    { heading: '郵便番号', data: data ? data.postCode : '', type: 'postCode' },
    { heading: '住所', data: data ? data.address : '', type: 'address' },
    { heading: '電話番号', data: data ? data.phone : '', type: 'phone' },
    { heading: 'URL', data: data ? data.url : '', type: 'url' }
  ];

  return (
    <>
      <StyledTableWrapper>
        <StyledTable>
          <tbody>
            {billCorporateInfo.map((item, index) => (
              <StyledTableRow key={index}>
                <StyledTableHeader>{item.heading}</StyledTableHeader>
                <StyledTableData>
                  {item.data && item.type === 'image' ? (
                    <StyledImage src={item.data} alt="企業ロゴの画像" />
                  ) : (
                    item.data
                  )}
                </StyledTableData>
              </StyledTableRow>
            ))}
          </tbody>
        </StyledTable>
      </StyledTableWrapper>
      <StyledButtonContainer justifyContent="flex-end">
        <PrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} width="120" onClick={() => setState(true)}>
          変更する
        </PrimaryButton>
      </StyledButtonContainer>
    </>
  );
};

const CorporateInfoInput: React.FC<CorporateInfoType> = ({ setState, data }) => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [companyNameError, setCompanyNameError] = useState('');
  const [streetAddressError, setStreetAddressError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [updateOrg, { called, loading }] = useMutation(UPDATE_ORG);
  const { idToken } = useLoginUser();
  const url = getUploadProfileImagePath();

  const sendLogo = () => {
    if (!state.corporateLogoFile) return;
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url);
    xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
    xhr.send(state.corporateLogoFile);
  };

  const dispatchArray = (array: Array<string>) => {
    if (array.length === 1) return;
    array.forEach((li, i) => {
      dispatch({
        type: 'CHANGE_TEXT_NUMBER',
        text: li,
        item: array.length === 2 ? 'postalCode' : 'phoneNumber',
        stateArray: array.length === 2 ? 'postalCode' : 'phoneNumber',
        num: i
      });
    });
  };

  useEffect(() => {
    if (!data) return;
    const phone = data.phone.split('-');
    const postCode = data.postCode.split('-');
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.name,
      item: 'companyName',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: data?.logoImageThumbnail?.url ?? '',
      item: 'corporateLogo',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.address,
      item: 'streetAddress',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.url,
      item: 'url',
      stateArray: null,
      num: 0
    });
    dispatchArray(postCode);
    dispatchArray(phone);
  }, []);

  useEffect(() => {
    if (
      data &&
      companyNameError === 'none' &&
      postalCodeError === 'none' &&
      streetAddressError === 'none' &&
      phoneNumberError === 'none' &&
      urlError === 'none'
    ) {
      sendLogo();
      updateOrg({
        variables: {
          input: {
            postCode:
              state.postalCode[0].length === 0 && state.postalCode[1].length === 0
                ? ''
                : state.postalCode.join('-'),
            phone: state.phoneNumber.join('-'),
            address: state.streetAddress,
            name: state.companyName,
            url: state.url
          }
        }
      });
    }
  }, [companyNameError, streetAddressError, phoneNumberError, postalCodeError, urlError]);

  useEffect(() => {
    if (!called || loading) return;
    setState(false);
  }, [called, loading]);

  return (
    <>
      {called && loading ? (
        <Loader />
      ) : (
        <>
          <StyledInputs>
            <StyledFormItem>
              <CompanyName
                companyNameText="企業名"
                require={true}
                state={state.companyName}
                stateKey="companyName"
                dispatch={dispatch}
                error={companyNameError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <CorporateLogo
                require={false}
                state={state.corporateLogo}
                stateKey="corporateLogo"
                dispatch={dispatch}
              />
            </StyledFormItem>
            <StyledFormItem>
              <PostalCode
                state={state.postalCode}
                stateKey="postalCode"
                dispatch={dispatch}
                error={postalCodeError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <StreetAddress
                require={true}
                state={state.streetAddress}
                stateKey="streetAddress"
                dispatch={dispatch}
                error={streetAddressError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <PhoneNumber
                require={true}
                state={state.phoneNumber}
                stateKey="phoneNumber"
                dispatch={dispatch}
                error={phoneNumberError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <Url state={state.url} stateKey="url" dispatch={dispatch} error={urlError} />
            </StyledFormItem>
          </StyledInputs>
          <StyledButtonContainer>
            <MonochromeButton
              size={STYLE_TYPE.SIZE.MEDIUM}
              width="120"
              onClick={() => setState(false)}
            >
              キャンセル
            </MonochromeButton>
            <PrimaryButton
              size={STYLE_TYPE.SIZE.MEDIUM}
              width="120"
              onClick={() =>
                Validation.company(
                  state,
                  setCompanyNameError,
                  setPostalCodeError,
                  setStreetAddressError,
                  setPhoneNumberError,
                  setUrlError
                )
              }
            >
              決定
            </PrimaryButton>
          </StyledButtonContainer>
        </>
      )}
    </>
  );
};

const EnterpriseSettingCompany: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { setStoreProfileImageUrl } = useLoginUserHook();
  const [getCorporateInfo, { data, loading }] = useLazyQuery(GET_CORPORATE_INFO, {
    onCompleted: (data) =>
      data?.loginSession?.user?.organization?.logoImage?.url
        ? setStoreProfileImageUrl(data.loginSession.user.organization.logoImage.url)
        : null
  });

  useEffect(() => {
    if (isEdit) return;
    getCorporateInfo();
  }, [isEdit]);

  const info = useMemo(() => data?.loginSession?.user?.organization ?? null, [data]);

  return (
    <EnterpriseSettingBase>
      <StyledSettingsContainer>
        <StyledSettingsContentsTitle>企業情報</StyledSettingsContentsTitle>
        {loading ? (
          <Loader></Loader>
        ) : isEdit ? (
          <CorporateInfoInput setState={setIsEdit} data={info} />
        ) : (
          <CorporateInfoTable setState={setIsEdit} data={info} />
        )}
      </StyledSettingsContainer>
    </EnterpriseSettingBase>
  );
};

export default EnterpriseSettingCompany;

/* --------------------------------
  EnterpriseSettingCompanyのスタイル
---------------------------------*/
export const StyledSettingsContainer = styled.div`
  width: 100%;
  max-width: 801px;
  margin-top: 50px;
`;

export const StyledSettingsContentsTitle = styled.h3`
  margin: 0;
  padding: 0;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
`;

/* --------------------------------
  CorporateInfoTableのスタイル
---------------------------------*/
const StyledTableWrapper = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledTable = styled.table`
  width: 100%;
`;

const StyledTableRow = styled.tr`
  height: 70px;
  line-height: 70px;
`;

const StyledTableData = styled.td`
  padding-left: 30px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledTableHeader = styled(StyledTableData)`
  width: 300px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  box-sizing: border-box;
`;

export const StyledButtonContainer = styled.div<{ justifyContent?: string }>`
  display: flex;
  margin-top: 20px;

  justify-content: ${({ justifyContent }) => (!!justifyContent ? justifyContent : 'space-between')};
`;

/* --------------------------------
  CorporateInfoInputのスタイル
---------------------------------*/

const StyledInputs = styled.div`
  padding-top: 35px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

export const StyledFormItem = styled.div<{ marginTop?: string }>`
  margin-top: ${({ marginTop }) => (marginTop ? `${marginTop}` : '30px')};
`;

const StyledImage = styled.img`
  width: 167px;
  height: 167px;
  margin-top: 30px;
  border-radius: 50%;
  object-fit: cover;
`;
