import React, { useState } from 'react';
import styled from 'styled-components';
import { isValidateEmail } from '@meettry/engineer/utils';
import auth, { authError } from '@meettry/ui-components/utils/auth';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import { VALIDATION } from '@meettry/engineer/utils/Constant';

const ForgotPassEMail = () => {
  const [stateEmail, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState(null);
  const [validateMessage, setValidateMessage] = useState(null);
  const { showErrorDialog, showNotificationDialog, closeAllDialog } = useDialog();

  const onChangeEmail = (e) => {
    setEmail(e.target.value || '');
  };

  const onSubmitSendMail = (e) => {
    e.preventDefault();

    setErrorMessage(null);
    setValidateMessage(null);
    if (isValidateForm(stateEmail)) {
      return;
    }

    return auth
      .sendPasswordResetEmail(stateEmail)
      .then(() => {
        showNotificationDialog(['パスワード再設定用のリンクをメールに送信しました。'], () => {
          closeAllDialog();
        });
      })
      .catch((error) => {
        const errorMsg = authError(error);
        showErrorDialog([errorMsg], () => {
          closeAllDialog();
        });
      });
  };

  const isValidateForm = (email) => {
    let isValidate = false;
    if (email === '') {
      setValidateMessage(VALIDATION.EMAIL.EMPTY);
      isValidate = true;
    }
    if (email !== '' && !isValidateEmail(stateEmail)) {
      setValidateMessage(VALIDATION.EMAIL.INCORRECT);
      isValidate = true;
    }
    return isValidate;
  };

  const isDisplayErrorMessage = () => {
    return errorMessage !== undefined && errorMessage !== null;
  };

  const isDisplayValidateMessage = () => {
    return validateMessage !== undefined && validateMessage !== null;
  };

  return (
    <>
      <StyledPageTitle>パスワードをお忘れの方へ</StyledPageTitle>
      <StyledFormBox
        onSubmit={(e) => onSubmitSendMail(e, { vertical: 'top', horizontal: 'center' })}
      >
        <StyledText>パスワードをリセットするには、メール アドレスを入力してください。</StyledText>
        <StyledText>
          メールが届かない場合、迷惑メールのフォルダを確認するか、noreply@meettry.com
          のブロックを解除する必要がある場合があります。
        </StyledText>
        <StyledFormItem
          data-error={isDisplayErrorMessage() || isDisplayValidateMessage()}
          style={{ marginTop: '20px' }}
        >
          <StyledLabel htmlFor="email">メールアドレス</StyledLabel>
          <StyledInput
            placeholder="メールアドレス"
            onChange={onChangeEmail}
            id="email"
          ></StyledInput>
          {isDisplayErrorMessage() && <StyledErrorText>{errorMessage}</StyledErrorText>}
          {isDisplayValidateMessage() && <StyledErrorText>{validateMessage}</StyledErrorText>}
        </StyledFormItem>
        <StyledFormButtons>
          <PrimaryButton size="large" type="submit">
            送信する
          </PrimaryButton>
        </StyledFormButtons>
      </StyledFormBox>
    </>
  );
};

export default ForgotPassEMail;
// styled-components
const StyledFormBox = styled.form`
  position: relative;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 40px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;
const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #0fafa9;
  text-align: center;

  & + * {
    margin-top: 40px;
  }
`;
const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;
const StyledInput = styled.input`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;

  ${StyledLabel} + & {
    margin-top: 10px;
  }
`;
const StyledErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;
const StyledFormItem = styled.div`
  & + & {
    margin-top: 15px;
  }

  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }

  &[data-error='true'] ${StyledErrorText} {
    display: block;
  }
`;

const StyledFormButtons = styled.div`
  margin-top: 20px;
`;
const StyledText = styled.p`
  font-size: 14px;
  line-height: 1.75;
  color: #333333;
`;
