import React from 'react';
import { useLocation } from 'react-router-dom';
import NavLinkBase from '@meettry/ui-components/components/atoms/NavLinkBase';
import ImageBase from '@meettry/ui-components/components/atoms/Images/ImageBase';
import imgLogo from '@meettry/ui-components/images/mainLogo/main_logo.png';
import imgEnterpriseLogo from '@meettry/ui-components/images/mainLogo/enterprise_main_logo.png';
import imgWhiteLogo from '@meettry/ui-components/images/mainLogo/footer_logo.png';
import { HeaderStateType } from '@meettry/ui-components/types/location';

const IMAGE_SIZE = {
  small: 115,
  normal: 160,
  footer: 190
};

const ImageLogoLink = ({
  size,
  toPath
}: {
  size: 'small' | 'normal' | 'footer';
  toPath?: string | null;
}) => {
  const imageWidth = IMAGE_SIZE[size] || IMAGE_SIZE.normal;
  const location = useLocation<HeaderStateType>();
  return (
    <NavLinkBase toPath={!!toPath ? toPath : '/'}>
      <ImageBase
        source={
          size === 'footer'
            ? imgWhiteLogo
            : location.pathname.includes('/enterprise') ||
              (location.state && location.state.fromEnterprise)
            ? imgEnterpriseLogo
            : imgLogo
        }
        alt="techtree-career"
        width={`${imageWidth}px`}
        isCenter={size === 'footer'}
      />
    </NavLinkBase>
  );
};

export default ImageLogoLink;
