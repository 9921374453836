import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton, LineButton } from '@meettry/ui-components/components/atoms/Button';
import { ConfirmationType } from '@meettry/ui-components/types/dialog';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

export const DeleteConfirmation: React.FC<ConfirmationType> = (props) => {
  const { texts, onCancel, onAccept } = props;

  return (
    <StyledDeleteConfirmation>
      <StyledDeleteConfirmationContainer>
        {texts.map((text, i) => (
          <StyledConfirmationText key={i}>{text}</StyledConfirmationText>
        ))}
        <StyledConfirmationButtons>
          <LineButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onCancel}>
            キャンセル
          </LineButton>
          <PrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onAccept}>
            削除
          </PrimaryButton>
        </StyledConfirmationButtons>
      </StyledDeleteConfirmationContainer>
    </StyledDeleteConfirmation>
  );
};

export default DeleteConfirmation;

const StyledDeleteConfirmation = styled(DialogBase)`
  position: relative;
`;

const StyledDeleteConfirmationContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledConfirmationText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledConfirmationButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 140px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;
