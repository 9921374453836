import React from 'react';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import { Notice } from '@meettry/company/component/organisms/notice';
import { Message } from '@meettry/company/component/organisms/message';
import { TitledContainer } from '@meettry/company/component/organisms/titledContainer';
import { Recommends } from '@meettry/company/component/organisms/recommends';
import { LatestChallenge } from '@meettry/company/component/organisms/latestChallenge';
import { FeaturedChallenge } from '@meettry/company/component/organisms/dashboardChallengeList';
import styled from 'styled-components';

const EnterpriseDashboard: React.FC<PageCommonProps> = () => {
  return (
    <StyledContentsWrapper>
      <Notice type="important" length={3} />
      <Notice margin="sections" length={1} />
      <TitledContainer hasMore={true} title="未読メッセージ">
        <Message />
      </TitledContainer>
      <TitledContainer title="おすすめエンジニア">
        <Recommends />
      </TitledContainer>
      <TitledContainer accordion={true} hasMore={true} title="注目のチャレンジ">
        <FeaturedChallenge />
      </TitledContainer>
      <TitledContainer accordion={true} hasMore={true} title="新着のチャレンジ">
        <LatestChallenge />
      </TitledContainer>
    </StyledContentsWrapper>
  );
};

export default EnterpriseDashboard;

export const StyledContentsWrapper = styled.div`
  padding: 50px;
`;
