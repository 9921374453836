import React, { useState, useRef, FormEvent } from 'react';
import styled, { css } from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const SearchBox = (props: {
  onSubmit: (event: FormEvent<HTMLFormElement>) => void;
  onChange: (value: string) => void;
  searchWord: string;
  placeholder: string;
  className?: string;
  getInputProps?: () => void;
}) => {
  const {
    onSubmit,
    onChange,
    searchWord,
    placeholder,
    className,
    getInputProps = () => {}
  } = props;
  const inputElement = useRef(null);
  const [isFocus, updateIsFocus] = useState(false);

  return (
    <StyledSearchForm className={className} isActive={isFocus} onSubmit={onSubmit}>
      <StyledSearchFormInput
        {...getInputProps()}
        type="search"
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
        onFocus={() => updateIsFocus(true)}
        onBlur={() => updateIsFocus(false)}
        value={searchWord}
        ref={inputElement}
      />
      <StyledSearchFormButton>
        <Icon iconColor="primary" iconName="search" width="16px" />
      </StyledSearchFormButton>
    </StyledSearchForm>
  );
};

export default SearchBox;

const StyledSearchForm = styled.form<{ isActive: boolean }>`
  && {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 200px;
    min-height: 30px;
    background: ${COLOR_DEFINITIONS.BG.SUB};
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-radius: 30px;
    transition: border 0.1s;
    ${({ isActive }) =>
      isActive &&
      css`
        border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
      `}
  }
`;

const StyledSearchFormInput = styled.input`
  && {
    width: 100%;
    appearance: none;
    outline: none;
    border: none;
    padding: 5px 10px;
    padding-right: 0;
    background: none;
    -webkit-search-cancel-button {
      -webkit-appearance: searchfield-cancel-button;
    }
    font-size: 14px;
  }
`;

const StyledSearchFormButton = styled.button`
  && {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    appearance: none;
    outline: none;
    border: none;
    background: none;
    padding: 0;
    width: 30px;
  }
`;
