import React, { useRef, useEffect, useMemo, ReactNode } from 'react';

import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { isBoolean } from 'lodash';

type ModalProps = {
  show: boolean;
  displayModal: Function;
  title?: string;
  cls: string;
  children?: ReactNode;
  modalBackClick?: Function | null;
  isBorder?: boolean;
};

export const Modal: React.FC<ModalProps> = (props) => {
  const modalEl = useRef<HTMLDivElement>(null);
  const MODAL_MARGIN_SIZE = 100;
  const windowFitSize = window.innerHeight - MODAL_MARGIN_SIZE;

  const isScroll = useMemo(() => {
    if (!props.show) return false;
    const modalHeight = !!modalEl.current ? modalEl.current.offsetHeight : 0;
    const windowFitSize = window.innerHeight - MODAL_MARGIN_SIZE;
    return modalHeight > windowFitSize;
  }, [props.show]);

  const style: {
    top: string;
    transform?: string;
    height?: string;
    overflowY?: 'scroll';
  } = isScroll
    ? {
        top: `${MODAL_MARGIN_SIZE}px`,
        transform: 'translate(-50%,0%)',
        height: `${windowFitSize}px`,
        overflowY: 'scroll'
      }
    : { top: '50%' };

  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body');

    if (props.show && !!bodyEl[0]) bodyEl[0].style.overflow = 'hidden';
    else bodyEl[0].style.overflow = 'auto';
  }, [props.show]);

  return (
    <div>
      <StyledModalContent isShow={props.show} className={`${props.cls}`}>
        <StyledModalDialog cls={props.cls} ref={modalEl} style={props.show ? style : {}}>
          {!!props.title ? (
            <StyledModalTitle isBorder={props.isBorder}>{props.title}</StyledModalTitle>
          ) : null}
          {props.children}
        </StyledModalDialog>
        <StyledModalBackdrop
          onClick={() => {
            props.displayModal(false);
            !!props.modalBackClick && props.modalBackClick();
          }}
        />
      </StyledModalContent>
    </div>
  );
};

const StyledModalContent = styled.div<{ isShow: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  transition: opacity 0.3s;
  opacity: 0;
  visibility: hidden;
  ${({ isShow }) =>
    isShow
      ? `opacity: 1;
visibility: visible;`
      : null}
`;

const StyledModalDialog = styled.div<{ cls?: string }>`
  position: absolute;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  width: 100%;
  border-radius: 5px;
  box-sizing: border-box;
  background: ${COLOR_DEFINITIONS.BG.WHITE};
  box-shadow: 0px 5px 20px ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  transition: top 0.3s;
  ${({ cls }) =>
    cls === 'create-modal'
      ? `max-width: 820px;
  width: 100%;
  padding: 40px;
  box-sizing: border-box;`
      : cls === 'comment-modal'
      ? `max-width: 800px;
      width: 100%;
      padding: 40px;`
      : cls === 'message-modal'
      ? `max-width: 600px;
      width: 100%;
      padding: 50px 100px;`
      : null}
`;

const StyledModalTitle = styled.span<{ isBorder?: boolean }>`
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 20px;
  font-weight: bold;
  ${({ isBorder }) =>
    isBorder
      ? `display: block;
      padding-bottom: 20px;
      border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};`
      : null}
`;

const StyledModalBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
`;
