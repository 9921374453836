import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  PrimaryButton,
  LineButton,
  GrayButton
} from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import {
  ResumeDetail,
  ResumeProject
} from '@meettry/ui-components/components/organisms/UserPage/Resume/ResumeForm';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import { RESUME_FORM_INPUT } from '@meettry/ui-components/utils/Constant';
import { TagType, ResumeType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const maxPage = 2;
const minPage = 1;
const isMaxPage = (page: number) => maxPage <= page;
const isMinPage = (page: number) => minPage >= page;

const initFormState = {
  title: '',
  companyName: '',
  workStartDate: null,
  workEndDate: null,
  workDetail: '',
  inputTag: '',
  tags: [],
  isVisible: false
};

type ResumeEditProps = {
  onClose: () => void;
  onSubmit: (data: any) => void;
  resume: ResumeType;
  loading: boolean;
};

type FormModelType = {
  title: string;
  companyName: string;
  workStartDate: Date | null;
  workEndDate: Date | null;
  workDetail: string;
  inputTag: string;
  tags: Array<TagType>;
  isVisible: boolean;
  id?: string;
  isPinned?: boolean;
};

export const ResumeEdit = (props: ResumeEditProps) => {
  const { onClose, onSubmit, resume = {}, loading } = props;
  const { showCancelConfirmationDialog, closeDialog } = useDialog();
  const [dirty, setDirty] = useState(false);
  const [page, setPage] = useState(1);
  const [formModel, setFormModel] = useState<FormModelType>({
    ...initFormState,
    ...resume
  });
  //MEMO(aida) 必須入力項目のチェック
  const [formFillError, setFormFillError] = useState({
    title: false,
    companyName: false,
    workStartDate: false
  });
  //MEMO(aida) 入力文字数をチェックする
  const [formInputError, setFormInputError] = useState({
    title: false,
    workDetail: false
  });
  const [dateRangeError, setDateRangeError] = useState(false);

  useEffect(() => {
    const { workStartDate, workEndDate } = formModel;
    if (!workStartDate || !workEndDate) return;
    const startDate = new Date(workStartDate).getTime();
    const endDate = new Date(workEndDate).getTime();
    const endBeforeStart = endDate < startDate;
    setDateRangeError(endBeforeStart);
  }, [formModel.workStartDate, formModel.workEndDate]);

  const onSubmitForm = () => {
    const {
      companyName,
      id,
      isPinned,
      isVisible,
      tags,
      title,
      workEndDate,
      workStartDate,
      workDetail
    } = formModel;
    if (!title || !companyName || !workStartDate) {
      setFormFillError({
        ...formFillError,
        title: !title,
        companyName: !companyName,
        workStartDate: !workStartDate
      });
      return;
    }
    const newResume = {
      companyName,
      id,
      isPinned,
      isVisible,
      tags,
      title,
      workEndDate,
      workStartDate,
      workDetail
    };
    onSubmit(newResume);
  };

  const onBack = () => {
    !isMinPage(page) && setPage(page - 1);
  };

  const onGo = () => {
    const { companyName, title, workStartDate } = formModel;
    if (!title || !companyName || !workStartDate) {
      setFormFillError({
        ...formFillError,
        title: !title,
        companyName: !companyName,
        workStartDate: !workStartDate
      });
      return;
    }
    //MEMO(aida) 入力期間に誤りがある場合進ませない
    if (dateRangeError) return;
    //MEMO(aida) 入力文字数にエラーがある状態では次に進ませない
    if (Object.values(formInputError).find((val) => val === true)) return;
    !isMaxPage(page) && setPage(page + 1);
  };

  const onChange = (target: 'title' | 'companyName' | 'workDetail') => (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dirty || setDirty(true);
    //MEMO(aida) 必須項目のチェック
    if (target in formFillError) {
      setFormFillError({ ...formFillError, [target]: !e.target.value });
    }
    //MEMO(aida) 入力文字数のチェック
    if (target === 'title') {
      if (!(e.target.value.length > RESUME_FORM_INPUT.LENGTH.TITLE)) {
        setFormInputError({ ...formInputError, [target]: false });
        setFormModel({ ...formModel, [target]: e.target.value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    if (target === 'workDetail') {
      if (!(e.target.value.length > RESUME_FORM_INPUT.LENGTH.WORK_DETAIL)) {
        setFormInputError({ ...formInputError, [target]: false });
        setFormModel({ ...formModel, [target]: e.target.value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    setFormModel({ ...formModel, [target]: e.target.value });
  };

  const onChangeDate = (target: 'workStartDate' | 'workEndDate') => (value: {
    date: Date | [Date, Date] | null;
    event: React.SyntheticEvent<any> | undefined;
  }) => {
    dirty || setDirty(true);
    setFormFillError({ ...formFillError, [target]: !value });
    setFormModel({ ...formModel, [target]: value });
  };

  const onChangeTags = (value: Array<TagType>) => {
    dirty || setDirty(true);
    setFormModel({ ...formModel, tags: value });
  };

  const onChangeVisible = (value: boolean) => {
    dirty || setDirty(true);
    setFormModel({ ...formModel, isVisible: value });
  };

  const onCloseForm = () => {
    if (dirty) {
      showCancelConfirmationDialog(['保存されていない変更を破棄しますか？'], onClose, closeDialog);
      return;
    }
    onClose();
  };

  return (
    <StyledEditForm>
      <StyledForm onSubmit={onSubmitForm}>
        {page === 1 && (
          <ResumeDetail
            showTitle={true}
            onChangeTitle={onChange('title')}
            onChangeCompany={onChange('companyName')}
            onChangeStartData={onChangeDate('workStartDate')}
            onChangeEndDate={onChangeDate('workEndDate')}
            onChangeTags={onChangeTags}
            onChangeComment={onChange('workDetail')}
            titleError={formFillError.title}
            titleInputError={formInputError.title}
            companyError={formFillError.companyName}
            startDateError={formFillError.workStartDate}
            title={formModel.title}
            company={formModel.companyName}
            startDate={formModel.workStartDate}
            endDate={formModel.workEndDate}
            dateRangeError={dateRangeError}
            tags={formModel.tags}
            comment={formModel.workDetail}
            commentError={formInputError.workDetail}
          />
        )}
        {page === 2 && (
          <ResumeProject
            tags={formModel.tags}
            onChangeTags={onChangeTags}
            isVisible={formModel.isVisible}
            onChangeIsVisible={onChangeVisible}
          />
        )}
        <StyledFormButtonArea>
          <StyledFormButtonLeft>
            <GrayButton
              width="120"
              size={STYLE_TYPE.SIZE.MEDIUM}
              addStyle={{ color: COLOR_DEFINITIONS.TEXT.MAIN }}
              onClick={onCloseForm}
            >
              キャンセル
            </GrayButton>
          </StyledFormButtonLeft>
          <StyledFormButtonRight>
            <LineButton
              width="120"
              size={STYLE_TYPE.SIZE.MEDIUM}
              onClick={onBack}
              disabled={isMinPage(page)}
            >
              戻る
            </LineButton>
            {isMaxPage(page) ? (
              <PrimaryButton
                width="120"
                size={STYLE_TYPE.SIZE.MEDIUM}
                onClick={onSubmitForm}
                disabled={loading}
              >
                変更する
              </PrimaryButton>
            ) : (
              <PrimaryButton width="120" size={STYLE_TYPE.SIZE.MEDIUM} onClick={onGo}>
                次へ
              </PrimaryButton>
            )}
          </StyledFormButtonRight>
        </StyledFormButtonArea>
      </StyledForm>
    </StyledEditForm>
  );
};

export default ResumeEdit;

const StyledEditForm = styled.div`
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  padding: 30px;
`;
const StyledForm = styled.form`
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;
const StyledFormButtonArea = styled.div`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  padding-top: 20px;
`;
const StyledFormButtonLeft = styled.div`
  > * {
    min-width: 120px;
  }
`;
const StyledFormButtonRight = styled.div`
  display: flex;
  > * {
    min-width: 120px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;
