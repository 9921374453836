import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type NotificationMessageType = {
  texts: Array<string>;
  onOk: () => void;
};

export const NotificationMessage: React.FC<NotificationMessageType> = (props) => {
  const { texts = [], onOk } = props;
  return (
    <StyledNotificationMessage>
      <StyledNotificationMessageContainer>
        {texts.map((text, i) => (
          <StyledNotificationMessageText key={i}>{text}</StyledNotificationMessageText>
        ))}
        <StyledNotificationButtons>
          <PrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onOk}>
            OK
          </PrimaryButton>
        </StyledNotificationButtons>
      </StyledNotificationMessageContainer>
    </StyledNotificationMessage>
  );
};

export default NotificationMessage;

const StyledNotificationMessage = styled(DialogBase)`
  position: relative;
`;

const StyledNotificationMessageContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledNotificationMessageText = styled.p`
  text-align: center;
  font-size: 16px;
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledNotificationButtons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 100px;
  }
`;
