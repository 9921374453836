import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useHistory, useRouteMatch, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { StyledContentsWrapper } from '@meettry/engineer/components/pages/EnterpriseDashboard';
import Accordion from '@meettry/ui-components/components/atoms/Accordion';
import EditMenu from '@meettry/ui-components/components/atoms/EditMenu';
import { Ellipsis } from '@meettry/company/component/atoms/ellipsis';
import ScoutDetailTable from '@meettry/ui-components/components/organisms/ScoutDetailTable';
import { ScoutTab } from '@meettry/company/component/molecules/scoutTab';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import useScoutDetail from '@meettry/ui-components/hooks/scout/useScoutDetail';
import { formatDate } from '@meettry/ui-components/utils';
import { Pagination } from '@meettry/ui-components/components/molecules/Pagination';
import ScoutUserList from '@meettry/ui-components/components/organisms/CassetteScoutUser';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { EditModal } from '@meettry/ui-components/components/organisms/Modal';
import ScoutCommentBox from '@meettry/ui-components/components/molecules/ScoutCommentBox';
import ScoutEditorBox, {
  DefaultScoutEditModel,
  extractScoutEditModel,
  isValidScoutEditModel,
  SCOUT_EDIT_TYPE,
  ScoutEditModel
} from '@meettry/ui-components/components/molecules/ScoutEditorBox';
import { useQuery } from '@apollo/react-hooks';
import { GET_TAG_LIST } from '@meettry/company/graphql/query/scout';
import { Tag } from '@meettry/ui-components/types/tag';
import useMousePosition from '@meettry/ui-components/hooks/useMousePosition';
import useSetDisplayMenu from '@meettry/ui-components/hooks/useSetDisplayMenu';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';

type ShowModalType = 'createScout' | 'editScout' | 'addScoutComment' | 'deleteScout';

/**
 * EnterpriseScoutDetail Page Component
 */
const EnterpriseScoutDetail = () => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch<{ scoutId: string }>();
  const { showCancelConfirmationDialog, closeDialog } = useDialog();

  /**
   * State
   */
  const [isOpenDetail, setIsOpenDetail] = useState(-1); // -1 = close, 0 = open
  const [showModal, setShowModal] = useState<ShowModalType | ''>('');
  const [scoutComment, setScoutComment] = useState({ userId: '', text: '' });
  const [scoutEditModel, setScoutEditModel] = useState<ScoutEditModel>(DefaultScoutEditModel());
  const [scoutEditError, setScoutEditError] = useState<boolean>(false);
  const [showEditMenu, setShowEditMenu] = useState<boolean>(false);

  const hoverOpenMenuEl = useRef<HTMLUListElement>(null);
  const editMenuIconEl = useRef<HTMLImageElement>(null);

  const [dirty, setDirty] = useState(false);

  /**
   * Query
   */
  const { data: tagData } = useQuery(GET_TAG_LIST);

  /**
   * Custom Hooks
   */
  const {
    loading,
    paging,
    setPaging,
    offset,
    scoutDetail,
    matchedUsers,
    currentScoutMatchedType,
    setCurrentScoutMatchedType,
    mutateScoutComment,
    mutateCreateScout,
    createScoutData,
    createScoutLoading,
    mutateUpdateScout,
    updateScoutData,
    updateScoutLoading,
    mutateDeleteScout,
    deleteScoutData,
    deleteScoutLoading,
    scoutMatchedUsersLoading,
    toggleBookmark,
    bookmarkLoading
  } = useScoutDetail(match.params.scoutId || '');

  const mousePosition = useMousePosition(showEditMenu);
  const isShowMenu = useSetDisplayMenu(
    hoverOpenMenuEl.current,
    editMenuIconEl.current,
    mousePosition
  );

  /**
   * Func: goToChatPage
   * チャットページに遷移
   */
  const goToChatPage = useCallback(
    (scoutRoomId: string | null, userId: string, nickname: string) => {
      if (scoutRoomId) {
        history.push({
          pathname: `/enterprise/chat/${scoutRoomId}`,
          state: { userId: '', nickname: '', scoutId: match.params.scoutId }
        });
      } else {
        history.push({
          pathname: `/enterprise/chat`,
          state: { userId, nickname, scoutId: match.params.scoutId }
        });
      }
    },
    []
  );

  /**
   * Func: createScout
   * スカウトの作成
   */
  const createScout = () => {
    const isValid = isValidScoutEditModel(scoutEditModel);
    setScoutEditError(!isValid);
    if (!isValid) return;
    const {
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive,
      requiredGeneralTags,
      requiredSkillTags,
      expectedGeneralTags,
      expectedSkillTags
    } = scoutEditModel;
    const createModel = {
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount: Number(recruitmentCount),
      isActive,
      scoutTags: [
        ...requiredGeneralTags,
        ...requiredSkillTags,
        ...expectedGeneralTags,
        ...expectedSkillTags
      ]
    };
    mutateCreateScout({ variables: { input: createModel } });
  };

  /**
   * Func: updateScout
   * スカウトのアップデート
   */
  const updateScout = () => {
    const isValid = isValidScoutEditModel(scoutEditModel);
    setScoutEditError(!isValid);
    if (!isValid) return;
    const {
      id,
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive,
      requiredGeneralTags,
      requiredSkillTags,
      expectedGeneralTags,
      expectedSkillTags
    } = scoutEditModel;
    const updateModel = {
      id: Number(id),
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount: Number(recruitmentCount),
      isActive,
      scoutTags: [
        ...requiredGeneralTags,
        ...requiredSkillTags,
        ...expectedGeneralTags,
        ...expectedSkillTags
      ]
    };
    mutateUpdateScout({ variables: { input: updateModel } });
  };

  /**
   * Func: updateActiveScout
   */
  const updateActiveScout = () => {
    const {
      id,
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive,
      scoutTags
    } = scoutDetail;
    const updateModel = {
      id,
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive: isActive === 1 ? 0 : 1,
      scoutTags: scoutTags.map((tag) => ({
        isRequired: tag.isRequired,
        years: tag.years,
        tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
      }))
    };
    mutateUpdateScout({ variables: { input: updateModel } });
  };

  /**
   * Func: deleteScout
   * スカウトの削除
   */
  const deleteScout = () => {
    mutateDeleteScout({ variables: { id: scoutDetail.id } });
  };

  /**
   * Func: addScoutComment
   * エンジニアに対する申し送りメッセージを投稿
   */
  const addScoutComment = useCallback(() => {
    mutateScoutComment({ variables: { ...scoutComment } });
  }, [mutateScoutComment, scoutComment]);

  /**
   * Func: ShowEditScoutModal
   * スカウト編集モーダルの表示
   */
  const showEditScoutModal = () => {
    setScoutEditModel(extractScoutEditModel(scoutDetail));
    setShowModal('editScout');
    setScoutEditError(false);
  };

  /**
   * Func: ShowCreateScoutModal
   * スカウト作成モーダルの表示
   */
  const showCreateScoutModal = () => {
    setScoutEditModel(DefaultScoutEditModel());
    setShowModal('createScout');
    setScoutEditError(false);
  };

  /**
   * Variables
   */
  const scoutTabData = useMemo(
    () =>
      matchedUsers.map((matchedUser) => ({
        name: matchedUser.name,
        count: matchedUser.count,
        type: matchedUser.type
      })),
    [matchedUsers]
  );
  const activeMatchedUsers = useMemo(
    () => matchedUsers.find((matchedUser) => matchedUser.type === currentScoutMatchedType),
    [matchedUsers, currentScoutMatchedType]
  );
  const tags: Tag[] = tagData?.tagList ?? [];

  /**
   * 鉛筆マークのホバーに関する副作用
   */
  useEffect(() => {
    if (!isShowMenu) {
      setShowEditMenu(false);
    }
  }, [isShowMenu, mousePosition]);

  /**
   * スカウト作成が成功したらモーダルを閉じて、詳細画面を表示する
   */
  useEffect(() => {
    if (!createScoutLoading && createScoutData) {
      setShowModal('');
      history.push(`/enterprise/scout/${createScoutData.createScout.scout.id}`);
    }
  }, [createScoutData, createScoutLoading, setShowModal]);

  /**
   * スカウト編集が成功したらモーダルを閉じる
   */
  useEffect(() => {
    if (!updateScoutLoading && updateScoutData) {
      setShowModal('');
    } else {
    }
  }, [updateScoutData, updateScoutLoading, setShowModal]);

  /**
   * スカウト削除が成功したらモーダルを閉じる
   */
  useEffect(() => {
    if (!deleteScoutLoading && deleteScoutData) {
      setShowModal('');
    }
  }, [deleteScoutLoading, deleteScoutData, setShowModal]);

  const onCancel = () => {
    //MEMO(aida) 内容が編集されている場合に確認モーダルを表示する
    if (dirty) {
      showCancelConfirmationDialog(
        ['保存されていない変更を破棄しますか?'],
        () => {
          closeDialog();
          setShowModal('');
          setScoutEditModel(DefaultScoutEditModel());
          setDirty(false);
        },
        () => {
          closeDialog();
        }
      );
      return;
    }
    setShowModal('');
  };

  return (
    <StyledContentsWrapper>
      <StyledEngineerListWrapper>
        <StyledEngineerListTitle>
          <StyledIcon iconName="resume" iconColor="black" width="18px" />
          スカウト
        </StyledEngineerListTitle>
        {loading ? (
          <StyledLoader>
            <Loader />
          </StyledLoader>
        ) : (
          <>
            <StyledEngineerListWrap>
              <StyledEngineerListDetails>
                <StyledEditIcon
                  iconColor="gray"
                  iconName="pencil_gray"
                  fileType="png"
                  onMouseEnter={() => setShowEditMenu(true)}
                  ref={editMenuIconEl}
                />
                <EditMenu
                  menuRef={hoverOpenMenuEl}
                  isShowMenu={showEditMenu}
                  top={40}
                  right={-25}
                  list={[
                    {
                      icon: { iconName: 'edit', iconColor: 'gray' },
                      text: '編集',
                      onClick: showEditScoutModal
                    }
                    // MEMO(oomura, 2021/05/27): 不要なため削除だが、今後表示する可能性を考慮してコメントアウト
                    // {
                    //   icon: { iconName: 'view', iconColor: 'gray' },
                    //   text: (scoutDetail?.isActive ?? -1) === 1 ? '募集終了' : '募集再開',
                    //   onClick: updateActiveScout
                    // },
                    // {
                    //   icon: { iconName: 'delete', iconColor: 'gray' },
                    //   text: '削除',
                    //   onClick: () => setShowModal('deleteScout')
                    // }
                  ]}
                />
                <StyledEngineerListData>
                  最終更新日: {scoutDetail?.updated ? formatDate(scoutDetail?.updated) : ''}
                </StyledEngineerListData>
                <StyledEngineerListSubtitle>{scoutDetail?.title ?? ''}</StyledEngineerListSubtitle>
                <Ellipsis
                  lineNumber={2}
                  cls="engineer-list-desc"
                  isWorkShorten={isOpenDetail != -1}
                  Tag="p"
                >
                  {scoutDetail?.comment ?? ''}
                </Ellipsis>
                <StyledScoutDetailAccordion activeIndex={isOpenDetail}>
                  <ScoutDetailTable scoutDetail={scoutDetail} />
                </StyledScoutDetailAccordion>
                <StyledSeeMore
                  showDetails={isOpenDetail === 0}
                  onClick={() => {
                    setIsOpenDetail(isOpenDetail === -1 ? 0 : -1);
                  }}
                >
                  {isOpenDetail === 0 ? '詳細を閉じる' : 'もっと見る'}
                </StyledSeeMore>
              </StyledEngineerListDetails>
              <ScoutTab
                active={currentScoutMatchedType}
                setCurrent={setCurrentScoutMatchedType}
                tab={scoutTabData}
              />
              <StyledEngineerListContainer>
                <StyledTitleBox>
                  <StyledTitleBoxText>{activeMatchedUsers?.name ?? ''}</StyledTitleBoxText>
                  <LineButton
                    size={STYLE_TYPE.SIZE.SMALL}
                    width="96"
                    onClick={showCreateScoutModal}
                  >
                    <StyledIcon iconColor="primary" iconName="plus" />
                    新規作成
                  </LineButton>
                </StyledTitleBox>
                {scoutMatchedUsersLoading ? (
                  <StyledLoader>
                    <Loader />
                  </StyledLoader>
                ) : (
                  <>
                    <StyledEngineerList>
                      <ScoutUserList
                        scoutUsers={activeMatchedUsers?.results ?? []}
                        addComment={(userId) => {
                          setScoutComment({ userId, text: '' });
                          setShowModal('addScoutComment');
                        }}
                        sendMessage={goToChatPage}
                        toggleBookmark={toggleBookmark}
                        enabledBookmark={!bookmarkLoading}
                      />
                    </StyledEngineerList>
                    {(activeMatchedUsers?.count ?? 0) > 0 && (
                      <Pagination
                        maxCount={activeMatchedUsers?.count ?? 1}
                        viewCount={offset}
                        currentPage={paging}
                        setCurrentPage={setPaging}
                        marginTop={30}
                      />
                    )}
                  </>
                )}
              </StyledEngineerListContainer>
            </StyledEngineerListWrap>
          </>
        )}
      </StyledEngineerListWrapper>
      <EditModal
        title={['スカウト編集']}
        show={showModal === 'editScout'}
        displayModal={(flag) =>
          !updateScoutLoading && (flag ? showEditScoutModal() : setShowModal(''))
        }
        okClick={() => updateScout()}
        okClickText="保存"
        disabledOkButton={updateScoutLoading}
        cancelClickText="キャンセル"
        cancelClick={() => {
          onCancel();
        }}
        disabledCancelButton={updateScoutLoading}
        buttonWidth="120"
      >
        <ScoutEditorBox
          type={SCOUT_EDIT_TYPE.EDIT}
          model={scoutEditModel}
          setModel={setScoutEditModel}
          error={scoutEditError}
          tags={tags}
          setDirty={setDirty}
          dirty={dirty}
        />
      </EditModal>
      <EditModal
        title={['スカウト新規作成']}
        show={showModal === 'createScout'}
        displayModal={(flag) =>
          !createScoutLoading && (flag ? showCreateScoutModal() : setShowModal(''))
        }
        okClick={() => createScout()}
        okClickText="作成"
        disabledOkButton={createScoutLoading}
        cancelClickText="キャンセル"
        cancelClick={() => {
          onCancel();
        }}
        disabledCancelButton={createScoutLoading}
        buttonWidth="120"
      >
        <ScoutEditorBox
          type={SCOUT_EDIT_TYPE.CREATE}
          model={scoutEditModel}
          setModel={setScoutEditModel}
          error={scoutEditError}
          tags={tags}
          setDirty={setDirty}
          dirty={dirty}
        />
      </EditModal>
      <EditModal
        title={['コメント追加']}
        show={showModal === 'addScoutComment'}
        displayModal={(flag) => setShowModal(flag ? 'addScoutComment' : '')}
        okClick={() => {
          if (!!scoutComment.text) {
            addScoutComment();
            setShowModal('');
          }
        }}
        okClickText="コメントする"
        cancelClickText="キャンセル"
        cancelClick={() => {
          setShowModal('');
        }}
        buttonWidth="140"
      >
        <ScoutCommentBox
          text={scoutComment.text}
          setText={(text) => setScoutComment({ ...scoutComment, text })}
        />
      </EditModal>
      <EditModal
        show={showModal === 'deleteScout'}
        displayModal={(flag) => setShowModal(flag ? 'deleteScout' : '')}
        okClick={() => deleteScout()}
        okClickText="削除する"
        disabledOkButton={deleteScoutLoading}
        cancelClickText="キャンセル"
        cancelClick={() => setShowModal('')}
        disabledCancelButton={deleteScoutLoading}
        buttonWidth="120"
      >
        <StyledConfirmScoutDelete>
          「{scoutDetail?.title ?? ''}」 を削除しますか？
        </StyledConfirmScoutDelete>
      </EditModal>
    </StyledContentsWrapper>
  );
};
export default EnterpriseScoutDetail;

const StyledLoader = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

// engineer-list-wrapper
const StyledEngineerListWrapper = styled.div`
  font-family: ${FONT_FAMILY.COMMON};
`;

const StyledEngineerListTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const StyledEngineerListWrap = styled.div`
  position: relative;
  margin-top: 25px;
  padding-top: 20px;
  box-sizing: border-box;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledEngineerListDetails = styled.div<{ showEditScreen?: boolean }>`
  ${({ showEditScreen }) => (showEditScreen ? `display:none;` : null)}
`;

const StyledEditIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 20px;
  height: 20px;
  background: url('../../images/icon_pencil_gray.png') no-repeat;
  background-size: cover;
  cursor: pointer;
`;

const StyledEngineerListData = styled.span`
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  font-size: 12px;
  font-weight: 500;
`;

const StyledEngineerListSubtitle = styled.h3`
  margin-top: 10px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 18px;
  font-weight: bold;
  line-height: 31px;
`;

const StyledSeeMore = styled.span<{ showDetails?: boolean }>`
  position: relative;
  display: block;
  margin: 15px auto 0 auto;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 16px;
  cursor: pointer;
  width: ${({ showDetails }) => (showDetails ? ` 96` : ` 80`)}px;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: -17px;
    transform: rotate(45deg);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-bottom: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    transition: transform 0.2s;
    ${({ showDetails }) =>
      showDetails
        ? `top: 7px;
    left: -17px;
    transform: rotate(-135deg);
    display: inline-block;
    width: 10px;
    height: 10px;
    border-right: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-bottom: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};`
        : ``}
  }
`;

const StyledEngineerListContainer = styled.div`
  margin-top: 20px;
`;

const StyledTitleBox = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTitleBoxText = styled.h3`
  font-family: ${FONT_FAMILY.COMMON};
  font-size: 20px;
  font-weight: bold;
`;

const StyledEngineerList = styled.div`
  margin-top: 20px;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
`;

const StyledScoutDetailAccordion = styled(Accordion)`
  margin-top: 40px;
`;

const StyledConfirmScoutDelete = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`;
