import React from 'react';
import styled, { css } from 'styled-components';
import ReactDatepicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import '@meettry/ui-components/components/atoms/Input/Datepicker/datepicker.css';
import icons from '@meettry/ui-components/images/icons';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const Datepicker: React.FC<any> = (props) => {
  return <StyledDatepicker {...props} />;
};

export default Datepicker;

const StyledDatepicker = styled<any & { error?: boolean }>(ReactDatepicker)`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 180px;
  height: 48px;
  font-size: 16px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 20px;
  background-image: url(${icons.primary.day});
  background-position: 20px center;
  background-size: 18px;
  background-repeat: no-repeat;
  ${({ error }) =>
    error &&
    css`
      background-color: rgb(244, 35, 60, 0.1);
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
    `}
`;
