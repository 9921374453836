import React from 'react';
import styled, { css } from 'styled-components';
import { useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import TagCounter from '@meettry/ui-components/components/atoms/Text/TagCounter';
import Icon, { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type TabLinkItemType = {
  to: string;
  name: string;
  count?: number | null;
  Content: () => JSX.Element;
  iconProps?: IconPropsType;
};

type TabLinkType = {
  linkList: Array<TabLinkItemType>;
};

const TabLink: React.FC<TabLinkType> = (props) => {
  const { linkList = [] } = props;
  const location = useLocation();
  const { pathname } = location;
  const linkListLength = linkList.length;
  const itemWidth = 100 / linkListLength;
  const currentPath = pathname.split('/').slice(0, 4).join('/');
  const currentPosition = linkList.findIndex(({ to }) => to === currentPath);
  const barPosition = itemWidth * currentPosition;

  return (
    <StyledTabLinkWrapper>
      <StyledTabLinkContainer barPosition={barPosition} itemWidth={itemWidth}>
        {linkList.map(({ to, name, Content, count, iconProps }, i) => (
          <StyledLinkItem
            itemWidth={itemWidth}
            className={currentPath === to ? 'active' : ''}
            key={`tab-link-${name}_${i}`}
          >
            <Link to={to}>
              {!!iconProps ? (
                <Icon {...iconProps} iconColor={currentPath === to ? 'primary' : 'black'} />
              ) : null}
              <Content />
              {count ? <TagCounter>{count}</TagCounter> : null}
            </Link>
          </StyledLinkItem>
        ))}
      </StyledTabLinkContainer>
    </StyledTabLinkWrapper>
  );
};

export default TabLink;

const StyledTabLinkWrapper = styled.div`
  && {
    width: 100%;
  }
`;

const StyledTabLinkContainer = styled.ul<{ barPosition: number; itemWidth: number }>`
  && {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    position: relative;
    ${({ barPosition, itemWidth }) =>
      0 <= barPosition &&
      css`
        ::before {
          content: '';
          display: block;
          position: absolute;
          width: ${itemWidth}%;
          height: 2px;
          background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
          top: 0;
          left: ${barPosition}%;
          transition: left ease 0.5s;
        }
      `}
  }
`;
const StyledLinkItem = styled.li<{ itemWidth: number }>`
  && {
    width: ${({ itemWidth }) => `${itemWidth}%`};
    height: 60px;
    flex-shrink: 0;
    a {
      display: flex;
      align-items: center;
      justify-content: center;
      color: ${COLOR_DEFINITIONS.BG.BLACK};
      font-size: 18px;
      width: 100%;
      height: 100%;
    }
    &.active {
      a {
        color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        path {
          fill: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        }
      }
    }
  }
`;
