export const COLOR_DEFINITIONS = {
  MAIN: {
    PRIMARY: '#0fafa9',
    PRIMARY_DARK: '#008681',
    SECONDARY: '#2459bf',
    GRADATION: ['#0fafa9', '#00dddd']
  },
  BG: {
    MAIN: '#dbf3f2',
    SUB: '#f7f7f7',
    FOOTER: '#333333',
    WHITE: '#ffffff',
    BLACK: '#000000',
    SKILL: '#e8eef9',
    ALERT: '#ffeaea',
    TRANSLUCENT: {
      MAIN: 'rgba(15, 175, 169, 0.05)',
      MAIN_LIGHT: 'rgba(15, 175, 169, 0.1)',
      SECONDARY: 'rgba(255, 255, 0, 0.1)',
      SUB: 'rgba(51, 51, 51, 0.15)',
      ALERT: 'rgba(244, 35, 60, 0.1)',
      SHADOW: 'rgba(0, 0, 0, 0.2)',
      BLACK: 'rgba(0, 0, 0, 0.4)',
      TRANSPARENT: 'rgba(255, 255, 255, 0)',
      TECH_STACK: 'rgba(36, 89, 191, 0.1)'
    },
    MODAL: {
      MESSAGE: '#fee8eb'
    }
  },
  BUBBLE_CHART: {
    FRONTEND: '#ff5858',
    BACKEND: '#ffa908',
    //以下定義必要あり
    CATEGORY_FIRST: '#4dc2ff',
    CATEGORY_SECOND: '#ac86d9',
    CATEGORY_THIRD: '#8fd62d'
  },
  TEXT: {
    MAIN: '#333333',
    SECONDARY: '#999999',
    SUB: '#cccccc',
    ALERT: '#f4233c',
    WHITE: '#ffffff'
  },
  LINE: {
    MAIN: '#dddddd',
    SECONDARY: '#eeeeee',
    SUB: '#f7f7f7',
    THIRDLY: '#707070',
    ALERT: '#ffff00',
    SELECTBOX: '#343434'
  },
  HOVER: {
    MAIN: '#008681',
    OUTLINED: '#E6F7F6'
  }
};
