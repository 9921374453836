import React, { useRef, useEffect, useMemo, ReactNode } from 'react';
import styled from 'styled-components';
import { PrimaryButton, LineButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

export type ModalType = 'EDIT' | 'NOTICE';
export const MODAL_TYPE_EDIT: ModalType = 'EDIT';
export const MODAL_TYPE_NOTICE: ModalType = 'NOTICE';

export type ModalProps = {
  type: ModalType;
  show: boolean;
  displayModal: (flag: boolean) => void;
  title?: string[];
  children?: ReactNode;
  modalBackClick?: Function | null;
  isBorder?: boolean;
  cancelClick?: () => void;
  cancelClickText?: string;
  disabledCancelButton?: boolean;
  okClick?: () => void;
  okClickText?: string;
  disabledOkButton?: boolean;
  buttonWidth?: string;
};

export const Modal: React.FC<ModalProps> = (props) => {
  const {
    type,
    show,
    displayModal,
    title,
    children,
    cancelClick,
    okClick,
    modalBackClick,
    cancelClickText,
    okClickText,
    disabledCancelButton = false,
    disabledOkButton = false,
    buttonWidth = '80'
  } = props;
  const modalEl = useRef<HTMLDivElement>(null);
  const MODAL_MARGIN_SIZE = 100;
  const windowFitSize = window.innerHeight - MODAL_MARGIN_SIZE;

  const isScroll = useMemo(() => {
    if (!show) return false;
    const modalHeight = !!modalEl.current ? modalEl.current.offsetHeight : 0;
    const windowFitSize = window.innerHeight - MODAL_MARGIN_SIZE;
    return modalHeight > windowFitSize;
  }, [show]);

  const style: {
    height?: string;
    overflowY?: 'scroll';
  } = isScroll
    ? {
        height: `${windowFitSize}px`,
        overflowY: 'scroll'
      }
    : {};

  useEffect(() => {
    const bodyEl = document.getElementsByTagName('body');

    if (show && !!bodyEl[0]) bodyEl[0].style.overflow = 'hidden';
    else bodyEl[0].style.overflow = 'auto';
  }, [show]);

  return (
    <StyledModalWrapper show={show}>
      <StyledModalDialog ref={modalEl} type={type} style={show ? style : {}}>
        {!!title ? (
          <StyledModalTitle type={type}>
            {title.map((text) => (
              <p key={text}>{text}</p>
            ))}
          </StyledModalTitle>
        ) : null}
        {children}
        <StyledButtons>
          {cancelClick && (
            <LineButton
              onClick={cancelClick}
              size={STYLE_TYPE.SIZE.MEDIUM}
              width={buttonWidth}
              disabled={disabledCancelButton}
            >
              {cancelClickText ?? 'キャンセル'}
            </LineButton>
          )}
          <PrimaryButton
            onClick={okClick}
            size={STYLE_TYPE.SIZE.MEDIUM}
            width={buttonWidth}
            disabled={disabledOkButton}
          >
            {okClickText ?? '決定'}
          </PrimaryButton>
        </StyledButtons>
      </StyledModalDialog>
      <StyledBackdrop
        onClick={() => {
          displayModal(false);
          !!modalBackClick && modalBackClick();
        }}
      />
    </StyledModalWrapper>
  );
};

const StyledModalWrapper = styled.div<{ show: boolean }>`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s;
  ${({ show }) =>
    show
      ? `
        opacity: 1;
        visibility: visible;
      `
      : ''}
`;

const StyledModalDialog = styled.div<{ type: ModalType }>`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  max-width: 80%;
  border-radius: 5px;
  box-sizing: border-box;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  color: #333333;
  transition: top 0.3s;
  ${({ type }) => {
    switch (type) {
      case MODAL_TYPE_EDIT: {
        return `
          padding: 35px 40px 40px;
        `;
      }
      case MODAL_TYPE_NOTICE:
      default: {
        return `
          padding: 50px 45px;
        `;
      }
    }
  }}
`;

const StyledModalTitle = styled.span<{ type: ModalType }>`
  color: #0fafa9;
  font-size: 20px;
  font-weight: bold;

  ${({ type }) => {
    switch (type) {
      case MODAL_TYPE_EDIT: {
        return ``;
      }
      case MODAL_TYPE_NOTICE:
      default: {
        return `
          text-align: center;
          line-height: 1;
          * + * {
            margin-top: 14px;
          }        
        `;
      }
    }
  }}
`;

const StyledBackdrop = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.3s;
  background-color: rgba(0, 0, 0, 0.2);
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;

  > * + * {
    margin-left: 10px;
  }
`;
