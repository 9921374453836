import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton, LineButton } from '@meettry/ui-components/components/atoms/Button';

type EditProfileType = {
  texts?: Array<string>;
  onAccept: () => void;
  onDecline: () => void;
};

export const ActivateScoutConfirmation: React.FC<EditProfileType> = (props) => {
  const { onAccept, onDecline } = props;
  return (
    <StyledEditProfileDialog>
      <StyledEditProfileContainer>
        <StyledEditProfileText>
          企業スカウトからのスカウトを受け取ることが可能です。
        </StyledEditProfileText>
        <StyledEditProfileText>スカウトをONにしますか？</StyledEditProfileText>
        <StyledActivateScoutButtons>
          <LineButton onClick={onDecline}>OFFのまま登録</LineButton>
          <PrimaryButton onClick={onAccept}>ONにして登録</PrimaryButton>
        </StyledActivateScoutButtons>
      </StyledEditProfileContainer>
    </StyledEditProfileDialog>
  );
};

export const ScoutUnableConfirmation: React.FC<EditProfileType> = (props) => {
  const { texts, onAccept, onDecline } = props;
  return (
    <StyledEditProfileDialog>
      <StyledEditProfileContainer>
        {texts &&
          texts.map((text, i) => <StyledEditProfileText key={i}>{text}</StyledEditProfileText>)}
        <StyledEditProfileButtons>
          <LineButton onClick={onDecline}>NO</LineButton>
          <PrimaryButton onClick={onAccept}>OK</PrimaryButton>
        </StyledEditProfileButtons>
      </StyledEditProfileContainer>
    </StyledEditProfileDialog>
  );
};

export const ProfileEditConfirmation: React.FC<EditProfileType> = (props) => {
  const { texts, onAccept, onDecline } = props;
  return (
    <StyledEditProfileDialog>
      <StyledEditProfileContainer>
        {texts &&
          texts.map((text, i) => <StyledEditProfileText key={i}>{text}</StyledEditProfileText>)}
        <StyledEditProfileButtons>
          <LineButton onClick={onDecline}>NO</LineButton>
          <PrimaryButton onClick={onAccept}>OK</PrimaryButton>
        </StyledEditProfileButtons>
      </StyledEditProfileContainer>
    </StyledEditProfileDialog>
  );
};

const StyledEditProfileDialog = styled(DialogBase)`
  position: relative;
`;

const StyledEditProfileContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledEditProfileText = styled.p`
  text-align: center;
  font-size: 16px;
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledEditProfileButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 100px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;

const StyledActivateScoutButtons = styled(StyledEditProfileButtons)`
  & > * {
    max-width: 140px;
  }
`;
