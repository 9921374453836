import React from 'react';
import styled from 'styled-components';

export type IconProps = {
  iconName: string;
  iconColor?: 'black' | 'gray' | 'primary' | 'red' | 'white' | 'blue';
  fileType?: 'svg' | 'png';
  width?: string;
  height?: string;
  marginRight?: string;
  style?: object;
  onClick?: () => void;
  accordion?: {
    isAccordion: boolean;
    hide: boolean;
  };
  onMouseEnter?: () => void;
  className?: string;
};

type StyledIconProps = {
  height?: string;
  width?: string;
  marginRight?: string;
  accordion?: {
    isAccordion: boolean;
    hide: boolean;
  };
};

const Icon = React.forwardRef(
  (
    props: IconProps,
    ref:
      | ((instance: HTMLImageElement | null) => void)
      | React.MutableRefObject<HTMLImageElement | null>
      | null
  ) => {
    const {
      iconName = 'pc',
      width = '12px',
      iconColor = 'black',
      fileType = 'svg',
      ...restProps
    } = props;
    return (
      <StyledImg
        width={width}
        src={require(`../../../images/icons/${iconColor}/icon_${iconName}.${fileType}`)}
        {...restProps}
        ref={ref}
      />
    );
  }
);

export default Icon;

const StyledImg = styled.img<StyledIconProps>`
  height: ${({ height }) => height || 'auto'};
  width: ${({ width }) => width};
  ${({ marginRight }) => (marginRight ? `margin-right: ${marginRight}` : null)}
  ${({ accordion }) => (accordion && accordion.isAccordion ? `transition: transform 0.2s;` : null)}
  ${({ accordion }) =>
    accordion && accordion.isAccordion && accordion.hide ? `transform: rotate(180deg);` : null}
`;
