import React from 'react';
import styled from 'styled-components';
import avatarImage from '@meettry/company/images/avatar.png';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const EngineerProfile: React.FC<{ margin?: number }> = ({ margin }) => {
  return (
    <StyledEngineerProfile margin={margin}>
      <StyledEngineerProfileImg src={avatarImage} alt="エンジニアのアイコン" />
      <StyledEngineerProfileUserName>エンジニアの名前</StyledEngineerProfileUserName>
    </StyledEngineerProfile>
  );
};

const StyledEngineerProfile = styled.span<{ margin?: number }>`
  display: flex;
  align-items: center;
  margin-top: ${({ margin }) => (margin ? margin : 0)}px;
`;

const StyledEngineerProfileImg = styled.img`
  width: 25px;
`;

const StyledEngineerProfileUserName = styled.span`
  margin-left: 4px;
  font-size: 12px;
  color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
`;
