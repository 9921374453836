import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

/**
 * State
 */
type PopupOption = {
  id: string;
  type: 'success' | 'error';
  text: string;
};
type PopupState = {
  popups: PopupOption[]
};
export const initialState: PopupState = {
  popups: []
};

/**
 * Reducers
 */
type ShowPopupPayload = PopupOption;
const showPopup = (state: PopupState, action: PayloadAction<ShowPopupPayload>) => ({
   ...state,
   popups: [...state.popups, action.payload]
});

type RemovePopupPayload = Pick<PopupOption, 'id'>;
const removePopup = (state: PopupState, action: PayloadAction<RemovePopupPayload>) => ({
  ...state,
  popups: state.popups.filter(p => p.id !== action.payload.id)
});


/**
 * Slice
 */
const slice = createSlice({
  name: 'popup',
  initialState,
  reducers: {
    showPopup,
    removePopup
  }
});

/**
 * Selectors
 */
export const usePopupSelectors = () => {
  return useSelector((state: { popup: PopupState }) => state.popup)
};

export const popupActions = slice.actions;
export default slice.reducer;
