import gql from 'graphql-tag';

/**
 * 検索取得クエリー（エンジニアが検索）
 */
export const SEARCH_BY_CUSTOMER_QUERY = gql`
  query($queryString: String!, $first: Int!, $offset: Int!, $field: String!) {
    searchFor(queryString: $queryString) {
      users {
        count
        queryString
        results(page: { first: $first, offset: $offset }, orders: [{ field: $field, order: ASC }]) {
          id
          nickname
          location
          bio
          profileImage {
            url
          }
        }
      }
      resumes {
        count
        queryString
        results(page: { first: $first, offset: $offset }, orders: [{ field: $field, order: ASC }]) {
          id
          title
          companyName
          workDetail
          workStartDate
          workEndDate
          updated
          user {
            nickname
            profileImage {
              url
            }
          }
          tags {
            name
            displayName
            category
          }
        }
      }
      challenges {
        count
        queryString
        results(page: { first: $first, offset: $offset }, orders: [{ field: $field, order: ASC }]) {
          id
          title
          challengeDetail
          updated
          tags {
            name
            displayName
          }
          user {
            nickname
            profileImage {
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * 検索取得クエリー（企業アカウントが検索）
 */
export type SearchBhyEnterpriseQueryResponse = {
  loginSession: {
    user: {
      organization: {
        bookmarks: {
          users: {
            results: {
              id: string;
            }[];
          };
        };
      };
    };
  };
  searchFor: {
    users: {
      count: number;
      queryString: string;
      results: {
        id: string;
        nickname: string;
        location: string;
        bio: string;
        profileImage: {
          url: string;
        };
        chat: {
          scoutRoomId: string;
        };
      }[];
    };
    challenges: {
      count: number;
      queryString: string;
      results: {
        id: string;
        title: string;
        challengeDetail: string;
        updated: string;
        tags: {
          name: string;
          displayName: string;
        };
        user: {
          nickname: string;
          profileImage: {
            url: string;
          };
        };
      }[];
    };
    resumes: {
      count: number;
      queryString: string;
      results: {
        id: string;
        title: string;
        companyName: string;
        workDetail: string;
        workStartDate: string;
        workEndDate: string;
        updated: string;
        user: {
          nickname: string;
          profileImage: {
            url: string;
          };
        };
        tags: {
          name: string;
          displayName: string;
          category: string;
        };
      }[];
    };
    chatMessages: {
      count: number;
      queryString: string;
      results: {
        roomId: string;
        body: string;
        createdAt: string;
        senderId: string;
        senderName: string;
      }[];
    };
  };
};
export const SEARCH_BY_ENTERPRISE_QUERY = gql`
  query($queryString: String!, $first: Int!, $offset: Int!, $field: String!) {
    loginSession {
      user {
        organization {
          bookmarks {
            users {
              results(
                page: { first: $first, offset: $offset }
                orders: [{ field: $field, order: ASC }]
              ) {
                id
                nickname
              }
            }
          }
        }
      }
    }
    searchFor(queryString: $queryString) {
      users {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          nickname
          location
          bio
          userDetail {
            enableScoutChatNotifMail
            enableAcceptingScout
          }
          profileImage {
            url
          }
          chat {
            scoutRoomId
          }
        }
      }
      resumes {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          title
          companyName
          workDetail
          workStartDate
          workEndDate
          updated
          user {
            nickname
            profileImage {
              url
            }
          }
          tags {
            name
            displayName
            category
          }
        }
      }
      challenges {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          title
          challengeDetail
          updated
          tags {
            name
            displayName
          }
          user {
            nickname
            profileImage {
              url
            }
          }
        }
      }
    }
  }
`;
