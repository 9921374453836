import gql from 'graphql-tag';
import constants from '@meettry/engineer/constants';

import { ONE_TIME_TOKEN_NAME } from '@meettry/engineer/utils/Constant';
import { ERROR_CODE_LIST } from '@meettry/engineer/utils/ErrCodes';

// サーバ接続エラー汎用ポップアップ対応関数
export function withErrorPopup(argPromise) {
  return argPromise.catch(() => {
    // 共通のエラー処理
  });
}

export function isValidateEmail(email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function getResponseErrors(result) {
  if (
    'errors' in result &&
    Array.isArray(result.errors) &&
    'extensions' in result.errors[0] &&
    'code' in result.errors[0].extensions
  ) {
    const { code } = result.errors[0].extensions;
    return ERROR_CODE_LIST.find((_errorData) => _errorData.code === code);
  }
  return null;
}

export const getCookieArray = () =>
  document.cookie
    .split('; ')
    .filter((x) => x !== '')
    .reduce((acc, x) => {
      const [key, value] = x.split('=');
      return {
        [key]: decodeURIComponent(value),
        ...acc
      };
    }, {});

export function getCookieOOT() {
  const arrCookie = getCookieArray();
  return arrCookie[ONE_TIME_TOKEN_NAME];
}

// ヘッダーにtokenをセットしたclientを返す
export function getClientToken(client, gqlQuery, queryVariables) {
  const ott = getCookieOOT();

  return client.mutate({
    mutation: gqlQuery,
    variables: queryVariables,
    context: {
      headers: {
        [ONE_TIME_TOKEN_NAME]: ott
      }
    }
  });
}

// TODO:one time token 取得箇所仮実装状態
// one time token を取得
export function getOneTimeToken(client) {
  const gqlQuery = gql`
    mutation () {
      genToken() {
        ok
      }
    }
  `;

  return client.mutate({
    mutation: gqlQuery
  });
}

export function getErrorCodes(error) {
  if (!error) {
    return [];
  }
  if (!error.graphQLErrors) {
    return [];
  }
  if (!error.graphQLErrors.length) {
    return [];
  }

  return error.graphQLErrors
    .map((x) => {
      if (!x.extensions) {
        return null;
      }
      return x.extensions.code;
    })
    .filter((x) => x);
}

// https://leihauoli.atlassian.net/wiki/spaces/XTEC/pages/198606852/API#%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%B3%E3%83%BC%E3%83%89
export function getErrorPhrase(error) {
  switch (error) {
    //CommonError
    case 'E-001-0101': //E-001-0101 : ログイントークンが無効、または空
      return 'ログイントークンが無効です。';
    case 'E-001-0100': //E-001-0100 : ログインしていない
      return 'ログインしていないです。';
    case 'E-001-0002': //E-001-0002 : 何かが見つからない
      return '存在していないです。';
    case 'E-001-0003': //E-001-0003 : 許可されていない処理
      return '許可されていない行為です。';
    case 'E-001-1000': //E-001-1000 : 値が範囲外
      return '値が範囲外です。';
    case 'E-001-1001': //E-001-1001 : 処理対象として指定されたデータが既に処理済みである
      return 'すでに実行済みです。';
    case 'E-001-0001': //E-001-0001 : 予期せぬエラー
      return '予期せぬエラーです。';
    //UserError
    case 'E-002-0014': // E-002-0014 : ニックネームが重複しています
      return '既に存在するニックネームです。';
    case 'E-002-0011': // E-002-0011 : Emailが未認証
      return 'Emailが未認証';
    case 'E-002-0012': // E-002-0012 : ニックネームが空です
      return 'ニックネームが空です';
    case 'E-002-0001': // E-002-0001 : 既に使用されているメールアドレス
      return '既に使用されているメールアドレス';
    case ' E-002-0004': // E-002-0004 : 既に使用されているユーザー名
      return '既に使用されているユーザー名';
    case 'E-002-0009 ': // E-002-0009 : 無効な本登録用トークン
      return '無効な本登録用トークン';
    case 'E-002-0007': // E-002-0007 : 無効なリクエスト
      return '無効なリクエスト';
    case 'E-002-0006': // E-002-0006 : 無効な処理対象ユーザー
      return '無効な処理対象ユーザー';
    case 'E-002-0013 ': // E-002-0013 : 対象ユーザーはスカウトを拒否している
      return '対象ユーザーはスカウトを拒否している';
    case 'E-002-0008': // E-002-0008 : 不正なリクエスト
      return '不正なリクエスト';
    case 'E-002-0010': // E-002-0010 : 本登録済みユーザー
      return '本登録済みユーザー';
    case 'E-002-0005': // E-002-0005 : 指定されたユーザーが存在しない
      return '指定されたユーザーが存在しない';
    //ChallengeError
    case 'E-003-0001': //E-003-0001 : 指定されたレジュメが存在しない
      return '指定されたレジュメが存在しない';
    //ResumeError
    case 'E-004-0001': //E-004-0001 : 指定されたチャレンジが存在しない
      return '指定されたチャレンジが存在しない';
    //OrganizationError
    case 'E-005-0003': //E-005-0003 : 既に使用されている組織名
      return '既に使用されている組織名';
    case 'E-005-0001': //E-005-0001 : 指定された組織が存在しない
      return '指定された組織が存在しない';
    case 'E-005-0002': //E-005-0002 : ユーザーの所属する組織が存在しない(多くの場合でサーバー側のデータ不整合が原因)
      return 'ユーザーの所属する組織が存在しない';
    //ChatError
    case 'E-006-0004': //E-006-0004 : チャットメッセージが空
      return 'チャットメッセージが空';
    case 'E-006-0001': //E-006-0001 : 作成しようとしたチャットルームと同じパラメータのチャットルームがすでに存在している
      return '作成しようとしたチャットルームと同じパラメータのチャットルームがすでに存在している';
    case 'E-006-0002': //E-006-0002 : 対象としたチャットルームが存在していない
      return '対象としたチャットルームが存在していない';
    case 'E-006-0003': //E-006-0003 : 対象としたチャットルームの参加者ではない
      return '対象としたチャットルームの参加者ではない';
    //FileError
    case 'E-007-0001': //E-007-0001 : ファイルサイズが定められた範囲を超えている
      return 'ファイルサイズが定められた範囲を超えている';
    default:
      return error;
  }
}

const DAY = 24 * 60 * 60 * 1000;
export const milliSecToDays = (x) => parseFloat((x / DAY).toFixed(1));

const YEAR = 365;
export const daysToYears = (x) => parseFloat((x / YEAR).toFixed(1));

const MONTH = 30;
export const daysToMonthes = (x) => parseFloat((x / MONTH).toFixed(1));

export const buildPeriodText = (days, callback) => {
  let val;
  let unit;
  if (days > YEAR / 2) {
    val = daysToYears(days);
    unit = '年';
  } else if (days > MONTH / 2) {
    val = daysToMonthes(days);
    unit = '月';
  } else {
    val = days;
    unit = '日';
  }
  if (callback) {
    return callback(val, unit);
  }
  return `${val}${unit}`;
};

const CURRENT_DATE = new Date().toISOString().slice(0, 10);

export const splitDateTime = (beforeStr) => {
  return beforeStr.split('T');
};

export const getDaysBetween = (beforeStr) => {
  const afterStr = splitDateTime(beforeStr);
  const UPDATE_DATE = afterStr[0];

  //MEMO(aida) 最終更新日時が当日の場合に時間を返す
  if (UPDATE_DATE === CURRENT_DATE) {
    const lastUpdatedDt = new Date(beforeStr);
    //MEMO(aida) 桁数をとるために文字列に変換
    const hours = `${lastUpdatedDt.getHours()}`;
    const minuets = `${lastUpdatedDt.getMinutes()}`;
    const displayHours = hours.length === 1 ? `0${hours}` : hours;
    const displayMinuets = minuets.length === 1 ? `0${minuets}` : minuets;
    return `${displayHours}:${displayMinuets}`;
  }

  const differenceMs = Math.round(Math.abs((new Date(CURRENT_DATE) - new Date(UPDATE_DATE)) / DAY));
  const pastDaySuffix = differenceMs > 1 ? 'days ago' : 'day ago';
  return differenceMs > MONTH
    ? UPDATE_DATE.replace(/-/gi, '/')
    : `${differenceMs} ${pastDaySuffix}`;
};

export function getBreadcrumbsPath(selectType, nickname, myNickname) {
  switch (selectType) {
    case 'search':
      return `/search`;
    case nickname:
      return `/user/${nickname}`;
    case 'resume':
    case 'challenge':
    case 'profile':
    case 'chat':
    case 'followers':
    case 'following':
      return `/user/${nickname}/${selectType}`;
    default:
      return `/user/${myNickname}`;
  }
}

export function getTextJP(selectType, nickname) {
  switch (selectType) {
    case nickname:
      return nickname;
    case 'resume':
      return '経歴';
    case 'challenge':
      return 'チャレンジ';
    case 'profile':
      return 'プロフィール編集';
    case 'chat':
      return 'チャット';
    case 'followers':
      return 'フォロワー一覧';
    case 'following':
      return 'フォロー一覧';
    case 'search':
      const searchParams = new URLSearchParams(window.location.search);
      const queryString = searchParams.get('q') || '';
      return queryString;
    default:
      return 'TOP';
  }
}

export const getPathArray = (url, nickname, myNickname) => {
  const pathSplit = url.split('/');
  // search画面以外
  const items = pathSplit !== 2 ? pathSplit.slice(1, pathSplit.length) : pathSplit;

  const formattedItems =
    nickname === myNickname
      ? items
          .filter((item) => item !== myNickname)
          .map((value) => ({
            text: getTextJP(value, nickname),
            path: getBreadcrumbsPath(value, nickname, myNickname)
          }))
      : items.map((value) => ({
          text: getTextJP(value, nickname),
          path: getBreadcrumbsPath(value, nickname, myNickname)
        }));

  return formattedItems;
};

// object Propertyのbooleanが全部check
export const checkObjAllFalse = (obj) => {
  for (const o in obj) {
    if (obj[o]) return true;
  }
  return false;
};

// textがなかったり、空きスペースが存在する場合
export const isEmptyOrSpaces = (str) => {
  if (str.trim() == '' || str.trim().length == 0) {
    return false;
  }
  return true;
};
/**
 * Get the URL parameter value
 *
 * @param  name {string} パラメータのキー文字列
 * @return  url {url} 対象のURL文字列（任意）
 */
export function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

export const getTagCategory = (categoryName) =>
  Object.keys(constants.TAG_CATEGORIES).find(
    (key) => constants.TAG_CATEGORIES[key].name.toUpperCase() == categoryName.toUpperCase()
  );

//オブジェクト内に空文字が存在するかをチェックするための関数
export const checkEmptyStringInObject = (object) => {
  return !!Object.keys(object).filter((value) => object[value] === '').length;
};

/**
 * 英語の数字区分
 *
 * @param  text {string}
 * @return  regType {boolean}
 */
export const checkCharacterType = (text) => {
  const regType = /^[A-Za-z0-9+]*$/; // 英語

  return regType.test(text);
};

/**
 * パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、記号の全てを含んだこと確認
 *
 * @param  password {string}
 * @return {boolean}
 */
export function isValidatePassword(password) {
  const pattern = new RegExp(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/);

  return !pattern.test(password);
}

// dateStringsをYYYYMMDDの形式に変換する
export const toYYYYMMDD = (dateStrings) => {
  const date = new Date(dateStrings);
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();
  return `${yyyy}/${mm}/${dd}`;
};

//MEMO(aida) DateオブジェクトをgraphqlのDate型の形式に変換する関数
export const formatDateToGQLDateType = (date) => {
  if (typeof date === 'string') return date;
  const YYYY = date.getFullYear();
  const MM = date.getMonth() + 1;
  const formatedMM = `${MM}`.length === 1 ? `0${MM}` : MM;
  const DD = date.getDate();
  const formatedDD = `${DD}`.length === 1 ? `0${DD}` : DD;
  return `${YYYY}-${formatedMM}-${formatedDD}`;
};
