import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export default styled.span`
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
  border-radius: 20px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding: 0 10px;
`;
