import React from 'react';
import { ExperienceTag, JobTag } from '@meettry/company/component/atoms/tags';

const experienceTagsItem: Array<{ lang: string; year: number }> = [
  { lang: 'Python2.x', year: 5.5 },
  { lang: 'Java', year: 2.4 },
  { lang: 'MySQL', year: 5.5 },
  { lang: 'React2x', year: 8.2 },
  { lang: 'JavaScript', year: 8 },
  { lang: 'Python2.x', year: 5.5 },
  { lang: 'Java', year: 2.4 },
  { lang: 'Python', year: 3.5 },
  { lang: 'React2x', year: 8.2 },
  { lang: 'MySQL', year: 5.5 }
];

export const SkillTagArea: React.FC = () => {
  return (
    <div className="skillbox-experience-tags">
      {experienceTagsItem.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <ExperienceTag item={item} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

const jobTagsItem = [
  'プロジェクトマネジメント',
  '品質管理',
  '10万/秒アクセス',
  'アーキテクチャ',
  'フルスタック',
  'ソーシャルゲーム',
  '経営管理',
  '0→1',
  '100名規模の開発体制'
];

export const JobTagArea: React.FC = () => {
  return (
    <div className="skillbox-job-tags">
      {jobTagsItem.map((item, i) => {
        return (
          <React.Fragment key={i}>
            <JobTag item={item} />
          </React.Fragment>
        );
      })}
    </div>
  );
};
