import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import { ChallengeType } from '@meettry/ui-components/types/userPage';
import ChallengeItem from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeItem';
import {
  ChallengeEdit,
  ChallengeHistoryEdit
} from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeForm';
import { ChallengePinNotation } from '@meettry/ui-components/components/organisms/UserPage/Overview/OverviewParts/PinNotation';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import { NoItemNoticeBox } from '@meettry/ui-components/components/organisms/NoticeBox';
import { useEngineer, useChallenge } from '@meettry/ui-components/hooks/useUserPage';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import {
  DELETE_CHALLENGE,
  UPDATE_CHALLENGE_PINNED,
  UPDATE_CHALLENGE_VISIBLE
} from '@meettry/ui-components/queries/user_page';
import { ListAnimation } from '@meettry/ui-components/utils/animation';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

const ChallengeParts = () => {
  const { state, isOwner } = useEngineer();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { challengeList } = state;
  const isPinnedChallengeList = challengeList.items.filter((item) => item.isPinned);
  const { updateChallenge, removeChallenge, pinChallenge, visibleChallenge } = useChallenge();
  const { showDeleteConfirmationDialog, closeDialog } = useDialog();
  const { startLoading, endLoading } = useLoading();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const [isEditMode, setIsEditMode] = useState(false);
  const [isHistoryEditMode, setIsHistoryEditMode] = useState(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  const [mutateDeleteChallenge, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_CHALLENGE,
    {
      onCompleted: ({ deleteChallenge }) => {
        if (!deleteChallenge) return;
        removeChallenge(deleteChallenge.id);
        endLoading();
        showSuccessPopup('チャレンジを削除しました');
      }
    }
  );

  const [pinMutation, { loading: pinLoading, error: pinError }] = useMutation(
    UPDATE_CHALLENGE_PINNED,
    {
      onCompleted: ({ updateChallenge }) => {
        if (!updateChallenge) return;
        const { challenge } = updateChallenge;
        pinChallenge(challenge);
        endLoading();
        showSuccessPopup('チャレンジを更新しました');
      }
    }
  );

  const [visibleMutation, { loading: visibleLoading, error: visibleError }] = useMutation(
    UPDATE_CHALLENGE_VISIBLE,
    {
      onCompleted: ({ updateChallenge }) => {
        if (!updateChallenge) return;
        const { challenge } = updateChallenge;
        visibleChallenge(challenge);
        endLoading();
        showSuccessPopup('チャレンジを更新しました');
      }
    }
  );

  //MEMO(aida) チャレンジ更新系のエラーハンドリング。onErrorが動かないのでuseEffectでErrorStateを監視する
  useEffect(() => {
    const isError = deleteError || pinError || visibleError;
    if (!isError) return;
    endLoading();
    if (deleteError) showErrorPopup('チャレンジを削除できませんでした');
    if (pinError) showErrorPopup('チャレンジを更新できませんでした');
    if (visibleError) showErrorPopup('チャレンジを更新できませんでした');
  }, [deleteError, pinError, visibleError]);

  const onUpdateChallenge = (challenge: ChallengeType) => {
    // MEMO(aida) 編集モードを初期状態に戻す
    updateChallenge(challenge);
    setIsHistoryEditMode(false);
    setIsEditMode(false);
    setEditingItemId(null);
  };

  const onUpdateChallengePinned = (challenge: ChallengeType) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault();
    if (pinLoading) return;
    startLoading('チャレンジを更新しています。');
    pinMutation({
      variables: {
        id: challenge.id,
        isPinned: !challenge.isPinned
      }
    });
  };

  const onUpdateChallengeVisible = (challenge: ChallengeType) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault();
    if (visibleLoading) return;
    startLoading('チャレンジを更新しています。');
    visibleMutation({
      variables: {
        id: challenge.id,
        isVisible: !challenge.isVisible
      }
    });
  };

  const onDeleteChallenge = (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const deleteChallenge = () => {
      if (deleteLoading) return;
      closeDialog();
      startLoading('チャレンジを削除しています。');
      mutateDeleteChallenge({
        variables: { input: { id: challenge.id } }
      });
    };
    showDeleteConfirmationDialog(
      [
        `${challenge.title}のチャレンジ詳細を削除します。`,
        '削除されたデータは、復元できませんがよろしいでしょうか？'
      ],
      deleteChallenge,
      closeDialog
    );
  };

  const onHistoryEdit = (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setEditingItemId(challenge.id);
    setIsHistoryEditMode(true);
  };

  const onEditChallenge = (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setEditingItemId(challenge.id);
    setIsEditMode(true);
  };
  const onCreateChallenge = () => {
    history.push(`${pathname}/challenge`, { addMode: true });
  };
  const toChallengePage = () => {
    history.push(`${pathname}/challenge`);
  };

  const onClickChallengeTitle = (id: string) => () => {
    history.push(`${pathname}/challenge`, { selectedChallengeId: id });
  };

  const onCloseEdit = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    closeDialog();
  };

  const onCloseHistoryEdit = () => {
    setIsHistoryEditMode(false);
    setEditingItemId(null);
  };

  return (
    <>
      {isOwner && (
        <StyledCreateButton>
          <LineButton size={STYLE_TYPE.SIZE.SMALL} onClick={onCreateChallenge}>
            <StyledIcon iconColor="primary" iconName="plus" />
            新規作成
          </LineButton>
        </StyledCreateButton>
      )}
      {!isPinnedChallengeList.length &&
        (isOwner ? (
          <StyledCautionWrapper>
            <ChallengePinNotation onClick={toChallengePage} />
          </StyledCautionWrapper>
        ) : (
          <NoItemNoticeBox contents={['ピン留めされたチャレンジはまだありません']} />
        ))}
      {!!isPinnedChallengeList.length && (
        <StyledChallengeList>
          {isPinnedChallengeList.map((challenge) => {
            const isItemHistoryEditMode = isHistoryEditMode && editingItemId === challenge.id;
            const isItemEditMode = isEditMode && editingItemId === challenge.id;
            return (
              <CSSTransition
                key={challenge.id}
                timeout={ListAnimation.timeout}
                classNames={ListAnimation.classNames}
              >
                <StyledChallengeItemWrapper>
                  {isItemEditMode ? (
                    <ChallengeEdit
                      item={challenge}
                      onUpdate={onUpdateChallenge}
                      onClose={onCloseEdit}
                    />
                  ) : (
                    <ChallengeItem
                      onClickTitle={onClickChallengeTitle(challenge.id)}
                      challenge={challenge}
                      isOwner={isOwner}
                      onHistoryEdit={onHistoryEdit}
                      onEdit={onEditChallenge}
                      onVisible={onUpdateChallengeVisible}
                      onPinned={onUpdateChallengePinned}
                      onDelete={onDeleteChallenge}
                      isOverview={true}
                    />
                  )}
                  {isItemHistoryEditMode && (
                    <ChallengeHistoryEdit
                      item={challenge}
                      onUpdate={onUpdateChallenge}
                      onClose={onCloseHistoryEdit}
                    />
                  )}
                </StyledChallengeItemWrapper>
              </CSSTransition>
            );
          })}
        </StyledChallengeList>
      )}
    </>
  );
};

export default ChallengeParts;

const StyledCreateButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const StyledChallengeList = styled(TransitionGroup)`
  padding-top: 20px;
`;

const StyledChallengeItemWrapper = styled(ListAnimation.animation)`
  :not(:first-child) {
    margin-top: 20px;
  }
`;

const StyledCautionWrapper = styled.div`
  margin-top: 20px;
`;
