
// eslint-disable-next-line import/prefer-default-export
export const makeHandleSubmit = (input, mutation) => () => {
  const inputData = { ...input };
  // eslint-disable-next-line no-underscore-dangle
  delete inputData.__typename;
  // delete inputData.tags;
  delete inputData.inputTag;
  delete inputData.created;
  // eslint-disable-next-line array-callback-return
  inputData.tags.map((item) => {
    // eslint-disable-next-line no-underscore-dangle
  delete item.__typename;
  delete item.techStackId;
  delete item.relations;
  });
  // console.log('level 3 last');
  // console.log(inputData);
  mutation({ variables: { input: inputData } });
};
