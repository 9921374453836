import React from 'react';
import styled from 'styled-components';
import { ButtonType } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const Button: React.FC<ButtonType> = (props) => {
  const {
    onClick,
    width,
    size = STYLE_TYPE.SIZE.MEDIUM,
    styleType,
    children,
    disabled,
    margin,
    addStyle,
    type = 'button'
  } = props;

  const onClickHandle = (e: MouseEvent) => {
    if (type !== 'submit') e.preventDefault();
    e.stopPropagation();
    typeof onClick === 'function' && onClick();
  };

  return (
    <StyledButton
      onClick={onClickHandle}
      width={width}
      styleType={styleType}
      size={size}
      disabled={disabled}
      margin={margin}
      style={addStyle}
    >
      {children}
    </StyledButton>
  );
};

const getStyleType = (props: ButtonType) => {
  switch (props.styleType) {
    case STYLE_TYPE.ROLE.SECONDARY:
      return `
                background-color: ${COLOR_DEFINITIONS.BG.WHITE};
                color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
                border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
                &:hover{
                    background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
                }
            `;
    case STYLE_TYPE.ROLE.THIRDLY:
      return `
                color: ${COLOR_DEFINITIONS.TEXT.MAIN};
                border: 1px solid ${COLOR_DEFINITIONS.TEXT.SUB};
                background-color: ${COLOR_DEFINITIONS.BG.WHITE};
                &:hover{
                    background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
                }
            `;
    case STYLE_TYPE.ROLE.GRAY:
      return `
                color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
                border: 1px solid ${COLOR_DEFINITIONS.TEXT.SUB};
                background-color: ${COLOR_DEFINITIONS.BG.WHITE};
                &:hover{
                    background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
                }
            `;
    case STYLE_TYPE.ROLE.WHITE:
      return `
                color: ${COLOR_DEFINITIONS.TEXT.MAIN};
                background-color: ${COLOR_DEFINITIONS.BG.WHITE};
                &:hover{
                    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
                    background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
                }
            `;
    case STYLE_TYPE.ROLE.ROUND:
      return `
              border-radius: 50vh;
            `;
    default:
      return;
  }
};

const getStyleSize = (props: ButtonType) => {
  switch (props.size) {
    case STYLE_TYPE.SIZE.SMALL:
      return `
                padding: 8px 10px;
                font-size: 14px;
            `;
    case STYLE_TYPE.SIZE.MEDIUM:
      return `
                padding: 10px 20px;
                font-size: 14px;
            `;
    case STYLE_TYPE.SIZE.LARGE:
      return `
                padding: 20px 30px;
            `;
    default:
      return;
  }
};

const getStyleDisabled = (props: ButtonType) => {
  if (props.disabled) {
    return `
        color: ${COLOR_DEFINITIONS.TEXT.SUB};
        background: ${COLOR_DEFINITIONS.LINE.SUB};
        border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
        pointer-events: none;
    `;
  }
};

const StyledButton = styled.button<ButtonType>`
  width: ${(props) => (props.width ? `${props.width}px` : '100%')};
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  color: ${COLOR_DEFINITIONS.TEXT.WHITE};
  text-align: center;
  padding: 20px 0;
  border-radius: 4px;
  transition: all 0.2s;
  border: 1px solid transparent;
  line-height: 1;
  cursor: pointer;
  ${(props) =>
    props.margin
      ? `margin:${props.margin
          .map((margin) => (margin === 'auto' || margin === 0 ? margin : margin + 'px'))
          .join(' ')};`
      : null}
  &:hover {
    background-color: ${COLOR_DEFINITIONS.HOVER.MAIN};
  }
  &:focus {
    outline: none;
  }
  ${(props) => getStyleType(props)};
  ${(props) => getStyleSize(props)};
  ${(props) => getStyleDisabled(props)};
`;

export default Button;
