import { generateMedia } from 'styled-media-query';

export const breakPoint = 960;
export const mediaQuery = generateMedia({
  se: '320px',
  sp: '414px',
  //ここをsp/tabletの境として定義,
  minTablet: '540px',
  tablet: '749px',
  breakPoint: `${breakPoint}px`,
  breakPointPC: `${breakPoint + 1}px`,
  desktop: '1012px',
  basePCWidth: '1280px',
  overPCSize: '1460px'
});
