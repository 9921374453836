import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Formatter from '@meettry/company/utils/format';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_PAYMENT } from '@meettry/ui-components/queries/corporateInfo';
import { LineTag } from '@meettry/ui-components/components/atoms/Tag';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { BillDestination } from '@meettry/ui-components/components/organisms/BillDestination';
import { BillList } from '@meettry/ui-components/components/organisms/BillList';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';
import EnterpriseSettingBase from '@meettry/ui-components/components/organisms/EnterpriseSettingBase';
import {
  StyledSettingsContainer,
  StyledSettingsContentsTitle
} from '@meettry/engineer/components/pages/EnterpriseSettingCompany';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const tabData = [
  { text: '請求書一覧', type: 'bill-list', component: <BillList></BillList> },
  {
    text: '請求書送付先',
    type: 'sending-bill-destination',
    component: <BillDestination></BillDestination>
  }
];

const Price: React.FC = () => {
  const date = new Date();
  const year = date.getFullYear();
  const month = ('0' + (date.getMonth() + 1)).slice(-2);
  const yearMonth = Number(`${year}${month}`);

  const [getPayment, { data, loading }] = useLazyQuery(GET_PAYMENT, { variables: { yearMonth } });

  useEffect(() => {
    getPayment();
  }, []);

  const billing = useMemo(() => data?.loginSession?.user?.organization?.payment?.billing ?? null, [
    data
  ]);

  const currentPlan = useMemo(
    () => data?.loginSession?.user?.organization?.currentPlan?.name ?? null,
    [data]
  );

  const breakDown = [
    {
      type: 'スカウト課金',
      details:
        !!billing?.scoutUnitPrice || billing?.scoutUnitPrice === 0
          ? [
              `${billing.scoutUnitPrice.toLocaleString()}円×${
                billing?.paidScoutCount
              } 課金スカウト`,
              `( 総スカウト：${billing?.totalScoutCount} ,無料スカウト：${billing?.freeScoutCount} )`
            ]
          : null,
      price: billing?.scoutUnitPrice * billing?.paidScoutCount
    },
    {
      type: '月額利用料',
      details: !!currentPlan ? [`${currentPlan}プラン`] : null,
      price: billing?.isFreeMonthlyPrice === 1 ? 0 : billing?.monthlyPrice ?? null
    }
  ];

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <>
          <StyledEstimatePriceWrapper>
            <StyledEstimatePriceLabel>請求予定額（税抜き）</StyledEstimatePriceLabel>
            <StyledEstimatePrice>
              {!!billing?.subtotalAmount || billing?.subtotalAmount === 0
                ? Formatter.insertComma(billing.subtotalAmount, 'yen')
                : null}
            </StyledEstimatePrice>
          </StyledEstimatePriceWrapper>
          <StyledBreakDown>
            <tbody>
              {breakDown.map((item, i) => (
                <StyledBreakDownTableRow key={i}>
                  <StyledBreakDownTableHeading>{item.type}</StyledBreakDownTableHeading>
                  <StyledBreakDownTableDetails>
                    {!!item.details
                      ? item.details.map((detail, j) => (
                          <StyledBreakDownTableDetail key={j}>
                            {detail}
                            {item.type === '月額利用料' && billing?.isFreeMonthlyPrice === 1 ? (
                              <StyledLineTag>無料キャンペーン中</StyledLineTag>
                            ) : null}
                          </StyledBreakDownTableDetail>
                        ))
                      : null}
                  </StyledBreakDownTableDetails>
                  <StyledBreakDownTablePrice>
                    {!!item.price || item.price === 0 ? Formatter.insertComma(item.price) : null}
                  </StyledBreakDownTablePrice>
                </StyledBreakDownTableRow>
              ))}
            </tbody>
          </StyledBreakDown>
        </>
      )}
    </>
  );
};

const EnterpriseSettingPayment: React.FC = () => {
  const [currentTabType, setCurrentTabType] = useState(tabData[0].type);
  const findBillContent = tabData.find((item) => currentTabType === item.type);
  const price = findBillContent && findBillContent.type === 'bill-list' ? <Price></Price> : null;
  return (
    <EnterpriseSettingBase>
      <StyledSettingsContainer>
        {price}
        <StyledSettingsContentsTitleBill>請求書・決済</StyledSettingsContentsTitleBill>
        <StyledBillSettlementContents>
          <ChangeTab
            active={currentTabType}
            setCurrent={setCurrentTabType}
            tab={tabData}
            isBottom={true}
          />
          {!!findBillContent ? findBillContent.component : null}
        </StyledBillSettlementContents>
      </StyledSettingsContainer>
    </EnterpriseSettingBase>
  );
};

export default EnterpriseSettingPayment;

/* --------------------------------
  EnterpriseSettingPaymentのスタイル
---------------------------------*/
const StyledBillSettlementContents = styled.div`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
`;

const StyledSettingsContentsTitleBill = styled(StyledSettingsContentsTitle)`
  margin-top: 50px;
`;

/* --------------------------------
  Priceのスタイル
---------------------------------*/
const StyledEstimatePriceWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  width: 100%;
  padding: 29px;
  height: 90px;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN_LIGHT};
`;

const StyledEstimatePriceLabel = styled.span`
  display: block;
  font-size: 14px;
  font-weight: normal;
`;

const StyledEstimatePrice = styled.span`
  display: flex;
  align-items: center;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 32px;
  font-weight: bold;
`;

const StyledBreakDown = styled.table`
  width: 100%;
  margin-top: 10px;
`;

const StyledBreakDownTableRow = styled.tr`
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledBreakDownTableData = styled.td`
  padding: 23px 30px;
`;

const StyledBreakDownTableHeading = styled(StyledBreakDownTableData)`
  width: 260px;
  box-sizing: border-box;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  font-size: 14px;
`;

const StyledBreakDownTableDetails = styled(StyledBreakDownTableData)`
  font-size: 14px;
`;

const StyledBreakDownTableDetail = styled.p`
  line-height: 1.8;
`;

const StyledBreakDownTablePrice = styled(StyledBreakDownTableData)`
  text-align: right;
  vertical-align: middle;
`;

const StyledLineTag = styled(LineTag)`
  margin-left: 16px;
`;
