import React from 'react';
import styled from 'styled-components';
import { SelectBox } from '@meettry/engineer/components/atoms/Input';
import { options } from '@meettry/engineer/components/organisms/EditProfile/constant';
import { SELECTBOX } from '@meettry/engineer/utils/Constant';

export const InputBirthday = (props) => {
  const { userBirth, handleChangeUserBirth, error } = props;
  return (
    <StyledInputBirthday>
      <SelectBox
        onChange={handleChangeUserBirth('year')}
        selectedValue={userBirth.year}
        placeholder="選択"
        values={options.year}
        size={SELECTBOX.SIZE.SMALL}
        error={error}
      />
      <StyledBirthTitle>年</StyledBirthTitle>
      <SelectBox
        onChange={handleChangeUserBirth('month')}
        selectedValue={userBirth.month}
        placeholder="選択"
        values={options.month}
        size={SELECTBOX.SIZE.SMALL}
        error={error}
      />
      <StyledBirthTitle>月</StyledBirthTitle>
      <SelectBox
        onChange={handleChangeUserBirth('day')}
        selectedValue={userBirth.day}
        placeholder="選択"
        values={options.day}
        size={SELECTBOX.SIZE.SMALL}
        error={error}
      />
      <StyledBirthTitle>日</StyledBirthTitle>
    </StyledInputBirthday>
  );
};

const StyledInputBirthday = styled.div`
  display: flex;
  align-items: center;
`;

const StyledBirthTitle = styled.div`
  font-size: 16px;
  * + & {
    margin: 0 15px;
  }
`;
