import React, { createContext, useReducer, Dispatch } from 'react';
import uid from 'uuid';
import { ProviderComponent } from '@meettry/ui-components/types/context';

// actions
const SHOW_DIALOG = 'SHOW_DIALOG';
const HIDE_DIALOG = 'HIDE_DIALOG';
const HIDE_ALL_DIALOG = 'HIDE_ALL_DIALOG';

export const showDialog = (dialogType: string, payload = {}) => ({
  type: SHOW_DIALOG,
  payload: {
    ...payload,
    id: uid(),
    // MEMO(aida) ここに表示させたいダイアログの種類を指定する。ここの種類でpayloadの値が変わってくる
    dialogType
  }
});

export const hideDialog = () => ({
  type: HIDE_DIALOG,
  payload: {}
});

export const hideAllDialog = () => ({
  type: HIDE_ALL_DIALOG,
  payload: {}
});

const initState = () => ({
  dialogs: []
});

const initialState = initState();

//reducer
const dialogReducer = (state: DialogsState = initialState, action: DialogsAction) => {
  switch (action.type) {
    case SHOW_DIALOG:
      return { ...state, dialogs: [...state.dialogs, action.payload] };
    case HIDE_DIALOG:
      return { ...state, dialogs: state.dialogs.slice(0, -1) };
    case HIDE_ALL_DIALOG:
      return initialState;
    default:
      return state;
  }
};

type DialogState = {
  id?: string | null;
  dialogType?: string;
  texts?: Array<string>;
  onAccept?: () => void;
  onDecline?: () => void;
  onOk?: () => void;
  onCancel?: () => void;
  onRegisterVisible?: () => void;
  onRegisterInvisible?: () => void;
};

type DialogsState = {
  dialogs: Array<DialogState>;
};

type Action<T, U> = { type: T; payload: U };

type DialogsOption = DialogState;

type DialogsAction = Action<string, DialogsOption>;

type DialogsDispatch = Dispatch<DialogsAction>;
//context
type DialogsStore = {
  state: DialogsState;
  dispatch: DialogsDispatch;
};
const defaultStore: DialogsStore = {
  state: initialState,
  dispatch: () => initialState
};
// context
export const DialogContext = createContext<DialogsStore>(defaultStore);

// Provider
export const DialogProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(dialogReducer, initialState);
  return <DialogContext.Provider value={{ state, dispatch }}>{children}</DialogContext.Provider>;
};
