import React, { useEffect, useState } from 'react';
import { match } from 'react-router';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { find, get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { EngineerType, MatchParams } from '@meettry/ui-components/types/userPage';
import TabLink from '@meettry/ui-components/components/molecules/TabLink';
import UserCard from '@meettry/ui-components/components/organisms/UserPage/Users/UserCard';
import Pagination from '@meettry/ui-components/components/organisms/UserPage/Users/Pagination';
import {
  GET_USERS_FOLLOWEES,
  GET_USERS_FOLLOWERS,
  GET_ENGINEER_ID,
  GET_LOGIN_USER,
  FOLLOW_USER,
  UNFOLLOW_USER
} from '@meettry/ui-components/components/organisms/UserPage/Users/queries';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const queries = [
  { query: GET_USERS_FOLLOWERS, target: 'followers', path: 'followers' },
  { query: GET_USERS_FOLLOWEES, target: 'followees', path: 'following' }
];

type UsersType = {
  match: match<MatchParams>;
  location: any;
  history: any;
  isOwner: boolean;
};

const Users: React.FC<UsersType> = (props) => {
  const { match, location, history, isOwner } = props;
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const { startLoading, endLoading } = useLoading();
  const [searchQuery, setSearchQuery] = useState('');
  const [targetNickname, setTargetNickname] = useState('');
  const [loginUserFollowee, setLoginUserFollowee] = useState<Array<string>>([]);
  const [isSearch, setIsSearch] = useState(false);

  const { nickname, userstype } = match.params;
  const currentUserstype = userstype ? userstype : 'followers';
  const currentQuery = find(queries, (o) => o.path === currentUserstype);
  const params = new URLSearchParams(location.search);
  const rows = parseInt(params.get('r') || '10', 10);
  const page = parseInt(params.get('p') || '1', 10);
  params.set('r', rows.toString());
  params.set('p', page.toString());

  useEffect(() => {
    isSearch && setIsSearch(false);
    setSearchQuery('');
  }, [userstype]);

  const { data: targetEngineer, loading: targetLoading, error: targetError } = useQuery(
    GET_ENGINEER_ID,
    {
      variables: {
        nicknames: [nickname]
      }
    }
  );
  const { data: dataUser, loading: loginUserLoading, error: loginUserError } = useQuery(
    GET_LOGIN_USER,
    {
      fetchPolicy: 'network-only',
      onCompleted: ({ loginSession }) => {
        if (!loginSession) return;
        setLoginUserFollowee(loginSession.user.followees.map((val: EngineerType) => val.nickname));
      }
    }
  );

  const [doUser, { data, loading: userLoading, error: userError }] = useLazyQuery(
    get(currentQuery ? currentQuery : queries[0], 'query'),
    {
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        if (!data.searchFor) {
          if (currentUserstype === 'followers')
            showErrorPopup('フォロワー一覧を読み込めませんでした');
          if (currentUserstype === 'followees')
            showErrorPopup('フォロー中一覧を読み込めませんでした');
          return;
        }
      }
    }
  );
  const [followQuery, { loading: followLoading, error: followError }] = useMutation(FOLLOW_USER, {
    onCompleted: ({ followUser }) => {
      if (!followUser) return;
      if (!followUser.ok) {
        showErrorPopup(`${targetNickname}をフォローできませんでした`);
        return;
      }
      endLoading();
      showSuccessPopup(`${targetNickname}をフォローしました`);
      setLoginUserFollowee([...loginUserFollowee, targetNickname]);
      setTargetNickname('');
    }
  });
  const [unfollowQuery, { loading: unfollowLoading, error: unfollowError }] = useMutation(
    UNFOLLOW_USER,
    {
      onCompleted: ({ unfollowUser }) => {
        if (!unfollowUser) return;
        if (!unfollowUser.ok) {
          showErrorPopup(`${targetNickname}をアンフォローできませんでした`);
          return;
        }
        endLoading();
        showSuccessPopup(`${targetNickname}をアンフォローしました`);
        const updatedUserFollowee = loginUserFollowee.filter(
          (nickname) => nickname !== targetNickname
        );
        setLoginUserFollowee([...updatedUserFollowee]);
        setTargetNickname('');
      }
    }
  );

  //MEMO(aida) 対象のユーザ取得に失敗した際のエラーハンドリング
  useEffect(() => {
    const isError = userError || loginUserError;
    if (!isError) return;
    if (currentUserstype === 'followers') showErrorPopup('フォロワー一覧を読み込めませんでした');
    if (currentUserstype === 'followees') showErrorPopup('フォロー中一覧を読み込めませんでした');
    if (loginUserError) showErrorPopup('ユーザを読み込めませんでした');
  }, [userError]);

  //MEMO(aida) フォロー・アンフォローのgraphqlエラーのエラーハンドリング
  useEffect(() => {
    const isError = followError || unfollowError;
    if (!isError) return;
    if (followError) showErrorPopup(`${targetNickname}をフォローできませんでした`);
    if (unfollowError) showErrorPopup(`${targetNickname}をアンフォローできませんでした`);
    setTargetNickname('');
  }, [unfollowError, followError]);

  const followButtonHandler = (id: string, followed: boolean, nickname: string) => {
    setTargetNickname(nickname);
    if (followed) {
      startLoading(`${nickname}のアンフォロー中...`);
      unfollowQuery({ variables: { id } });
      return;
    }
    startLoading(`${nickname}をフォロー中...`);
    followQuery({ variables: { id } });
  };

  const fetchItems = () => {
    const targetId = get(targetEngineer, 'users[0].id');
    targetId &&
      doUser({
        variables: {
          targetId,
          first: rows,
          offset: (page - 1) * rows,
          queryString: searchQuery
        }
      });
  };

  useEffect(() => {
    fetchItems();
  }, [page, userstype, targetLoading]);

  const onSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setIsSearch(true);
    fetchItems();
  };

  const res = get(data, `searchFor.${get(currentQuery, 'target')}`);

  const onChangeSearchWord = (value: string) => {
    setSearchQuery(value);
  };

  const handlePaginationClick = (
    e: React.MouseEvent<HTMLLIElement | HTMLDivElement>,
    tar: number
  ) => {
    e.preventDefault();
    const params = `p=${tar}`;
    history.push({
      ...location,
      pathnaem: `/user/${nickname}/${userstype}`,
      search: params
    });
    window.scrollTo(0, 0);
  };

  const linkList = [
    {
      to: `/user/${nickname}/followers`,
      name: 'followers',
      Content: () => <span>フォロワー</span>
    },
    { to: `/user/${nickname}/following`, name: 'following', Content: () => <span>フォロー中</span> }
  ];

  const users = get(res, 'results');
  const usersCount = get(res, 'count');

  return (
    <StyledUsersWrapper>
      <StyledUsersContainer>
        <StyledUserInner>
          <TabLink linkList={linkList} />
          <UserCard
            loginUser={get(dataUser, `loginSession`)}
            users={users}
            onSearch={onSearch}
            searchWord={searchQuery}
            onChangeSearchWord={onChangeSearchWord}
            isLoading={userLoading || loginUserLoading}
            type={currentUserstype}
            onClickFollow={followButtonHandler}
            loginUserFollowee={loginUserFollowee}
            isSearch={isSearch}
            isOwner={isOwner}
          />
          {usersCount && usersCount > 10 ? (
            <Pagination
              handleClick={handlePaginationClick}
              count={get(res, 'count')}
              showCount={10}
              offset={page}
            />
          ) : null}
        </StyledUserInner>
      </StyledUsersContainer>
    </StyledUsersWrapper>
  );
};

const StyledUsersWrapper = styled.div`
  flex: 1;
  background: ${COLOR_DEFINITIONS.BG.SUB};
`;

const StyledUsersContainer = styled.div`
  width: 100%;
  max-width: 1200px;
  min-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledUserInner = styled.div`
  width: 100%;
  max-width: 900px;
  margin-left: auto;
  margin-right: auto;
  padding-top: 38px;
  padding-bottom: 120px;
`;

export default withRouter(Users);
