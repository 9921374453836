import React, { useMemo } from 'react';
import styled from 'styled-components';
import { FormItem } from '@meettry/ui-components/components/molecules/Form';
import AdditionalTagInput from '@meettry/ui-components/components/organisms/Forms/AdditionalTagInput';
import { Textarea, TextField, Datepicker } from '@meettry/ui-components/components/atoms/Input';
import { TagType } from '@meettry/ui-components/types/userPage';

type ResumeDetailProps = {
  showTitle: boolean;
  onChangeTitle: (e: any) => void;
  onChangeCompany: (e: any) => void;
  onChangeStartData: (value: any) => void;
  onChangeEndDate: (value: any) => void;
  onChangeTags: (value: any) => void;
  onChangeComment: (e: any) => void;
  titleError: boolean;
  titleInputError: boolean;
  companyError: boolean;
  startDateError: boolean;
  title: string;
  company: string;
  startDate: Date | null;
  endDate: Date | null;
  dateRangeError: boolean;
  tags: Array<TagType>;
  comment: string;
  commentError: boolean;
};

export const ResumeDetail = (props: ResumeDetailProps) => {
  const {
    showTitle,
    onChangeTitle,
    onChangeCompany,
    onChangeStartData,
    onChangeEndDate,
    onChangeTags,
    onChangeComment,
    titleError,
    titleInputError,
    companyError,
    startDateError,
    dateRangeError,
    title,
    company,
    startDate,
    endDate,
    tags,
    comment,
    commentError
  } = props;

  const displayStartDate = useMemo(
    () => (typeof startDate === 'string' ? new Date(startDate) : startDate),
    [startDate]
  );
  const displayEndDate = useMemo(
    () => (typeof endDate === 'string' ? new Date(endDate) : endDate),
    [endDate]
  );

  const titleErrorText = useMemo(
    () => (titleError ? '入力してください' : titleInputError ? 'タイトルは50文字までです' : ''),
    [titleError, titleInputError]
  );

  const dateErrorText = useMemo(
    () => (dateRangeError ? '終了日は開始日よりも前の日付を入力ください' : '選択してください'),
    [dateRangeError]
  );

  return (
    <>
      {showTitle && <StyledFormTitle>経歴の概要</StyledFormTitle>}
      <FormItem
        label="タイトル"
        required={true}
        error={titleError || titleInputError}
        errorText={titleErrorText}
      >
        <TextField
          error={titleError || titleInputError}
          type="text"
          placeholder="全体へ公開されるので、特定の固有名詞にご注意ください"
          defaultValue={title}
          onChange={onChangeTitle}
        />
      </FormItem>
      <FormItem label="会社名" required={true} error={companyError} errorText="入力してください">
        <TextField
          error={companyError}
          type="text"
          placeholder="会社名を入力してください　全体へ公開はされません"
          onChange={onChangeCompany}
          defaultValue={company}
        />
      </FormItem>
      <FormItem
        label="期間"
        required={true}
        error={startDateError || dateRangeError}
        errorText={dateErrorText}
      >
        <StyledDatepickers>
          <Datepicker
            showYearDropdown
            selected={displayStartDate}
            error={startDateError || dateRangeError}
            onChange={onChangeStartData}
            placeholderText="2019/01/01"
            dateFormat="yyyy/MM/dd"
            maxDate={new Date()}
          />
          &nbsp;〜&nbsp;
          <Datepicker
            showYearDropdown
            selected={displayEndDate}
            onChange={onChangeEndDate}
            error={dateRangeError}
            placeholderText="2020/01/01"
            dateFormat="yyyy/MM/dd"
            minDate={displayStartDate}
          />
        </StyledDatepickers>
      </FormItem>
      <FormItem label="タグ">
        <AdditionalTagInput placeholder="タグを入力" value={tags} onChange={onChangeTags} />
      </FormItem>
      <FormItem label="コメント" error={commentError} errorText="コメントは400文字までです">
        <Textarea
          error={commentError}
          onChange={onChangeComment}
          placeholder="全体に公開されるので、特定の固有名詞にご注意ください(400文字以内)"
          defaultValue={comment}
        />
      </FormItem>
    </>
  );
};

export default ResumeDetail;

const StyledFormTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const StyledDatepickers = styled.div`
  display: flex;
  align-items: center;
`;
