import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import auth, { authError, useLoginUser } from '@meettry/ui-components/utils/auth';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { TextField } from '@meettry/engineer/components/atoms/Input';
import { FormItem } from '@meettry/engineer/components/molecules/Form';

/**
 * ChangeEmailForm
 * メールアドレス変更フォームコンポーネント
 */
const ChangeEmailForm = () => {
  const history = useHistory();
  const [newEmail, setNewEmail] = useState('');
  const [newEmailError, setNewEmailError] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [currentPasswordError, setCurrentPasswordError] = useState('');
  const [error, setError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    user: { nickname },
    isEnterprise
  } = useLoginUser();
  const { showNotificationDialog, showErrorDialog, closeAllDialog } = useDialog();

  // 現在のパスワード変更用
  const handleChangeCurrentPassword = (e) => {
    setCurrentPassword(e.target.value);
    if (currentPasswordError !== '') setCurrentPasswordError('');
  };

  // 新しいメールアドレス変更用 oncChange
  const handleChangeNewEmail = (e) => {
    setNewEmail(e.target.value);
    if (newEmailError !== '') setNewEmailError('');
  };

  // 新しいメールアドレスをsubmit
  const handleSubmit = (e) => {
    e.preventDefault();
    if (newEmail === '') {
      setNewEmailError('新しいメールアドレスを入力してください');
    } else if (currentPassword === '') {
      setCurrentPasswordError('現在のパスワードを入力してください');
    } else {
      setIsSubmitting(true);
      auth
        .reauthenticate(currentPassword)
        .then(() => {
          auth
            .updateEmail(newEmail)
            .then((_res) => {
              auth
                .sendEmailVerification(
                  // MEMO(nakayama): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
                  `${window.location.origin}${
                    isEnterprise ? `/enterprise/scout` : `/user/${nickname}`
                  }`
                )
                .then(
                  (__res) => {
                    showNotificationDialog(
                      [
                        'メールアドレス確認用のメールを送信しました。',
                        '内容をご確認頂きリンクをクリックして',
                        '承認を完了してください。'
                      ],
                      () => {
                        history.push('/login');
                        closeAllDialog();
                      }
                    );
                  },
                  () => {
                    setIsSubmitting(false);
                  }
                );
            })
            .catch((err) => {
              setIsSubmitting(false);
              const errorMsg = authError(err, 'change-email').split('\n');
              showErrorDialog(errorMsg, () => {
                closeAllDialog();
              });
            });
        })
        .catch((err) => {
          setIsSubmitting(false);
          const errorMsg = authError(err, 'change-email').split('\n');
          showErrorDialog(errorMsg, () => {
            closeAllDialog();
          });
        });
    }
  };

  return (
    <StyledForm onSubmit={handleSubmit}>
      <StyledFormHeader>
        <StyledFormTitle>メールアドレス変更</StyledFormTitle>
      </StyledFormHeader>
      <StyledFormBody>
        <StyledList>
          <FormItem
            label={'新しいメールアドレス'}
            error={newEmailError.length > 0}
            errorText={newEmailError}
          >
            <TextField
              type={'text'}
              onChange={handleChangeNewEmail}
              placeholder={'新しいメールアドレスを入力'}
              defaultValue={''}
              error={newEmailError.length > 0}
            />
          </FormItem>
          <FormItem
            label={'現在のパスワード'}
            error={currentPasswordError.length > 0}
            errorText={currentPasswordError}
          >
            <TextField
              type={'password'}
              onChange={handleChangeCurrentPassword}
              placeholder={'現在のパスワードを入力'}
              defaultValue={''}
              error={currentPasswordError.length > 0}
            />
          </FormItem>
        </StyledList>
      </StyledFormBody>
      <StyledButtonWrapper>
        <PrimaryButton size={STYLE_TYPE.SIZE.LARGE} type="submit" disabled={isSubmitting}>
          変更する
        </PrimaryButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};
export default ChangeEmailForm;

const StyledForm = styled.form`
  padding: 40px 50px;
  margin-bottom: 120px;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
`;

const StyledFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;
`;

const StyledFormTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;

const StyledFormBody = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  border-bottom: 1px solid #eeeeee;
`;

const StyledList = styled.ul`
  & > * + * {
    margin-top: 30px;
  }
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;
