import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import EditMenu from '@meettry/ui-components/components/atoms/EditMenu';
import { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';

type EditMenuComponentProps = {
  EditMenuList: Array<{
    icon?: IconPropsType;
    text?: string;
    onClick?: (e?: MouseEvent) => void;
  }>;
  toggleIcon: JSX.Element;
};

const EditMenuComponent: React.FC<EditMenuComponentProps> = (props) => {
  const { EditMenuList, toggleIcon } = props;
  const [isShow, toggleShow] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e) => {
    if (
      menuRef.current &&
      toggleRef.current &&
      !menuRef.current.contains(e.target) &&
      !toggleRef.current.contains(e.target)
    ) {
      toggleShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      <StyledEditToggle
        ref={toggleRef}
        onClick={(e) => {
          e.preventDefault();
          toggleShow(!isShow);
        }}
      >
        {toggleIcon}
      </StyledEditToggle>
      {<EditMenu menuRef={menuRef} list={EditMenuList} isShowMenu={isShow} />}
    </>
  );
};

export default EditMenuComponent;

const StyledEditToggle = styled.div`
  padding: 10px;
  cursor: pointer;
`;
