import React from 'react';
import * as Formatter from '@meettry/company/utils/format';
import { Button } from '@meettry/company/component/atoms/btn';

type PlanList = {
  price: number;
  name: string;
  text: string;
  contractStatus: { text: string; cls: string };
  recommend: boolean;
};

type PricePlanProps = {
  planList: PlanList;
  displayModal: Function | null;
};

export const PricePlan: React.FC<PricePlanProps> = (props) => {
  const buttonText = props.planList.contractStatus.text;
  const buttonClassName = props.planList.contractStatus.cls;
  const recommendConditions = props.planList.recommend ? (
    <span className="recommend">おすすめ</span>
  ) : null;
  return (
    <div className="plan-type">
      <h3>
        {Formatter.insertComma(props.planList.price, '')}
        <span>{props.planList.name}</span>
      </h3>
      <p>{props.planList.text}</p>
      <Button
        buttonText={buttonText}
        cls={`btn ${buttonClassName} middle`}
        onClick={() => (!!props.displayModal ? props.displayModal(true) : null)}
      />
      {recommendConditions}
    </div>
  );
};
