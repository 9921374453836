import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';

import Icon from '@meettry/engineer/components/atoms/Icon/Icon';

import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type HeaderMenuType = {
  list: {
    name: string;
    target: string;
  };
  closeMenu?: (cb: any) => false | void;
};

const HeaderMenu: React.FC<HeaderMenuType> = ({ list, closeMenu }) => {
  const location = useLocation();
  const history = useHistory();
  const enterprise = '/enterprise';
  const moveToTarget = () => {
    if (!list.target) return;
    if (list.target.indexOf('http') >= 0) {
      window.open(list.target);
    } else if (list.target === enterprise) {
      history.push(enterprise);
    } else if (list.target === '/') {
      history.push('/');
    } else {
      const target = document.getElementById(list.target);
      const header = document.getElementsByTagName('header');
      const movePosition =
        target && window.pageYOffset + target.getBoundingClientRect().top - header[0].clientHeight;
      window.scrollTo({ top: movePosition ?? 0, behavior: 'smooth' });
    }
  };
  return (
    <StyledDivMenuWrapper
      onClick={() => (closeMenu ? closeMenu(moveToTarget) : moveToTarget())}
      path={location.pathname}
    >
      {list.name}
      {!list.target ? <Icon imgName="window_open" /> : null}
    </StyledDivMenuWrapper>
  );
};

export default HeaderMenu;

// styled-components
const StyledDivMenuWrapper = styled.li<{ path: string }>`
  position: relative;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  margin-right: 38px;
  ${mediaQuery.greaterThan('basePCWidth')`
  margin-right: 38px;
  `}
  ${mediaQuery.lessThan('basePCWidth')`
  margin-right: 18px;
  `}
  ${mediaQuery.greaterThan('breakPoint')`
  ${FONT_DEFINITIONS.SMALL_BOLD};
  `}
  ${mediaQuery.lessThan('breakPoint')`
  ${FONT_DEFINITIONS.SECTION_BOLD};
  text-align: center;
  margin: 0 0 75px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    margin: 0 0 25px;
  `}
  ${mediaQuery.lessThan('sp')`
  ${FONT_DEFINITIONS.READ_TEXT};
  `}
${({ path }) => mediaQuery.greaterThan('breakPoint')`
&:after{
  position: absolute;
  content: '';
  width: 120%;
  height: 4px;
  bottom: -37px;
  left: -10%;
}
&:hover{
  &:after{
    background-color: ${
      path === '/enterprise' ? COLOR_DEFINITIONS.BG.MAIN : COLOR_DEFINITIONS.MAIN.PRIMARY
    };
  }
}
`}
`;
