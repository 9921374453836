import React from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { PageCommonProps } from '@meettry/company/route/route';
import { useLoginStatus } from '@meettry/ui-components/utils/firebase';
import { Breadcrumb } from '@meettry/company/component/molecules/breadcrumb';
import UserProfile from '@meettry/company/component/organisms/userPage/UserProfile';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';
import SkillBox from '@meettry/company/component/organisms/userPage/SkillBox';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';

const tabData = [
  { text: 'ダッシュボード', type: 'Dashboard', path: '/user_name/dashboard' },
  { text: '経歴', type: 'Resume', path: '/user_name/resume' },
  { text: 'チャレンジ', type: 'Challenge', path: '/user_name/challenge' }
];

const UserPage: React.FC<PageCommonProps> = (props) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const { initialized, authorized } = useLoginStatus();
  const isPage = !initialized && authorized && tabData.find((data) => data.path === locationPath);

  return (
    <React.Fragment>
      {isPage ? (
        <main className="userpage-wrapper">
          <Breadcrumb />
          <div className="container">
            <UserProfile />
            <div className="main-contents">
              <div className="userpage-tablink">
                <ChangeTab tab={tabData} active={locationPath} />
              </div>
              <SkillBox />
              <Switch>
                {!!props.childRoute &&
                  props.childRoute.map((li, i) => {
                    return (
                      <Route
                        key={i}
                        path={props.path + li.path}
                        exact={li.exact}
                        component={li.component}
                      />
                    );
                  })}
              </Switch>
            </div>
          </div>
        </main>
      ) : (
        <NotFoundPage />
      )}
    </React.Fragment>
  );
};

export default UserPage;
