import { useEffect, useContext, useState } from 'react';
import { EngineerContext, setResumeList } from '@meettry/ui-components/contexts/engineer';
import { ResumeType } from '@meettry/ui-components/types/userPage';

//TODO(aida) 定数置き場に持っていく
export const RESUME_SORT_TYPE = {
  LATEST: 'LATEST', //経歴の開始日が最新順 デフォルト値
  OLDEST: 'OLDEST', //経歴が古い順
  UPDATED: 'UPDATED', //更新順
  LONGEST: 'LONGEST' //経歴年数が長い順
};

export const RESUME_SORT_OPTION = [
  { value: RESUME_SORT_TYPE.LATEST, text: '最新順' },
  { value: RESUME_SORT_TYPE.OLDEST, text: '古い順' },
  { value: RESUME_SORT_TYPE.UPDATED, text: '更新順' },
  { value: RESUME_SORT_TYPE.LONGEST, text: '年数順' }
];

export const useResume = () => {
  const { state, dispatch } = useContext(EngineerContext);
  const { resumeList } = state;
  const [isInitial, setIsInitial] = useState(true);
  //MEMO(aida) 整合性を保ちかつ不要な通信を避けるために表示中の項目をidで管理する。
  //MEMO(aida) showingListIdにidが含まれているresumeList.itemのみを表示する。
  const [showingListId, setShowingListId] = useState<Array<string> | null>(null);
  //MEMO(aida) ピン留めや表示・非表示や新規作成・編集・削除などの更新はcontextのchallengeList.itemにのみ反映させる。
  const [showingList, setShowingList] = useState<Array<ResumeType> | null>(null);
  //MEMO(aida) ソート順の管理に使用する
  const [sortType, setSortType] = useState(RESUME_SORT_TYPE.LATEST);

  const addResume = (resume: ResumeType) => {
    const updatedResumeList = [...resumeList.items, resume];
    setShowingListId(updatedResumeList.map(({ id }: ResumeType) => id));
    dispatch(setResumeList(updatedResumeList));
  };

  const updateResume = (resume: ResumeType) => {
    const updatedResumeList = resumeList.items.map((item: ResumeType) => {
      if (item.id === resume.id) return resume;
      return item;
    });
    dispatch(setResumeList(updatedResumeList));
  };

  const removeResume = (id: string) => {
    const updatedResumeList = resumeList.items.filter((item: ResumeType) => item.id !== id);
    setShowingListId(updatedResumeList.map(({ id }: ResumeType) => id));
    dispatch(setResumeList(updatedResumeList));
  };

  const pinResume = (resume: ResumeType) => {
    const updatedResumeList = resumeList.items.map((item: ResumeType) => {
      //TODO(aida) pinのmutationのクエリを直してresumeだけをreturnさせる。
      if (item.id === resume.id) return { ...item, ...resume };
      return item;
    });
    dispatch(setResumeList(updatedResumeList));
  };

  const visibleResume = (resume: ResumeType) => {
    //TODO(aida) visibleのmutationのクエリを直してresumeだけをreturnさせる。
    const updatedResumeList = resumeList.items.map((item: ResumeType) => {
      if (item.id === resume.id) return { ...item, ...resume };
      return item;
    });
    dispatch(setResumeList(updatedResumeList));
  };

  const setSort = (type: string) => {
    setSortType(type);
  };

  //TODO(aida) 書き出す。challengeとガッチャンこするか考える
  const sort = {
    latest: (items: Array<ResumeType>) => {
      const sortedItems = items.sort((a, b) => {
        if (a.created && b.created) {
          const timeA = new Date(a.created).getTime();
          const timeB = new Date(b.created).getTime();
          return timeB - timeA;
        }
        return 0;
      });
      return [...sortedItems];
    },
    oldest: (items: Array<ResumeType>) => {
      const sortedItems = items.sort((a, b) => {
        const timeA = new Date(a.workStartDate).getTime();
        const timeB = new Date(b.workStartDate).getTime();
        return timeA - timeB;
      });
      return [...sortedItems];
    },
    updated: (items: Array<ResumeType>) => {
      const sortedItems = items.sort((a, b) => {
        const timeA = new Date(a.updated).getTime();
        const timeB = new Date(b.updated).getTime();
        return timeB - timeA;
      });
      return [...sortedItems];
    },
    longest: (items: Array<ResumeType>) => {
      const sortedItems = items.sort((a, b) => {
        const endDateA = a.workEndDate ? new Date(a.workEndDate) : new Date();
        const endDateB = b.workEndDate ? new Date(b.workEndDate) : new Date();
        const timeA = endDateA.getTime() - new Date(a.workStartDate).getTime();
        const timeB = endDateB.getTime() - new Date(b.workStartDate).getTime();
        return timeB - timeA;
      });
      return [...sortedItems];
    }
  };

  const sortResume = (sortType: string) => {
    const showingResumeItems = resumeList.items.filter(
      ({ id }: { id: string }) => showingListId && showingListId.includes(id)
    );
    switch (sortType) {
      case RESUME_SORT_TYPE.LATEST:
        setShowingList(sort.latest(showingResumeItems));
        return;
      case RESUME_SORT_TYPE.OLDEST:
        setShowingList(sort.oldest(showingResumeItems));
        return;
      case RESUME_SORT_TYPE.UPDATED:
        setShowingList(sort.updated(showingResumeItems));
        return;
      case RESUME_SORT_TYPE.LONGEST:
        setShowingList(sort.longest(showingResumeItems));
        return;
      default:
        setShowingList(sort.latest(showingResumeItems));
        return;
    }
  };

  //MEMO(aida) ソート・結果を反映する処理
  useEffect(() => {
    if (isInitial) return;
    sortResume(sortType);
  }, [sortType, resumeList.items, showingListId]);

  //MEMO(aida)　初期状態の画面表示用の配列をセットする
  useEffect(() => {
    setShowingListId(resumeList.items.map(({ id }: { id: string }) => id));
    setShowingList(sort.latest(resumeList.items));
    setIsInitial(false);
  }, []);

  return {
    state,
    addResume,
    updateResume,
    removeResume,
    pinResume,
    visibleResume,
    sortType,
    setSort,
    showingList,
    setShowingList,
    setShowingListId
  };
};

export default useResume;
