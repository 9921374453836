import React, { useState } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import ImageLogoLink from '@meettry/ui-components/components/atoms/Images/ImageLogoLink';
import Icon from '@meettry/ui-components/components/atoms/Icon/Icon';
import TopFootCTA from '@meettry/ui-components/components/molecules/CallToAction';
import FooterLinks from '@meettry/ui-components/components/organisms/Footers/FooterLinks';
import { useTopPath } from '@meettry/ui-components/hooks/useTopPath';

const FOOTER_LINKS = [
  { name: 'プライバシーポリシー', path: '/privacy' },
  { name: '利用規約', path: '/terms' },
  { name: '特定商取引に関する表記', path: '/tokutei' },
  { name: 'お問い合わせ', path: '/contact' },
  { name: '運営会社', path: 'https://www.leihauoli.com/' }
];

type FooterType = {
  isWidthDynamic?: string;
};

const Footer: React.FC<FooterType> = ({ isWidthDynamic }) => {
  const [hoverIcon, changeHoverIcon] = useState(false);
  const history = useHistory();
  const topPath = useTopPath();

  const clickLogo = () => {
    history.push(topPath);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
    <>
      <StyledFooter currentPage={isWidthDynamic}>
        <div>
          <StyledFooterLogoWrap onClick={() => clickLogo()}>
            <StyledFooterLogo size="footer" />
          </StyledFooterLogoWrap>
          <FooterLinks footerLinks={FOOTER_LINKS} currentPage={isWidthDynamic} />
          <StyledP>© meettry All rights reserved.</StyledP>
        </div>
        <MoveToTop
          onClick={() =>
            window.scrollTo({
              top: 0,
              behavior: 'smooth'
            })
          }
          onMouseEnter={() => changeHoverIcon(true)}
          onMouseLeave={() => changeHoverIcon(false)}
        >
          <ArrowIcon
            style={{ opacity: hoverIcon ? 0 : 1 }}
            iconColor="primary"
            iconName="arrow_up"
            width="20px"
          />
          <ArrowIcon
            style={{ opacity: hoverIcon ? 1 : 0 }}
            iconColor="black"
            iconName="arrow_up"
            width="20px"
          />
        </MoveToTop>
      </StyledFooter>
      <TopFootCTA />
    </>
  );
};

export default Footer;

// styled-components

const StyledFooter = styled.footer<{ currentPage?: string }>(
  ({ currentPage }) =>
    `
    position: relative;
    min-width: ${currentPage ? 'auto' : '1280px'};
    padding: 60px 0;
    color: #FFFFFF;
    background-color: ${COLOR_DEFINITIONS.BG.FOOTER};
    
    a {
      color: #FFFFFF;
    }
   `
);

const StyledFooterLogoWrap = styled.div`
  && {
  }
`;

const StyledFooterLogo = styled(ImageLogoLink)``;

const ArrowIcon = styled(Icon)`
  transition: opacity 0.5s;
`;

const StyledDiv = styled.div`
  && {
    margin: 0 auto;
    padding: 0 50px;
  }
`;

const StyledP = styled.p`
  && {
    text-align: center;
    font-size: 12px;
    letter-spacing: 1px;
  }
`;

const MoveToTop = styled.div`
  display: block;
  position: absolute;
  width: 60px;
  height: 60px;
  border-radius: 30px;
  top: -30px;
  background-color: #ffffff;
  box-shadow: 0px -1px 8px rgba(0, 0, 0, 0.4);
  cursor: pointer;
  transition: top 0.25s;
  &:hover {
    top: -28px;
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  ${mediaQuery.greaterThan('breakPoint')`
  right: 90px;
  `}
  ${mediaQuery.between('minTablet', 'breakPoint')`
  right: 40px;
  `}
  ${mediaQuery.lessThan('minTablet')`
  right: 20px;
  width: 40px;
  height: 40px;
  top: -20px;
  `}
`;
