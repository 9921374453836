import { useContext } from 'react';
import {
  EngineerContext,
  setSelectedTechStackSummaryTags,
  setSelectedOtherTags
} from '@meettry/ui-components/contexts/engineer';

export const useTagFilter = () => {
  const { state, dispatch } = useContext(EngineerContext);
  const { selectedTechStackSummaryTags, selectedOtherTags } = state;

  const addSelectedTechStackSummaryTags = (targetTag: string) => {
    const newSelectedTags = [...selectedTechStackSummaryTags, targetTag];
    dispatch(setSelectedTechStackSummaryTags(newSelectedTags));
  };
  const removeSelectedTechStackSummaryTags = (targetTag: string) => {
    const newSelectedTags = selectedTechStackSummaryTags.filter((tag) => tag !== targetTag);
    dispatch(setSelectedTechStackSummaryTags(newSelectedTags));
  };

  const addSelectedOtherTags = (targetTag: string) => {
    const newSelectedTags = [...selectedOtherTags, targetTag];
    dispatch(setSelectedOtherTags(newSelectedTags));
  };
  const removeSelectedOtherTags = (targetTag: string) => {
    const newSelectedTags = selectedOtherTags.filter((tag) => tag !== targetTag);
    dispatch(setSelectedOtherTags(newSelectedTags));
  };

  return {
    selectedTechStackSummaryTags,
    addSelectedTechStackSummaryTags,
    removeSelectedTechStackSummaryTags,
    selectedOtherTags,
    addSelectedOtherTags,
    removeSelectedOtherTags
  };
};
