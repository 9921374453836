import gql from 'graphql-tag';

export const GET_TAG_LIST = gql`
  query TagList {
    tagList {
      id
      displayName
      category
    }
  }
`;

export const CREATE_SCOUT = gql`
  mutation createScout($input: CreateScoutInput!) {
    createScout(input: $input) {
      scout {
        id
      }
    }
  }
`;
