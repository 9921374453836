import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type AlertProps = {
  message?: string | null;
};
export const Alert: React.FC<AlertProps> = (props) => {
  const { message } = props;
  return !!message ? (
    <StyledAlert>
      <StyledAlertTitle>重要なお知らせ</StyledAlertTitle>
      <StyledAlertTextArea>
        <span>{message}</span>
      </StyledAlertTextArea>
    </StyledAlert>
  ) : null;
};

const StyledAlert = styled.div`
  min-height: 40px;
  background-color: ${COLOR_DEFINITIONS.BG.ALERT};
  padding: 0 40px;
  display: flex;
  span {
    font-size: 12px;
    line-height: 2;
    display: inline-block;
    padding: 8px 0;
    color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  }
`;

const StyledAlertTitle = styled.span`
  flex: none;
`;

const StyledAlertTextArea = styled.div`
  margin-left: 20px;
  width: 100%;
`;
