import React from 'react';

export const FORMAT_COMMA_NUMBER = 'comma';

type FormatNumType = {
  type: string;
  children: number;
};

const FormatNum: React.FC<FormatNumType> = (props) => {
  const { children, type } = props;
  const num = (type = 'kformat', number = 0) => {
    let formatNum: number | string = ~~children;
    if (type == FORMAT_COMMA_NUMBER) {
      return (formatNum = Intl.NumberFormat().format(formatNum));
    }
    return (formatNum =
      Math.abs(formatNum) > 999
        ? Math.sign(formatNum) * Number((Math.abs(formatNum) / 1000).toFixed(1)) + 'k'
        : Math.sign(formatNum) * Math.abs(formatNum));
  };
  return <span>{num(type, children)}</span>;
};

export default FormatNum;
