import React from 'react';
import styled, { css } from 'styled-components';
import { SELECTBOX } from '@meettry/engineer/utils/Constant';

export const SelectBox = (props) => {
  const {
    selectedValue = '',
    values = [],
    name = '',
    onChange,
    placeholder = '',
    className,
    size = '',
    error = false
  } = props;

  return (
    <StyledSelectWrapper className={className} size={size}>
      <StyledSelect
        value={selectedValue}
        name={name}
        onChange={onChange}
        isDefault={'' === selectedValue}
        size={size}
        error={error}
      >
        <StyledOption value="">{placeholder}</StyledOption>
        {values.map(({ value, content }) => (
          <StyledOption key={`${name}_${content}`} value={value}>
            {content}
          </StyledOption>
        ))}
      </StyledSelect>
    </StyledSelectWrapper>
  );
};

export default SelectBox;

const StyledSelectWrapper = styled.div`
  width: 100%;
  ${({ size }) => {
    switch (size) {
      case SELECTBOX.SIZE.LARGE:
      case SELECTBOX.SIZE.MEDIUM:
        return css`
          max-width: 200px;
        `;
      case SELECTBOX.SIZE.SMALL:
        return css`
          max-width: 140px;
        `;
      default:
        return null;
    }
  }}
  position: relative;
  ::after {
    content: '';
    width: 10px;
    height: 10px;
    display: block;
    position: absolute;
    top: 45%;
    right: 20px;
    transform: rotate(45deg) translate(-50%, -50%);
    border: 2px solid #0fafa9;
    border-top: none;
    border-left: none;
  }
`;

const StyledSelect = styled.select`
  ${(props) => (props.isDefault ? `color:#999999 ` : null)}
  -webkit-appearance: none;
  appearance: none;
  display: block;
  outline: none;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  width: 100%;
  background-color: #f7f7f7;
  padding: 0 20px;
  cursor: pointer;
  ${({ size }) => {
    switch (size) {
      case SELECTBOX.SIZE.LARGE:
      case SELECTBOX.SIZE.MEDIUM:
      case SELECTBOX.SIZE.SMALL:
        return css`
          font-size: 16px;
          height: 48px;
        `;
      default:
        return css`
          font-size: 12px;
          height: 40px;
        `;
    }
  }}
  ${({ error }) =>
    error
      ? css`
          background-color: #fee8eb;
          border: 1px solid #f4233c;
        `
      : null}
`;

const StyledOption = styled.option`
  line-height: 1;
  font-size: 16px;
`;
