import gql from 'graphql-tag';

export const GET_CORPORATE_INFO = gql`
  query {
    loginSession {
      user {
        organization {
          id
          phone
          postCode
          address
          url
          name
          logoImage {
            url
          }
          logoImageThumbnail {
            url
          }
        }
      }
    }
  }
`;

export const UPDATE_ORG = gql`
  mutation updateOrg($input: UpdateOrganizationInput!) {
    updateOrganization(input: $input) {
      ok
    }
  }
`;

export const GET_BILLING_ADDRESS = gql`
  query billingAddress {
    loginSession {
      user {
        organization {
          id
          billingAddress {
            name
            postCode
            address
            department
            representative
            phone
          }
        }
      }
    }
  }
`;

export const UPDATE_BILLING_ADDRESS = gql`
  mutation updateOrgBillingAddress($input: OrganizationBillingAddressInput!) {
    updateOrganizationBillingAddress(input: $input) {
      ok
    }
  }
`;

export const GET_PAYMENT = gql`
  query getPayment($yearMonth: Int!) {
    loginSession {
      user {
        organization {
          id
          currentPlan {
            name
          }
          payment {
            billing(yearMonth: $yearMonth) {
              organizationId
              yearMonth
              subtotalAmount
              taxRate
              taxAmount
              totalAmount
              totalScoutCount
              freeScoutCount
              paidScoutCount
              monthlyPrice
              scoutUnitPrice
              isFreeMonthlyPrice
            }
          }
        }
      }
    }
  }
`;

export const GET_BILLINGS = gql`
  query getPayment($filter: OrganizationPaymentBillingsFilter, $page: PageInfo!) {
    loginSession {
      user {
        organization {
          id
          created
          currentPlan {
            name
          }
          payment {
            billings(filter: $filter) {
              results(page: $page) {
                organizationId
                yearMonth
                subtotalAmount
              }
              count
            }
          }
        }
      }
    }
  }
`;
