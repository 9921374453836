import React, { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

type StyledNavLinkProps = {
  fontSize?: string;
};

type NavLinkBaseProps = {
  toPath: string;
  children?: ReactNode;
  blank?: boolean;
  fromEnterprise?: boolean;
} & StyledNavLinkProps;

const NavLinkBase: React.FC<NavLinkBaseProps> = (props) => {
  const { toPath, children, fontSize, blank, fromEnterprise } = props;
  const target = blank ? '_blank' : '_self';
  return (
    <StyledNavLink
      to={{
        pathname: toPath,
        state: { fromEnterprise: !!fromEnterprise }
      }}
      fontSize={fontSize}
      target={target}
    >
      {children}
    </StyledNavLink>
  );
};

export default NavLinkBase;

const StyledNavLink = styled(NavLink)`
  && {
    padding: 0;
    margin: 0;
    font-size: ${(props: StyledNavLinkProps) => (props.fontSize ? props.fontSize : '0')};
    text-decoration: none;
    color: unset;
    display: block;
  }
`;
