import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { SET_USER_PROFILE } from '@meettry/engineer/graphql/queries/user_profile_edit_page';
import { isNumber, isUrl } from '@meettry/engineer/utils/validation';
import { FormItem } from '@meettry/engineer/components/molecules/Form';
import {
  InputName,
  InputNameRuby,
  InputSex,
  InputBirthday,
  InputPhone,
  InputPostCode,
  InputAddress,
  InputLocation,
  InputJobSeekingLevel,
  InputWorkStyle,
  InputBio,
  InputEnableAcceptingScout,
  InputScoutNotification
} from '@meettry/engineer/components/organisms/EditProfile/EditProfileForm';
import { EditProfilePreview } from '@meettry/engineer/components/organisms/EditProfile/EditProfilePreview';
import { CHECKBOX, SELECTBOX } from '@meettry/engineer/utils/Constant';
import { PrimaryButton, TertiaryButton } from '@meettry/engineer/components/atoms/Button';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';

/**
 * useSetProfile Hook
 *
 */
const useSetProfile = (onCompleted) => {
  const [doMutation, { loading, error, data }] = useMutation(SET_USER_PROFILE, {
    onCompleted: onCompleted
  });
  return [
    doMutation,
    {
      loading,
      error,
      data
    }
  ];
};

/**
 * useEnableScout Hook
 * 必要項目を入力してスカウトを受け取るが有効かどうかを確認する Custom Hook
 */
const useEnableScout = (userDetail, setUserDetail) => {
  const {
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    phone,
    sex,
    birthDate,
    postCode,
    address
  } = userDetail;

  const isEnable = useMemo(() => {
    return (
      firstName !== '' &&
      lastName !== '' &&
      firstNameKana !== '' &&
      lastNameKana !== '' &&
      phone !== '' &&
      sex !== '' &&
      birthDate !== null &&
      postCode !== '' &&
      address !== ''
    );
  }, [firstName, lastName, firstNameKana, lastNameKana, phone, sex, birthDate, postCode, address]);

  const [isEnableScout, setEnableScout] = useState(isEnable);
  useEffect(() => {
    setEnableScout(isEnable);
    if (!isEnable) setUserDetail({ ...userDetail, enableAcceptingScout: 0 });
  }, [isEnable]);

  return { isEnableScout };
};
/**
 * EditProfileForm Component
 * ユーザープロフィールを編集し保存するコンポーネント
 */
const EditProfileForm = (props) => {
  const { profile, workStyleList } = props;
  const { startLoading, endLoading } = useLoading();
  const {
    showCancelConfirmationDialog,
    showActivateScoutConfirmationDialog,
    showScoutUnableConfirmationDialog,
    closeDialog
  } = useDialog();
  const { showErrorPopup, showSuccessPopup } = usePopup();
  const { bio, url, location, workStyles } = profile.user;
  const initUserState = { bio, url, location, workStyles };
  const [user, setUser] = useState(initUserState);
  const [isEditMode, setIsEditMode] = useState(false);
  const [dirty, setDirty] = useState(false);
  const [previewUserDetail, setPreviewUserDetail] = useState(profile.userDetail);
  const [previewUser, setPreviewUser] = useState(profile.user);
  const {
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    phone,
    sex,
    birthDate,
    postCode,
    address,
    jobSeekingLevel,
    enableAcceptingScout,
    enableScoutChatNotifMail
  } = profile.userDetail;
  const initUserDetailState = {
    firstName,
    lastName,
    firstNameKana,
    lastNameKana,
    phone,
    sex,
    birthDate,
    postCode,
    address,
    jobSeekingLevel,
    enableAcceptingScout,
    enableScoutChatNotifMail
  };
  const [userDetail, setUserDetail] = useState(initUserDetailState);

  const { isEnableScout } = useEnableScout(userDetail, setUserDetail);
  const initUserWorkStylesState = workStyles.map((s) => s.id);
  const [userWorkStyles, setUserWorkStyles] = useState(initUserWorkStylesState);

  const [mutation, { error }] = useSetProfile((data) => {
    if (!data) return;
    setDirty(false);
    setIsEditMode(false);
    //MEMO(aida) 更新後の表示をプレビューと編集画面に反映させる
    const {
      setUserProfile: { userDetail: resUserDetail, user: resUser }
    } = data;
    delete resUserDetail.__typename;
    delete resUser.__typename;
    setUserDetail({ ...userDetail, ...resUserDetail });
    setUser({
      ...user,
      location: resUser.location,
      url: resUser.url,
      bio: resUser.bio,
      workStyles: resUser.workStyles
    });
    setPreviewUserDetail({ ...previewUserDetail, ...resUserDetail });
    setPreviewUser({
      ...previewUser,
      location: resUser.location,
      url: resUser.url,
      bio: resUser.bio,
      workStyles: resUser.workStyles
    });
    endLoading();
    showSuccessPopup('プロフィールを更新しました');
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    const isError = error;
    if (!isError) return;
    endLoading();
    if (error) showErrorPopup('プロフィールを更新できませんでした');
  }, [error]);

  //MEMO(aida) errorの状態を管理するためのstate
  const [inputErrorState, updateInputErrorState] = useState({
    birthDate: false,
    phone: false,
    postCode: false,
    url: false,
    bio: false
  });

  //TODO(aida)別のファイルに切り抜く
  //MEMO(aida) インプット毎のバリデーション関数をまとめたもの
  const validators = {
    url: (value) => {
      if (value === '') return false;
      return !isUrl(value);
    },
    birthDate: (value) => {
      const { year, month, day } = value;
      const emptyInput = Object.keys(value).filter((key) => value[key] === '');
      if (emptyInput.length === 3) return false;
      if (!!emptyInput.length) return true;
      const dateString = `${year}/${month}/${day}`;
      const dt = new Date(dateString);
      const objectDateString = `${dt.getFullYear()}/${dt.getMonth() + 1}/${dt.getDate()}`;
      const isDateExist = dateString === objectDateString;
      return isDateExist ? false : true;
    },
    postCode: (value) => {
      const { first, second } = value;
      const emptyInput = Object.keys(value).filter((key) => value[key] === '');
      if (emptyInput.length === 2) return false;
      if (!!emptyInput.length) return true;

      const isInputNumber = isNumber(first) & isNumber(second);
      const isInputCompleted = (first.length === 3) & (second.length === 4);
      return isInputNumber & isInputCompleted ? false : true;
    },
    phone: (value) => {
      const { first, second, third } = value;
      const emptyInput = Object.keys(value).filter((key) => value[key] === '');
      if (emptyInput.length === 3) return false;
      if (!!emptyInput.length) return true;

      const isInputNumber = isNumber(first) & isNumber(second) & isNumber(third);
      const isInputCompleted = (first.length === 3) & (second.length === 4) & (third.length === 4);
      return isInputNumber & isInputCompleted ? false : true;
    },
    bio: (value) => {
      return value.length > 160;
    }
  };

  //MEMO(aida) inputErrorState内にerrorが存在するかをチェックするための関数
  const validateUserInput = (inputErrorState) => {
    const errs = Object.keys(inputErrorState).filter((input) => inputErrorState[input]);
    // MEMO(aida) errsのlengthが１以上の場合はエラー状態のためtrueを返す
    return !!errs.length ? true : false;
  };

  //MEMO(aida) バリデーションが必要なインプットにて入力値を確認しinputErrorStateの状態を更新するための関数
  const checkUserInput = (name, value) => {
    //MEMO(aida) バリデーションが必要ない項目に関してがreturnさせる
    const errorTarget = Object.keys(inputErrorState);
    if (!errorTarget.includes(name)) return;

    //MEMO(aida) validatorsから対象のバリデーション関数を参照し結果を登録する
    updateInputErrorState({ ...inputErrorState, [name]: validators[name](value) });
  };

  let displayBirthTxt = '';
  if (!!birthDate) {
    const date = birthDate.replace(/T00:00:00/g, '').split('-');

    displayBirthTxt = new Date(...date);
  }
  const [userBirth, setUserBirth] = useState({
    year: !!displayBirthTxt ? displayBirthTxt.getFullYear() : '',
    month: !!displayBirthTxt ? displayBirthTxt.getMonth() : '',
    day: !!displayBirthTxt ? displayBirthTxt.getDate() : ''
  });

  let displayPhoneTxt = '';
  if (!!phone) {
    displayPhoneTxt = phone.split('-');
  }
  const [userPhone, setUserPhone] = useState({
    first: !!displayPhoneTxt ? displayPhoneTxt[0] : '',
    second: !!displayPhoneTxt ? displayPhoneTxt[1] : '',
    third: !!displayPhoneTxt ? displayPhoneTxt[2] : ''
  });

  let displayPostCodeTxt = '';
  if (!!postCode) {
    displayPostCodeTxt = postCode.split('-');
  }
  const [userPostCode, setUserPostCode] = useState({
    first: !!displayPostCodeTxt ? displayPostCodeTxt[0] : '',
    second: !!displayPostCodeTxt ? displayPostCodeTxt[1] : ''
  });

  const handleSubmit = () => {
    const inputData = {
      user: { ...user },
      userDetail: { ...userDetail }
    };

    if (validateUserInput(inputErrorState)) {
      console.log('[profile validation]some thing missing...');
      return;
    }

    inputData.user.workStyles = [...userWorkStyles.map((id) => ({ id }))];

    // スカウトを受け取れるがONになっていない状態
    if (isEnableScout && !inputData.userDetail.enableAcceptingScout) {
      showActivateScoutConfirmationDialog(
        () => {
          closeDialog();
          inputData.userDetail.enableAcceptingScout = 1;
          mutation({ variables: { input: inputData } });
          startLoading('プロフィールを更新中...');
        },
        () => {
          closeDialog();
          mutation({ variables: { input: inputData } });
          startLoading('プロフィールを更新中...');
        }
      );
      return;
    }
    // スカウトを受け取れないががONになっている状態
    if (!isEnableScout && !inputData.userDetail.enableAcceptingScout) {
      showScoutUnableConfirmationDialog(
        [
          '氏名、性別、生年月日、携帯電話番号、郵便番号、',
          '住所が登録されていない場合、',
          '企業からのスカウトを送ることができませんが',
          'よろしいですか？'
        ],
        () => {
          closeDialog();
          inputData.userDetail.enableAcceptingScout = 0;
          mutation({ variables: { input: inputData } });
          startLoading('プロフィールを更新中...');
        },
        () => {
          closeDialog();
        }
      );
      return;
    }
    // スカウト受け取りに必要な項目が未入力の場合
    if (!isEnableScout) {
      showScoutUnableConfirmationDialog(
        [
          '氏名、性別、生年月日、携帯電話番号、郵便番号、',
          '住所が登録されていない場合、',
          '企業からのスカウトを送ることができませんが',
          'よろしいですか？'
        ],
        () => {
          closeDialog();
          inputData.userDetail.enableAcceptingScout = 0;
          mutation({ variables: { input: inputData } });
          startLoading('プロフィールを更新中...');
        },
        () => {
          closeDialog();
        }
      );
      return;
    }

    mutation({ variables: { input: inputData } });
    startLoading('プロフィールを更新中...');
  };

  const cancelEdit = () => {
    setDirty(false);
    setIsEditMode(false);
    //MEMO(aida) 編集した後にキャンセルを押した際に編集状態を初期化する。
    setUser(initUserState);
    setUserDetail(initUserDetailState);
    setUserWorkStyles(initUserWorkStylesState);
    window.scrollTo(0, 0);
  };

  const onCancel = () => {
    //MEMO(aida) 内容が編集されている場合に確認モーダルを表示する
    if (dirty) {
      showCancelConfirmationDialog(
        ['保存されていない変更を破棄しますか?'],
        () => {
          closeDialog();
          cancelEdit();
        },
        () => {
          closeDialog();
        }
      );
      return;
    }
    cancelEdit();
  };

  const handleChangeUser = (name) => (e) => {
    dirty || setDirty(true);
    setUser({ ...user, [name]: e.target.value });
    checkUserInput(name, e.target.value);
  };

  const handleChangeUserDetail = (name) => (e) => {
    dirty || setDirty(true);
    let value = e.target.value;

    if (['sex', 'enableAcceptingScout', 'jobSeekingLevel'].includes(name)) {
      value = Number(e.target.value);
    }
    setUserDetail({ ...userDetail, [name]: value });
  };

  const handleChangeRadioButton = (name) => (value) => {
    dirty || setDirty(true);
    setUserDetail({ ...userDetail, [name]: value });
  };

  const handleChangeWorkStyle = () => (e) => {
    dirty || setDirty(true);
    const value = e.target.value;
    if (userWorkStyles.includes(value)) {
      setUserWorkStyles([...userWorkStyles.filter((id) => id !== value)]);
    } else {
      setUserWorkStyles([...userWorkStyles, value]);
    }
  };

  const handleChangeUserBirth = (name) => (e) => {
    dirty || setDirty(true);
    let value = '';
    if (name === 'year') {
      value = e.target.value.slice(0, 4);
    } else {
      value = e.target.value.slice(0, 2);
    }
    setUserBirth({ ...userBirth, [name]: value });
    checkUserInput('birthDate', { ...userBirth, [name]: value });
  };

  useEffect(() => {
    if (!userBirth.year && !userBirth.month && !userBirth.day) {
      setUserDetail({ ...userDetail, birthDate: null });
      return;
    }
    const datetime = new Date(userBirth.year, userBirth.month, userBirth.day);
    const year = datetime.getFullYear();
    const month = `0${datetime.getMonth()}`.slice(-2);
    const day = `0${datetime.getDate()}`.slice(-2);
    const birthDate = `${year}-${month}-${day}T00:00:00`;
    setUserDetail({ ...userDetail, birthDate });
  }, [userBirth]);

  const handleChangeUserPhone = (name) => (e) => {
    dirty || setDirty(true);
    let value = '';
    if (name === 'first') {
      if (e.target.length > 3) return;
      value = e.target.value.slice(0, 3);
    } else {
      if (e.target.length > 4) return;
      value = e.target.value.slice(0, 4);
    }
    setUserPhone({ ...userPhone, [name]: value });
    checkUserInput('phone', { ...userPhone, [name]: value });
  };

  useEffect(() => {
    if (!userPhone.first && !userPhone.second && !userPhone.third) {
      setUserDetail({ ...userDetail, phone: '' });
      return;
    }
    const phone = `${userPhone.first}-${userPhone.second}-${userPhone.third}`;
    setUserDetail({ ...userDetail, phone });
  }, [userPhone]);

  const handleChangeUserPostCode = (name) => (e) => {
    dirty || setDirty(true);
    let value = '';
    if (name === 'first') {
      if (e.target.length > 3) return;
      value = e.target.value.slice(0, 3);
    } else {
      if (e.target.length > 3) return;
      value = e.target.value.slice(0, 4);
    }
    setUserPostCode({ ...userPostCode, [name]: value });
    checkUserInput('postCode', { ...userPostCode, [name]: value });
  };

  useEffect(() => {
    if (!userPostCode.first && !userPostCode.second) {
      setUserDetail({ ...userDetail, postCode: '' });
      return;
    }
    const postCode = `${userPostCode.first}-${userPostCode.second}`;
    setUserDetail({ ...userDetail, postCode });
  }, [userPostCode]);

  const formItemList = [
    {
      title: '名前',
      component: (
        <InputName
          userDetail={userDetail}
          onChangeFirstName={handleChangeUserDetail('firstName')}
          onChangeLastName={handleChangeUserDetail('lastName')}
        />
      )
    },
    {
      title: 'フリガナ',
      component: (
        <InputNameRuby
          userDetail={userDetail}
          onChangeFirstName={handleChangeUserDetail('firstNameKana')}
          onChangeLastName={handleChangeUserDetail('lastNameKana')}
        />
      )
    },
    {
      title: '性別',
      component: <InputSex value={userDetail.sex} onChange={handleChangeRadioButton('sex')} />
    },
    {
      title: '生年月日',
      component: (
        <InputBirthday
          userBirth={userBirth}
          handleChangeUserBirth={handleChangeUserBirth}
          error={inputErrorState.birthDate}
        />
      ),
      error: inputErrorState.birthDate,
      errorText: '全ての項目を入力してください'
    },
    {
      title: '携帯番号',
      component: (
        <InputPhone
          userPhone={userPhone}
          handleChangeUserPhone={handleChangeUserPhone}
          error={inputErrorState.phone}
        />
      ),
      error: inputErrorState.phone,
      errorText: '全ての項目を半角数字で入力してください'
    },
    {
      title: '郵便番号',
      component: (
        <InputPostCode
          userPostCode={userPostCode}
          onChange={handleChangeUserPostCode}
          error={inputErrorState.postCode}
        />
      ),
      errorText: '全ての項目を半角数字で入力してください',
      error: inputErrorState.postCode
    },
    {
      title: '住所',
      component: (
        <InputAddress userDetail={userDetail} handleChangeUserDetail={handleChangeUserDetail} />
      )
    },
    {
      title: '活動拠点',
      component: <InputLocation user={user} handleChangeUser={handleChangeUser} />
    },
    /*TODO(minami) [XTC-643] 仕様が確定次第、変更します。 (2020/10/14)
    {title: "URL", component: <InputUrl user={user} handleChangeUser={handleChangeUser} inputErrorState={inputErrorState} />}, */
    {
      title: '稼働状況',
      component: (
        <InputJobSeekingLevel
          userDetail={userDetail}
          handleChangeUserDetail={handleChangeUserDetail}
          size={SELECTBOX.SIZE.MEDIUM}
        />
      )
    },
    {
      title: '希望の働き方',
      component: (
        <InputWorkStyle
          workStyleList={workStyleList}
          userWorkStyles={userWorkStyles}
          handleChangeWorkStyle={handleChangeWorkStyle}
          height={CHECKBOX.SIZE.LARGE}
        />
      )
    },
    {
      title: '自己紹介',
      component: (
        <InputBio user={user} handleChangeUser={handleChangeUser} error={inputErrorState.bio} />
      ),
      error: inputErrorState.bio,
      errorText: '自己紹介は160文字までです'
    },
    {
      title: 'スカウト受付状況',
      component: (
        <InputEnableAcceptingScout
          value={userDetail.enableAcceptingScout}
          isEnableScout={isEnableScout}
          onChange={handleChangeRadioButton('enableAcceptingScout')}
        />
      )
    },
    // TODO(oomura): スカウト通知設定のAPIが完了次第対応
    {
      title: 'スカウト通知設定',
      component: (
        <InputScoutNotification
          isEnableScout={isEnableScout}
          value={userDetail.enableScoutChatNotifMail}
          onChange={handleChangeRadioButton('enableScoutChatNotifMail')}
        />
      )
    }
  ];

  return (
    <StyledProfileWrapper>
      <StyledPageTitle>プロフィール編集</StyledPageTitle>
      <StyledFormWrapper>
        <div>
          <StyledPageTitleSub>
            詳細な個人情報はあなたと運営者のみに表示されます。
          </StyledPageTitleSub>
          <StyledPageTitleSub>
            ご入力いただいた氏名、住所、電話番号、メールアドレスは、サービス内で他のユーザー・企業に公開されることはございません。
          </StyledPageTitleSub>
          <StyledPageTitleSub>住所は、国、都道府県単位に変換し、表示されます。</StyledPageTitleSub>
        </div>
        <StyledFormTitle>基本情報</StyledFormTitle>
        {isEditMode ? (
          <StyledFormList>
            {formItemList.map((li, i) => (
              <FormItem error={li.error} errorText={li.errorText} key={i} label={li.title}>
                {li.component}
              </FormItem>
            ))}
            <FormItem>
              <StyledScountRequiredmentText>
                スカウトを受け取るにはすべての項目の入力が必要です。
              </StyledScountRequiredmentText>
            </FormItem>
          </StyledFormList>
        ) : (
          <EditProfilePreview user={previewUser} userDetail={previewUserDetail} />
        )}
      </StyledFormWrapper>
      {isEditMode ? (
        <StyledEditButtonWrapper>
          <TertiaryButton disabled={!isEditMode} onClick={onCancel}>
            キャンセル
          </TertiaryButton>
          <PrimaryButton
            disabled={
              !dirty ||
              Object.keys(inputErrorState).filter((input) => inputErrorState[input]).length
            }
            onClick={() => handleSubmit()}
          >
            変更する
          </PrimaryButton>
        </StyledEditButtonWrapper>
      ) : (
        <StyledPreviewButtonWrapper>
          <PrimaryButton
            onClick={() => {
              window.scrollTo(0, 0);
              setIsEditMode(true);
            }}
          >
            編集する
          </PrimaryButton>
        </StyledPreviewButtonWrapper>
      )}
    </StyledProfileWrapper>
  );
};

export default EditProfileForm;

const StyledProfileWrapper = styled.div`
  padding: 40px 50px;
  margin-bottom: 120px;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
`;
const StyledPageTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;
const StyledPageTitleSub = styled.p`
  font-size: 12px;
  margin-top: 6px;
`;
const StyledFormWrapper = styled.form`
  margin-top: 15px;
  padding-top: 15px;
  padding-bottom: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
`;
const StyledFormTitle = styled.h3`
  margin-top: 20px;
  font-size: 18px;
  font-weight: bold;
`;

const StyledFormList = styled.div`
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledScountRequiredmentText = styled.p`
  color: #999999;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  margin-top: 20px;
  > * {
    max-width: 100px;
  }
`;

const StyledEditButtonWrapper = styled(StyledButtonWrapper)`
  justify-content: space-between;
`;

const StyledPreviewButtonWrapper = styled(StyledButtonWrapper)`
  justify-content: flex-end;
`;
