type ProcessType = 'local' | 'development' | 'staging' | 'production';

type FirebaseConfigType = {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  // measurementId: string; // firebaseのAnalyticsを無効化
};

export const config: { [k in ProcessType]: FirebaseConfigType } = {
  local: {
    apiKey: 'AIzaSyA1yT9d8pfDhReokHbywHj2hFFcKu7OKbk',
    authDomain: 'meettry-dev.firebaseapp.com',
    databaseURL: 'https://meettry-dev.firebaseio.com',
    projectId: 'meettry-dev',
    storageBucket: 'meettry-dev.appspot.com',
    messagingSenderId: '602549909481',
    appId: '1:602549909481:web:213ba0eff4577d0a797d78',
    // measurementId: 'G-8G5741F7RZ'
  },
  development: {
    apiKey: 'AIzaSyA1yT9d8pfDhReokHbywHj2hFFcKu7OKbk',
    authDomain: 'meettry-dev.firebaseapp.com',
    databaseURL: 'https://meettry-dev.firebaseio.com',
    projectId: 'meettry-dev',
    storageBucket: 'meettry-dev.appspot.com',
    messagingSenderId: '602549909481',
    appId: '1:602549909481:web:213ba0eff4577d0a797d78',
    // measurementId: 'G-8G5741F7RZ'
  },
  staging: {
    apiKey: 'AIzaSyA1FNCgLCNjfwUwXD_ftzi3LrQ5a5_A57g',
    authDomain: 'meettry-stg.firebaseapp.com',
    databaseURL: 'https://meettry-stg.firebaseio.com',
    projectId: 'meettry-stg',
    storageBucket: 'meettry-stg.appspot.com',
    messagingSenderId: '48777790156',
    appId: '1:48777790156:web:8bce210456ae282d1cb869',
    // measurementId: 'G-0YS4DPSC20'
  },
  production: {
    apiKey: 'AIzaSyA2d-6fW_Q10LQY4ibcEp5a5iP4egsgWCw',
    authDomain: 'meettry.firebaseapp.com',
    databaseURL: 'https://meettry.firebaseio.com',
    projectId: 'meettry',
    storageBucket: 'meettry.appspot.com',
    messagingSenderId: '912533139036',
    appId: '1:912533139036:web:c8bf26e983646e6835c099',
    // measurementId: 'G-4WKNL2KE4F'
  }
};
