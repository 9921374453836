import React from 'react';
import styled from 'styled-components';

// グレー背景、黒文字、年数付き クリッカブル(バブルチャート横技術スタックサマリのタグ)

export const TechSummaryTag = (props) => {
  const { className, children, period, onClick } = props;
  const onClickHandle = (e) => {
    e.preventDefault();
    typeof onClick === 'function' && onClick();
  };
  return (
    <StyledTechSummaryTag className={className} onClick={onClickHandle}>
      <StyledTechSummaryText>{children}</StyledTechSummaryText>
      {period ? <StyledTechPeriod>{period}</StyledTechPeriod> : null}
    </StyledTechSummaryTag>
  );
};

const StyledTechSummaryTag = styled.div`
  user-select: none;
  display: inline-block;
  padding: 5px 8px;
  margin: 0 5px 10px 0;
  background-color: #eeeeee;
  border-radius: 3px;
  color: #333333;
`;

const StyledTechSummaryText = styled.span`
  font-weight: bold;
`;

const StyledTechPeriod = styled.span`
  margin-left: 10px;
`;
