import React, { useState, useEffect } from 'react';
import { useHistory, Link } from 'react-router-dom';
import * as Validation from '@meettry/company/utils/validation';
import * as FindError from '@meettry/company/utils/findError';
import { SIGN, LOGIN } from '@meettry/ui-components/constants/error';
import { useSignIn } from '@meettry/ui-components/utils/firebase';
import { PageCommonProps } from '@meettry/company/route/route';
import { Button } from '@meettry/company/component/atoms/btn';
import { Alert } from '@meettry/company/component/atoms/alert';
import { SignWrapper } from '@meettry/company/component/organisms/signWrapper';
import { Modal } from '@meettry/company/component/organisms/modal';

type ComponentPropsType = {
  value: string;
  changeValue: (val: string) => void;
  error: string;
  setError: React.Dispatch<React.SetStateAction<string>>;
};

const MailArea: React.FC<ComponentPropsType> = (props) => {
  const emailErrorFind = FindError.findError(!props.value ? LOGIN : SIGN, props.error);
  const emailError = !!emailErrorFind ? <span>{emailErrorFind}</span> : null;
  const emailErrorClass = !!emailError ? 'error' : '';
  return (
    <div className="input-area">
      <label htmlFor="email">メールアドレス</label>
      <input
        name="email"
        type="text"
        placeholder="メールアドレスを入力"
        value={props.value}
        className={emailErrorClass}
        onChange={(e) => props.changeValue(e.target.value)}
        onBlur={() => Validation.login(props.value, props.setError, 'email')}
      />
      {emailError}
    </div>
  );
};

const PasswordArea: React.FC<ComponentPropsType> = (props) => {
  const passwordErrorFind = FindError.findError(!props.value ? LOGIN : SIGN, props.error);
  const passwordError = !!passwordErrorFind ? <span>{passwordErrorFind}</span> : null;
  const passwordErrorClass = !!passwordError ? 'error' : '';
  return (
    <div className="input-area">
      <label htmlFor="password">パスワード</label>
      <input
        name="password"
        type="password"
        placeholder="パスワードを入力"
        value={props.value}
        className={passwordErrorClass}
        onChange={(e) => props.changeValue(e.target.value)}
        onBlur={() => Validation.login(props.value, props.setError, 'password')}
      />
      {passwordError}
    </div>
  );
};

type ErrorModalType = {
  show: boolean;
  displayModal: React.Dispatch<React.SetStateAction<boolean>>;
  message: string | null;
};

const ErrorModal: React.FC<ErrorModalType> = ({ show, displayModal, message }) => {
  const toEngineerDesc = 'こちらの画面からログインしてください。';

  return message ? (
    <Modal show={show} displayModal={displayModal} title="" cls="error-modal">
      <span>{message}</span>
      {message === toEngineerDesc ? (
        <a target="_blank" href="https://meettry.com/login">
          エンジニア画面へ
        </a>
      ) : (
        <Button
          buttonText="OK"
          cls="btn primary middle"
          onClick={() => {
            displayModal(false);
          }}
        />
      )}
    </Modal>
  ) : null;
};

const Login: React.FC<PageCommonProps> = () => {
  const [requestSignIn, requestStatus] = useSignIn();
  const history = useHistory();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [passwordError, setPasswordError] = useState<string>('');
  const [show, displayModal] = useState(false);

  const activeBtn = !!email && !!password ? 'primary' : 'second';

  useEffect(() => {
    if (requestStatus.error) displayModal(true);
  }, [requestStatus]);

  useEffect(() => {
    if (requestStatus.completed) history.push('/user/dashboard');
  }, [history, requestStatus]);

  return (
    <SignWrapper title="ログイン" cls="login">
      <MailArea
        value={email}
        changeValue={(val: string) => setEmail(val)}
        error={emailError}
        setError={setEmailError}
      />
      <PasswordArea
        value={password}
        changeValue={(val: string) => setPassword(val)}
        error={passwordError}
        setError={setPasswordError}
      />
      <div className="button-area">
        <Button
          buttonText="ログイン"
          cls={`btn ${activeBtn} large`}
          onClick={() => {
            if (emailError === 'none' && passwordError === 'none' && !requestStatus.loading)
              requestSignIn(email, password);
          }}
          disabled={requestStatus.loading}
        />
        <div className="text-link">
          <Link to="/forgot_pass">パスワードをお忘れの方はこちら</Link>
        </div>
      </div>
      {show ? (
        <ErrorModal show={show} displayModal={displayModal} message={requestStatus.error} />
      ) : null}
    </SignWrapper>
  );
};

export default Login;
