import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory, NavLink, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import NoticeFormTemplate from '@meettry/engineer/components/molecules/Template/NoticeFormTemplate';
import {
  LoginStatus,
  useEmailVerification,
  useLogin,
  useLoginUser
} from '@meettry/ui-components/utils/auth';
import { isValidateEmail } from '@meettry/engineer/utils';
import { VALIDATION } from '@meettry/engineer/utils/Constant';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { LocationState } from '@meettry/ui-components/types/location';

const NOTICE_PHRASE = {
  stress: 'メールアドレスの認証が完了しておりません。',
  normal:
    'メールアドレス確認用のメールを送信しました。\n内容をご確認頂きリンクをクリックして\n承認を完了してください。'
};

type Model = {
  email: string;
  password: string;
};

/**
 * Login Component
 */
const Login: React.FC = () => {
  const location = useLocation<LocationState>();
  const history = useHistory();
  const { showErrorDialog, showNotificationDialog, closeDialog } = useDialog();
  const { showSuccessPopup } = usePopup();
  const { status, user, isEnterprise } = useLoginUser();
  const [login, { loading: loginLoading, error: errLogin }] = useLogin();
  const [emailVerification, { error: errEmailVerification }] = useEmailVerification();
  const [model, setModel] = useState<Model>({ email: '', password: '' });
  const [error, setError] = useState<Model>({ email: '', password: '' });

  /**
   * ログイン実行
   */
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidateForm(model.email, model.password)) return;

    login(model.email, model.password);
    setError({ email: '', password: '' });
  };

  /**
   * バリデーション
   */
  const isValidateForm = (email: string, password: string) => {
    let isValidate = false;
    if (email === '') {
      setError({ ...error, email: VALIDATION.EMAIL.EMPTY });
      isValidate = true;
    } else if (email !== '' && !isValidateEmail(email)) {
      setError({ ...error, email: VALIDATION.EMAIL.INCORRECT });
      isValidate = true;
    } else if (password === '') {
      setError({ ...error, password: VALIDATION.PASSWORD.EMPTY });
      isValidate = true;
    }
    return isValidate;
  };

  /**
   * ログインエラーが発生した際の処理
   */
  useEffect(() => {
    if (errLogin === 'auth/only-org-user') return;
    if (errLogin || errEmailVerification) {
      showErrorDialog([errLogin ?? '', errEmailVerification ?? '']);
    }
  }, [errLogin, errEmailVerification]);

  /**
   * ログインステータスによってアクション振り分け
   */
  useEffect(() => {
    switch (status) {
      case LoginStatus.NotInitialized:
      case LoginStatus.NotLogin:
        break;
      case LoginStatus.LoginNotEmailVerified:
        if (loginLoading) {
          if (process.env.REACT_APP_ENVIRONMENT == 'local') {
            emailVerification(`http://${process.env.REACT_APP_WEBHOST}/signup/nickname`);
          } else {
            emailVerification(`https://${process.env.REACT_APP_WEBHOST}/signup/nickname`);
          }
        }
        break;
      case LoginStatus.LoginEmailVerified:
        showNotificationDialog(['ログインしました。', 'ニックネームの登録をお願いします。'], () => {
          history.replace({ pathname: '/signup/nickname' });
          closeDialog();
        });
        break;
      case LoginStatus.LoginNicknameRegistered:
        // 別画面からリダイレクトしてきた場合は、下の画面に戻す
        if (location.state?.from) {
          history.replace(location.state?.from);
          break;
        }

        // MEMO(oomura): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
        const pathname = isEnterprise ? '/enterprise/scout' : `/user/${user.nickname}`;
        history.replace({ pathname });
        showSuccessPopup('ログインしました');
        break;
      default:
        throw new Error('Unexpected route');
    }
  }, [status, loginLoading]);

  /**
   * Modelの変更処理
   */
  const onChangeForm = (target: keyof Model, value: string) => {
    if (value.length > 0) {
      setModel({ ...model, [target]: value });
      setError({ ...error, [target]: '' });
    }
  };

  /**
   * Render
   * メール認証完了してない場合に表示
   */
  if (status === LoginStatus.LoginNotEmailVerified) {
    return (
      <NoticeFormTemplate stressPhrase={NOTICE_PHRASE.stress} normalPhrase={NOTICE_PHRASE.normal} />
    );
  }

  /**
   * Render
   */
  return (
    <StyledLoginForm onSubmit={onSubmit}>
      <StyledFormItem data-error={error.email.length > 0}>
        <StyledLabel htmlFor="email">メールアドレス</StyledLabel>
        <StyledInput
          onChange={(e) => onChangeForm('email', e.target.value)}
          name="email"
          type="text"
          placeholder="メールアドレスを入力"
        />
        <StyledErrorText>{error.email}</StyledErrorText>
      </StyledFormItem>
      <StyledFormItem data-error={error.password.length > 0}>
        <StyledLabel htmlFor="password">パスワード</StyledLabel>
        <StyledInput
          onChange={(e) => onChangeForm('password', e.target.value)}
          name="password"
          type="password"
          placeholder="パスワードを入力"
        />
        <StyledErrorText>{error.password}</StyledErrorText>
      </StyledFormItem>
      <StyledFormButtons>
        <PrimaryButton size="large" type="submit" disabled={loginLoading}>
          ログイン
        </PrimaryButton>
      </StyledFormButtons>
      <StyledLinkContainer>
        <StyleNavLink to="/forgot_pass/email">パスワードをお忘れの方はこちら</StyleNavLink>
      </StyledLinkContainer>
    </StyledLoginForm>
  );
};

export default Login;

// styled-components
const StyledLoginForm = styled.form`
  position: relative;
  width: 100%;
  max-width: 460px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 40px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-sizing: border-box;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;

  ${StyledLabel} + & {
    margin-top: 10px;
  }
`;

const StyledErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;

const StyledFormItem = styled.div`
  & + & {
    margin-top: 15px;
  }

  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }

  &[data-error='true'] ${StyledErrorText} {
    display: block;
  }
`;

const StyledFormButtons = styled.div`
  margin-top: 20px;
`;

const StyledLinkContainer = styled.div`
  margin-top: 15px;
  text-align: center;
`;

const StyleNavLink = styled(NavLink)`
  font-size: 12px;
  color: #2459bf;
  text-align: center;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
