import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { diffDate, formatDate, getDaysBetween, getTagCategory } from '@meettry/ui-components/utils';
import HighlightedText from '@meettry/ui-components/components/atoms/HighlightedText';
import { BasicTag, GrayRoundTag } from '@meettry/ui-components/components/atoms/Tag';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import icons from '@meettry/ui-components/images/icons';

type Props = {
  resume: any;
  onClick: (nickname: string) => void;
  searchWord?: string;
};

const filterResumeTags = (tagArray = [], tagKind: string) =>
  tagArray.filter((tag: { category: string }) => tag.category === getTagCategory(tagKind));

//TODO(aida) ユーザ詳細のレジュメコンポーネントと共通化するべき

/**
 * CassetteResume Component
 */
const CassetteResume: React.FC<Props> = (props) => {
  const history = useHistory();
  const { resume, searchWord = '', onClick } = props;
  const startDate = new Date(resume.workStartDate);
  const endDate = resume.workEndDate ? new Date(resume.workEndDate) : new Date();
  const tags =
    resume.tags && resume.tags.filter((tag: { category: string }) => tag.category === null);

  const resumeLanguageTags = filterResumeTags(resume.tags, 'language');
  const resumeFrameworkTags = filterResumeTags(resume.tags, 'framework');
  const resumeDatabaseTags = filterResumeTags(resume.tags, 'database');
  const resumeInfraTags = filterResumeTags(resume.tags, 'infrastructure');
  const resumeOsTags = filterResumeTags(resume.tags, 'os');
  const resumeToolTags = filterResumeTags(resume.tags, 'tool');

  return (
    <div onClick={() => onClick(resume.user?.nickname)}>
      <StyledContentHeader>
        <StyledUser>
          <CircleAvatar name="user_avatar" size="sm" src={resume.user?.profileImage?.url ?? null} />
          <span>{resume.user?.nickname ?? ''}</span>
        </StyledUser>
        <StyledUpdatedText>
          <img
            src={icons.gray.time}
            alt="最終更新日"
            width="17"
            style={{ marginRight: '5px', verticalAlign: '-5px' }}
          />
          {`Updated ${getDaysBetween(resume.updated)}`}
        </StyledUpdatedText>
      </StyledContentHeader>
      <StyledCassetteResume>
        <div>
          <StyledResumeProjectPeriod>
            <span>{formatDate(startDate)}</span>
            <span>|</span>
            <span>{formatDate(endDate)}</span>
            <span>{diffDate(startDate, endDate)}</span>
          </StyledResumeProjectPeriod>
        </div>
        <div>
          <StyledResumeProjectName>
            <HighlightedText keyword={searchWord}>{resume.title}</HighlightedText>
            <span>{resume.companyName}</span>
          </StyledResumeProjectName>
          <div style={{ marginTop: '20px' }}>
            <StyledTagList>
              {tags.map((tag: { name: string; displayName: string }) => (
                <BasicTag key={tag.name}>
                  <HighlightedText keyword={searchWord} isAllSame={true}>
                    {tag.displayName}
                  </HighlightedText>
                </BasicTag>
              ))}
            </StyledTagList>
          </div>
          <div style={{ marginTop: '10px' }}>
            <StyledSkillList>
              {resumeLanguageTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.code} alt="言語" width="14" />
                  </dt>
                  {resumeLanguageTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeFrameworkTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.framework} alt="フレームワーク" width="14" />
                  </dt>
                  {resumeFrameworkTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeDatabaseTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.db} alt="データベース" width="14" />
                  </dt>
                  {resumeDatabaseTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeInfraTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.server} alt="インフラ" width="14" />
                  </dt>
                  {resumeInfraTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeOsTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.pc} alt="OS" width="14" />
                  </dt>
                  {resumeOsTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeToolTags.length ? (
                <>
                  <dt>
                    <img src={icons.black.othercode} alt="ツール" width="14" />
                  </dt>
                  {resumeToolTags.map((tag: { name: string; displayName: string }) => (
                    <dd key={tag.name}>
                      <GrayRoundTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </GrayRoundTag>
                    </dd>
                  ))}
                </>
              ) : null}
            </StyledSkillList>
          </div>
          <div style={{ marginTop: '20px' }}>
            <StyledResumeProjectDescription>
              <HighlightedText keyword={searchWord}>{resume.workDetail}</HighlightedText>
            </StyledResumeProjectDescription>
          </div>
        </div>
      </StyledCassetteResume>
    </div>
  );
};

export default CassetteResume;

const StyledCassetteResume = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 20px 30px 30px 0;
  text-decoration: none;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 150px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 150px);
    padding-left: 30px;
    border-left: 1px solid #eeeeee;
  }
`;

const StyledResumeProjectPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;

  & > *:nth-child(1) {
    font-size: 16px;
  }

  & > *:nth-child(2) {
    margin-top: 8px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    margin-top: 8px;
  }

  & > *:nth-child(4) {
    font-size: 12px;
    margin-top: 13px;
  }
`;

const StyledResumeProjectName = styled.div`
  & > * {
    display: block;
  }

  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: #0fafa9;
    word-wrap: break-word;
    line-height: 1.2;
  }

  & > *:nth-child(2) {
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
  }
`;

const StyledTagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-left: -5px;
  & > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledSkillList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  & > dt,
  & > dd {
    padding: 5px;
  }

  & > * + dt {
    padding-left: 10px;
  }

  & img {
    vertical-align: middle;
  }
`;

const StyledResumeProjectDescription = styled.p`
  color: #333333;
  font-size: 14px;
  line-height: 1.75;
  word-break: break-word;
`;

const StyledContentHeader = styled.div`
  padding: 10px 30px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledUpdatedText = styled.span`
  font-size: 12px;
  color: #999999;
`;

const StyledUser = styled.div`
  display: inline-flex;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }

  & > *:nth-child(2) {
    font-size: 12px;
    color: #2459bf;
  }
`;
