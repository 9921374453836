import gql from 'graphql-tag';
import Users from '@meettry/ui-components/components/organisms/UserPage/Users';

export const CONFIRM_SEND_SCOUT_QUERY = gql`
  query {
    loginSession {
      user {
        organization {
          currentPlan {
            scoutUnitPrice
          }
        }
      }
    }
  }
`;

export type ConfirmSendScoutQueryResponse = {
  loginSession: {
    user: {
      organization: {
        currentPlan: {
          scoutUnitPrice: number;
        };
      };
    };
  };
};

export const FETCH_SCOUT_BY_ID_QUERY = gql`
  query($scoutId: ID!) {
    scout(id: $id) {
      id
      title
      comment
    }
  }
`;

/**
 * 詳細検索を除いた、スカウトリストのQuery
 */
export const FETCH_SCOUTS_QUERY = gql`
  query($first: Int!, $offset: Int!, $field: String!, $order: Order!) {
    loginSession {
      user {
        organization {
          scouts {
            count
            results(
              page: { first: $first, offset: $offset }
              orders: { field: $field, order: $order }
            ) {
              id
              title
              comment
              recruitmentCount
              scoutTags {
                isRequired
                tag {
                  displayName
                  category
                }
              }
              isActive
              users {
                chatReplied {
                  count
                }
                chatSent {
                  count
                }
                matched {
                  count
                }
                matchedInBookmarks {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * 詳細検索ができるスカウトリストのQuery
 */
export const SEARCH_SCOUTS_QUERY = gql`
  query(
    $first: Int!
    $offset: Int!
    $field: String!
    $order: Order!
    $idList: [ID]
    $searchText: String
    $includeInactive: Boolean
  ) {
    loginSession {
      user {
        organization {
          bookmarks {
            users {
              count
            }
          }
          scoutUsers {
            chatReplied {
              count
            }
            chatSent {
              count
            }
            matched {
              count
            }
            matchedInBookmarks {
              count
            }
          }
          scouts(
            filter: { idList: $idList, searchText: $searchText, includeInactive: $includeInactive }
          ) {
            count
            results(
              page: { first: $first, offset: $offset }
              orders: { field: $field, order: $order }
            ) {
              id
              title
              comment
              recruitmentCount
              scoutTags {
                isRequired
                tag {
                  displayName
                  category
                }
              }
              isActive
              users {
                chatReplied {
                  count
                }
                chatSent {
                  count
                }
                matched {
                  count
                }
                matchedInBookmarks {
                  count
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const FETCH_SCOUTS_BY_IDS_QUERY = gql`
  query(
    $idList: [ID]
    $field: String!
    $order: Order!
    $searchText: String
    $includeInactive: Boolean
  ) {
    loginSession {
      user {
        organization {
          scouts(
            filter: { idList: $idList, searchText: $searchText, includeInactive: $includeInactive }
          ) {
            all(orders: { field: $field, order: $order }) {
              id
              title
              comment
            }
          }
        }
      }
    }
  }
`;

/**
 * スカウト詳細情報
 */
const FRAG_SCOUT_USER = gql`
  fragment ScoutUser on User {
    id
    url
    nickname
    bio
    location
    isBookmarked
    userDetail {
      enableScoutChatNotifMail
      enableAcceptingScout
    }
    profileImage {
      url
    }
    profileImageThumbnail {
      url
    }
    chat {
      scoutRoomId
    }
  }
`;
export const FETCH_SCOUT_BY_ID = gql`
  query($id: ID!) {
    loginSession {
      user {
        organization {
          scout(id: $id) {
            id
            isDelete
            isActive
            deleted
            created
            updated
            title
            comment
            recruitmentStartAt
            recruitmentEndAt
            recruitmentCount
            scoutTags {
              isRequired
              years
              tag {
                id
                displayName
                category
              }
            }
          }
        }
      }
    }
  }
`;

/**
 * fetchScoutMatchedUsers
 * スカウトに対して、マッチしたユーザー郡を取得
 */
export const FETCH_SCOUT_MATCHED_USERS = gql`
  query($id: ID!, $first: Int!, $offset: Int!, $field: String!) {
    loginSession {
      user {
        organization {
          scout(id: $id) {
            users {
              chatReplied {
                results(
                  page: { first: $first, offset: $offset }
                  orders: { field: $field, order: ASC }
                ) {
                  ...ScoutUser
                }
                count
              }
              chatSent {
                results(
                  page: { first: $first, offset: $offset }
                  orders: { field: $field, order: ASC }
                ) {
                  ...ScoutUser
                }
                count
              }
              matched {
                results(
                  page: { first: $first, offset: $offset }
                  orders: { field: $field, order: ASC }
                ) {
                  ...ScoutUser
                }
                count
              }
              matchedInBookmarks {
                results(
                  page: { first: $first, offset: $offset }
                  orders: { field: $field, order: ASC }
                ) {
                  ...ScoutUser
                }
                count
              }
            }
          }
        }
      }
    }
  }
  ${FRAG_SCOUT_USER}
`;

/**
 * addScoutHandoverMessageQuery
 * ユーザーに対して、申し送りメッセージを追加
 */
export const ADD_SCOUT_COMMENT_QUERY = gql`
  mutation($userId: ID!, $text: String!) {
    addScoutComment(input: { targetUserId: $userId, text: $text }) {
      scoutComment {
        id
        isDelete
        deleted
        created
        updated
        authorUserId
        targetUserId
        text
        authorUser {
          id
          nickname
        }
      }
    }
  }
`;

export const SCOUT_COMMENT_QUERY = gql`
  query($userIds: [ID!]!) {
    loginSession {
      user {
        organization {
          scoutComments(targetUserIdList: $userIds) {
            id
            isDelete
            deleted
            created
            updated
            authorUserId
            targetUserId
            text
            authorUser {
              id
              nickname
            }
          }
        }
      }
    }
  }
`;

export const CREATE_SCOUT = gql`
  mutation($input: CreateScoutInput!) {
    createScout(input: $input) {
      scout {
        id
        isDelete
        deleted
        created
        updated
        organizationId
        title
        comment
        recruitmentStartAt
        recruitmentEndAt
        recruitmentCount
        isActive
        scoutTags {
          isRequired
          years
          tag {
            id
            displayName
            category
          }
        }
      }
    }
  }
`;

export const UPDATE_SCOUT = gql`
  mutation($input: UpdateScoutInput!) {
    updateScout(input: $input) {
      scout {
        id
        isDelete
        deleted
        created
        updated
        organizationId
        title
        comment
        recruitmentStartAt
        recruitmentEndAt
        recruitmentCount
        isActive
        scoutTags {
          isRequired
          years
          tag {
            id
            displayName
            category
          }
        }
      }
    }
  }
`;

export const DELETE_SCOUT = gql`
  mutation($id: ID!) {
    deleteScout(input: { id: $id }) {
      id
    }
  }
`;

/**
 * 全てのスカウトに対して条件がマッチするユーザーをカウントするQuery
 */
// export const SCOUT_USERS_QUERY = gql`
//
// `;

// export const SEND_SCOUT_QUERY = gql`
// mutation() {
//   addScoutHandoverMessage(input: {
//
//   }) {
//     ok
//   }
// }
// `;
