import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type BaseModalType = {
  Header: React.ReactNode;
  Footer: React.ReactNode;
  children: React.ReactNode;
};

const BaseModal: React.FC<BaseModalType> = (props) => {
  const { Header, children, Footer } = props;

  // MEMO(aida)モーダル表示時に後ろの要素をスクロールさせないための処理
  useEffect(() => {
    document.body.classList.add('modal-open');
    return () => {
      document.body.classList.remove('modal-open');
    };
  }, []);

  return (
    <StyledCover>
      <StyledModalBody>
        {Header && <StyledModalHeader>{Header}</StyledModalHeader>}
        {children}
        {Footer && <StyledModalFooter>{Footer}</StyledModalFooter>}
      </StyledModalBody>
    </StyledCover>
  );
};

export default BaseModal;

const StyledModalBody = styled.div`
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 800px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  box-shadow: 0 5px 20px ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
  max-height: 90vh;
  overflow-y: scroll;
`;

const StyledCover = styled.div`
  z-index: 9999;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
`;

const StyledModalHeader = styled.div`
  position: sticky;
  z-index: 9000;
  top: 0;
  left: 0;
`;
const StyledModalFooter = styled.div`
  position: sticky;
  z-index: 9000;
  bottom: 0;
  left: 0;
`;
