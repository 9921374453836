import React from 'react';
import styled from 'styled-components';
import { EngineerProfile } from '@meettry/company/component/molecules/engineerProfile';
import { Ellipsis } from '@meettry/company/component/atoms/ellipsis';
import { DonutGraph } from '@meettry/company/component/atoms/donutGraph';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { BasicTag } from '@meettry/ui-components/components/atoms/Tag';

const langList: Array<string> = ['Kubernetes', 'DevOps', 'Google Cloud Platform', 'Istio'];

type AchievementRateProps = {
  percent: number;
};
export const AchievementRate: React.FC<AchievementRateProps> = (props) => {
  return (
    <StyledAchievementRate>
      <DonutGraph percent={props.percent} />
      <StyledAchievementRateDesc>
        <StyledAchievementRateTitle>達成率</StyledAchievementRateTitle>
        <StyledAchievementRateNumber>{props.percent}</StyledAchievementRateNumber>
        <StyledAchievementRateUnit>%</StyledAchievementRateUnit>
      </StyledAchievementRateDesc>
    </StyledAchievementRate>
  );
};

export const FeaturedChallenge: React.FC = () => {
  return (
    <StyledFeaturedChallengeUl>
      {Array.from({ length: 3 }, (_, i) => (
        <StyledFeaturedChallengeLi key={i} margin="sections">
          <StyledFeaturedChallengeWrapper>
            <StyledFeaturedChallengeTitle>
              Kubernetesを使用したDevOpsの習得
            </StyledFeaturedChallengeTitle>
            <StyledTechStacks>
              {langList.map((item, index) => (
                <StyledBasicTag size="S" key={index}>
                  {item}
                </StyledBasicTag>
              ))}
            </StyledTechStacks>
            <Ellipsis
              lineNumber={3}
              cls="default-text"
              isWorkShorten={true}
              Tag="p"
              margin="sections"
            >
              Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloud
              Runも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります
            </Ellipsis>

            <EngineerProfile margin={8} />
          </StyledFeaturedChallengeWrapper>
          <AchievementRate percent={50} />
        </StyledFeaturedChallengeLi>
      ))}
    </StyledFeaturedChallengeUl>
  );
};

const StyledFeaturedChallengeUl = styled.ul`
  margin: 22px 0 0;
  padding: 0;
`;

const StyledFeaturedChallengeLi = styled.li<{ margin: 'contents' | 'sections' | number }>`
  display: flex;
  justify-content: space-between;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  ${({ margin }) =>
    margin &&
    `
    + li {
      margin-top: ${margin === 'contents' ? 50 : margin === 'sections' ? 20 : margin}px
    }
    `}
`;

const StyledFeaturedChallengeWrapper = styled.div`
  width: calc(100% - 170px);
`;

const StyledFeaturedChallengeTitle = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
`;

export const StyledTechStacks = styled.div`
  margin-top: 12px;
`;

export const StyledBasicTag = styled(BasicTag)`
  & + span {
    margin-left: 5px;
  }
`;

const StyledAchievementRate = styled.div`
  position: relative;
  width: auto;
  height: 100%;
  border-radius: 50%;
  text-align: center;
`;

const StyledAchievementRateDesc = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-weight: bold;
`;

const StyledAchievementRateTitle = styled.span`
  display: block;
  font-size: 12px;
`;

const StyledAchievementRateNumber = styled.span`
  font-size: 32px;
  line-height: 1.5;
`;

const StyledAchievementRateUnit = styled.span`
  font-size: 20px;
  margin-left: 4px;
`;
