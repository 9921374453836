import React, { useState, useEffect } from 'react';
import Loader from '@meettry/engineer/components/atoms/Loader';
import styled from 'styled-components';
import Breadcrumbs from '@meettry/engineer/components/atoms/Breadcrumbs';

/**
 * 定数
 */
// アプリケーションページの状態定数
const PAGE_STATUS = {
  LOADING: 'LOADING', // ローディング中
  INITIALIZE_LOAD_ERROR: 'INITIALIZE_LOAD_ERROR', // ページ表示に必要な初期情報の読み取りに失敗
  IN_SERVICE: 'IN_SERVICE' // 正常に画面が表示されている
};

/**
 * Application Page Template
 * アプリケーション画面共有のページテンプレート
 * breadcrumbs: ぱんくずリスト
 * pageType: 画面の幅の設定。 「 slim / その他 」
 */
const ApplicationPage = (props) => {
  const { children, pageType, loading, error, data, breadcrumbs = [] } = props;
  const [pageStatus, setPageStatus] = useState(PAGE_STATUS.LOADING);

  // ページ初期表示時に初期ロードが必要ない場合は最初からページを表示させる
  // loadingがundefinedのみ有効
  useEffect(() => {
    if (loading === void 0) setPageStatus(PAGE_STATUS.IN_SERVICE);
  }, []);

  // ページ初期表示時に初期ロードが必要な場合はここで、ページの表示を切り分け
  useEffect(() => {
    if (data && !error && !loading) {
      const dataKeys = Object.keys(data);
      if (dataKeys.filter((k) => data[k] !== null || data[k] !== void 0).length > 0) {
        setPageStatus(PAGE_STATUS.IN_SERVICE);
      } else {
        setPageStatus(PAGE_STATUS.INITIALIZE_LOAD_ERROR);
      }
    } else if (error) {
      setPageStatus(PAGE_STATUS.INITIALIZE_LOAD_ERROR);
    }
  }, [data, error, loading]);

  // ページステータスによってrenderを切り替え
  switch (pageStatus) {
    case PAGE_STATUS.LOADING:
      return <StyledLoader />;
    case PAGE_STATUS.INITIALIZE_LOAD_ERROR:
      return <p>Something was wrong with query ...</p>;
    case PAGE_STATUS.IN_SERVICE:
    default:
      return (
        <StyledApplicationPage>
          {breadcrumbs.length ? <StyledBreadcrumbs items={breadcrumbs} /> : null}
          <StyledLayoutSingleContent pageType={pageType}>{children}</StyledLayoutSingleContent>
        </StyledApplicationPage>
      );
  }
};

export default ApplicationPage;

/**
 * Styled Components
 */
const StyledLoader = styled(Loader)`
  && {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
const StyledApplicationPage = styled.div`
  flex: 1;
  padding: 70px 0 30px;
  background: #f7f7f7;
  position: relative;
`;
const StyledLayoutSingleContent = styled.div`
  margin: 0 auto;
  flex: 1;
  ${({ pageType }) => {
    switch (pageType) {
      case 'slim':
        return 'max-width: 900px';
      default:
        return 'max-width: 1200px';
    }
  }}
`;
const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: absolute;
  width: 100%;
  top: 0;
`;
