import React, { createContext, useReducer, Dispatch } from 'react';
import { ProviderComponent } from '@meettry/ui-components/types/context';

// actions
const SHOW_LOADING = 'SHOW_LOADING';
const HIDE_LOADING = 'HIDE_LOADING';

export const showLoading = (text: string) => ({
  type: SHOW_LOADING,
  payload: {
    text
  }
});

export const hideLoading = () => ({
  type: HIDE_LOADING,
  payload: { text: null }
});

export const initState = () => ({
  loading: {
    show: false,
    text: null
  }
});

const initialState = initState();

// reducer
const loadingReducer = (state: LoadingState = initialState, action: LoadingAction) => {
  const { text } = action.payload;
  switch (action.type) {
    case SHOW_LOADING:
      return { ...state, loading: { show: true, text } };
    case HIDE_LOADING:
      return { ...state, loading: { show: false, text } };
    default:
      return state;
  }
};

type LoadingState = {
  loading: {
    show: boolean;
    text: string | null;
  };
};
type Action<T, U> = { type: T; payload: U };

type LoadingOption = { text: string | null };

type LoadingAction = Action<string, LoadingOption>;

type LoadingDispatch = Dispatch<LoadingAction>;
//context
type LoadingStore = {
  state: LoadingState;
  dispatch: LoadingDispatch;
};
const defaultStore: LoadingStore = {
  state: initialState,
  dispatch: () => initialState
};

// context
export const LoadingContext = createContext<LoadingStore>(defaultStore);

// Provider
export const LoadingProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(loadingReducer, initialState);
  return <LoadingContext.Provider value={{ state, dispatch }}>{children}</LoadingContext.Provider>;
};
