import React, { useEffect, useState, useMemo, ChangeEvent, FormEvent } from 'react';
import styled from 'styled-components';
import firebase, { firebaseError } from '@meettry/ui-components/utils/firebase';
import { useHistory } from 'react-router-dom';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { FormItem } from '@meettry/engineer/components/molecules/Form';
import { TextField } from '@meettry/engineer/components/atoms/Input';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import { VALIDATION } from '@meettry/engineer/utils/Constant';
import { isValidatePassword } from '@meettry/engineer/utils';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import useLoading from '@meettry/ui-components/hooks/useLoading';

type Props = {
  actionCode?: string;
};

/**
 * ResetPasswordForm
 * パスワードリセットフォーム
 */
const ResetPasswordForm: React.FC<Props> = (props) => {
  const { actionCode = '' } = props;
  const history = useHistory();
  const {
    status,
    user: { nickname },
    isEnterprise
  } = useLoginUser();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const { startLoading, endLoading } = useLoading();
  const [passwordInput, setPasswordInput] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [inputError, setInputError] = useState(false);

  // MEMO(oomura): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
  const redirectingUrl = useMemo(
    () => (isEnterprise ? '/enterprise/scout' : nickname ? `/user/${nickname}` : '/login'),
    [nickname, isEnterprise]
  );

  /**
   * フォームチェンジメソッド
   */
  const onChangePassword = (e: ChangeEvent<HTMLInputElement>) => {
    setPasswordInput(e.target.value);
    const isNotValidInput = isValidatePassword(e.target.value);
    if (isNotValidInput) {
      setIsValid(false);
      setInputError(true);
      return;
    }
    setIsValid(true);
    setInputError(false);
  };

  /**
   * 変更したパスワードを送信
   */
  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const newPassword = passwordInput;
    firebase
      .verifyPasswordResetCode(actionCode)
      .then((email: string) => {
        firebase
          .confirmPasswordReset(actionCode, newPassword)
          .then(async (_res) => {
            startLoading('パスワードをリセットしています');
            // パスワードがリセットされた際に新しいパスワードを使ってログインさせる
            await firebase
              .signInWithEmailAndPassword(email, newPassword)
              .then(() => {
                history.push(redirectingUrl);
              })
              .catch((err) => {
                const message = firebaseError(err) ?? '';
                showErrorPopup(message);
              });
            showSuccessPopup('パスワードを変更してログインします');
          })
          .catch((err) => {
            const message = firebaseError(err) ?? '';
            showErrorPopup(message);
          })
          .finally(() => {
            endLoading();
          });
      })
      .catch((err) => {
        const message = firebaseError(err) ?? '';
        showErrorPopup(message);
      });
  };

  /**
   * Render
   */
  return (
    <StyledResetPassowrdForm onSubmit={onSubmit}>
      <div>
        <StyledFormTitle>パスワードの再設定</StyledFormTitle>
      </div>
      <StyledFormContainer>
        <FormItem
          label="新しいパスワード"
          error={inputError}
          errorText={VALIDATION.PASSWORD.INCORRECT}
        >
          <TextField
            type="password"
            placeholder="新しいパスワードを入力"
            onChange={onChangePassword}
            defautlValue={passwordInput}
            error={inputError}
          />
        </FormItem>
      </StyledFormContainer>
      <StyledSupplement>
        パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、
        <br />
        記号の全てを含んだものを指定してください。
      </StyledSupplement>
      <StyledFormButtons>
        <PrimaryButton disabled={!isValid} type="submit">
          変更
        </PrimaryButton>
      </StyledFormButtons>
    </StyledResetPassowrdForm>
  );
};

export default ResetPasswordForm;

const StyledResetPassowrdForm = styled.form`
  padding: 40px 50px;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
`;

const StyledFormTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;

const StyledFormContainer = styled.div`
  margin-top: 20px;
`;

const StyledFormButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  > * {
    max-width: 100px;
    min-height: 40px;
  }
`;

const StyledSupplement = styled.div`
  padding-left: 170px;
  margin-top: 30px;
  font-size: 14px;
`;
