import React from 'react';
import {
  Modal,
  MODAL_TYPE_EDIT,
  MODAL_TYPE_NOTICE,
  ModalProps
} from '@meettry/ui-components/components/organisms/Modal/Modal';

type InjectProps = Pick<ModalProps, 'type'>;

const Generator = (injectProps: InjectProps) => {
  return (props: Omit<ModalProps, 'type'>) => <Modal {...props} {...injectProps} />;
};

export const EditModal = Generator({ type: MODAL_TYPE_EDIT });
export const NoticeModal = Generator({ type: MODAL_TYPE_NOTICE });
