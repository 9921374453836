import React, { useCallback, useMemo, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { TopBtn } from '@meettry/ui-components/components/organisms/Tops/utils';
import { useLoginUser, useLogout } from '@meettry/ui-components/utils/auth';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { HeaderUserProfile } from '@meettry/ui-components/components/molecules/HeaderUserProfile';
import { useTopPath } from '@meettry/ui-components/hooks/useTopPath';
import iconHeaderChat from '@meettry/ui-components/images/header/icon-header-chat.png';
import useChatNotifications from '@meettry/ui-components/hooks/chat/useChatNotifications';
import ActionMenu, { ActionMenuChatItem } from '@meettry/ui-components/components/atoms/ActionMenu';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const HeaderMember: React.FC = () => {
  const { isEnterprise } = useLoginUser();
  const [onHoverItem, updateHoverItem] = useState<string | null>(null);
  const { notifications, markAsRead } = useChatNotifications();

  const handleResetHoverItem = useCallback(() => {
    updateHoverItem('');
  }, []);

  const handleOnHoverMenuItem = (name: string) => (e?: any) => {
    e.preventDefault();
    if (name === onHoverItem) return;
    if (e.type === 'mouseenter') {
      updateHoverItem(name);
    }
  };

  const readAll = () => {
    if (notifications.length === 0) return;
    notifications.forEach(notification => markAsRead(notification.messageId));
  };
  const chatNotificationMenus = useMemo(() => notifications.map((notification) => ({
    component: <ActionMenuChatItem nickname={notification.messageContent.senderName} message={notification.messageContent.body} createdAt={notification.messageContent.createdAt} />,
    link: isEnterprise ? `/enterprise/chat/${notification.roomId}` : `/chat/${notification.roomId}`
  })), [isEnterprise, notifications]);

  return (
    <StyledHeaderMemberWrapper>
      <StyledIconWrap activeBadge={notifications.length > 0}>
        <StyledIcon src={iconHeaderChat} alt="チャット" width="24px" onMouseEnter={handleOnHoverMenuItem('chat')} />
        <ActionMenu
          width="400px"
          menus={chatNotificationMenus}
          title="チャット通知"
          isOpen={onHoverItem === 'chat'}
          onClose={handleResetHoverItem}
          header={<StyledActionMenuLink to={isEnterprise ? '/enterprise/chat' : '/chat'}>全てのメッセージを見る</StyledActionMenuLink>}
          footer={<StyledChatActionMenuFooter disabled={notifications.length === 0}><span onClick={readAll}>すべて既読にする</span></StyledChatActionMenuFooter>}
        />
      </StyledIconWrap>
      <HeaderUserProfile />
    </StyledHeaderMemberWrapper>
  );
};

export default HeaderMember;

type HamburgerHeaderMemberType = {
  closeMenu: (cb?: any) => void;
};

export const HamburgerHeaderMember: React.FC<HamburgerHeaderMemberType> = ({ closeMenu }) => {
  const [logout] = useLogout();
  const history = useHistory();
  const topPath = useTopPath();
  return (
    <>
      <StyledLogoutBtn
        color="outlined"
        size="small"
        onClick={() => {
          logout();
          closeMenu();
          history.push('/');
        }}
      >
        ログアウト
      </StyledLogoutBtn>
      <StyledMypageBtn
        color="contained"
        size="small"
        onClick={() => {
          closeMenu();
          history.push(topPath);
        }}
      >
        マイページへ
      </StyledMypageBtn>
    </>
  );
};

const StyledHeaderMemberWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 25px;
  }
`;

const StyledIcon = styled.img`
  && {
    vertical-align: middle;
		cursor: pointer;
		&& {
			transition: opacity 0.1s;
			&:hover {
				opacity: 0.8;
			}
		}
  }
`;

const StyledIconWrap = styled.div<{ activeBadge: boolean }>`
  position: relative;
  
  &::before {
    position: absolute;
    top: 0;
    right: 0;
    content: '';
    display: none;
    width: 8px;
    height: 8px;
    background-color: ${ COLOR_DEFINITIONS.TEXT.ALERT };
    border-radius: 8px;
    
    ${({ activeBadge }) => (activeBadge ? `
      display: block;
    `: '')}
  }
`;

const StyledLogoutBtn = styled(TopBtn)`
  margin-top: 0;
  ${mediaQuery.greaterThan('basePCWidth')`
padding: 10px 40px;
`}
  ${mediaQuery.between('breakPointPC', 'basePCWidth')`
  padding: 10px 16px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  margin: 0 auto 20px;
  `}
`;

const StyledMypageBtn = styled(StyledLogoutBtn)`
  ${mediaQuery.greaterThan('breakPoint')`
margin: 0 10px 0 0;
`}
  ${mediaQuery.lessThan('breakPoint')`
margin: 0 auto 20px auto;
`}
`;

const StyledActionMenuLink = styled(Link)`
  font-size: 12px;
  color: ${ COLOR_DEFINITIONS.MAIN.SECONDARY };
`;

const StyledChatActionMenuFooter = styled.div<{ disabled: boolean; }>`
  text-align: right;
  & > * {
    cursor: pointer;
		font-size: 12px;
		color: ${ COLOR_DEFINITIONS.MAIN.SECONDARY };
    
    ${({ disabled }) => ( disabled ? `
      color: ${ COLOR_DEFINITIONS.TEXT.SECONDARY };
      cursor: default;
    ` : '')}
  }
`;
