import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import icons from '@meettry/ui-components/images/icons';

const SearchNoResult = () => {
  return (
    <StyledSearchNoData>
      <img src={icons.primary.noData} alt="NO DATA" />
      <p>条件に一致する検索結果はありません</p>
      <p>キーワードを確認・変更して検索し直してください。</p>
    </StyledSearchNoData>
  );
};

export default SearchNoResult;

const StyledSearchNoData = styled.div`
  padding: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
  margin-top: 40px;

  img {
    width: 60px;
  }

  & > *:nth-child(2) {
    font-size: 20px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    font-weight: bold;
    margin-top: 30px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    margin-top: 20px;
  }
`;
