import React from 'react';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import styled from 'styled-components';

type TagType = {
  type?: string;
  size?: string;
  children?: React.ReactNode;
  onClick?: () => void;
  period?: string | void;
};

const Tag: React.FC<TagType> = (props) => {
  const { type, size, children, period } = props;
  return (
    <StyledTag {...props} type={type} size={size}>
      {children}
      {period ? <StyledTechPeriod>{period}</StyledTechPeriod> : null}
    </StyledTag>
  );
};

export default Tag;

const getStyleType = (props: TagType) => {
  switch (props.type) {
    case 'line':
      return `
        border: 1px solid ${COLOR_DEFINITIONS.MAIN.SECONDARY};
        background-color: ${COLOR_DEFINITIONS.BG.WHITE};
        border-radius: 50vh;
      `;
    case 'gray':
      return `
        color: ${COLOR_DEFINITIONS.BG.FOOTER};
        background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
      `;
    case 'grayRound':
      return `
        color: ${COLOR_DEFINITIONS.BG.FOOTER};
        border-radius: 50vh;
        background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
      `;
    case 'required':
      return `
        padding: 3px 5px;
        font-size: 10px;
        color: ${COLOR_DEFINITIONS.TEXT.ALERT};
        border-radius: 50vh;
        background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
      `;
    default:
      return ``;
  }
};

const getStyleSize = (props: TagType) => {
  switch (props.size) {
    case 'S':
      return `
        font-size: 12px;
        padding: 3px 8px;
      `;
    default:
      return ``;
  }
};

const StyledTag = styled.span<TagType>`
  display: inline-block;
  font-size: 14px;
  padding: 5px 10px;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.TECH_STACK};
  text-align: center;
  border-radius: 3px;
  color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
  ${(props) => getStyleSize(props)}
  ${(props) => getStyleType(props)}
`;

const StyledTechPeriod = styled.span`
  margin-left: 10px;
`;
