import gql from 'graphql-tag';

/**
 * Customer ユーザー登録
 */
export const SIGNUP_USER_QUERY = gql`
    mutation ($email: String!, $password: String!) {
        registerUser(input: {
            email: $email
            password: $password
        }) {
            ok
        }
    }
`;

/**
 * Customer ユーザー登録後のニックネーム登録
 */
export const SIGNUP_NICKNAME_QUERY = gql`
  mutation ($nickname: String!) {
    signup(input:{
      nickname: $nickname
    }) {
      ok
    }
  }
`;
