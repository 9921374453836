import gql from 'graphql-tag';
import {
  FRAG_RESUME_LIST,
  FRAG_CHALLENGE_LIST
} from '@meettry/ui-components/queries/user_page/fragments';

// MEMO(aida) エンジニア詳細画面にて必要な情報を取得する。
// 対象のエンジニアの詳細
// 対象のエンジニアのチャレンジ(challenge)
// 対象のエンジニアの経歴(resume)
// 対象のエンジニアの更新履歴(activities)
// タグのリスト
export const FETCH_ENGINEER = gql`
  query FetchEngineer($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      id
      nickname
      totalView
      bio
      location
      lastUpdated
      url
      userDetail {
        jobSeekingLevel
        enableAcceptingScout
      }
      chat {
        scoutRoomId
      }
      workStyles {
        id
        name
      }
      profileImage {
        url
        updated
      }
      points {
        total
      }
      scoutCount
      workStyles {
        id
        name
      }
      followers {
        nickname
      }
      followees {
        nickname
      }
      scouteeRating
      activities {
        id
        category
        relatedId
        created
      }
      challengeList {
        ...ChallengeList
      }
      resumeList {
        ...ResumeList
      }
      userTags {
        userId
        tagId
        days
        tag {
          id
          name
          displayName
          category
          techStackId
        }
        resumes {
          workEndDate
        }
      }
    }
    tagList {
      id
      name
      displayName
      category
      techStackId
    }
  }

  ${FRAG_CHALLENGE_LIST}
  ${FRAG_RESUME_LIST}
`;

export const FETCH_ENGINEER_ACTIVITIES = gql`
  query FetchEngineerActivities($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      id
      activities {
        id
        category
        relatedId
        created
      }
      challengeList {
        ...ChallengeList
      }
      resumeList {
        ...ResumeList
      }
    }
  }

  ${FRAG_CHALLENGE_LIST}
  ${FRAG_RESUME_LIST}
`;
