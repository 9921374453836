import React, { useState, useRef, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type EllipsisProps = {
  children: string;
  lineNumber: number;
  cls?: string;
  isWorkShorten: boolean;
  Tag: React.ElementType;
  margin?: 'sections' | number;
};

export const getStyleSize = (elm: HTMLElement, propertyName: string) => {
  if (!elm) return 0;
  return parseInt(window.getComputedStyle(elm).getPropertyValue(propertyName));
};

export const useShortenedText = (
  elm: HTMLElement | null,
  lineNumber: number,
  changeEllipsis: Function,
  text: string
) => {
  const [didMounted, setMount] = useState<Boolean>(false);
  const isHalfChar = new RegExp(/[ -~]/);

  useEffect(() => {
    setMount(true);
  }, []);

  const ellipsisText: Array<string> = useMemo(() => {
    if (!elm) return [];
    const textElHeight = elm.clientHeight;
    const lineHeight = getStyleSize(elm, 'line-height');
    changeEllipsis(textElHeight > lineNumber * lineHeight);
    if (textElHeight <= lineNumber * lineHeight) return [text];

    const fontSize = getStyleSize(elm, 'font-size');
    const textElWidth = elm.clientWidth;
    const textLimit = Math.floor(textElWidth / fontSize);
    const textContent = text;
    const charsArray = textContent.split('');
    const newTextContents: Array<string> = [];
    let wordCount = 0;
    let textStartNumber = 0;
    //半角文字を0.5文字としてカウント
    charsArray.some((chr, index) => {
      wordCount += chr.match(isHalfChar) ? 0.5 : 1;
      if (wordCount === textLimit || wordCount === textLimit - 0.5) {
        newTextContents.push(textContent.substr(textStartNumber, index - textStartNumber));
        textStartNumber = index;
        wordCount = 0;

        // 必要な要素が揃ったらループ終了
        return newTextContents.length === lineNumber;
      }
    });

    const newTextContentsLast = newTextContents[lineNumber - 1];
    const isLastTextHalfChar = newTextContentsLast
      .substr(newTextContentsLast.length - 1, 1)
      .match(isHalfChar);
    newTextContents[lineNumber - 1] = newTextContentsLast.substr(
      0,
      newTextContentsLast.length - (isLastTextHalfChar ? 2 : 1)
    );
    newTextContents[lineNumber - 1] = newTextContents[lineNumber - 1] + '...';

    return newTextContents;
  }, [didMounted]);
  return ellipsisText;
};

export const Ellipsis: React.FC<EllipsisProps> = (props) => {
  const { Tag, lineNumber, children, cls, isWorkShorten, margin } = props;
  const [isEllipsis, changeEllipsis] = useState(false);
  const textEl = useRef<HTMLParagraphElement>(null);

  const shortenedText = useShortenedText(textEl.current, lineNumber, changeEllipsis, children);

  return (
    <>
      {Tag === 'p' ? (
        <StyledEllipsisParagraph ref={textEl} cls={cls} isEllipsis={isEllipsis} margin={margin}>
          {isWorkShorten && isEllipsis
            ? shortenedText.map((text, index) => <span key={index}>{text}</span>)
            : children}
        </StyledEllipsisParagraph>
      ) : Tag === 'span' ? (
        <StyledEllipsisBlock ref={textEl} cls={cls} isEllipsis={isEllipsis} margin={margin}>
          {isWorkShorten && isEllipsis
            ? shortenedText.map((text, index) => <span key={index}>{text}</span>)
            : children}
        </StyledEllipsisBlock>
      ) : null}
    </>
  );
};

const StyledEllipsisParagraph = styled.p<{
  cls?: string;
  customer?: boolean;
  isEllipsis: boolean;
  margin?: 'sections' | number;
}>`
  display: block;
  line-height: 1.5;

  ${({ margin }) =>
    `margin-top: ${margin === 'sections' ? 20 : typeof margin === 'number' ? margin : 50}px;`}

  ${({ isEllipsis }) =>
    isEllipsis
      ? `
      overflow-y: hidden;
    `
      : ''}

  //default-text
  ${({ cls }) =>
    cls === 'default-text'
      ? `
      font-size: 14px;
      color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    `
      : null}
  ${({ cls, customer }) =>
    cls === 'default-text' && customer
      ? `
    margin-top: 21px;
    line-height: 24px;
    `
      : null}

  //challengeList-title
  ${({ cls }) =>
    cls === 'challengeList-title'
      ? `
        font-size: 18px;
        font-weight: bold;
        color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    `
      : null}

  //engineer-list-desc
  ${({ cls }) =>
    cls === 'engineer-list-desc'
      ? `
        font-size: 14px;
        color: ${COLOR_DEFINITIONS.TEXT.MAIN};
        font-weight: 500;
        line-height: 24px;
    `
      : null}

  //engineer-list-subtitle
  ${({ cls }) =>
    cls === 'engineer-list-subtitle'
      ? `
        margin-top: 10px;
        color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        font-size: 18px;
        font-weight: bold;
        line-height: 31px;
    `
      : null}
`;

const StyledEllipsisSpan = StyledEllipsisParagraph.withComponent('span');
const StyledEllipsisBlock = styled(StyledEllipsisSpan)`
  display: inline-block;
`;
