import React, { useReducer, useState, useEffect } from 'react';
import * as Validation from '@meettry/company/utils/validation';
import { Button } from '@meettry/company/component/atoms/btn';
import {
  CompanyName,
  PostalCode,
  StreetAddress,
  PhoneNumber,
  Url,
  CorporateLogo
} from '@meettry/company/component/atoms/input';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { GET_CORPORATE_INFO, UPDATE_ORG } from '@meettry/ui-components/queries/corporateInfo';
import { useLoginStatus } from '@meettry/ui-components/utils/firebase';
import Loader from '@meettry/ui-components/components/atoms/Loader';

type CorporateInfoDataType = {
  id: string;
  name: string;
  phone: string;
  postCode: string;
  address: string;
  url: string;
};

type CorporateInfoType = {
  setState: React.Dispatch<React.SetStateAction<boolean>>;
  data: CorporateInfoDataType | null;
};

export type InputCompanyProps = {
  companyName: string;
  corporateLogo: string;
  postalCode: Array<string>;
  streetAddress: string;
  phoneNumber: Array<string>;
  url: string;
};

const initialState: InputCompanyProps = {
  companyName: '',
  corporateLogo: '',
  postalCode: ['', ''],
  streetAddress: '',
  phoneNumber: ['', '', ''],
  url: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;
const CHANGE_TEXT_NUMBER = 'CHANGE_TEXT_NUMBER' as const;

type InputAction = {
  type: typeof CHANGE_TEXT | typeof CHANGE_TEXT_NUMBER;
  text: string;
  item: keyof InputCompanyProps;
  stateArray: 'postalCode' | 'phoneNumber';
  num: number;
};

const changeInput: React.Reducer<InputCompanyProps, InputAction> = (
  state: InputCompanyProps,
  action: InputAction
) => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return { ...state, [action.item]: action.text };
    case 'CHANGE_TEXT_NUMBER':
      const itemCopy = state[action.stateArray];
      itemCopy[action.num] = action.text;
      return { ...state, [action.item]: itemCopy };
    default:
      return state;
  }
};

const CorporateInfoTable: React.FC<CorporateInfoType> = ({ setState, data }) => {
  const billCorporateInfo = [
    { heading: '企業名', data: data ? data.name : '', type: 'name' },
    { heading: '企業ロゴ', data: '', type: 'image' },
    { heading: '郵便番号', data: data ? data.postCode : '', type: 'postCode' },
    { heading: '住所', data: data ? data.address : '', type: 'address' },
    { heading: '電話番号', data: data ? data.phone : '', type: 'phone' },
    { heading: 'URL', data: data ? data.url : '', type: 'url' }
  ];

  return (
    <div>
      <div className="corporateInfo-table-wrapper">
        <table className="corporateInfo-table">
          <tbody>
            {billCorporateInfo.map((item, index) => (
              <tr key={index}>
                <td>{item.heading}</td>
                <td>{item.data}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="btn-container">
        <Button
          buttonText="変更する"
          cls="btn primary middle"
          onClick={() => {
            setState(true);
          }}
        />
      </div>
    </div>
  );
};

const CorporateInfoInput: React.FC<CorporateInfoType> = ({ setState, data }) => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [companyNameError, setCompanyNameError] = useState('');
  const [streetAddressError, setStreetAddressError] = useState('');
  const [phoneNumberError, setPhoneNumberError] = useState('');
  const [urlError, setUrlError] = useState('');
  const [postalCodeError, setPostalCodeError] = useState('');
  const [updateOrg, { data: org, called, loading }] = useMutation(UPDATE_ORG);

  const dispatchArray = (array: Array<string>) => {
    if (array.length === 1) return;
    array.forEach((li, i) => {
      dispatch({
        type: 'CHANGE_TEXT_NUMBER',
        text: li,
        item: array.length === 2 ? 'postalCode' : 'phoneNumber',
        stateArray: array.length === 2 ? 'postalCode' : 'phoneNumber',
        num: i
      });
    });
  };

  useEffect(() => {
    if (!data) return;
    const phone = data.phone.split('-');
    const postCode = data.postCode.split('-');
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.name,
      item: 'companyName',
      stateArray: 'phoneNumber',
      num: 0
    });
    //TODO(minami): ロゴ保存のAPIが実装されたら dispatch({ type: 'CHANGE_TEXT', text: "", item: "corporateLogo" });
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.address,
      item: 'streetAddress',
      stateArray: 'phoneNumber',
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: data.url,
      item: 'url',
      stateArray: 'phoneNumber',
      num: 0
    });
    dispatchArray(postCode);
    dispatchArray(phone);
  }, []);

  useEffect(() => {
    if (
      data &&
      companyNameError === 'none' &&
      streetAddressError === 'none' &&
      phoneNumberError === 'none'
    ) {
      updateOrg({
        variables: {
          input: {
            id: data.id,
            postCode: state.postalCode.join('-'),
            phone: state.phoneNumber.join('-'),
            address: state.streetAddress,
            name: state.companyName,
            url: state.url
          }
        }
      });
    }
  }, [companyNameError, streetAddressError, phoneNumberError]);

  useEffect(() => {
    if (called && !loading) {
      setState(false);
    }
  }, [called, loading]);

  return (
    <>
      <div className="corporate-info-contents">
        <CompanyName
          companyNameText="企業名"
          require={true}
          state={state.companyName}
          stateKey="companyName"
          dispatch={dispatch}
          error={companyNameError}
        ></CompanyName>
        <CorporateLogo
          require={false}
          state={state.corporateLogo}
          stateKey="corporateLogo"
          dispatch={dispatch}
        ></CorporateLogo>
        <PostalCode
          state={state.postalCode}
          stateKey="postalCode"
          dispatch={dispatch}
          error={postalCodeError}
        ></PostalCode>
        <StreetAddress
          require={true}
          state={state.streetAddress}
          stateKey="streetAddress"
          dispatch={dispatch}
          error={streetAddressError}
        ></StreetAddress>
        <PhoneNumber
          require={true}
          state={state.phoneNumber}
          stateKey="phoneNumber"
          dispatch={dispatch}
          error={phoneNumberError}
        ></PhoneNumber>
        <Url state={state.url} stateKey="url" dispatch={dispatch} error={urlError}></Url>
      </div>
      <div className="btn-container">
        <Button buttonText="キャンセル" cls="btn third middle" onClick={() => setState(false)} />
        <Button
          buttonText="決定"
          cls="btn primary middle"
          onClick={() =>
            Validation.company(
              state,
              setCompanyNameError,
              setPostalCodeError,
              setStreetAddressError,
              setPhoneNumberError,
              setUrlError
            )
          }
        />
      </div>
    </>
  );
};

const CorporateInfo: React.FC = () => {
  const [isEdit, setIsEdit] = useState(false);
  const { user } = useLoginStatus();
  const [getCorporateInfo, { data, loading }] = useLazyQuery(GET_CORPORATE_INFO, {
    variables: { id: user.organizationId }
  });

  useEffect(() => {
    if (!user.organizationId) return;
    getCorporateInfo();
  }, [user]);

  return (
    <div className="setting-container corporate-info">
      <h2>企業情報</h2>
      {loading ? (
        <Loader></Loader>
      ) : isEdit ? (
        <CorporateInfoInput setState={setIsEdit} data={data ? data.organization : null} />
      ) : (
        <CorporateInfoTable setState={setIsEdit} data={data ? data.organization : null} />
      )}
    </div>
  );
};

export default CorporateInfo;
