import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ChatMessage, DateGroupMessages } from '@meettry/ui-components/types/chat';
import { FriendMessage, UserMessage } from '@meettry/ui-components/components/atoms/ChatMessage';
import { DATE_FORMAT, formatDate } from '@meettry/ui-components/utils';
import Loader from '@meettry/ui-components/components/atoms/Loader';

type Props = {
  messages: DateGroupMessages | null;
  ownId: string; // 自分自身のID
  getPrev: () => void;
  markAsRead: (messageId: string) => void;
  loadingOlders: boolean;
  errorOlders: any;
  loadingNewers: boolean;
  errorNewers: any;
  hasNewMessagesOlders: boolean | null;
  targetImageUrl?: string;
};

const renderMessages = (
  dateString: string,
  messages: ChatMessage[],
  ownId: string,
  markAsRead: Props['markAsRead'],
  targetImageUrl: Props['targetImageUrl']
) => {
  return (
    <React.Fragment key={dateString}>
      <StyledMessageDate>{dateString}</StyledMessageDate>
      {messages.map((message) => {
        markAsRead(message.id);
        const Message =
          ownId !== message.senderId ? (
            <StyledFriendMessage
              key={message.id}
              timeString={formatDate(message.createdAt, DATE_FORMAT.TIME)}
              imagePath={targetImageUrl}
            >
              {message.body}
            </StyledFriendMessage>
          ) : (
            <StyledUserMessage
              key={message.id}
              timeString={formatDate(message.createdAt, DATE_FORMAT.TIME)}
            >
              {message.body}
            </StyledUserMessage>
          );
        return Message;
      })}
    </React.Fragment>
  );
};

/**
 * ChatMessages
 */
const ChatMessages: React.FC<Props> = (props) => {
  const {
    messages,
    ownId,
    getPrev,
    markAsRead,
    loadingOlders,
    errorOlders,
    hasNewMessagesOlders,
    loadingNewers,
    errorNewers,
    targetImageUrl
  } = props;

  /**
   * state
   */
  const [lockMessagesScroll, setLockMessagesScroll] = useState<boolean | null>(null);

  /**
   * hooks
   */
  const listRef = useRef<HTMLOListElement | null>(null);
  const prevLockMessagesScroll = useRef<boolean>(lockMessagesScroll);

  /**
   * Func: fetchPrevMessages
   */
  const fetchPrevMessages = () => {
    getPrev();
    // setLockMessagesScroll(true);
  };

  /**
   * メッセージが更新されたら、メッセージリストの最下部までスクロールさせる
   */
  useEffect(() => {
    if (!listRef.current) return;
    if (loadingOlders) return;
    listRef.current.scrollTop = listRef.current?.scrollHeight || 0;
  }, [messages, loadingOlders]);
  return (
    <StyledChatMessages ref={listRef}>
      <>
        {errorOlders && <div>{errorOlders}</div>}
        {loadingOlders || loadingNewers ? (
          <Loader />
        ) : (
          <StyledGetOlderMessageButton
            onClick={fetchPrevMessages}
            disabled={loadingOlders || (hasNewMessagesOlders !== null && !hasNewMessagesOlders)}
          >
            {hasNewMessagesOlders === false ? '全て取得しました' : 'より以前を取得'}
          </StyledGetOlderMessageButton>
        )}
        {messages &&
          Object.keys(messages).map((dateString: string) =>
            renderMessages(dateString, messages[dateString], ownId, markAsRead, targetImageUrl)
          )}
        {errorNewers && <div>{errorNewers}</div>}
      </>
    </StyledChatMessages>
  );
};
export default ChatMessages;

const StyledChatMessages = styled.ol`
  max-height: 600px;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 35px 40px;
  overflow-y: scroll;
`;

const StyledMessageDate = styled.li`
  width: 200px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f7f7f7;
  font-size: 12px;
  color: #333333;
  border-radius: 18px;
  margin-left: auto;
  margin-right: auto;

  * + & {
    margin-top: 40px;
  }
`;

const StyledFriendMessage = styled(FriendMessage)`
  margin-top: 20px;

  ${StyledMessageDate} + & {
    margin-top: 40px;
  }
`;

const StyledUserMessage = styled(UserMessage)`
  margin-top: 20px;

  ${StyledMessageDate} + & {
    margin-top: 40px;
  }
`;

const StyledGetOlderMessageButton = styled.button<{ disabled: boolean }>`
  appearance: none;
  display: block;
  width: 100%;
  max-width: 136px;
  margin: 0 auto;
  cursor: pointer;
  color: #0fafa9;
  border: 1px solid #0fafa9;
  background-color: #ffffff;
  border-radius: 15px;
  transition: all ease 0.3s;
  :hover {
    color: #ffffff;
    background-color: #0fafa9;
  }

  ${({ disabled }) => disabled && `display: none;`}
`;
