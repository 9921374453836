import React from 'react';

type ChallengeItemLogProps = {
  listItem: {
    id: string;
    date: string;
    time: string;
    before: number;
    after: number;
    updateText: string;
  }[];
  id: string;
  createdAt: string;
};

const ChallengeItemLog: React.FC<ChallengeItemLogProps> = (props) => {
  const { listItem, id, createdAt } = props;

  return (
    <div className="challnege-item-log">
      <span className="header">3 Update or Activity</span>
      <ul className="activity-log">
        {listItem.map((item, i) => {
          return (
            item.id === id && (
              <li className="log-wrap" key={i}>
                <div className="log-content">
                  <div className="title-wrap">
                    <span>Update</span>
                    <span>{item.date}</span>
                    <span>{item.time}</span>
                    <span className="progress">
                      {`${item.before}% > ${item.after}%`}
                    </span>
                  </div>
                  <p className="text">{item.updateText}</p>
                </div>
              </li>
            )
          );
        })}
        <li className="log-wrap">
          <div className="log-content">
            <span className="created">Created {createdAt}</span>
          </div>
        </li>
      </ul>
    </div>
  );
};

export default ChallengeItemLog;
