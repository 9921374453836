import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { TopBtn } from '@meettry/ui-components/components/organisms/Tops/utils';
import TopDecorate from '@meettry/ui-components/images/commons/top_decorate.png';

type mode = {
  mode?: 'company' | 'engineer';
};

type TopMainProps = mode & {
  messages: Array<string>;
  keyVisual: ReactNode;
  registerBtnMessage: string;
  registerBtnLocate: string;
  bgColor: string;
};

const TopMain: React.FC<TopMainProps> = (props) => {
  const history = useHistory();
  return (
    <TopMainVisual bgColor={props.bgColor}>
      <TopContainer>
        <TopMessageContainer mode={props.mode}>
          <TopMessages>
            {props.messages.map((str, i) => (
              <span key={i}>{str}</span>
            ))}
          </TopMessages>
          <RegisterBtn
            color="contained"
            size="midium"
            onClick={() => history.push(props.registerBtnLocate)}
          >
            {props.registerBtnMessage}
          </RegisterBtn>
        </TopMessageContainer>
        {props.keyVisual}
      </TopContainer>
    </TopMainVisual>
  );
};

export default TopMain;

// styled-components
export const TopMainVisual = styled.div<{
  bgColor?: string;
  isOpen?: boolean;
  mode?: 'company' | null;
  decorate?: string;
}>`
  background-color: ${(props) => props.bgColor};
  background-image: url(${TopDecorate});
  background-repeat: no-repeat;
  background-position: top 8px left 0;
  position: relative;
  &:before {
    content: '';
    display: block;
    position: absolute;
    background-color: #ffffff;
    width: 100%;
    height: 8px;
    top: 0;
    left: 0;
  }
  ${mediaQuery.greaterThan('breakPoint')`
    padding-top: 110px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
    background-size: 63%;
  `}
  ${mediaQuery.lessThan('sp')`
    background-position: top 0 left 0;
    background-size: 260px;
    &:before{
      display: none;
    }
  `}
`;

const TopContainer = styled.div`
  display: flex;
  overflow: hidden;
  ${mediaQuery.greaterThan('overPCSize')`
  justify-content: center;
  `}
  ${mediaQuery.lessThan('breakPoint')`
    flex-direction: column;
  `}
`;

const TopMessageContainer = styled.div<mode>`
  ${(props) => mediaQuery.greaterThan('breakPoint')`
  min-width: ${props.mode === 'company' ? '480px' : '440px'};
  padding: 200px 80px 200px 140px;
  `};
  ${mediaQuery.lessThan('breakPoint')`
  width: calc(100% - 80px);
    padding: 168px 40px 0;
    margin-bottom: 20px;
  `}
  ${mediaQuery.lessThan('minTablet')`
  width: calc(100% - 40px);
  padding: 172px 20px 0;
  `};
  ${(props) =>
    props.mode === 'company'
      ? `
    color: #FFFFFF;
  `
      : null}
`;

const TopMessages = styled.h1`
  span {
    display: block;
  }
  ${mediaQuery.greaterThan('breakPoint')`
  ${FONT_DEFINITIONS.TITLE};
  `}
  ${mediaQuery.lessThan('breakPoint')`
  ${FONT_DEFINITIONS.TABLET_TITLE};
text-align: center;
  `}
  ${mediaQuery.lessThan('minTablet')`
  ${FONT_DEFINITIONS.SP_TITLE};
  `};
  ${mediaQuery.lessThan('sp')`
  font-size: 18px;
  `};
`;

const RegisterBtn = styled(TopBtn)`
  ${mediaQuery.lessThan('sp')`
    margin-top: 48px;
  `}
`;
