import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const checkSameWord = (word: string = '', keyword: string = '') => {
  return word.toString().toLowerCase() === keyword.toLowerCase();
};

type Props = {
  children: string;
  keyword?: string;
  isAllSame?: boolean;
};

/**
 * テキストをハイライトするコンポーネント
 */
const HighlightedText: React.FC<Props> = (props) => {
  const { children, keyword = '', isAllSame = false } = props;

  if (isAllSame) {
    return (
      <span>
        <StyledHighlight isHighlight={checkSameWord(children, keyword)}>{children}</StyledHighlight>
      </span>
    );
  }

  const parts = (children || '').split(new RegExp(`(${keyword})`, 'gi'));
  return (
    <span>
      {parts.map((part, index) => {
        return (
          <StyledHighlight key={index} isHighlight={checkSameWord(part, keyword)}>
            {part}
          </StyledHighlight>
        );
      })}
    </span>
  );
};

export default HighlightedText;

const StyledHighlight = styled.span<{ isHighlight: boolean }>`
  background-color: ${(props) => (props.isHighlight ? COLOR_DEFINITIONS.LINE.ALERT : '')};
`;
