import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import challengePinImageSrc from '@meettry/ui-components/images/pin/pinned_challenge_explain.png';
import resumePinImageSrc from '@meettry/ui-components/images/pin/pinned_resume_explain.png';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type PinNotationType = {
  onClick: () => void;
  buttonText: string;
  title: string;
  description: string;
  imageSrc: string;
};

export const PinNotation: React.FC<PinNotationType> = (props) => {
  const { onClick, buttonText, title, description, imageSrc } = props;
  return (
    <StyledBoxWrapper>
      <StyledBoxContainer>
        <div>
          <StyledBoxTitle>{title}</StyledBoxTitle>
          <StyledBoxArticle>{description}</StyledBoxArticle>
          <StyledButtonWrapper>
            <StyledPrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onClick}>
              {buttonText}
            </StyledPrimaryButton>
          </StyledButtonWrapper>
        </div>
        {imageSrc && (
          <StyledImageContainer>
            <img src={imageSrc} />
          </StyledImageContainer>
        )}
      </StyledBoxContainer>
    </StyledBoxWrapper>
  );
};

const StyledBoxWrapper = styled.div`
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  padding: 50px 72px;
`;

const StyledBoxContainer = styled.div`
  width: 100%;
  display: flex;
`;

const StyledBoxTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
`;

const StyledBoxArticle = styled.p`
  margin-top: 20px;
  font-size: 14px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  line-height: 1.5;
`;
const StyledButtonWrapper = styled.div`
  margin-top: 30px;
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  max-width: 200px;
`;
const StyledImageContainer = styled.div`
  margin-left: 50px;
`;

const challengePinNotationProps = {
  title: 'チャレンジをピン留めしましょう',
  description:
    'あなたが現在挑戦中のチャレンジ、過去のチャレンジの一部をマイページのTOPに表示する事ができます。企業やエンジニアとのきっかけ作りにピン留め機能をさっそく使って見ましょう！',
  buttonText: 'さっそくピン留めする',
  imageSrc: challengePinImageSrc
};

export const ChallengePinNotation: React.FC<{ onClick: () => void }> = (props) => (
  <PinNotation {...props} {...challengePinNotationProps} />
);

const resumePinNotationProps = {
  title: '経歴をピン留めしましょう',
  description:
    'あなたが経験してきた中で、経歴の一部をマイページのTOPに表示する事ができます。企業やエンジニアとのきっかけ作りにピン留め機能をさっそく使って見ましょう！',
  buttonText: 'さっそくピン留めする',
  imageSrc: resumePinImageSrc
};

export const ResumePinNotation: React.FC<{ onClick: () => void }> = (props) => (
  <PinNotation {...props} {...resumePinNotationProps} />
);
