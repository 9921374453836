import React from 'react';
import styled from 'styled-components';
import { EngineerProfile } from '@meettry/company/component/molecules/engineerProfile';
import { Ellipsis } from '@meettry/company/component/atoms/ellipsis';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import {
  StyledTechStacks,
  StyledBasicTag
} from '@meettry/company/component/organisms/dashboardChallengeList';

const langList: Array<string> = ['Kubernetes', 'DevOps', 'Google Cloud Platform'];

export const LatestChallenge: React.FC = () => {
  return (
    <StyledLatestChallengeUl>
      {Array.from({ length: 6 }, (_, i) => (
        <StyledLatestChallengeLi key={i}>
          <EngineerProfile />
          <Ellipsis
            lineNumber={2}
            cls="challengeList-title"
            isWorkShorten={true}
            Tag="span"
            margin={15}
          >
            Kubernetesを使用したDevOpsの習得テ キストがここに入ります最大2行を想定テキストテキスト
          </Ellipsis>
          <StyledTechStacks>
            {langList.map((item, index) => (
              <StyledBasicTag size="S" key={index}>
                {item}
              </StyledBasicTag>
            ))}
          </StyledTechStacks>
        </StyledLatestChallengeLi>
      ))}
    </StyledLatestChallengeUl>
  );
};

const StyledLatestChallengeUl = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 22px;
`;

const StyledLatestChallengeLi = styled.li`
  box-sizing: border-box;
  padding: 20px 24px;
  width: calc(100% / 2 - 6px);
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  &:nth-child(n + 3) {
    margin-top: 10px;
  }
`;
