import React, { FormEvent } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { URL_SEARCH_PARAM_KEY } from '@meettry/ui-components/constants';
import SearchBox from '@meettry/ui-components/components/molecules/SearchBox/SearchBox';
import useSearchQueryManager from '@meettry/ui-components/hooks/useSearchQueryManager';
import { useLoginUser } from '@meettry/ui-components/utils/auth';

const SEARCH_PATHNAME = '/search';
const ENTERPRISE_SEARCH_PATHNAME = '/enterprise/search';

const HeaderSearch = () => {
  const location = useLocation();
  const history = useHistory();
  const {
    queryString,
    resetQuery,
    changeQuery,
    createHistoryContentWithSearchQuery
  } = useSearchQueryManager();
  const { isEnterprise } = useLoginUser();

  const searchFullText = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!queryString) return;
    resetQuery(URL_SEARCH_PARAM_KEY.SEARCH_TYPE);
    resetQuery(URL_SEARCH_PARAM_KEY.SEARCH_PAGE);
    resetQuery(URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER);

    if (isEnterprise) {
      const historyContent = createHistoryContentWithSearchQuery(ENTERPRISE_SEARCH_PATHNAME);
      if (!historyContent) return;
      location.pathname === ENTERPRISE_SEARCH_PATHNAME
        ? history.replace(historyContent)
        : history.push(historyContent);
    } else {
      const historyContent = createHistoryContentWithSearchQuery(SEARCH_PATHNAME);
      if (!historyContent) return;
      location.pathname === SEARCH_PATHNAME
        ? history.replace(historyContent)
        : history.push(historyContent);
    }
  };

  return (
    <SearchBox
      onChange={changeQuery(URL_SEARCH_PARAM_KEY.SEARCH_QUERY)}
      onSubmit={searchFullText}
      searchWord={String(queryString)}
      placeholder="検索"
    />
  );
};

export default HeaderSearch;
