import { css } from 'styled-components';
// ここは変更しないでください
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const FONT_FAMILY = {
  COMMON: `Jaldi, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif`,
  ENG: `'Montserrat', sans-serif`
};

export const FONT_DEFINITIONS = {
  TITLE: css`
    font-size: 40px;
    line-height: 1.5;
    font-weight: bold;
  `,
  TABLET_TITLE: css`
    font-size: 36px;
    line-height: 2;
    font-weight: bold;
  `,
  SP_TITLE: css`
    font-size: 28px;
    line-height: 2;
    font-weight: bold;
  `,
  TOP_SECTION_TITLE: css`
    font-size: 36px;
    line-height: 1.2;
    font-weight: bold;
  `,
  MESSAGE_BOLD: css`
    font-size: 32px;
    line-height: 52px;
    font-weight: bold;
  `,
  MESSAGE_CTA_BOLD: css`
    font-size: 28px;
    line-height: 1.5;
    font-weight: bold;
  `,
  SECTION_BOLD: css`
    font-size: 24px;
    line-height: 1.5;
    font-weight: bold;
  `,
  SECTION: css`
    font-size: 24px;
    line-height: 1.5;
  `,
  CONTENT: css`
    font-size: 20px;
    line-height: 1.5;
    font-weight: bold;
  `,
  READ_TEXT_BOLD: css`
    font-size: 18px;
    line-height: 1.75;
    font-weight: bold;
  `,
  READ_TEXT: css`
    font-size: 18px;
    line-height: 1.75;
  `,
  DEFAULT_BOLD: css`
    font-size: 16px;
    line-height: 1.75;
    font-weight: bold;
  `,
  DAFAULT: css`
    font-size: 16px;
    line-height: 1.75;
  `,
  NOTIFICATION: css`
    font-size: 12px;
    line-height: 1.5;
  `,
  NOTE: css`
    font-size: 10px;
    line-height: 2;
  `,
  NOTE_ALERT: css`
    font-size: 12px;
    line-height: 2;
    color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  `,
  NOTE_ALERT_SMALL: css`
    font-size: 10px;
    line-height: 2;
    color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  `,
  SMALL_BOLD: css`
    font-size: 14px;
    line-height: 1.5;
    font-weight: bold;
  `,
  SMALL: css`
    font-size: 14px;
    line-height: 1.5;
  `,
  XS_BOLD: css`
    font-size: 12px;
    line-height: 2;
    font-weight: bold;
  `,
  XS: css`
    font-size: 12px;
    line-height: 2;
  `
};
