import { useCallback, useEffect, useMemo } from 'react';
import { useChatContext } from '@meettry/ui-components/contexts/chat.context';
import { ChatCursors, ChatMessage, DateGroupMessages } from '@meettry/ui-components/types/chat';
import { timestampTo } from '@meettry/engineer/components/organisms/Chat/utils';
import useMessagesBackward from '@meettry/ui-components/hooks/chat/useChatMessagesBackward';
import useMessagesForward from '@meettry/ui-components/hooks/chat/useChatMessagesForward';
import { setChatMessages } from '@meettry/ui-components/contexts/chat.context';
import { formatDate } from '@meettry/ui-components/utils';
import { MESSAGE_LOAD_LIMIT } from '@meettry/ui-components/utils/Constant';

const groupByDate = (messages: ChatMessage[]) =>
  messages.reduce<DateGroupMessages>((acc, x) => {
    const date = formatDate(x.createdAt);
    if (!acc[date]) {
      acc[date] = [];
    }
    acc[date].push(x);
    return acc;
  }, {});

/**
 * useChatRoom
 * 特定のチャットルームに対しての処理
 */
const useChatRoom = (roomId: string, cursors: ChatCursors | null) => {
  const { state, dispatch } = useChatContext();

  /**
   * 選択されたチャットルーム
   */
  const room = useMemo(() => state.chatRooms.find((r) => r.id === roomId) ?? null, [roomId, state]);
  const cursor = cursors?.[roomId] ?? null;

  const appendMessages = useCallback(
    (values) => {
      const newMessages = (room?.messages ?? []).concat(values);

      dispatch(setChatMessages(roomId, newMessages));
    },
    [room, roomId]
  );

  const prependMessages = useCallback(
    (values) => {
      const newMessages = values.concat(room?.messages ?? []);
      dispatch(setChatMessages(roomId, newMessages, (values || []).length >= MESSAGE_LOAD_LIMIT));
    },
    [room, roomId]
  );
  const { getPrev, loading: loadingOlders, error: errorOlders } = useMessagesBackward(
    roomId,
    cursor,
    room?.messages ?? null,
    prependMessages
  );

  const { loading: loadingNewers, error: errorNewers } = useMessagesForward(
    roomId,
    cursor,
    appendMessages
  );

  // 日付でグルーピングしたメッセージのマップ
  const mappedMessages: DateGroupMessages = groupByDate(room?.messages ?? []);

  /**
   * チャットルームが選択された場合にメッセージを取得
   */
  useEffect(() => {
    if (!room) return;
  }, [room]);

  return {
    room,
    messages: mappedMessages,
    getPrev,
    loadingOlders,
    errorOlders,
    loadingNewers,
    errorNewers
  };
};
export default useChatRoom;
