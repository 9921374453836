import React from 'react';
import styled, { css } from 'styled-components';
import ReactDatepicker from 'react-datepicker';
import iconPathCalendar from '@meettry/engineer/images/icon-calendar.png';
import 'react-datepicker/dist/react-datepicker.css';
import '@meettry/engineer/components/atoms/Input/Datepicker/datepicker.css';

export const Datepicker = (props) => {
  return <StyledDatepicker {...props} />;
};

export default Datepicker;

const StyledDatepicker = styled(ReactDatepicker)`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 180px;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 50px;
  padding-right: 20px;
  background-image: url(${iconPathCalendar});
  background-position: 20px center;
  background-size: 18px;
  background-repeat: no-repeat;
  ${({ error }) =>
    error &&
    css`
      background-color: rgb(244, 35, 60, 0.1);
      border: 1px solid #f4233c;
    `}
`;
