import React, { useState, useEffect } from 'react';
import marked from 'marked';
import styled from 'styled-components';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

type cbType = (data: string) => void;
type MarkdownAreaProps = {
  markdownFile: string;
};

export const loadMarkdown = async (data: string, cb: cbType) => {
  marked.setOptions({
    breaks: false,
    gfm: true,
    headerIds: true,
    headerPrefix: '',
    langPrefix: 'language-',
    mangle: true,
    pedantic: false,
    sanitize: false,
    silent: false,
    smartLists: false,
    smartypants: false
  });
  const loadedMd = await fetch(data)
    .then((res) => res.text())
    .then((txt: string) => marked(txt));
  await cb(loadedMd);
};

export const useMarkdown = (data: string) => {
  const [md, setMd] = useState<string>('');
  useEffect(() => {
    loadMarkdown(data, (res) => setMd(res));
  }, [data]);
  return md;
};

export const MarkdownArea: React.FC<MarkdownAreaProps> = (props) => {
  const data = require(`@meettry/ui-components/mdDocuments/${props.markdownFile}.md`);
  const loadedMarkdown = useMarkdown(data);
  return <StyledMarkdownArea dangerouslySetInnerHTML={{ __html: loadedMarkdown }} />;
};

const StyledMarkdownArea = styled.div`
  && {
    text-align: justify;
    word-break: break-all;
    h2 {
      padding-bottom: 8px;
      border-bottom: 1px solid #eeeeee;
      font-weight: bold;
      font-size: 20px;
      line-height: 34px;
      margin-top: 80px;
      ${mediaQuery.lessThan('minTablet')`
        font-size: 18px;
        `}
    }
    p {
      font-size: 16px;
      line-height: 28px;
      padding: 40px 30px 0;
      ${mediaQuery.lessThan('minTablet')`
          font-size: 14px;
          padding: 40px 0 0;
      `}
      &:first-child {
        padding: 0;
      }
    }
    ol {
      margin-top: 30px;
      margin-left: 20px;
      padding: 0 60px;
      ${mediaQuery.lessThan('minTablet')`
            font-size: 14px;
            padding: 0 14px;
          `}
      li {
        counter-increment: cnt;
        line-height: 28px;
        &::before {
          content: '(' counter(cnt) ') ';
          display: inline-block;
          margin-left: -20px;
        }
        ol {
          margin-top: 0;
          li {
            counter-increment: childCnt;
            &::before {
              content: '(' counter(childCnt) ') ';
            }
            ol {
              margin-top: 0;
              li {
                counter-increment: grandchildCnt;
                &::before {
                  content: '(' counter(grandchildCnt) ') ';
                }
              }
            }
          }
        }
      }
    }
    h3 {
      font-size: 16px;
      padding: 40px 30px 0;
      font-weight: bold;
      ${mediaQuery.lessThan('minTablet')`
          font-size: 14px;
          padding: 40px 0 0;
      `}
      & + p {
        font-size: 16px;
        padding: 10px 30px 0;
        ${mediaQuery.lessThan('minTablet')`
          font-size: 14px;
          padding: 10px 0 0;
      `}
      }
    }
  }
`;
