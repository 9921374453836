import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router';
import {
  TagType,
  ChallengeType,
  ChallengeCreateValueType
} from '@meettry/ui-components/types/userPage';
import {
  useEngineer,
  useChallenge,
  CHALLENGE_SORT_OPTION
} from '@meettry/ui-components/hooks/useUserPage';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import DetailHeader from '@meettry/ui-components/components/organisms/UserPage/Common/DetailHeader';
import ChallengeItem from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeItem';
import { makeHandleSubmit } from '@meettry/ui-components/components/organisms/UserPage/Challenge/util';
import {
  FETCH_ENGINEER,
  CREATE_CHALLENGE,
  DELETE_CHALLENGE,
  UPDATE_CHALLENGE_PINNED,
  UPDATE_CHALLENGE_VISIBLE,
  SEARCH_ENGINEER_CHALLENGE
} from '@meettry/ui-components/queries/user_page';
import {
  ChallengeCreate,
  ChallengeEdit,
  ChallengeHistoryEdit
} from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeForm';
import ChallengeEmptyList from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeEmptyList';
import { ListAnimation } from '@meettry/ui-components/utils/animation';

type ChallengeListLocationType = {
  addMode?: boolean;
  selectedChallengeId?: string | null;
};

const ChallengeList = () => {
  const location = useLocation<ChallengeListLocationType>();
  const history = useHistory();
  const { state, storeEngineer, isOwner } = useEngineer();
  const { nickname, challengeList, userTags } = state;
  const {
    addChallenge,
    removeChallenge,
    updateChallenge,
    pinChallenge,
    visibleChallenge,
    showingList,
    setShowingListId,
    setSort,
    sortType
  } = useChallenge();
  const { showDeleteConfirmationDialog, showMaximumReachedDialog, closeDialog } = useDialog();
  const { startLoading, endLoading } = useLoading();
  const { showErrorPopup, showSuccessPopup } = usePopup();
  const [isSearch, setIsSearch] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isHistoryEditMode, setIsHistoryEditMode] = useState(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [searchWord, updateSearchWord] = useState('');
  const [searchTag, updateSearchTag] = useState<Array<TagType>>([]);
  const [displaySearchWord, updateDisplaySearchWord] = useState('');
  // MEMO(aida) ダッシュボードからクリックされたチャレンジのID
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | null>(null);
  const selectedChallengeRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selectedChallengeRef.current) return;
    //MEMO(aida) レンダーされてからスクロールする
    setTimeout(() => {
      const targetRect =
        selectedChallengeRef.current && selectedChallengeRef.current.getBoundingClientRect();
      //MEMO(aida) 96=>headerの高さ
      window.scrollTo({ top: targetRect ? targetRect.top - 96 : 0, behavior: 'smooth' });
      // MEMO(aida) スクロールさせたらクリックされたチャレンジのIDをクリアする
      setSelectedChallengeId(null);
    }, 300);
  }, [selectedChallengeRef.current]);

  const [searchQuery, { error: searchError, loading: searchLoading }] = useLazyQuery(
    SEARCH_ENGINEER_CHALLENGE,
    {
      onCompleted: ({ users }) => {
        const challengeListId = users[0].challengeList.items.map(({ id }: { id: string }) => id);
        setShowingListId(challengeListId);
        setIsSearch(true);
      }
    }
  );

  //MEMO(minami) エンジニアの取得
  const [fetchEngineer, { data: engineerData, loading: loadingEngineer }] = useLazyQuery(
    FETCH_ENGINEER,
    {
      variables: { nicknames: [nickname] }
    }
  );

  //MEMO(minami) 取得したデータをstoreに格納する
  useEffect(() => {
    if (!loadingEngineer && engineerData?.users?.length > 0) {
      storeEngineer(engineerData.users[0]);
    }
  }, [engineerData, loadingEngineer]);

  const onChangeSearchWord = (value: string) => {
    updateSearchWord(value);
  };
  const onSelectSearchTag = (tag: TagType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const updatedSearchTag = [...searchTag, tag];
    updateSearchTag(Array.from(new Set(updatedSearchTag)));
    updateSearchWord('');
  };
  const onSelectSearchTagDownshift = (tag: TagType) => {
    const updatedSearchTag = [...searchTag, tag];
    updateSearchTag(Array.from(new Set(updatedSearchTag)));
    updateSearchWord('');
  };
  const onRemoveSearchTag = (targetTag: TagType) => (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const updatedSearchTag = searchTag.filter((tag: TagType) => tag !== targetTag);
    updateSearchTag(updatedSearchTag);
  };
  const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchQuery({
      variables: {
        nicknames: [nickname],
        queryString: searchWord,
        queryTags: searchTag.map((tag) => tag.displayName)
      }
    });
    updateDisplaySearchWord(searchWord);
    updateSearchWord('');
    updateSearchTag([]);
  };

  //MEMO(aida) ダッシュボードから遷移時に初期状態で新規作成モーダルを開く
  useEffect(() => {
    if (!location.state) return;
    if (!location.state.addMode) return;
    setIsAddMode(true);
    //MEMO(aida) リロード時にlocation.stateが保持されたままになるのでクリアしておく
    history.replace(location.pathname);
  }, []);

  //MEMO(aida) ダッシュボードからピン留めされたアイテムを選択しての遷移時の処理
  useEffect(() => {
    if (!location.state) return;
    if (!location.state.selectedChallengeId) return;
    setSelectedChallengeId(location.state.selectedChallengeId);
    //MEMO(aida) リロード時にlocation.stateが保持されたままになるのでクリアしておく
    history.replace(location.pathname);
  }, []);

  const [mutateCreateChallenge, { loading: createLoading, error: createError }] = useMutation(
    CREATE_CHALLENGE,
    {
      onCompleted: ({ createChallenge }) => {
        if (!createChallenge) return;
        addChallenge(createChallenge.challenge);
        fetchEngineer();
        setIsAddMode(false);
        endLoading();
        showSuccessPopup('チャレンジを作成しました');
      }
    }
  );

  const [mutateDeleteChallenge, { loading: deleteLoading, error: deleteError }] = useMutation(
    DELETE_CHALLENGE,
    {
      onCompleted: ({ deleteChallenge }) => {
        if (!deleteChallenge) return;
        removeChallenge(deleteChallenge.id);
        fetchEngineer();
        endLoading();
        showSuccessPopup('チャレンジを削除しました');
      }
    }
  );

  const [pinMutation, { loading: pinLoading, error: errorPin }] = useMutation(
    UPDATE_CHALLENGE_PINNED,
    {
      onCompleted: ({ updateChallenge }) => {
        if (!updateChallenge) return;
        const { challenge } = updateChallenge;
        pinChallenge(challenge);
        endLoading();
        showSuccessPopup('チャレンジを更新しました');
      }
    }
  );

  const [visibleMutation, { loading: visibleLoading, error: visibleError }] = useMutation(
    UPDATE_CHALLENGE_VISIBLE,
    {
      onCompleted: ({ updateChallenge }) => {
        if (!updateChallenge) return;
        const { challenge } = updateChallenge;
        visibleChallenge(challenge);
        endLoading();
        showSuccessPopup('チャレンジを更新しました');
      }
    }
  );

  //MEMO(aida) チャレンジ更新系のエラーハンドリング。onErrorが動かないのでuseEffectでErrorStateを監視する
  useEffect(() => {
    const isError = visibleError || errorPin || deleteError || createError;
    if (!isError) return;
    endLoading();
    if (deleteError) showErrorPopup('チャレンジを削除できませんでした');
    if (createError) showErrorPopup('チャレンジを作成できませんでした');
    if (visibleError) showErrorPopup('チャレンジを更新できませんでした');
    if (errorPin) showErrorPopup('チャレンジを更新できませんでした');
  }, [visibleError, errorPin, deleteError, createError]);

  const onCreateChallenge = (newChallenge: ChallengeCreateValueType) => {
    if (createLoading) return;
    startLoading('チャレンジを作成しています。');
    makeHandleSubmit(newChallenge, mutateCreateChallenge)();
  };

  const onEditChallenge = (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setEditingItemId(challenge.id);
    setIsEditMode(true);
  };

  const onHistoryEditChallenge = (challenge: ChallengeType) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault();
    setEditingItemId(challenge.id);
    setIsHistoryEditMode(true);
  };

  const onUpdateChallengePinned = (item: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    if (pinLoading) return;
    const pinnedChallenge = challengeList.items.filter((item: ChallengeType) => item.isPinned);
    const maxPinnedCount = 3;
    if (!item.isPinned && pinnedChallenge.length >= maxPinnedCount) {
      showMaximumReachedDialog(
        [
          'ピン留めできる最大数に到達しています。',
          `ピン留めできるチャレンジは${maxPinnedCount}つまでです。`
        ],
        closeDialog
      );
      return;
    }
    startLoading('チャレンジを更新しています。');
    pinMutation({
      variables: {
        id: item.id,
        isPinned: !item.isPinned
      }
    });
  };

  const onUpdateChallengeVisible = (item: ChallengeType) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault();
    if (visibleLoading) return;
    startLoading('チャレンジを更新しています。');
    visibleMutation({
      variables: {
        id: item.id,
        isVisible: !item.isVisible
      }
    });
  };

  const onClickDeleteChallenge = (challenge: ChallengeType) => (
    e: React.MouseEvent<HTMLLIElement>
  ) => {
    e.preventDefault();
    const deleteChallenge = () => {
      if (deleteLoading) return;
      closeDialog();
      startLoading('チャレンジを削除しています。');
      mutateDeleteChallenge({ variables: { input: { id: challenge.id } } });
    };
    showDeleteConfirmationDialog(
      [
        `${challenge.title}のチャレンジ詳細を削除します。`,
        '削除されたデータは、復元できませんがよろしいでしょうか？'
      ],
      deleteChallenge,
      closeDialog
    );
  };

  const onUpdateChallenge = (challenge: ChallengeType) => {
    // MEMO(aida) 編集モードを初期状態に戻す
    updateChallenge(challenge);
    fetchEngineer();
    setIsHistoryEditMode(false);
    setIsEditMode(false);
    setEditingItemId(null);
  };

  const onAddMode = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    setIsAddMode(true);
  };
  const onCloseAddMode = () => {
    setIsAddMode(false);
    closeDialog();
  };
  const onCloseEdit = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    closeDialog();
  };

  const onCloseHistoryEdit = () => {
    setIsHistoryEditMode(false);
    setEditingItemId(null);
  };

  const onSortChallenge = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSort(e.target.value);
  };

  return (
    <>
      {isAddMode && (
        <ChallengeCreate
          onSubmit={onCreateChallenge}
          loading={createLoading}
          onClose={onCloseAddMode}
        />
      )}
      <DetailHeader
        titleImage="challenge"
        title="チャレンジ"
        onSelectSearchTagDownshift={onSelectSearchTagDownshift}
        onSelectSearchTag={onSelectSearchTag}
        searchWord={searchWord}
        onSubmitSearch={onSubmitSearch}
        onChangeSearchWord={onChangeSearchWord}
        userTags={userTags}
        onRemoveSearchTag={onRemoveSearchTag}
        placeholder="チャレンジを検索"
        searchTag={searchTag}
        isOwner={isOwner}
        onAddMode={onAddMode}
        sortOption={CHALLENGE_SORT_OPTION}
        onSort={onSortChallenge}
        sortType={sortType}
      />
      {!showingList || searchLoading ? (
        <Loader />
      ) : (
        <>
          <TransitionGroup>
            {showingList.length >= 0
              ? showingList.map((item) => {
                  const isItemHistoryEditMode = isHistoryEditMode && editingItemId === item.id;
                  const isItemEditMode = isEditMode && editingItemId === item.id;
                  const isSelectedFromDashboard = selectedChallengeId === item.id;
                  return (
                    <CSSTransition
                      key={item.title + item.id}
                      timeout={ListAnimation.timeout}
                      classNames={ListAnimation.classNames}
                    >
                      <StyledChallengeItemWrapper
                        ref={isSelectedFromDashboard ? selectedChallengeRef : null}
                      >
                        {isItemEditMode ? (
                          <ChallengeEdit
                            item={item}
                            onUpdate={onUpdateChallenge}
                            onClose={onCloseEdit}
                          />
                        ) : (
                          <ChallengeItem
                            challenge={item}
                            isOwner={isOwner}
                            onEdit={onEditChallenge}
                            onHistoryEdit={onHistoryEditChallenge}
                            onVisible={onUpdateChallengeVisible}
                            onPinned={onUpdateChallengePinned}
                            onDelete={onClickDeleteChallenge}
                            searchWord={displaySearchWord}
                          />
                        )}
                        {isItemHistoryEditMode && (
                          <ChallengeHistoryEdit
                            item={item}
                            onUpdate={onUpdateChallenge}
                            onClose={onCloseHistoryEdit}
                          />
                        )}
                      </StyledChallengeItemWrapper>
                    </CSSTransition>
                  );
                })
              : null}
          </TransitionGroup>
          {showingList.length <= 0 && (
            <ChallengeEmptyList isSearch={isSearch} isOwner={isOwner} onClick={onAddMode} />
          )}
        </>
      )}
    </>
  );
};

export default ChallengeList;

const StyledChallengeItemWrapper = styled(ListAnimation.animation)`
  margin-top: 20px;
`;
