import gql from 'graphql-tag';
import { FRAG_CHALLENGE_LIST_ITEM } from '@meettry/ui-components/queries/user_page/fragments';

// // create challenege
// export const CREATE_ENGINEER_CHALLENGE = gql``;
export const CREATE_CHALLENGE = gql`
  mutation CreateChallenge($input: CreateChallengeInput!) {
    createChallenge(input: $input) {
      challenge {
        ...ChallengeListItem
      }
    }
  }

  ${FRAG_CHALLENGE_LIST_ITEM}
`;
// // update challenge
// export const UPDATE_ENGINEER_CHALLENGE = gql``;
export const UPDATE_CHALLENGE = gql`
  mutation UpdateChallenge($input: UpdateChallengeInput!) {
    updateChallenge(input: $input) {
      challenge {
        ...ChallengeListItem
      }
    }
  }
  ${FRAG_CHALLENGE_LIST_ITEM}
`;

export const UPDATE_CHALLENGE_PINNED = gql`
  mutation UpdateChallengePinned($id: ID!, $isPinned: Boolean!) {
    updateChallenge(input: { id: $id, isPinned: $isPinned }) {
      challenge {
        id
        isPinned
      }
    }
  }
`;

export const UPDATE_CHALLENGE_VISIBLE = gql`
  mutation UpdateChallengeVisible($id: ID!, $isVisible: Boolean!) {
    updateChallenge(input: { id: $id, isVisible: $isVisible }) {
      challenge {
        id
        isVisible
        isPinned
      }
    }
  }
`;
// // delete challenge
// export const DELETE_ENGINEER_CHALLENGE = gql``;
export const DELETE_CHALLENGE = gql`
  mutation DeleteChallenge($input: DeleteChallengeInput!) {
    deleteChallenge(input: $input) {
      id
    }
  }
`;
// search challenge
export const SEARCH_ENGINEER_CHALLENGE = gql`
  query SearchEngineerChallenge(
    $nicknames: [String]!
    $queryString: String
    $queryTags: [String!]
  ) {
    users(nicknames: $nicknames) {
      id
      challengeList(filterParams: { queryString: $queryString, queryTags: $queryTags }) {
        items {
          id
        }
      }
    }
  }
`;
