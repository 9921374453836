import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import constants from '@meettry/engineer/constants';
import { useQuery, useMutation, useLazyQuery } from '@apollo/react-hooks';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { SEARCH_TYPE } from '@meettry/engineer/utils/Constant';
import {
  FETCH_FOLLOWEE_QUERY,
  FOLLOW_USER_QUERY,
  UNFOLLOW_USER_QUERY,
  SEARCH_QUERY
} from '@meettry/engineer/graphql/queries/search_result';
import SearchResultForm from '@meettry/engineer/components/organisms/SearchResult/SearchResult/SearchResultForm';

const { URL_SEARCH_PARAM_KEY } = constants;
const SEARCH_SORT = {
  CREATED: 'created',
  UPDATED: 'updated'
};

export const useSearchQuery = (location, history) => {
  const searchParams = new URLSearchParams(location.search);
  const queryString = searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_QUERY) || '';
  const type = Object.values(SEARCH_TYPE).includes(
    (searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_TYPE) || SEARCH_TYPE.USER).toLowerCase()
  )
    ? searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_TYPE) || SEARCH_TYPE.USER
    : SEARCH_TYPE.USER;
  const displayNumber = parseInt(
    searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER) || '10',
    10
  );
  const page = parseInt(searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_PAGE) || '1', 10);
  const [doSearchQuery, { data, loading, error }] = useLazyQuery(SEARCH_QUERY);

  const [sortType, setSortType] = useState(SEARCH_SORT.UPDATED);
  const onChangeSort = (e) => setSortType(e.target.value);

  useEffect(() => {
    if (queryString) {
      doSearchQuery({
        variables: {
          queryString,
          first: displayNumber,
          offset: displayNumber * (page - 1),
          field: sortType
        }
      });
    }
  }, [queryString, displayNumber, page, sortType]);

  const refreshQueryParams = (params = {}, options = {}) => {
    const searchParams = new URLSearchParams(location.search);
    const historyFunc = options.replace ? history.replace : history.push;

    Object.keys(params).forEach((key) =>
      params[key] !== null ? searchParams.set(key, params[key]) : searchParams.delete(key)
    );
    historyFunc({ ...location, pathname: '/search', search: searchParams.toString() });
  };

  return {
    queryString,
    type,
    displayNumber,
    page,
    data,
    loading,
    error,
    refreshQueryParams,
    onChangeSort,
    sortType
  };
};

/**
 * SearchResult Component
 */
const SearchResult = () => {
  const history = useHistory();
  const {
    user: { nickname: loginNickname }
  } = useLoginUser();
  const [loginUserFollowee, updateLoginUserFollowee] = useState([]);
  const [targetNickname, setTargetNickname] = useState('');
  const { startLoading, endLoading } = useLoading();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const { loading: loadingFollowee, data: followeeData } = useQuery(FETCH_FOLLOWEE_QUERY, {
    fetchPolicy: 'network-only'
  });

  const [mutateFollow, { loading: loadingFollow, error: errorFollow }] = useMutation(
    FOLLOW_USER_QUERY,
    {
      onCompleted: ({ followUser }) => {
        if (!followUser) return;
        if (!followUser.ok) return;
        endLoading();
        showSuccessPopup(`${targetNickname}をフォローしました`);
        updateLoginUserFollowee([...loginUserFollowee, targetNickname]);
        setTargetNickname('');
      }
    }
  );
  const [mutateUnfollow, { loading: loadingUnfollow, error: errorUnfollow }] = useMutation(
    UNFOLLOW_USER_QUERY,
    {
      onCompleted: ({ unfollowUser }) => {
        if (!unfollowUser) return;
        if (!unfollowUser.ok) return;
        endLoading();
        showSuccessPopup(`${targetNickname}をアンフォローしました`);
        const updatedFollowee = loginUserFollowee.filter((followee) => followee !== targetNickname);
        updateLoginUserFollowee(updatedFollowee);
        setTargetNickname('');
      }
    }
  );

  //MEMO(aida) graphqlエラーのエラーハンドリング
  useEffect(() => {
    const isError = errorFollow || errorUnfollow;
    if (!isError) return;
    endLoading();
    if (errorUnfollow) showErrorPopup(`${targetNickname}をアンフォローできませんでした`);
    if (errorFollow) showErrorPopup(`${targetNickname}をフォローできませんでした`);
  }, [errorUnfollow, errorFollow]);

  useEffect(() => {
    if (!loadingFollowee && !!followeeData) {
      const followeesNickname = followeeData.loginSession.user.followees.map(
        (item) => item.nickname
      );
      updateLoginUserFollowee([...loginUserFollowee, ...followeesNickname]);
    }
  }, [loadingFollowee, followeeData]);

  const onClickUserFollow = (id, nickname, isUserFollowed) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    setTargetNickname(nickname);
    if (loadingFollow || loadingUnfollow) return;
    if (isUserFollowed) {
      mutateUnfollow({
        variables: {
          userId: id
        }
      });
      startLoading(`${nickname}をアンフォロー中...`);
      return;
    }
    mutateFollow({
      variables: {
        userId: id
      }
    });
    startLoading(`${nickname}をフォロー中...`);
  };

  const onClickUserChat = (id, nickname) => (e) => {
    e.preventDefault();
    e.stopPropagation();
    history.push({
      pathname: `/user/${loginNickname}/chat`,
      state: { targetUser: { newTargetUserId: id, newTargetUserName: nickname } }
    });
  };

  return (
    <SearchResultForm
      loginUserFollowee={loginUserFollowee}
      loadingFollowee={loadingFollowee}
      onClickUserFollow={onClickUserFollow}
      onClickUserChat={onClickUserChat}
    />
  );
};

export default SearchResult;
