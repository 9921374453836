import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import Loader from '@meettry/ui-components/components/atoms/Loader';

const PreparingPage: React.FC = () => {
  return (
    <StyledPreparingWrapper>
      <StyledPreparingInner>
        <Loader />
      </StyledPreparingInner>
    </StyledPreparingWrapper>
  );
};

export default PreparingPage;

const StyledPreparingWrapper = styled.div`
  && {
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  }
`;

const StyledPreparingInner = styled.div`
  && {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    min-height: calc(100vh - 96px);
    margin: 0 auto;
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  }
`;
