import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY, FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import {
  TopTitles,
  SectionWrapper,
  headerMenuList
} from '@meettry/ui-components/components/organisms/Tops/utils';

type TopFlowProps = {
  flowList: Array<{
    img: any;
    msg: string;
  }>;
};

const TopFlow: React.FC<TopFlowProps> = (props) => {
  const title = '使い方';
  const sectionId = headerMenuList.find((obj) => obj.name === title);
  return (
    <TopFlowSection id={sectionId && sectionId.target}>
      <TopFlowSectionWrapper>
        <TopTitles>
          <div>FLOW</div>
          <div>{title}</div>
        </TopTitles>
        <FlowContainer>
          {props.flowList.map((li, i) => {
            const displayNo = i + 1;
            return (
              <FlowItem key={i}>
                <FlowMessageBox>
                  <FlowNo>{displayNo > 9 ? displayNo : `0${displayNo}`}</FlowNo>
                  <FlowMessage>{li.msg}</FlowMessage>
                </FlowMessageBox>
                <FlowImage src={li.img} />
              </FlowItem>
            );
          })}
        </FlowContainer>
      </TopFlowSectionWrapper>
    </TopFlowSection>
  );
};

const TopFlowSection = styled.div`
  background-color: #dbf3f2;
`;

const TopFlowSectionWrapper = styled(SectionWrapper)`
  margin: 74px auto 174px;
  ${mediaQuery.lessThan('minTablet')`
  margin: 74px auto 78px;
`};
`;

const FlowContainer = styled.div`
  margin-top: 18px;
`;

const FlowItem = styled.div`
  display: flex;
  position: relative;
  justify-content: space-between;
  padding-top: 42px;
  &:not(:first-child) {
    margin-top: 84px;
    ${mediaQuery.lessThan('minTablet')`
    margin-top: 32px;
    `};
  }
  ${mediaQuery.lessThan('breakPoint')`
  flex-direction: column-reverse;
`};
  ${mediaQuery.greaterThan('breakPointPC')`
  &:nth-child(odd) {
    flex-direction: row;
  }
  &:nth-child(even) {
    flex-direction: row-reverse;
  }
  `};
`;

const FlowMessageBox = styled.div`
  ${mediaQuery.greaterThan('breakPointPC')`
width: 380px;
`};
  ${mediaQuery.lessThan('breakPoint')`
  position: relative;
width: 100%;
`};
`;

const FlowNo = styled.span`
  color: ${COLOR_DEFINITIONS.TEXT.WHITE};
  font-size: 160px;
  font-family: ${FONT_FAMILY.ENG};
  line-height: 0.85;
  opacity: 0.5;
  font-weight: bold;
`;

const FlowMessage = styled.div`
  position: absolute;
  ${mediaQuery.greaterThan('breakPointPC')`
  ${FONT_DEFINITIONS.CONTENT};
  line-height: 24px;
  width: 380px;
  top: 52%;
  transform: translateY(-52%);
  `};
  ${mediaQuery.lessThan('breakPoint')`
    font-size: 32px;
    line-height: 38px;
    font-weight: bold;
    width: 100%;
    top: 36px;
  `};
  ${mediaQuery.lessThan('minTablet')`
  ${FONT_DEFINITIONS.SECTION_BOLD};
    font-size: 16px;
  `};
`;

const FlowImage = styled.img`
  ${mediaQuery.greaterThan('breakPointPC')`
width: 50%;
`};
  ${mediaQuery.lessThan('breakPoint')`
width: 100%;
`};
`;

export default TopFlow;
