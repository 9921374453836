import { useEffect, useState, useCallback } from 'react';
import {
  ChatCursorsFieldType,
  ChatCursors,
  ChatRoomWithMessages,
  ChatMessage
} from '@meettry/ui-components/types/chat';

/**
 * Constants
 */
// カーソルのアップデートディレイ時間
const DELAY_TIME_FOR_UPDATING = 3000;

/**
 * buildCursorsRef
 * チャットカーソルのDocument（firestore）取得
 */
const buildCursorsRef = (fb: any, uid: string) => {
  if (!uid) {
    return null;
  }
  return fb.db.collection('chat_cursors').doc(uid);
};

/**
 * processCursor
 */
const processCursor = (cursor: ChatCursorsFieldType): ChatCursors =>
  Object.keys(cursor).reduce((acc, key) => {
    const timestamp = cursor[key];
    return {
      ...acc,
      [key]: timestamp.toDate()
    };
  }, {});

/**
 * isCursorUpdated
 */
const isCursorUpdated = (a: any, b: any) => {
  if (!a || !b) {
    return false;
  }

  if (Object.is(a, b)) {
    return false;
  }

  const aKeys = Object.keys(a);
  const bKeys = Object.keys(b);
  if (aKeys.length !== bKeys.length) {
    return true;
  }
  if (aKeys.map((x) => b[x]).filter((x) => x).length !== aKeys.length) {
    return true;
  }
  if (!aKeys.every((x) => a[x] - b[x] === 0)) {
    return true;
  }

  return false;
};

/**
 * useCursorDocRef
 */
const useCursorDocRef = (fb: any, uid: string) => {
  const [ref, setRef] = useState(buildCursorsRef(fb, uid));

  useEffect(() => {
    setRef(buildCursorsRef(fb, uid));
  }, [uid]);

  return ref;
};

/**
 * useServerCursors
 * roomごとのどこまでメッセージを閲覧したかのカーソルを取得
 */
const useServerCursors = (
  fb: any,
  uid: string,
  rooms: ChatRoomWithMessages[] | null
): [ChatCursors | null, boolean, any] => {
  const ref = useCursorDocRef(fb, uid);
  const [cursors, setCursors] = useState<ChatCursors | null>(null);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(undefined);

  useEffect(() => {
    if (ref) {
      setLoading(true);
      setError(undefined);

      ref
        .get()
        .then((snapshot: any) => {
          if (snapshot.exists) {
            const cursor = processCursor(snapshot.data());
            setCursors(cursor);
            setLoading(false);
          } else {
            setCursors({} as any);
            ref
              .set({})
              .catch(setError)
              .finally(() => setLoading(false));
          }
        })
        .catch(setError);
    }
  }, [ref]);

  // サーバーカーソルの更新

  const messages =
    rooms &&
    rooms
      .map((x) => x.messages ?? null)
      .flat()
      .filter((x) => x)
      .map((message) => message?.id);

  const buildNewCursors = useCallback(() => {
    if (!rooms || !cursors) {
      return cursors;
    }

    return rooms.reduce((acc, room) => {
      if (room.messages && room.messages.length > 0) {
        const serverCursor = cursors[room.id];
        const newCursor = room.messages[room.messages.length - 1].createdAt;
        const isOnlyCurrentCursor = newCursor && !serverCursor;
        const isCurrentCursorMoreRecent =
          newCursor && serverCursor && newCursor.getTime() - serverCursor.getTime() > 0;

        if (isOnlyCurrentCursor || isCurrentCursorMoreRecent) {
          return {
            ...acc,
            [room.id]: newCursor
          };
        }
      }
      return acc;
    }, cursors);
  }, [rooms, cursors]);

  useEffect(() => {
    // メッセージが更新されるたびにカーソルを更新すると書き込み量が多くなるので、
    // setTimeout で少しタイミングをずらす
    // TODO: これでもまだ頻繁に書き込み過ぎかもしれない(より丁度いいタイミングでカーソルを更新したい)
    const timer = setTimeout(() => {
      const newCursors = buildNewCursors();

      if (ref && isCursorUpdated(cursors, newCursors)) {
        fb.db
          .runTransaction((transaction: any) =>
            transaction.get(ref).then((doc: any) => {
              transaction.update(ref, { ...doc.data(), ...newCursors });
            })
          )
          .catch((err: any) => console.error(err));
      }
    }, DELAY_TIME_FOR_UPDATING);

    return () => clearTimeout(timer);
  }, [ref, messages && messages.length, cursors]);

  return [cursors, loading, error];
};

export default useServerCursors;
