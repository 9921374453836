import { ApolloClient } from 'apollo-client';
import { createHttpLink } from 'apollo-link-http';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { setContext } from 'apollo-link-context';
import { ApolloLink } from 'apollo-link';
import { onError } from 'apollo-link-error';
import { ONE_TIME_TOKEN_NAME } from '@meettry/engineer/utils/Constant';
import { useState } from 'react';

import auth from '@meettry/ui-components/utils/auth';

// https://github.com/apollographql/apollo-client/issues/5423
const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData: {
    __schema: {
      types: []
    }
  }
});

const endpoint = (() => {
  if (process.env.REACT_APP_ENVIRONMENT == 'local') {
    return `http://${process.env.REACT_APP_APIHOST}/graphql`;
  } else {
    return `https://${process.env.REACT_APP_APIHOST}/graphql`;
  }
})();

const credentials = (() => {
  switch (process.env.REACT_APP_ENVIRONMENT) {
    case 'local':
    case 'development':
    case 'staging':
    case 'production':
      return 'include';
    default:
      return 'same-origin';
  }
})();

const httpLink = createHttpLink({
  uri: endpoint,
  credentials: credentials
});
const errorLink = onError(({ operation, response, graphQLErrors, networkError }) => {
  console.log('operation', operation);
  console.log('response', response);
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
  }
  if (networkError) {
    console.log('onError networkError : ', networkError);
  }
});

const middleWareLink = setContext(async (_, previousContext) => {
  const authorization = await auth.getIdTokenJWT();

  return {
    ...previousContext,
    ...{
      headers: {
        ...authorization
      }
    }
  };
});

const afterWareLink = new ApolloLink((operation, forward) =>
  forward(operation).map((response) => {
    const {
      response: { headers }
    } = operation.getContext();
    if (headers) {
      // console.log('headers', headers);
      const csrfToken = headers.get(ONE_TIME_TOKEN_NAME);
      if (csrfToken) {
        document.cookie = `${ONE_TIME_TOKEN_NAME}=${csrfToken}`;
      }
    }
    process.env.REACT_APP_ENVIRONMENT !== 'production' && console.log('client response', response);
    return response;
  })
);

export const appClient = new ApolloClient({
  link: ApolloLink.from([afterWareLink, middleWareLink, errorLink, httpLink]),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all'
    },
    mutate: {
      errorPolicy: 'all'
    }
  },
  // fetchOptions: {
  //   mode: 'no-cors',
  // },
  cache: new InMemoryCache({ fragmentMatcher })
});

// TODO: useLazyQuery のonCompleted や fetchPolicy の動作がイマイチなので代替として機能するカスタムフック
export function useLazyQuery(query, variables) {
  const [resQuery, setResQuery] = useState({
    loading: true,
    error: null,
    data: null
  });
  // 最初にfalseためloading問題があってfalse => true 変更

  const doQuery = (options) => {
    setResQuery({ ...resQuery, loading: true });
    appClient
      .query({ query, variables, ...options })
      .then(({ data }) => {
        setResQuery({ ...resQuery, data, loading: false });
      })
      .catch((err) => {
        setResQuery({ ...resQuery, error: err, loading: false });
      });
  };

  return [doQuery, resQuery];
}
