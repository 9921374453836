import React from 'react';
import styled from 'styled-components';
import { FormItem } from '@meettry/engineer/components/molecules/Form';
import { JOB_SEEKING_LEVEL_LIST } from '@meettry/ui-components/constants';
import { toYYYYMMDD } from '@meettry/engineer/utils';

const EditProfilePreviewUnregister = (props) => {
  const { text = '未登録' } = props;
  return <StyledEditProfilePreviewUnregister>{text}</StyledEditProfilePreviewUnregister>;
};

export const EditProfilePreview = (props) => {
  const { userDetail, user } = props;
  const {
    address,
    birthDate,
    enableAcceptingScout,
    firstName,
    firstNameKana,
    jobSeekingLevel,
    lastName,
    lastNameKana,
    phone,
    postCode,
    sex,
    enableScoutChatNotifMail
  } = userDetail;
  const { bio, workStyles } = user;

  return (
    <StyledEditProfilePreview>
      <FormItem label="名前">
        <StyledEditProfilePreviewItem>
          {lastName && firstName ? `${lastName} ${firstName}` : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="フリガナ">
        <StyledEditProfilePreviewItem>
          {lastNameKana && firstNameKana ? (
            `${lastNameKana} ${firstNameKana}`
          ) : (
            <EditProfilePreviewUnregister />
          )}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="性別">
        <StyledEditProfilePreviewItem>
          {!sex && <EditProfilePreviewUnregister />}
          {sex === 1 && '男'}
          {sex === 2 && '女'}
          {sex === 9 && 'その他'}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="生年月日">
        <StyledEditProfilePreviewItem>
          {birthDate ? toYYYYMMDD(birthDate) : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="携帯番号">
        <StyledEditProfilePreviewItem>
          {phone ? phone : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="郵便番号">
        <StyledEditProfilePreviewItem>
          {postCode ? postCode : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="住所">
        <StyledEditProfilePreviewItem>
          {address ? address : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="稼働状況">
        <StyledEditProfilePreviewItem>
          {jobSeekingLevel ? (
            JOB_SEEKING_LEVEL_LIST.find((val) => val.value === userDetail.jobSeekingLevel).content
          ) : (
            <EditProfilePreviewUnregister
              text={JOB_SEEKING_LEVEL_LIST.find((val) => val.value === 0).content}
            />
          )}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="希望の働き方">
        <StyledEditProfilePreviewItem>
          {workStyles.length ? (
            workStyles.map((item) => (
              <StyledWorkStyleText key={item.id}>{item.name}</StyledWorkStyleText>
            ))
          ) : (
            <EditProfilePreviewUnregister />
          )}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="自己紹介">
        <StyledEditProfilePreviewItem>
          {bio ? <StyledBioText>{bio}</StyledBioText> : <EditProfilePreviewUnregister />}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="スカウト受付状況">
        <StyledEditProfilePreviewItem>
          {enableAcceptingScout === 1 && '受け取る'}
          {enableAcceptingScout === 0 && '受け取らない'}
        </StyledEditProfilePreviewItem>
      </FormItem>
      <FormItem label="スカウト通知設定">
        <StyledEditProfilePreviewItem>
          {enableScoutChatNotifMail === 1 && '受け取る'}
          {enableScoutChatNotifMail === 0 && '受け取らない'}
        </StyledEditProfilePreviewItem>
      </FormItem>
    </StyledEditProfilePreview>
  );
};

export default EditProfilePreview;

const StyledEditProfilePreview = styled.div`
  padding-top: 30px;
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledEditProfilePreviewItem = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  min-height: 48px;
  border-bottom: 1px solid #0fafa9;
`;

const StyledEditProfilePreviewUnregister = styled.p`
  font-weight: bold;
  color: #999999;
`;

const StyledWorkStyleText = styled.span`
  :not(:first-child) {
    margin-left: 5px;
    :before {
      content: '/';
      display: inline-block;
      padding-right: 5px;
    }
  }
`;

const StyledBioText = styled.p`
  word-break: break-all;
  white-space: pre-wrap;
  line-height: 1.75;
`;
