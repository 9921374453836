import React, { useState, useEffect, ChangeEvent, useRef } from 'react';
import styled from 'styled-components';

const MAX_TEXTAREA_HEIGHT_PIXEL = 220;

type Props = {
  roomId: string;
  sendMessage: (roomId: string, message: string) => void;
  loading: boolean;
  error: boolean;
  sendResult: any;
};

/**
 * ChatMessageInput
 */
const ChatMessageInput: React.FC<Props> = (props) => {
  const { sendResult, loading, error, sendMessage, roomId } = props;
  const [input, setInput] = useState('');

  /**
   * state
   */
  const [textareaHeight, setTextareaHeight] = useState<string>('auto');

  /**
   * hooks
   */
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  /**
   * input更新
   */
  const handleOnChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    e.preventDefault();
    setTextareaHeight('auto');
    setInput(e.target.value);
  };

  /**
   * テキストが更新されたらそのテキスト内容の高さに応じて、テキストエリアの高さを変更する
   */
  useEffect(() => {
    if (!textareaRef?.current) return;
    if (MAX_TEXTAREA_HEIGHT_PIXEL < textareaRef.current.scrollHeight) {
      setTextareaHeight(`${MAX_TEXTAREA_HEIGHT_PIXEL}px`);
    } else {
      setTextareaHeight(`${ textareaRef.current.scrollHeight }px`);
    }
  }, [input]);


  /**
   * 送信後のアクション
   * メッセージ入力を空にする
   */
  useEffect(() => {
    if (sendResult?.sendChatMessage?.ok && !loading && !error) {
      setInput('');
    }
  }, [sendResult, error, loading]);



  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    const message = input && input.trim();
    if (!roomId || !message) return;
    sendMessage(roomId, message);
  };

  return (
    <StyledChatInputWrapper>
      <StyledChatInputTextareaWrapper>
        <StyledChatTextarea
          ref={textareaRef}
          placeholder="メッセージを入力…"
          value={input}
          onChange={handleOnChange}
          disabled={loading}
          style={{ height: textareaHeight }}
          rows={1}
        />
      </StyledChatInputTextareaWrapper>
      <StyledChatInputButtonWrapper>
        <StyledChatSendButton
          onClick={handleSubmit}
          disabled={loading || !roomId}
        >
          送信
        </StyledChatSendButton>
      </StyledChatInputButtonWrapper>
    </StyledChatInputWrapper>
  );
};


export default ChatMessageInput;

const StyledChatInputWrapper = styled.div`
  && {
    display: flex;
    padding: 10px;
    align-items: flex-end;
  }
`;

const StyledChatInputTextareaWrapper = styled.div`
  && {
    width: 100%;
  }
`;
const StyledChatInputButtonWrapper = styled.div`
  && {
    display: flex;
    align-items: center;
  }
`;

const StyledChatTextarea = styled.textarea`
  && {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    display: block;
    padding: 10px;
    appearance: none;
    border: solid 1px #eeeeee;
    border-radius: 4px;
    font-size: 16px;
    resize: none;
    ::placeholder {
      color: #999999;
    }
  }
`;

const StyledChatSendButton = styled.button`
  && {
    display: block;
    background-color: #0fafa9;
    color: #ffffff;
    cursor: pointer;
    border: none;
    appearance: none;
    min-height: 40px;
    width: 100%;
    min-width: 86px;
    font-size: 14px;
    border-radius: 5px;
    margin-left: 10px;
    transition: opacity ease 0.3s;
    :hover {
      opacity: 0.8;
    }
  }
`;
