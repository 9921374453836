import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import { get } from 'lodash';

import { config } from '@meettry/ui-components/utils/firebase/config';

type ReturnVoid = () => void;

export const LoginStatus = {
  NotInitialized: 0,
  NotLogin: 1,
  LoginNotEmailVerified: 2,
  LoginEmailVerified: 3,
  LoginNicknameRegistered: 4
};

class Firebase {
  // analytics: firebase.analytics.Analytics;
  auth: firebase.auth.Auth;
  db: firebase.firestore.Firestore;

  constructor() {
    const project = firebase.initializeApp(get(config, `${process.env.REACT_APP_ENVIRONMENT}`, ''));
    //MEMO(aida) firebaseのanalyticsを無効化
    // this.analytics = project.analytics();
    this.auth = project.auth();
    this.db = project.firestore();
  }

  subscribeMaintenance(callback: any) {
    const maintenanceRef = this.db.collection('system_settings').doc('maintenance');
    const unsubscribe = maintenanceRef.onSnapshot(
      (snapshot) => {
        const { value } = <{ value: any }>snapshot.data();
        typeof callback === 'function' && callback(value);
      },
      (err) => {
        console.log(err);
      }
    );
    return unsubscribe;
  }

  applyActionCode(actionCode: string): Promise<any> {
    return this.auth.applyActionCode(actionCode);
  }

  signIn(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  signOut() {
    return this.auth.signOut();
  }

  signUp(email: string, password: string) {
    return this.auth.createUserWithEmailAndPassword(email, password);
  }

  getCurrentUser() {
    return this.auth?.currentUser ?? null;
  }

  // Login required
  sendEmailVerification(url: string) {
    return this.getCurrentUser()?.sendEmailVerification({ url });
  }

  // Login required
  updateName(name: string) {
    return this.getCurrentUser()?.updateProfile({ displayName: name });
  }

  // Login required
  updateEmail(email: string) {
    return this.getCurrentUser()?.updateEmail(email);
  }

  // Login required
  updatePassword(password: string) {
    return this.getCurrentUser()?.updatePassword(password);
  }

  sendPasswordResetEmail(email: string) {
    return this.auth.sendPasswordResetEmail(email);
  }

  onAuthStateChanged(func: firebase.Observer<any> | ReturnVoid) {
    return this.auth.onAuthStateChanged(func);
  }

  onIdTokenChanged(func: firebase.Observer<any> | ReturnVoid) {
    return this.auth.onIdTokenChanged(func);
  }

  isInitialized() {
    return new Promise((resolve) => {
      this.auth.onAuthStateChanged(resolve);
    });
  }

  async isUsedEmail(email: string) {
    const providers = await this.auth.fetchSignInMethodsForEmail(email);
    return (
      providers.findIndex(
        (p: string) => p === firebase.auth.EmailAuthProvider.EMAIL_PASSWORD_SIGN_IN_METHOD
      ) !== -1
    );
  }

  getLoginStatus() {
    if (this.auth === null) {
      return LoginStatus.NotInitialized;
    } else if (this.auth.currentUser === null) {
      return LoginStatus.NotLogin;
    } else if (!this.auth.currentUser.emailVerified) {
      return LoginStatus.LoginNotEmailVerified;
    } else if (!this.auth.currentUser.displayName) {
      return LoginStatus.LoginEmailVerified;
    } else {
      return LoginStatus.LoginNicknameRegistered;
    }
  }

  getCurrentUserName() {
    return this.getCurrentUser()?.displayName ?? null;
  }

  getCurrentUserEmail() {
    return this.getCurrentUser()?.email ?? null;
  }

  isCurrentUserEmailVerified() {
    return this.getCurrentUser()?.emailVerified ?? null;
  }

  // Login required
  async refreshUser() {
    await this.getCurrentUser()?.reload();
  }

  // Login required
  async refreshToken() {
    await this.getIdToken(true);
  }

  getIdToken(forceRefresh = true) {
    return this.getCurrentUser()?.getIdToken(forceRefresh);
  }

  getIdTokenResult() {
    return this.getCurrentUser()?.getIdTokenResult();
  }

  async getIdTokenJWT() {
    const getIdToken = await this.getIdToken();
    if (!getIdToken) return {};
    const token = await getIdToken;
    return { Authorization: `Bearer ${token}` };
  }

  reauthenticate(currentPassword: string) {
    const currentUserEmail = this.getCurrentUserEmail();
    const credential = firebase.auth.EmailAuthProvider.credential(
      !currentUserEmail ? '' : currentUserEmail,
      currentPassword
    );

    return this.getCurrentUser()?.reauthenticateWithCredential(credential) ?? null;
  }

  confirmPasswordReset(code: string, newPassword: string): Promise<void> {
    return this.auth.confirmPasswordReset(code, newPassword);
  }

  verifyPasswordResetCode(code: string): Promise<string> {
    return this.auth.verifyPasswordResetCode(code);
  }

  signInWithEmailAndPassword(
    email: string,
    password: string
  ): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithEmailAndPassword(email, password);
  }
}

export default new Firebase();
