import React, { useState, useEffect, useRef, useCallback } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type MenuListType = {
  Icon?: React.ReactNode;
  text?: string;
  onClick?: Function;
};

type EditMenuType = {
  list: Array<MenuListType>;
  toggleIcon: React.ReactNode;
};

const EditMenu: React.FC<EditMenuType> = (props) => {
  const { list = [], toggleIcon } = props;
  const [isShow, toggleShow] = useState(false);
  const menuRef = useRef<HTMLUListElement>(null);
  const toggleRef = useRef<HTMLDivElement>(null);

  const handleClickOutside = useCallback((e) => {
    if (
      menuRef.current &&
      toggleRef.current &&
      !menuRef.current.contains(e.target) &&
      !toggleRef.current.contains(e.target)
    ) {
      toggleShow(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  });

  return (
    <>
      <StyledEditToggle
        ref={toggleRef}
        onClick={(e) => {
          e.preventDefault();
          toggleShow(!isShow);
        }}
      >
        {toggleIcon}
      </StyledEditToggle>
      {isShow && (
        <StyledEditMenu ref={menuRef}>
          {list.map((val, i) => {
            const { Icon, text, onClick } = val;
            if (!text) return null;
            return (
              <StyledEditMenuItem
                onClick={(event) => {
                  !!onClick && onClick(event);
                }}
                key={i}
              >
                {Icon}
                <p>{text}</p>
              </StyledEditMenuItem>
            );
          })}
        </StyledEditMenu>
      )}
    </>
  );
};

export default EditMenu;

const StyledEditToggle = styled.div`
  padding: 10px;
  cursor: pointer;
`;

const StyledEditMenu = styled.ul`
  width: 140px;
  background: ${COLOR_DEFINITIONS.BG.WHITE};
  position: absolute;
  top: 36px;
  right: -24px;
  box-shadow: 0 2px 10px ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
  z-index: 10000;
`;

const StyledEditMenuItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 5px;
  cursor: pointer;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 14px;
  transition: backgroud ease 1s;
  &:not(first-child) {
    border-top-width: 1px;
    border-top-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-top-style: solid;
  }
  &:hover {
    background: ${COLOR_DEFINITIONS.BG.SUB};
  }
  p {
    padding-left: 5px;
  }
`;
