import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import ChatRoomList from '@meettry/ui-components/components/organisms/ChatRoomList';
import useChatRooms from '@meettry/ui-components/hooks/chat/useChatRooms';
import useServerCursors from '@meettry/ui-components/hooks/chat/useServerCursors';
import { useLoginStatus } from '@meettry/ui-components/utils/firebase';
import firebase from '@meettry/ui-components/utils/firebase';
import { ChatRoomWithMessages } from '@meettry/ui-components/types/chat';
import ChatMessages from '@meettry/ui-components/components/organisms/ChatMessages';
import ChatMessageInput from '@meettry/ui-components/components/organisms/ChatMessageInput';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { SEND_CHAT_MESSAGE } from '@meettry/ui-components/queries/chat';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { UserIdStateType } from '@meettry/ui-components/types/location';
import useChatRoom from '@meettry/ui-components/hooks/chat/useChatRoom';
import useChatNotifications from '@meettry/ui-components/hooks/chat/useChatNotifications';
import { FETCH_USER_BY_NICKNAMES_QUERY } from '@meettry/ui-components/queries/user';

/**
 * チャットページ
 */
const ChatPage: React.FC<PageCommonProps> = () => {
  /**
   * ログイン情報
   */
  const { user: myself, isEnterprise } = useLoginStatus();
  const uid = firebase.auth.currentUser?.uid ?? '';

  /**
   * Local State
   */
  const [searchChatText] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState<string>('');

  /**
   * Hooks
   */
  const history = useHistory();
  const location = useLocation<UserIdStateType>();
  const match = useRouteMatch<{ roomId: string }>();
  const [rooms, loadingRooms, errorRooms] = useChatRooms(firebase, uid);
  const [cursors, loadingCursors, errorCursors] = useServerCursors(firebase, uid, rooms);
  const {
    room,
    messages,
    getPrev,
    loadingOlders,
    errorOlders,
    loadingNewers,
    errorNewers
  } = useChatRoom(selectedRoomId, cursors);
  const { counts, markAsRead } = useChatNotifications();

  /**
   * Query
   */
  const [userByNicknamesData, setUserByNicknamesData] = useState<any>(null);
  const [
    fetchUserByNicknamesQuery,
    { loading: userByNicknamesLoading, error: userByNicknamesError }
  ] = useLazyQuery(FETCH_USER_BY_NICKNAMES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      console.log('onCompleted', data);
      if (data) setUserByNicknamesData(data);
    }
  });

  /**
   * Mutation
   */
  const [
    mutateSendChatMessage,
    { data: sendMessageData, error: sendMessageError, loading: sendMessageLoading }
  ] = useMutation(SEND_CHAT_MESSAGE);

  /**
   * Variables
   */
  const userId = location.state?.userId;
  const loading = loadingRooms || loadingCursors;
  const error = errorRooms || errorCursors;
  const selectedRoom = useMemo(() => rooms?.find((r) => r.id === selectedRoomId) ?? null, [
    rooms,
    selectedRoomId
  ]);
  const selectedRoomUser = useMemo(
    () =>
      userByNicknamesData?.users?.find(
        (user: { nickname: string }) => user.nickname === selectedRoom?.ownerName
      ) ?? null,
    [userByNicknamesData, selectedRoom]
  );
  const roomList = useMemo(
    () =>
      rooms
        .filter((room) => room?.totalMessages && room.totalMessages > 0)
        .map((room) => {
          const user =
            userByNicknamesData?.users?.find(
              (user: { nickname: string }) => room.ownerName === user.nickname
            ) ?? null;
          return {
            chat: room,
            text: '',
            name: user?.organization?.name ?? '',
            image: user?.organization?.logoImageThumbnail?.url ?? ''
          };
        }),
    [rooms, userByNicknamesData]
  );

  /**
   * 企業ユーザーの場合、企業画面チャットにリダイレクト
   */
  useEffect(() => {
    if (isEnterprise) {
      history.replace(`/enterprise/chat/${match.params.roomId}`);
    }
  }, [isEnterprise]);

  /**
   * /enterprise/chat/:roomId の roomIdが存在する場合はselectedRoomIdにセット
   */
  useEffect(() => {
    if (match.params.roomId) {
      setSelectedRoomId(match.params.roomId);
      return;
    }
  }, [match.params.roomId]);

  const selectRoom = (room: ChatRoomWithMessages) => history.replace(`/chat/${room.id}`);

  const handleSearchChatSubmit = (e: Event) => {
    e.preventDefault();
    //TODO(aida)検索処理の追加
    console.log('search for', searchChatText);
  };

  const sendMessage = (roomId: string, message: string) => {
    mutateSendChatMessage({ variables: { chatRoomId: roomId, message } });
  };

  /**
   * roomsが更新されたら、user情報を取得する
   * @param room
   */
  useEffect(() => {
    if (rooms.length > 0) {
      // user情報の取得
      const nicknames = rooms.map((room) => room.ownerName).filter((nickname) => nickname);
      if (nicknames.length > 0) {
        fetchUserByNicknamesQuery({ variables: { nicknames } });
      }
    }
  }, [rooms]);

  return (
    <StyledChatPage>
      <div>
        <ChatRoomList
          myself={myself}
          rooms={roomList}
          onSelectRoom={selectRoom}
          selectedRoomId={selectedRoomId}
          counts={counts}
        />
      </div>
      <StyledChatBody>
        <StyledChatBodyHeader>
          <span>{selectedRoomUser?.organization?.name ?? selectedRoomUser?.name}</span>
          {isEnterprise && <StyledChatScoutLink to={''}>スカウト名</StyledChatScoutLink>}
        </StyledChatBodyHeader>
        <ChatMessages
          messages={messages}
          ownId={uid}
          markAsRead={markAsRead}
          getPrev={getPrev}
          loadingOlders={loadingOlders}
          errorOlders={errorOlders}
          loadingNewers={loadingNewers}
          errorNewers={errorNewers}
          hasNewMessagesOlders={selectedRoom?.hasNewMessagesOlders ?? null}
          targetImageUrl={selectedRoomUser?.profileImageThumbnail?.url}
        />
        <ChatMessageInput
          roomId={selectedRoom?.id ?? ''}
          sendMessage={sendMessage}
          sendResult={sendMessageData}
          loading={sendMessageLoading}
          error={!!sendMessageError}
        />
      </StyledChatBody>
    </StyledChatPage>
  );
};
export default ChatPage;

const StyledChatPage = styled.div`
  min-height: 800px;
  display: flex;
  background-color: #ffffff;
  width: 900px;
  margin: 60px auto 60px;

  & > *:first-child {
    width: 260px;
    display: flex;
  }

  & > *:last-child {
    width: calc(100% - 260px);
  }
`;

const StyledChatBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid #eeeeee;
`;

const StyledChatBodyHeader = styled.div`
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eeeeee;

  & > *:first-child {
    font-size: 24px;
    font-weight: bold;
  }
`;

const StyledChatScoutLink = styled(Link)`
  font-size: 14px;
  color: #0fafa9;
  text-decoration: underline;
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:visited {
    color: #0fafa9;
  }
`;
