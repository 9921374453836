import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import HighlightedText from '@meettry/engineer/components/atoms/HighlightedText';
import { CircleAvatar } from '@meettry/engineer/components/atoms/Images/Avatars';
import iconPathUpdatedDate from '@meettry/engineer/images/icon-challenge-updated-date.png';
import { StackTag } from '@meettry/engineer/components/atoms/Tag';
import { getDaysBetween } from '@meettry/engineer/utils';

/**
 * CassetteChallenge
 */
const CassetteChallenge = (props) => {
  const { data, searchWord } = props;
  const history = useHistory();
  const userPagePath = `/user/${data.user.nickname}/challenge`;

  const onClickCassetteChallenge = () => {
    history.push(userPagePath);
  };

  return (
    <StyledCassetteChallenge onClick={() => onClickCassetteChallenge()}>
      <StyledContentHeader>
        <StyledUser>
          <CircleAvatar
            name="user_avatar"
            size="sm"
            src={data.user.profileImage ? data.user.profileImage.url : null}
          />
          <span>{data.user.nickname}</span>
        </StyledUser>
        <StyledUpdatedText>
          <img
            src={iconPathUpdatedDate}
            alt="最終更新日"
            width="17"
            style={{ marginRight: '5px', verticalAlign: '-5px' }}
          />
          {`Updated ${getDaysBetween(data.updated)}`}
        </StyledUpdatedText>
      </StyledContentHeader>
      <StyledChallengeContent>
        <StyledChallengeInfo>
          <HighlightedText keyword={searchWord}>{data.title}</HighlightedText>
        </StyledChallengeInfo>
        <StyledTagWrapper>
          <StyledTagContainer>
            {data.tags.map(({ displayName, name }, i) => (
              <StackTag key={`${i}_${name}`}>
                <HighlightedText keyword={searchWord}>{displayName}</HighlightedText>
              </StackTag>
            ))}
          </StyledTagContainer>
        </StyledTagWrapper>
        <StyledChallengeDescription>
          <HighlightedText keyword={searchWord}>{data.challengeDetail}</HighlightedText>
        </StyledChallengeDescription>
      </StyledChallengeContent>
    </StyledCassetteChallenge>
  );
};

export default CassetteChallenge;

const StyledCassetteChallenge = styled.div`
  display: block;
  padding: 20px 30px;
  text-decoration: none;
`;

const StyledUser = styled.div`
  display: inline-flex;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }

  & > *:nth-child(2) {
    font-size: 12px;
    color: #2459bf;
  }
`;

const StyledChallengeContent = styled.div`
  padding-top: 20px;
`;

const StyledContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledUpdatedText = styled.span`
  font-size: 12px;
  color: #999999;
`;

const StyledChallengeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    display: block;
  }
  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: #0fafa9;
    word-break: break-all;
    line-height: 1.2;
  }
`;

const StyledTagWrapper = styled.div`
  margin-top: 10px;
`;

const StyledTagContainer = styled.div`
  margin-top: -5px;
  margin-left: -5px;
  > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledChallengeDescription = styled.div`
  font-size: 14px;
  color: #333333;
  line-height: 1.75;
  margin-top: 20px;
  word-break: break-word;
`;
