import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useLocation, useRouteMatch } from 'react-router';
import { useHistory, Switch, Route } from 'react-router-dom';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { MatchParams } from '@meettry/ui-components/types/userPage';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import Breadcrumbs from '@meettry/ui-components/components/atoms/Breadcrumbs';
import Challenge from '@meettry/ui-components/components/organisms/UserPage/Challenge';
import Overview from '@meettry/ui-components/components/organisms/UserPage/Overview';
import Resume from '@meettry/ui-components/components/organisms/UserPage/Resume';
import { getPathArray } from '@meettry/ui-components/utils';
import TabLink from '@meettry/ui-components/components/molecules/TabLink';
import Users from '@meettry/ui-components/components/organisms/UserPage/Users';
import { FETCH_ENGINEER } from '@meettry/ui-components/queries/user_page';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';
import UserProfile from '@meettry/ui-components/components/organisms/UserPage/Common/UserProfile';
import SkillBox from '@meettry/ui-components/components/organisms/UserPage/Common/SkillBox';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const isShowBio = (page: string) => ['', 'resume', 'challenge'].includes(page);
const isShowSkillBox = (page: string) => ['', 'resume', 'challenge'].includes(page);
const isShowMainTab = (page: string) => ['', 'resume', 'challenge', 'chat'].includes(page);

const useUserPage = (nickname: string) => {
  const { state, storeEngineer, storeTagList, isOwner } = useEngineer();
  const {
    user: { nickname: userNickname }
  } = useLoginUser();
  const [isNotFound, setIsNotFound] = useState(false);

  //MEMO(aida) エンジニアの取得
  const { data, loading: loadingEngineer } = useQuery(FETCH_ENGINEER, {
    variables: { nicknames: [nickname] },
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      if (data.users?.length > 0) {
        setIsNotFound(false);
      } else {
        setIsNotFound(true);
      }
    }
  });

  //MEMO(aida) 取得したデータをstoreに格納する
  useEffect(() => {
    if (!loadingEngineer && data?.users?.length > 0) {
      storeEngineer(data.users[0]);
      storeTagList(data.tagList);
    }
  }, [data, loadingEngineer]);

  return {
    state,
    loading: loadingEngineer,
    isOwner,
    isNotFound,
    loginUserNickname: userNickname
  };
};

const UserPage = () => {
  const match = useRouteMatch<MatchParams>();
  const history = useHistory();
  const location = useLocation();
  const { nickname } = match.params;
  const { pathname: currentPath } = location;
  const { state, loading, isOwner, isNotFound, loginUserNickname } = useUserPage(nickname);
  const { resumeList, challengeList } = state;
  // MEMO(aida)[3]は現在のページのインデックス
  const currentPage = currentPath.split('/')[3] || '';

  // MEMO(aida) パンくずリスト作成用の配列
  const pathArray = getPathArray(
    history.location.pathname,
    match.params.nickname,
    loginUserNickname
  );

  // MEMO(aida) メインのタブを構成する要素
  const linkList = [
    {
      to: `/user/${nickname}`,
      name: 'overview',
      Content: () => <StyledNavLabel>ダッシュボード</StyledNavLabel>
    },
    {
      to: `/user/${nickname}/resume`,
      name: 'resume',
      count: resumeList.count,
      iconProps: { iconName: 'resume', width: '20' },
      Content: () => (
        <>
          <StyledNavLabel>経 歴</StyledNavLabel>
        </>
      )
    },
    {
      to: `/user/${nickname}/challenge`,
      name: 'challenge',
      count: challengeList.count,
      iconProps: { iconName: 'challenge', width: '20' },
      Content: () => (
        <>
          <StyledNavLabel>チャレンジ</StyledNavLabel>
        </>
      )
    }
  ];

  if (loading) {
    return <StyledLoader />;
  }

  if (isNotFound) {
    return (
      <StyledNoUserExistText>
        <NotFoundPage />
      </StyledNoUserExistText>
    );
  }

  return (
    <>
      {state.nickname && (
        <StyledMain>
          <StyledBreadcrumbs items={pathArray} />
          <StyledMainContainer>
            {isShowBio(currentPage) ? (
              <StyledUserProfileWrapper>
                <UserProfile />
              </StyledUserProfileWrapper>
            ) : null}
            <StyledPageMainContent>
              {isShowMainTab(currentPage) ? <TabLink linkList={linkList} /> : null}
              <StyledFlexColumnContainer>
                {isShowSkillBox(currentPage) ? <SkillBox /> : null}
                <Switch>
                  <Route path="/user/:nickname/resume">
                    <StyledWrapper>
                      <Resume />
                    </StyledWrapper>
                  </Route>
                  <Route path="/user/:nickname/challenge">
                    <StyledWrapper>
                      <Challenge />
                    </StyledWrapper>
                  </Route>
                  <Route path="/user/:nickname/:userstype">
                    <Users isOwner={isOwner} />
                  </Route>
                  <Route path="/user/:nickname">
                    <StyledWrapper>
                      <Overview isOwner={isOwner} />
                    </StyledWrapper>
                  </Route>
                </Switch>
              </StyledFlexColumnContainer>
            </StyledPageMainContent>
          </StyledMainContainer>
        </StyledMain>
      )}
    </>
  );
};

export default UserPage;

const StyledNoUserExistText = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
`;

const StyledWrapper = styled.div`
  height: 100%;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  padding: 50px;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
`;

const StyledMain = styled.div`
  flex: 1;
  padding: 50px 40px 30px;
  background: ${COLOR_DEFINITIONS.BG.SUB};
  position: relative;
  width: 100%;
  box-sizing: border-box;
`;

const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 1200px;
  padding: 0;
  margin: 0 auto;
`;

const StyledPageMainContent = styled.div`
  width: 100%;
  max-width: 900px;
  :only-child {
    margin: 0 auto;
  }
  :not(:first-child) {
    margin-left: 60px;
  }
`;

const StyledFlexColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledUserProfileWrapper = styled.div`
  width: 240px;
  flex-shrink: 0;
`;

const StyledNavLabel = styled.span`
  margin: 0 10px;
`;
