import React from 'react';
import styled from 'styled-components';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import {
  TopTitles,
  SectionWrapper,
  headerMenuList,
  HeaderMenuType
} from '@meettry/ui-components/components/organisms/Tops/utils';
import CatchImage from '@meettry/ui-components/images/commons/aboutus-copy.png';
import AboutBg from '@meettry/ui-components/images/commons/aboutus-bg.jpg';

const messages = [
  '政府の働き方改革、新型コロナウイルスによって働き方が急速に変化し、多様化してきています。',
  'しかし、新たな働き方や挑戦の場を見つける主要な手段は従来の人材紹介や求人サービスなどから、大きく変わっていないのが現状です。',
  '私たちは人材紹介や求人サービスの紹介手数料などの高額な成果報酬という、エンジニアを採用する企業の意思決定を躊躇わせる企業のハードルを取り除きます。そして、エンジニアに転職時期以外の企業や仲間との出会いをつくります。',
  'そんな、挑戦に前向きな人と企業が出会うプラットフォームを目指しています。'
];

const TopAbout = () => {
  const titleName = 'メトリィの想い';
  const sectionId = headerMenuList.find((obj: HeaderMenuType) => obj.name === titleName);
  return (
    <TopAboutSection id={sectionId && sectionId.target}>
      <TopAboutWrapper>
        <TopAboutTitleWrapper>
          <TopAboutTitle themeColor="white">
            <div>ABOUT US</div>
            <div>{titleName}</div>
          </TopAboutTitle>
        </TopAboutTitleWrapper>
        <TopAboutMessageBox>
          <AboutMessageCatch src={CatchImage} />
          <AboutMessages>
            {messages.map((msg, i) => (
              <span key={i}>{msg}</span>
            ))}
          </AboutMessages>
        </TopAboutMessageBox>
      </TopAboutWrapper>
    </TopAboutSection>
  );
};

const TopAboutSection = styled.div`
  background-image: url(${AboutBg});
  background-size: cover;
`;

const TopAboutWrapper = styled(SectionWrapper)`
  display: flex;
  margin: 100px auto 140px;
  ${mediaQuery.lessThan('breakPoint')`
   flex-direction: column;
 `}
`;

const TopAboutTitleWrapper = styled.div`
  position: relative;
  min-width: 252px;
  width: 252px;
  ${mediaQuery.greaterThan('breakPoint')`
  margin-right: 148px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  margin: 0 auto;
  `}
`;

const TopAboutTitle = styled(TopTitles)`
  ${mediaQuery.greaterThan('breakPoint')`
top: 50%; 
transform: translateY(-50%);
`}
`;

const TopAboutMessageBox = styled.div`
  ${mediaQuery.lessThan('breakPoint')`
  margin-top: 40px;
  `}
`;

const AboutMessageCatch = styled.img`
  ${mediaQuery.greaterThan('breakPoint')`
  width: 400px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  width: 100%;
  `}
`;

const AboutMessages = styled.p`
  ${FONT_DEFINITIONS.DAFAULT};
  color: white;
  span {
    display: block;
  }
  ${mediaQuery.greaterThan('breakPoint')`
  width: 540px;
  font-weight: bold;
  span{
    margin-bottom: 1em;
  }
  `}
  ${mediaQuery.lessThan('breakPoint')`
  width: 100%;
  span{
    margin-bottom: 2em;
  }
  `}
`;

export default TopAbout;
