import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { RouteProps } from 'react-router';

type Props = RouteProps & {
  publicRoute?: boolean; // 認証しないルーティングかどうか
  auth?: boolean; // 認証されたかどうか
  PreparingViewComponent: React.ReactNode; // 初期化中にみせるコンポーネント
  allow?: boolean; // 閲覧できるかどうか
  DisallowViewComponent: React.ReactNode; // 閲覧できない場合にみせるコンポーネント
  redirectPath?: '';
};

/**
 * AuthRoute
 * 認証処理つきルートコンポーネント
 */
const AuthRoute: React.FC<Props> = (props) => {
  const {
    publicRoute = false,
    PreparingViewComponent,
    auth = null,
    allow = null,
    DisallowViewComponent,
    redirectPath = '/',
    ...routeProps
  } = props;
  const location = useLocation();


  /**
   * パブリックルーティングであれば、そのまま表示
   */
  if (publicRoute) return <Route {...routeProps} />;


  /**
   * 初期化中ならローディング画面を見せる
   */
  if (auth === null) return <Route {...routeProps} render={(props) => PreparingViewComponent } />


  /**
   * 認証が必要なページで認証していないものは、ログインページへ
   */
  if (auth === false) {
    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
  }

  /**
   * 対象アカウントに特定のページを見せない場合、権限がないページを見せる
   */
  if (auth && !allow) return <Route {...routeProps} render={(props) => DisallowViewComponent } />


  /**
   * 上記の条件をクリアした場合、画面を表示
   */
  return <Route {...routeProps} />;
};
export default AuthRoute;
