//MEMO(aida)プロフィール編集画面にて生年月日optionaを作るための配列
const date = new Date();
export const options = {
  year: [],
  month: [],
  day: []
};
for (let i = date.getFullYear() - 10; i >= 1960; i--) {
  options.year = [...options.year, { value: i, content: i }];
}

for (let i = 1; i <= 12; i++) {
  options.month = [...options.month, { value: i, content: i }];
}

for (let i = 1; i <= 31; i++) {
  options.day = [...options.day, { value: i, content: i }];
}
