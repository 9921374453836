import React, { useCallback } from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type Props = {
  username: string;
  chatRating: number;
  changeRating: (num: number) => void;
  submit: () => void;
  disabledSubmit: boolean;
};

const starIcon = {
  iconName: 'star',
  iconColor: 'primary'
};

const starFilledIcon = {
  iconName: 'star_filled',
  iconColor: 'primary'
};

const MAX_EVALUATION_RANGE = 3;
const EVALUATION_ARRAY = [...Array(MAX_EVALUATION_RANGE)].map((_, i) => ++i);

/**
 * ChatEvaluation
 */
const ChatEvaluation: React.FC<Props> = (props) => {
  const { username, chatRating = 0, changeRating, submit, disabledSubmit } = props;

  const onSelect = (num: number) => () => changeRating(num);

  const Icons = useCallback(
    () =>
      EVALUATION_ARRAY.map((num) =>
        num <= chatRating ? (
          <Icon
            key={`icon_star_filled_${num}`}
            iconName="star_filled"
            iconColor="primary"
            onClick={onSelect(num)}
            width="20px"
          />
        ) : (
          <Icon
            key={`icon_star_${num}`}
            iconName="star"
            iconColor="primary"
            onClick={onSelect(num)}
            width="20px"
          />
        )
      ),
    [chatRating]
  );

  return (
    <StyledChatEvaluation>
      <StyledEvaluationTitle>コミュニケーション満足度</StyledEvaluationTitle>
      <StyledEvaluationStars>{Icons()}</StyledEvaluationStars>
      <StyledEvaluationMessage>
        {username}さんとのコミュニケーションの現時点での満足度を教えてください。
        <br />
        満足度自体が、相手方に伝わることはありません。
      </StyledEvaluationMessage>
      {!disabledSubmit && (
        <StyledButtons>
          <PrimaryButton size={STYLE_TYPE.SIZE.SMALL} width="80" onClick={submit}>
            回答する
          </PrimaryButton>
        </StyledButtons>
      )}
    </StyledChatEvaluation>
  );
};
export default ChatEvaluation;

const StyledChatEvaluation = styled.div`
  box-sizing: border-box;
  background-color: #d1f0f0;
  padding: 15px;
  width: 100%;

  > * + * {
    margin-top: 10px;
  }
`;

const StyledEvaluationTitle = styled.p`
  font-size: 12px;
  font-weight: bold;
  text-align: center;
`;

const StyledEvaluationStars = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  > * + * {
    margin-left: 5px;
  }
`;

const StyledEvaluationMessage = styled.p`
  font-size: 12px;
  text-align: center;
  line-height: 1.3;
`;

const StyledButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
