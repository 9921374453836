import React, { useReducer, useState, useEffect } from 'react';
import * as Validation from '@meettry/company/utils/validation';
import { Button } from '@meettry/company/component/atoms/btn';
import { Password } from '@meettry/company/component/atoms/input';
import { Modal } from '@meettry/company/component/organisms/modal';

import auth from '@meettry/ui-components/utils/firebase';

export type InputAccountProps = {
  mailAddress: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const initialState: InputAccountProps = {
  mailAddress: '',
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;

type InputAction = {
  type: typeof CHANGE_TEXT;
  text: string;
  item: keyof InputAccountProps;
  num: number;
};

const changeInput: React.Reducer<InputAccountProps, InputAction> = (
  state: InputAccountProps,
  action: InputAction
) => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return { ...state, [action.item]: action.text };
    default:
      return state;
  }
};

const ChangePassword: React.FC = () => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [currentPasswordError, setCurrentPasswordError] = useState('none');
  const [newPasswordError, setNewPasswordError] = useState('none');
  const [confirmPasswordError, setConfirmPasswordError] = useState('none');
  const [sendable, changeSendable] = useState(false);
  const [modalText, setModalText] = useState('');
  const inputAreaItems: {
    passwordText: string;
    require: boolean;
    state: string;
    stateKey: keyof InputAccountProps;
    error: string;
    setError: Function;
  }[] = [
    {
      passwordText: '現在のパスワード',
      require: true,
      state: state.currentPassword,
      stateKey: 'currentPassword',
      error: currentPasswordError,
      setError: setCurrentPasswordError
    },
    {
      passwordText: '新しいパスワード',
      require: false,
      state: state.newPassword,
      stateKey: 'newPassword',
      error: newPasswordError,
      setError: setNewPasswordError
    },
    {
      passwordText: '新しいパスワード確認用',
      require: false,
      state: state.confirmPassword,
      stateKey: 'confirmPassword',
      error: confirmPasswordError,
      setError: setConfirmPasswordError
    }
  ];
  const resetInputArea = () => {
    inputAreaItems.map((item) => {
      dispatch({ type: 'CHANGE_TEXT', text: '', item: item.stateKey, num: 0 });
      item.setError('none');
    });
  };

  const updatePassword = async () => {
    try{
      await auth.updatePassword(state.newPassword);
        resetInputArea();
        setModalText('パスワードを変更しました。');
    }catch(error) {
      setModalText('エラーが発生しました。しばらく時間をおいてお試しください');
    }
  };

  useEffect(() => {
    if (!state.newPassword) return;
    updatePassword();
  }, [sendable]);
  return (
    <form
      className="setting-container account"
      onSubmit={(e) => {
        Validation.changePassword(
          state,
          setCurrentPasswordError,
          setNewPasswordError,
          setConfirmPasswordError,
          changeSendable
        );
        e.preventDefault();
      }}
    >
      <div className="account-title">
        <h2>パスワード</h2>
      </div>
      <div className="account-contents">
        {inputAreaItems.map((item, i) => {
          return (
            <Password
              passwordText={item.passwordText}
              require={item.require}
              state={item.state}
              stateKey={item.stateKey}
              dispatch={dispatch}
              error={item.error}
              key={i}
            />
          );
        })}
      </div>
      <div className="btn-container">
        <Button buttonText="キャンセル" cls="btn third middle" onClick={() => resetInputArea()} />
        <Button buttonText="変更する" cls="btn primary middle" type={'submit'} />
      </div>
      <Modal show={!!modalText} displayModal={setModalText} cls="change-password-modal">
        <span>{modalText}</span>
        <Button buttonText="OK" cls="btn primary middle" onClick={() => setModalText('')} />
      </Modal>
    </form>
  );
};

export default ChangePassword;
