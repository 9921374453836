import React from 'react';
import styled from 'styled-components';
import { getDaysBetween, splitDateTime } from '@meettry/ui-components/utils';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { ChallengeLogListType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const getTime = (datetime: string) => {
  const afterStr = splitDateTime(datetime);
  const timeArray = afterStr[1].split(':');
  const time = timeArray[0] + ':' + timeArray[1];

  return time;
};

type ChallengeItemLogContentType = {
  item: ChallengeLogListType;
};

const ChallengeItemLogContent: React.FC<ChallengeItemLogContentType> = ({ item }) => (
  <LITimeLine key={item.id}>
    {/* タイトル */}
    <DivTimeLineTitle>
      <TimeLineTitleLabel>
        {`Updated ${getDaysBetween(item.created)} ${getTime(item.created)}  `}
        <StyledSpan>{`${item.beforeProgressRate}% > ${item.afterProgressRate}%`}</StyledSpan>
      </TimeLineTitleLabel>
      <TimeLineStone>
        <Icon iconName="challenge_commit" iconColor="gray" width="25px" />
      </TimeLineStone>
    </DivTimeLineTitle>
    {/* 内容 */}
    <DivTimeLineContent>
      <TimeLineContentText>{item.comment}</TimeLineContentText>
    </DivTimeLineContent>
  </LITimeLine>
);

type ChallengeItemLogType = {
  item: Array<ChallengeLogListType>;
  historyListRef: React.RefObject<HTMLUListElement>;
  created: string;
};

const ChallengeItemLog: React.FC<ChallengeItemLogType> = ({ item, historyListRef, created }) => {
  const logData = item.sort((a, b) => Number(b.id) - Number(a.id));
  return (
    <>
      {item && item.length > 0 && (
        <ULTimeLine ref={historyListRef}>
          {logData.map((data) => (
            <ChallengeItemLogContent key={data.id} item={data} />
          ))}
          <LITimeLine>
            <DivTimeLineTitle>
              <TimeLineTitleLabel>
                {`Created ${getDaysBetween(created)} ${getTime(created)}  `}
              </TimeLineTitleLabel>
              <TimeLineStone>
                <Icon iconName="challenge_commit" iconColor="gray" width="25px" />
              </TimeLineStone>
            </DivTimeLineTitle>
          </LITimeLine>
        </ULTimeLine>
      )}
    </>
  );
};

export default ChallengeItemLog;

const ULTimeLine = styled.ul`
  && {
    list-style: none;
    padding-left: 31px;
    border-left: 3px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    margin-top: 20px;
  }
`;

const LITimeLine = styled.li`
  && {
    position: relative;
  }
`;

const DivTimeLineTitle = styled.div`
  && {
    display: block;
  }
`;

const TimeLineTitleLabel = styled.label`
  && {
    z-index: 100;
    transition: transform 0.2s linear;
  }
`;

const DivTimeLineContent = styled.div`
  && {
    margin-top: 10px;
    margin-right: 20px;
    padding-bottom: 30px;
    position: relative;
    display: inline-block;
  }
`;

const TimeLineContentText = styled.p`
  && {
    word-break: break-all;
    font-size: 14px;
  }
`;

const TimeLineStone = styled.span`
  && {
    left: -53px;
    width: 24px;
    height: 24px;
    background: ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border: 9px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-radius: 50%;
    display: block;
    position: absolute;
    top: -10px;
  }
`;

const StyledSpan = styled.span`
  && {
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    font-size: 14px;
  }
`;
