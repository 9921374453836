import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type RadioButtonType = {
  label: string;
  name: string;
  value: any;
  checked: boolean;
  onChange: (value: any) => void;
  disabled?: boolean;
};

export const RadioButton: React.FC<RadioButtonType> = (props) => {
  const { label, name, value, checked, onChange, disabled = false } = props;
  const onChangeHandle = () => {
    typeof onChange === 'function' && onChange(value);
  };
  return (
    <StyledRadioButton checked={checked} disabled={disabled}>
      <input type="radio" name={name} checked={checked} onChange={onChangeHandle} />
      <span>{label}</span>
    </StyledRadioButton>
  );
};

export default RadioButton;

const StyledRadioButton = styled.label<{ checked?: boolean; disabled?: boolean }>`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-radius: 50%;
    box-sizing: border-box;
  }
  ::after {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    box-sizing: border-box;
  }
  span {
    font-size: 16px;
    display: block;
    line-height: 1.25;
  }
  input[type='radio'] {
    display: none;
  }
`;
