import { useEffect, useState } from 'react';

/**
 * useMousePosition
 * @param showMenu
 */
const useMousePosition = (showMenu?: boolean) => {
  const [mousePosition, setMousePosition] = useState<[number, number]>([0, 0]);

  const updateMousePosition = (e: MouseEvent) => {
    setMousePosition([e.clientX, e.clientY]);
  };

  useEffect(() => {
    if (showMenu) {
      window.addEventListener('mousemove', updateMousePosition);
    } else {
      window.removeEventListener('mousemove', updateMousePosition);
    }
    return () => window.removeEventListener('mousemove', updateMousePosition);
  }, [showMenu]);
  return mousePosition;
};
export default useMousePosition;
