import React from 'react';
import styled from 'styled-components';
import { ceil } from 'lodash';
import icons from '@meettry/ui-components/images/icons';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type PaginationType = {
  handleClick: Function;
  count: number;
  showCount: number;
  offset: number;
};

const Pagination: React.FC<PaginationType> = (props) => {
  const { count, showCount, handleClick, offset } = props;
  const pageCount = ceil(count / showCount);
  const disabledPrevious = offset === 1 ? 'disabled' : null;
  const disabledForward = offset + 1 > pageCount ? 'disabled' : null;
  const paginationCount = pageCount < 5 ? pageCount : 5;
  const countRange = 3;

  const FirstButton = (
    <React.Fragment key="pagination-first-btn">
      <StyledPaginationButton
        onClick={(e) => {
          handleClick(e, 1);
        }}
      >
        1
      </StyledPaginationButton>
      <StyledPaginationDots />
    </React.Fragment>
  );

  const LastButton = (
    <React.Fragment key="pagination-last-btn">
      <StyledPaginationDots />
      <StyledPaginationButton
        onClick={(e) => {
          handleClick(e, pageCount);
        }}
      >
        {pageCount}
      </StyledPaginationButton>
    </React.Fragment>
  );

  const buttons = [];

  for (let i = 0; i < paginationCount; i++) {
    let value = 0;
    if (offset <= countRange) {
      value = i + 1;
    }
    if (offset >= countRange && offset <= pageCount - countRange) {
      value = offset + i - 2;
    }
    if (offset > pageCount - countRange) {
      value = pageCount - paginationCount + i + 1;
    }
    const active = offset === value ? 'active' : null;
    buttons.push(
      <StyledPaginationButton
        key={i}
        onClick={(e) => {
          handleClick(e, value);
        }}
        active={active}
      >
        {value}
      </StyledPaginationButton>
    );
  }

  if (pageCount > 5 && offset > countRange) {
    buttons.unshift(FirstButton);
  }
  if (pageCount > 5 && offset <= pageCount - countRange) {
    buttons.push(LastButton);
  }

  return (
    <>
      {pageCount ? (
        <StyledPaginationWrapper>
          <StyledPaginationInner>
            <StyledPaginationButtonWrapper>
              <StyledPaginationArrowWrapper
                onClick={(e) => {
                  if (!!disabledPrevious) return;
                  handleClick(e, offset - 1);
                }}
              >
                <StyledPaginationArrowButton disabled={disabledPrevious} />
              </StyledPaginationArrowWrapper>
              {buttons}
              <StyledPaginationArrowWrapper
                onClick={(e) => {
                  if (!!disabledForward) return;
                  handleClick(e, offset + 1);
                }}
                back
              >
                <StyledPaginationArrowButton disabled={disabledForward} back />
              </StyledPaginationArrowWrapper>
            </StyledPaginationButtonWrapper>
          </StyledPaginationInner>
        </StyledPaginationWrapper>
      ) : null}
    </>
  );
};

export default Pagination;

const StyledPaginationWrapper = styled.div`
  margin-top: 40px;
`;

const StyledPaginationInner = styled.div`
  display: flex;
  justify-content: center;
`;

const StyledPaginationArrowWrapper = styled.div<{ back?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: ${(props) => (props.back ? '20px' : null)};
`;
const StyledPaginationArrowButton = styled.div<{ disabled: 'disabled' | null; back?: boolean }>`
  width: 12px;
  height: 12px;
  border: ${(props) =>
    props.disabled
      ? `solid ${COLOR_DEFINITIONS.TEXT.SECONDARY};`
      : `solid ${COLOR_DEFINITIONS.MAIN.PRIMARY}`};
  border-width: 2px 2px 0 0;
  margin: auto 0;
  transform: ${(props) => (props.back ? 'rotate(45deg)' : 'rotate(225deg)')};
  cursor: ${(props) => (props.disabled ? 'auto' : 'pointer')};
`;

const StyledPaginationButtonWrapper = styled.ol`
  display: flex;
`;
const StyledPaginationDots = styled.li`
  width: 16px;
  background: url(${icons.primary.ellipsis});
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  margin-left: 8px;
`;
const StyledPaginationButton = styled.li<{ active?: 'active' | null }>`
  font-size: 16px;
  color: ${(props) => (props.active ? COLOR_DEFINITIONS.BG.WHITE : COLOR_DEFINITIONS.MAIN.PRIMARY)};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background: ${(props) =>
    props.active ? COLOR_DEFINITIONS.MAIN.PRIMARY : COLOR_DEFINITIONS.BG.WHITE};
  cursor: pointer;
  margin-left: 8px;
  &:first-of-type {
    margin-left: 20px;
  }
`;
