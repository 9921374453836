import React from 'react';
import styled from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { FETCH_USER_PROFILE_IMAGE } from '@meettry/ui-components/queries/chat';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import { flexibleDateString } from '@meettry/ui-components/utils';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type Props = {
  nickname: string;
  message: string;
  createdAt: Date;
};

export const ActionMenuChatItem: React.FC<Props> = (props) => {
  const { nickname, message, createdAt } = props;

  const { data } = useQuery(FETCH_USER_PROFILE_IMAGE, { variables: { nicknames: [nickname] } });
  const imagePath = data?.users[0]?.profileImage?.url ?? '';

  return (
    <StyledChatActionMenuItem>
      <div>
        <CircleAvatar src={imagePath} size="smx" />
      </div>
      <StyledChatActionMenuItemBody>
        <StyledChatActionMenuItemTitle>
          <span>{nickname}</span>
          <span>{flexibleDateString(createdAt)}</span>
        </StyledChatActionMenuItemTitle>
        <StyledChatActionMenuItemMessage>{message}</StyledChatActionMenuItemMessage>
      </StyledChatActionMenuItemBody>
    </StyledChatActionMenuItem>
  );
};

const StyledChatActionMenuItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledChatActionMenuItemBody = styled.div`
  width: calc(100% - 50px);
  margin-left: 10px;
`;

const StyledChatActionMenuItemTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};

  & > *:nth-child(1) {
    font-size: 12px;
    font-weight: bold;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
`;

const StyledChatActionMenuItemMessage = styled.div`
  font-size: 12px;
  margin-top: 12px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;
