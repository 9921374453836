import React, { useState } from 'react';
import styled from 'styled-components';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

type ContactFormProps = {
  title: string;
  actionName: string;
  nameInputKey: string;
  mailInputKey: string;
  inquiryTypeInputKey: string;
  inquiryContentInputKey: string;
  agreeInputKey: string;
  optionArray: [];
};

// MEMO(中山):MTY-1415で対応
const ContactForm: React.FC<ContactFormProps> = (props) => {
  const [isSendCompletely, changeSendCompletely] = useState(false);
  const [isError, changeError] = useState(false);
  const [isNetworkError, changeNetworkError] = useState(false);

  const formInitState = {
    name: '',
    mail: '',
    inquiryType: '',
    inquiryContent: '',
    agree: ''
  };
  const [formInput, setFormInput] = useState(formInitState);
  const onFormInputChange = (
    target: string,
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  ) => {
    setFormInput({
      ...formInput,
      [target]: target !== 'agree' ? e.target.value : !formInput.agree ? '同意する' : ''
    });
  };

  const formErrorInitState = {
    name: false,
    mail: false,
    inquiryType: false,
    inquiryContent: false
  };
  const [formError, setFormError] = useState(formErrorInitState);
  const onFormErrorChange = (target: string, isError: boolean) => {
    setFormError({
      ...formError,
      [target]: isError
    });
  };

  const submit = () => {
    const submitParams = new FormData();
    submitParams.append(props.nameInputKey, formInput.name);
    submitParams.append(props.mailInputKey, formInput.mail);
    submitParams.append(props.inquiryTypeInputKey, formInput.inquiryType);
    submitParams.append(props.inquiryContentInputKey, formInput.inquiryContent);
    submitParams.append(props.agreeInputKey, `${formInput.agree}`);

    changeSendCompletely(true);
  };

  return (
    <StyledContactPage>
      <StyledTitleContent>
        <StyledOnTitleText>CONTACT</StyledOnTitleText>
        <StyledTitleText>{props.title}</StyledTitleText>
        {!isSendCompletely ? (
          <StyledUnderTheTitleText>お問い合わせ内容を入力してください。</StyledUnderTheTitleText>
        ) : null}
      </StyledTitleContent>

      <StyledForm
        onSubmit={(e) => {
          if (
            !!formInput.name &&
            !formError.name &&
            !!formInput.mail &&
            !formError.mail &&
            !!formInput.inquiryType &&
            !formError.inquiryType &&
            !!formInput.inquiryContent &&
            !formError.inquiryContent &&
            !!formInput.agree
          ) {
            changeError(false);
            submit();
          } else {
            changeError(true);
          }
          changeNetworkError(false);
          e.preventDefault();
        }}
      >
        {!isSendCompletely ? (
          <>
            <div>
              <label className="require">
                <span>お名前</span>
                <StyledInput
                  type="text"
                  placeholder="お名前をご記入ください。"
                  onChange={(e) => onFormInputChange('name', e)}
                  onBlur={(e) => {
                    onFormErrorChange('name', e.target.value === '');
                  }}
                />
              </label>
            </div>
            <div>
              <label className="require">
                <span>メールアドレス</span>
                <StyledInput
                  type="text"
                  placeholder="example@mail.co.jp"
                  onBlur={(e) => {
                    const mailError = !/^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
                      e.target.value
                    );
                    onFormErrorChange('mail', mailError);
                    !mailError && onFormInputChange('mail', e);
                  }}
                />
              </label>
            </div>
            <div>
              <label className="require">
                <span>お問い合わせの種類</span>
                <StyledSelectWrap>
                  <StyledSelect
                    onChange={(e) => {
                      onFormInputChange('inquiryType', e);
                    }}
                    value={formInput.inquiryType}
                    onBlur={(e) => {
                      onFormErrorChange('inquiryType', e.target.value === '');
                    }}
                  >
                    <option value="" disabled hidden>
                      選択してください
                    </option>
                    {props.optionArray.map((option, i) => (
                      <option key={i}>{option}</option>
                    ))}
                  </StyledSelect>
                </StyledSelectWrap>
              </label>
            </div>
            <div>
              <label className="require">
                <span>お問い合わせ内容</span>
                <StyledTextArea
                  placeholder="お問い合わせ内容の詳細をご記入ください。"
                  onChange={(e) => onFormInputChange('inquiryContent', e)}
                  onBlur={(e) => {
                    onFormErrorChange('inquiryContent', e.target.value === '');
                  }}
                />
              </label>
            </div>
            <StyledAgreeContent>
              <input
                type="checkbox"
                id="agree"
                value="同意する"
                onChange={(e) => {
                  onFormInputChange('agree', e);
                }}
              />
              <label htmlFor="agree">
                <span>
                  <StyledLink href="/privacy" target="_blank">
                    プライバシーポリシー
                  </StyledLink>
                  への同意
                </span>
              </label>
            </StyledAgreeContent>
            {!!isError ? <StyledErrorText>必須項目が入力されていません</StyledErrorText> : null}
            {!!isNetworkError ? <StyledErrorText>送信に失敗しました</StyledErrorText> : null}
            <StyledSubmitBtn type={'submit'}>送信</StyledSubmitBtn>
          </>
        ) : (
          <StyledSendCompletelyText>
            お問い合わせありがとうございました。
            <br />
            内容を確認の上、
            <br />
            後日担当者よりご連絡いたします。
          </StyledSendCompletelyText>
        )}
      </StyledForm>
    </StyledContactPage>
  );
};

export default ContactForm;

const StyledContactPage = styled.main`
  padding: 60px 70px 100px;
  color: #333333;
  ${mediaQuery.lessThan('minTablet')`
  padding: 60px 0 100px;
`}

  *::placeholder {
    color: #999999;
  }

  label.require {
    > span {
      font-weight: bold;
      &::after {
        content: '必須';
        display: inline-block;
        padding: 0 5px;
        margin-left: 8px;
        font-size: 10px;
        line-height: 16px;
        font-weight: normal;
        border-radius: 30px;
        vertical-align: middle;
        color: #f4233c;
        background-color: rgba(244, 35, 60, 0.1);
      }
    }
  }
`;

const StyledTitleContent = styled.div`
  text-align: center;
`;

const StyledOnTitleText = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 14px;
`;

const StyledTitleText = styled.div`
  font-weight: bold;
  font-size: 36px;
  margin-top: 10px;

  &::after {
    content: '';
    display: block;
    width: 64px;
    height: 4px;
    background-color: #0fafa9;
    border-radius: 80px;
    margin: 14px auto 0;
  }
`;

const StyledUnderTheTitleText = styled.div`
  margin-top: 40px;
  font-size: 16px;
  line-height: 1.5;
`;

const StyledForm = styled.form`
  margin: 40px auto 0;
  font-size: 14px;
  padding: 38px 40px;
  max-width: 750px;
  box-sizing: border-box;
  background-color: #ffffff;
  > div + div {
    margin-top: 26px;
  }
`;

const StyledInput = styled.input`
  margin-top: 10px;
  box-sizing: border-box;
  padding: 0 14px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
`;

const StyledTextArea = styled.textarea`
  margin-top: 10px;
  box-sizing: border-box;
  padding: 14px;
  width: 100%;
  height: 144px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  resize: none;
`;

const StyledSelect = styled.select`
  box-sizing: border-box;
  padding: 0 14px;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  appearance: none;
  color: #999999;
  ${(props) => (!!props.value ? 'color: #333333;' : null)}
`;

const StyledSelectWrap = styled.div`
  position: relative;
  cursor: pointer;
  margin-top: 10px;

  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border-top: 10px solid #343434;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    pointer-events: none;
  }
`;

const StyledAgreeContent = styled.div`
  margin-top: 36px;
  text-align: center;
  font-size: 16px;

  input {
    display: none;
  }

  label {
    position: relative;
    display: inline-block;
    > span {
      margin-left: 8px;
      line-height: 1.5;
      ${mediaQuery.lessThan('minTablet')`
        display: inline-block;
        max-width: 223.2px;
        width: 100%;
      `}
    }
    &::before {
      content: '';
      display: inline-block;
      width: 20px;
      height: 20px;
      border: solid 1px rgba(0, 0, 0, 0.4);
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      right: 100%;
      transform: translateY(-50%);
    }
  }

  input:checked {
    & + label {
      &::after {
        content: '';
        position: absolute;
        display: block;
        top: calc(50% - 5px);
        right: calc(100% + 3px);
        width: 10px;
        height: 4px;
        border-bottom: 3px solid #0fafa9;
        border-left: 3px solid #0fafa9;
        transform: rotate(-45deg);
      }
    }
  }
`;

const StyledSubmitBtn = styled.button`
  display: block;
  width: 100%;
  max-width: 300px;
  height: 60px;
  margin: 30px auto 0;
  border-radius: 100px;
  font-size: 18px;
  font-weight: bold;
  background-color: #0fafa9;
  color: #ffffff;
  transition: background-color 0.1s;

  &:hover {
    background-color: #008681;
  }
`;

const StyledLink = styled.a`
  color: #0fafa9;
  cursor: pointer;
  text-decoration: underline;
  ${mediaQuery.lessThan('minTablet')`
    display:inline-block;
  `}
`;

const StyledSendCompletelyText = styled.p`
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
`;

const StyledErrorText = styled.span`
  margin-top: 30px;
  padding: 4px 10px;
  display: block;
  font-size: 12px;
  color: #f4233c;
  background-color: rgba(244, 35, 60, 0.1);
`;
