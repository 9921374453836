import shared from '@meettry/ui-components/constants/shared.json';

export default {
  ...shared
};

/**
 * ユーザータイプ
 */
export const USER_TYPE = {
  CUSTOMER: 'CUSTOMER',
  ENTERPRISE: 'ENTERPRISE',
  ADMIN: 'ADMIN'
};

/**
 * 検索用のURL query のkey name
 */
export const URL_SEARCH_PARAM_KEY = {
  SEARCH_QUERY: 'q',
  SEARCH_TYPE: 'type',
  SEARCH_PAGE: 'page',
  SEARCH_DISPLAY_NUMBER: 'd'
};

/**
 * 検索ターゲットのタイプ
 */
export const SEARCH_TYPE = {
  USER: 'user',
  RESUME: 'resume',
  CHALLENGE: 'challenge',
  CHAT: 'chat'
};

/**
 * Styling用の定数
 */
export const STYLE_TYPE = {
  SIZE: { LARGE: 'LARGE', MEDIUM: 'MEDIUM', SMALL: 'SMALL' },
  ROLE: {
    PRIMARY: 'PRIMARY',
    SECONDARY: 'SECONDARY',
    THIRDLY: 'THIRDLY',
    GRAY: 'GRAY',
    WHITE: 'WHITE',
    ROUND: 'ROUND'
  }
};

/**
 * Iconの色の定数
 */
export const ICON = {
  COLOR: {
    PRIMARY: 'primary',
    BLACK: 'black',
    GRAY: 'gray',
    RED: 'red',
    WHITE: 'white'
  },
  NAME: {
    ARROW_DOWN: 'arrowDown',
    ARROW_LEFT: 'arrowLeft',
    ARROW_RIGHT: 'arrowRight',
    ARROW_UP: 'arrowUp',
    CAMERA: 'camera',
    CHALLENGE_COMMIT: 'challengeCommit',
    CHALLENGE: 'challenge',
    CHAT: 'chat',
    CLIP: 'clip',
    CODE: 'code',
    COMPANY: 'company',
    CREATE_CHALLENGE: 'createChallenge',
    DAY: 'day',
    DB: 'db',
    DELETE: 'dlt',
    EDIT: 'edit',
    EDITING_BUTTON: 'editingButton',
    FOLLOWERS: 'followers',
    FOLLOWING: 'following',
    FRAMEWORK: 'framework',
    FRIEND: 'friend',
    HEART: 'heart',
    IMAGE: 'image',
    LIKE: 'like',
    LINK: 'link',
    MAP: 'map',
    MINUS: 'minus',
    NEW_LIST: 'newList',
    NEWS: 'news',
    OTHERCODE: 'othercode',
    PC: 'pc',
    PIN: 'pin',
    PLUS: 'plus',
    PROFILE: 'profile',
    RESUME: 'resume',
    SCOUT: 'scout',
    SEARCH: 'search',
    SERVER: 'server',
    TAG_DELETE: 'tagDelete',
    TIME: 'time',
    TO: 'to',
    UPDATE: 'update',
    VIEW: 'view',
    CHECK_BOX: 'checkBox'
  }
};

/**
 * 注意表示文句
 */
export const CAUTION_PHRASE = {
  AGENDA: {
    title: '条件に一致するユーザーはいませんでした',
    content: 'キーワードを確認・変更して検索し直してください。'
  }
};

/**
 * 性別リスト
 */
export const SEX_LIST = [
  { value: 1, content: '男' },
  { value: 2, content: '女' },
  { value: 9, content: 'その他' }
];

/**
 * 稼働状況の定数リスト
 */
export const JOB_SEEKING_LEVEL_LIST = [
  { value: 0, content: '探していない' },
  { value: 1, content: '相談次第' },
  { value: 2, content: 'そこまでではない' },
  { value: 3, content: 'いいのがあれば' },
  { value: 4, content: 'がっつり' }
];

/**
 * 禁止用語リスト
 */
export const BANNED_WORD_LIST = [
  'shit',
  'piss',
  'tits',
  'fuck',
  'cunt',
  'cock',
  'cocksucker',
  'motherfucker',
  'bitch',
  'goddamn',
  'asshole',
  'nigger',
  'moron'
];

/**
 * チャレンジの入力制限
 */
export const CHALLENGE_FORM_INPUT = {
  LENGTH: {
    TITLE: 50,
    CHALLENGE_DETAIL: 400
  }
};
