import gql from 'graphql-tag';

export const FETCH_FOLLOWEE_QUERY = gql`
  query {
    loginSession {
      user {
        followees {
          nickname
        }
      }
    }
  }
`;

export const FOLLOW_USER_QUERY = gql`
  mutation($userId: ID!) {
    followUser(input: { userId: $userId }) {
      ok
    }
  }
`;

export const UNFOLLOW_USER_QUERY = gql`
  mutation($userId: ID!) {
    unfollowUser(input: { userId: $userId }) {
      ok
    }
  }
`;

// 検索取得
export const SEARCH_QUERY = gql`
  query($queryString: String!, $first: Int!, $offset: Int!, $field: String!) {
    searchFor(queryString: $queryString) {
      users {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          nickname
          location
          bio
          profileImage {
            url
          }
        }
      }
      resumes {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          title
          companyName
          workDetail
          workStartDate
          workEndDate
          updated
          user {
            nickname
            profileImage {
              url
            }
          }
          tags {
            name
            displayName
            category
          }
        }
      }
      challenges {
        count
        queryString
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: $field, order: DESC }]
        ) {
          id
          title
          challengeDetail
          updated
          tags {
            name
            displayName
          }
          user {
            nickname
            profileImage {
              url
            }
          }
        }
      }
    }
  }
`;
