import React from 'react';
import { useHistory } from 'react-router-dom';
import ChallengeItem from '@meettry/company/component/organisms/userPage/Challenge/ChallengeItem';
import EmptyListParts from '@meettry/company/component/organisms/userPage/Dashboard/EmptyListParts';

const langList: Array<string> = ['Kubernetes', 'DevOps', 'Google Cloud Platform', 'Istio'];
export const challengeListItem = [
  {
    title: 'Kubernetesを使用したDevOpsの習得',
    langTag: langList,
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloudRunも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキスト',
    achievement: 40,
    id: '1',
    isPin: true,
    update: true,
    createdAt: '2021/01/02 20:00'
  },
  {
    title: 'Kubernetesを使用したDevOpsの習得',
    langTag: langList,
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloudRunも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキスト',
    achievement: 30,
    id: '2',
    isPin: false,
    update: false,
    createdAt: '2021/01/03 19:00'
  },
  {
    title: 'Kubernetesを使用したDevOpsの習得',
    langTag: langList,
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloudRunも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキスト',
    achievement: 30,
    id: '3',
    isPin: true,
    update: true,
    createdAt: '2021/01/04  18:00'
  }
];

export const ChallengeParts: React.FC = () => {
  const history = useHistory();

  const onClickChallengeTitle = (id: string) => () => {
    history.push('/user_name/challenge', { selectedChallengeId: id });
    window.scrollTo(0, 0);
  };

  return (
    <React.Fragment>
      {challengeListItem.length ? (
        <ul className="challenge-parts-wrap">
          {challengeListItem.map((item, i) => {
            return (
              item.isPin && (
                <li key={i}>
                  <ChallengeItem listItem={item} onClick={onClickChallengeTitle(item.id)} />
                </li>
              )
            );
          })}
        </ul>
      ) : (
        <EmptyListParts text="チャレンジ" />
      )}
    </React.Fragment>
  );
};
