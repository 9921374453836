import React from 'react';
import styled, { css } from 'styled-components';
import { CHECKBOX } from '@meettry/engineer/utils/Constant';

export const CheckBox = (props) => {
  const { value, checked, onChange, id, name, label, size } = props;
  return (
    <StyledWrapListContents>
      <StyledCheckboxInput
        type="checkbox"
        value={value}
        id={id}
        name={name}
        checked={checked}
        onChange={onChange}
      />
      <StyledCheckLabel htmlFor={id} size={size}>
        {label}
      </StyledCheckLabel>
    </StyledWrapListContents>
  );
};

export default CheckBox;

const StyledWrapListContents = styled.div`
  display: flex;
  align-items: center;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
`;

const StyledCheckboxInput = styled.input`
  display: none;
  position: relative;
`;

const StyledCheckLabel = styled.label`
  cursor: pointer;
  display: inline-block;
  padding-left: 30px;
  ${({ size }) => {
    switch (size) {
      case CHECKBOX.SIZE.SMALL:
        return css`
          font-size: 12px;
        `;
      default:
        return css`
          font-size: 16px;
        `;
    }
  }}
  position: relative;
  line-height: 20px;
  & + & {
    margin-left: 50px;
  }
  &::after {
    content: '';
    display: none;
    position: absolute;
    box-sizing: border-box;
    left: 5px;
    top: 50%;
    transform: translateY(-75%) rotate(-45deg);
    height: 5px;
    width: 10px;
    border-bottom: 2px solid #0fafa9;
    border-left: 2px solid #0fafa9;
  }
  &::before {
    content: '';
    display: inline-block;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    position: absolute;
    left: 0;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    border-radius: 4px;
  }
  ${StyledCheckboxInput}:checked + &::after {
    display: inline-block;
  }
`;
