import React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type TextFieldProps = {
  type?: string | never;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onKeyDown?: React.KeyboardEventHandler<HTMLInputElement>;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
  placeholder: string;
  defaultValue?: string | number | readonly string[];
  error?: boolean;
  className?: string;
  value?: string;
  width?: string | number;
  pattern?: string;
};
export const TextField = React.forwardRef(
  (
    props: TextFieldProps,
    ref?:
      | ((instance: HTMLInputElement | null) => void)
      | React.MutableRefObject<HTMLInputElement | null>
      | null
  ) => {
    const {
      type = 'text',
      onChange,
      placeholder,
      defaultValue,
      error,
      className,
      value,
      onKeyDown,
      ...restProps
    } = props;
    return (
      <StyledTextfield
        type={type}
        className={className}
        onChange={onChange}
        placeholder={placeholder}
        defaultValue={defaultValue}
        error={error}
        value={value}
        onKeyDown={onKeyDown}
        ref={ref}
        {...restProps}
      />
    );
  }
);

export default TextField;

const StyledTextfield = styled.input<{ error?: boolean }>`
  width: 100%;
  max-height: 48px;
  font-size: 16px;
  padding: 16px 20px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  border: ${COLOR_DEFINITIONS.LINE.SECONDARY} solid 1px;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-radius: 4px;
  }
  ${({ error }) =>
    error &&
    css`
      background-color: ${COLOR_DEFINITIONS.BG.MODAL.MESSAGE};
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      :focus {
        outline: none;
        border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      }
    `}
`;
