import React, { useRef, useEffect } from 'react';


const ANIME_SPEED = 3;
type DonutGraphProps = {
  percent: number;
};

export const DonutGraph: React.FC<DonutGraphProps> = (props) => {
  const { percent } = props;
  const canvas = useRef<HTMLCanvasElement>(null);
  useEffect(() => {
    if (!!canvas.current) {
      const ctx = canvas.current.getContext('2d');

      if (!ctx) return;
      const draw = (
        strokeStyle: string | CanvasGradient,
        arcStartPoint: number,
        arcEndPoint: number
      ) => {
        ctx.beginPath();
        ctx.strokeStyle = strokeStyle;
        ctx.arc(65, 65, 58.5, arcStartPoint, arcEndPoint);
        ctx.lineWidth = 13;
        ctx.stroke();
      };

      const grd = ctx.createLinearGradient(0, 0, 150, 0);
      grd.addColorStop(1, '#0fafa9');
      grd.addColorStop(0, '#00dddd');

      let animeCount = 0;
      let callbackId: number | null = null;
      const animeFunction = () => {
        if (!canvas.current) return;
        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

        const arcPoint = 100 * ((animeCount / 100) * (percent / 100 + 1 - animeCount / 100));
        draw('#F7F7F7', (Math.PI * (360 * (arcPoint / 100) - 90)) / 180, (Math.PI * 360) / 180);
        draw(grd, ((0 - 90) * Math.PI) / 180, (Math.PI * (360 * (arcPoint / 100) - 90)) / 180);

        if (animeCount + ANIME_SPEED > percent) {
          animeCount += percent - animeCount;
        } else if (animeCount + ANIME_SPEED <= percent) {
          animeCount += ANIME_SPEED;
        }

        if (animeCount <= percent) {
          callbackId = requestAnimationFrame(animeFunction);
        } else if (!!callbackId) {
          cancelAnimationFrame(callbackId);
        }
      };

      animeFunction();
    }
  }, []);
  return <canvas width="130" height="130" ref={canvas} />;
};
