import React from 'react';
import styled from 'styled-components';

// 白背景、青文字、青枠、角丸 クリッカブル(経歴のタグなどに使用するもの)
export const Tag = (props) => {
  const {className, onClick,children } = props;
  const onClickHandle = (e)=>{
    e.preventDefault();
    typeof onClick === 'function' && onClick();
  }
  return <StyledTag className={className} onClick={onClickHandle}>{children}</StyledTag>;
};

const StyledTag = styled.div`
  display: inline-block;
  user-select: none;
  font-size: 12px;
  color: #2459bf;
  border: 1px solid #2459bf;
  padding: 3px 10px;
  border-radius: 50px;
`;
