import React, { useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { CHALLENGE_FORM_INPUT } from '@meettry/ui-components/constants';
import { ChallengeType, TagType } from '@meettry/ui-components/types/userPage';
import { UPDATE_CHALLENGE } from '@meettry/ui-components/queries/user_page';
import AdditionalTagInput from '@meettry/ui-components/components/organisms/Forms/AdditionalTagInput';
import { makeHandleSubmit } from '@meettry/ui-components/components/organisms/UserPage/Challenge/util';
import { TextField, Textarea } from '@meettry/ui-components/components/atoms/Input';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import { FormItem } from '@meettry/ui-components/components/molecules/Form';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type ChallengeEditType = {
  item: ChallengeType;
  onUpdate: (challenge: ChallengeType) => void;
  onClose: () => void;
};

export const ChallengeEdit: React.FC<ChallengeEditType> = (props) => {
  const { item, onUpdate, onClose } = props;
  const { showCancelConfirmationDialog, closeDialog } = useDialog();
  const { startLoading, endLoading } = useLoading();
  const { showErrorPopup, showSuccessPopup } = usePopup();
  //TODO(aida) memo化
  const initState = {
    id: item.id,
    title: item.title,
    tags: item.tags,
    challengeDetail: item.challengeDetail,
    progressRate: item.progressRate,
    isPinned: !!item.isPinned,
    isVisible: !!item.isVisible
  };
  const [dirty, setDirty] = useState(false);
  const [challengeInput, updateChallenge] = useState(initState);
  const [mutateUpdateChallenge, { data, error, loading }] = useMutation(UPDATE_CHALLENGE, {
    onCompleted: ({ updateChallenge }) => {
      if (!updateChallenge) {
        showErrorPopup('チャレンジを更新できませんでした');
        return;
      }
      const { challenge } = updateChallenge;
      onUpdate(challenge);
      endLoading();
      showSuccessPopup('チャレンジを更新しました');
    }
  });
  useEffect(() => {
    const isError = error;
    if (!isError) return;
    if (error) showErrorPopup('チャレンジを更新できませんでした');
  }, [error]);
  //MEMO(aida) 必須入力項目のチェック
  const [formFillError, setFormFillError] = useState({
    title: false,
    challengeDetail: false
  });
  const inputCheckSubjects = Object.keys(formFillError);
  //MEMO(aida) 入力文字数をチェックする
  const [formInputError, setFormInputError] = useState({
    title: false,
    challengeDetail: false
  });

  const handleSubmit = () => {
    const { title, challengeDetail } = challengeInput;
    if (!title || !challengeDetail) {
      setFormFillError({ title: !title, challengeDetail: !challengeDetail });
      return;
    }
    if (Object.values(formInputError).find((val) => val === true)) return;
    const editChallenge = {
      ...challengeInput,
      comment: challengeInput.challengeDetail
    };
    makeHandleSubmit(editChallenge, mutateUpdateChallenge)();
    startLoading('チャレンジを更新しています。');
  };
  const onChangeHandler = (target: string) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dirty || setDirty(true);
    const { value } = e.target;
    if (inputCheckSubjects.includes(target)) {
      setFormFillError({ ...formFillError, [target]: !value });
    }
    if (target === 'title') {
      if (!(value.length > CHALLENGE_FORM_INPUT.LENGTH.TITLE)) {
        setFormInputError({ ...formInputError, [target]: false });
        updateChallenge({ ...challengeInput, [target]: value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    if (target === 'challengeDetail') {
      if (!(value.length > CHALLENGE_FORM_INPUT.LENGTH.CHALLENGE_DETAIL)) {
        setFormInputError({ ...formInputError, [target]: false });
        updateChallenge({ ...challengeInput, [target]: value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    updateChallenge({ ...challengeInput, [target]: value });
  };

  const onChangeTags = (value: Array<TagType>) => {
    dirty || setDirty(true);
    updateChallenge({ ...challengeInput, tags: value });
  };

  const cancelHandler = () => {
    if (dirty) {
      showCancelConfirmationDialog(['保存されていない変更を破棄しますか？'], onClose, closeDialog);
      return;
    }
    onClose();
  };

  const titleErrorText = useMemo(
    () =>
      formFillError.title
        ? '項目が未入力です'
        : formInputError.title
        ? 'タイトルは50文字までです'
        : '',
    [formFillError.title, formInputError.title]
  );
  const detailErrorText = useMemo(
    () =>
      formFillError.challengeDetail
        ? '項目が未入力です'
        : formInputError.challengeDetail
        ? 'チャレンジ説明は400文字までです'
        : '',
    [formFillError.challengeDetail, formInputError.challengeDetail]
  );

  return (
    <StyledChallengeEditForm>
      <StyledFormInputs>
        <FormItem
          label="タイトル"
          required={true}
          error={formFillError.title || formInputError.title}
          errorText={titleErrorText}
        >
          <TextField
            onChange={onChangeHandler('title')}
            defaultValue={challengeInput.title}
            placeholder="チャレンジのタイトルを入力してください"
            error={formFillError.title || formInputError.title}
          />
        </FormItem>
        <FormItem label="言語・環境">
          <AdditionalTagInput
            placeholder="言語・環境を入力"
            value={challengeInput.tags}
            onChange={onChangeTags}
          />
        </FormItem>
        <FormItem
          label="チャレンジ説明"
          required={true}
          error={formFillError.challengeDetail || formInputError.challengeDetail}
          errorText={detailErrorText}
        >
          <Textarea
            onChange={onChangeHandler('challengeDetail')}
            defaultValue={challengeInput.challengeDetail}
            placeholder="現在自身のスキルアップのためにチャレンジしている内容の説明を記載ください。"
            error={formFillError.challengeDetail || formInputError.challengeDetail}
          />
        </FormItem>
        <StyledFooter>
          <MonochromeButton onClick={cancelHandler} type="button">
            キャンセル
          </MonochromeButton>
          <PrimaryButton disabled={loading} onClick={handleSubmit} type="button">
            変更する
          </PrimaryButton>
        </StyledFooter>
      </StyledFormInputs>
    </StyledChallengeEditForm>
  );
};

export default ChallengeEdit;

const StyledChallengeEditForm = styled.form`
  padding: 38px 30px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledFormInputs = styled.div`
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 20px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  > * {
    max-width: 120px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;
