import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';

type TabType = {
  name: string;
  type: string;
  path?: string;
};

type SettingTabType = {
  tab: Array<TabType>;
  active?: string;
  className?: string;
};

export const SettingTab: React.FC<SettingTabType> = (props) => {
  const { tab, active, className } = props;

  return (
    <StyledSettingsTabs className={className}>
      {tab.map((item, index) =>
        item.path ? (
          <StyledSettingsTab to={item.path} key={index} tablength={tab.length}>
            <StyledSettingsTabItem active={active === item.path}>{item.name}</StyledSettingsTabItem>
          </StyledSettingsTab>
        ) : null
      )}
    </StyledSettingsTabs>
  );
};

const StyledSettingsTabs = styled.ul<{ className?: string }>`
  display: flex;
  width: 706px;
  height: 30px;
  margin: 0 auto;
  padding: 0;
  text-align: center;
`;

const StyledSettingsTab = styled<any & { tablength: number }>(Link)`
  display: block;
  width: ${({ tablength }) => `calc(100% / ${tablength})`};
  font-size: 14px;
  font-family: ${FONT_FAMILY.COMMON};
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  line-height: 30px;
  border-left: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  cursor: pointer;
  &:last-of-type {
    border-right: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledSettingsTabItem = styled.span<{ active: boolean }>`
  ${({ active }) =>
    active
      ? `display: block;
        width: calc(100% - 20px);
        margin: 0 auto;
        border-radius: 5px;
        background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN_LIGHT};
        color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};`
      : null}
`;
