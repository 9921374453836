import React, { useMemo } from 'react';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { useQuery } from '@apollo/react-hooks';
import EditProfileForm from '@meettry/engineer/components/organisms/EditProfile';
import { GET_PROFILE_EDIT_PAGE_DATA } from '@meettry/engineer/graphql/queries/user_profile_edit_page';
import ApplicationPage from '@meettry/engineer/components/templates/ApplicationPage';

/**
 * EditProfile Page Component
 * ユーザー情報を編集するページ
 */
const EditProfilePage = () => {
  const {
    user: { nickname }
  } = useLoginUser();
  // ユーザー情報編集ページに必要なデータを取得
  const { data, error, loading } = useQuery(GET_PROFILE_EDIT_PAGE_DATA);
  // ぱんくずリスト
  const breadcrumbs = useMemo(
    () => [
      { path: `/user/${nickname}`, text: 'TOP' },
      { path: '/profile', text: 'プロフィール編集' }
    ],
    [nickname]
  );
  const applicationPageProps = { data, error, loading, breadcrumbs, pageType: 'slim' };
  return (
    <ApplicationPage {...applicationPageProps}>
      {data ? (
        <EditProfileForm profile={data.loginSession} workStyleList={data.workStyles} />
      ) : null}
    </ApplicationPage>
  );
};

export default EditProfilePage;
