import React, { useState, useEffect, useRef, useMemo } from 'react';
import { LineTag, BasicTag } from '@meettry/ui-components/components/atoms/Tag';
import EditMenu from '@meettry/ui-components/components/atoms/EditMenu';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { Scout } from '@meettry/ui-components/types/scout';
import { TAG_CATEGORY } from '@meettry/ui-components/utils/Constant';
import { Link } from 'react-router-dom';
import useMousePosition from '@meettry/ui-components/hooks/useMousePosition';
import useSetDisplayMenu from '@meettry/ui-components/hooks/useSetDisplayMenu';

/**
 * BookmarkedListItem Component
 * 全てのお気に入りをしたユーザーを表示するリストアイテム
 */
type BookmarkedListItemProps = {
  title: string;
  count: number;
};
export const BookmarkedListItem: React.FC<BookmarkedListItemProps> = (props) => {
  const { title, count } = props;
  return (
    <StyledScoutListContent to={'/enterprise/scout/bookmark'}>
      <div>
        <StyledScoutListTitle>{title}</StyledScoutListTitle>
      </div>
      <StyledScoutListRightContent>
        <StyledScoutListNumber>
          {count}
          <StyledScoutListNumberSmall>人</StyledScoutListNumberSmall>
        </StyledScoutListNumber>
      </StyledScoutListRightContent>
    </StyledScoutListContent>
  );
};

/**
 * ScoutListContent Component
 */
type Props = {
  scout: Scout;
  updateActiveScout: (scout: Scout) => void;
  deleteScout: (id: string, title: string) => void;
};
export const ScoutListItem: React.FC<Props> = (props) => {
  const { scout, updateActiveScout, deleteScout } = props;

  /**
   * State
   */
  const [showMenu, displayMenu] = useState(false);
  const hoverOpenMenuEl = useRef<HTMLUListElement>(null);
  const editMenuIconEl = useRef<HTMLImageElement | null>(null);

  /**
   * CustomHook
   */
  const mousePosition = useMousePosition(showMenu);
  const isShowMenu = useSetDisplayMenu(
    hoverOpenMenuEl.current,
    editMenuIconEl.current,
    mousePosition
  );

  /**
   * Variables
   */
  const generalTags = useMemo(
    () => scout.scoutTags.filter((tagInfo) => tagInfo.tag.category === null),
    [scout.scoutTags]
  );
  const languageTags = useMemo(
    () => scout.scoutTags.filter((tagInfo) => tagInfo.tag.category === TAG_CATEGORY.LANGUAGE),
    [scout.scoutTags]
  );
  const frameworkTags = useMemo(
    () => scout.scoutTags.filter((tagInfo) => tagInfo.tag.category === TAG_CATEGORY.FRAMEWORK),
    [scout.scoutTags]
  );
  const dbTags = useMemo(
    () => scout.scoutTags.filter((tagInfo) => tagInfo.tag.category === TAG_CATEGORY.DATABASE),
    [scout.scoutTags]
  );
  const matchedCount = scout.users.matched.count ?? 0;

  /**
   * useSetDisplayMenuのboolean値でメニューを表示するかどうか
   */
  useEffect(() => {
    displayMenu(isShowMenu);
  }, [isShowMenu]);

  const EditMenuList = (
    <>
      <StyledEditMenuIcon
        iconName="pencil_gray"
        iconColor="gray"
        fileType="png"
        ref={editMenuIconEl}
        onMouseEnter={() => {
          displayMenu(true);
        }}
      />
      <EditMenu
        menuRef={hoverOpenMenuEl}
        isShowMenu={showMenu}
        top={40}
        right={-30}
        list={[
          {
            icon: { iconName: 'recruitment_end', iconColor: 'gray', fileType: 'svg' },
            text: scout.isActive ? '募集終了' : '募集再開',
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              updateActiveScout(scout);
            }
          },
          {
            icon: {
              iconName: 'delete',
              iconColor: 'gray'
            },
            text: '削除',
            onClick: (e) => {
              e.preventDefault();
              e.stopPropagation();
              deleteScout(scout.id, scout.title);
            }
          }
        ]}
      />
    </>
  );

  const ScoutListContent = (
    <div>
      <StyledScoutListTitle>{scout.title}</StyledScoutListTitle>
      <StyledScoutListDesc>
        {generalTags.map((item, index) => (
          <StyledLineTag key={index}>{item.tag.displayName}</StyledLineTag>
        ))}
      </StyledScoutListDesc>
      <StyledScoutListLangBox>
        <StyledStyledScoutListLangIcon iconName="skill_language" iconColor="black" fileType="png" />
        {languageTags.map((item, index) => (
          <StyledBasicTag key={index}>{item.tag.displayName}</StyledBasicTag>
        ))}
        <StyledStyledScoutListLangIcon
          iconName="skill_framework"
          iconColor="black"
          fileType="png"
        />
        {frameworkTags.map((item, index) => (
          <StyledBasicTag key={index}>{item.tag.displayName}</StyledBasicTag>
        ))}
        <StyledStyledScoutListLangIcon iconName="skill_database" iconColor="black" fileType="png" />
        {dbTags.map((item, index) => (
          <StyledBasicTag key={index}>{item.tag.displayName}</StyledBasicTag>
        ))}
      </StyledScoutListLangBox>
    </div>
  );

  const ScoutResult = (
    <StyledScoutListRightContent>
      <StyledScoutListResult>条件結果</StyledScoutListResult>
      <StyledScoutListNumber>
        {matchedCount}
        <StyledScoutListNumberSmall>人</StyledScoutListNumberSmall>
      </StyledScoutListNumber>
    </StyledScoutListRightContent>
  );

  return (
    <StyledScoutListContent to={`/enterprise/scout/${scout.id}`} active={scout.isActive}>
      {scout.isActive === 0 && (
        <StyledInactiveMark>
          <Icon iconName="recruitment_end" iconColor="gray" fileType="svg" width="14px" />
        </StyledInactiveMark>
      )}
      {EditMenuList}
      {ScoutListContent}
      {ScoutResult}
    </StyledScoutListContent>
  );
};

const StyledScoutListContent = styled<any & { active: number }>(Link)`
  cursor: pointer;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 800px;
  width: 100%;
  padding: 30px;
  box-sizing: border-box;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  transition: background 0.2s;
  & + & {
    margin-top: 20px;
  }

  &:hover {
    background-color: rgba(255, 255, 0, 0.1);
  }

  ${({ active }) =>
    active === 0 &&
    `
    &::before {
      content: '';
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background-color: #333333;
      opacity: 0.15;
    }
  `}
`;

const StyledRecruitmentEndWrap = styled.div`
  position: absolute;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SUB};
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  &::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledRecruitmentEndIcon = styled(Icon)`
  position: absolute;
  top: 5px;
  left: 5px;
`;

const StyledEditMenuIcon = styled(Icon)`
  position: absolute;
  top: 10px;
  right: 10px;
  display: block;
  width: 16px;
  height: 16px;
  cursor: pointer;
`;

const StyledScoutListTitle = styled.p`
  font-size: 18px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  line-height: 31px;
  cursor: pointer;
`;

const StyledScoutListDesc = styled.div`
  margin-top: 10px;
`;

const StyledScoutListLangBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 12px;
`;

const StyledStyledScoutListLangIcon = styled(Icon)`
  margin: 4px 4px 0 0;
  &:not(:first-child) {
    margin-left: 4px;
  }
`;

const StyledScoutListRightContent = styled.div`
  border-left: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  padding-left: 25px;
  min-width: 87px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-family: ${FONT_FAMILY.COMMON};
`;

const StyledScoutListResult = styled.span`
  display: block;
  padding: 4px 18px;
  box-sizing: border-box;
  border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  border-radius: 18px;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
`;

const StyledScoutListNumber = styled.span`
  display: block;
  margin-top: 4px;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
`;

const StyledScoutListNumberSmall = styled.span`
  font-size: 12px;
`;

const StyledLineTag = styled(LineTag)`
  margin: 4px 0 0 4px;
  display: inline-block;
`;

const StyledBasicTag = StyledLineTag.withComponent(BasicTag);

const StyledInactiveMark = styled.div`
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border: 22px solid transparent;
  border-left-color: #eeeeee;
  border-top-color: #eeeeee;

  & > * {
    position: absolute;
    top: -15px;
    left: -15px;
  }
`;
