import React from 'react';
import styled from 'styled-components';
import Icon, { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const ICON_SIZE = '18px';

type EditMenuType = {
  menuRef?: React.RefObject<HTMLUListElement> | null;
  isShowMenu?: boolean;
  top?: number;
  right?: number;
  list: Array<{
    icon?: IconPropsType;
    text?: string;
    onClick?: (e?: any) => void;
  }>;
};

const EditMenu: React.FC<EditMenuType> = (props) => {
  const { menuRef, isShowMenu, top, right, list } = props;
  return (
    <StyledEditMenu ref={menuRef} isShowMenu={isShowMenu} top={top} right={right}>
      {list &&
        list.map((val, i) => {
          const { icon, text, onClick } = val;
          if (!!text)
            return (
              <StyledEditMenuItem onClick={onClick} key={i}>
                {!!icon ? <Icon {...icon} /> : null}
                <p>{text}</p>
              </StyledEditMenuItem>
            );
        })}
    </StyledEditMenu>
  );
};

export default EditMenu;

const StyledEditMenu = styled.ul<{ isShowMenu?: boolean; top?: number; right?: number }>`
  && {
    position: absolute;
    min-width: 140px;
    background: ${COLOR_DEFINITIONS.BG.WHITE};
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
    z-index: 9999;
    display: ${({ isShowMenu }) => (!isShowMenu ? `none` : `block`)};
    ${({ top }) => (!!top ? `top:${top}px` : null)};
    ${({ right }) => (!!right ? `right:${right}px` : null)};
  }
`;

const StyledEditMenuItem = styled.li`
  && {
    display: flex;
    align-items: center;
    padding: 10px 5px;
    cursor: pointer;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    font-size: 14px;
    transition: backgroud ease 1s;
    &:not(first-child) {
      border-top-width: 1px;
      border-top-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
      border-top-style: solid;
    }
    &:hover {
      background: ${COLOR_DEFINITIONS.BG.SUB};
    }
    p {
      padding-left: 5px;
    }
  }
`;
