import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { TopBtn } from '@meettry/ui-components/components/organisms/Tops/utils';
import RegisterDecorateImage from '@meettry/ui-components/images/commons/signup-bg.png';

type mode = {
  mode?: 'company' | 'engineer';
};

type TopRegisterProps = mode & {
  children?: ReactNode;
  messages: Array<string>;
  btnMessage: string;
  btnPath?: string;
};

export const openRegisterWindow = () => {
  window.open('/enterprise/contact', 'blank');
};
const TopRegister: React.FC<TopRegisterProps> = (props) => {
  const history = useHistory();
  return (
    <TopRegisterSection>
      <TopRegisterContainer>
        <TopRegisterMessage mode={props.mode}>
          {props.messages.map((msg, i) => (
            <span key={i}>{msg}</span>
          ))}
        </TopRegisterMessage>
        {props.children}
        <RegisterBtn
          color="contained"
          size="midium"
          onClick={() => (!!props.btnPath ? openRegisterWindow() : history.push('/signup'))}
        >
          {props.btnMessage}
        </RegisterBtn>
      </TopRegisterContainer>
    </TopRegisterSection>
  );
};

const TopRegisterSection = styled.div`
  position: relative;
  overflow: hidden;
  background-color: #dbf3f2;
  padding: 100px 0;
  background-image: url(${RegisterDecorateImage});
  background-repeat: no-repeat;
  background-position: top 50px left -243px;
`;

const TopRegisterContainer = styled.div`
  ${mediaQuery.greaterThan('breakPoint')`
  width: 940px;
  margin: 0 auto;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  width: calc(100% - 80px);
  margin: 0 40px;
  `}
  text-align: center;
`;

const TopRegisterMessage = styled.p<mode>`
  ${mediaQuery.greaterThan('breakPoint')`
${FONT_DEFINITIONS.CONTENT};
`}
  ${mediaQuery.between('tablet', 'breakPoint')`
${FONT_DEFINITIONS.MESSAGE_BOLD};
`}
${mediaQuery.lessThan('tablet')`
${FONT_DEFINITIONS.CONTENT};
`}
  span {
    display: ${(props) => (props.mode === 'company' ? 'inline-block' : 'block')};
  }
`;

const RegisterBtn = styled(TopBtn)`
  margin: 30px auto 0;
`;

export default TopRegister;
