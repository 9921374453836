import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { ChallengeType, TagType } from '@meettry/ui-components/types/userPage';
import BaseModal from '@meettry/ui-components/components/organisms/Modal/BaseModal';
import { UPDATE_CHALLENGE } from '@meettry/ui-components/queries/user_page';
import { makeHandleSubmit } from '@meettry/ui-components/components/organisms/UserPage/Challenge/util';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import { Textarea, ProgressInput } from '@meettry/ui-components/components/atoms/Input';
import { FormItem } from '@meettry/ui-components/components/molecules/Form';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type ChallengeHistoryEditType = {
  item: ChallengeType;
  onUpdate: (challenge: ChallengeType) => void;
  onClose: () => void;
};

type ChallengeHistoryEditValueType = {
  id: string;
  title: string;
  tags: Array<TagType>;
  progressRate: number;
  isPinned: boolean;
  isVisible: boolean;
  comment: string;
};

export const ChallengeHistoryEdit: React.FC<ChallengeHistoryEditType> = (props) => {
  const { onUpdate, onClose, item } = props;
  const { showCancelConfirmationDialog, closeDialog } = useDialog();
  const { startLoading, endLoading } = useLoading();
  const [progressRate, changeProgressRate] = useState(Number(item.progressRate));
  const { showErrorPopup, showSuccessPopup } = usePopup();
  const initState = {
    id: item.id,
    title: item.title,
    tags: item.tags,
    progressRate: item.progressRate,
    isPinned: !!item.isPinned,
    isVisible: !!item.isVisible,
    comment: ''
  };
  const [challengeInput, updateChallengeInput] = useState<ChallengeHistoryEditValueType>(initState);
  const [dirty, setDirty] = useState(false);

  const [mutateUpdateChallenge, { data, error, loading }] = useMutation(UPDATE_CHALLENGE, {
    onCompleted: ({ updateChallenge }) => {
      if (!updateChallenge) {
        showErrorPopup('チャレンジを更新できませんでした');
        return;
      }
      const { challenge } = updateChallenge;
      onUpdate(challenge);
      endLoading();
      showSuccessPopup('チャレンジを更新しました');
    }
  });
  useEffect(() => {
    const isError = error;
    if (!isError) return;
    if (error) showErrorPopup('チャレンジを更新できませんでした');
  }, [error]);

  const onChangeProgressRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dirty || setDirty(true);
    changeProgressRate(parseInt(e.target.value));
  };
  const onClickAchieveButton = (add: boolean) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dirty || setDirty(true);
    if (!progressRate && !add) return;
    if (progressRate === 100 && add) return;
    const val = add ? progressRate + 1 : progressRate - 1;
    changeProgressRate(val);
  };

  const onChangeValue = (target: string) => (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    dirty || setDirty(true);
    updateChallengeInput({ ...challengeInput, [target]: e.target.value });
  };

  const onCancelHandle = () => {
    if (dirty) {
      showCancelConfirmationDialog(['保存されていない変更を破棄しますか？'], onClose, closeDialog);
      return;
    }
    onClose();
  };

  const onSubmitHandle = () => {
    const editChallenge = {
      ...challengeInput,
      progressRate,
      comment: challengeInput.comment
    };
    makeHandleSubmit(editChallenge, mutateUpdateChallenge)();
    startLoading('チャレンジを更新しています。');
  };

  const Header = (
    <StyledChallengeHistoryEditHeader>進捗を更新する</StyledChallengeHistoryEditHeader>
  );
  const Content = (
    <StyledForm>
      <FormItem label="達成率">
        <ProgressInput
          onClickMinus={onClickAchieveButton(false)}
          onClickPlus={onClickAchieveButton(true)}
          progressRate={progressRate}
          onChangeProgressRate={onChangeProgressRate}
        />
      </FormItem>
      <FormItem label="コメント">
        <Textarea
          placeholder="コメントを入力してください"
          value={challengeInput.comment}
          onChange={onChangeValue('comment')}
        />
      </FormItem>
    </StyledForm>
  );
  const Footer = (
    <StyledChallengeHistoryEditFooter>
      <MonochromeButton onClick={onCancelHandle}>キャンセル</MonochromeButton>
      <PrimaryButton disabled={loading} onClick={onSubmitHandle}>
        決定
      </PrimaryButton>
    </StyledChallengeHistoryEditFooter>
  );

  return (
    <BaseModal Header={Header} Footer={Footer}>
      {Content}
    </BaseModal>
  );
};

export default ChallengeHistoryEdit;

const StyledForm = styled.form`
  padding: 30px 40px;
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledChallengeHistoryEditHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 40px 0 20px;
  margin: 0 40px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const StyledChallengeHistoryEditFooter = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 0 40px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  margin: 0 40px;
  > * {
    max-width: 120px;
  }
`;
