import gql from 'graphql-tag';

// export const GET_USERS_ID = gql``;

export const GET_USERS_FOLLOWEES = gql`
  query SearchFor($queryString: String!, $targetId: ID!, $first: Int!, $offset: Int!) {
    searchFor(queryString: $queryString) {
      followees(targetId: $targetId) {
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: "created", order: ASC }]
        ) {
          id
          nickname
          bio
          location
          profileImage {
            url
          }
          url
        }
        count
      }
    }
  }
`;

export const GET_USERS_FOLLOWERS = gql`
  query SearchFor($queryString: String!, $targetId: ID!, $first: Int!, $offset: Int!) {
    searchFor(queryString: $queryString) {
      followers(targetId: $targetId) {
        results(
          page: { first: $first, offset: $offset }
          orders: [{ field: "created", order: ASC }]
        ) {
          id
          nickname
          bio
          location
          profileImage {
            url
          }
          url
        }
        count
      }
    }
  }
`;

export const FOLLOW_USER = gql`
  mutation FollowUser($id: ID!) {
    followUser(input: { userId: $id }) {
      ok
    }
  }
`;
export const UNFOLLOW_USER = gql`
  mutation UnfollowUseer($id: ID!) {
    unfollowUser(input: { userId: $id }) {
      ok
    }
  }
`;

export const GET_ENGINEER_ID = gql`
  query GetEngineerID($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      id
    }
  }
`;

export const GET_LOGIN_USER = gql`
  query {
    loginSession {
      user {
        id
        followers {
          id
          nickname
        }
        followees {
          id
          nickname
        }
      }
    }
  }
`;
