import React, { createContext, Dispatch, Reducer, useContext, useReducer } from 'react';
import { ProviderComponent } from '@meettry/ui-components/types/context';
import { NotificationExpansion } from '@meettry/ui-components/types/notification';

/**
 * Notification context
 *  ユーザーに対する通知全般を格納するコンテキスト
 */

/**
 * Action
 */
type Action<T, U> = { type: T; payload: U };

export const NOTIFICATION_ACTION_TYPE = {
  SET_NOTIFICATIONS: 'SET_NOTIFICATIONS',
};
type NotificationAction = SetNotificationsAction;


type SetNotificationsAction = Action<
  typeof NOTIFICATION_ACTION_TYPE.SET_NOTIFICATIONS,
  {
    notifications: NotificationExpansion[]
  }
  >;
export const setNotifications = (notifications: NotificationExpansion[]): SetNotificationsAction => ({
  type: NOTIFICATION_ACTION_TYPE.SET_NOTIFICATIONS,
  payload: { notifications }
});


/**
 * Dispatch
 */
type NotificationDispatch = Dispatch<NotificationAction>;


/**
 * State
 */
type NotificationState = {
  notifications: NotificationExpansion[]
};

const defaultState = (): NotificationState => ({
  notifications: []
});


/**
 * Reducer
 */
type NotificationReducer = Reducer<NotificationState, NotificationAction>
const notificationReducer: NotificationReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case NOTIFICATION_ACTION_TYPE.SET_NOTIFICATIONS: {
      const { notifications } = action.payload as SetNotificationsAction['payload'];
      return { notifications };
    }

    default: {
      return state;
    }
  }
};

/**
 * Context
 */
type NotificationStore = {
  state: NotificationState;
  dispatch: NotificationDispatch;
};
const defaultStore: NotificationStore = {
  state: defaultState(),
  dispatch: () => defaultState(),
};
const NotificationContext = createContext<NotificationStore>(defaultStore);

// Provider
export const NotificationProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(notificationReducer, defaultState());
  return <NotificationContext.Provider value={{state, dispatch}}>{children}</NotificationContext.Provider>;
};

export const useNotificationContext = () => {
  const context = useContext(NotificationContext);
  if (context === undefined)
    throw new Error(`No provider for NotificationContext given`);
  return { ...context };
};
