import React, { useState } from 'react';
import { SearchBar } from '@meettry/company/component/atoms/input';
import { useLocation } from 'react-router-dom';
import {
  FollowListItem,
  EmptyFollow,
  NotFoundFollowers
} from '@meettry/company/component/organisms/userFollowPage/followListItem';
import {
  Pagination,
  DEFAULT_PAGINATION_DISPLAY_ITEM
} from '@meettry/company/component/molecules/pagination';

const userFollowedListItem = {
  firstName: 'Followed',
  lastName: 'name',
  country: 'Japan',
  city: 'Tokyo',
  occupation: 'Service developer',
  prof:
    'ここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章ここに自己紹介の文章が入りますここに自己紹介の文章'
};

const userFollowingListItem = {
  firstName: 'Following',
  lastName: 'name',
  country: 'Japan',
  city: 'Tokyo',
  occupation: 'Service developer',
  prof:
    'ここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章が入りますここに自己紹介の文章ここに自己紹介の文章が入りますここに自己紹介の文章'
};

const FollowList: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  const [current, setCurrent] = useState(1);

  const listItem =
    locationPath === '/follow/followed' ? userFollowedListItem : userFollowingListItem;

  return (
    <div className="follow-list-wrap">
      <div className="follow-list-container">
        <div className="follow-serch-bar">
          <SearchBar
            placeHolder={
              locationPath === '/follow/followed' ? 'フォロワーを検索' : 'フォロー中を検索'
            }
          />
        </div>
        <ul className="follow-list">
          {listItem ? (
            Array.from({ length: 10 }, (_, i) => {
              return (
                <li key={i}>
                  <FollowListItem listItem={listItem} />
                </li>
              );
            })
          ) : (
            <EmptyFollow
              text={locationPath === '/follow/followed' ? 'フォロワー' : 'フォロー中のユーザー'}
            />
          )}
        </ul>
      </div>
      <Pagination
        maxLength={8}
        current={current}
        setCurrent={setCurrent}
        displayNumber={DEFAULT_PAGINATION_DISPLAY_ITEM}
      />
    </div>
  );
};

export default FollowList;
