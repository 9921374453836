import { ACTION, SIGN } from '@meettry/ui-components/constants/error';

export default (e: any, method = '') => {
  console.log(e);
  switch (e.code) {
    case 'auth/cancelled-popup-request':
    case 'auth/popup-closed-by-user':
      return null;
    case 'auth/email-already-in-use':
      switch (method) {
        case 'signup': {
          return SIGN.EMAIL_ALREADY_USE;
        }
        case 'change-email': {
          return SIGN.EMAIL_UNAVAILABLE;
        }
        default: {
          return SIGN.WRONG_EMAIL_OR_PASSWORD;
        }
      }
    case 'auth/invalid-email':
      return SIGN.INVALID_EMAIL;
    case 'auth/user-disabled':
      return SIGN.USER_DISABLED;
    case 'auth/user-not-found':
      return SIGN.WRONG_EMAIL_OR_PASSWORD;
    case 'auth/user-mismatch':
      if (method === 'signin/popup') {
        return SIGN.USER_MISMATCH;
      }
      return SIGN.WRONG_EMAIL_OR_PASSWORD;
    case 'auth/weak-password':
      return SIGN.WEAK_PASSWORD;
    case 'auth/wrong-password':
      return SIGN.WRONG_EMAIL_OR_PASSWORD;
    case 'auth/popup-blocked':
      return SIGN.POPUP_BLOCKED;
    case 'auth/too-many-requests':
      return SIGN.TOO_MANY_REQUESTS;
    case 'auth/operation-not-supported-in-this-environment':
    case 'auth/auth-domain-config-required':
    case 'auth/operation-not-allowed':
    case 'auth/unauthorized-domain':
      return SIGN.UNAVAILABLE_AUTHORIZED;
    case 'auth/requires-recent-login':
      return SIGN.REQUIRES_RECENT_LOGIN;
    case 'auth/invalid-action-code':
      return ACTION.INVALID_CODE;
    default:
      if (method.indexOf('signin') !== -1) {
        return SIGN.FAULT_AUTHORIZED;
      } else if (method.indexOf('auth/only-org-user') !== -1) {
        return 'auth/only-org-user';
      }
      return SIGN.OCCUR_ERROR;
  }
};
