import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { HeaderStateType } from '@meettry/ui-components/types/location';

//MEMO (minami) Login中であればマイページ、Logout中であればLPのパスを設定
export const useTopPath = () => {
  const [path, setPath] = useState('');
  const location = useLocation<HeaderStateType>();
  const {
    authorized,
    isEnterprise,
    user: { nickname }
  } = useLoginUser();

  useEffect(() => {
    // MEMO(oomura): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
    const topPath =
      authorized && isEnterprise
        ? '/enterprise/scout'
        : authorized && !isEnterprise
        ? `/user/${nickname}`
        : location.pathname === '/enterprise' || (location.state && location.state.fromEnterprise)
        ? '/enterprise'
        : '/';
    setPath(topPath);
  }, [authorized, isEnterprise, nickname, location]);

  return path;
};
