import React from 'react';
import styled from 'styled-components';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

const InputFormTemplate = ({ children }) => {
  return <StyledLoginForm>{children}</StyledLoginForm>;
};

export default InputFormTemplate;

const StyledLoginForm = styled.div`
  position: relative;
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  padding: 85px 70px;
  background-color: #ffffff;
  border-radius: 5px;
  text-align: center;
  box-sizing: border-box;
  ${mediaQuery.lessThan('minTablet')`
    padding: 85px 40px;
  `}
`;
