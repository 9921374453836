import gql from 'graphql-tag';

export const FRAG_USER_DETAIL_ITEM = gql`
  fragment UserDetailItem on UserDetail {
    firstName
    lastName
    firstNameKana
    lastNameKana
    phone
    sex
    birthDate
    postCode
    address
    enableAcceptingScout
    jobSeekingLevel
    enableScoutChatNotifMail
  }
`;

export const FRAG_USER_ITEM = gql`
  fragment UserItem on User {
    nickname
    bio
    location
    url
    id
    lastUpdated
    workStyles {
      id
      name
    }
  }
`;

// ユーザー情報取得クエリ
export const GET_PROFILE_EDIT_PAGE_DATA = gql`
  query GetProfileEditPageData {
    loginSession {
      user {
        ...UserItem
      }
      userDetail {
        ...UserDetailItem
      }
    }
    workStyles {
      id
      name
      order
    }
  }
  ${FRAG_USER_ITEM}
  ${FRAG_USER_DETAIL_ITEM}
`;

export const FRAG_SET_USER_ITEM = gql`
  fragment ProfSetUserItem on User {
    bio
    url
    location
    workStyles {
      id
      name
    }
  }
`;

// ユーザー情報登録・更新クエリ
export const SET_USER_PROFILE = gql`
  mutation SetUserProfile($input: SetUserProfileInput!) {
    setUserProfile(input: $input) {
      ok
      user {
        ...ProfSetUserItem
      }
      userDetail {
        ...UserDetailItem
      }
    }
  }
  ${FRAG_SET_USER_ITEM}
  ${FRAG_USER_DETAIL_ITEM}
`;

export const GQL_MAIL_CHANGE = gql`
  mutation($email: String!) {
    mailChange(input: { email: $email }) {
      ok
    }
  }
`;

export const GQL_MAIL_CHECK_CODE = gql`
  mutation($code: String!) {
    codeCheckMainChange(input: { code: $code }) {
      ok
      email
    }
  }
`;

// 働き方取得クエリ
export const GET_WORK_STYLES = gql`
  query GetWorkStyles {
    workStyles {
      id
      name
      order
    }
  }
`;
