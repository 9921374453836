import React, { useState, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';
import styled from 'styled-components';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import Loader from '@meettry/engineer/components/atoms/Loader';
import { FadeAnimation } from '@meettry/engineer/utils/Animation';

export const LoadingMask = (props) => {
  const { text } = props;
  return (
    <StyledLoadingWrapper>
      <div>
        <Loader />
        {text && <p>{text}</p>}
      </div>
    </StyledLoadingWrapper>
  );
};

export const Loading = () => {
  const {
    state: { loading }
  } = useLoading();

  useEffect(() => {
    if (loading.show) {
      document.body.classList.add('loading-open');
      return;
    }
    document.body.classList.remove('loading-open');
  }, [loading]);

  return (
    <CSSTransition in={loading.show} timeout={100} classNames={FadeAnimation.classNames}>
      <FadeAnimation.animation>
        {loading.show ? <LoadingMask text={loading.text} /> : null}
      </FadeAnimation.animation>
    </CSSTransition>
  );
};

export default Loading;

const StyledLoadingWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
