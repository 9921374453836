import React, { useState, useReducer } from 'react';
import styled from 'styled-components';
import * as Validation from '@meettry/company/utils/validation';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import {
  CompanyName,
  PostalCode,
  StreetAddress,
  DepartmentName,
  RepresentativeName,
  PhoneNumber
} from '@meettry/company/component/atoms/input';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import {
  StyledButtonContainer,
  StyledFormItem
} from '@meettry/engineer/components/pages/EnterpriseSettingCompany';

const billCorporateInfo = [
  { type: '会社名', text: '株式会社レイハウオリ' },
  {
    type: '住所',
    text: '東京都渋谷区渋谷3-2-3 帝都青山ビル6F'
  },
  {
    type: '部署',
    text: '経理部'
  },
  {
    type: '担当者',
    text: '田中太郎'
  },
  {
    type: '電話番号',
    text: '0357783639'
  }
];

type BillDestinationTableType = {
  setCurrent: React.Dispatch<React.SetStateAction<boolean>>;
};

export type InputBillProps = {
  companyName: string;
  postalCode: Array<string>;
  streetAddress: string;
  phoneNumber: Array<string>;
  departmentName: string;
  representativeName: string;
};

const initialState: InputBillProps = {
  companyName: '',
  postalCode: ['', ''],
  streetAddress: '',
  phoneNumber: ['', '', ''],
  departmentName: '',
  representativeName: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;
const CHANGE_TEXT_NUMBER = 'CHANGE_TEXT_NUMBER' as const;

type InputAction = {
  type: typeof CHANGE_TEXT | typeof CHANGE_TEXT_NUMBER;
  text: string;
  item: keyof InputBillProps;
  stateArray: 'postalCode' | 'phoneNumber';
  num: number;
};

const changeInput: React.Reducer<InputBillProps, InputAction> = (
  state: InputBillProps,
  action: InputAction
) => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return { ...state, [action.item]: action.text };
    case 'CHANGE_TEXT_NUMBER':
      const itemCopy = state[action.stateArray];
      itemCopy[action.num] = action.text;
      return { ...state, [action.item]: itemCopy };
    default:
      return state;
  }
};

export const BillDestinationTable: React.FC<BillDestinationTableType> = ({ setCurrent }) => {
  return (
    <div>
      <StyledBillDestinationTableContainer>
        <StyledBillDestinationTable>
          <tbody>
            {billCorporateInfo.map((item, index) => (
              <StyledBillDestinationTableRow key={index}>
                <StyledBillDestinationTableHeading>{item.type}</StyledBillDestinationTableHeading>
                <StyledBillDestinationTableData>{item.text}</StyledBillDestinationTableData>
              </StyledBillDestinationTableRow>
            ))}
          </tbody>
        </StyledBillDestinationTable>
      </StyledBillDestinationTableContainer>
      <StyledButtonContainer justifyContent="flex-end">
        <PrimaryButton
          width="120"
          onClick={() => {
            setCurrent(true);
          }}
        >
          変更する
        </PrimaryButton>
      </StyledButtonContainer>
    </div>
  );
};

export const BillDestinationInput: React.FC<BillDestinationTableType> = ({ setCurrent }) => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [companyNameError, setCompanyNameError] = useState('none');
  const [postalCodeError, setPostalCodeError] = useState('none');
  const [streetAddressError, setStreetAddressError] = useState('none');
  const [departmentNameError, setDepartmentNameError] = useState('none');
  const [representativeNameError, setRepresentativeNameError] = useState('none');
  const [phoneNumberError, setPhoneNumberError] = useState('none');

  return (
    <React.Fragment>
      <StyledBillDestinationTableContainer>
        <StyledFormItem marginTop="0">
          <CompanyName
            companyNameText="会社名"
            state={state.companyName}
            stateKey="companyName"
            dispatch={dispatch}
            error={companyNameError}
          />
        </StyledFormItem>
        <StyledFormItem>
          <PostalCode
            state={state.postalCode}
            stateKey="postalCode"
            dispatch={dispatch}
            error={postalCodeError}
          />
        </StyledFormItem>
        <StyledFormItem>
          <StreetAddress
            state={state.streetAddress}
            stateKey="streetAddress"
            dispatch={dispatch}
            error={streetAddressError}
          />
        </StyledFormItem>
        <StyledFormItem>
          <DepartmentName
            state={state.departmentName}
            stateKey="departmentName"
            dispatch={dispatch}
            error={departmentNameError}
          />
        </StyledFormItem>
        <StyledFormItem>
          <RepresentativeName
            state={state.representativeName}
            stateKey="representativeName"
            dispatch={dispatch}
            error={representativeNameError}
          />
        </StyledFormItem>
        <StyledFormItem>
          <PhoneNumber
            require={false}
            state={state.phoneNumber}
            stateKey="phoneNumber"
            dispatch={dispatch}
            error={phoneNumberError}
          />
        </StyledFormItem>
      </StyledBillDestinationTableContainer>
      <StyledButtonContainer>
        <MonochromeButton
          width="120"
          onClick={() => {
            setCurrent(false);
          }}
        >
          キャンセル
        </MonochromeButton>
        <PrimaryButton
          width="120"
          onClick={() =>
            Validation.bill(
              state,
              setCompanyNameError,
              setPostalCodeError,
              setStreetAddressError,
              setDepartmentNameError,
              setRepresentativeNameError,
              setPhoneNumberError
            )
          }
        >
          変更する
        </PrimaryButton>
      </StyledButtonContainer>
    </React.Fragment>
  );
};

export const BillDestination: React.FC = () => {
  const [current, setCurrent] = useState(false);
  return (
    <div>
      {current ? (
        <BillDestinationInput setCurrent={setCurrent}></BillDestinationInput>
      ) : (
        <BillDestinationTable setCurrent={setCurrent}></BillDestinationTable>
      )}
    </div>
  );
};

/* --------------------------------
  BillDestinationTableのスタイル
---------------------------------*/
const StyledBillDestinationTableContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledBillDestinationTable = styled.table`
  width: 100%;
`;

const StyledBillDestinationTableRow = styled.tr`
  height: 70px;
  line-height: 70px;
`;

const StyledBillDestinationTableData = styled.td`
  padding-left: 30px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledBillDestinationTableHeading = styled(StyledBillDestinationTableData)`
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  width: 261px;
  box-sizing: border-box;
`;
