import styled from 'styled-components';

const DEFAULT_TIMEOUT = 500;

const List = styled.div`
  // enter from
  &.list-enter {
    opacity: 0;
    left: 20px;
    margin-bottom: 0;
    max-height: 0;
  }
  // enter to
  &.list-enter-active {
    opacity: 1;
    left: 0px;
    max-height: 500px;
    margin-bottom: 20px;
    transition: opacity 0.2s ease-out 0.1s, left 0.2s ease-out 0.1s, max-height 1s ease,
      margin-bottom 0.5s ease;
  }
  // exit from
  &.list-exit {
    opacity: 1;
    left: 0px;
    max-height: 500px;
    margin-bottom: 20px;
  }
  // exit to
  &.list-exit-active {
    opacity: 0;
    left: 20px;
    max-height: 0;
    margin-bottom: 0;
    transition: opacity 0.15s ease-in 0.05s, left 0.2s ease-in, max-height 0.2s ease 0.1s,
      margin-bottom 0.2s ease 0.1s;
  }
`;
export const ListAnimation = {
  animation: List,
  classNames: 'list',
  timeout: DEFAULT_TIMEOUT
};

const Fade = styled.div`
  // enter from
  &.fade-enter {
    opacity: 0;
  }
  // enter to
  &.fade-enter-active {
    opacity: 1;
    transition: opacity 0.15s ease;
  }
  // exit from
  &.fade-exit {
    opacity: 1;
  }
  // exit to
  &.fade-exit-active {
    opacity: 0;
    transition: opacity 0.15s ease;
  }
`;
export const FadeAnimation = {
  animation: Fade,
  classNames: 'fade',
  timeout: DEFAULT_TIMEOUT
};

//MEMO(aida) 右から入ってフェードアウトするアニメーション
const slideInRightFadeClassnames = 'slide-in-right-fade';
const SlideInRightFade = styled.div`
  // enter from
  &.${slideInRightFadeClassnames}-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  // enter to
  &.${slideInRightFadeClassnames}-enter-active {
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0);
  }
  // exit from
  &.${slideInRightFadeClassnames}-exit {
    opacity: 1;
    transform: translateX(0);
  }
  // exit to
  &.${slideInRightFadeClassnames}-exit-active {
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(5%);
  }
`;
export const SlideInRightFadeAnimation = {
  animation: SlideInRightFade,
  classNames: slideInRightFadeClassnames,
  timeout: DEFAULT_TIMEOUT
};

//MEMO(aida) 上から入ってフェードアウトするアニメーション
const slideInTopFadeClassnames = 'slide-int-top-fade';
const SlideInTopFade = styled.div`
  // enter from
  &.${slideInTopFadeClassnames}-enter {
    opacity: 0;
    transform: translateX(100%);
  }
  // enter to
  &.${slideInTopFadeClassnames}-enter-active {
    transition: all 0.3s ease;
    opacity: 1;
    transform: translateX(0);
  }
  // exit from
  &.${slideInTopFadeClassnames}-exit {
    opacity: 1;
    transform: translateX(0);
  }
  // exit to
  &.${slideInTopFadeClassnames}-exit-active {
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(5%);
  }
`;
export const SlideInTopFadeAnimation = {
  animation: SlideInTopFade,
  classNames: slideInTopFadeClassnames,
  timeout: DEFAULT_TIMEOUT
};
