import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
export { ActionMenuChatItem } from './ChatActionMenuItem';

type Menu = {
  component: React.ReactNode | string;
  link: string;
};

type Props = {
  isOpen: boolean;
  menus: Menu[],
  title?: string;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  width?: string;
  onClose: () => void;
};

/**
 * ActionMenu Component
 */
const ActionMenu: React.FC<Props> = (props) => {
  const { menus = [], title = '', header = null, footer = null, isOpen, width = 'auto', onClose } = props;

  useEffect(() => {
      window.addEventListener('click', onClose);
  }, []);

  return (
    <StyledActionMenu>
      <StyledActionMenuBody isOpen={isOpen} width={width}>
        <StyledActionMenuHeader>
          <StyledActionMenuTitle>{ title }</StyledActionMenuTitle>
          { header }
        </StyledActionMenuHeader>
        <StyledActionMenuList>
          {
            menus.length != 0 && menus.map((menu, index) => (
              <li key={`${index}`}>
                <Link to={menu.link} >
                  { menu.component }
                </Link>
              </li>
            ))
          }
          {
            menus.length === 0 && (
              <StyledNoItem>通知がありません</StyledNoItem>
            )
          }
        </StyledActionMenuList>
        <StyledActionMenuFooter>
          { footer }
        </StyledActionMenuFooter>
      </StyledActionMenuBody>
    </StyledActionMenu>
  )
};
export default ActionMenu;

const StyledActionMenu = styled.div`
  position: relative;
`;

const StyledActionMenuBody = styled.div<{ isOpen: boolean, width: string }>`
  position: absolute;
  top: 10px;
  right: calc(50% - 10px);
  display: none;
  max-width: 400px;
  background-color: ${ COLOR_DEFINITIONS.BG.WHITE };
  border: 1px solid ${ COLOR_DEFINITIONS.LINE.SECONDARY };
  box-shadow: 0 0 5px ${ COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW };
  
  ${({ width }) => (
    width && `width: ${width}`
  )};
  
  ${({ isOpen }) => (
    isOpen && 'display: block;'
  )};
`;

const StyledActionMenuHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  white-space: nowrap;
  line-height: 1;
	border-bottom: 1px solid ${ COLOR_DEFINITIONS.LINE.SECONDARY };
`;

const StyledActionMenuTitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${ COLOR_DEFINITIONS.TEXT.MAIN };
`;

const StyledActionMenuList = styled.ol`
  & > li + li > * {
		border-top: 1px solid ${ COLOR_DEFINITIONS.LINE.SECONDARY };
  }
  & > li > * {
    display: block;
    padding: 15px 20px;
    transition: background 0.2s;
    
    &:hover {
      background-color: rgba(255,255, 0, 0.1);
    }
  }
`;

const StyledActionMenuFooter = styled.div`
  padding: 12px 20px;
	border-top: 1px solid ${ COLOR_DEFINITIONS.LINE.SECONDARY };
`;

const StyledNoItem = styled.div`
	color: ${ COLOR_DEFINITIONS.TEXT.SECONDARY };
  padding: 15px 20px;
`;
