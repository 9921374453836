import React from 'react';
import styled, { css } from 'styled-components';
import { TagType } from '@meettry/ui-components/types/userPage';

type SearchSelectedTagListProps = {
  selectedTags: Array<TagType>;
  onRemoveTag: (item: TagType) => (e: React.MouseEvent<HTMLSpanElement>) => void;
  className?: string;
};

const SearchSelectedTagList = (props: SearchSelectedTagListProps) => {
  const { selectedTags = [], onRemoveTag, className } = props;
  return (
    <StyledSearchSelectedTagsWrapper className={className}>
      <StyledSearchSelectedTags>
        {selectedTags.map((item, i) => (
          <StyledTagsWrapper key={`tag_${item}-${i}`}>
            <StyledTags nonCategorized={!item.category}>
              <span>{item.displayName}</span>
              <StyledRemoveButton onClick={onRemoveTag(item)} />
            </StyledTags>
          </StyledTagsWrapper>
        ))}
      </StyledSearchSelectedTags>
    </StyledSearchSelectedTagsWrapper>
  );
};

export default SearchSelectedTagList;

const StyledSearchSelectedTagsWrapper = styled.div`
  && {
    width: 100%;
    min-width: 440px;
    border: 1px solid #eeeeee;
    box-sizing: border-box;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
    padding: 20px;
    padding-top: 10px;
    padding-left: 15px;
    background-color: #ffffff;
  }
`;

const StyledSearchSelectedTags = styled.div`
  && {
    width: 100%;
  }
`;

const StyledTagsWrapper = styled.div`
  && {
    display: inline-block;
    margin-top: 10px;
    margin-left: 5px;
  }
`;
const StyledTags = styled.p<{ nonCategorized: boolean }>`
  && {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    padding: 5px 10px;
    background-color: #e8eef9;
    color: #2459bf;
    border-radius: 3px;
    ${({ nonCategorized }) =>
      nonCategorized
        ? css`
            background-color: #ffffff;
            border: 1px solid #2459bf;
            border-radius: 20px;
          `
        : null}
  }
`;

const StyledRemoveButton = styled.span`
  && {
    margin-left: 14px;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #2459bf;
    position: relative;
    cursor: pointer;
    ::before {
      transform: translate(-50%, -50%) rotate(45deg);
    }
    ::after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
    ::before,
    ::after {
      content: '';
      position: absolute;
      width: 7px;
      height: 1px;
      background-color: #ffffff;
      top: 50%;
      left: 50%;
    }
  }
`;
