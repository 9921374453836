import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import { MuiThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider as StyledThemeProvider } from 'styled-components';
import smoothscroll from 'smoothscroll-polyfill';
import MainTheme from '@meettry/engineer/materialUI/thema/MainThema';

import App from '@meettry/engineer/App';

smoothscroll.polyfill();

ReactDOM.render(
  <CookiesProvider>
    <MuiThemeProvider theme={MainTheme}>
      <StyledThemeProvider theme={MainTheme}>
        <App />
      </StyledThemeProvider>
    </MuiThemeProvider>
  </CookiesProvider>,
  document.getElementById('root')
);
