import React, { useState } from 'react';
import styled from 'styled-components';
import uuid from 'uuid';
import Downshift from 'downshift';
import { TextField } from '@meettry/ui-components/components/atoms/Input';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import { TagType, CategoryType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

const MAX_TEXT_LENGTH = 20;

type AdditionalTagInputType = {
  tagList?: Array<TagType>;
  placeholder: string;
  tagType?: string;
  value: Array<TagType>;
  onChange: (value: Array<TagType>) => void;
  currentCategory?: CategoryType;
};

const AdditionalTagInput: React.FC<AdditionalTagInputType> = ({
  tagList = [],
  placeholder,
  tagType,
  value = [],
  onChange,
  currentCategory = null
}) => {
  const [text, setText] = useState('');

  //MEMO(aida) Downshift用に配列を整形s
  const suggestedItems = tagList.map((val) => ({
    value: val.displayName
  }));

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      setText((e.target as HTMLInputElement).value);
      addTag();
    }
  };

  const removeTag = (item: TagType) => {
    if (item.category === currentCategory) {
      const tags = item.id
        ? value.filter((i) => i.id !== item.id)
        : item.name
        ? value.filter((i) => i.name !== item.name)
        : item.displayName
        ? value.filter((i) => i.displayName !== item.displayName)
        : null;
      tags !== null && onChange(tags);
    }
  };

  const addTag = () => {
    if (text === '') return; // 空文字リターン
    if (value.filter((i) => i.displayName == text && i.category === currentCategory).length > 0)
      return; // 重複リターン

    const existsTag = tagList.find(
      (tag) =>
        tag.displayName.toLowerCase() === text.toLocaleLowerCase() &&
        tag.category === currentCategory
    );
    const tag = existsTag || {
      id: null,
      name: uuid.v4(),
      displayName: text.trim(),
      category: currentCategory
    };
    const tags = [...value, tag];
    onChange(tags);
    setText('');
  };

  return (
    <>
      <StyledInputArea>
        <Downshift
          onChange={(selection) => {
            setText(selection.value);
          }}
          itemToString={(item) => (item && item.displayName ? item : '')}
        >
          {({
            getRootProps,
            getInputProps,
            getItemProps,
            getMenuProps,
            inputValue,
            highlightedIndex,
            isOpen
          }) => (
            <StyledSuggestInputWrapper {...getRootProps()}>
              <div>
                <TextField
                  {...getInputProps({
                    type: 'text',
                    value: text,
                    placeholder: placeholder,
                    onChange: (e: React.ChangeEvent<HTMLInputElement>) => {
                      if (e.target.value.length >= MAX_TEXT_LENGTH) return;
                      setText(e.target.value);
                    },
                    onKeyPress: (e: React.KeyboardEvent<HTMLInputElement>) => {
                      //MEMO(aida) suggestのアイテムが選択されている場合は何もしない
                      if (typeof highlightedIndex === 'number') return;
                      onKeyDown(e);
                    }
                  })}
                />
              </div>
              <StyledSuggestItemList {...getMenuProps()}>
                {isOpen
                  ? suggestedItems
                      .filter((item) => !inputValue || item.value.includes(inputValue))
                      .map((item, index) => (
                        <StyledSuggestItem
                          isActive={highlightedIndex === index}
                          {...getItemProps({
                            key: `${item.value}_${index}`,
                            index,
                            item
                          })}
                        >
                          {item.value}
                        </StyledSuggestItem>
                      ))
                  : null}
              </StyledSuggestItemList>
            </StyledSuggestInputWrapper>
          )}
        </Downshift>
        <LineButton size={STYLE_TYPE.SIZE.MEDIUM} width="80" onClick={addTag}>
          追加
        </LineButton>
      </StyledInputArea>
      {value && value.length > 0 && (
        <StyledTagList>
          {value
            .filter((v) => v.category === currentCategory)
            .map((tag, i) => (
              <li key={`${i}_${tag.id}_${tag.name}_${tag.displayName}`}>
                <StyledTag type={tagType}>
                  <span>{tag.displayName}</span>
                  <span onClick={() => removeTag(tag)} />
                </StyledTag>
              </li>
            ))}
        </StyledTagList>
      )}
    </>
  );
};

export default AdditionalTagInput;

const StyledInputArea = styled.div`
  display: flex;
  max-width: 390px;
  & > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  margin: -2.5px;
  margin-top: 12.5px; /* 15px */

  & > li {
    padding: 2.5px;
  }
`;

const StyledTag = styled.span<{ type?: string }>`
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
  padding: 3px 3px 3px 7px;
  border: 1px solid ${COLOR_DEFINITIONS.MAIN.SECONDARY};
  border-radius: 50px;

  & > *:nth-child(2) {
    cursor: pointer;
    position: relative;
    width: 14px;
    height: 14px;
    background-color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
    border-radius: 100%;
    margin-left: 5px;
  }

  & > *:nth-child(2)::before,
  & > *:nth-child(2)::after {
    content: '';
    position: absolute;
    width: 7px;
    height: 1px;
    background-color: ${COLOR_DEFINITIONS.BG.MAIN};
    top: 50%;
    left: 50%;
  }

  & > *:nth-child(2)::before {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  & > *:nth-child(2)::after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &[type='skill'] {
    padding: 5px 10px;
    background-color: ${COLOR_DEFINITIONS.BG.SKILL};
    border: none;
    border-radius: 5px;
  }
`;

const StyledSuggestInputWrapper = styled.div`
  position: relative;
  width: 100%;
`;

const StyledSuggestItemList = styled.ul`
  position: absolute;
  width: 100%;
  z-index: 9999;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  box-shadow: 0 0 10px ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SHADOW};
  max-height: 200px;
  overflow-y: scroll;
`;

const StyledSuggestItem = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  min-height: 26px;
  font-size: 12px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  transition: background-color ease 0.5s;
  cursor: pointer;
  :hover {
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
  }
  ${({ isActive }) => (isActive ? `background-color: ${COLOR_DEFINITIONS.BG.SUB}` : null)};
`;
