import React from 'react';
import styled from 'styled-components';

export const DialogBase = (props) => {
  const { header, children, footer, className } = props;

  return (
    <StyledDialogBaseWrapper className={className}>
      {header && <StyledDialogHeaderWrapeer>{header}</StyledDialogHeaderWrapeer>}
      <StyledDialogContentWrapper>{children}</StyledDialogContentWrapper>
      {footer && <StyledDialogFooterWrapeer>{footer}</StyledDialogFooterWrapeer>}
    </StyledDialogBaseWrapper>
  );
};

export default DialogBase;

const StyledDialogBaseWrapper = styled.div`
  // MEMO(aida)　仮置き高さと幅はこれを拡張するコンポーネントが指定する想定
  width: 100%;
  max-width: 600px;
  min-height: 214px;
  background-color: #ffffff;
  border-radius: 5px;
`;

const StyledDialogHeaderWrapeer = styled.div``;

const StyledDialogContentWrapper = styled.div``;

const StyledDialogFooterWrapeer = styled.div``;
