import React, { ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';

type ButtonProps = {
  buttonText?: string;
  cls?: string;
  onClick?: Function;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

export const Button: React.FC<ButtonProps> = (props) => {
  const { buttonText, cls, onClick, disabled, type } = props;
  return (
    <button
      className={cls}
      type={!!type ? type : 'button'}
      onClick={() => !!onClick && onClick()}
      disabled={disabled}
    >
      {buttonText}
    </button>
  );
};

type SortButtonProps = {
  options?: Array<{ key: any; name: any }>;
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
  onChange: (key: string) => void;
  value: any;
  className?: string;
};

export const SortButton: React.FC<SortButtonProps> = (props) => {
  const { options, margin, onChange, value, className } = props;

  const onChangeSelect = (e: ChangeEvent<HTMLSelectElement>) => {
    onChange(e.target.value);
  };

  return (
    <StyledSortButton margin={margin} className={className}>
      <StyledSelectWrap>
        <StyledSelect onChange={onChangeSelect} value={value}>
          {options?.map((item, i) => (
            <option key={item.key} value={item.key}>
              {item.name}
            </option>
          ))}
        </StyledSelect>
      </StyledSelectWrap>
    </StyledSortButton>
  );
};

type ConnectionButtonProps = {
  buttonText?: string;
  folowCount?: number;
  cls?: string;
  onClick?: Function;
  disabled?: boolean;
  type?: 'button' | 'submit';
};

export const ConnectionButton: React.FC<ConnectionButtonProps> = (props) => {
  const { buttonText, cls, onClick, disabled, type, folowCount } = props;
  return (
    <button
      className={cls}
      type={!!type ? type : 'button'}
      onClick={() => !!onClick && onClick()}
      disabled={disabled}
    >
      {buttonText}
      <span>{folowCount}</span>
    </button>
  );
};

const StyledSortButton = styled.div<{ margin: SortButtonProps['margin'] }>`
  display: flex;
  justify-content: center;
  ${(props) =>
    props.margin
      ? `margin:${props.margin
          .map((margin) => (margin === 'auto' || margin === 0 ? margin : margin + 'px'))
          .join(' ')};`
      : null}
`;

const StyledSelectWrap = styled.div`
  position: relative;
  height: 30px;
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 6px;
    border-bottom: 1px solid ${COLOR_DEFINITIONS.TEXT.SECONDARY};
    border-right: 1px solid ${COLOR_DEFINITIONS.TEXT.SECONDARY};
    position: absolute;
    top: 50%;
    right: 8px;
    transform: translateY(-50%) translateX(-50%) rotate(45deg);
    transform-origin: right;
  }
`;

const StyledSelect = styled.select`
  cursor: pointer;
  width: 100%;
  height: 30px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 5px;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 14px;
  font-family: ${FONT_FAMILY.COMMON};
  outline: none;
  padding: 0 22px 0 8px;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  &:hover {
    background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;
