import React from 'react';
import styled from 'styled-components';

import Login from '@meettry/engineer/components/organisms/Logins/Login';
import ApplicationPage from '@meettry/engineer/components/templates/ApplicationPage';
import { PageCommonProps } from '@meettry/ui-components/types/route';

const LoginPage:React.FC<PageCommonProps> = () => (
  <ApplicationPage pageType="slim">
    <StyledLoginPage>
      <StyledPageTitle>ログイン</StyledPageTitle>
      <Login />
    </StyledLoginPage>
  </ApplicationPage>
);
export default LoginPage;

const StyledLoginPage = styled.div`
  & > *:not(:first-child) {
    margin-top: 40px;
  }
`;

const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #0fafa9;
  text-align: center;
`;
