import React, { useEffect } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { useHistory } from 'react-router-dom';
import { useTopPath } from '@meettry/ui-components/hooks/useTopPath';
import notFound404 from '@meettry/ui-components/images/notFound/404.png';

const NotFoundPage: React.FC = () => {
  const history = useHistory();
  const topPath = useTopPath();
  const onClickTop = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    history.push(topPath);
  };

  // MEMO (minami) 表示後10秒後にリダイレクト
  useEffect(() => {
    if (topPath === '') return;
    const timer = setTimeout(() => history.push(topPath), 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [topPath]);

  return (
    <StyledNotFoundWrapper>
      <StyledNotFoundInner>
        <StyledNotFoundContainer>
          <StyledNotFoundImage src={notFound404}>
            <img src={notFound404} alt="page not found" />
          </StyledNotFoundImage>
          <StyledNotFoundHeading>お探しのページは見つかりませんでした</StyledNotFoundHeading>
          <StyledNotFoundTextWrapper>
            <StyledNotFoundText>一時的にアクセスできない状況にあるか、</StyledNotFoundText>
            <StyledNotFoundText>移動もしくは削除された可能性があります。</StyledNotFoundText>
          </StyledNotFoundTextWrapper>
          <StyledButton onClick={onClickTop}>トップページへ</StyledButton>
        </StyledNotFoundContainer>
      </StyledNotFoundInner>
    </StyledNotFoundWrapper>
  );
};

export default NotFoundPage;

const StyledNotFoundWrapper = styled.div`
  && {
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  }
`;

const StyledNotFoundInner = styled.div`
  && {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    min-height: calc(100vh - 96px);
    margin: 0 auto;
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  }
`;

const StyledNotFoundContainer = styled.div`
  && {
    width: 100%;
    max-width: 360px;
  }
`;

const StyledNotFoundImage = styled.div<{ src: string }>`
  && {
    width: 100%;
    max-width: 165px;
    min-height: 144px;
    margin: 0 auto;
    ${({ src }) => (src ? `background-image: url(${src});` : null)}
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    img {
      display: none;
    }
  }
`;

const StyledNotFoundHeading = styled.h1`
  && {
    text-align: center;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }
`;

const StyledNotFoundTextWrapper = styled.div`
  && {
    margin-top: 20px;
  }
`;

const StyledNotFoundText = styled.p`
  && {
    text-align: center;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    font-size: 16px;
    :not(:first-child) {
      margin-top: 15px;
    }
  }
`;

const StyledButton = styled.button`
  && {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    appearance: none;
    outline: none;
    border: none;
    background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    color: ${COLOR_DEFINITIONS.TEXT.WHITE};
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    width: 100%;
    max-width: 360px;
    min-height: 60px;
    transition: opacity ease 0.1s;
    text-align: center;
    :hover {
      opacity: 0.8;
    }
  }
`;
