import React from 'react';
import UserProfileTop from '@meettry/company/component/organisms/userPage/UserProfile/userProfileTop';
import UserProfileBottom from '@meettry/company/component/organisms/userPage/UserProfile/userProfileBottom';

const UserProfile = () => {
  return (
    <div className="user-profile">
      <UserProfileTop />
      <UserProfileBottom />
    </div>
  );
};

export default UserProfile;
