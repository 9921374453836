import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  BOOKMARK_USER_BY_ENTERPRISE_QUERY,
  FETCH_BOOKMARK_USERS_QUERY,
  UNBOOKMARK_USER_BY_ENTERPRISE_QUERY
} from '@meettry/ui-components/queries';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { useEffect, useMemo, useState } from 'react';

type BookmarkTargetUser = { nickname: string; id: string; isBookmark: boolean };

/**
 * ユーザーのブックマーク関係のフック
 */
const useBookmark = () => {
  /**
   * State
   */
  // ブックマーク登録した時の一時保管
  const [bookmarkUser, setBookmarkUser] = useState<BookmarkTargetUser | null>(null);
  // アンブックマーク登録した時の一時保管
  const [unBookmarkUser, setUnBookmarkUser] = useState<BookmarkTargetUser | null>(null);
  // ブックマーク後の最新の状態
  const [newBookmarkUserIds, setNewBookmarkUserIds] = useState<string[]>([]);
  // アンブックマーク後の最新の状態
  const [newUnBookmarkUserIds, setNewUnBookmarkUserIds] = useState<string[]>([]);

  /**
   * Custom Hook
   */
  const { showSuccessPopup, showErrorPopup } = usePopup();

  /**
   * Query
   */
  const [
    fetchBookmarkUsers,
    { data: bookmarkUsersData, loading: bookmarkUsersLoading, error: bookmarkUsersError }
  ] = useLazyQuery(FETCH_BOOKMARK_USERS_QUERY);
  const [
    mutateBookmark,
    { data: mutateBookmarkData, loading: mutateBookmarkLoading, error: mutateBookmarkError }
  ] = useMutation(BOOKMARK_USER_BY_ENTERPRISE_QUERY);
  const [
    mutateUnBookmark,
    { data: mutateUnBookmarkData, loading: mutateUnBookmarkLoading, error: mutateUnBookmarkError }
  ] = useMutation(UNBOOKMARK_USER_BY_ENTERPRISE_QUERY);

  /**
   * Variables
   */
  const bookmarkLoading = mutateBookmarkLoading || mutateUnBookmarkLoading;
  const { bookmarkUsers, bookmarkUserCount } = useMemo(() => {
    const users = bookmarkUsersData?.loginSession?.user?.organization?.bookmarks?.users;
    return {
      bookmarkUsers: users?.results ?? [],
      bookmarkUserCount: users?.count ?? 0
    };
  }, [bookmarkUsersData]);

  /**
   * Func: toggleBookmark
   */
  const toggleBookmark = (user: BookmarkTargetUser) => {
    if (user.isBookmark) {
      mutateUnBookmark({ variables: { userId: user.id } });
      setUnBookmarkUser(user);
    } else {
      mutateBookmark({ variables: { userId: user.id } });
      setBookmarkUser(user);
    }
  };

  /**
   * After Bookmark
   */
  useEffect(() => {
    if (!bookmarkUser) return;
    if (!mutateBookmarkLoading) {
      if (mutateBookmarkData?.bookmarkUser?.ok) {
        showSuccessPopup(`${bookmarkUser.nickname}をお気に入りに登録しました`);
        setNewUnBookmarkUserIds((prevBookmark) =>
          prevBookmark.filter((id) => id !== bookmarkUser.id)
        );
        setNewBookmarkUserIds((prevBookmark) => [...prevBookmark, bookmarkUser.id]);
        setBookmarkUser(null);
      } else {
        showErrorPopup('お気に入りに登録できませんでした');
      }
    }
  }, [
    mutateBookmarkData,
    mutateBookmarkLoading,
    mutateBookmarkError,
    setNewBookmarkUserIds,
    setNewUnBookmarkUserIds,
    bookmarkUser
  ]);

  /**
   * After UnBookmark
   */
  useEffect(() => {
    if (!unBookmarkUser) return;
    if (!mutateUnBookmarkLoading) {
      if (mutateUnBookmarkData?.unbookmarkUser?.ok) {
        showSuccessPopup(`${unBookmarkUser.nickname}のお気に入りを解除しました`);
        setNewBookmarkUserIds((prevBookmark) =>
          prevBookmark.filter((id) => id !== unBookmarkUser.id)
        );
        setNewUnBookmarkUserIds((prevBookmark) => [...prevBookmark, unBookmarkUser.id]);
      } else {
        showErrorPopup('お気に入りを解除できませんでした');
      }
    }
  }, [
    mutateUnBookmarkData,
    mutateUnBookmarkLoading,
    mutateUnBookmarkError,
    setNewBookmarkUserIds,
    setNewUnBookmarkUserIds,
    unBookmarkUser
  ]);

  return {
    toggleBookmark,
    bookmarkLoading,
    newBookmarkUserIds,
    newUnBookmarkUserIds,
    fetchBookmarkUsers,
    bookmarkUsersLoading,
    bookmarkUsers,
    bookmarkUserCount
  };
};
export default useBookmark;
