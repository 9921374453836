import React from 'react';
import {
  EmptyNoticeBox,
  NoItemNoticeBox
} from '@meettry/ui-components/components/organisms/NoticeBox';

type ChallengeEmptyListType = {
  isOwner: boolean;
  onClick: () => void;
  isSearch: boolean;
};

// MEMO(aida)　ユーザ詳細チャレンジにてリストが空の際の表示
export const ChallengeEmptyList: React.FC<ChallengeEmptyListType> = (props) => {
  const { isOwner, onClick, isSearch } = props;

  if (isSearch) return <NoItemNoticeBox contents={['一致するチャレンジはありません']} />;

  return isOwner ? (
    <EmptyNoticeBox
      heading="自身の成長のためにチャレンジしていることを登録しよう"
      contents={[
        'あなたが仕事以外でチャレンジし、',
        '自分のスキルを伸ばすために行っているプロジェクトや学習を登録し、',
        'あなたのチャレンジに興味をもった仲間や企業と繋がりましょう！'
      ]}
      buttonText="さっそく登録する"
      onClick={onClick}
    />
  ) : (
    <NoItemNoticeBox contents={['チャレンジはまだありません']} />
  );
};

export default ChallengeEmptyList;
