import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@apollo/react-hooks';
import { buildPeriodText } from '@meettry/ui-components/utils';
import { UserTagsType, TechStackType, ResumeType } from '@meettry/ui-components/types/userPage';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { LineTag, GrayTag } from '@meettry/ui-components/components/atoms/Tag';
import { GET_TAGS_DATA } from '@meettry/ui-components/queries/user_page';
import TechStack from '@meettry/ui-components/components/organisms/UserPage/Common/SkillBox/TechStack';
import { useEngineer, useTagFilter } from '@meettry/ui-components/hooks/useUserPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const getWorkEndDateTime = (workEndDate: string) => {
  return workEndDate ? new Date(workEndDate).getTime() : new Date().getTime();
};

const SkillBox = () => {
  const { state } = useEngineer();
  const { nickname, userTags } = state;
  const {
    selectedTechStackSummaryTags,
    addSelectedTechStackSummaryTags,
    removeSelectedTechStackSummaryTags,
    selectedOtherTags,
    addSelectedOtherTags,
    removeSelectedOtherTags
  } = useTagFilter();
  const history = useHistory();
  const { data, error, loading } = useQuery(GET_TAGS_DATA, {
    variables: { nicknames: [nickname] }
  });
  const [skillList, setSkillList] = useState<Array<UserTagsType>>([]);
  const [otherSkillList, setOtherSkillList] = useState<Array<UserTagsType>>([]);
  const [userTechStackList, setUserTechStackList] = useState([]);
  const [techStackCategoryList, setTechStackCategoryList] = useState([]);

  useEffect(() => {
    if (data && !error && !loading) {
      //MEMO(aida) バブルチャート用のオブジェクトを生成しているらしい
      const tsl = userTags.reduce((acc: any, value: UserTagsType) => {
        const { tag, days } = value;
        //MEMO(aida) タグがtechStackIdを持っていない場合はreturn
        if (!tag.techStackId) return acc;
        //MEMO(aida) acc内に該当のタグがまだない場合は追加する。
        if (!acc[tag.techStackId]) {
          acc[tag.techStackId] = {
            techStack: (data.techStackList || []).find(
              (x: TechStackType) => x.id === `${tag.techStackId}`
            ),
            days: 0
          };
        }
        //MEMO(aida) 経験日数を合算する。
        acc[tag.techStackId].days += days;
        return acc;
      }, {});

      const displaySkillList = userTags.filter(({ tag }: UserTagsType) => tag.category);
      const sortedDisplaySkillList = displaySkillList.reduce(
        (acc: Array<UserTagsType>, value: UserTagsType, index: number) => {
          if (index === 0) return [value];
          //MEMO(aida) 一個前のtagが関連付いた経歴の日付の配列を作る
          const previousResumeDates = acc[index - 1].resumes.map(({ workEndDate }: ResumeType) =>
            getWorkEndDateTime(workEndDate)
          );
          //MEMO(aida) 一個前のtagが関連付いた経歴の直近の日付を取り出す
          const previousLatestDate = Math.max(...previousResumeDates);
          //MEMO(aida) tagが関連付いた経歴の日付の配列を作る
          const resumeDates = value.resumes.map(({ workEndDate }) =>
            getWorkEndDateTime(workEndDate)
          );
          //MEMO(aida) tagが関連付いた経歴の直近の日付を取り出す
          const latestDate = Math.max(...resumeDates);
          //MEMO(aida) 一個前のタグの経歴の終了日と比較して並び変える
          if (latestDate === previousLatestDate) return [...acc, value];
          if (latestDate > previousLatestDate) return [value, ...acc];
          if (latestDate < previousLatestDate) return [...acc, value];
          return [...acc, value];
        },
        []
      );

      //MEMO(aida) categoryが分類されていないタグから重複をなくす
      const displayOtherSkillList = userTags
        .filter(({ tag }: UserTagsType) => !tag.category)
        .reduce((acc: Array<UserTagsType>, val: UserTagsType) => {
          if (acc.some((some) => some.tag.displayName === val.tag.displayName)) return [...acc];
          return [...acc, val];
        }, []);
      const sortedDisplayOtherSkillList = displayOtherSkillList.sort(
        (a: UserTagsType, b: UserTagsType) => {
          const comparisonA = a.resumes.length;
          const comparisonB = b.resumes.length;
          return comparisonB - comparisonA;
        }
      );

      //MEMO(aida) tslから{days, techStack}[]を作成しstateで保持する。
      setUserTechStackList(Object.values(tsl));
      setTechStackCategoryList(data.techStackCategoryList);
      setSkillList(sortedDisplaySkillList);
      setOtherSkillList(sortedDisplayOtherSkillList);
    }
  }, [data, userTags, error, loading]);

  const onClickTechSummaryTag = (displayName: string) => () => {
    const isAlreadySelected = selectedTechStackSummaryTags.includes(displayName);
    if (isAlreadySelected) {
      removeSelectedTechStackSummaryTags(displayName);
      return;
    }
    addSelectedTechStackSummaryTags(displayName);
    history.push(`/user/${nickname}/resume`);
  };

  const onClickOtherTag = (displayName: string) => () => {
    const isAlreadySelected = selectedOtherTags.includes(displayName);
    if (isAlreadySelected) {
      removeSelectedOtherTags(displayName);
      return;
    }
    addSelectedOtherTags(displayName);
    history.push(`/user/${nickname}/resume`);
  };

  return (
    <StyledSkillBox>
      {loading && <StyledLoader />}
      {!loading && !error && (
        <StyledTechStackSection>
          <StyledBubbleChart>
            <TechStack
              userTechStackList={userTechStackList}
              techStackCategoryList={techStackCategoryList}
            />
          </StyledBubbleChart>
          <StyledTagWrapper>
            <StyledSkillWrapper>
              <StyledSkillContents>
                {/* 直近経歴 > 経験年数 */}
                {skillList.map(({ days, tag: { id, displayName } }) => (
                  <React.Fragment key={id}>
                    <StyledTechSummaryTag
                      key={id}
                      period={buildPeriodText(days)}
                      onClick={onClickTechSummaryTag(displayName)}
                      active={selectedTechStackSummaryTags.includes(displayName)}
                    >
                      {displayName}
                    </StyledTechSummaryTag>
                  </React.Fragment>
                ))}
              </StyledSkillContents>
            </StyledSkillWrapper>
            <StyledRoleWrapper>
              <StyledRoleContents>
                {/* 出現回数 > 直近経歴  */}
                {otherSkillList.map(({ tag: { id, displayName } }) => (
                  <StyledTag
                    key={id}
                    onClick={onClickOtherTag(displayName)}
                    active={selectedOtherTags.includes(displayName)}
                  >
                    {displayName}
                  </StyledTag>
                ))}
              </StyledRoleContents>
            </StyledRoleWrapper>
          </StyledTagWrapper>
        </StyledTechStackSection>
      )}
    </StyledSkillBox>
  );
};

export default SkillBox;

const StyledSkillBox = styled.div`
  min-height: 372px;
  position: relative;
`;

const StyledLoader = styled(Loader)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -40px);
`;

const StyledTechStackSection = styled.div`
  display: flex;
  flex-direction: row;
`;

const StyledBubbleChart = styled.div`
  background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
  width: 100%;
`;

const StyledSkillWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  height: 145px;
`;

const StyledTagWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 300px;
  min-height: 372px;
  flex-shrink: 0;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const StyledRoleWrapper = styled.div`
  padding: 20px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-top: none;
  height: 145px;
`;

const StyledSkillContents = styled.div`
  flex: 1 1 auto;
  justify-content: center;
  height: 145px;
  overflow: auto;
  & > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledRoleContents = styled.div`
  flex: 1 1 auto;
  height: 145px;
  justify-content: center;
  overflow: auto;
  /* TODO(aida) もう少し整理したらちゃんとやる #コンポーネント化 */
  & > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledTechSummaryTag = styled(GrayTag)`
  cursor: pointer;
  transition: ease all 0.2s;
  ${(props) =>
    props.active &&
    css`
      color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
      background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
    `}
`;

const StyledTag = styled(LineTag)`
  cursor: pointer;
  transition: ease all 0.2s;
  ${(props) =>
    props.active &&
    css`
      background-color: ${COLOR_DEFINITIONS.BG.SKILL};
    `}
`;
