import React from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { LineTag, BasicTag } from '@meettry/ui-components/components/atoms/Tag';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { ScoutDetail } from '@meettry/ui-components/types/scout';
import { formatDate } from '@meettry/ui-components/utils';

type Props = {
  scoutDetail: ScoutDetail;
};

/**
 * ScoutDetailTable
 */
const ScoutDetailTable: React.FC<Props> = (props) => {
  const { scoutDetail } = props;

  /**
   * Variables
   */
  const scoutTags = scoutDetail?.scoutTags ?? [];
  const requiredJobList = scoutTags
    .filter((tag) => tag.isRequired && tag.tag.category === null)
    .map((tag) => (
      <StyledLineTag key={tag.tag.displayName}>{`${tag.tag.displayName}${
        tag.years ? ` ${tag.years}年` : ''
      }`}</StyledLineTag>
    ));

  const requiredLangList = scoutTags
    .filter((tag) => tag.isRequired && tag.tag.category !== null)
    .map((tag) => (
      <StyledBasicTag key={tag.tag.displayName}>{`${tag.tag.displayName}${
        tag.years ? ` ${tag.years}年` : ''
      }`}</StyledBasicTag>
    ));

  const expectedJobList = scoutTags
    .filter((tag) => !tag.isRequired && tag.tag.category === null)
    .map((tag) => <StyledLineTag key={tag.tag.displayName}>{tag.tag.displayName}</StyledLineTag>);

  const expectedLangList = scoutTags
    .filter((tag) => !tag.isRequired && tag.tag.category !== null)
    .map((tag) => <StyledBasicTag key={tag.tag.displayName}>{tag.tag.displayName}</StyledBasicTag>);

  const startAt = scoutDetail?.recruitmentStartAt ? formatDate(scoutDetail.recruitmentStartAt) : '';
  const endAt = scoutDetail?.recruitmentEndAt ? formatDate(scoutDetail.recruitmentEndAt) : '';

  const scoutDetailContents = [
    {
      heading: '募集期間',
      data: `${startAt} 〜 ${endAt}`,
      jobData: '',
      langData: ''
    },
    {
      heading: '採用人数',
      data: `${scoutDetail?.recruitmentCount ?? 0}人`,
      jobData: '',
      langData: ''
    },
    {
      heading: '必須スキル',
      jobData: requiredJobList,
      langData: requiredLangList
    },
    {
      heading: '歓迎スキル',
      jobData: expectedJobList,
      langData: expectedLangList
    }
  ];

  return (
    <StyledScoutDetailTableWrap>
      <StyledScoutDetailTable>
        <StyledScoutDetailTableBody>
          {scoutDetailContents.map((item, index) => {
            return (
              <StyledScoutDetailTableRow key={index}>
                <StyledScoutDetailTableHeader>{item.heading}</StyledScoutDetailTableHeader>
                <StyledScoutDetailTableData>
                  {item.data}
                  <div>{item.jobData}</div>
                  <StyledLangTagWrap>
                    {item.langData !== '' ? (
                      <>
                        <StyledIcon iconName="skill_language" iconColor="black" fileType="png" />
                        {item.langData}
                      </>
                    ) : null}
                  </StyledLangTagWrap>
                </StyledScoutDetailTableData>
              </StyledScoutDetailTableRow>
            );
          })}
        </StyledScoutDetailTableBody>
      </StyledScoutDetailTable>
    </StyledScoutDetailTableWrap>
  );
};
export default ScoutDetailTable;

const StyledScoutDetailTableWrap = styled.div`
  padding: 20px 40px;
  box-sizing: border-box;
  width: 100%;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledScoutDetailTable = styled.table`
  width: 100%;
`;

const StyledScoutDetailTableBody = styled.tbody`
  width: 100%;
`;

const StyledScoutDetailTableRow = styled.tr`
  display: block;
  padding: 20px 0;
  & + & {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  }
`;

const StyledScoutDetailTableHeader = styled.th`
  width: 160px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-family: ${FONT_FAMILY.COMMON};
  font-size: 16px;
  font-weight: 500;
`;

const StyledScoutDetailTableData = styled.td`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-family: ${FONT_FAMILY.COMMON};
  font-size: 16px;
  font-weight: 500;
`;

const StyledLangTagWrap = styled.div`
  margin-top: 12px;
  display: flex;
  align-items: center;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin: 5px 5px 0 0;
  background-repeat: no-repeat;
  background-size: cover;
  width: 14px;
  height: 14px;
`;

const StyledLineTag = styled(LineTag)`
  margin: 4px 4px 0 0;
  display: inline-block;
`;

const StyledBasicTag = StyledLineTag.withComponent(BasicTag);
