import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton, LineButton } from '@meettry/ui-components/components/atoms/Button';
import { ConfirmationType } from '@meettry/ui-components/types/dialog';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const CancelConfirmation: React.FC<ConfirmationType> = (props) => {
  const { texts, onCancel, onAccept } = props;

  return (
    <StyledCancelConfirmation>
      <StyledCancelConfirmationContainer>
        {texts.map((text, i) => (
          <StyledConfirmationText key={i}>{text}</StyledConfirmationText>
        ))}
        <StyledConfirmationButtons>
          <LineButton onClick={onCancel}>キャンセル</LineButton>
          <PrimaryButton onClick={onAccept}>破棄</PrimaryButton>
        </StyledConfirmationButtons>
      </StyledCancelConfirmationContainer>
    </StyledCancelConfirmation>
  );
};

export default CancelConfirmation;

const StyledCancelConfirmation = styled(DialogBase)`
  position: relative;
`;

const StyledCancelConfirmationContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledConfirmationText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledConfirmationButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 120px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;
