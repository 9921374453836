import { useContext } from 'react';
import { showLoading, hideLoading, LoadingContext } from '@meettry/ui-components/contexts/loading';

// 項目更新時などに表示させるローディングを管理する
const useLoading = () => {
  const { state, dispatch } = useContext(LoadingContext);
  const startLoading = (text = '') => {
    dispatch(showLoading(text));
  };
  const endLoading = () => {
    dispatch(hideLoading());
  };

  return {
    state,
    startLoading,
    endLoading
  };
};

export default useLoading;
