import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type NoticeProps = {
  type?: 'important';
  length: number;
  margin?: 'contents' | 'sections' | number;
};

export const Notice: React.FC<NoticeProps> = (props) => {
  const { type, margin, length } = props;
  return (
    <>
      <StyledNotice {...props}>
        <StyledNoticeOl>
          {Array.from({ length: length }, (_, i) => (
            <StyledNoticeList key={i}>
              <StyledNoticeDate>10分前</StyledNoticeDate>
              <StyledNoticeListDesc>
                <StyledNoticeListSpanLink {...props}>名前なまえさん</StyledNoticeListSpanLink>
                <span>がフォローしました</span>
              </StyledNoticeListDesc>
            </StyledNoticeList>
          ))}
        </StyledNoticeOl>
        <StyledShowMore>もっと見る</StyledShowMore>
      </StyledNotice>
    </>
  );
};

const StyledNotice = styled.div<NoticeProps>`
  position: relative;
  width: 100%;
  box-sizing: border-box;
  padding: 20px 30px;
  font-size: 12px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${({ type }) =>
    type ? COLOR_DEFINITIONS.BG.TRANSLUCENT.SECONDARY : COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};

  ${({ margin }) =>
    margin
      ? `margin-top: ${margin === 'contents' ? 50 : margin === 'sections' ? 20 : margin}px`
      : null}
`;

const StyledNoticeOl = styled.ol`
  margin: 0;
  padding: 0;
  list-style: none;
`;

const StyledNoticeList = styled.li`
  margin-top: 12px;
  &:first-child {
    margin-top: 0;
  }
`;

const StyledNoticeDate = styled.span`
  display: inline-block;
  width: 80px;
`;
const StyledNoticeListDesc = styled.span`
  font-size: 14px;
`;

const StyledNoticeListSpanLink = styled.span<NoticeProps>`
  ${({ type }) =>
    type
      ? `color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
    text-decoration: underline;
      &:hover {
          cursor: pointer;
      }`
      : null}
`;

const StyledShowMore = styled.span`
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  cursor: pointer;
`;
