import React from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { BUTTON } from '@meettry/engineer/utils/Constant';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

/**
 * アイコンボタン
 * MEMO(PARK)：countを表示することもできる
 * @param {*} props
 * @param {String} buttonType
 * @param {Object} iconProps
 */
export const IconButton = (props) => {
  const { size, theme, marginRight, iconProps, children, ...restProps } = props;

  return (
    <PrimaryButton
      styleType={
        theme === BUTTON.THEME.TERTIARY
          ? STYLE_TYPE.ROLE.THIRDLY
          : theme === BUTTON.THEME.SECONDARY
          ? STYLE_TYPE.ROLE.SECONDARY
          : null
      }
      size={
        size === 'small'
          ? STYLE_TYPE.SIZE.SMALL
          : size === 'medium'
          ? STYLE_TYPE.SIZE.MEDIUM
          : size === 'large'
          ? STYLE_TYPE.SIZE.LARGE
          : !!size
          ? size
          : null
      }
      {...restProps}
    >
      <Icon marginRight={!!marginRight ? marginRight : '4px'} {...iconProps} />
      <span>{children}</span>
    </PrimaryButton>
  );
};

export const PrimaryIconButton = (props) => <IconButton {...props} />;
export const SecondaryIconButton = (props) => (
  <IconButton {...props} styleType={STYLE_TYPE.ROLE.SECONDARY} />
);
export const TertiaryIconButton = (props) => (
  <IconButton {...props} styleType={STYLE_TYPE.ROLE.THIRDLY} />
);

export const FollowButton = (props) => {
  const { count, following, ...restProps } = props;
  const iconProps = {
    iconColor: following ? 'gray' : 'white',
    iconName: 'followers'
  };

  return (
    <IconButton iconProps={iconProps} {...restProps}>
      {following ? 'フォロー中' : 'フォローする'}
      {count ? <span>{count}</span> : null}
    </IconButton>
  );
};

export const ConnectionButton = (props) => {
  const { children, count, ...restProps } = props;
  return (
    <IconButton {...restProps}>
      {children}
      {count ? <StyledConnectionCount>{count}</StyledConnectionCount> : null}
    </IconButton>
  );
};

const StyledConnectionCount = styled.span`
  font-weight: bold;
  margin-left: 5px;
`;
