import { useEffect, useState } from 'react';
import { throttle } from 'lodash';

export const useWindowWidth = (): number => {
  const [windowWidth, setWindowWidth] = useState<number>(window.innerWidth);
  const changeWindowWidth = () => throttle(() => setWindowWidth(window.innerWidth), 500);

  useEffect(() => {
    window.addEventListener('resize', changeWindowWidth);
    return window.removeEventListener('resize', changeWindowWidth);
  }, []);
  return windowWidth;
};
