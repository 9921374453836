import React, { createContext, Dispatch, Reducer, useContext, useReducer } from 'react';
import {
  ChatRoom,
  ChatMessage,
  ChatRoomWithMessages,
  ChatMessageCounts
} from '@meettry/ui-components/types/chat';
import { ProviderComponent } from '@meettry/ui-components/types/context';

/**
 * Chat context
 * 右上に表示される通知ポップアップのコンテクスト
 */

/**
 * Constants
 */
type ChatRoomMessageLoadingType = 'INIT' | 'FORWARD' | 'BACKEND';
export const CHAT_ROOM_MESSAGE_LOADING_TYPE: { [key in string]: ChatRoomMessageLoadingType } = {
  INIT: 'INIT',
  FORWARD: 'FORWARD',
  BACKEND: 'BACKEND'
};

/**
 * Action
 */
type Action<T, U> = { type: T; payload: U };

export const CHAT_ACTION_TYPE = {
  UPDATE_CHAT_ROOMS: 'UPDATE_CHAT_ROOMS',
  SET_CHAT_ROOM_MESSAGES: 'SET_CHAT_ROOM_MESSAGES',
  SET_CHAT_MESSAGE_COUNTS: 'SET_CHAT_MESSAGE_COUNTS'
};
type ChatAction = UpdateChatRoomAction | SetChatRoomMessagesAction | SetChatMessageCountsAction;

// チャットルームの更新
type UpdateChatRoomAction = Action<
  typeof CHAT_ACTION_TYPE.UPDATE_CHAT_ROOMS,
  {
    chatRooms: ChatRoom[];
  }
>;
export const updateChatRooms = (chatRooms: ChatRoom[]): UpdateChatRoomAction => ({
  type: CHAT_ACTION_TYPE.UPDATE_CHAT_ROOMS,
  payload: {
    chatRooms
  }
});

// 特定のチャットルームのチャットメッセージ更新
type SetChatRoomMessagesAction = Action<
  typeof CHAT_ACTION_TYPE.SET_CHAT_ROOM_MESSAGES,
  {
    roomId: string;
    messages: ChatMessage[];
    hasNewMessagesOlders: ChatRoomWithMessages['hasNewMessagesOlders'];
  }
>;
export const setChatMessages = (
  roomId: string,
  messages: ChatMessage[],
  hasNewMessagesOlders: ChatRoomWithMessages['hasNewMessagesOlders'] = null
): SetChatRoomMessagesAction => ({
  type: CHAT_ACTION_TYPE.SET_CHAT_ROOM_MESSAGES,
  payload: { roomId, messages, hasNewMessagesOlders }
});

// チャットルームごとの未読メッセージのカウントを更新
type SetChatMessageCountsAction = Action<
  typeof CHAT_ACTION_TYPE.SET_CHAT_MESSAGE_COUNTS,
  { chatMessageCounts: ChatMessageCounts }
>;
export const setChatMessageCounts = (
  chatMessageCounts: ChatMessageCounts
): SetChatMessageCountsAction => ({
  type: CHAT_ACTION_TYPE.SET_CHAT_MESSAGE_COUNTS,
  payload: { chatMessageCounts }
});

/**
 * Dispatch
 */
type ChatDispatch = Dispatch<ChatAction>;

/**
 * State
 */
type ChatState = {
  loadingType: ChatRoomMessageLoadingType | null;
  chatRooms: ChatRoomWithMessages[];
  chatMessageCounts: ChatMessageCounts;
};

const defaultState = (): ChatState => ({
  loadingType: null,
  chatRooms: [],
  chatMessageCounts: {}
});

/**
 * Reducer
 */
type ChatReducer = Reducer<ChatState, ChatAction>;
const chatReducer: ChatReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case CHAT_ACTION_TYPE.UPDATE_CHAT_ROOMS: {
      const { chatRooms } = action.payload as UpdateChatRoomAction['payload'];
      if (!chatRooms || !Array.isArray(chatRooms)) return state;

      // すでに保存されているチャットルームIDの取得
      const storedRoomIds = state.chatRooms.map((room) => room.id);

      // すでに保存されているチャットルームIDに存在しないIDのみを抽出し、新しくチャットルーム情報をを作成
      const roomToCreated = chatRooms
        .filter((room) => !storedRoomIds.includes(room.id))
        .map((room) => ({ ...room, messages: null, hasNewMessagesOlders: null }));

      // すでに保存されているチャットルームIDに存在するIDのみを抽出し、チャットルーム情報を更新
      const roomToUpdated = chatRooms
        .filter((room) => storedRoomIds.includes(room.id))
        .map((room) => {
          const targetRoom = state.chatRooms.find((r) => r.id === room.id);
          return targetRoom
            ? { ...targetRoom, ...room }
            : { ...room, messages: null, hasNewMessagesOlders: null };
        });

      const newChatRooms = [...roomToUpdated, ...roomToCreated].sort((a, b) => {
        return (b.updatedAt ?? b.createdAt).getTime() - (a.updatedAt ?? a.createdAt).getTime();
      });
      return { ...state, chatRooms: newChatRooms };
    }

    case CHAT_ACTION_TYPE.SET_CHAT_ROOM_MESSAGES: {
      const {
        roomId,
        messages,
        hasNewMessagesOlders
      } = action.payload as SetChatRoomMessagesAction['payload'];
      const newChatRooms = state.chatRooms
        .map((room) =>
          roomId === room.id
            ? {
                ...room,
                hasNewMessagesOlders: hasNewMessagesOlders ?? room.hasNewMessagesOlders,
                messages
              }
            : room
        )
        .sort((a, b) => {
          return (b.updatedAt ?? b.createdAt).getTime() - (a.updatedAt ?? a.createdAt).getTime();
        });
      return { ...state, chatRooms: newChatRooms };
    }

    case CHAT_ACTION_TYPE.SET_CHAT_MESSAGE_COUNTS: {
      const { chatMessageCounts } = action.payload as SetChatMessageCountsAction['payload'];
      return { ...state, chatMessageCounts };
    }

    default: {
      return state;
    }
  }
};

/**
 * Context
 */
type ChatStore = {
  state: ChatState;
  dispatch: ChatDispatch;
};
const defaultStore: ChatStore = {
  state: defaultState(),
  dispatch: () => defaultState()
};
const ChatContext = createContext<ChatStore>(defaultStore);

// Provider
export const ChatProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(chatReducer, defaultState());
  return <ChatContext.Provider value={{ state, dispatch }}>{children}</ChatContext.Provider>;
};

export const useChatContext = () => {
  const context = useContext(ChatContext);
  if (context === undefined) throw new Error(`No provider for ChatContext given`);
  return { ...context };
};
