import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { EngineerType } from '@meettry/ui-components/types/userPage';
import {
  FollowButton,
  IconButton
} from '@meettry/ui-components/components/atoms/Button/IconButton';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import {
  FETCH_FOLLOWEE_QUERY,
  FOLLOW_USER_QUERY,
  UNFOLLOW_USER_QUERY
} from '@meettry/ui-components/queries/user_page/common';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { GET_ENABLE_ACCEPTING_SCOUT } from '@meettry/ui-components/queries/user_page/common';

type UserProfileButtonListType = {
  id: string;
  nickname: string;
  isOwner: boolean;
  onClickChatHandler: () => void;
  isLoginUserCompany: boolean;
};

const UserProfileButtonList: React.FC<UserProfileButtonListType> = (props) => {
  const { id, nickname, isOwner, onClickChatHandler, isLoginUserCompany } = props;
  const history = useHistory();
  const { startLoading, endLoading } = useLoading();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const [loginUserFollowing, updateLoginUserFollowing] = useState<Array<string>>([]);

  const { data: loginUserFollowee, loading: loadingLoginUserFollowee } = useQuery(
    FETCH_FOLLOWEE_QUERY
  );

  const [mutateFollow, { loading: loadingFollow, error: errorFollow }] = useMutation(
    FOLLOW_USER_QUERY,
    {
      onCompleted: ({ followUser }) => {
        if (!followUser) return;
        if (!followUser.ok) {
          showErrorPopup(`${nickname}をフォローできませんでした`);
          return;
        }
        updateLoginUserFollowing([...loginUserFollowing, nickname]);
        endLoading();
        showSuccessPopup(`${nickname}をフォローしました`);
      }
    }
  );
  const [mutateUnfollow, { loading: loadingUnfollow, error: errorUnfollow }] = useMutation(
    UNFOLLOW_USER_QUERY,
    {
      onCompleted: ({ unfollowUser }) => {
        if (!unfollowUser) return;
        if (!unfollowUser.ok) {
          showErrorPopup(`${nickname}をアンフォローできませんでした`);
          return;
        }
        const updatedUserFollowing = loginUserFollowing.filter((name) => nickname !== name);
        updateLoginUserFollowing(updatedUserFollowing);
        endLoading();
        showSuccessPopup(`${nickname}をアンフォローしました`);
      }
    }
  );

  //MEMO(aida) フォロー・アンフォローのgraphqlエラーのエラーハンドリング
  useEffect(() => {
    const isError = errorFollow || errorUnfollow;
    if (!isError) return;
    endLoading();
    if (errorUnfollow) showErrorPopup(`${nickname}をアンフォローできませんでした`);
    if (errorFollow) showErrorPopup(`${nickname}をフォローできませんでした`);
  }, [errorFollow, errorUnfollow]);

  useEffect(() => {
    if (!loadingLoginUserFollowee && loginUserFollowee) {
      const followeesNickname = !!loginUserFollowee.loginSession.user
        ? loginUserFollowee.loginSession.user.followees.map((item: EngineerType) => item.nickname)
        : [];
      updateLoginUserFollowing([...loginUserFollowing, ...followeesNickname]);
    }
  }, [loginUserFollowee, loadingLoginUserFollowee]);

  const onClickEditProfile = () => {
    history.push({ pathname: `/profile` });
  };

  const onClickFollow = (id: string, nickname: string) => () => {
    if (loadingFollow || loadingUnfollow) return;
    if (isLoginUserFollowing) {
      mutateUnfollow({
        variables: {
          userId: id
        }
      });
      startLoading(`${nickname}のアンフォロー中...`);
      return;
    }
    mutateFollow({
      variables: {
        userId: id
      }
    });
    startLoading(`${nickname}をフォロー中...`);
    return;
  };

  const isLoginUserFollowing = loginUserFollowing.includes(nickname);

  const { data: usersData } = useQuery(GET_ENABLE_ACCEPTING_SCOUT, {
    variables: {
      nicknames: nickname
    }
  });

  if (isOwner) {
    return (
      <li>
        <IconButton
          styleType={STYLE_TYPE.ROLE.THIRDLY}
          onClick={onClickEditProfile}
          size={STYLE_TYPE.SIZE.MEDIUM}
          iconProps={{
            iconName: 'edit',
            iconColor: 'gray'
          }}
        >
          プロフィール編集
        </IconButton>
      </li>
    );
  }

  return (
    <>
      {isOwner ? (
        <li>
          <IconButton
            styleType={STYLE_TYPE.ROLE.THIRDLY}
            onClick={onClickEditProfile}
            size={STYLE_TYPE.SIZE.MEDIUM}
            iconProps={{
              iconName: 'edit',
              iconColor: 'gray'
            }}
          >
            プロフィール編集
          </IconButton>
        </li>
      ) : isLoginUserCompany ? (
        <li>
          <IconButton
            styleType={STYLE_TYPE.ROLE.SECONDARY}
            onClick={onClickChatHandler}
            size={STYLE_TYPE.SIZE.MEDIUM}
            iconProps={{
              iconName: 'chatIcon',
              iconColor:
                usersData?.users[0]?.userDetail?.enableAcceptingScout !== 1 ?? true
                  ? 'gray'
                  : 'primary'
            }}
            disabled={usersData?.users[0]?.userDetail?.enableAcceptingScout !== 1 ?? true}
          >
            メッセージ
          </IconButton>
        </li>
      ) : (
        !loadingLoginUserFollowee && (
          <li>
            <FollowButton
              styleType={isLoginUserFollowing ? STYLE_TYPE.ROLE.THIRDLY : STYLE_TYPE.ROLE.PRIMARY}
              size={STYLE_TYPE.SIZE.MEDIUM}
              following={isLoginUserFollowing}
              onClick={onClickFollow(id, nickname)}
            />
          </li>
        )
      )}
    </>
  );
};

export default UserProfileButtonList;
