import gql from 'graphql-tag';

export const FETCH_BOOKMARK_USERS_QUERY = gql`
  query($page: PageInfo!, $orders: [OrderField]) {
    loginSession {
      user {
        organization {
          bookmarks {
            users {
              results(page: $page, orders: $orders) {
                id
                nickname
                isBookmarked
                bio
                userDetail {
                  enableAcceptingScout
                  enableScoutChatNotifMail
                }
              }
              count
            }
          }
        }
      }
    }
  }
`;

export const BOOKMARK_USER_BY_ENTERPRISE_QUERY = gql`
  mutation($userId: String!) {
    bookmarkUser(input: { userId: $userId }) {
      ok
    }
  }
`;

export const UNBOOKMARK_USER_BY_ENTERPRISE_QUERY = gql`
  mutation($userId: String!) {
    unbookmarkUser(input: { userId: $userId }) {
      ok
    }
  }
`;
