import arrowDown from '@meettry/ui-components/images/icons/gray/icon_arrow_down.svg';
import arrowLeft from '@meettry/ui-components/images/icons/gray/icon_arrow_left.svg';
import arrowRight from '@meettry/ui-components/images/icons/gray/icon_arrow_right.svg';
import arrowUp from '@meettry/ui-components/images/icons/gray/icon_arrow_up.svg';

import camera from '@meettry/ui-components/images/icons/gray/icon_camera.svg';
import challengeCommit from '@meettry/ui-components/images/icons/gray/icon_challenge_commit.svg';
import challenge from '@meettry/ui-components/images/icons/gray/icon_challenge.svg';
import chat from '@meettry/ui-components/images/icons/gray/icon_chatIcon.svg';
import clip from '@meettry/ui-components/images/icons/gray/icon_clip.svg';
import code from '@meettry/ui-components/images/icons/gray/icon_code.svg';
import company from '@meettry/ui-components/images/icons/gray/icon_company.svg';

import day from '@meettry/ui-components/images/icons/gray/icon_day.svg';
import db from '@meettry/ui-components/images/icons/gray/icon_day.svg';
import dlt from '@meettry/ui-components/images/icons/gray/icon_delete.svg';

import edit from '@meettry/ui-components/images/icons/gray/icon_edit.svg';
import editingButton from '@meettry/ui-components/images/icons/gray/icon_editing_btn.svg';

import followers from '@meettry/ui-components/images/icons/gray/icon_followers.svg';
import following from '@meettry/ui-components/images/icons/gray/icon_following.svg';
import framework from '@meettry/ui-components/images/icons/gray/icon_framework.svg';
import friend from '@meettry/ui-components/images/icons/gray/icon_friendIcon.svg';

import heart from '@meettry/ui-components/images/icons/gray/icon_heart.svg';

import image from '@meettry/ui-components/images/icons/gray/icon_img.svg';

import like from '@meettry/ui-components/images/icons/gray/icon_like.svg';
import link from '@meettry/ui-components/images/icons/gray/icon_link.svg';

import map from '@meettry/ui-components/images/icons/gray/icon_map.svg';
import minus from '@meettry/ui-components/images/icons/gray/icon_minus.svg';

import newList from '@meettry/ui-components/images/icons/gray/icon_new_list.svg';
import news from '@meettry/ui-components/images/icons/gray/icon_newsIcon.svg';

import othercode from '@meettry/ui-components/images/icons/gray/icon_othercode.svg';

import pc from '@meettry/ui-components/images/icons/gray/icon_pc.svg';
import pin from '@meettry/ui-components/images/icons/gray/icon_pin.svg';
import plus from '@meettry/ui-components/images/icons/gray/icon_plus.svg';
import profile from '@meettry/ui-components/images/icons/gray/icon_profile.svg';

import resume from '@meettry/ui-components/images/icons/gray/icon_resume.svg';

import scout from '@meettry/ui-components/images/icons/gray/icon_scout.svg';
import search from '@meettry/ui-components/images/icons/gray/icon_Search.svg';
import server from '@meettry/ui-components/images/icons/gray/icon_server.svg';

import tagDelete from '@meettry/ui-components/images/icons/gray/icon_tag_delete.svg';
import time from '@meettry/ui-components/images/icons/gray/icon_time.svg';
import to from '@meettry/ui-components/images/icons/gray/icon_to.svg';

import update from '@meettry/ui-components/images/icons/gray/icon_update.svg';

import view from '@meettry/ui-components/images/icons/gray/icon_view.svg';

import checkBox from '@meettry/ui-components/images/icons/gray/icon_checkbox.png';

import hidden from '@meettry/ui-components/images/icons/gray/icon_hidden.svg';

export default {
  arrowDown,
  arrowLeft,
  arrowRight,
  arrowUp,
  camera,
  challengeCommit,
  challenge,
  chat,
  clip,
  code,
  company,
  day,
  db,
  dlt,
  edit,
  editingButton,
  followers,
  following,
  framework,
  friend,
  heart,
  hidden,
  image,
  like,
  link,
  map,
  minus,
  newList,
  news,
  othercode,
  pc,
  pin,
  plus,
  profile,
  resume,
  scout,
  search,
  server,
  tagDelete,
  time,
  to,
  update,
  view,
  checkBox
};
