import React, { createContext, useReducer, Dispatch } from 'react';
import uid from 'uuid';
import { ProviderComponent } from '@meettry/ui-components/types/context';
import { Provider } from 'react';

// actions
const SHOW_POPUP = 'SHOW_POPUP';
const REMOVE_POPUP = 'REMOVE_POPUP';

//通知ポップアップ表示用のアクション, ポップアップの種類と文言を指定する。 popupType = "success" | "error" |
export const showPopup = (popupType: 'success' | 'error', text: string) => ({
  type: SHOW_POPUP,
  payload: {
    id: uid(),
    type: popupType,
    text
  }
});

//通知ポップアップ削除用のアクション 削除対象のポップアップのidを指定する
export const removePopup = (popupId: string) => ({
  type: REMOVE_POPUP,
  payload: {
    id: popupId
  }
});

//MEMO(aida) popupsが想定する配列の要素
// {
//   id: string,
//   type: "success" | "error",
//   text: string
// }

//TODO(aida) stateの初期値を考え直す
const initState = () => ({
  popups: []
});

const initialState = initState();
//reducer
const popupReducer = (state: PopupsState = initialState, action: PopupsAction) => {
  switch (action.type) {
    case SHOW_POPUP:
      const afterAddedPopups = [...state.popups, action.payload];
      return { ...state, popups: afterAddedPopups };
    case REMOVE_POPUP:
      const { id = null } = action.payload;
      const afterRemovedPopups = state.popups.filter((popup) => popup.id !== id);
      return { ...state, popups: afterRemovedPopups };
    default:
      return state;
  }
};

type PopupState = {
  id: string | null;
  type?: 'success' | 'error';
  text?: string;
};

type PopupsState = {
  popups: Array<PopupState>;
};
type Action<T, U> = { type: T; payload: U };

type PopupsOption = PopupState;

type PopupsAction = Action<string, PopupsOption>;

type PopupsDispatch = Dispatch<PopupsAction>;
//context
type PopupsStore = {
  state: PopupsState;
  dispatch: PopupsDispatch;
};
const defaultStore: PopupsStore = {
  state: initialState,
  dispatch: () => initialState
};

//context
export const PopupContext = createContext<PopupsStore>(defaultStore);

// Provider
export const PopupProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(popupReducer, initialState);
  return <PopupContext.Provider value={{ state, dispatch }}>{children}</PopupContext.Provider>;
};
