import { useEffect, useMemo, useState } from 'react';
import {
  ADD_SCOUT_COMMENT_QUERY,
  CREATE_SCOUT,
  DELETE_SCOUT,
  FETCH_SCOUT_BY_ID,
  FETCH_SCOUT_MATCHED_USERS,
  SCOUT_COMMENT_QUERY,
  UPDATE_SCOUT
} from '@meettry/ui-components/queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  ScoutComment,
  ScoutDetail,
  ScoutUser,
  ScoutUserWithComment
} from '@meettry/ui-components/types/scout';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import useBookmark from '@meettry/ui-components/hooks/useBookmark';
import { useHistory } from 'react-router';

/**
 * Constants
 */
const DEFAULT_COUNT = 1;
const DEFAULT_VIEW_COUNT = 10;
export const SCOUT_DETAIL_USER_TYPE = {
  MATCHED: 'matched',
  MATCHED_IN_BOOKMARKS: 'matchedInBookmarks',
  CHAT_SENT: 'chatSent',
  CHAT_REPLIED: 'chatReplied'
};

/**
 * useScoutDetail Hook
 * スカウト詳細関連のフック
 */
const useScoutDetail = (scoutId: string) => {
  const history = useHistory();
  /**
   * State
   */
  const [paging, setPaging] = useState(DEFAULT_COUNT);
  const [offset, setOffset] = useState(DEFAULT_VIEW_COUNT);
  const [currentScoutMatchedType, setCurrentScoutMatchedType] = useState(
    SCOUT_DETAIL_USER_TYPE.MATCHED
  );
  const [newScoutComments, setNewScoutComments] = useState<ScoutComment[]>([]);

  /**
   * Custom Hook
   */
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const {
    toggleBookmark,
    bookmarkLoading,
    newBookmarkUserIds,
    newUnBookmarkUserIds
  } = useBookmark();

  /**
   * Query
   */
  const [fetchScoutByIdQuery, { data, loading, error }] = useLazyQuery(FETCH_SCOUT_BY_ID, {
    fetchPolicy: 'no-cache' // MEMO(oomura): ページ遷移して戻ってくると undefined になるから no-cacheを設定
  });
  const [
    fetchScoutCommentsQuery,
    { data: scoutCommentsData, loading: scoutCommentsLoading, error: scoutCommentsError }
  ] = useLazyQuery(SCOUT_COMMENT_QUERY, {
    fetchPolicy: 'no-cache' // MEMO(oomura): ページ遷移して戻ってくると undefined になるから no-cacheを設定
  });
  const [
    fetchScoutMatchedUsers,
    {
      data: scoutMatchedUsersData,
      loading: scoutMatchedUsersLoading,
      error: scoutMatchedUsersError
    }
  ] = useLazyQuery(FETCH_SCOUT_MATCHED_USERS, {
    fetchPolicy: 'no-cache' // MEMO(oomura): ページ遷移して戻ってくると undefined になるから no-cacheを設定
  });
  const [
    mutateScoutComment,
    {
      data: mutateScoutCommentData,
      loading: mutateScoutCommentLoading,
      error: mutateScoutCommentError
    }
  ] = useMutation(ADD_SCOUT_COMMENT_QUERY);
  const [
    mutateCreateScout,
    { data: createScoutData, loading: createScoutLoading, error: createScoutError }
  ] = useMutation(CREATE_SCOUT);
  const [
    mutateUpdateScout,
    { data: updateScoutData, loading: updateScoutLoading, error: updateScoutError }
  ] = useMutation(UPDATE_SCOUT);
  const [
    mutateDeleteScout,
    { data: deleteScoutData, loading: deleteScoutLoading, error: deleteScoutError }
  ] = useMutation(DELETE_SCOUT);

  /**
   * Variables
   */
  const scoutDetail: ScoutDetail = useMemo(
    () =>
      updateScoutData?.updateScout?.scout ?? data?.loginSession?.user?.organization?.scout ?? null,
    [data, updateScoutData]
  );
  const matchedUsers: {
    type: string;
    name: string;
    count: number;
    results: ScoutUserWithComment[];
  }[] = useMemo(() => {
    const users = scoutMatchedUsersData?.loginSession?.user?.organization?.scout?.users ?? {
      matched: { results: [], count: 0 },
      matchedInBookmarks: { results: [], count: 0 },
      chatSent: { results: [], count: 0 },
      chatReplied: { results: [], count: 0 }
    };
    const comments: ScoutComment[] =
      scoutCommentsData?.loginSession?.user?.organization?.scoutComments ?? [];
    const mappingUser = (user: ScoutUser): ScoutUserWithComment => ({
      ...user,
      isBookmarked: (() => {
        if (newBookmarkUserIds.includes(user.id)) return true;
        else if (newUnBookmarkUserIds.includes(user.id)) return false;
        else return user.isBookmarked;
      })(),
      comments: [
        ...comments.filter((c) => c.targetUserId === parseInt(user.id, 10)),
        ...newScoutComments.filter((c) => c.targetUserId === parseInt(user.id, 10))
      ]
    });
    users.matched.results = users.matched.results.map(mappingUser);
    users.matchedInBookmarks.results = users.matchedInBookmarks.results.map(mappingUser);
    users.chatSent.results = users.chatSent.results.map(mappingUser);
    users.chatReplied.results = users.chatReplied.results.map(mappingUser);

    return [
      { type: SCOUT_DETAIL_USER_TYPE.MATCHED, name: '条件に合ったエンジニア', ...users.matched },
      {
        type: SCOUT_DETAIL_USER_TYPE.MATCHED_IN_BOOKMARKS,
        name: 'お気に入りのエンジニア',
        ...users.matchedInBookmarks
      },
      { type: SCOUT_DETAIL_USER_TYPE.CHAT_SENT, name: 'スカウトメール送信済', ...users?.chatSent },
      {
        type: SCOUT_DETAIL_USER_TYPE.CHAT_REPLIED,
        name: 'スカウトメール返信有',
        ...users?.chatReplied
      }
    ];
  }, [
    scoutMatchedUsersData,
    scoutMatchedUsersLoading,
    scoutCommentsData,
    newScoutComments,
    newBookmarkUserIds,
    newUnBookmarkUserIds
  ]);

  /**
   * スカウト詳細情報取得
   */
  useEffect(() => {
    if (!scoutId) return;
    fetchScoutByIdQuery({ variables: { id: scoutId } });
  }, [scoutId]);

  /**
   * スカウト詳細に紐づいたマッチしたユーザー郡の取得
   */
  useEffect(() => {
    if (!scoutId) return;
    fetchScoutMatchedUsers({
      variables: {
        id: scoutId,
        first: DEFAULT_VIEW_COUNT,
        offset: Number(offset) * (Number(paging) - 1),
        field: 'updated'
      }
    });
  }, [scoutId, paging, offset, updateScoutData, newBookmarkUserIds, newUnBookmarkUserIds]);

  /**
   * スカウトユーザーの申し送りメッセージの取得
   */
  useEffect(() => {
    if (scoutMatchedUsersData) {
      const { matched, matchedInBookmarks, chatSent, chatReplied } = scoutMatchedUsersData
        ?.loginSession?.user?.organization?.scout?.users ?? {
        matched: { results: [] },
        matchedInBookmarks: { results: [] },
        chatSent: { results: [] },
        chatReplied: { results: [] }
      };
      const usersIds = [
        ...matched.results,
        ...matchedInBookmarks.results,
        ...chatSent.results,
        ...chatReplied.results
      ].map((user) => user.id);
      fetchScoutCommentsQuery({
        variables: { userIds: usersIds }
      });
    }
  }, [scoutMatchedUsersData]);

  /**
   * スカウト詳細フェッチエラー
   */
  useEffect(() => {
    if (!loading && error) {
      showErrorPopup('スカウト情報の読み込みに失敗しました。');
    }
  }, [loading, error]);

  /**
   * スカウトユーザー申し送り事項フェッチエラー
   */
  useEffect(() => {
    if (!scoutCommentsLoading && scoutCommentsError) {
      showErrorPopup('スカウト情報の読み込みに失敗しました。');
    }
  }, [scoutCommentsLoading, scoutCommentsError]);

  /**
   * スカウトメッセージを送った結果
   */
  useEffect(() => {
    // error
    if (!mutateScoutCommentLoading && !mutateScoutCommentData && error) {
      showErrorPopup('コメントを追加できませんでした');
      // success
    } else if (
      !mutateScoutCommentLoading &&
      mutateScoutCommentData?.addScoutComment?.scoutComment
    ) {
      showSuccessPopup('コメントを追加しました');
      setNewScoutComments((prevComments) => [
        ...prevComments,
        mutateScoutCommentData.addScoutComment.scoutComment as ScoutComment
      ]);
    }
  }, [
    mutateScoutCommentData,
    mutateScoutCommentLoading,
    mutateScoutCommentError,
    setNewScoutComments
  ]);

  /**
   * スカウト作成した結果
   */
  useEffect(() => {
    if (!createScoutLoading && createScoutError) {
      showErrorPopup('スカウトを作成できませんでした');
    } else if (!createScoutLoading && createScoutData?.createScout?.scout) {
      showSuccessPopup('スカウトを作成しました');
    }
  }, [createScoutLoading, createScoutError]);

  /**
   * スカウト編集した結果
   */
  useEffect(() => {
    if (!updateScoutLoading && updateScoutError) {
      showErrorPopup('スカウトを編集できませんでした');
    } else if (!updateScoutLoading && updateScoutData?.updateScout?.scout) {
      showSuccessPopup('スカウトを編集しました');
    }
  }, [updateScoutLoading, updateScoutError]);

  /**
   * スカウトを削除した結果
   */
  useEffect(() => {
    if (!deleteScoutLoading && deleteScoutError) {
      showErrorPopup('スカウトを削除できませんでした');
    } else if (!deleteScoutLoading && deleteScoutData?.deleteScout?.id) {
      showSuccessPopup('スカウトを削除しました');
      history.replace('/enterprise/scout');
    }
  }, [deleteScoutLoading, deleteScoutError]);

  return {
    loading,
    paging,
    setPaging,
    offset,
    scoutDetail,
    matchedUsers,
    currentScoutMatchedType,
    setCurrentScoutMatchedType,
    mutateScoutComment,
    mutateCreateScout,
    createScoutData,
    createScoutLoading,
    mutateUpdateScout,
    updateScoutData,
    updateScoutLoading,
    mutateDeleteScout,
    deleteScoutData,
    deleteScoutLoading,
    scoutMatchedUsersLoading,
    toggleBookmark,
    bookmarkLoading
  };
};
export default useScoutDetail;
