import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';

type TabType = {
  name: string;
  type: string;
  count: number;
};

type Props = {
  setCurrent?: Function;
  tab: Array<TabType>;
  active?: string;
};

/**
 * ScoutTab
 */
export const ScoutTab: React.FC<Props> = (props) => {
  return (
    <StyledScoutTabList>
      {props.tab.map((item, index) => (
        <StyledScoutTabListContent
          isActive={props.active === item.type}
          key={index}
          onClick={() => {
            if (props.setCurrent && props.active !== item.type) props.setCurrent(item.type);
          }}
        >
          {item.name}
          <StyledScoutTabItemBottom>
            {item.count}
            <StyledScoutTabItemBottomUnit>人</StyledScoutTabItemBottomUnit>
          </StyledScoutTabItemBottom>
        </StyledScoutTabListContent>
      ))}
    </StyledScoutTabList>
  );
};

const StyledScoutTabList = styled.ul`
  display: flex;
  width: 100%;
  margin: 25px auto 0;
  padding: 0;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  text-align: center;
`;

const StyledScoutTabListContent = styled.li<{ isActive: boolean }>`
  display: block;
  width: calc(100% / 4);
  padding: 13px 30px;
  font-size: 12px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  line-height: 30px;
  border-left: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  cursor: pointer;
  &:last-of-type {
    border-right: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
  ${({ isActive }) =>
    !!isActive
      ? `display: block;
  position: relative;
  margin: 0 auto;
  background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  color: ${COLOR_DEFINITIONS.TEXT.WHITE};
  &::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translate(-50%, 100%);
    width: 0;
    height: 0;
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }`
      : ``}
`;

const StyledScoutTabItemBottom = styled.span`
  display: block;
  font-size: 32px;
`;

const StyledScoutTabItemBottomUnit = styled.span`
  display: inline-block;
  font-size: 20px;
`;
