import gql from 'graphql-tag';

export const SEND_CHAT_MESSAGE = gql`
  mutation($chatRoomId: ID!, $message: String!) {
    sendChatMessage(input: { chatRoomId: $chatRoomId, message: $message }) {
      ok
    }
  }
`;

export const CREATE_SCOUT_CHAT_ROOM_MUTATION = gql`
  mutation($userId: ID!) {
    createScoutChatRoom(input: { userId: $userId }) {
      ok
      roomId
    }
  }
`;

export const UPDATE_SCOUT_CHAT_ROOM_MUTATION = gql`
  mutation($chatRoomId: ID!, $scoutId: ID) {
    updateScoutChatRoom(input: { chatRoomId: $chatRoomId, scoutId: $scoutId }) {
      ok
    }
  }
`;

export const FETCH_USER_PROFILE_IMAGE = gql`
  query($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      profileImage {
        url
      }
    }
  }
`;

export const RATE_SCOUTEE_MUTATION = gql`
  mutation($userId: ID!, $newRating: Int!) {
    rateScoutee(input: { userId: $userId, newRating: $newRating }) {
      ok
    }
  }
`;
