import { AppRoute } from '@meettry/ui-components/src/types/route';

/**
 * Customer
 */
import Top from '@meettry/engineer/components/pages/Top';
import UserPage from '@meettry/engineer/components/pages/UserPage';
import LoginPage from '@meettry/engineer/components/pages/LoginPage';
import ForgotPassPage from '@meettry/engineer/components/pages/ForgotPassPage';
import SignUpPage from '@meettry/engineer/components/pages/SignUpPage';
import SearchResult from '@meettry/engineer/components/pages/SearchResult';
import Privacy from '@meettry/engineer/components/pages/Privacy';
import Terms from '@meettry/engineer/components/pages/Terms';
import Tokutei from '@meettry/engineer/components/pages/Tokutei';
import Contact from '@meettry/engineer/components/pages/Contact';
import NotFoundPage from '@meettry/engineer/components/pages/NotFound';
import EditProfilePage from '@meettry/engineer/components/pages/EditProfilePage';
import ChangeEmailPage from '@meettry/engineer/components/pages/ChangeEmailPage';
import ChangePasswordPage from '@meettry/engineer/components/pages/ChangePasswordPage';
import ActionPage from '@meettry/engineer/components/pages/ActionPage';
import ChatPage from '@meettry/engineer/components/pages/Chat';

/**
 * Enterprise
 */
import EnterpriseTop from '@meettry/company/component/pages/EnterpriseTop';
import EnterpriseRegister from '@meettry/ui-components/components/pages/register';
import EnterpriseSettingCompany from '@meettry/engineer/components/pages/EnterpriseSettingCompany';
import EnterpriseSettingPayment from '@meettry/engineer/components/pages/EnterpriseSettingPayment';
import EnterpriseSettingPlan from '@meettry/company/component/pages/EnterpriseSettingPlan';
import EnterpriseSignup from '@meettry/engineer/components/pages/EnterpriseSignup';
import EnterpriseBase from '@meettry/engineer/components/pages/EnterpriseBase';
import EnterpriseDashboard from '@meettry/engineer/components/pages/EnterpriseDashboard';
import EnterpriseSearch from '@meettry/engineer/components/pages/EnterpriseSearch';
import EnterpriseScout from '@meettry/engineer/components/pages/EnterpriseScout';
import EnterpriseChat from '@meettry/engineer/components/pages/EnterpriseChat';
import EnterpriseScoutDetail from '@meettry/engineer/components/pages/EnterpriseScoutDetail';
import EnterpriseScoutBookmark from '@meettry/engineer/components/pages/EnterpriseScoutBookmark/idnex';

/**
 * 認証なしルーティング
 */
export const publicRoutes = (): AppRoute[] => [
  {
    name: 'SignUp',
    path: '/signup',
    exact: false,
    public: true,
    auth: true,
    allow: true,
    component: SignUpPage
  },
  { name: 'Top', path: '/', exact: true, public: true, auth: true, allow: true, component: Top },
  {
    name: 'EnterpriseTop',
    path: '/enterprise',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: EnterpriseTop
  },
  {
    name: 'EnterpriseSignupAdmin',
    path: '/enterprise/signup',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: EnterpriseSignup
  },
  {
    name: 'EnterpriseSignupMember',
    path: '/enterprise/signup/member',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: EnterpriseSignup
  },
  {
    name: 'EnterpriseContact',
    path: '/enterprise/contact',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: EnterpriseRegister
  },
  {
    name: 'Login',
    path: '/login',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: LoginPage
  },
  {
    name: 'ForgotPassword',
    path: '/forgot_pass',
    exact: false,
    public: true,
    auth: true,
    allow: true,
    component: ForgotPassPage
  },
  {
    name: 'Privacy',
    path: '/privacy',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: Privacy
  },
  {
    name: 'Terms',
    path: '/terms',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: Terms
  },
  {
    name: 'Action',
    path: '/action',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: ActionPage
  },
  {
    name: 'Contact',
    path: '/contact',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: Contact
  },
  {
    name: 'Tokutei',
    path: '/tokutei',
    exact: true,
    public: true,
    auth: true,
    allow: true,
    component: Tokutei
  }
];

/**
 * Eドメ・Cドメ共通認証ありルーティング
 */
export const privateRoutes = (auth: boolean): AppRoute[] => [
  {
    name: 'Profile',
    path: `/profile`,
    exact: true,
    public: false,
    auth,
    allow: true,
    component: EditProfilePage
  },
  {
    name: 'Chat',
    path: '/chat',
    exact: true,
    public: false,
    auth,
    allow: true,
    component: ChatPage
  },
  {
    name: 'ChatDetail',
    path: '/chat/:roomId',
    exact: false,
    public: false,
    auth,
    allow: true,
    component: ChatPage
  },
  {
    name: 'Search',
    path: '/search',
    exact: true,
    public: false,
    auth,
    allow: true,
    component: SearchResult
  },
  {
    name: 'User',
    path: '/user/:nickname',
    exact: false,
    public: false,
    auth,
    allow: true,
    component: UserPage
  },
  {
    name: 'ChangeEmail',
    path: '/change-email',
    exact: true,
    public: false,
    auth,
    allow: true,
    component: ChangeEmailPage
  },
  {
    name: 'ChangePassword',
    path: '/change-password',
    exact: true,
    public: false,
    auth,
    allow: true,
    component: ChangePasswordPage
  }
];

/**
 * Eドメのみ認証ありルーティング
 */
export const privateEnterpriseRoutes = (auth: boolean, isEnterprise: boolean): AppRoute[] => [
  {
    name: 'EnterpriseSearch',
    exact: true,
    path: '/enterprise/search',
    public: false,
    auth,
    allow: isEnterprise,
    component: EnterpriseSearch
  },
  {
    name: 'EnterpriseUser',
    path: '/enterprise',
    exact: false,
    public: false,
    auth,
    allow: isEnterprise,
    component: EnterpriseBase,
    childRoute: [
      {
        name: 'EnterpriseDashboard',
        exact: true,
        path: '/dashboard',
        public: false,
        auth,
        allow: isEnterprise,
        // MEMO(oomura): 企業β版リリース時にはダッシュボードは表示しない
        // component: EnterpriseDashboard
        component: EnterpriseScout
      },
      {
        name: 'EnterpriseScout',
        exact: true,
        path: '/scout',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseScout
      },
      {
        name: 'EnterpriseScoutBookmark',
        exact: true,
        path: '/scout/bookmark',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseScoutBookmark
      },
      {
        name: 'EnterpriseScoutDetail',
        exact: false,
        path: '/scout/:scoutId',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseScoutDetail
      },
      {
        name: 'EnterpriseChat',
        exact: true,
        path: '/chat',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseChat
      },
      {
        name: 'EnterpriseChatDetail',
        exact: false,
        path: '/chat/:roomId',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseChat
      },
      {
        name: 'EnterpriseSettingCompany',
        exact: true,
        path: '/settings/company',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseSettingCompany
      },
      {
        name: 'EnterpriseSettingPayment',
        exact: true,
        path: '/settings/payment',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseSettingPayment
      },
      {
        name: 'EnterpriseSettingPlan',
        exact: true,
        path: '/settings/plan',
        public: false,
        auth,
        allow: isEnterprise,
        component: EnterpriseSettingPlan
      }
    ]
  }
];

/**
 * 正常系以外のルーティング
 */
export const ErrorRoutes = (): AppRoute[] => [
  {
    name: 'NotFound',
    path: '',
    exact: false,
    public: true,
    auth: true,
    allow: true,
    component: NotFoundPage
  }
];

export const appRoutes = (auth: boolean, isEnterprise: boolean): AppRoute[] => {
  return [
    ...publicRoutes(),
    ...privateRoutes(auth),
    ...privateEnterpriseRoutes(auth, isEnterprise),
    ...ErrorRoutes()
  ];
};
