import gql from 'graphql-tag';

// challenge
export const FRAG_CHALLENGE_LIST_ITEM = gql`
  fragment ChallengeListItem on Challenge {
    id
    title
    progressRate
    challengeDetail
    isPinned
    isVisible
    updated
    created
    tags {
      id
      name
      displayName
      category
      techStackId
      relations {
        relatedTagId
        weight
      }
    }
    challengeLogList {
      id
      created
      updated
      challengeId
      beforeProgressRate
      afterProgressRate
      comment
    }
  }
`;

export const FRAG_CHALLENGE_LIST = gql`
  fragment ChallengeList on ChallengeList {
    items {
      ...ChallengeListItem
    }
    count
  }

  ${FRAG_CHALLENGE_LIST_ITEM}
`;

// resume
export const FRAG_RESUME_LIST_ITEM = gql`
  fragment ResumeListItem on Resume {
    id
    title
    companyName
    workStartDate
    workEndDate
    workDetail
    isPinned
    isVisible
    created
    updated
    tags {
      id
      name
      displayName
      category
      techStackId
      relations {
        relatedTagId
        weight
      }
    }
  }
`;

export const FRAG_RESUME_LIST = gql`
  fragment ResumeList on ResumeList {
    items {
      ...ResumeListItem
    }
    count
  }

  ${FRAG_RESUME_LIST_ITEM}
`;

// tags
export const FRAG_ENGINEER_TAGS_DATA = gql`
  fragment EngineerTagsData on User {
    id
    userTechStacks {
      techStack {
        id
        created
        updated
        name
        image {
          url
        }
        categoryId
        category {
          name
          color
        }
        tags {
          id
          name
          displayName
          category
          techStackId
        }
      }
      days
    }
  }
`;

export const FRAG_USER_TAG = gql`
  fragment UserTag on UserTag {
    userId
    tagId
    days
    tag {
      id
      name
      displayName
      category
      techStackId
    }
    resumes {
      workEndDate
    }
  }
`;
