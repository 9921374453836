import React from 'react';
import styled from 'styled-components';
import { TextField } from '@meettry/engineer/components/atoms/Input';

export const InputNameRuby = (props) => {
  const { userDetail, onChangeFirstName, onChangeLastName } = props;
  return (
    <StyledInputName>
      <StyledInputItem>
        <StyledInputItemLabel>セイ</StyledInputItemLabel>
        <TextField
          defaultValue={userDetail.lastNameKana}
          onChange={onChangeLastName}
          placeholder="ヤマダ"
        />
      </StyledInputItem>
      <StyledInputItem>
        <StyledInputItemLabel>メイ</StyledInputItemLabel>
        <TextField
          defaultValue={userDetail.firstNameKana}
          onChange={onChangeFirstName}
          placeholder="タロウ"
        />
      </StyledInputItem>
    </StyledInputName>
  );
};

const StyledInputName = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

const StyledInputItem = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

const StyledInputItemLabel = styled.p`
  min-width: 34px;
`;
