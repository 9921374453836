import React from 'react';
import * as d3 from 'd3';
import PropTypes from 'prop-types';
import '@meettry/ui-components/styles/bubblechart.css';

const STRENGTH = 0.08;
const CONTAINER_PADDING = 1.5;
const ANIMATION_TIME = 1000;
const ALPHA_FOR_HEAT_FORCE = 0.2;

const forceCollide = () => d3.forceCollide((d) => d.r + 1);

const dragNode = (simulation) =>
  d3
    .drag()
    .on('start', (d) => {
      if (!d3.event.active) {
        simulation.alphaTarget(ALPHA_FOR_HEAT_FORCE).restart();
      }
      d.fx = d.x;
      d.fy = d.y;
    })
    .on('drag', (d) => {
      d.fx = d3.event.x;
      d.fy = d3.event.y;
    })
    .on('end', (d) => {
      if (!d3.event.active) {
        simulation.velocityDecay(0.1).alphaTarget(0);
      }
      d.fx = null;
      d.fy = null;
    });

export default class BubbleChart extends React.Component {
  static propTypes = {
    stacks: PropTypes.objectOf(PropTypes.any).isRequired
  };

  constructor(props) {
    super(props);
    const { stacks } = props;
    this.state = {
      stacks
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    if (prevState.stacks === nextProps.stacks) {
      return { stacks: nextProps.stacks };
    }

    return null;
  }

  componentDidMount() {
    this.init();
  }

  componentDidUpdate() {
    const { stacks: propStacks } = this.props;
    const { stacks: stateStacks } = this.state;
    if (propStacks !== stateStacks) {
      this.init();
      this.setState({ stacks: propStacks });
    }
  }

  componentWillUnmount() {
    if (this.nodeContainer) {
      this.nodeContainer.on('click', null);
    }
    d3.select(document).on('click', null);
  }

  init() {
    d3.selectAll('.node').remove();
    const { stacks } = this.props;

    // set properties
    const container = d3.select('#jsi-bubble-chart-container');
    this.width = container.property('clientWidth');
    this.height = container.property('clientHeight');
    this.focusedNode = null;
    if (this.height <= 0 || this.width <= 0) {
      return;
    }

    // set up node data
    const root = d3.hierarchy({ children: stacks.data }).sum((d) => d.radius);
    const pack = d3.pack().size([this.width, this.height]).padding(CONTAINER_PADDING);

    //MEMO(aida) バブル生成箇所
    const nodes = pack(root)
      .leaves()
      .map((node) => {
        const { data } = node;
        const category = stacks.categories[data.categoryId];
        return {
          x: this.width / 2 + (node.x - this.width / 2) * 3,
          y: this.height / 2 + (node.y - this.height / 2) * 3,
          r: 0,
          radius: node.r,
          name: data.name,
          category: category && category.name,
          color: category && category.color,
          image: {
            url: node.data.image?.url ?? null
          }
        };
      });

    // set up force environment
    this.simulation = d3
      .forceSimulation()
      .force('charge', d3.forceManyBody())
      .force('collide', forceCollide())
      .force('x', d3.forceX(this.width / 2).strength(STRENGTH))
      .force('y', d3.forceY(this.height / 2).strength(STRENGTH));

    this.simulation.nodes(nodes).on('tick', this.progressTicks.bind(this));

    // create node container
    this.nodeContainer = container
      .selectAll('.node')
      .data(nodes)
      .enter()
      .append('div')
      .classed('node', true)
      .call(dragNode(this.simulation));

    this.nodeContainer
      .style('background-color', (d) => d.color)
      .transition()
      .duration(ANIMATION_TIME)
      .ease(d3.easeElasticOut)
      .tween('circleIn', (d) => {
        const i = d3.interpolateNumber(0, d.radius);
        return (t) => {
          d.r = i(t);
          this.simulation.force('collide', forceCollide());
        };
      });

    // construct node elements
    this.nodeContainer
      .filter((d) => d.image.url)
      .append('img')
      .classed('node-icon', true)
      .attr('src', (d) => d.image.url)
      .attr('title', (d) => d.name)
      .on('error', function () {
        d3.select(this).style('visibility', 'hidden');
      });

    this.nodeContainer
      .filter((d) => !d.image.url)
      .append('div')
      .text((d) => d.name);

    // bind events
    this.nodeContainer.on('click', this.selectNode.bind(this));
  }

  progressTicks() {
    this.nodeContainer
      .style('transform', (d) => `translate(${d.x - d.r}px, ${d.y - d.r}px)`)
      .style('width', (d) => `${d.r * 2}px`)
      .style('height', (d) => `${d.r * 2}px`)
      .style('border-radius', (d) => `${d.r}px`);
  }

  selectNode(currentNode) {
    const { onClickNode } = this.props;
    typeof onClickNode === 'function' && onClickNode(currentNode.name);
    d3.event.stopPropagation();
    if (currentNode === this.focusedNode) {
      this.shrinkNode();
      return;
    }
    const lastNode = this.focusedNode;
    this.simulation.alphaTarget(ALPHA_FOR_HEAT_FORCE).restart();
    if (lastNode) {
      this.shrinkNode(lastNode);
    }
    this.growNode(currentNode);
  }

  // 要素を大きくする処理
  growNode(currentNode) {
    this.focusedNode = currentNode;

    this.nodeContainer
      .filter((d, i) => i === currentNode.index)
      .transition()
      .duration(ANIMATION_TIME)
      .ease(d3.easePolyOut)
      .tween('moveIn', () => {
        //大きさを変更
        const ir = d3.interpolateNumber(currentNode.r, Math.min(this.width, this.height) / 4);

        return (t) => {
          currentNode.r = ir(t);
          this.simulation.force('collide', forceCollide());
        };
      });
  }

  // 要素を縮める処理
  shrinkNode(targetNode) {
    const relation = !!targetNode;
    if (!relation) {
      targetNode = this.focusedNode;
    }
    if (!targetNode) {
      return;
    }
    targetNode.fx = null;
    targetNode.fy = null;
    this.simulation.alphaTarget(ALPHA_FOR_HEAT_FORCE).restart();
    this.nodeContainer
      .filter((d, i) => i === targetNode.index)
      .transition()
      .duration(ANIMATION_TIME)
      .ease(d3.easePoly)
      .tween('moveOut', () => {
        const ir = d3.interpolateNumber(targetNode.r, targetNode.radius);
        return (t) => {
          targetNode.r = ir(t);
          this.simulation.force('collide', forceCollide());
        };
      })
      .on('end', () => {
        this.simulation.velocityDecay(0.1).alphaTarget(0);
        if (!relation) {
          this.focusedNode = null;
        }
      });
  }

  render() {
    return <div id="jsi-bubble-chart-container" className="bubble-chart-container" />;
  }
}
