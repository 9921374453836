import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
// import { useQuery, useMutation } from '@apollo/react-hooks';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
// import usePopup from '@meettry/ui-components/hooks/usePopup';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import { WorkStylesType } from '@meettry/ui-components/types/userPage';
import UserProfileAvatar from '@meettry/ui-components/components/organisms/UserPage/Common/UserProfileAvatar';
import UserProfileButtonList from '@meettry/ui-components/components/organisms/UserPage/Common/UserProfile/UserProfileButtonList';
import FormatNum, {
  FORMAT_COMMA_NUMBER
} from '@meettry/ui-components/components/atoms/Text/FormatNum';
import SetIconText from '@meettry/ui-components/components/atoms/Text/SetIconText';
import { SelectBox } from '@meettry/ui-components/components/atoms/Input';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { getDaysBetween } from '@meettry/ui-components/utils';
import { JOB_SEEKING_LEVEL_LIST } from '@meettry/ui-components/constants';
// import { CHECKBOX } from '@meettry/engineer/utils/Constant';
import { EditableListItemContainer } from '@meettry/ui-components/components/molecules/EditableListItem';
// import {
//   GET_PROFILE_EDIT_PAGE_DATA,
//   SET_USER_PROFILE
// } from '@meettry/engineer/graphql/queries/user_profile_edit_page';
/* MEMO（パク）：正式版時、表示する */
// import SetIconTextNum from '@meettry/engineer/components/molecules/Set/SetIconTextNum';
// import Counter from '@meettry/engineer/components/atoms/texts/Counter';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

//TODO(aida) プロフィール更新系の機能をコメントアウト

type BioInputType = {
  workStyles: Array<WorkStylesType>;
  bio: string | null;
};

const initBioInput = {
  workStyles: [],
  bio: ''
};

const UserProfileTop = () => {
  const history = useHistory();
  const {
    user: { organizationId }
  } = useLoginUser();
  const { state, isOwner, updateBio } = useEngineer();
  const { showProfileEditConfirmationDialog, closeDialog } = useDialog();
  // const { showSuccessPopup, showErrorPopup } = usePopup();
  const { startLoading, endLoading } = useLoading();
  const {
    id,
    nickname,
    lastUpdated,
    bio,
    location,
    url,
    points,
    scoutCount,
    workStyles,
    userDetail
  } = state;
  // const [workStyleList, setWorkStyleList] = useState([]);
  const [dirty, setDirty] = useState(false);
  const [userInput, setUserInput] = useState<BioInputType>(initBioInput);
  const initUserDetailInputState = { jobSeekingLevel: userDetail.jobSeekingLevel };
  const [userDetailInput, setUserDetailInput] = useState(initUserDetailInputState);

  const jobSeekingLevel = JOB_SEEKING_LEVEL_LIST.find(
    (val) => val.value === userDetailInput.jobSeekingLevel
  );

  // const { data, loading } = useQuery(GET_PROFILE_EDIT_PAGE_DATA);
  // const [doMutation, { error: setUserError }] = useMutation(SET_USER_PROFILE, {
  //   onCompleted: (data) => {
  //     const { setUserProfile } = data;
  //     endLoading();
  //     if (!setUserProfile) return;
  //     const { user, userDetail } = setUserProfile;
  //     const { bio, lastUpdated } = user;
  //     const { jobSeekingLevel } = userDetail;
  //     console.log('res set profile', user, userDetail);
  //     showSuccessPopup('プロフィール情報を更新しました');
  //     updateBio({ bio, lastUpdated, userDetail: { jobSeekingLevel } });
  //   }
  // });

  //MEMO(aida) プロフィール更新時のgraphqlエラーのハンドリング
  // useEffect(() => {
  //   const isError = setUserError;
  //   if (!isError) return;
  //   endLoading();
  //   if (setUserError) showErrorPopup('プロフィール情報を更新できませんでした');
  // }, [setUserError]);

  // useEffect(() => {
  //   if (!loading && data) {
  //     const {
  //       loginSession: {
  //         user: { bio, location, url },
  //         userDetail: {
  //           firstName,
  //           lastName,
  //           firstNameKana,
  //           lastNameKana,
  //           phone,
  //           sex,
  //           birthData,
  //           postCode,
  //           address,
  //           jobSeekingLevel,
  //           enableAcceptingScount,
  //           enableScountChatNotification
  //         }
  //       },
  //       workStyles
  //     } = data;
  //     setUserInput({ ...userInput, bio, location, url });
  //     //MEMO(aida)　ユーザ情報更新に更新対象外の情報も必要なためセットする
  //     setUserDetailInput({
  //       ...userDetailInput,
  //       firstName,
  //       lastName,
  //       firstNameKana,
  //       lastNameKana,
  //       phone,
  //       sex,
  //       birthData,
  //       postCode,
  //       address,
  //       jobSeekingLevel,
  //       enableAcceptingScount,
  //       enableScountChatNotification
  //     });
  //     setWorkStyleList(workStyles);
  //   }
  // }, [data, loading]);

  // const updateProfile = () => {
  //   const userDetail = userDetailInput;
  //   const user = userInput;
  //   doMutation({ variables: { input: { userDetail, user } } });
  // };

  const onClickOutside = () => {
    showProfileEditConfirmationDialog(
      ['プロフィール情報を変更してもよろしいでしょうか？'],
      () => {
        closeDialog();
        // updateProfile();
        startLoading('プロフィールを更新中...');
      },
      () => {
        closeDialog();
        setUserDetailInput(initUserDetailInputState);
        setUserInput({ bio, workStyles });
        setDirty(false);
      }
    );
  };

  const onClickChatHandler = () => {
    history.push({
      pathname: `/enterprise/chat`,
      state: { userId: id, nickname }
    });
  };

  const handleChangeUserDetail = (name: string) => (e: React.ChangeEvent<HTMLSelectElement>) => {
    setDirty(true);
    let value: string | number = e.target.value;
    if (name === 'jobSeekingLevel') {
      value = Number(e.target.value);
    }
    setUserDetailInput({ ...userDetailInput, [name]: value });
  };

  // const handleChangeUser = (name: string) => (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
  //   setDirty(true);
  //   if (name === 'workStyles') {
  //     const isIncluded = userInput.workStyles.includes({ id: e.target.value });
  //     setUserInput({
  //       ...userInput,
  //       [name]: isIncluded
  //         ? userInput.workStyles.filter((val) => val !== e.target.value)
  //         : [...userInput.workStyles, e.target.value]
  //     });
  //     return;
  //   }
  //   setUserInput({ ...userInput, [name]: e.target.value });
  // };

  return (
    <>
      <StyledUserTopGrid>
        <UserProfileAvatar />
        {nickname && <StyledUserName>{nickname}</StyledUserName>}
        {isOwner && (
          <StyledUserPoint>
            <FormatNum type={FORMAT_COMMA_NUMBER}>{points !== null ? points.total : 0}</FormatNum>pt
          </StyledUserPoint>
        )}
        {isOwner && (
          <StyledScout>
            スカウト数<FormatNum type={FORMAT_COMMA_NUMBER}>{scoutCount}</FormatNum>
          </StyledScout>
        )}
      </StyledUserTopGrid>
      {/* MEMO（パク）：正式版時、表示する */}
      {/* <StyledItemContainer>
        {totalView && (
          <Counter>
            <SetIconTextNum totalNum={totalView} imgName={'view_gray'} type={'comma'} />
          </Counter>
        )}
        <Counter>
          <SetIconTextNum totalNum={0} imgName={'like_gray'} type={'comma'} />
        </Counter>
      </StyledItemContainer> */}
      {lastUpdated && (
        <StyledUserUpdate>
          <SetIconText iconName="time" iconColor="gray" fontSize="12px">
            Updated {lastUpdated ? getDaysBetween(lastUpdated) : ''}
          </SetIconText>
        </StyledUserUpdate>
      )}
      {typeof id === 'string' && typeof nickname === 'string' ? (
        <StyledListContainer>
          <UserProfileButtonList
            id={id}
            nickname={nickname}
            isOwner={isOwner}
            onClickChatHandler={onClickChatHandler}
            isLoginUserCompany={!!organizationId}
          />
        </StyledListContainer>
      ) : null}

      <EditableListItemContainer
        isOwner={false}
        onClickOutside={onClickOutside}
        EditComponent={
          <StyledLabelForm>
            <StyledLabel>稼働状況</StyledLabel>
            <StyledContent>
              <SelectBox
                onChange={handleChangeUserDetail('jobSeekingLevel')}
                selectedValue={userDetailInput.jobSeekingLevel.toString()}
                placeholder="選択"
                values={JOB_SEEKING_LEVEL_LIST}
              />
            </StyledContent>
          </StyledLabelForm>
        }
        DisplayComponent={
          <StyledLabelForm>
            <StyledLabel>稼働状況</StyledLabel>
            {jobSeekingLevel ? <StyledContent>{jobSeekingLevel.content}</StyledContent> : null}
          </StyledLabelForm>
        }
      />

      {!!workStyles.length && (
        <EditableListItemContainer
          isOwner={false}
          onClickOutside={onClickOutside}
          EditComponent={
            <StyledProfileSection>
              {/* <div>
                <StyledLabel>希望の働き方</StyledLabel>
                <div>
                  {workStyleList.map(({ id, name }) => (
                    <CheckBox
                      key={id}
                      name="work-style"
                      id={id}
                      label={name}
                      value={id}
                      size={CHECKBOX.SIZE.SMALL}
                      onChange={handleChangeUser('workStyles')}
                      checked={userInput.workStyles.includes({ id })}
                    />
                  ))}
                </div>
              </div> */}
            </StyledProfileSection>
          }
          DisplayComponent={
            <StyledProfileSection>
              <StyledLabelForm>
                <StyledLabel>希望の働き方</StyledLabel>
                <StyledContent>
                  {workStyles.map((val: WorkStylesType) => val.name).join(' / ')}
                </StyledContent>
              </StyledLabelForm>
            </StyledProfileSection>
          }
        />
      )}

      {bio && (
        <EditableListItemContainer
          onClickOutside={onClickOutside}
          isOwner={false}
          EditComponent={
            <StyledProfileSection>
              {/* <Textarea
                onChange={handleChangeUser('bio')}
                defaultValue={bio}
                placeholder="某メガベンチャー企業にてサーバーサイドを4年ほど経験。フロントエンドに興味があり、フルスタックを目標にしています。来年までにサービスを一つリリースする事にチャレンジ中！"
              /> */}
            </StyledProfileSection>
          }
          DisplayComponent={
            <StyledProfileSection>
              <StyledBio>{dirty ? userInput.bio : bio}</StyledBio>
            </StyledProfileSection>
          }
        />
      )}

      <StyledListContainer>
        {location && (
          <StyledList>
            <SetIconText iconName="map" iconColor="gray" fontSize="12px">
              {location}
            </SetIconText>
          </StyledList>
        )}
        {/* MEMO（パク）：正式版時、表示する */}
        {/* {url && (
          <StyledList>
            <SetIconText imgName={'link_gray'} fontSize={'12px'}>
              <StyledBioLink target="_blank" rel="noopener" href={url}>
                {url}
              </StyledBioLink>
            </SetIconText>
          </StyledList>
        )} */}
      </StyledListContainer>
    </>
  );
};

export default UserProfileTop;

const StyledUserTopGrid = styled.div`
  text-align: center;
`;

const StyledUserName = styled.p`
  margin-top: 20px;
  font-size: 24px;
  font-weight: bold;
  word-break: break-all;
`;

const StyledUserPoint = styled.p`
  font-size: 18px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  margin-top: 15px;
`;

const StyledScout = styled.p`
  font-size: 10px;
  margin-top: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  & > span {
    margin-left: 5px;
  }
`;
/* MEMO（パク）：正式版時、表示する */
// const StyledItemContainer = styled.div`
//     margin-top: 20px;
//     word-break: break-all;
//     display: flex;
//     justify-content: center;
//     & > * + * {
//       margin-left: 10px;
//     }
// `;

const StyledUserUpdate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  margin-top: 10px;
`;

const StyledListContainer = styled.ul`
  margin-top: 20px;
  & > * + * {
    margin-top: 10px;
  }
`;
const StyledLabelForm = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 20px;
`;

const StyledLabel = styled.p`
  width: 30%;
  font-size: 10px;
  line-height: 1.75;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
`;

const StyledContent = styled.div`
  width: 70%;
  font-size: 12px;
  line-height: 1.75;
`;

const StyledProfileSection = styled.div`
  /* height: 35px; */
  margin-top: 20px;
  word-break: break-all;
`;

const StyledBio = styled.p`
  font-size: 14px;
  white-space: pre-wrap;
  line-height: 1.75;
`;

const StyledList = styled.li`
  display: flex;
  align-items: center;
  word-break: break-all;
`;

/* MEMO（パク）：正式版時、表示する */
// const StyledBioLink = styled.a`
//   color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
// `;
