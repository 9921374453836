import { useContext, useMemo } from 'react';
import {
  EngineerContext,
  setEngineer,
  setUserTags,
  setTagList
} from '@meettry/ui-components/contexts/engineer';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { EngineerType, UserTagsType, TagType } from '@meettry/ui-components/types/userPage';

export const useEngineer = () => {
  const { state, dispatch } = useContext(EngineerContext);
  const { user: loginUser } = useLoginUser();

  const storeEngineer = (engineer: EngineerType) => {
    dispatch(setEngineer(engineer));
  };

  const storeEngineerUserTags = (userTags: Array<UserTagsType>) => {
    dispatch(setUserTags(userTags));
  };

  const storeTagList = (tagList: Array<TagType>) => {
    dispatch(setTagList(tagList));
  };

  const updateBio = (updatedInfo: EngineerType) => {
    dispatch(setEngineer({ ...state, ...updatedInfo }));
  };

  const isOwner = useMemo(() => {
    return loginUser.nickname === state.nickname;
  }, [loginUser.nickname, state.nickname]);

  return {
    state,
    storeEngineer,
    storeEngineerUserTags,
    storeTagList,
    //MEMO(aida) bio更新用の関数
    updateBio,
    // MEMO(aida) 共通して使用する値
    isOwner
  };
};

export default useEngineer;
