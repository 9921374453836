import React, { useState } from 'react';
import styled from 'styled-components';
import { ScoutUser, ScoutUserWithComment } from '@meettry/ui-components/types/scout';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import Icon, { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import {
  PrimaryIconButton,
  SecondaryIconButton,
  TertiaryIconButton
} from '@meettry/ui-components/components/atoms/Button/IconButton';
import defaultProfile from '@meettry/ui-components/images/avatar/icon_default_avatar.png';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { formatDate } from '@meettry/ui-components/utils';
import { useHistory } from 'react-router-dom';

/**
 * CassetteScoutUser Component
 */
type CassetteScoutUserProps = {
  addComment: (userId: string) => void;
  toggleBookmark: (user: { id: string; nickname: string; isBookmark: boolean }) => void;
  enabledBookmark: boolean;
  sendMessage: (scoutRoomId: string | null, userId: string, nickname: string) => void;
  buttonStyle?: any;
  scoutUser: ScoutUserWithComment;
};
const CassetteScoutUser: React.FC<CassetteScoutUserProps> = (props) => {
  const history = useHistory();

  const {
    scoutUser,
    buttonStyle,
    addComment,
    toggleBookmark,
    sendMessage,
    enabledBookmark
  } = props;
  const bookmarkIconInfo: IconPropsType = {
    iconName: 'heart',
    iconColor: scoutUser.isBookmarked ? 'gray' : 'white'
  };
  const BookmarkButton = () =>
    scoutUser.isBookmarked ? (
      <TertiaryIconButton
        size={buttonStyle?.size}
        width={buttonStyle?.width}
        iconProps={bookmarkIconInfo}
        onClick={() =>
          enabledBookmark &&
          toggleBookmark({
            id: scoutUser.id,
            nickname: scoutUser.nickname,
            isBookmark: scoutUser.isBookmarked
          })
        }
      >
        <span style={{ color: COLOR_DEFINITIONS.TEXT.SECONDARY }}>お気に入り</span>
      </TertiaryIconButton>
    ) : (
      <PrimaryIconButton
        size={buttonStyle?.size}
        width={buttonStyle?.width}
        iconProps={bookmarkIconInfo}
        onClick={() =>
          enabledBookmark &&
          toggleBookmark({
            id: scoutUser.id,
            nickname: scoutUser.nickname,
            isBookmark: scoutUser.isBookmarked
          })
        }
      >
        お気に入り
      </PrimaryIconButton>
    );

  return (
    <StyledCassetteScoutUser
      onClick={() => {
        history.push(`/user/${scoutUser.nickname}`);
      }}
    >
      <StyledUserProfile>
        <div>
          <CircleAvatar src={scoutUser?.profileImage?.url ?? defaultProfile} size="md" />
        </div>
        <div>
          <StyledCassetteUserInfo>
            <div>
              <StyledCassetteUserName>{scoutUser?.nickname}</StyledCassetteUserName>
              <StyledCassetteUserSubInfo>
                {scoutUser?.location && (
                  <StyledIconWrapper>
                    <Icon iconName="map" iconColor="gray" width="17px" />
                    {scoutUser.location}
                  </StyledIconWrapper>
                )}
              </StyledCassetteUserSubInfo>
            </div>
            <StyledCassetteButtons>
              <BookmarkButton />
              <SecondaryIconButton
                size={buttonStyle?.size}
                width={buttonStyle?.width}
                iconProps={{
                  iconName: 'chatIcon',
                  iconColor:
                    scoutUser?.userDetail?.enableAcceptingScout !== 1 ?? true ? 'gray' : 'primary'
                }}
                onClick={() =>
                  sendMessage(
                    scoutUser?.chat?.scoutRoomId ?? null,
                    scoutUser?.id,
                    scoutUser?.nickname
                  )
                }
                disabled={scoutUser?.userDetail?.enableAcceptingScout !== 1 ?? true}
              >
                メッセージ
              </SecondaryIconButton>
            </StyledCassetteButtons>
          </StyledCassetteUserInfo>
          <StyledCassetteUserDescription>{scoutUser.bio}</StyledCassetteUserDescription>
          {scoutUser.comments.map((comment) => (
            <StyledComment key={comment.id}>
              <StyledCommentTet>{comment.text}</StyledCommentTet>
              <div>
                {comment.authorUser.nickname} {formatDate(comment.updated)}
              </div>
            </StyledComment>
          ))}
        </div>
      </StyledUserProfile>
      <StyledSubAction>
        <div
          onClick={(e) => {
            e.stopPropagation();
            addComment(scoutUser?.id ?? '');
          }}
        >
          <StyledAddCommentIcon iconColor="primary" iconName="comment" fileType="png" />
          コメントを追加
        </div>
      </StyledSubAction>
    </StyledCassetteScoutUser>
  );
};

const StyledCassetteScoutUser = styled.div`
  padding-bottom: 30px;
  transition: background-color 0.2s;
  & + & {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
  &:hover {
    background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  }
`;
const StyledUserProfile = styled.div`
  display: flex;
  padding: 30px 30px 15px;
  color: inherit;
  text-decoration: none;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 130px;
    padding-right: 30px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 130px);
  }
`;

const StyledCassetteUserInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCassetteUserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;

const StyledCassetteUserSubInfo = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  & img {
    vertical-align: middle;
  }
  & > * + * {
    margin-left: 10px;
  }
`;

const StyledIconWrapper = styled.div`
  font-size: 12px;
  & > * {
    margin-right: 5px;
  }
`;

const StyledCassetteButtons = styled.div`
  display: inline-flex;
  min-width: 280px;
  height: 100%;
  white-space: nowrap;
  & > * + * {
    margin-left: 10px;
  }
`;

const StyledCassetteUserDescription = styled.p`
  font-size: 14px;
  color: #333333;
  line-height: 1.75;
  word-break: break-word;
  margin-top: 10px;
`;

const StyledSubAction = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 30px;

  & > * {
    cursor: pointer;
    font-size: 14px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    transition: opacity 0.2s;
    &:hover {
      opacity: 0.8;
    }
  }
`;

const StyledAddCommentIcon = styled(Icon)`
  display: inline-block;
  width: 12px;
  height: 13px;
  margin-right: 5px;
  vertical-align: text-top;
`;

const StyledComment = styled.div`
  & > *:first-child {
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
    border-radius: 22px;
    border-top-right-radius: 0;
    padding: 15px 20px;
    line-height: 1.6;
  }

  & > *:last-child {
    font-size: 10px;
    line-height: 1;
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
    text-align: right;
    margin-top: 8px;
  }

  & + & {
    margin-top: 15px;
  }
`;

const StyledCommentTet = styled.pre`
  white-space: pre-wrap;
  text-align: justify;
`;

/**
 * ScoutUserList Component
 */
type Props = Omit<CassetteScoutUserProps, 'scoutUser'> & {
  scoutUsers: CassetteScoutUserProps['scoutUser'][];
};
const ScoutUserList: React.FC<Props> = (props) => {
  const { scoutUsers, ...restProps } = props;

  return (
    <StyledScoutUserList>
      {scoutUsers.length > 0 ? (
        scoutUsers.map((scoutUser) => (
          <CassetteScoutUser key={scoutUser.id} scoutUser={scoutUser} {...restProps} />
        ))
      ) : (
        <StyledNoScoutUser>マッチしたユーザーはいません</StyledNoScoutUser>
      )}
    </StyledScoutUserList>
  );
};
export default ScoutUserList;

const StyledScoutUserList = styled.ol`
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledNoScoutUser = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
`;
