import React from 'react';
import UserProfileAbatar from '@meettry/company/component/organisms/userPage/UserProfile/userProfileAvatar';
import { Button } from '@meettry/company/component/atoms/btn';

const userData = {
  name: 'yoppei_lei',
  point: 12000,
  scout: 1000,
  views: 1000,
  like: 100,
  update: 10,
  status: '相談次第',
  work: ['リモートワーク', '正社員', 'フレックス', 'フルタイム'],
  profile:
    'プロフィールのテキストが入りますプロフィールのテキストが入りますプロフィールのテキストが入りますプロフィールのテキストが入りますプロフィールのテキストが入りますプロフィール',
  city: 'Tokyo',
  country: 'Japan',
  url: 'https://techtree.jp'
};

const UserProfileTop = () => {
  return (
    <React.Fragment>
      <div className="user-profile-top">
        <UserProfileAbatar />
        <p className="user-name">{userData.name}</p>
        <p className="user-point">
          {userData.point.toLocaleString()}
          <span>pt</span>
        </p>
        <p className="scout-count">
          スカウト数<span>{userData.scout}</span>
        </p>
        <div className="tag-wrap">
          <span className="number-of-views">{userData.views}</span>
          <span className="good-count">{userData.like}</span>
        </div>
        <div className="user-update">update {userData.update} days ago</div>
        <Button
          cls={'btn send-message-btn'}
          buttonText={'メッセージを送信する'}
          onClick={() => {}}
        />
        <div className="label-form">
          <span className="label-title">稼働状況</span>
          <span className="label-content">{userData.status}</span>
        </div>
        <div className="label-form">
          <span className="label-title">希望の働き方</span>
          <span className="label-content">
            {userData.work.map((item, i) => {
              return (
                <span key={i} className="work">
                  {item}
                  <span>/</span>
                </span>
              );
            })}
          </span>
        </div>
        <div className="profile-section">
          <p className="profile">{userData.profile}</p>
        </div>
        <div className="profile-tag">
          <span className="location">
            {userData.city}/{userData.country}
          </span>
          <a target="_blank" href={userData.url} className="site-url">
            {userData.url}
          </a>
        </div>
      </div>
    </React.Fragment>
  );
};

export default UserProfileTop;
