import usePopup from '@meettry/ui-components/hooks/usePopup';
import { ADD_SCOUT_COMMENT_QUERY, SCOUT_COMMENT_QUERY } from '@meettry/ui-components/queries';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useEffect, useMemo, useState } from 'react';
import { ScoutComment } from '@meettry/ui-components/types/scout';

/**
 * useScoutComment
 * 企業ユーザーがエンジニアユーザーに申し送り事項を付与する関連のフック
 */
const useScoutComment = () => {
  /**
   * State
   */
  const [comments, setComments] = useState<ScoutComment[]>([]);
  const [newAddComments, setNewAddComments] = useState<ScoutComment[]>([]);
  const [tempComment, setTempComment] = useState<{ userId: string; text: string }>({
    userId: '',
    text: ''
  });

  /**
   * Query
   */
  const [
    fetchScoutComments,
    { data: scoutCommentsData, loading: scoutCommentsLoading, error: scoutCommentsError }
  ] = useLazyQuery(SCOUT_COMMENT_QUERY, {
    fetchPolicy: 'no-cache'
  }); // MEMO(oomura): ページ遷移して戻ってくると undefined になるから no-cacheを設定

  /**
   * Mutation
   */
  const [
    mutate,
    { data: addScoutCommentData, loading: addScoutCommentLoading, error: addScoutCommentError }
  ] = useMutation(ADD_SCOUT_COMMENT_QUERY);

  /**
   * Custom Hook
   */
  const { showSuccessPopup, showErrorPopup } = usePopup();

  /**
   * fetchScoutComments
   *
   */
  const mutateAddScoutComment = (userId?: string, text?: string) => {
    if (userId && text) {
      setTempComment({ userId, text });
      mutate({ variables: { userId, text } });
    } else {
      mutate({ variables: { ...tempComment } });
    }
  };

  /**
   * fetch 完了後、 comments stateの更新
   */
  useEffect(() => {
    const comments = scoutCommentsData?.loginSession?.user?.organization?.scoutComments;
    if (!scoutCommentsLoading && (comments || comments?.length === 0)) {
      setComments(comments);
      setNewAddComments([]);
    } else if (!scoutCommentsLoading && scoutCommentsError) {
      showErrorPopup('コメントを取得できませんでした');
    }
  }, [scoutCommentsData, scoutCommentsLoading, scoutCommentsError, setComments]);

  /**
   * コメント追加した結果
   */
  useEffect(() => {
    const scoutComment = addScoutCommentData?.addScoutComment?.scoutComment;
    if (!addScoutCommentLoading && scoutComment) {
      setTempComment({ userId: '', text: '' });
      showSuccessPopup('コメントを追加しました');
      setNewAddComments((prev) => [...prev, scoutComment]);
    } else if (!addScoutCommentLoading && addScoutCommentError) {
      showErrorPopup('コメントを追加できませんでした');
    }
  }, [
    setTempComment,
    setNewAddComments,
    addScoutCommentLoading,
    addScoutCommentData,
    scoutCommentsError
  ]);

  /**
   * variables
   */
  const mergedComments = useMemo(() => [...comments, ...newAddComments], [
    comments,
    newAddComments
  ]);

  return {
    fetchScoutComments,
    scoutCommentsLoading,
    mutateAddScoutComment,
    addScoutCommentLoading,
    tempComment,
    setTempComment,
    comments: mergedComments,
    newAddComments
  };
};
export default useScoutComment;
