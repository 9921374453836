import React from 'react';
import styled from 'styled-components';

export const RadioButton = (props) => {
  const { label, name, value, checked, onChange, disabled = false } = props;
  const onChangeHandle = () => {
    typeof onChange === 'function' && onChange(value);
  };
  return (
    <StyledRadioButton checked={checked} disabled={disabled}>
      <input type="radio" name={name} checked={checked} onChange={onChangeHandle} />
      <span>{label}</span>
    </StyledRadioButton>
  );
};

export default RadioButton;

const StyledRadioButton = styled.label`
  cursor: pointer;
  display: inline-flex;
  position: relative;
  padding-left: 30px;
  ::before {
    content: '';
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    width: 20px;
    height: 20px;
    background-color: #f7f7f7;
    border: 1px solid #eeeeee;
    border-radius: 50%;
    box-sizing: border-box;
  }
  ::after {
    content: '';
    display: ${({ checked }) => (checked ? 'block' : 'none')};
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background-color: #0fafa9;
    box-sizing: border-box;
  }
  span {
    font-size: 16px;
    display: block;
  }
  input[type='radio'] {
    display: none;
  }
`;
