import React, { createContext, Dispatch, Reducer, useContext, useReducer } from 'react';
import { ProviderComponent } from '@meettry/ui-components/types/context';

/**
 * HooksMaster context
 *  「複数箇所でHooksは呼び出されるが、Hooksの内部処理は複数回呼び出されたくない」という時に使用する
 * 「複数回呼び出されたくない」処理は、このHooksがtrueを返す時にだけ実行されるようにすればOK
 *  を実現するために必要なコンテキスト
 *  useHooksMasterFlagで使用している
 */

/**
 * Action
 */
type Action<T, U> = { type: T; payload: U };

export const HOOKS_MASTER_ACTION_TYPE = {
  SET_HOOKS_MASTER_ID: 'SET_HOOKS_MASTER_ID',
};
type HooksMasterAction = SetHooksMasterIdAction;


type SetHooksMasterIdAction = Action<
  typeof HOOKS_MASTER_ACTION_TYPE.SET_HOOKS_MASTER_ID,
  {
    name: string;
    id: string | null;
  }
  >;
export const setHooksMasterId = (name: string, id: string | null): SetHooksMasterIdAction => ({
  type: HOOKS_MASTER_ACTION_TYPE.SET_HOOKS_MASTER_ID,
  payload: { name, id }
});


/**
 * Dispatch
 */
type HooksMasterDispatch = Dispatch<HooksMasterAction>;


/**
 * State
 */
type HooksMasterState = {
  [name in string]: string | null;
};

const defaultState = (): HooksMasterState => ({});


/**
 * Reducer
 */
type HooksMasterReducer = Reducer<HooksMasterState, HooksMasterAction>
const hooksMasterReducer: HooksMasterReducer = (state = defaultState(), action) => {
  switch (action.type) {
    case HOOKS_MASTER_ACTION_TYPE.SET_HOOKS_MASTER_ID: {
      const { name, id } = action.payload as SetHooksMasterIdAction['payload'];
      if (state[name]) return state;

      const newState = {
        ...state,
        [name]: id
      };
      return newState;
    }

    default: {
      return state;
    }
  }
};

/**
 * Context
 */
type HooksMasterStore = {
  state: HooksMasterState;
  dispatch: HooksMasterDispatch;
};
const defaultStore: HooksMasterStore = {
  state: defaultState(),
  dispatch: () => defaultState(),
};
const HooksMasterContext = createContext<HooksMasterStore>(defaultStore);

// Provider
export const HooksMasterProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(hooksMasterReducer, defaultState());
  return <HooksMasterContext.Provider value={{state, dispatch}}>{children}</HooksMasterContext.Provider>;
};

export const useHooksMasterContext = () => {
  const context = useContext(HooksMasterContext);
  if (context === undefined)
    throw new Error(`No provider for HooksMasterContext given`);
  return { ...context };
};
