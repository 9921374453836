import { useEffect, useContext, useState } from 'react';
import { EngineerContext, setChallengeList } from '@meettry/ui-components/contexts/engineer';
import { ChallengeType } from '@meettry/ui-components/types/userPage';

//TODO(aida) 定数置き場に持っていく
export const CHALLENGE_SORT_TYPE = {
  UPDATED: 'UPDATED', //更新日順 デフォルト
  ACHIEVEMENT: 'ACHIEVEMENT', //達成率順
  CREATED: 'CREATED' //作成日順
};

export const CHALLENGE_SORT_OPTION = [
  { value: CHALLENGE_SORT_TYPE.UPDATED, text: '更新日' },
  { value: CHALLENGE_SORT_TYPE.ACHIEVEMENT, text: '達成率' },
  { value: CHALLENGE_SORT_TYPE.CREATED, text: '作成日' }
];

export const useChallenge = () => {
  const { state, dispatch } = useContext(EngineerContext);
  const { challengeList } = state;

  const [isInitial, setIsInitial] = useState(true);
  //MEMO(aida) 整合性を保ちかつ不要な通信を避けるために表示中の項目をidで管理する。
  const [showingListId, setShowingListId] = useState<Array<string> | null>(null);
  //MEMO(aida) showingListIdにidが含まれているchallengeList.itemのみを表示する。
  //MEMO(aida) ピン留めや表示・非表示や新規作成・編集・削除などの更新はcontextのchallengeList.itemにのみ反映させる。
  const [showingList, setShowingList] = useState<Array<ChallengeType> | null>(null);
  //MEMO(aida) ソート順の管理に使用する
  const [sortType, setSortType] = useState(CHALLENGE_SORT_TYPE.UPDATED);

  const addChallenge = (challenge: ChallengeType) => {
    const updatedChallengeList = [...challengeList.items, challenge];
    setShowingListId(updatedChallengeList.map(({ id }: ChallengeType) => id));
    dispatch(setChallengeList(updatedChallengeList));
  };

  const removeChallenge = (id: string) => {
    const updatedChallengeList = challengeList.items.filter(
      (item: ChallengeType) => item.id !== id
    );
    setShowingListId(updatedChallengeList.map(({ id }: ChallengeType) => id));
    dispatch(setChallengeList(updatedChallengeList));
  };

  const updateChallenge = (challenge: ChallengeType) => {
    const updatedChallengeList = challengeList.items.map((item: ChallengeType) => {
      if (item.id === challenge.id) return challenge;
      return item;
    });
    dispatch(setChallengeList(updatedChallengeList));
  };

  const pinChallenge = (challenge: ChallengeType) => {
    const updatedChallengeList = challengeList.items.map((item: ChallengeType) => {
      if (item.id === challenge.id) return { ...item, ...challenge };
      return item;
    });
    dispatch(setChallengeList(updatedChallengeList));
  };

  const visibleChallenge = (challenge: ChallengeType) => {
    const updatedChallengeList = challengeList.items.map((item: ChallengeType) => {
      if (item.id === challenge.id) return { ...item, ...challenge };
      return item;
    });
    dispatch(setChallengeList(updatedChallengeList));
  };

  const setSort = (value: string) => {
    setSortType(value);
  };

  const sort = {
    updated: (items: Array<ChallengeType>) => {
      const sortedItems = items.sort((a, b) => {
        const timeA = new Date(a.updated).getTime();
        const timeB = new Date(b.updated).getTime();
        return timeB - timeA;
      });
      return [...sortedItems];
    },
    achievement: (items: Array<ChallengeType>) => {
      const sortedItems = items.sort((a, b) => {
        const achievementA = a.progressRate;
        const achievementB = b.progressRate;
        return achievementB - achievementA;
      });
      return [...sortedItems];
    },
    created: (items: Array<ChallengeType>) => {
      const sortedItems = items.sort((a, b) => {
        const timeA = new Date(a.created).getTime();
        const timeB = new Date(b.created).getTime();
        return timeA - timeB;
      });
      return [...sortedItems];
    }
  };

  const sortChallenge = (sortType: string) => {
    const showingChallengeItems = challengeList.items.filter(
      ({ id }: ChallengeType) => showingListId && showingListId.includes(id)
    );
    switch (sortType) {
      case CHALLENGE_SORT_TYPE.UPDATED:
        setShowingList(sort.updated(showingChallengeItems));
        return;
      case CHALLENGE_SORT_TYPE.CREATED:
        setShowingList(sort.created(showingChallengeItems));
        return;
      case CHALLENGE_SORT_TYPE.ACHIEVEMENT:
        setShowingList(sort.achievement(showingChallengeItems));
        return;
      default:
        setShowingList(sort.updated(showingChallengeItems));
    }
  };

  //MEMO(aida) challengeList.items or showingListId or sotrTypeが更新された際に表示用のshowingListも更新する
  useEffect(() => {
    if (isInitial) return;
    sortChallenge(sortType);
  }, [sortType, showingListId, challengeList.items]);

  //MEMO(aida) initial render時にshowingListとshowingListIdをcontextのchallengeList.itemsから設定する
  useEffect(() => {
    setShowingListId(challengeList.items.map((item: ChallengeType) => item.id));
    setShowingList(sort.updated(challengeList.items));
    setIsInitial(false);
  }, []);

  return {
    //MEMO(aida) このフックからはchallengeListしか取れなくする？
    state,
    addChallenge,
    removeChallenge,
    updateChallenge,
    pinChallenge,
    visibleChallenge,
    showingList,
    setShowingList,
    setShowingListId,
    sortType,
    setSort
  };
};

export default useChallenge;
