import React from 'react';
import { useLocation } from 'react-router-dom';
import { LangTag } from '@meettry/company/component/atoms/tags';
import { AchievementRate } from '@meettry/company/component/organisms/dashboardChallengeList';
import { Ellipsis } from '@meettry/company/component/atoms/ellipsis';
import ChallengeItemLog from '@meettry/company/component/organisms/userPage/Challenge/ChallengeItemLog';

const ChallengeLog = [
  {
    id: '1',
    date: '2021/01/10',
    time: '17:00',
    before: 25,
    after: 40,
    updateText:
      '〇〇が完了したので、達成率を変更。あとは、まるまるだけだテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  },
  {
    id: '1',
    date: '2021/01/12',
    time: '15:00',
    before: 20,
    after: 25,
    updateText:
      '〇〇が完了したので、達成率を変更。あとは、まるまるだけだテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  },
  {
    id: '3',
    date: '2021/02/12',
    time: '12:00',
    before: 15,
    after: 30,
    updateText:
      '〇〇が完了したので、達成率を変更。あとは、まるまるだけだテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  },
  {
    id: '3',
    date: '2021/02/10',
    time: '11:00',
    before: 10,
    after: 15,
    updateText:
      '〇〇が完了したので、達成率を変更。あとは、まるまるだけだテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  }
];

type ChallengeItemProps = {
  listItem: {
    title: string;
    langTag: Array<string>;
    text: string;
    achievement: number;
    id: string;
    isPin: boolean;
    update: boolean;
    createdAt: string;
  };
  onClick?: () => void;
};

const ChallengeItem: React.FC<ChallengeItemProps> = (props) => {
  const { listItem, onClick } = props;
  const location = useLocation();
  const locationPath = location.pathname;

  return (
    <div className="user-challenge-item">
      {listItem.isPin ? <div className="pin" /> : null}
      <div className="challenge-item-wrap">
        <div className="item-content">
          <span
            className={locationPath === '/user_name/challenge' ? 'item-title' : 'item-title click'}
            onClick={onClick}
          >
            {listItem.title}
          </span>
          <div className="lang-tags">
            {listItem.langTag.map((item, i) => (
              <LangTag item={item} key={i} />
            ))}
          </div>
          <Ellipsis lineNumber={3} cls="default-text" isWorkShorten={true} Tag="p">
            {listItem.text}
          </Ellipsis>
        </div>
        <AchievementRate percent={listItem.achievement} />
      </div>
      {locationPath === '/user_name/challenge' && listItem.update ? (
        <ChallengeItemLog listItem={ChallengeLog} id={listItem.id} createdAt={listItem.createdAt} />
      ) : null}
    </div>
  );
};

export default ChallengeItem;
