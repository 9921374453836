import React, { useState } from 'react';
import { Button } from '@meettry/company/component/atoms/btn';

type FollowListItemProps = {
  listItem: {
    firstName: string;
    lastName: string;
    country: string;
    city: string;
    occupation: string;
    prof: string;
  };
};
export const FollowListItem: React.FC<FollowListItemProps> = (props) => {
  const { listItem } = props;
  const [clickBtn, setClickBtn] = useState(false);

  return (
    <div className="follow-list-item">
      <i className="profile-image" />
      <div className="prof-contents">
        <div className="content-box">
          <div>
            <span className="name">
              {listItem.firstName} {listItem.lastName}
            </span>
            <span className="place">
              {listItem.city}/{listItem.country}
            </span>
            <span className="occupation">{listItem.occupation}</span>
          </div>
          <div>
            <Button
              buttonText={clickBtn ? 'フォロー中' : 'フォローする'}
              cls={`btn follow-btn ${clickBtn ? 'active' : ''}`}
              onClick={() => {
                setClickBtn(!clickBtn);
              }}
            />
            <Button buttonText="メッセージ" cls="btn message-btn" />
          </div>
        </div>
        <div className="prof-text">
          <p>{listItem.prof}</p>
        </div>
      </div>
    </div>
  );
};

type EmptyFollowProps = {
  text: string;
};

export const EmptyFollow: React.FC<EmptyFollowProps> = (props) => {
  const { text } = props;
  return (
    <div className="not-follow">
      <span>{text}がいません。</span>
    </div>
  );
};

export const NotFoundFollowers: React.FC = () => {
  return (
    <div className="not-found-followers">
      <i />
      <span className="title">条件に一致するユーザーはいませんでした</span>
      <span className="text">キーワードを確認・変更して検索し直してください。</span>
    </div>
  );
};
