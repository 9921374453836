import React from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type EmptyNoticeBoxProps = {
  heading: string;
  contents: Array<string>;
  buttonText: string;
  onClick: () => void;
};
export const EmptyNoticeBox = (props: EmptyNoticeBoxProps) => {
  const { heading, contents = [], onClick = null, buttonText = '' } = props;
  return (
    <StyledEmptyNoticeBox>
      {heading ? <StyledBoxHeading>{heading}</StyledBoxHeading> : null}
      <StyledBoxContentWrapper>
        {contents.map((val, i) => (
          <StyledBoxContent key={`notice_text-${i}`}>{val}</StyledBoxContent>
        ))}
      </StyledBoxContentWrapper>
      {onClick && buttonText ? (
        <StyledBoxButtonRow>
          <PrimaryButton
            width="180"
            size={STYLE_TYPE.SIZE.MEDIUM}
            onClick={onClick}
            margin={[0, 'auto', 0, 'auto']}
          >
            {buttonText}
          </PrimaryButton>
        </StyledBoxButtonRow>
      ) : null}
    </StyledEmptyNoticeBox>
  );
};

type NoItemNoticeBoxProps = {
  contents: Array<string>;
};

export const NoItemNoticeBox = (props: NoItemNoticeBoxProps) => {
  const { contents = [] } = props;
  return (
    <StyledNoItemNoticeBox>
      <StyledNoItemContent>
        {contents.map((val, i) => (
          <StyledNoItemText key={`no-item_${i}`}>{val}</StyledNoItemText>
        ))}
      </StyledNoItemContent>
    </StyledNoItemNoticeBox>
  );
};

const StyledNoItemNoticeBox = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 96px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  display: flex;
  align-items: center;
`;

const StyledNoItemContent = styled.div`
  width: 100%;
`;
const StyledNoItemText = styled.p`
  text-align: center;
  font-size: 16px;
`;

const StyledEmptyNoticeBox = styled.div`
  margin-top: 20px;
  width: 100%;
  min-height: 270px;
  padding: 50px 0;
  box-sizing: border-box;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
`;

const StyledBoxHeading = styled.p`
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 20px;
  font-weight: bold;
`;

const StyledBoxContentWrapper = styled.div`
  margin-top: 20px;
`;

const StyledBoxContent = styled.p`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 14px;
  :not(:first-child) {
    margin-top: 12px;
  }
`;

const StyledBoxButtonRow = styled.div`
  margin-top: 30px;
`;
