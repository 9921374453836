import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import HighlightedText from '@meettry/engineer/components/atoms/HighlightedText';
import { getTagCategory } from '@meettry/engineer/utils';
import { StackTag, Tag } from '@meettry/engineer/components/atoms/Tag';
import { CircleAvatar } from '@meettry/engineer/components/atoms/Images/Avatars';
import iconPathUpdatedDate from '@meettry/engineer/images/icon-challenge-updated-date.png';
import { getDaysBetween } from '@meettry/engineer/utils';
import iconPathSkillDatabase from '@meettry/engineer/images/icon-skill-database.png';
import iconPathSkillFramework from '@meettry/engineer/images/icon-skill-framework.png';
import iconPathSkillInfra from '@meettry/engineer/images/icon-skill-infra.png';
import iconPathLanguage from '@meettry/engineer/images/icon-skill-language.png';
import iconPathOs from '@meettry/engineer/images/icon-skill-os.png';
import iconPathTool from '@meettry/engineer/images/icon-skill-tool.png';

const formatDate = (d) => {
  const datetime = new Date(d);
  const yyyy = datetime.getFullYear();
  const MM = `0${datetime.getMonth() + 1}`.slice(-2);
  const dd = `0${datetime.getDate()}`.slice(-2);
  return `${yyyy}/${MM}/${dd}`;
};

const diffDate = (startDate, endDate) => {
  const year = endDate.getFullYear() - startDate.getFullYear(),
    month = endDate.getMonth() - startDate.getMonth();
  return `${month < 0 ? year - 1 : year}年${month < 0 ? 12 - Math.abs(month) : month}ヵ月`;
};

const filterResumeTags = (tagArray = [], tagKind) =>
  tagArray.filter((tag = {}) => tag.category === getTagCategory(tagKind));

//TODO(aida) ユーザ詳細のレジュメコンポーネントと共通化するべき

/**
 * CassetteResume Component
 */
const CassetteResume = (props) => {
  const history = useHistory();
  const { data, searchWord } = props;
  const startDate = new Date(data.workStartDate);
  const endDate = data.workEndDate ? new Date(data.workEndDate) : new Date();
  const tags = data.tags && data.tags.filter((tag) => tag.category === null);
  const userPagePath = `/user/${data.user.nickname}/resume`;

  const resumeLanguageTags = filterResumeTags(data.tags, 'language');
  const resumeFrameworkTags = filterResumeTags(data.tags, 'framework');
  const resumeDatabaseTags = filterResumeTags(data.tags, 'database');
  const resumeInfraTags = filterResumeTags(data.tags, 'infrastructure');
  const resumeOsTags = filterResumeTags(data.tags, 'os');
  const resumeToolTags = filterResumeTags(data.tags, 'tool');

  const onClickCassetteResume = () => {
    history.push(userPagePath);
  };

  return (
    <div onClick={onClickCassetteResume}>
      <StyledContentHeader>
        <StyledUser>
          <CircleAvatar
            name="user_avatar"
            size="sm"
            src={data.user.profileImage ? data.user.profileImage.url : null}
          />
          <span>{data.user.nickname}</span>
        </StyledUser>
        <StyledUpdatedText>
          <img
            src={iconPathUpdatedDate}
            alt="最終更新日"
            width="17"
            style={{ marginRight: '5px', verticalAlign: '-5px' }}
          />
          {`Updated ${getDaysBetween(data.updated)}`}
        </StyledUpdatedText>
      </StyledContentHeader>
      <StyledCassetteResume>
        <div>
          <StyledResumeProjectPeriod>
            <span>{formatDate(startDate)}</span>
            <span>|</span>
            <span>{formatDate(endDate)}</span>
            <span>{diffDate(startDate, endDate)}</span>
          </StyledResumeProjectPeriod>
        </div>
        <div>
          <StyledResumeProjectName>
            <HighlightedText keyword={searchWord}>{data.title}</HighlightedText>
            <span>{data.companyName}</span>
          </StyledResumeProjectName>
          <div style={{ marginTop: '20px' }}>
            <StyledTagList>
              {tags.map((tag) => (
                <Tag key={tag.name}>
                  <HighlightedText keyword={searchWord} isAllSame={true}>
                    {tag.displayName}
                  </HighlightedText>
                </Tag>
              ))}
            </StyledTagList>
          </div>
          <div style={{ marginTop: '10px' }}>
            <StyledSkillList>
              {resumeLanguageTags.length ? (
                <>
                  <dt>
                    <img src={iconPathLanguage} alt="言語" width="14" />
                  </dt>
                  {resumeLanguageTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeFrameworkTags.length ? (
                <>
                  <dt>
                    <img src={iconPathSkillFramework} alt="フレームワーク" width="14" />
                  </dt>
                  {resumeFrameworkTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeDatabaseTags.length ? (
                <>
                  <dt>
                    <img src={iconPathSkillDatabase} alt="データベース" width="14" />
                  </dt>
                  {resumeDatabaseTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeInfraTags.length ? (
                <>
                  <dt>
                    <img src={iconPathSkillInfra} alt="インフラ" width="14" />
                  </dt>
                  {resumeInfraTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeOsTags.length ? (
                <>
                  <dt>
                    <img src={iconPathOs} alt="OS" width="14" />
                  </dt>
                  {resumeOsTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeToolTags.length ? (
                <>
                  <dt>
                    <img src={iconPathTool} alt="ツール" width="14" />
                  </dt>
                  {resumeToolTags.map((tag) => (
                    <dd key={tag.name}>
                      <StackTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StackTag>
                    </dd>
                  ))}
                </>
              ) : null}
            </StyledSkillList>
          </div>
          <div style={{ marginTop: '20px' }}>
            <StyledResumeProjectDescription>
              <HighlightedText keyword={searchWord}>{data.workDetail}</HighlightedText>
            </StyledResumeProjectDescription>
          </div>
        </div>
      </StyledCassetteResume>
    </div>
  );
};

export default CassetteResume;

const StyledCassetteResume = styled.div`
  box-sizing: border-box;
  display: flex;
  padding: 20px 30px 30px 0;
  text-decoration: none;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 150px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 150px);
    padding-left: 30px;
    border-left: 1px solid #eeeeee;
  }
`;

const StyledResumeProjectPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #333333;

  & > *:nth-child(1) {
    font-size: 16px;
  }

  & > *:nth-child(2) {
    margin-top: 8px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    margin-top: 8px;
  }

  & > *:nth-child(4) {
    font-size: 12px;
    margin-top: 13px;
  }
`;

const StyledResumeProjectName = styled.div`
  & > * {
    display: block;
  }

  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: #0fafa9;
    word-wrap: break-word;
    line-height: 1.2;
  }

  & > *:nth-child(2) {
    font-size: 14px;
    color: #333333;
    margin-top: 10px;
  }
`;

const StyledTagList = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-left: -5px;
  & > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledSkillList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;

  & > dt,
  & > dd {
    padding: 5px;
  }

  & > * + dt {
    padding-left: 10px;
  }

  & img {
    vertical-align: middle;
  }
`;

const StyledResumeProjectDescription = styled.p`
  color: #333333;
  font-size: 14px;
  line-height: 1.75;
  word-break: break-word;
`;

const StyledContentHeader = styled.div`
  padding: 10px 30px 0;
  display: flex;
  justify-content: space-between;
`;

const StyledUpdatedText = styled.span`
  font-size: 12px;
  color: #999999;
`;

const StyledUser = styled.div`
  display: inline-flex;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }

  & > *:nth-child(2) {
    font-size: 12px;
    color: #2459bf;
  }
`;
