import React, { useState } from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { Accordion } from '@meettry/company/component/molecules/accordion';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type TitledContainerProps = {
  accordion?: boolean;
  accordionCloseHeight?: number;
  hasMore?: boolean;
  hasBorder?: boolean;
  title: string;
  margin?: 'sections' | number;
};

export const TitledContainer: React.FC<TitledContainerProps> = (props) => {
  const { children, accordion, accordionCloseHeight, hasMore, hasBorder, title, margin } = props;
  const [show, changeDisplay] = useState(true);
  const [accordionAnimating, setAccordionAnimating] = useState(false);
  return (
    <StyledTitledContainer margin={margin}>
      <StyledTitleArea border={hasBorder || (accordion && !show && !accordionAnimating)}>
        <StyledAccordionWrapper
          onClick={() => {
            if (accordion) {
              changeDisplay(!show);
              setAccordionAnimating(true);
            }
          }}
        >
          <StyledContentsTitle accordion={!!accordion}>{title}</StyledContentsTitle>
          {accordion ? (
            <Icon
              width="16px"
              iconName="arrow_up"
              iconColor="primary"
              accordion={{
                isAccordion: accordion,
                hide: accordion && !show
              }}
            />
          ) : null}
        </StyledAccordionWrapper>
        {hasMore ? <StyledLinkToMore>もっと見る</StyledLinkToMore> : null}
      </StyledTitleArea>
      {accordion ? (
        <Accordion
          show={show}
          closeHeight={accordionCloseHeight && accordionCloseHeight}
          setAnimating={setAccordionAnimating}
        >
          {children}
        </Accordion>
      ) : (
        children
      )}
    </StyledTitledContainer>
  );
};

const StyledTitledContainer = styled.div<{ margin?: 'sections' | number }>`
  ${({ margin }) =>
    `margin-top: ${margin === 'sections' ? 20 : typeof margin === 'number' ? margin : 50}px;`}
`;

const StyledTitleArea = styled.div<{ border?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${({ border }) =>
    border &&
    `
    padding-bottom: 24px;
    border-bottom: 1px solid $back_secondary_color;
    `}
`;

const StyledAccordionWrapper = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

const StyledContentsTitle = styled.span<{ accordion?: boolean }>`
  margin: 0;
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};

  ${({ accordion }) => accordion && `margin-right: 16px;`}
`;

const StyledLinkToMore = styled.span`
  font-size: 16px;
  color: ${COLOR_DEFINITIONS.TEXT.SUB};
  cursor: pointer;
`;
