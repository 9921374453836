import React from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import SearchTagInput from '@meettry/ui-components/components/organisms/SearchTagInput';
import SearchSelectedTagList from '@meettry/ui-components/components/atoms/SearchSelectedTagList';
import { TagType, UserTagsType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type DetailHeaderProps = {
  title: string;
  titleImage: string;
  onSelectSearchTagDownshift: (tag: TagType) => void;
  searchWord: string;
  onSubmitSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  onChangeSearchWord: (value: string) => void;
  userTags: Array<UserTagsType>;
  onSelectSearchTag: (item: TagType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  placeholder: string;
  searchTag: Array<TagType>;
  isOwner: boolean;
  onAddMode: () => void;
  onRemoveSearchTag: (targetTag: TagType) => (e: React.MouseEvent<HTMLSpanElement>) => void;
  sortOption: Array<{
    value: string;
    text: string;
  }>;
  onSort?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  sortType?: string;
};

const DetailHeader = (props: DetailHeaderProps) => {
  const {
    title,
    titleImage,
    onSelectSearchTagDownshift,
    searchWord,
    onSubmitSearch,
    onChangeSearchWord,
    userTags,
    onSelectSearchTag,
    placeholder,
    searchTag,
    isOwner,
    onAddMode,
    onRemoveSearchTag,
    sortOption = [],
    onSort
  } = props;

  return (
    <StyledDetailHeader>
      <StyledDetailHeaderText>
        <StyledIcon iconColor="black" iconName={titleImage} width="18px" />
        {title}
      </StyledDetailHeaderText>
      <StyledSearchSection>
        <StyledSearchWrapper>
          <SearchTagInput
            onChangeDownshift={onSelectSearchTagDownshift}
            searchWord={searchWord}
            onSubmitSearch={onSubmitSearch}
            onChangeSearchWord={onChangeSearchWord}
            tagList={userTags.map((item) => item.tag)}
            onSelectTag={onSelectSearchTag}
            placeholder={placeholder}
          />
          {searchTag.length ? (
            <StyledSearchSelectedTagList selectedTags={searchTag} onRemoveTag={onRemoveSearchTag} />
          ) : null}
        </StyledSearchWrapper>
        {sortOption.length ? (
          <StyledSearchSortWrapper>
            <label htmlFor="sort-search">表示順</label>
            <StyledSortSelect style={{ marginLeft: '10px' }}>
              <select onChange={onSort} id="sort-search">
                {sortOption.map(({ text, value }) => (
                  <option key={value} value={value}>
                    {text}
                  </option>
                ))}
              </select>
            </StyledSortSelect>
          </StyledSearchSortWrapper>
        ) : null}
        {isOwner && (
          <StyledAddButtonWrapper>
            <LineButton size={STYLE_TYPE.SIZE.SMALL} onClick={onAddMode}>
              <StyledIcon iconColor="primary" iconName="plus" />
              新規作成
            </LineButton>
          </StyledAddButtonWrapper>
        )}
      </StyledSearchSection>
    </StyledDetailHeader>
  );
};

export default DetailHeader;

const StyledDetailHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const StyledDetailHeaderText = styled.p`
  font-size: 20px;
  font-weight: bold;
`;

const StyledSearchSection = styled.div`
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const StyledSearchSortWrapper = styled.div`
  margin-left: 10px;
`;

const StyledSelect = styled.div`
  position: relative;
  display: inline-block;
  & > select {
    appearance: none;
    outline: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    padding: 8px 30px 8px 10px;
  }

  &:after {
    pointer-events: none;
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-right: 1px solid ${COLOR_DEFINITIONS.LINE.THIRDLY};
    border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.THIRDLY};
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -75%) rotate(45deg);
  }
`;

const StyledSortSelect = styled(StyledSelect)`
  & > select {
    font-size: 14px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    cursor: pointer;
  }
`;

const StyledSearchWrapper = styled.div`
  position: relative;
`;

const StyledSearchSelectedTagList = styled(SearchSelectedTagList)`
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  z-index: 150;
`;

const StyledAddButtonWrapper = styled.div`
  display: flex;
  margin-left: 20px;
`;
