import React from 'react';
import styled from 'styled-components';
import {
  ChatMessageType,
  CHAT_MESSAGE_TYPE_USER,
  CHAT_MESSAGE_TYPE_FRIEND
} from '@meettry/ui-components/components/atoms/ChatMessage';
import { Avatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import defaultAvatarIcon from '@meettry/ui-components/images/avatar/icon_default_avatar.png';

export type ChatMessageProps = {
  type: ChatMessageType;
  children: React.ReactNode | string;
  timeString: string;
  imagePath?: string;
  className?: string;
};

const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  const { type, children, timeString, className, imagePath } = props;
  return (
    <StyledMessageWrapper type={type} className={className}>
      {type === CHAT_MESSAGE_TYPE_FRIEND && (
        <StyledAvatar
          src={imagePath || defaultAvatarIcon}
          size="sm"
          circle={true}
          alt="アイコン画像"
        />
      )}
      <StyledMessage type={type}>{children}</StyledMessage>
      <StyledTime type={type}>{timeString}</StyledTime>
    </StyledMessageWrapper>
  );
};

export default ChatMessage;

const getStyleType = (props: { type: ChatMessageType }) => {
  switch (props.type) {
    case 'user':
      return `
        background-color: rgba(15,175,169,0.2);
        border-top-right-radius: 0;
      `;
    default:
      return `
        background-color: #eee;
        border-top-left-radius: 0;
      `;
  }
};

const StyledMessageWrapper = styled.div<{ type: ChatMessageType }>`
  text-align: ${(props) => (props.type === CHAT_MESSAGE_TYPE_USER ? 'right' : 'left')};
`;

const StyledMessage = styled.pre<{ type: ChatMessageType }>`
  max-width: 432px;
  font-size: 14px;
  text-align: left;
  color: #333;
  line-height: 1.75;
  padding: 10px 15px;
  border-radius: 22px;
  display: inline-block;
  word-break: break-word;
  white-space: pre-wrap;

  ${(props) => getStyleType(props)}
`;

const StyledTime = styled.div<{ type: ChatMessageType }>`
  color: #999999;
  font-size: 10px;
  margin-top: 8px;
`;

const StyledAvatar = styled(Avatar)`
  margin-right: 10px;
`;
