import React from 'react';
import {
  EmptyNoticeBox,
  NoItemNoticeBox
} from '@meettry/ui-components/components/organisms/NoticeBox';

type ResumeEmptyListProps = {
  isSearch: boolean;
  isOwner: boolean;
  onClick: () => void;
};

export const ResumeEmptyList = (props: ResumeEmptyListProps) => {
  const { isOwner, onClick, isSearch } = props;

  if (isSearch) return <NoItemNoticeBox contents={['一致する経歴はありません']} />;

  return isOwner ? (
    <EmptyNoticeBox
      heading="経歴を登録しよう"
      contents={[
        'これまで参画してきたプロジェクトを登録して、',
        'あなたの経歴に興味がある仲間や企業にアピールしましょう。'
      ]}
      buttonText="経歴を登録する"
      onClick={onClick}
    />
  ) : (
    <NoItemNoticeBox contents={['経歴はまだありません']} />
  );
};

export default ResumeEmptyList;
