import React, { useCallback, useEffect, useState } from 'react';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import { LoginStatus, useLoginStatus } from '@meettry/ui-components/utils/firebase';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';
import { Breadcrumb } from '@meettry/company/component/molecules/breadcrumb';
import useSearchQueryManager from '@meettry/ui-components/hooks/useSearchQueryManager';
import { SEARCH_TYPE } from '@meettry/ui-components/constants';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  BOOKMARK_USER_BY_ENTERPRISE_QUERY,
  SEARCH_BY_ENTERPRISE_QUERY,
  SearchBhyEnterpriseQueryResponse,
  UNBOOKMARK_USER_BY_ENTERPRISE_QUERY
} from '@meettry/ui-components/queries';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import CassetteUser from '@meettry/ui-components/components/organisms/CassetteUser';
import CassetteResume from '@meettry/ui-components/components/organisms/CassetteResume';
import CassetteChallenge from '@meettry/ui-components/components/organisms/CassetteChallenge';
import iconPathNoData from '@meettry/ui-components/images/icons/primary/icon_no_data.svg';
import { simplifyDigits } from '@meettry/ui-components/utils';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import SearchResult from '@meettry/ui-components/components/organisms/SearchResult';

const TAB_SETTINGS = {
  USER: {
    type: SEARCH_TYPE.USER,
    label: 'ユーザー',
    order: 0
  },
  RESUME: {
    type: SEARCH_TYPE.RESUME,
    label: '経歴',
    order: 1
  },
  CHALLENGE: {
    type: SEARCH_TYPE.CHALLENGE,
    label: 'チャレンジ',
    order: 2
  },
  CHAT: {
    type: SEARCH_TYPE.CHAT,
    label: 'チャット',
    order: 3
  }
};

/**
 * SearchPage
 * 検索結果画面
 */
const SearchPage: React.FC<PageCommonProps> = () => {
  const history = useHistory();
  const location = useLocation();
  const [targetUser, setTargetUser] = useState<{ userId: string; nickname: string } | null>(null);
  const [bookmarkUsers, updateBookmarkUsers] = useState<string[]>([]);
  const { status } = useLoginStatus();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const pageLoading = status === LoginStatus.NotInitialized;
  const canViewPage = status === LoginStatus.LoginNicknameRegistered;
  const {
    queryString,
    displayNumber,
    page,
    type,
    sortType,
    changeSortType,
    changeQuery,
    resetQuery,
    createHistoryContentWithSearchQuery
  } = useSearchQueryManager();

  /**
   * 企業側検索クエリー
   */
  const [
    doSearchQuery,
    { data: searchResults, loading: searchResultsLoading, error: searchResultsError }
  ] = useLazyQuery<SearchBhyEnterpriseQueryResponse>(SEARCH_BY_ENTERPRISE_QUERY, {
    fetchPolicy: 'no-cache'
  });

  /**
   * エンジニアお気に入りミューテーション
   */
  const [
    mutateBookmark,
    { data: bookmarkMutationResult, loading: bookmarkLoading, error: bookmarkError }
  ] = useMutation(BOOKMARK_USER_BY_ENTERPRISE_QUERY, {
    onCompleted: ({ bookmarkUser }) => {
      if (!bookmarkUser?.ok) {
        showErrorPopup(`${targetUser?.nickname ?? ''}をお気に入りに追加できませんでした`);
      } else {
        showSuccessPopup(`${targetUser?.nickname ?? ''}をお気に入りに追加しました`);
        if (targetUser) updateBookmarkUsers([...bookmarkUsers, targetUser.userId]);
      }
      setTargetUser(null);
    },
    onError: (error) => {
      showErrorPopup(`${targetUser?.nickname ?? ''}をお気に入りに追加できませんでした`);
      setTargetUser(null);
    }
  });

  /**
   * エンジニアお気に入り外しミューテーション
   */
  const [
    mutateUnbookmark,
    { data: unbookmarkMutationResult, loading: unbookmarkLoading, error: unbookmarkError }
  ] = useMutation(UNBOOKMARK_USER_BY_ENTERPRISE_QUERY, {
    onCompleted: ({ unbookmarkUser }) => {
      if (!unbookmarkUser?.ok) {
        showErrorPopup(`${targetUser?.nickname ?? ''}をお気に入りから外せませんでした`);
      } else {
        showSuccessPopup(`${targetUser?.nickname ?? ''}をお気に入りから外しました`);
        if (targetUser) updateBookmarkUsers(bookmarkUsers.filter((u) => u !== targetUser.userId));
      }
      setTargetUser(null);
    },
    onError: (error) => {
      showErrorPopup(`${targetUser?.nickname ?? ''}をお気に入りから外せませんでした`);
      setTargetUser(null);
    }
  });

  /**
   * ページ遷移
   */
  const goToUserPage = useCallback((nickname: string) => {
    history.push(`/user/${nickname}`);
  }, []);
  const goToUserResumePage = useCallback((nickname: string) => {
    history.push(`/user/${nickname}/resume`);
  }, []);
  const goToUserChallengePage = useCallback((nickname: string) => {
    history.push(`/user/${nickname}/challenge`);
  }, []);
  const goToChatPage = useCallback((scoutRoomId: string, userId, nickname: string) => {
    if (scoutRoomId) {
      history.push(`/enterprise/chat/${scoutRoomId}`);
    } else {
      history.push(`/enterprise/chat`, { userId, nickname });
    }
  }, []);

  /**
   * エンジニアのブックマーク
   */
  const bookmarkEngineer = (userId: string, nickname: string, isUserBookmark: boolean) => {
    if (bookmarkLoading || unbookmarkLoading) return;
    setTargetUser({ userId, nickname });
    if (isUserBookmark) {
      mutateUnbookmark({
        variables: { userId }
      });
    } else {
      mutateBookmark({
        variables: { userId }
      });
    }
  };

  /**
   * エンジニアにメッセージを送る
   */
  const clickMessage = (scoutRoomId: string, userId: string, nickname: string) => {
    goToChatPage(scoutRoomId, userId, nickname);
  };

  /**
   * 検索クエリー情報をURLへ反映
   */
  useEffect(() => {
    const historyContent = createHistoryContentWithSearchQuery(location.pathname);
    if (!historyContent) return;
    history.replace(historyContent);
  }, [queryString, displayNumber, page, type]);

  /**
   * 検索結果をフェッチ
   */
  useEffect(() => {
    if (!queryString) return;
    if (!canViewPage) return;

    doSearchQuery({
      variables: {
        queryString,
        first: displayNumber,
        offset: Number(displayNumber) * (Number(page) - 1),
        field: sortType
      }
    });
  }, [queryString, displayNumber, sortType, page, canViewPage]);
  const userResult = searchResults?.searchFor.users;
  const resumeResult = searchResults?.searchFor.resumes;
  const challengeResult = searchResults?.searchFor.challenges;
  const chatMessageResult = searchResults?.searchFor.chatMessages;

  /**
   * bookmarkUser の更新
   */
  useEffect(() => {
    if (!searchResultsLoading && !!searchResults) {
      updateBookmarkUsers(
        searchResults?.loginSession.user?.organization?.bookmarks.users.results.map((r) => r.id) ??
          []
      );
    }
  }, [searchResults, searchResultsLoading]);

  /**
   * 検索カテゴリと検索結果のカウント
   */
  const searchCategory = [
    {
      type: TAB_SETTINGS.USER.type,
      label: TAB_SETTINGS.USER.label,
      count: userResult?.count ?? 0
    },
    {
      type: TAB_SETTINGS.RESUME.type,
      label: TAB_SETTINGS.RESUME.label,
      count: resumeResult?.count ?? 0
    },
    {
      type: TAB_SETTINGS.CHALLENGE.type,
      label: TAB_SETTINGS.CHALLENGE.label,
      count: challengeResult?.count ?? 0
    }
    // {
    //   type: TAB_SETTINGS.CHAT.type,
    //   label: TAB_SETTINGS.CHAT.label,
    //   count: chatMessageResult?.count ?? 0
    // },
  ];
  const activeCount = searchCategory.find((c) => c.type === type)?.count ?? 0;

  /**
   * 検索結果の一覧コンポーネント
   */
  const viewList = (type: string) => {
    switch (type) {
      case SEARCH_TYPE.RESUME: {
        if (!resumeResult || resumeResult.results.length === 0)
          return (
            <StyledSearchNoData>
              <img src={iconPathNoData} width="60" alt="NO DATA" />
              <p>条件に一致する検索結果はありません</p>
              <p>キーワードを確認・変更して検索し直してください。</p>
            </StyledSearchNoData>
          );
        return (
          <StyledSearchResultList>
            {resumeResult.results.map((resume) => (
              <li key={`resume_${resume.id}`}>
                <CassetteResume
                  resume={resume}
                  searchWord={resumeResult.queryString}
                  onClick={goToUserResumePage}
                />
              </li>
            ))}
          </StyledSearchResultList>
        );
      }
      case SEARCH_TYPE.CHALLENGE: {
        if (!challengeResult || challengeResult.results.length === 0)
          return (
            <StyledSearchNoData>
              <img src={iconPathNoData} width="60" alt="NO DATA" />
              <p>条件に一致する検索結果はありません</p>
              <p>キーワードを確認・変更して検索し直してください。</p>
            </StyledSearchNoData>
          );
        return (
          <StyledSearchResultList>
            {challengeResult.results.map((challenge) => (
              <li key={`challenge_${challenge.id}`}>
                <CassetteChallenge
                  challenge={challenge}
                  searchWord={challengeResult.queryString}
                  onClick={goToUserChallengePage}
                />
              </li>
            ))}
          </StyledSearchResultList>
        );
      }
      case SEARCH_TYPE.CHAT: {
        if (!chatMessageResult || chatMessageResult.results.length === 0)
          return (
            <StyledSearchNoData>
              <img src={iconPathNoData} width="60" alt="NO DATA" />
              <p>条件に一致する検索結果はありません</p>
              <p>キーワードを確認・変更して検索し直してください。</p>
            </StyledSearchNoData>
          );
        return (
          <StyledSearchResultList>
            {chatMessageResult.results.map((chat) => (
              <li key={`chat_${chat.roomId}_${chat.senderId}_${chat.createdAt}`}>
                {JSON.stringify(chat)}
              </li>
            ))}
          </StyledSearchResultList>
        );
      }
      case SEARCH_TYPE.USER:
      default: {
        if (!userResult || userResult.results.length === 0)
          return (
            <StyledSearchNoData>
              <img src={iconPathNoData} width="60" alt="NO DATA" />
              <p>条件に一致する検索結果はありません</p>
              <p>キーワードを確認・変更して検索し直してください。</p>
            </StyledSearchNoData>
          );
        return (
          <StyledSearchResultList>
            {userResult.results.map((user) => (
              <li key={`user_${user.id}`}>
                <CassetteUser
                  userInfo={user}
                  searchWord={userResult.queryString}
                  onClick={goToUserPage}
                  onClickBookmark={bookmarkEngineer}
                  onClickMessage={clickMessage}
                  isLoginUserCompany={true}
                  isBookmark={bookmarkUsers.includes(user.id) ?? false}
                  buttonStyle={{ size: STYLE_TYPE.SIZE.MEDIUM }}
                />
              </li>
            ))}
          </StyledSearchResultList>
        );
      }
    }
  };

  const handleChangeTab = (type: string) => {
    resetQuery('page');
    changeQuery('type')(type);
  };

  const onChangeSort: (e: React.ChangeEvent<HTMLSelectElement>) => void = (e) =>
    changeSortType(e.target.value);

  return pageLoading ? (
    <StyledContents className="searchPage" />
  ) : canViewPage ? (
    <StyledContents className="searchPage">
      <StyledBreadcrumbArea>
        <Breadcrumb />
      </StyledBreadcrumbArea>
      <StyledContentInner>
        <SearchResult
          searchCategory={searchCategory}
          type={type}
          handleChangeTab={handleChangeTab}
          queryString={queryString}
          sortType={sortType}
          onChangeSort={onChangeSort}
          isLoading={searchResultsLoading}
          onPaginationClick={(i) => {
            changeQuery('page')(String(i));
            window.scrollTo(0, 0);
          }}
          searchResultsCount={activeCount}
          SearchResult={!searchResultsLoading ? viewList(String(type)) : null}
        />
      </StyledContentInner>
    </StyledContents>
  ) : (
    <NotFoundPage />
  );
};
export default SearchPage;

const StyledContents = styled.div`
  && {
    padding: 10px 50px 50px;
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
    flex: 1;
    min-height: calc(100vh - 96px);
  }
`;

const StyledContentInner = styled.div`
  && {
    margin-top: 50px;
    max-width: 1200px;
    min-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
`;

const StyledBreadcrumbArea = styled.div`
  margin-left: -40px;
  margin-right: -40px;
`;

const StyledSearchResultList = styled.ul`
  cursor: pointer;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  margin-top: 10px;

  & > li {
    transition: background 0.15s;
  }

  & > li:hover {
    background-color: ${COLOR_DEFINITIONS.HOVER.OUTLINED};
  }

  & > li + li {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledSearchNoData = styled.div`
  padding: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
  margin-top: 40px;

  & > *:nth-child(2) {
    font-size: 20px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    font-weight: bold;
    margin-top: 30px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    margin-top: 20px;
  }
`;
