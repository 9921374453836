import React from 'react';
import ActivityTimeLineItem from '@meettry/company/component/organisms/userPage/ActivityTimeLine/ActivityTimeLineItem';

const timelineList = [
  {
    history: 'Updated 5 commits in 2 carrer history',
    resume: [
      {
        title: '#1 ベンチャー企業でのソーシャルゲーム開発運用',
        commits: '3',
        langTag: []
      },
      {
        title: '#1 ベンチャー企業でのソーシャルゲーム開発運用',
        commits: '',
        langTag: []
      },
      {
        title: '#2 ベンチャー企業でのソーシャルゲーム開発運用',
        commits: '2',
        langTag: []
      }
    ]
  },
  {
    history: 'created 1 carrer history',
    resume: [
      {
        title: '#2 ベンチャー企業でのソーシャルゲーム開発運用',
        commits: '',
        langTag: ['Python', 'C#']
      }
    ]
  },
  {
    history: 'created 1 carrer history',
    resume: [
      {
        title: '#2 ベンチャー企業でのソーシャルゲーム開発運用',
        commits: '',
        langTag: ['Python', 'C#']
      }
    ]
  }
];

const ActivityTimeLine: React.FC = () => {
  return (
    <div className="user-activity-wrap">
      <span className="user-activity-title">更新履歴</span>
      <ActivityTimeLineItem listItem={timelineList} />
    </div>
  );
};

export default ActivityTimeLine;
