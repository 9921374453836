import React from 'react';
import { ChallengeParts } from '@meettry/company/component/organisms/userPage/Dashboard/ChallengeParts';
import { ResumeParts } from '@meettry/company/component/organisms/userPage/Dashboard/ResumeParts';
import AcrivityTimeLine from '@meettry/company/component/organisms/userPage/ActivityTimeLine';

const UserDashboard: React.FC = () => {
  return (
    <div className="user-dashboard-wrap">
      <div className="challenge-wrap">
        <span className="title challenge">チャレンジ</span>
        <ChallengeParts />
      </div>
      <div className="resume-wrap">
        <span className="title resume">経歴</span>
        <ResumeParts />
      </div>
      <AcrivityTimeLine />
    </div>
  );
};

export default UserDashboard;
