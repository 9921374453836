import React from 'react';

type EmptyListItemProps = {
  text: string;
};

const EmptyListParts: React.FC<EmptyListItemProps> = (props) => {
  const { text } = props;
  return (
    <div className="empty-list-item">
      <span>{text}はまだありません</span>
    </div>
  );
};

export default EmptyListParts;
