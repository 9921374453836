export const required = value => {
  if (!value || !value.toString().trim().length) {
    return 'この項目は空にできません';
  }
  return null;
};

export const isNumericPercent = value => {
  if (!value || Number.isNaN(value)) {
    return '半角で数値を入力してください';
  }
  if (value < 0 && value > 100) {
    return '0〜100の間で値を入力してください';
  }
  return null;
};

/**
 * Options for validate() function
 * @typedef {Object} ValidationOptions
 * @property {string[]} only - only に指定されたフィールドのみをバリデーション対象とする
 */

/**
 * バリデーションの実行
 *
 * @param {Object} formValues フォームの各フィールドの情報をまとめた連想配列
 * 例) {title: 'hoge', flag: true}
 *
 * @param {Object} validators 各フィールドに対して実行するバリデーション関数をまとめた連想配列
 * 例) {title: [required], email: [required, isEmail]}
 *
 * @param {ValidationOptions} opts オプション{@link ValidationOptions} を指定する
 *
 * @returns {Object} 各フィールドのエラー文言をまとめた連想配列。
 * エラーのないフィールドは出力されず、エラーが一つもない場合は空の連想配列が返される
 * 例) {title: 'この項目は空にできません'}
 */
export const validate = (formValues, validators, opts) =>
  Object.keys(formValues).reduce((errors, key) => {
    if (opts && opts.only && !opts.only.includes(key)) {
      return errors;
    }

    const value = formValues[key];

    if (validators[key]) {
      const err = validators[key].reduce((msg, func) => {
        if (!msg) return func(value);
        return msg;
      }, null);

      if (err) {
        return { ...errors, [key]: err };
      }
    }

    return errors;
  }, {});

export const isNumber = value => {
  const numPattern = /^\d*$/;
  return numPattern.test(value);
};

export const isUrl = value => {
  const urlPattern = /https?:\/\/[\w!?/\+\-_~=;\.,*&@#$%\(\)\'\[\]]+/;
  return urlPattern.test(value);
};
