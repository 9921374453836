import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { OnCloseType } from '@meettry/ui-components/types/dialog';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

export const MaximumReached: React.FC<OnCloseType> = (props) => {
  const { texts, onClose } = props;
  return (
    <StyledMaximumReached>
      <StyledMaximumReachedContainer>
        {texts.map((text, i) => (
          <StyledMaximumReachedText key={i}>{text}</StyledMaximumReachedText>
        ))}
        <StyledMaximumReachedButtons>
          <PrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onClose}>
            閉じる
          </PrimaryButton>
        </StyledMaximumReachedButtons>
      </StyledMaximumReachedContainer>
    </StyledMaximumReached>
  );
};

export default MaximumReached;

const StyledMaximumReached = styled(DialogBase)`
  position: relative;
`;

const StyledMaximumReachedContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledMaximumReachedText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledMaximumReachedButtons = styled.div`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 100px;
  }
`;
