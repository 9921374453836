import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@meettry/engineer/components/atoms/Input';

export const InputScoutNotification = (props) => {
  const { value, isEnableScout, onChange } = props;
  return (
    <StyledInputNotification>
      <RadioButton
        value={1}
        id="scout_notification"
        name="scoutNotification"
        checked={value === 1}
        disabled={!isEnableScout}
        label="メールで通知を受け取る"
        onChange={onChange}
      />
      <RadioButton
        value={0}
        id="scout_notification"
        name="scoutNotification"
        checked={value === 0}
        disabled={!isEnableScout}
        label="メールで通知を受け取らない"
        onChange={onChange}
      />
    </StyledInputNotification>
  );
};

const StyledInputNotification = styled.div`
  > *:not(:first-child) {
    margin-left: 50px;
  }
`;
