import React from 'react';

import logoImage from '@meettry/ui-components/images/mainLogo/main_logo.png';

type MaintenanceTimeProps = {
  year: number;
  month: number;
  day: number;
  dayOfWeek: string;
  startTime: string;
  endTime: string;
};

const MaintenanceTime: React.FC<MaintenanceTimeProps> = (props) => {
  return (
    <div className="maintenance-time">
      <span>メンテナンス時間</span>
      <span>
        {props.year}年{props.month}月{props.day}日（{props.dayOfWeek}）{props.startTime} 〜{' '}
        {props.endTime}
      </span>
      <span>※延長する場合があります</span>
    </div>
  );
};

export default () => {
  return (
    <div className="maintenance">
      <div className="maintenance-logo">
        <img src={logoImage} alt="meettryのロゴマーク" />
      </div>
      <span className="maintenance-text-main">メンテナンス中です</span>
      <span className="maintenance-text-sub">システムメンテナンスのためご利用いただけません</span>
      <small>© meettry All rights reserved.</small>
    </div>
  );
};
