import { InputAccountProps } from '@meettry/company/component/organisms/changePassword';
import { InputCompanyProps } from '@meettry/company/component/organisms/corporateInfo';
import { InputBillProps } from '@meettry/company/component/organisms/billDestination';
import { StateActionType } from '@meettry/company/component/pages/register';
import { SetStateAction } from 'react';

import auth from '@meettry/ui-components/utils/firebase';

const MAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
const PW_REGEX = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
const NUMBER_REGEX = /^[0-9０-９]+$/;
const TEL_REGEX = /^[0-9０-９-ー]+$/;
const URL_REGEX = /^http(s)?:\/\/([\w-]+\.)+[\w-]+(\/[\w-.\/?%&=]*)?/;

export const signUp = (
  value: string,
  changeValue: React.Dispatch<React.SetStateAction<string>>
) => {
  !value
    ? changeValue('EMPTY')
    : !PW_REGEX.test(value)
    ? changeValue('WEAK_PASSWORD')
    : changeValue('none');
};

export const login = (
  value: string,
  changeValue: React.Dispatch<React.SetStateAction<string>>,
  target: string
) => {
  target === 'email'
    ? !value
      ? changeValue('EMPTY')
      : !MAIL_REGEX.test(value)
      ? changeValue('INVALID_EMAIL')
      : changeValue('none')
    : !value
    ? changeValue('EMPTY')
    : value.length < 6
    ? changeValue('WEAK_PASSWORD')
    : changeValue('none');
};

export const register = (value: string | Array<string>, target: string = 'common') => {
  if (Array.isArray(value)) {
    if (!value.length) {
      return 'EMPTY';
    } else {
      return 'none';
    }
  } else if (!value) {
    return 'EMPTY';
  } else if (target === 'email' && !MAIL_REGEX.test(value)) {
    return 'INVALID_EMAIL';
  } else if (target === 'tel' && !TEL_REGEX.test(value)) {
    return 'INVALID_TEL';
  } else {
    return 'none';
  }
};

export const registerAll = (
  item: Array<{
    name: string;
    state: {
      value: string | Array<string>;
      error?: string;
    };
  }>,
  dispatch: React.Dispatch<StateActionType>
) => {
  item.forEach((obj) => {
    if (Array.isArray(obj.state.value)) {
      dispatch({
        type: 'PRESSED_SUBMIT',
        key: obj.name,
        state: obj.state,
        errorText: !obj.state.value.length ? 'EMPTY' : 'none'
      });
    } else if (!obj.state.value) {
      dispatch({ type: 'PRESSED_SUBMIT', key: obj.name, state: obj.state, errorText: 'EMPTY' });
    } else if (obj.name === 'email' && !MAIL_REGEX.test(obj.state.value)) {
      dispatch({
        type: 'PRESSED_SUBMIT',
        key: obj.name,
        state: obj.state,
        errorText: 'INVALID_EMAIL'
      });
    } else if (obj.name === 'tel' && !TEL_REGEX.test(obj.state.value)) {
      dispatch({
        type: 'PRESSED_SUBMIT',
        key: obj.name,
        state: obj.state,
        errorText: 'INVALID_TEL'
      });
    } else {
      dispatch({ type: 'PRESSED_SUBMIT', key: obj.name, state: obj.state, errorText: 'none' });
    }
  });
};

export const changeEmail = (
  item: InputAccountProps,
  setMailAddressError: React.Dispatch<SetStateAction<string>>
) => {
  !item.mailAddress
    ? setMailAddressError('empty')
    : !MAIL_REGEX.test(item.mailAddress)
    ? setMailAddressError('fault')
    : setMailAddressError('none');
};

export const changePassword = (
  item: InputAccountProps,
  setCurrentPasswordError: React.Dispatch<SetStateAction<string>>,
  setNewPasswordError: React.Dispatch<SetStateAction<string>>,
  setConfirmPasswordError: React.Dispatch<SetStateAction<string>>,
  changeSendable: Function
) => {
  const newPWErrorMessage = !item.newPassword
    ? 'empty'
    : !PW_REGEX.test(item.newPassword)
    ? 'fault'
    : 'none';

  setNewPasswordError(newPWErrorMessage);

  const confirmPWErrorMessage =
    item.newPassword && !item.confirmPassword
      ? 'empty'
      : item.confirmPassword && !PW_REGEX.test(item.confirmPassword)
      ? 'fault'
      : item.confirmPassword !== item.newPassword
      ? 'different'
      : 'none';

  setConfirmPasswordError(confirmPWErrorMessage);

  const reauthenticate = async () => {
    try {
      await auth.reauthenticate(item.currentPassword);
      setCurrentPasswordError('none');
      changeSendable(newPWErrorMessage === 'none' && confirmPWErrorMessage === 'none');
    } catch (error) {
      setCurrentPasswordError(error.code === 'auth/wrong-password' ? 'different' : error.code);
    }
  };

  if (!item.currentPassword) {
    setCurrentPasswordError('empty');
  } else if (!PW_REGEX.test(item.currentPassword)) {
    setCurrentPasswordError('fault');
  } else {
    reauthenticate();
  }
};

export const company = (
  item: InputCompanyProps,
  setCompanyNameError: React.Dispatch<SetStateAction<string>>,
  setPostalCodeError: React.Dispatch<SetStateAction<string>>,
  setStreetAddressError: React.Dispatch<SetStateAction<string>>,
  setPhoneNumberError: React.Dispatch<SetStateAction<string>>,
  setUrlError: React.Dispatch<SetStateAction<string>>
) => {
  !item.companyName ? setCompanyNameError('empty') : setCompanyNameError('none');

  item.postalCode[0].length === 0 && item.postalCode[1].length === 0
    ? setPostalCodeError('none')
    : item.postalCode[0].length === 0 || item.postalCode[1].length === 0
    ? setPostalCodeError('empty')
    : item.postalCode.forEach((element) => {
        !NUMBER_REGEX.test(element) ? setPostalCodeError('fault') : setPostalCodeError('none');
      });

  !item.streetAddress ? setStreetAddressError('empty') : setStreetAddressError('none');

  item.phoneNumber.forEach((element) => {
    element.length === 0
      ? setPhoneNumberError('empty')
      : !NUMBER_REGEX.test(element)
      ? setPhoneNumberError('fault')
      : setPhoneNumberError('none');
  });

  !item.url
    ? setUrlError('none')
    : !URL_REGEX.test(item.url)
    ? setUrlError('fault')
    : setUrlError('none');
};

export const bill = (
  item: InputBillProps,
  setCompanyNameError: React.Dispatch<SetStateAction<string>>,
  setPostalCodeError: React.Dispatch<SetStateAction<string>>,
  setStreetAddressError: React.Dispatch<SetStateAction<string>>,
  setRepresentativeNameError: React.Dispatch<SetStateAction<string>>,
  setPhoneNumberError: React.Dispatch<SetStateAction<string>>
) => {
  !item.companyName ? setCompanyNameError('empty') : setCompanyNameError('none');

  item.postalCode[0].length === 0 && item.postalCode[1].length === 0
    ? setPostalCodeError('none')
    : item.postalCode[0].length === 0 || item.postalCode[1].length === 0
    ? setPostalCodeError('empty')
    : item.postalCode.forEach((element) => {
        !NUMBER_REGEX.test(element) ? setPostalCodeError('fault') : setPostalCodeError('none');
      });

  !item.streetAddress ? setStreetAddressError('empty') : setStreetAddressError('none');

  !item.representativeName
    ? setRepresentativeNameError('empty')
    : setRepresentativeNameError('none');

  item.phoneNumber.forEach((element) => {
    element.length === 0
      ? setPhoneNumberError('empty')
      : !NUMBER_REGEX.test(element)
      ? setPhoneNumberError('fault')
      : setPhoneNumberError('none');
  });
};
