import React from 'react';
import styled from 'styled-components';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import {
  TopTitles,
  SectionWrapper,
  headerMenuList
} from '@meettry/ui-components/components/organisms/Tops/utils';

type mode = {
  mode?: 'company' | 'engineer';
};

type TopFeaturePropsType = mode & {
  featuresList: Array<{
    img: any;
    title: Array<string>;
    content: string;
    note: string;
  }>;
};

const TopFeature: React.FC<TopFeaturePropsType> = (props) => {
  const title = '特長';
  const sectionId = headerMenuList.find((obj) => obj.name === title);
  return (
    <TopFeatureWrapper id={sectionId && sectionId.target}>
      <SectionWrapper>
        <TopTitles>
          <div>FEATURE</div>
          <div>{title}</div>
        </TopTitles>
        <TopFeaturesContainer>
          {props.featuresList.map((li, i) => (
            <FeaturesContainer key={i}>
              <FeaturesImage src={li.img} />
              <FeatureMessageBox mode={props.mode}>
                <FeaturesTitle mode={props.mode}>
                  {li.title.map((title, j) => (
                    <div key={j}>{title}</div>
                  ))}
                </FeaturesTitle>
                <FeaturesContent>
                  {li.content}
                  {li.note ? <span>{li.note}</span> : null}
                </FeaturesContent>
              </FeatureMessageBox>
            </FeaturesContainer>
          ))}
        </TopFeaturesContainer>
      </SectionWrapper>
    </TopFeatureWrapper>
  );
};

const TopFeatureWrapper = styled.div`
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const TopFeaturesContainer = styled.div`
  display: flex;
  margin-top: 64px;
  ${mediaQuery.lessThan('breakPoint')`
  flex-direction: column;
`};
`;

const FeaturesContainer = styled.div`
  width: calc(100% - 100px / 3);
  ${mediaQuery.greaterThan('breakPoint')`
  &:nth-of-type(2) {
    margin: 0 50px;
  }
`};
  ${mediaQuery.lessThan('breakPoint')`
  width: 100%;
  margin: 0;
  &:nth-of-type(2) {
    margin: 78px 0;
  }
`};
`;

const FeaturesImage = styled.img`
  width: 100%;
`;

const FeatureMessageBox = styled.div<mode>`
  padding: 20px ${(props) => (props.mode === 'company' ? '8px' : '30px')} 0;
`;

const FeaturesTitle = styled.div<mode>`
  ${FONT_DEFINITIONS.SECTION_BOLD};
  color: #0fafa9;
  text-align: center;
  margin-bottom: 20px;
  ${mediaQuery.lessThan('minTablet')`
    font-size: 16px;
  `};
  div {
    ${mediaQuery.greaterThan('breakPoint')`
      min-height: 36px;
    `};
    ${(props) => mediaQuery.lessThan('breakPoint')`
      ${props.mode === 'company' ? `display: inline-block;` : null}
    `};
  }
`;

const FeaturesContent = styled.p`
  ${FONT_DEFINITIONS.DAFAULT};
`;

export default TopFeature;
