export const MEGA_NUMBER = 1000000;
export const KILO_NUMBER = 1000;

export const simplifyDigits = (num: number): string => {
  if (num > MEGA_NUMBER) {
    const megaNum = String(num / MEGA_NUMBER);
    return `${ (megaNum.search(/\\./g) !== -1) ? megaNum.match(/^\\d+\\.\\d{2}/g)[0] : megaNum}m`;
  } else if (num > KILO_NUMBER) {
    const kiloNum = String(num / KILO_NUMBER);
    return `${ (kiloNum.search(/\\./g) !== -1) ? kiloNum.match(/^\\d+\\.\\d{2}/g)[0] : kiloNum}k`;
  } else {
    return String(num);
  }
};
