import React, { useState, useRef, useEffect, ReactNode } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { breakPoint, mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { TopMainVisual } from '@meettry/ui-components/components/organisms/Tops/TopMainVisual';
import { useWindowWidth } from '@meettry/ui-components/hooks/useWindowWidth';
import TopDecorate from '@meettry/ui-components/images/commons/top_decorate.png';

type HamburgerMenuProps = {
  children?: ReactNode;
  isOpen: boolean;
  mode?: 'company' | null;
};

type HamburgerMenuButtonColorType = 'white' | 'primary';

type HamburgerMenuButtonProps = {
  onClick: (changedOpen: boolean) => void;
  isOpen: boolean;
  theme?: HamburgerMenuButtonColorType;
};

export type UseHamburgerOpenType = [boolean, (isOpen: boolean, cb?: Function) => void];
export const useHamburgerOpen = (): UseHamburgerOpenType => {
  const [isOpenHamburger, displayOpenHamburger] = useState(false);
  const [windowPosition, setWindowPosition] = useState(0);
  const callbackFunction = useRef<Function | null>(null);
  const windowWidth = useWindowWidth();
  const location = useLocation();
  useEffect(() => {
    const body = document.getElementsByTagName('body')[0];
    body.style.overflow = isOpenHamburger ? 'hidden' : '';
    if (isOpenHamburger) {
      setWindowPosition(window.scrollY);
    } else {
      !!windowPosition && window.scrollTo({ top: windowPosition });
    }
    if (typeof callbackFunction.current === 'function') {
      callbackFunction.current();
      callbackFunction.current = null;
    }
  }, [isOpenHamburger]);

  //windowのリサイズによって、ハンバーガーメニュー の使用不使用を判断
  useEffect(() => {
    if (isOpenHamburger && windowWidth > breakPoint) displayOpenHamburger(false);
  }, [windowWidth]);

  const displayOpenHamburgerFunction = (isOpen: boolean, cb?: Function): void => {
    if (typeof cb === 'function' && windowWidth > breakPoint) {
      cb();
    } else {
      if (!!cb) callbackFunction.current = cb;
      displayOpenHamburger(isOpen);
    }
  };

  //path変更でハンバーガー閉じる
  useEffect(() => {
    if (isOpenHamburger) displayOpenHamburger(false);
  }, [location.pathname]);

  return [isOpenHamburger, displayOpenHamburgerFunction];
};

export const HamburgerMenu: React.FC<HamburgerMenuProps> = (props) => {
  const root = document.getElementById('root');
  return (
    root &&
    ReactDOM.createPortal(
      <HamburgerMenuContainer isOpen={props.isOpen} decorate={TopDecorate} mode={props.mode}>
        <HamburgerMenuArea isOpen={props.isOpen}>{props.children}</HamburgerMenuArea>
      </HamburgerMenuContainer>,
      root
    )
  );
};

const HamburgerMenuContainer = styled(TopMainVisual)`
  ${mediaQuery.greaterThan('breakPointPC')`
    display: none;
  `}
  ${(props) => mediaQuery.lessThan('breakPoint')`
    position: fixed;
    width: 100vw;
    overflow: scroll;
    top: 0;
    left: 0;
    background-color: ${
      props.mode === 'company' ? COLOR_DEFINITIONS.MAIN.PRIMARY : COLOR_DEFINITIONS.BG.MAIN
    };
    z-index: 100;
    transition: height 0.5s;
    height: ${props.isOpen ? '100vh' : '0'};
  `}
  &:before {
    ${(props) => (props.isOpen ? 'position: fixed;' : '')}
  }
`;

const HamburgerMenuArea = styled.div<{ isOpen: boolean }>`
  ${(props) => mediaQuery.greaterThan('minTablet')`
    padding: ${props.isOpen ? '204px 40px 20px' : '0 40px'};
    `};
  ${(props) => mediaQuery.lessThan('minTablet')`
    padding: ${props.isOpen ? '90px 40px  20px' : '0 40px'};
    `};
`;

export const HamburgerMenuButton: React.FC<HamburgerMenuButtonProps> = ({
  isOpen,
  onClick,
  theme = 'primary'
}) => {
  return (
    <HamburgerMenuWrapper onClick={() => onClick(!isOpen)}>
      <HamburgerMenuBtn isOpen={isOpen} theme={theme} />
    </HamburgerMenuWrapper>
  );
};

const HamburgerMenuWrapper = styled.div`
  position: relative;
  width: 38px;
  height: 30px;
  display: none;
  ${mediaQuery.lessThan('breakPoint')`
  display: block;
 `}
`;

const HamburgerMenuBtn = styled.span<{ isOpen: boolean; theme: HamburgerMenuButtonColorType }>`
  ${(props) => mediaQuery.lessThan('breakPoint')`
position: absolute;
top: 0;
  display: block;
  margin-left: auto;
  width: 36px;
  height: 4px;
  background-color: ${(props) =>
    props.theme === 'primary' ? COLOR_DEFINITIONS.MAIN.PRIMARY : COLOR_DEFINITIONS.BG.WHITE};
  border-radius: 2px;
  transition: transform 0.5s;
  &::before{
    content: '';
    position: absolute;
    top: 12px;
    width: 36px;
    height: 4px;
    background-color: ${(props) =>
      props.theme === 'primary' ? COLOR_DEFINITIONS.MAIN.PRIMARY : COLOR_DEFINITIONS.BG.WHITE};
    border-radius: 2px;
    opacity: 1;
    transition: transform 0.5s, opacity 1s;
  }
  &::after{
    content: '';
    position: absolute;
    top: 23px;
    width: 36px;
    height: 4px;
    background-color: ${(props) =>
      props.theme === 'primary' ? COLOR_DEFINITIONS.MAIN.PRIMARY : COLOR_DEFINITIONS.BG.WHITE};
    border-radius: 2px;
    transition: transform 0.5s;
  }
  ${
    props.isOpen &&
    `
  transform: rotate(45deg) translate(10px, 8px);
  &::before{
    opacity: 0;
    transform: rotate(-45deg) translate(45px, -10px);
  }
  &::after{
    transform: rotate(-90deg) translate(22px, -1px);
  }
  `
  }
 `}
`;
