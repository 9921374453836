import React from 'react';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

export type FooterLinksProps = {
  footerLinks: Array<{
    name: string;
    path: string;
  }>;
  currentPage?: string;
};

const FooterLinks: React.FC<FooterLinksProps> = (props) => {
  const remainBox = props.footerLinks.length % 2;

  return (
    <StyledFootNavLists currentPage={props.currentPage}>
      {props.footerLinks.map((link) => {
        return (
          <StyledFootNavList key={link.name} currentPage={props.currentPage}>
            {link.path.indexOf('http') > -1 ? (
              <StyledLink
                onClick={(e) => {
                  e.preventDefault();
                  window.open(link.path);
                }}
              >
                {link.name}
              </StyledLink>
            ) : (
              <StyledListLink to={link.path}>{link.name}</StyledListLink>
            )}
          </StyledFootNavList>
        );
      })}
      {props.currentPage
        ? Array.from({ length: remainBox }).map((val, i) => (
            <StyledFootNavList key={i} currentPage={props.currentPage} />
          ))
        : null}
    </StyledFootNavLists>
  );
};

export default FooterLinks;

// styled-component
const StyledFootNavLists = styled.ul<{ currentPage?: string }>(
  (props) => `
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px auto;
    flex-wrap: wrap;
      ${mediaQuery.lessThan('breakPoint')`
      margin: 30px auto;
    `.join('')};
    ${mediaQuery.lessThan('minTablet')`
      flex-direction: ${props.currentPage ? 'column' : 'row'};
    `.join('')};
`
);

const StyledFootNavList = styled.li<{ currentPage?: string }>(
  (props) =>
    `
  ${mediaQuery.greaterThan('breakPointPC')`
    &:not(:last-child) {
      margin-right: 60px;
    }
  `.join('')};
  ${mediaQuery.lessThan('breakPoint')`
    width: ${props.currentPage ? 'calc(100% / 2)' : 'auto'};
    text-align: center;
    margin-bottom: ${props.currentPage ? ' 56px' : '0'};
    &:not(:last-child) {
      ${!props.currentPage && 'margin-right: 60px;'}
    }
    &&:last-child,:nth-last-child(2){
      margin-bottom: 0;
    }
  `.join('')};
  ${mediaQuery.lessThan('minTablet')`
    width: ${props.currentPage ? '100%' : 'auto'};
    &:not(:last-child) {
      margin-bottom: ${props.currentPage ? '20px' : '0'};
      ${!props.currentPage && 'margin-right: 60px;'}
    }
  `.join('')};
`
);

const CommonListStyle = `
&& {
  text-decoration-line: none;
  font-size: 14px;
  line-height: 24px;
  cursor: pointer;
}
`;

const StyledListLink = styled(NavLink)`
  ${CommonListStyle}
`;

const StyledLink = styled.a`
  ${CommonListStyle}
`;
