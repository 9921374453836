import dayjs from 'dayjs';

/**
 * Constants
 */
type DateFormat =
  | 'YYYY/MM/DD'
  | 'HH:mm'
  | 'YYYY/MM/DD HH:mm'
  | 'YYYY年MM月DD日'
  | 'YYYYMMDD'
  | 'YYYYMM';
export const DATE_FORMAT: { [format in string]: DateFormat } = {
  DATE: 'YYYY/MM/DD',
  TIME: 'HH:mm',
  DATE_TIME: 'YYYY/MM/DD HH:mm',
  DATE_JP: 'YYYY年MM月DD日',
  DATE_INT: 'YYYYMMDD',
  YEAR_MONTH_INT: 'YYYYMM'
};

const DAY = 24 * 60 * 60 * 1000;
const MONTH = 30;
const YEAR = 365;
const CURRENT_DATE = new Date().toISOString().slice(0, 10);

/**
 * formatDate
 * 指定したformatに変換
 */
export const formatDate = (
  date: Date | string | undefined,
  format: DateFormat = DATE_FORMAT.DATE
) => {
  return dayjs(date).format(format);
};

/**
 * diffDate
 * ◯年◯ヶ月形式の文字列に変換
 */
// TODO(oomura, 2021/03/01): 将来的にフォーマット形式を第二引数に入れたい
export const diffDate = (startDate: Date, endDate: Date) => {
  const year = endDate.getFullYear() - startDate.getFullYear(),
    month = endDate.getMonth() - startDate.getMonth();
  return `${month < 0 ? year - 1 : year}年${month < 0 ? 12 - Math.abs(month) : month}ヵ月`;
};

/**
 * splitDateTime
 * // FIXME(oomura, 2021/03/01): 誰が作った関数かわからないから誰か説明書いて
 */
export const splitDateTime = (beforeStr: string) => beforeStr.split('T');

/**
 * getDaysBetween
 * // FIXME(oomura, 2021/03/01): 誰が作った関数かわからないから誰か説明書いて
 */
export const getDaysBetween = (beforeStr: string) => {
  const afterStr = splitDateTime(beforeStr);
  const UPDATE_DATE = afterStr[0];
  const differenceMs = Math.round(
    Math.abs((new Date(CURRENT_DATE).getTime() - new Date(UPDATE_DATE).getTime()) / DAY)
  );

  return differenceMs > MONTH ? UPDATE_DATE.replace(/-/gi, '/') : `${differenceMs} days ago`;
};

// firebaseのTimestamp形式を各種フォーマットに変換する
export const timestampTo = {
  strDateTime(timestamp: any) {
    if (!timestamp) return '';

    const datetime = timestamp.toDate();

    const yyyy = datetime.getFullYear();
    const MM = `0${datetime.getMonth() + 1}`.slice(-2);
    const dd = `0${datetime.getDate()}`.slice(-2);
    const hh = `0${datetime.getHours()}`.slice(-2);
    const mm = `0${datetime.getMinutes()}`.slice(-2);
    return `${yyyy}/${MM}/${dd} ${hh}:${mm}`;
  },
  strDate(timestamp: any) {
    if (!timestamp) return '';

    const datetime = timestamp.toDate();

    const yyyy = datetime.getFullYear();
    const MM = `0${datetime.getMonth() + 1}`.slice(-2);
    const dd = `0${datetime.getDate()}`.slice(-2);
    return `${yyyy}/${MM}/${dd}`;
  },
  strTime(timestamp: any) {
    if (!timestamp) return '';

    const datetime = timestamp.toDate();

    const hh = `0${datetime.getHours()}`.slice(-2);
    const mm = `0${datetime.getMinutes()}`.slice(-2);
    return `${hh}:${mm}`;
  }
};

/**
 * flexibleDateString
 */
export const flexibleDateString = (date: Date) => {
  const startTodayDate = dayjs(new Date()).startOf('day');
  const d = dayjs(date);
  const diff = startTodayDate.diff(d, 'day');

  if (diff === 1) {
    return '昨日';
  } else if (diff === 0) {
    return formatDate(date, DATE_FORMAT.TIME);
  } else {
    return formatDate(date, DATE_FORMAT.DATE);
  }
};

/**
 * daysToYears
 * 日にちを年に変換
 */
export const daysToYears = (x: number) => parseFloat((x / YEAR).toFixed(1));

/**
 * daysToYears
 * 日にちを月に変換
 */
export const daysToMonths = (x: number) => parseFloat((x / MONTH).toFixed(1));

/**
 * buildPeriodText
 * 年月日のテキストに変換
 */
export const buildPeriodText = (days: number, callback?: (val: number, unit: string) => void) => {
  let val;
  let unit;
  if (days > YEAR / 2) {
    val = daysToYears(days);
    unit = '年';
  } else if (days > MONTH / 2) {
    val = daysToMonths(days);
    unit = '月';
  } else {
    val = days;
    unit = '日';
  }
  if (callback) {
    return callback(val, unit);
  }
  return `${val}${unit}`;
};
