import { useContext } from 'react';
import { showPopup, removePopup, PopupContext } from '@meettry/ui-components/contexts/popup';

const usePopup = () => {
  const { state, dispatch } = useContext(PopupContext);
  const showSuccessPopup = (text = '通信に成功') => {
    dispatch(showPopup('success', text));
  };
  const showErrorPopup = (text = '通信に失敗') => {
    dispatch(showPopup('error', text));
  };
  const hidePopup = (id: string) => {
    dispatch(removePopup(id));
  };
  return {
    state,
    showSuccessPopup,
    showErrorPopup,
    hidePopup
  };
};

export default usePopup;
