import { useContext } from 'react';
import {
  showDialog,
  hideDialog,
  hideAllDialog,
  DialogContext
} from '@meettry/ui-components/contexts/dialog';

export const useDialog = () => {
  const { state, dispatch } = useContext(DialogContext);

  // 更新完了dialogの表示
  const showSuccessDialog = (text: string) => {
    dispatch(showDialog('SUCCESS', { text }));
  };

  // 未公開登録の確認用のdialogの表示
  const showVisibleRegisterConfirmationDialog = (
    texts: Array<string>,
    onRegisterVisible: () => void,
    onRegisterInvisible: () => void
  ) => {
    dispatch(
      showDialog('VISIBLE_REGISTER_CONFIRMATION', { texts, onRegisterVisible, onRegisterInvisible })
    );
  };

  // 削除確認用のdialogの表示
  const showDeleteConfirmationDialog = (
    texts: Array<string>,
    onAccept: () => void,
    onCancel: () => void
  ) => {
    dispatch(showDialog('DELETE_CONFIRMATION', { texts, onAccept, onCancel }));
  };

  // 削除確認用のdialogの表示
  const showCancelConfirmationDialog = (
    texts: Array<string>,
    onAccept: () => void,
    onCancel: () => void
  ) => {
    dispatch(showDialog('CANCEL_CONFIRMATION', { texts, onAccept, onCancel }));
  };

  // ユーザ詳細にてプロフィール変更確認用のdialogの表示
  const showProfileEditConfirmationDialog = (
    texts: Array<string>,
    onAccept: () => void,
    onDecline: () => void
  ) => {
    dispatch(showDialog('SCOUT_UNABLE_CONFIRMATION', { texts, onAccept, onDecline }));
  };

  // スカウトがオンにできないこと確認用のdialogの表示
  const showScoutUnableConfirmationDialog = (
    texts: Array<string>,
    onAccept: () => void,
    onDecline: () => void
  ) => {
    dispatch(showDialog('SCOUT_UNABLE_CONFIRMATION', { texts, onAccept, onDecline }));
  };
  // スカウトがオンになっていないこと確認用のdialogの表示
  const showActivateScoutConfirmationDialog = (onAccept: () => void, onDecline: () => void) => {
    dispatch(showDialog('ACTIVATE_SCOUT_CONFIRMATION', { onAccept, onDecline }));
  };

  // ピンが最大数に到達している際のdialogの表示
  const showMaximumReachedDialog = (texts: Array<string>, onClose: () => void) => {
    dispatch(showDialog('MAXIMUM_REACHED', { texts, onClose }));
  };

  // エラーを表現するdialogの表示
  const showErrorDialog = (texts: Array<string>) => {
    dispatch(showDialog('ERROR_MESSAGE', { texts }));
  };

  // お知らせを表現するdialogの表示
  const showNotificationDialog = (texts: Array<string>, onOk: () => void) => {
    dispatch(showDialog('NOTIFICATION_MESSAGE', { texts, onOk }));
  };

  // dialogを閉じる処理
  const closeDialog = () => {
    dispatch(hideDialog());
  };

  // dialogを全て閉じる処理
  const closeAllDialog = () => {
    dispatch(hideAllDialog());
  };
  return {
    state,
    showSuccessDialog,
    showVisibleRegisterConfirmationDialog,
    showCancelConfirmationDialog,
    showDeleteConfirmationDialog,
    showMaximumReachedDialog,
    showErrorDialog,
    showNotificationDialog,
    showActivateScoutConfirmationDialog,
    showProfileEditConfirmationDialog,
    showScoutUnableConfirmationDialog,
    closeDialog,
    closeAllDialog
  };
};
