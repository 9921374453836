import React, { useRef, useState, useEffect } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { get } from 'lodash';
import { FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { TopBtn } from '@meettry/ui-components/components/organisms/Tops/utils';
import { openRegisterWindow } from '@meettry/ui-components/components/organisms/Tops/TopRegister';

/**
 * フッター下部のタイプ別文言
 */
const CTA_MESSAGES = {
  customer: {
    messages: ['まずは気軽に登録！', 'たくさんのスカウトがあなたを待っています！'],
    btnMessage: 'ユーザー登録はこちら',
    btnLocate: '/signup'
  },
  enterprise: {
    messages: ['成功報酬を気にしない採用活動をしましょう'],
    btnMessage: 'お問い合わせはこちら',
    btnLocate: '/enterprise/contact'
  }
};

type FooterCTAEmpty = {
  height: number;
};

const TopFootCTA: React.FC = () => {
  const [isShow, changeDisplay] = useState(true);
  const [rendered, reRender] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const cta = useRef(null);
  const ctaMessages = location.pathname.includes('/enterprise')
    ? CTA_MESSAGES.enterprise
    : CTA_MESSAGES.customer;
  const ctaHeight = get(cta.current, 'clientHeight', 0);
  useEffect(() => {
    changeDisplay(location.pathname === '/' || location.pathname === '/enterprise');
    //refの再取得が難しい場合に行う再レンダリング
    if (!cta.current) reRender(true);
  }, [location]);

  useEffect(() => {
    if (rendered) reRender(false);
  }, [rendered]);
  return isShow ? (
    <>
      <FooterCTAEmpty height={ctaHeight} />
      <TopFootCTAContainer ref={cta}>
        <CallContainer>
          <CTAMessage>
            {ctaMessages.messages.map((msg, i) => (
              <span key={i}>{msg}</span>
            ))}
          </CTAMessage>
          <RegisterBtn
            color="contained"
            size="midium"
            onClick={() => {
              !ctaMessages.btnLocate ? openRegisterWindow() : history.push(ctaMessages.btnLocate);
            }}
          >
            {ctaMessages.btnMessage}
          </RegisterBtn>
        </CallContainer>
        <StyledIcon
          onClick={() => changeDisplay(!isShow)}
          iconColor="primary"
          iconName="close"
          fileType="png"
          width="16px"
          height="16px"
        />
      </TopFootCTAContainer>
    </>
  ) : null;
};

const FooterCTAEmpty = styled.div<FooterCTAEmpty>`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: #ffffff;
`;

const CallContainer = styled.div`
  display: flex;
  margin: 0 auto;
  ${mediaQuery.greaterThan('breakPoint')`
  width: 940px;
  justify-content: space-between;
  `};
  ${mediaQuery.lessThan('breakPoint')`
  width: 100%;
    flex-direction: column;
   `}
`;

const TopFootCTAContainer = styled.div`
  position: fixed;
  display: flex;
  bottom: 0;
  width: calc(100vw - 60px);
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px 30px;
  vertical-align: middle;
`;

const CTAMessage = styled.p`
  ${mediaQuery.greaterThan('breakPointPC')`
  ${FONT_DEFINITIONS.CONTENT};
  margin: auto 40px auto 0;
  height: 35px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  text-align: center;
    span{
      display: block;
    }
  `}
  ${mediaQuery.between('tablet', 'breakPoint')`
${FONT_DEFINITIONS.MESSAGE_CTA_BOLD};
`}
  ${mediaQuery.between('sp', 'tablet')`
  ${FONT_DEFINITIONS.CONTENT};
   `}
   ${mediaQuery.lessThan('sp')`
   ${FONT_DEFINITIONS.SMALL_BOLD};
   `}
`;

const RegisterBtn = styled(TopBtn)`
  ${mediaQuery.greaterThan('breakPointPC')`
margin-top: 0;
`};
  ${mediaQuery.between('minTablet', 'breakPoint')`
margin: 30px auto 0;
 `}
  ${mediaQuery.lessThan('minTablet')`
margin: 16px auto 0;
 `}
`;

const StyledIcon = styled(Icon)`
  ${mediaQuery.greaterThan('overPCSize')`
 position: absolute;
 top: 50%;
 right: 0;
 transform: translate(-200%, -50%);
`}
  ${mediaQuery.between('breakPoint', 'overPCSize')`
margin: auto 0 auto auto;
`};
  ${mediaQuery.lessThan('breakPoint')`
position: absolute;
top: 20px;
right: 30px;
`};
`;

export default TopFootCTA;
