export const ONE_TIME_TOKEN_NAME = 'ott';
export const COOKIE_SID = 'sid';
export const GQL_ERROR_NAME = 'gqlErrors';

/**
 * タグカテゴリ
 */
export const TAG_CATEGORY = {
  LANGUAGE: 'LANGUAGE' as const,
  FRAMEWORK: 'FRAMEWORK' as const,
  DATABASE: 'DATABASE' as const,
  INFRASTRUCTURE: 'INFRASTRUCTURE' as const,
  OS: 'OS' as const,
  TOOL: 'TOOL' as const
} as const;

export const GENDER = [
  {
    value: 0,
    label: '不明'
  },
  {
    value: 1,
    label: '男性'
  },
  {
    value: 2,
    label: '女性'
  },
  {
    value: 9,
    label: '適用不能'
  }
];

export const SEARCH_TYPE = {
  USER: 'user',
  RESUME: 'resume',
  CHALLENGE: 'challenge'
};

const THEME = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary'
};

const SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large'
};

export const BUTTON = {
  THEME,
  SIZE
};

export const SELECTBOX = {
  THEME,
  SIZE
};

export const CHECKBOX = {
  THEME,
  SIZE
};

export const TEXTAREA = {
  THEME,
  SIZE
};

/**
 * MEMO(パク)：バリデーションを臨時に作成。もし確定したら、コメント削除
 */
export const VALIDATION = {
  EMAIL: {
    EMPTY: 'メールアドレスは必須です。',
    INCORRECT: 'メールアドレスの形式を入力してください。'
  },
  PASSWORD: {
    EMPTY: 'パスワードは必須です。',
    INCORRECT: '8〜15文字で半角の英小文字・大文字、半角数字、記号の全てを含んでください。'
  },
  NICKNAME: {
    EMPTY: 'ニックネームは必須です。',
    INCORRECT: 'ニックネームには英数字を使用してください',
    OVER_LENGTH: 'ニックネームは39文字以内にしてください',
    FORBIDDEN: '入力されたニックネームは使用できません'
  }
};

//MEMO(aida) 経歴フォームの入力制限
export const RESUME_FORM_INPUT = {
  LENGTH: {
    TITLE: 50,
    WORK_DETAIL: 400
  }
};
//MEMO(aida) チャレンジの入力制限
export const CHALLENGE_FORM_INPUT = {
  LENGTH: {
    TITLE: 50,
    CHALLENGE_DETAIL: 400
  }
};
//MEMO(aida) ニックネームの入力制限
export const NICKNAME_INPUT = {
  LENGTH: {
    NICKNAME: 39
  }
};
//MEMO(aida) プロフィール編集の入力制限
export const PROFILE_EDIT_INPUT = {
  LENGTH: {
    BIO: 160
  }
};

/**
 * Chat
 */
export const MESSAGE_LOAD_LIMIT = 5;

/**
 * Campaign
 */
export const CAMPAIGN_TYPE = {
  FREE_SCOUT: 'FREE_SCOUT',
  FREE_MONTHLY_PRICE: 'FREE_MONTHLY_PRICE'
};
