import gql from 'graphql-tag';
import {
  FRAG_RESUME_LIST,
  FRAG_RESUME_LIST_ITEM
} from '@meettry/ui-components/queries/user_page/fragments';

// //create resume
// export const CREATE_ENGINEER_RESUME = gql``;
export const CREATE_RESUME = gql`
  mutation CreateResume($input: CreateResumeInput!) {
    createResume(input: $input) {
      resume {
        ...ResumeListItem
      }
    }
  }

  ${FRAG_RESUME_LIST_ITEM}
`;
// //update resume
// export const UPDATE_ENGINEER_RESUME = gql``;

export const UPDATE_RESUME = gql`
  mutation UpdateResume($input: UpdateResumeInput!) {
    updateResume(input: $input) {
      resume {
        ...ResumeListItem
      }
    }
  }

  ${FRAG_RESUME_LIST_ITEM}
`;
export const UPDATE_RESUME_PINNED = gql`
  mutation UpdateResume($id: ID!, $isPinned: Boolean!) {
    updateResume(input: { id: $id, isPinned: $isPinned }) {
      resume {
        id
        isPinned
      }
    }
  }
`;
export const UPDATE_RESUME_VISIBLE = gql`
  mutation UpdateResume($id: ID!, $isVisible: Boolean!) {
    updateResume(input: { id: $id, isVisible: $isVisible }) {
      resume {
        id
        isVisible
        isPinned
      }
    }
  }
`;
// //delete resume
// export const DELETE_ENGINEER_RESUME = gql``;
export const DELETE_RESUME = gql`
  mutation DeleteResume($input: DeleteResumeInput!) {
    deleteResume(input: $input) {
      id
    }
  }
`;
//search resume
export const SEARCH_ENGINEER_RESUME = gql`
  query SearchEngineerResume($nicknames: [String]!, $queryString: String, $queryTags: [String!]) {
    users(nicknames: $nicknames) {
      id
      resumeList(filterParams: { queryString: $queryString, queryTags: $queryTags }) {
        ...ResumeList
      }
    }
  }
  ${FRAG_RESUME_LIST}
`;
