import React from 'react';
import styled from 'styled-components';

export const ProgressInput = (props) => {
  const { onClickMinus, onClickPlus, progressRate, onChangeProgressRate } = props;
  return (
    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <StyledItemFlex>
        <StyledChangeButton onClick={onClickMinus}>
          <span>-</span>
        </StyledChangeButton>
        <StyledProgressRate>
          {progressRate}
          <span>%</span>
        </StyledProgressRate>
        <StyledChangeButton onClick={onClickPlus}>
          <span>+</span>
        </StyledChangeButton>
      </StyledItemFlex>
      <StyledChallengeFormeRange
        onChange={onChangeProgressRate}
        value={progressRate}
        type="range"
        min="0"
        max="100"
      />
    </div>
  );
};

export default ProgressInput;

const StyledProgressRate = styled.span`
  box-sizing: border-box;
  min-width: 140px;
  color: #0fafa9;
  font-size: 32px;
  font-weight: bold;
  text-align: center;
  span {
    font-size: 20px;
    margin-left: 10px;
  }
`;

const StyledChangeButton = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #eee;
  width: 40px;
  height: 30px;
  border: #eeeeee solid 1px;
  border-radius: 5px;
  span {
    display: block;
    color: #0fafa9;
    font-size: 17px;
    ::selection {
      background: none;
    }
  }
`;

const StyledItemFlex = styled.div`
  display: flex;
  align-items: center;
`;

const StyledChallengeFormeRange = styled.input`
  width: 290px;
  height: 8px;
  appearance: none;
  outline: none;
  background: #eeeeee;
  position: relative;
  border-radius: 5px;
  cursor: pointer;
  &:before {
    content: '';
    display: block;
    width: ${(props) => props.value + `%`};
    height: 8px;
    background: #0fafa9;
    position: absolute;
    border-radius: 5px;
  }
  &::-webkit-slider-thumb {
    border: none;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 50%;
    box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
    position: relative;
    z-index: 9999;
    cursor: grab;
  }
`;
