import gql from 'graphql-tag';
import {
  FRAG_ENGINEER_TAGS_DATA,
  FRAG_USER_TAG
} from '@meettry/ui-components/queries/user_page/fragments';

export const GET_TAGS_DATA = gql`
  query GetTagsData($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      ...EngineerTagsData
    }
    techStackList {
      id
      categoryId
      name
      image {
        url
      }
    }
    techStackCategoryList {
      id
      name
      color
    }
  }

  ${FRAG_ENGINEER_TAGS_DATA}
`;

export const GET_USER_TAGS = gql`
  query GetUserTags($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      id
      userTags {
        ...UserTag
      }
    }
  }

  ${FRAG_USER_TAG}
`;
export const GET_ENABLE_ACCEPTING_SCOUT = gql`
  query($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      userDetail {
        enableAcceptingScout
      }
    }
  }
`;

export const FETCH_FOLLOWEE_QUERY = gql`
  query {
    loginSession {
      user {
        followees {
          nickname
        }
      }
    }
  }
`;

export const FOLLOW_USER_QUERY = gql`
  mutation($userId: ID!) {
    followUser(input: { userId: $userId }) {
      ok
    }
  }
`;

export const UNFOLLOW_USER_QUERY = gql`
  mutation($userId: ID!) {
    unfollowUser(input: { userId: $userId }) {
      ok
    }
  }
`;
