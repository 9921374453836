import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/react-hooks';
import styled from 'styled-components';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import { makeStyles } from '@material-ui/core/styles';
import { faFlagCheckered, faMonument, faPen, faRocket } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Button } from '@material-ui/core';
import { BasicTag } from '@meettry/ui-components/components/atoms/Tag';
import constants from '@meettry/ui-components/constants';
import { FETCH_ENGINEER_ACTIVITIES } from '@meettry/ui-components/queries/user_page';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { ResumeType, ChallengeType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type ActivityListType = {
  category: string;
  created: string;
  id: string;
  relatedId: number | null;
  month: string;
  key: string;
  list: Array<ActivityListType>;
  __typename?: string;
};

const ACTIVITY_TITLE_HEIGHT = 1;
const TIMELINE_BORDER = 0.3;
const TIMELINE_COLOR = COLOR_DEFINITIONS.LINE.SECONDARY;

const toYearMonthText = (datestr: string) => {
  const date = new Date(datestr);
  const year = date.getFullYear();
  const month = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ][date.getMonth()];

  return `${month} ${year}`;
};

const RESUME_CATEGORIES = Object.keys(constants.ACTIVITY_CATEGORY)
  .map((key) => {
    if (key.includes('RESUME')) {
      return key;
    }
    return null;
  })
  .filter((x) => x);
const CHALLENGE_CATEGORIES = Object.keys(constants.ACTIVITY_CATEGORY)
  .map((key) => {
    if (key.includes('CHALLENGE')) {
      return key;
    }
    return null;
  })
  .filter((x) => x);

const GROUPING_CATEGORIES = Object.keys(constants.ACTIVITY_CATEGORY)
  .map((key) => {
    if (key.includes('UPDATED')) {
      return key;
    }
    return null;
  })
  .filter((x) => x);

const getActivityTitle = (category: string, list: Array<ActivityListType | null>) =>
  ({
    ACCOUNT_CREATED: 'Joined Meettry',
    RESUME_CREATED: `Created ${list.length} career history`,
    RESUME_UPDATED: `Updated ${list.length} career history`,
    CHALLENGE_CREATED: `Created ${list.length} challenge`,
    CHALLENGE_UPDATED: `Updated ${list.length} challenge`
  }[category]);
const getActivityIcon = (category: string) =>
  ({
    ACCOUNT_CREATED: faRocket,
    RESUME_CREATED: faMonument,
    RESUME_UPDATED: faPen,
    CHALLENGE_CREATED: faFlagCheckered,
    CHALLENGE_UPDATED: faPen
  }[category]);
const itemsPerPage = 3;

const ActivityTimeline = () => {
  const { state } = useEngineer();
  const { nickname } = state;
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [yearAfter, setYearAfter] = useState(new Date().getFullYear());
  //MEMO(aida) fetchしたデータを上手く表示できないので、βではコメントアウト
  const [activities, setActivities] = useState<Array<ActivityListType>>([]);
  const [resumeList, setResumeList] = useState<Array<ResumeType>>([]);
  const [challengeList, setChallengeList] = useState<Array<ChallengeType>>([]);

  const { data: dataActivities, loading: loadingActivities } = useQuery(FETCH_ENGINEER_ACTIVITIES, {
    variables: { nicknames: [nickname] },
    fetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (!loadingActivities && dataActivities) {
      const {
        resumeList: resResumeList,
        challengeList: resChallengeList,
        activities: resActivities
      } = dataActivities.users[0];
      setActivities(resActivities);
      setChallengeList(resChallengeList.items);
      setResumeList(resResumeList.items);
    }
  }, [loadingActivities, dataActivities]);

  let activityItems = activities.filter((x) => {
    // 取得済みのresume と challengeに該当する関連データが存在しなければ、アクティビティを表示しない
    if (RESUME_CATEGORIES.includes(x.category)) {
      const resume = resumeList.find((y) => y.id === `${x.relatedId}`);
      if (!resume) {
        return null;
      }
    } else if (CHALLENGE_CATEGORIES.includes(x.category)) {
      const challenge = challengeList.find((y) => y.id === `${x.relatedId}`);
      if (!challenge) {
        return null;
      }
    }
    return x;
  });

  const years = activityItems
    .map((x) => new Date(x.created).getFullYear())
    .reverse()
    .filter((x, index, array) => array.findIndex((y) => y === x) === index);

  activityItems = activityItems
    .filter((x) => x && new Date(x.created).getFullYear() <= yearAfter)
    .sort((a, b) => Number(new Date(b.created)) - Number(new Date(a.created)))
    .reduce((acc: any, x, index) => {
      const prev = acc[acc.length - 1];
      const month = toYearMonthText(x.created);
      if (prev) {
        const grouping = GROUPING_CATEGORIES.includes(x.category);

        if (grouping && prev.month === month && prev.category === x.category) {
          acc[acc.length - 1] = { ...prev, list: [...prev.list, x] };
          return acc;
        }
      }
      acc.push({
        key: `${month}-${x.category}-${index}`,
        month,
        category: x.category,
        list: [x]
      });
      return acc;
    }, []);

  // pagination
  const hasMore = (page + 1) * itemsPerPage < activityItems.length;

  activityItems = activityItems.slice(0, (page + 1) * itemsPerPage);

  const months = activityItems
    .map((x) => x.month)
    .filter((x, index, array) => array.findIndex((y) => y === x) === index);

  const handleShowMore = () => {
    if (hasMore) {
      setPage(page + 1);
    }
  };

  const handleSetYear = (year: number) => () => {
    setYearAfter(year);
    setPage(0);
  };

  return loadingActivities ? (
    <Loader />
  ) : (
    <div className={classes.activityWrapper}>
      <div className={classes.activityContainer}>
        <div className={classes.activitDescriptionWrapper}>
          {months.map((month) => {
            const items = activityItems.filter((x) => x.month === month);
            return (
              <div key={month}>
                <div>
                  <div className={classes.activityContainer}>
                    <h5 className={classes.activityMonth}>{month}</h5>
                  </div>
                </div>
                <div className={classes.timelineWrapper}>
                  <ul className={classes.timelineContent}>
                    {items.map(({ key, category, list }) => (
                      <Activity
                        key={key}
                        resumes={resumeList}
                        challenges={challengeList}
                        category={category}
                        list={list}
                      />
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
          {hasMore ? <div className={classes.transparentBox} /> : null}
        </div>

        <Box flexShrink={0}>
          {years.map((year) => (
            <div className={classes.buttonWrapper} key={year}>
              <Button
                variant={yearAfter === year ? 'contained' : 'text'}
                className={classes.button}
                onClick={handleSetYear(year)}
              >
                {year}
              </Button>
            </div>
          ))}
        </Box>
        <div />
      </div>
      {hasMore && (
        <Button
          className={classes.arrowButton}
          variant="outlined"
          fullWidth
          onClick={handleShowMore}
        >
          もっと見る
        </Button>
      )}
    </div>
  );
};

export default ActivityTimeline;

type ActivityType = {
  category: string;
  list: Array<ActivityListType>;
  resumes: Array<ResumeType>;
  challenges: Array<ChallengeType>;
};

const Activity: React.FC<ActivityType> = ({ category, list, resumes, challenges }) => {
  const classes = useStyles();
  const icon = getActivityIcon(category);

  const activityList = list
    .map((activity, index, array) => {
      if (
        activity.relatedId &&
        Number((array || []).find((x) => x.relatedId === activity.relatedId)) < index
      ) {
        return null;
      }
      return activity;
    })
    .filter((x) => x)
    .sort((a, b) => (a !== null && b !== null ? Number(b.id) - Number(a.id) : 0));

  return (
    <li className={classes.activityList}>
      <div className={classes.activityIcon}>{!!icon ? <FontAwesomeIcon icon={icon} /> : null}</div>
      <div className={classes.activityTitle}>{getActivityTitle(category, activityList)}</div>
      <div className={classes.activityContents}>
        {activityList.length > 0
          ? activityList.map((activity, index) => {
              let description = null;
              let tags = null;
              if (RESUME_CATEGORIES.includes(category)) {
                const resume = (resumes || []).find(
                  (x) => activity && x.id === `${activity.relatedId}`
                );
                description = resume && resume.title;
                if (category === 'RESUME_CREATED') {
                  tags = resume?.tags;
                }
              } else if (CHALLENGE_CATEGORIES.includes(category)) {
                const challenge = (challenges || []).find(
                  (x) => activity && x.id === `${activity.relatedId}`
                );
                description = challenge && challenge.title;
                if (category === 'CHALLENGE_CREATED') {
                  tags = challenge?.tags;
                }
              }
              return (
                description && (
                  <div key={`${activity ? activity.id : 0}`} className={classes.description}>
                    <p className={classes.descriptionText}>
                      <span style={{ marginRight: '5px' }}>{`#${index + 1}`}</span>
                      {description}
                    </p>
                    {tags && tags.length ? (
                      <StyledTagWrapper>
                        <StyledTagContainer>
                          {tags.map((tag, index: number) => (
                            <BasicTag key={index}>{tag.displayName}</BasicTag>
                          ))}
                        </StyledTagContainer>
                      </StyledTagWrapper>
                    ) : null}
                  </div>
                )
              );
            })
          : null}
      </div>
    </li>
  );
};

const useStyles = makeStyles({
  separator: {
    height: '100%',
    position: 'relative',
    '&:before': {
      content: "' '",
      position: 'absolute',
      bottom: '50%',
      borderBottom: `1px solid ${TIMELINE_COLOR}`,
      width: '98%',
      left: '1rem',
      zIndex: '0'
    }
  },
  timelineContent: {
    paddingLeft: `${ACTIVITY_TITLE_HEIGHT * 0.5}rem`,
    marginLeft: `${ACTIVITY_TITLE_HEIGHT * 0.5}rem`,
    paddingTop: '30px',
    paddingBottom: '40px',
    borderLeft: `${TIMELINE_BORDER}rem solid ${TIMELINE_COLOR}`
  },
  timelineWrapper: {
    marginTop: '10px'
  },
  activityList: {
    position: 'relative',
    lineHeight: `${ACTIVITY_TITLE_HEIGHT}rem`,
    fontSize: `${ACTIVITY_TITLE_HEIGHT}rem`,
    paddingLeft: `${ACTIVITY_TITLE_HEIGHT}rem`
  },
  activityIcon: {
    display: 'inline-block',
    margin: `${ACTIVITY_TITLE_HEIGHT * 0.5}rem`,
    padding: `${ACTIVITY_TITLE_HEIGHT * 0.5}rem`,
    backgroundColor: TIMELINE_COLOR,
    borderRadius: `${ACTIVITY_TITLE_HEIGHT}rem`,
    position: 'absolute',
    height: `${ACTIVITY_TITLE_HEIGHT}rem`,
    width: `${ACTIVITY_TITLE_HEIGHT}rem`,
    left: `${-(ACTIVITY_TITLE_HEIGHT * 2 + TIMELINE_BORDER * 0.5)}rem`,
    top: `${-ACTIVITY_TITLE_HEIGHT}rem`,
    textAlign: 'center'
  },
  activityTitle: {
    fontSize: '14px'
  },
  activityContents: {
    padding: '20px 0 40px'
  },
  activitDescriptionWrapper: {
    paddingLeft: '40px',
    width: '597px',
    position: 'relative',
    '&:before': {
      content: "' '",
      display: 'block',
      position: 'absolute',
      top: '5px',
      left: '106px',
      width: 'calc(100% - 106px)',
      borderBottom: `1px solid ${TIMELINE_COLOR}`
    }
  },
  description: {
    fontWeight: 'bold',
    '& + &': {
      marginTop: '17px'
    }
  },
  descriptionText: {
    fontSize: '16px',
    fontWeight: 'bold',
    lineHeight: '1.5'
  },
  button: {
    margin: '0 10px 33px 33px',
    padding: '4px 34px',
    color: `${COLOR_DEFINITIONS.MAIN.PRIMARY}`
  },
  buttonWrapper: {
    '& > .MuiButton-contained': {
      color: `${COLOR_DEFINITIONS.MAIN.PRIMARY}`,
      backgroundColor: `${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN_LIGHT}`,
      boxShadow: 'none'
    }
  },
  activityContainer: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row'
  },
  arrowButton: {
    color: `${COLOR_DEFINITIONS.MAIN.PRIMARY}`,
    border: '0',
    padding: '0',
    fontSize: '16px'
  },
  activityWrapper: {
    paddingTop: '30px',
    marginTop: '20px',
    borderTop: `1px solid ${TIMELINE_COLOR}`
  },
  activityMonth: {
    fontSize: '12px',
    fontWeight: 'bold',
    position: 'relative'
  },
  transparentBox: {
    position: 'absolute',
    width: '100%',
    height: '100px',
    '&::after': {
      position: 'absolute',
      content: "''",
      left: '5px',
      top: '-80px',
      height: '80%',
      width: '100%',
      background: `linear-gradient(to bottom, ${COLOR_DEFINITIONS.BG.TRANSLUCENT.TRANSPARENT}, ${COLOR_DEFINITIONS.BG.WHITE} 50%)`
    }
  }
});
/* TODO(aida) もう少し整理したらちゃんとやる #コンポーネント化 */
const StyledTagWrapper = styled.div`
  margin-top: 10px;
`;

const StyledTagContainer = styled.div`
  margin-top: -5px;
  margin-left: -5px;
  & > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;
