import React, { useEffect, useState, useRef } from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { useMutation, useLazyQuery } from '@apollo/react-hooks';
import { useLocation, useHistory } from 'react-router';

import {
  useEngineer,
  useResume,
  useTagFilter,
  RESUME_SORT_OPTION
} from '@meettry/ui-components/hooks/useUserPage';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { makeHandleSubmit } from '@meettry/ui-components/components/organisms/UserPage/Resume/util';
import ResumeBox from '@meettry/ui-components/components/organisms/UserPage/Resume/ReumeBox';

import {
  ResumeCreate,
  ResumeEdit
} from '@meettry/ui-components/components/organisms/UserPage/Resume/ResumeForm';
import ResumeEmptyList from '@meettry/ui-components/components/organisms/UserPage/Resume/ResumeEmptyList';
import DetailHeader from '@meettry/ui-components/components/organisms/UserPage/Common/DetailHeader';
import {
  FETCH_ENGINEER,
  UPDATE_RESUME_PINNED,
  UPDATE_RESUME_VISIBLE,
  DELETE_RESUME,
  CREATE_RESUME,
  UPDATE_RESUME,
  SEARCH_ENGINEER_RESUME
} from '@meettry/ui-components/queries/user_page';
import { ListAnimation } from '@meettry/ui-components/utils/animation';
import { TagType, CreatedResumeType, ResumeType } from '@meettry/ui-components/types/userPage';

const ResumeList = () => {
  const history = useHistory();
  const location = useLocation<{ addMode?: boolean; selectedResumeId?: string | null }>();
  const { state, storeEngineer, isOwner } = useEngineer();
  const { selectedOtherTags, selectedTechStackSummaryTags } = useTagFilter();
  const { resumeList, userTags, nickname } = state;
  const {
    addResume,
    updateResume,
    removeResume,
    pinResume,
    visibleResume,
    setSort,
    showingList,
    setShowingListId
  } = useResume();
  const { showDeleteConfirmationDialog, showMaximumReachedDialog, closeDialog } = useDialog();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const { startLoading, endLoading } = useLoading();
  const [isSearch, setIsSearch] = useState(false);
  const [isAddMode, setIsAddMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);
  const [searchWord, updateSearchWord] = useState('');
  const [searchTag, updateSearchTag] = useState<Array<TagType>>([]);
  const [displaySearchWord, updateDisplaySearchWord] = useState('');

  const [selectedResumeId, setSelectedResumeId] = useState<string | null>(null);
  const selectedResumeRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    //MEMO(aida) レンダーされてからスクロールする
    setTimeout(() => {
      if (!selectedResumeRef.current) return;
      const targetRect = selectedResumeRef.current.getBoundingClientRect();
      //MEMO(aida) 96=>headerの高さ
      window.scrollTo({ top: targetRect.top - 96, behavior: 'smooth' });
      // MEMO(aida) スクロールさせたらクリックされた経歴のIDをクリアする
      setSelectedResumeId(null);
    }, 300);
  }, [selectedResumeRef.current]);

  //MEMO(minami) エンジニアの取得
  const [fetchEngineer, { data: engineerData, loading: loadingEngineer }] = useLazyQuery(
    FETCH_ENGINEER,
    {
      variables: { nicknames: [nickname] }
    }
  );

  //MEMO(minami) 取得したデータをstoreに格納する
  useEffect(() => {
    if (!loadingEngineer && engineerData?.users?.length > 0) {
      storeEngineer(engineerData.users[0]);
    }
  }, [engineerData, loadingEngineer]);

  const [searchQuery, { error: searchError, loading: searchLoading }] = useLazyQuery(
    SEARCH_ENGINEER_RESUME,
    {
      onCompleted: ({ users }) => {
        const resumeListId = users[0].resumeList.items.map(({ id }: { id: string }) => id);
        setShowingListId(resumeListId);
      }
    }
  );

  const onChangeSearchWord = (value: string) => {
    updateSearchWord(value);
  };
  const onSelectSearchTag = (tag: TagType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const updatedSearchTag = [...searchTag, tag];
    updateSearchTag(Array.from(new Set(updatedSearchTag)));
    updateSearchWord('');
  };
  const onSelectSearchTagDownshift = (tag: TagType) => {
    const updatedSearchTag = [...searchTag, tag];
    updateSearchTag(Array.from(new Set(updatedSearchTag)));
    updateSearchWord('');
  };
  const onRemoveSearchTag = (targetTag: TagType) => (e: React.MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    const updatedSearchTag = searchTag.filter((tag) => tag !== targetTag);
    updateSearchTag(updatedSearchTag);
  };
  const onSubmitSearch = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    searchQuery({
      variables: {
        nicknames: [nickname],
        queryString: searchWord,
        queryTags: searchTag.map((tag) => tag.displayName)
      }
    });
    updateDisplaySearchWord(searchWord);
    updateSearchWord('');
    updateSearchTag([]);
    setIsSearch(true);
  };

  //MEMO(aida) ダッシュボードから遷移時に追加状態で表示する。
  useEffect(() => {
    if (!location.state) return;
    if (!location.state.addMode) return;
    setIsAddMode(true);
    //MEMO(aida) リロード時にlocation.stateが保持されたままになるのでクリアしておく
    history.replace(location.pathname);
  }, []);

  //MEMO(aida) ダッシュボードからピン留めされたアイテムを選択しての遷移時の処理
  useEffect(() => {
    if (!location.state) return;
    if (!location.state.selectedResumeId) return;
    setSelectedResumeId(location.state.selectedResumeId);
    //MEMO(aida) リロード時にlocation.stateが保持されたままになるのでクリアしておく
    history.replace(location.pathname);
  }, []);

  //MEMO(aida) 技術サマリタグ・タグが選択された際にフィルタする
  useEffect(() => {
    //MEMO(aida) タグが選択されていないかつ検索状態じゃない場合は何もしない。
    if (!selectedOtherTags.length && !selectedTechStackSummaryTags.length && !isSearch) {
      return;
    }
    searchQuery({
      variables: {
        nicknames: [nickname],
        queryString: searchWord,
        queryTags: [...selectedOtherTags, ...selectedTechStackSummaryTags]
      }
    });
    setIsSearch(true);
  }, [selectedOtherTags, selectedTechStackSummaryTags]);

  const [
    mutateCreateResume,
    { error: createResumeError, loading: createResumeLoading }
  ] = useMutation(CREATE_RESUME, {
    onCompleted: (data) => {
      if (!data) return;
      const { createResume } = data;
      if (!createResume) return;
      addResume(createResume.resume);
      fetchEngineer();
      setIsAddMode(false);
      endLoading();
      showSuccessPopup('経歴を作成しました');
    }
  });

  const [
    mutateUpdateResume,
    { error: updateResumeError, loading: updateResumeLoading }
  ] = useMutation(UPDATE_RESUME, {
    onCompleted: (data) => {
      if (!data) return;
      const { updateResume: resUpdateResume } = data;
      if (!resUpdateResume) return;
      const { resume } = resUpdateResume;
      updateResume(resume);
      fetchEngineer();
      setIsEditMode(false);
      setEditingItemId(null);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  const [
    mutateDeleteResume,
    { error: deleteResumeError, loading: deleteResumeLoading }
  ] = useMutation(DELETE_RESUME, {
    onCompleted: (data) => {
      if (!data) return;
      const { deleteResume } = data;
      if (!deleteResume) return;
      removeResume(deleteResume.id);
      fetchEngineer();
      endLoading();
      showSuccessPopup('経歴を削除しました');
    }
  });

  const [
    mutateUpdateResumePinned,
    { error: updateResumePinnedError, loading: updateResumePinnedLoading }
  ] = useMutation(UPDATE_RESUME_PINNED, {
    onCompleted: (data) => {
      if (!data) return;
      const { updateResume: resUpdateResume } = data;
      if (!resUpdateResume) return;
      const { resume } = resUpdateResume;
      pinResume(resume);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  const [
    mutateUpdateResumeVisible,
    { error: updateResumeVisibleError, loading: updateResumeVisibleLoading }
  ] = useMutation(UPDATE_RESUME_VISIBLE, {
    onCompleted: (data) => {
      if (!data) return;
      const { updateResume: resUpdateResume } = data;
      if (!resUpdateResume) return;
      const { resume } = resUpdateResume;
      visibleResume(resume);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  //MEMO(aida) 経歴更新系のエラーハンドリング。onErrorが動かないのでuseEffectでErrorStateを監視する
  useEffect(() => {
    const isError =
      deleteResumeError ||
      createResumeError ||
      updateResumeVisibleError ||
      updateResumeError ||
      updateResumePinnedError;
    if (!isError) return;
    endLoading();
    if (deleteResumeError) showErrorPopup('経歴を削除できませんでした');
    if (createResumeError) showErrorPopup('経歴を作成できませんでした');
    if (updateResumeVisibleError) showErrorPopup('経歴を更新できませんでした');
    if (updateResumeError) showErrorPopup('経歴を更新できませんでした');
    if (updateResumePinnedError) showErrorPopup('経歴を更新できませんでした');
  }, [
    updateResumeVisibleError,
    updateResumePinnedError,
    deleteResumeError,
    updateResumeError,
    createResumeError
  ]);

  const onCreateResume = (data: ResumeType) => {
    if (createResumeLoading) return;
    startLoading('経歴を作成しています。');
    makeHandleSubmit(data, mutateCreateResume)();
  };

  const onUpdateResume = (data: ResumeType) => {
    if (updateResumeLoading) return;
    startLoading('経歴を更新しています。');
    makeHandleSubmit(data, mutateUpdateResume)();
  };

  const updateResumeVisible = (resume: CreatedResumeType) => {
    if (updateResumeVisibleLoading) return;
    startLoading('経歴を更新しています。');
    mutateUpdateResumeVisible({ variables: { id: resume.id, isVisible: !resume.isVisible } });
  };

  const updateResumePinned = (resume: CreatedResumeType) => {
    if (updateResumePinnedLoading) return;
    startLoading('経歴を更新しています。');
    mutateUpdateResumePinned({ variables: { id: resume.id, isPinned: !resume.isPinned } });
  };

  const onEditResume = (resume: CreatedResumeType) => (e: MouseEvent) => {
    e.preventDefault();
    setIsEditMode(true);
    setEditingItemId(resume.id);
  };
  const onUpdateResumeVisible = (resume: CreatedResumeType) => (e: MouseEvent) => {
    e.preventDefault();
    updateResumeVisible(resume);
  };

  const onUpdateResumePinned = (resume: CreatedResumeType) => (e: MouseEvent) => {
    e.preventDefault();
    const pinnedResumes = resumeList.items.filter((item: ResumeType) => item.isPinned);
    const maxPinnedCount = 5;

    if (!resume.isPinned && pinnedResumes.length >= maxPinnedCount) {
      showMaximumReachedDialog(
        [
          'ピン留めできる最大数に到達しています。',
          `ピン留めできる経歴は${maxPinnedCount}つまでです。`
        ],
        closeDialog
      );
      return;
    }
    updateResumePinned(resume);
  };

  const onClickDeleteResume = (resume: CreatedResumeType) => (e: MouseEvent) => {
    e.preventDefault();
    const deleteResume = () => {
      if (deleteResumeLoading) return;
      closeDialog();
      startLoading('経歴を削除しています。');
      mutateDeleteResume({ variables: { input: { id: resume.id } } });
    };
    showDeleteConfirmationDialog(
      [
        `${resume.title}の経歴詳細を削除いたします。`,
        `削除されたデータは、復元できませんがよろしいでしょうか？`
      ],
      deleteResume,
      closeDialog
    );
  };

  const onAddMode = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    setIsAddMode(true);
  };

  const onCloseAddMode = () => {
    setIsAddMode(false);
    closeDialog();
  };

  const onCloseEditMode = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    closeDialog();
  };

  const onSortResume = (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    setSort(e.target.value);
  };

  return (
    <>
      {isAddMode && (
        <ResumeCreate
          onClose={onCloseAddMode}
          loading={createResumeLoading}
          onSubmit={onCreateResume}
        />
      )}
      <DetailHeader
        titleImage="resume"
        title="経歴"
        onSelectSearchTagDownshift={onSelectSearchTagDownshift}
        onSelectSearchTag={onSelectSearchTag}
        searchWord={searchWord}
        onSubmitSearch={onSubmitSearch}
        onChangeSearchWord={onChangeSearchWord}
        userTags={userTags}
        onRemoveSearchTag={onRemoveSearchTag}
        placeholder="経歴を検索"
        searchTag={searchTag}
        isOwner={isOwner}
        onAddMode={onAddMode}
        sortOption={RESUME_SORT_OPTION}
        onSort={onSortResume}
      />
      {!showingList || searchLoading ? (
        <Loader />
      ) : (
        <StyledWrapper>
          <TransitionGroup>
            {showingList
              ? showingList.map((resume) => {
                  const isItemEditMode = isEditMode && editingItemId === resume.id;
                  const isSelectedFromDashboard = selectedResumeId === resume.id;
                  return (
                    <CSSTransition
                      key={resume.title + resume.id}
                      timeout={ListAnimation.timeout}
                      classNames={ListAnimation.classNames}
                    >
                      <StyledResumeItemWrapper
                        ref={isSelectedFromDashboard ? selectedResumeRef : null}
                      >
                        {!isItemEditMode && (
                          <ResumeBox
                            resume={resume}
                            isOwner={isOwner}
                            onEdit={onEditResume}
                            onVisible={onUpdateResumeVisible}
                            onPinned={onUpdateResumePinned}
                            onDelete={onClickDeleteResume}
                            searchWord={displaySearchWord}
                          />
                        )}
                        {isItemEditMode && (
                          <ResumeEdit
                            onClose={onCloseEditMode}
                            onSubmit={onUpdateResume}
                            resume={resume}
                            loading={updateResumeLoading}
                          />
                        )}
                      </StyledResumeItemWrapper>
                    </CSSTransition>
                  );
                })
              : null}
          </TransitionGroup>
          {!showingList.length && (
            <ResumeEmptyList isSearch={isSearch} isOwner={isOwner} onClick={onAddMode} />
          )}
        </StyledWrapper>
      )}
    </>
  );
};

export default ResumeList;

const StyledWrapper = styled.div`
  padding-top: 20px;
`;

const StyledResumeItemWrapper = styled(ListAnimation.animation)`
  :not(:first-child) {
    margin-top: 20px;
  }
`;
