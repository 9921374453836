import React, { useReducer, useState } from 'react';
import * as Validation from '@meettry/company/utils/validation';
import { Button } from '@meettry/company/component/atoms/btn';
import { MailAddress } from '@meettry/company/component/atoms/input';

export type InputAccountProps = {
  mailAddress: string;
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};

const initialState: InputAccountProps = {
  mailAddress: '',
  currentPassword: '',
  newPassword: '',
  confirmPassword: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;

type InputAction = {
  type: typeof CHANGE_TEXT;
  text: string;
  item: keyof InputAccountProps;
  num: number;
};

const changeInput: React.Reducer<InputAccountProps, InputAction> = (
  state: InputAccountProps,
  action: InputAction
) => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return { ...state, [action.item]: action.text };
    default:
      return state;
  }
};

const ChangeEmail: React.FC = () => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [mailAddressError, setMailAddressError] = useState('none');

  return (
    <div className="setting-container account">
      <div className="account-title">
        <h2>メールアドレス</h2>
      </div>
      <div className="account-contents">
        <MailAddress
          require={true}
          state={state.mailAddress}
          stateKey="mailAddress"
          dispatch={dispatch}
          error={mailAddressError}
        ></MailAddress>
      </div>
      <div className="btn-container">
        <Button
          buttonText="キャンセル"
          cls="btn third middle"
          onClick={() => console.log('キャンセル')}
        />
        <Button
          buttonText="変更する"
          cls="btn primary middle"
          onClick={() =>
            Validation.changeEmail(
              state,
              setMailAddressError
            )
          }
        />
      </div>
    </div>
  );
};

export default ChangeEmail;
