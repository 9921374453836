import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY, FONT_DEFINITIONS } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

export type HeaderMenuType = {
  name: string;
  target: string;
};
type HeaderMenuListType = Array<HeaderMenuType>;

type TopTitlesProps = {
  themeColor?: 'white';
};

type TopBtnProps = {
  size: 'small' | 'midium';
  color: 'outlined' | 'contained' | 'outlinedEnterprise' | 'containedEnterprise';
};

type SectionWrapper = {
  marginTop?: string;
  marginBottom?: string;
};

export const headerMenuList: HeaderMenuListType = [
  { name: '特長', target: 'jsi-feature-section' },
  { name: '使い方', target: 'jsi-flow-section' },
  { name: 'メトリィの想い', target: 'jsi-about-section' },
  { name: '採用担当者の皆様はこちら', target: '/enterprise' },
  { name: 'エンジニアの方はこちら', target: '/' }
];

export const TopTitles = styled.div((props: TopTitlesProps) => {
  const themeColor = props.themeColor === 'white' ? COLOR_DEFINITIONS.TEXT.WHITE : false;
  return `
    position: relative;
    text-align: center;
    color: ${themeColor || COLOR_DEFINITIONS.TEXT.MAIN}; 
    div{
      &:first-child {
        text-align: center;
        margin-bottom: 8px;
        font-family: ${FONT_FAMILY.ENG};
        ${FONT_DEFINITIONS.SMALL_BOLD}
        line-height: 18px;
      }
      &:last-child {
        text-align: center;
        font-size: 36px;
        line-height: 1.2;
        font-weight: bold;
        ${mediaQuery.lessThan('sp')`
          font-size: 24px;
        `.join('')};
      }
  
    }  
    &:after{
      content: '';
      position: absolute;
      bottom: -20px;
      left: calc(50% - 32px);
      height: 4px;
      width: 64px;
      background-color: ${themeColor || '#0fafa9'};
      border-radius: 2px;
    }
`;
});

const btnSize = [
  {
    size: 'small',
    padding: '10px 34px',
    border: 1,
    font: FONT_DEFINITIONS.SMALL_BOLD,
    width: 'auto',
    radius: '60px'
  },
  {
    size: 'midium',
    padding: '12.25px 58px',
    border: 2,
    font: FONT_DEFINITIONS.READ_TEXT_BOLD,
    width: '180px',
    radius: '30px'
  }
];

const btnColor = [
  {
    name: 'outlined',
    color: COLOR_DEFINITIONS.MAIN.PRIMARY,
    border: COLOR_DEFINITIONS.MAIN.PRIMARY,
    bg: 'transparent',
    hoverBg: COLOR_DEFINITIONS.HOVER.OUTLINED,
    hoverBorder: COLOR_DEFINITIONS.MAIN.PRIMARY
  },
  {
    name: 'outlinedEnterprise',
    color: COLOR_DEFINITIONS.MAIN.PRIMARY,
    border: COLOR_DEFINITIONS.MAIN.PRIMARY,
    bg: COLOR_DEFINITIONS.BG.WHITE,
    hoverBg: COLOR_DEFINITIONS.BG.MAIN,
    hoverBorder: COLOR_DEFINITIONS.BG.MAIN
  },
  {
    name: 'contained',
    color: COLOR_DEFINITIONS.TEXT.WHITE,
    border: COLOR_DEFINITIONS.MAIN.PRIMARY,
    bg: COLOR_DEFINITIONS.MAIN.PRIMARY,
    hoverBg: COLOR_DEFINITIONS.HOVER.MAIN,
    hoverBorder: COLOR_DEFINITIONS.HOVER.MAIN
  },
  {
    name: 'containedEnterprise',
    color: COLOR_DEFINITIONS.TEXT.WHITE,
    border: COLOR_DEFINITIONS.BG.WHITE,
    bg: COLOR_DEFINITIONS.MAIN.PRIMARY,
    hoverBg: COLOR_DEFINITIONS.HOVER.MAIN,
    hoverBorder: COLOR_DEFINITIONS.BG.WHITE
  }
];

export const TopBtn = styled.div((props: TopBtnProps) => {
  const size = btnSize.find((li) => li.size === props.size);
  const color = btnColor.find((li) => li.name === props.color);
  return `
    ${size && size.font};
  background-color: ${color && color.bg};
  margin-top: 80px;
  padding: ${size && size.padding};
  text-align: center;
  color: ${color && color.color};
  cursor: pointer;
  border: ${size && size.border}px solid ${color ? color.border : COLOR_DEFINITIONS.MAIN.PRIMARY};
  border-radius: ${size && size.radius};
  white-space: nowrap;
  transition: background-color 0.1s, border-color 0.1s;
  &:hover{
    background-color: ${color && color.hoverBg};
    border-color: ${color && color.hoverBorder};
  }
  ${mediaQuery.greaterThan('breakPoint')`
  width: ${size && size.width};
  `.join('')}
  ${mediaQuery.lessThan('breakPoint')`
  width: calc(100% - 120px);
  `.join('')}
  ${mediaQuery.lessThan('minTablet')`
  width: calc(100% - 60px);
  padding: 8px 30px;
   `.join('')}
  `;
});
export const SectionWrapper = styled.div(
  (props: SectionWrapper) => `
${mediaQuery.greaterThan('breakPoint')`
  width: 940px;
`.join('')};
${mediaQuery.between('tablet', 'breakPoint')`
  width: 640px;
`.join('')};
${mediaQuery.lessThan('tablet')`
  width: calc(100% - 60px);
  padding: 0 30px;
`.join('')};
margin: ${props.marginTop || '74px'} auto ${props.marginBottom || '78px'};
`
);
