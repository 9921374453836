import React from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { PageCommonProps } from '@meettry/company/route/route';
import { Breadcrumb } from '@meettry/company/component/molecules/breadcrumb';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';

const tabData = [
  { text: 'フォロー', type: 'following', path: '/follow/followed' },
  { text: 'フォロー中', type: 'followed', path: '/follow/following' }
];

const UserFollowPage: React.FC<PageCommonProps> = (props) => {
  const location = useLocation();
  const locationPath = location.pathname;

  return (
    <div className="follow-page-wrap">
      <Breadcrumb />
      <div className="follow-container">
        <div className="tab-link">
          <ChangeTab tab={tabData} active={locationPath} />
        </div>
        <Switch>
          {!!props.childRoute &&
            props.childRoute.map((li, i) => {
              return (
                <Route
                  key={i}
                  path={props.path + li.path}
                  exact={li.exact}
                  component={li.component}
                />
              );
            })}
        </Switch>
      </div>
    </div>
  );
};

export default UserFollowPage;
