import React from 'react';
import styled from 'styled-components';
import { Route, Switch } from 'react-router-dom';

import ForgotPassEMail from '@meettry/engineer/components/organisms/ForgotPass/ForgotPassEMail';
import ForgotPassInput from '@meettry/engineer/components/organisms/ForgotPass/ForgotPassInput';

export default () => (
  <StyledContents>
    <StyledContentInner>
      <Switch>
        <Route exact path="/forgot_pass/email" component={ForgotPassEMail} />
        <Route exact path="/forgot_pass/input" component={ForgotPassInput} />
      </Switch>
    </StyledContentInner>
  </StyledContents>
);

const StyledContents = styled.div`
  padding: 120px 50px 140px;
  flex: 1;
  background-color: #f7f7f7;
`;

const StyledContentInner = styled.div`
  max-width: 1200px;
  min-width: 900px;
  margin-left: auto;
  margin-right: auto;
`;
