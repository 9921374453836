import gql from 'graphql-tag';

export const FETCH_PAYMENT_INFO_QUERY = gql`
  query($yearMonth: Int!) {
    loginSession {
      user {
        organization {
          payment {
            billing(yearMonth: $yearMonth) {
              paidScoutCount
              scoutUnitPrice
            }
          }
          currentPlan {
            id
            name
            price
            scoutUnitPrice
          }
          activeCampaigns {
            id
            type
            freeScoutAmount
            freeScoutAmountUsed
            freeScoutStartAt
            freeScoutEndAt
            freeMonthlyPriceStartAt
            freeMonthlyPriceEndAt
          }
        }
      }
    }
  }
`;
