import React, { useRef, useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';

type EditableListItemContainerType = {
  onClickOutside: () => void;
  EditComponent: React.ReactNode;
  DisplayComponent: React.ReactNode;
  isOwner: boolean;
};

export const EditableListItemContainer: React.FC<EditableListItemContainerType> = (props) => {
  const { onClickOutside, EditComponent, DisplayComponent, isOwner = false } = props;
  const [editMode, setEditMode] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [editMode]);

  const handleClickOutside = (e: MouseEvent) => {
    if (!editMode) return;
    if (wrapperRef.current && !wrapperRef.current.contains(e.target as HTMLElement)) {
      typeof onClickOutside === 'function' && onClickOutside();
      setEditMode(false);
    }
  };

  const onClickEditIconHandler = () => {
    setEditMode(true);
  };

  return (
    <StyledEditableListItemContainer ref={wrapperRef}>
      {editMode ? EditComponent : DisplayComponent}
      {isOwner && !editMode && (
        <StyledIcon onClick={onClickEditIconHandler} iconName="editing_btn" iconColor="gray" />
      )}
    </StyledEditableListItemContainer>
  );
};

const StyledEditableListItemContainer = styled.div`
  position: relative;
`;

const StyledIcon = styled(Icon)`
  position: absolute;
  display: inline-block;
  margin-left: 5px;
  cursor: pointer;
  bottom: 0;
  right: 0;
`;
