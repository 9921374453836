import React from 'react';
import styled from 'styled-components';
import { getDaysBetween } from '@meettry/ui-components/utils';
import HighlightedText from '@meettry/ui-components/components/atoms/HighlightedText';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import { GrayRoundTag } from '@meettry/ui-components/components/atoms/Tag';
import icons from '@meettry/ui-components/images/icons';

type Props = {
  challenge: any;
  searchWord?: string;
  onClick: (nickname: string) => void;
};

/**
 * CassetteChallenge
 */
const CassetteChallenge: React.FC<Props> = (props) => {
  const { challenge, searchWord, onClick } = props;

  return (
    <StyledCassetteChallenge onClick={() => onClick(challenge.user?.nickname)}>
      <StyledContentHeader>
        <StyledUser>
          <CircleAvatar
            name="user_avatar"
            size="sm"
            src={challenge.user?.profileImage ? challenge.user?.profileImage.url : null}
          />
          <span>{challenge.user?.nickname}</span>
        </StyledUser>
        <StyledUpdatedText>
          <img
            src={icons.gray.time}
            alt="最終更新日"
            width="17"
            style={{ marginRight: '5px', verticalAlign: '-5px' }}
          />
          {`Updated ${getDaysBetween(challenge.updated)}`}
        </StyledUpdatedText>
      </StyledContentHeader>
      <StyledChallengeContent>
        <StyledChallengeInfo>
          <HighlightedText keyword={searchWord}>{challenge.title}</HighlightedText>
        </StyledChallengeInfo>
        <StyledTagWrapper>
          <StyledTagContainer>
            {challenge.tags.map(
              ({ displayName, name }: { displayName: string; name: string }, i: number) => (
                <GrayRoundTag key={`${i}_${name}`}>
                  <HighlightedText keyword={searchWord}>{displayName}</HighlightedText>
                </GrayRoundTag>
              )
            )}
          </StyledTagContainer>
        </StyledTagWrapper>
        <StyledChallengeDescription>
          <HighlightedText keyword={searchWord}>{challenge.challengeDetail}</HighlightedText>
        </StyledChallengeDescription>
      </StyledChallengeContent>
    </StyledCassetteChallenge>
  );
};

export default CassetteChallenge;

const StyledCassetteChallenge = styled.div`
  display: block;
  padding: 20px 30px;
  text-decoration: none;
`;

const StyledUser = styled.div`
  display: inline-flex;
  align-items: center;

  & > * + * {
    margin-left: 10px;
  }

  & > *:nth-child(2) {
    font-size: 12px;
    color: #2459bf;
  }
`;

const StyledChallengeContent = styled.div`
  padding-top: 20px;
`;

const StyledContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledUpdatedText = styled.span`
  font-size: 12px;
  color: #999999;
`;

const StyledChallengeInfo = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > * {
    display: block;
  }
  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: #0fafa9;
    word-break: break-all;
    line-height: 1.2;
  }
`;

const StyledTagWrapper = styled.div`
  margin-top: 10px;
`;

const StyledTagContainer = styled.div`
  margin-top: -5px;
  margin-left: -5px;
  > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;

const StyledChallengeDescription = styled.div`
  font-size: 14px;
  color: #333333;
  line-height: 1.75;
  margin-top: 20px;
  word-break: break-word;
`;
