import React from 'react';
import styled from 'styled-components';
import Icon from '@meettry/engineer/components/atoms/Icon/Icon';

const Checkbox = (props) => {
  const { onClick, children, isCheck, isDisplay = false } = props;
  const iconInfo = {
    imgName: `checkbox_${isCheck ? 'red' : 'gray'}`,
    width: '20px'
  };

  return (
    <StyledBox onClick={onClick} disabled={isDisplay}>
      <StyledIcon {...iconInfo} />
      {children}
    </StyledBox>
  );
};

export default Checkbox;

const StyledBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #f4233c;
  cursor: pointer;
  font-size: 1rem;
  font-family: 'Roboto', 'Helvetica', 'Arial', sans-serif;
  font-weight: 400;
  line-height: 1.5;
  letter-spacing: 0.00938em;
  &[disabled] {
    pointer-events: none;
    opacity: 0.2;
  }
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;
