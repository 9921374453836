import React from 'react';
import styled from 'styled-components';
import { Route, Switch, useHistory } from 'react-router-dom';
import ApplicationPage from '@meettry/engineer/components/templates/ApplicationPage';

import SignUp from '@meettry/engineer/components/organisms/Signups/SignUp';
import Nickname from '@meettry/engineer/components/organisms/Signups/Nickname';

export default () => {
  const history = useHistory();
  const pathSignUp = '/signup';
  const pathNickname = '/signup/nickname';
  const isSignUpMode = history.location.pathname.match(pathNickname);

  return (
    <ApplicationPage>
      {!isSignUpMode && <StyledPageTitle>ユーザー登録</StyledPageTitle>}
      <Switch>
        <Route exact path={pathSignUp} component={SignUp} />
        <Route exact path={pathNickname} component={Nickname} />
      </Switch>
    </ApplicationPage>
  );
};

const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #0fafa9;
  text-align: center;

  & + * {
    margin-top: 40px;
  }
`;
