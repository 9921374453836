import gql from 'graphql-tag';

export const FETCH_USER_BY_NICKNAMES_QUERY = gql`
  query($nicknames: [String]!) {
    users(nicknames: $nicknames) {
      id
      nickname
      scouteeRating
      profileImageThumbnail {
        url
      }
      isBookmarked
      organization {
        name
        logoImageThumbnail {
          url
        }
      }
    }
  }
`;
