/**
 * useSetDisplayMenu
 * @param hoverOpenMenuEl
 * @param editMenuIconEl
 * @param mousePosition
 */
const useSetDisplayMenu = (
  hoverOpenMenuEl: HTMLElement | null,
  editMenuIconEl: HTMLElement | null,
  mousePosition: [number, number]
) => {
  if (!hoverOpenMenuEl || !editMenuIconEl) return false;
  const hoverOpenMenuRect = hoverOpenMenuEl.getBoundingClientRect();
  const editMenuIconRect = editMenuIconEl.getBoundingClientRect();
  const hoverRangeLeft = Math.floor(hoverOpenMenuRect.left);
  const hoverRangeBottom = Math.ceil(hoverOpenMenuRect.top + hoverOpenMenuRect.height);
  const hoverRangeRight = Math.ceil(hoverRangeLeft + hoverOpenMenuRect.width);
  const hoverRangeTop = Math.floor(editMenuIconRect.top);
  return (
    mousePosition[0] >= hoverRangeLeft &&
    mousePosition[0] <= hoverRangeRight &&
    mousePosition[1] >= hoverRangeTop &&
    mousePosition[1] <= hoverRangeBottom
  );
};
export default useSetDisplayMenu;
