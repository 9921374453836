import React from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ja from 'date-fns/locale/ja';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import iconPathCalendar from '@meettry/ui-components/images/icons/primary/icon_calendar.png';
registerLocale('ja', ja);

type CalendarProps = {
  labelText?: string;
  startDate: Date;
  setStartDate: Function;
  endDate: Date;
  setEndDate: Function;
  showBtn: boolean;
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
};

export const Calendar: React.FC<CalendarProps> = (props) => {
  const { labelText, startDate, setStartDate, endDate, setEndDate, showBtn, margin } = props;
  const searchBtn = showBtn ? (
    <PrimaryButton
      width="100"
      size={STYLE_TYPE.SIZE.MEDIUM}
      margin={[0, 0, 0, 16]}
      onClick={() => console.log('検索')}
    >
      検索
    </PrimaryButton>
  ) : null;
  return (
    <StyledCalendarContainer margin={margin} showBtn={showBtn}>
      <StyledLabel labelText={labelText} htmlFor="calendar">
        {labelText}
      </StyledLabel>
      <StyledCalendarContents>
        <StyledDatePicker
          locale="ja"
          dateFormat="yyyy/MM/dd"
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          selectsStart
          startDate={startDate}
          endDate={endDate}
          maxDate={endDate}
          name="calendar"
          showBtn={showBtn}
        />
      </StyledCalendarContents>
      <StyledCalendarUnit>〜</StyledCalendarUnit>
      <StyledCalendarContents>
        <StyledDatePicker
          locale="ja"
          dateFormat="yyyy/MM/dd"
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          selectsEnd
          startDate={startDate}
          endDate={endDate}
          minDate={startDate}
          name="calendar"
          showBtn={showBtn}
        />
      </StyledCalendarContents>
      {searchBtn}
    </StyledCalendarContainer>
  );
};

type CalendarContainerType = {
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
  showBtn: boolean;
};

const StyledCalendarContainer = styled.div<CalendarContainerType>`
  display: flex;
  height: 40px;
  ${({ showBtn }) =>
    showBtn
      ? `
    justify-content: center;
  `
      : null}

  ${({ margin }) =>
    margin
      ? `margin:${margin
          .map((value) => (value === 'auto' || value === 0 ? value : value + 'px'))
          .join(' ')};`
      : null}
`;

const StyledLabel = styled.label<{ labelText?: string }>`
  display: inline-block;
  width: 100%;
  max-width: 170px;
  cursor: default;
  ${({ labelText }) => (!!labelText ? `line-height: 40px` : `width:0;`)}
`;

const StyledCalendarContents = styled.div`
  position: relative;
  display: inline-block;
`;

const StyledDatePicker = styled<any & { showBtn: boolean }>(DatePicker)`
  width: 100%;
  max-width: 180px;
  height: ${({ showBtn }) => (showBtn ? '40px' : '48px')};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  box-sizing: border-box;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  text-align: center;
  vertical-align: middle;
  font-size: 16px;
  background-image: url(${iconPathCalendar});
  background-position: 20px center;
  background-size: 18px;
  background-repeat: no-repeat;
`;

const StyledCalendarUnit = styled.span`
  margin: 0 8px;
  line-height: 40px;
`;
