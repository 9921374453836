import React from 'react';
import styled from 'styled-components';

export const FormItem = (props) => {
  const { label, error, errorText, required = false, children } = props;

  return (
    <StyledFormItem>
      <StyledFormLabelContainer>
        <StyeldFormLabel>{label}</StyeldFormLabel>
      </StyledFormLabelContainer>
      <StyledInputContainer>
        {required ? <StyledRequiredTag>必須</StyledRequiredTag> : null}
        {children}
        {error ? <StyledErrorText>{errorText}</StyledErrorText> : null}
      </StyledInputContainer>
    </StyledFormItem>
  );
};

export default FormItem;

const StyledFormItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;
const StyledFormLabelContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 170px;
`;
const StyeldFormLabel = styled.p`
  font-size: 16px;
`;

const StyledInputContainer = styled.div`
  width: 100%;
  position: relative;
`;

const StyledRequiredTag = styled.span`
  width: 100%;
  max-width: 30px;
  position: absolute;
  top: 50%;
  left: -30px;
  transform: translate(-50%, -50%);
  background-color: #fee8eb;
  color: #f4233c;
  border-radius: 10px;
  font-size: 10px;
  padding: 2px 0;
  text-align: center;
`;

const StyledErrorText = styled.span`
  position: absolute;
  top: 100%;
  left: 0;
  transform: translateY(2px);
  font-size: 12px;
  color: #f4233c;
`;
