import React, { useState, useRef, useEffect } from 'react';
import styled from 'styled-components';

type AccordionProps = {
  show?: boolean;
  closeHeight?: number;
  setAnimating?: Function;
};

export const Accordion: React.FC<AccordionProps> = (props) => {
  const { show, closeHeight, setAnimating, children } = props;
  const [height, setHeight] = useState(0);
  const accordionEl = useRef<HTMLDivElement>(null);
  const animationDuration = 200;
  const timerId = useRef<number | null>(null);
  useEffect(() => {
    timerId.current = window.setTimeout(() => {
      if (!!accordionEl.current && show && height !== accordionEl.current.clientHeight) {
        setHeight(accordionEl.current.clientHeight);
      }
    }, animationDuration);
    return () => {
      if (!!timerId.current) clearTimeout(timerId.current);
    };
  }, []);

  return (
    <StyledAccordionWrapper
      ref={accordionEl}
      style={
        show && height === 0
          ? {}
          : !show
          ? { maxHeight: closeHeight ? closeHeight : 0 }
          : { maxHeight: height }
      }
      onTransitionEnd={() => setAnimating && setAnimating(false)}
    >
      {children}
    </StyledAccordionWrapper>
  );
};

const StyledAccordionWrapper = styled.div`
  transition: max-height 0.2s;
  overflow: hidden;
`;
