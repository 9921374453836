import React, { ReactNode } from 'react';

type SignWrapperProps = {
  aboveTitle?: ReactNode;
  title: string;
  cls: string;
};

export const SignWrapper: React.FC<SignWrapperProps> = (props) => {
  const { aboveTitle } = props;
  return (
    <main className={`sign-wrapper ${props.cls}`}>
      {!!aboveTitle ? aboveTitle : null}
      <h1>{props.title}</h1>
      <form>{props.children}</form>
    </main>
  );
};
