import React from 'react';
import styled from 'styled-components';
import Downshift from 'downshift';
import SearchBox from '@meettry/ui-components/components/molecules/SearchBox/SearchBox';
import SearchTagList from '@meettry/ui-components/components/atoms/SearchTagList';
import { TagType } from '@meettry/ui-components/types/userPage';

type SearchTagInputProps = {
  onChangeDownshift: (tag: TagType) => void;
  placeholder: string;
  onChangeSearchWord: (value: string) => void;
  onSubmitSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  searchWord: string;
  tagList: Array<TagType>;
  onSelectTag: (item: TagType) => (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => void;
};

const SearchTagInput = (props: SearchTagInputProps) => {
  const {
    onChangeDownshift,
    placeholder,
    onChangeSearchWord,
    onSubmitSearch,
    searchWord,
    tagList,
    onSelectTag
  } = props;

  return (
    <Downshift
      onChange={onChangeDownshift}
      itemToString={(item) => (item && item.displayName ? item : '')}
    >
      {({ getRootProps, getInputProps, getMenuProps, getItemProps, highlightedIndex }) => (
        <div {...getRootProps({}, { suppressRefError: true })}>
          <SearchBox
            getInputProps={getInputProps}
            onChange={onChangeSearchWord}
            onSubmit={onSubmitSearch}
            searchWord={searchWord}
            placeholder={placeholder}
          />
          {searchWord ? (
            <StyledSearchTagList
              getMenuProps={getMenuProps}
              getItemProps={getItemProps}
              highlightedIndex={highlightedIndex}
              searchWord={searchWord}
              tagList={tagList}
              onSelectTag={onSelectTag}
            />
          ) : null}
        </div>
      )}
    </Downshift>
  );
};

export default SearchTagInput;

const StyledSearchTagList = styled(SearchTagList)`
  && {
    position: absolute;
    top: calc(100% + 5px);
    left: 0;
    z-index: 200;
  }
`;
