import React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type Props = {
  maxCount: number; // 最大件数
  viewCount: number; // 表示件数
  currentPage: number; // 現在のページ番号
  setCurrentPage: Function; // 指定したページ番号に更新
  bothSidesButtonCount?: number; // 現在のページが中間にある時に両隣に表示するボタンの数
  marginTop?: number;
};

/**
 * Pagination Component
 */
export const Pagination: React.FC<Props> = (props) => {
  const {
    maxCount,
    viewCount,
    currentPage,
    setCurrentPage,
    marginTop,
    bothSidesButtonCount = 2
  } = props;

  // ページネーションの最大ボタン数
  const maxPageCount = Math.ceil(maxCount / viewCount);

  // 状況に応じたページネーションのボタンを生成
  const buttonMapping: React.ReactNode[] = Array.from(new Array(maxPageCount))
    .map((_, i) => ++i)
    .reduce<(React.ReactNode | null)[]>((mapping, current) => {
      // 現在のページと数字が一致した時、アクティブボタン
      if (current === currentPage) {
        return [
          ...mapping,
          <StyledPaginationContent isCurrent={true} key={current}>
            {current}
          </StyledPaginationContent>
        ];
        // 現在のページの数字の前後±{bothSidesButtonCount}のとき、非アクティブボタン
      } else if (bothSidesButtonCount - Math.abs(current - currentPage) >= 0) {
        return [
          ...mapping,
          <StyledPaginationContent
            isCurrent={false}
            key={current}
            onClick={() => setCurrentPage(current)}
          >
            {current}
          </StyledPaginationContent>
        ];
        // 最初と最後のとき、非アクティブボタン
      } else if (current === 1 || current === maxPageCount) {
        return [
          ...mapping,
          <StyledPaginationContent
            isCurrent={false}
            key={current}
            onClick={() => setCurrentPage(current)}
          >
            {current}
          </StyledPaginationContent>
        ];
        // 前回のボタンがnullでなければ、三点リーダー用のnullを返す（ここで、判別したいので、一旦nullを返す）
      } else if (mapping[mapping.length - 1] !== null) {
        return [...mapping, null];
      } else {
        return [...mapping];
      }
    }, [])
    // nullにしたところを三点リーダーに置換
    .map((node, index) =>
      node !== null ? (
        node
      ) : (
        <StyledPaginationDecoration key={`ellipsis_${index}`}>…</StyledPaginationDecoration>
      )
    );

  return (
    <StyledPagination marginTop={marginTop}>
      <StyledArrow
        isDisabled={currentPage === 1}
        onClick={() => currentPage !== 1 && setCurrentPage(currentPage - 1)}
      />
      <StyledPaginationNumbers>{buttonMapping.map((node, i) => node)}</StyledPaginationNumbers>
      <StyledArrow
        isDisabled={currentPage === maxPageCount}
        onClick={() => currentPage !== maxPageCount && setCurrentPage(currentPage + 1)}
      />
    </StyledPagination>
  );
};

export const StyledPagination = styled.div<{ marginTop?: number }>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  ${(props) => (!!props.marginTop ? `margin-top:${props.marginTop}px` : ``)}
`;

const StyledArrow = styled.span<{ isDisabled: boolean }>`
  line-height: 32px;
  &:first-child,
  &:last-child {
    cursor: pointer;
    display: block;
    width: 10px;
    height: 10px;
    border-top: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-left: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    transition: border-color 0.1s;
    &:hover {
      border-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY_DARK};
    }
  }
  &:first-child {
    transform: rotate(-45deg);
  }
  &:last-child {
    transform: rotate(135deg);
  }
  ${(props) =>
    props.isDisabled
      ? `
      &&{
        cursor: default;
        border-color: ${COLOR_DEFINITIONS.LINE.MAIN};
        &:hover {
          border-color: ${COLOR_DEFINITIONS.LINE.MAIN};
        }
      }
    `
      : ``}
`;

const StyledPaginationNumbers = styled.ol`
  margin: 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
`;

const PaginationContentStyle = css`
  cursor: pointer;
  line-height: 32px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  display: block;
  border-radius: 50%;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  transition: background-color 0.1s;
  text-align: center;
  &:hover {
    background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  }
  & + li {
    margin-left: 8px;
  }
`;

const StyledPaginationContent = styled.li<{ isCurrent: boolean }>`
  ${PaginationContentStyle}
  ${(props) =>
    props.isCurrent
      ? `
      &&{
        background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        color: ${COLOR_DEFINITIONS.TEXT.WHITE};
        cursor: default;
      }
    `
      : ``}
`;

const PaginationDecorationStyle = css`
  width: 16px;
  cursor: default;
  background-color: transparent;
  border-radius: 0;
  border: none;
`;

const StyledPaginationDecoration = styled.li`
  ${PaginationContentStyle}
  ${PaginationDecorationStyle}
`;
