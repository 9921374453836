import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { ROUTE, RouteType } from '@meettry/company/route/route';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { tail } from 'lodash';

export const Breadcrumb: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  const locationDisassembly = locationPath.split('/');
  locationDisassembly.shift();
  const locationArray = locationDisassembly.map((li) => {
    return '/' + li;
  });

  const createBreadCrumb = () => {
    const breadCrumbList: Array<string> = [];
    const findRoute = (route: RouteType, location: Array<string>) => {
      const routePath = route.find((li) => (li.path === location[0] ? li.name : null));
      routePath && breadCrumbList.push(routePath.name);
      const lastPath = tail(location);
      if (lastPath.length && !!routePath && !!routePath.childRoute) {
        findRoute(routePath.childRoute, lastPath);
      }
    };
    findRoute(ROUTE, locationArray);
    return breadCrumbList;
  };
  const breadCrumb = createBreadCrumb();

  return (
    <StyledBreadcrumb>
      <ol>
        {breadCrumb.map((li, i) => {
          return (
            <React.Fragment key={i}>
              <li key={i}>
                <span>{li}</span>
              </li>
              {i < breadCrumb.length - 1 ? (
                <li>
                  <Icon width="10px" iconName="arrow_right" iconColor="primary" />
                </li>
              ) : null}
            </React.Fragment>
          );
        })}
      </ol>
    </StyledBreadcrumb>
  );
};

const StyledBreadcrumb = styled.div`
  padding: 0 40px;
  ol {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    line-height: 1;
    font-size: 12px;
    color: $primary_color;
    li {
      span {
        cursor: pointer;
      }
      span,
      img {
        vertical-align: middle;
      }
      &:not(:last-child) {
        span,
        img {
          margin-right: 8px;
        }
      }
      &:last-child {
        color: $text_secondary_color;
        cursor: default;
      }
    }
  }
`;
