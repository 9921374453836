import React from 'react';

// import EnterpriseTop from '@meettry/company/component/pages/EnterpriseTop';
import Login from '@meettry/company/component/pages/login';
// import EnterpriseSignup from '@meettry/company/component/pages/EnterpriseSignup';
import Setting from '@meettry/company/component/pages/setting';
import Register from '@meettry/company/component/pages/register';
import Privacy from '@meettry/company/component/pages/privacy';
import Terms from '@meettry/company/component/pages/terms';
import Tokutei from '@meettry/company/component/pages/tokutei';
import Contact from '@meettry/company/component/pages/contact';
// import MainWrapper from '@meettry/company/component/organisms/base';
import Company from '@meettry/company/component/organisms/corporateInfo';
import ChangeEmail from '@meettry/company/component/organisms/changeEmail';
import ChangePassword from '@meettry/company/component/organisms/changePassword';
import Payment from '@meettry/company/component/organisms/billSettlement';
import Plan from '@meettry/company/component/organisms/contractPlan';
import ForgotPass from '@meettry/company/component/pages/forgotPass';
import Maintenance from '@meettry/company/component/pages/maintenance';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';
import UserPage from '@meettry/company/component/pages/userPage';
import userDashboard from '@meettry/company/component/organisms/userPage/Dashboard';
import Challenge from '@meettry/company/component/organisms/userPage/Challenge';
import Resume from '@meettry/company/component/organisms/userPage/Resume';
import SearchPage from '@meettry/company/component/pages/search';
import UserFollowPage from '@meettry/company/component/pages/userFollowPage';
import FollowList from '@meettry/company/component/organisms/userFollowPage/followList';

type Route = {
  name: string;
  exact: boolean;
  path: string;
  display: boolean;
  component: React.FC<PageCommonProps>;
  childRoute?: RouteType;
};

export type RouteType = Array<Route>;

export type PageCommonProps = {
  childRoute?: RouteType;
  path: string;
};

export const ROUTE: RouteType = [
  // { name: 'Top', exact: true, path: '/', display: true, component: EnterpriseTop },
  { name: 'Register', exact: true, path: '/register', display: true, component: Register },
  { name: 'Login', exact: false, path: '/login', display: true, component: Login },
  // { name: 'SignUp', exact: false, path: '/signup', display: true, component: EnterpriseSignup },
  // {
  //   name: 'User',
  //   exact: false,
  //   path: '/user',
  //   display: true,
  //   component: MainWrapper,
  //   childRoute: [
  //     { name: 'Dashboard', exact: false, path: '/dashboard', display: true, component: EnterpriseDashboard },
  //     { name: 'Scout', exact: false, path: '/scout', display: true, component: EnterpriseScout }
  //   ]
  // },
  {
    name: 'TOP',
    exact: false,
    path: '/user_name',
    display: true,
    component: UserPage,
    childRoute: [
      {
        name: 'user_name',
        exact: false,
        path: '/dashboard',
        display: true,
        component: userDashboard
      },
      { name: 'チャレンジ', exact: false, path: '/challenge', display: true, component: Challenge },
      { name: '経歴', exact: false, path: '/resume', display: true, component: Resume }
    ]
  },
  {
    name: 'TOP',
    exact: false,
    path: '/follow',
    display: true,
    component: UserFollowPage,
    childRoute: [
      {
        name: 'フォロワー一覧',
        exact: false,
        path: '/followed',
        display: true,
        component: FollowList
      },
      {
        name: 'フォロー一覧',
        exact: false,
        path: '/following',
        display: true,
        component: FollowList
      }
    ]
  },
  {
    name: 'Search',
    exact: false,
    path: '/search',
    display: true,
    component: SearchPage,
  },
  {
    name: 'Setting',
    exact: false,
    path: '/setting',
    display: true,
    component: Setting,
    childRoute: [
      { name: 'Company', exact: false, path: '/company', display: true, component: Company },
      {
        name: 'MailAddress',
        exact: false,
        path: '/change-email',
        display: true,
        component: ChangeEmail
      },
      {
        name: 'Password',
        exact: false,
        path: '/change-password',
        display: true,
        component: ChangePassword
      },
      { name: 'Payment', exact: false, path: '/payment', display: true, component: Payment },
      { name: 'Plan', exact: false, path: '/plan', display: true, component: Plan }
    ]
  },
  {
    name: 'プライバシーポリシー',
    exact: true,
    path: '/privacy',
    display: true,
    component: Privacy
  },
  { name: '利用規約', exact: true, path: '/terms', display: true, component: Terms },
  {
    name: '特定商取引に関する表記',
    exact: true,
    path: '/tokutei',
    display: true,
    component: Tokutei
  },
  { name: 'Contact', exact: true, path: '/contact', display: true, component: Contact },
  {
    name: 'ForgotPass',
    exact: true,
    path: '/forgot_pass',
    display: true,
    component: ForgotPass
  },
  {
    name: 'Maintenance',
    exact: true,
    path: '/maintenance',
    display: true,
    component: Maintenance
  },
  {
    name: 'NotFound',
    exact: false,
    path: '',
    display: true,
    component: NotFoundPage
  }
];
