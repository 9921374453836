import React from 'react';
import { useLocation, Switch, Route } from 'react-router-dom';
import { PageCommonProps } from '@meettry/company/route/route';
import { SettingTab } from '@meettry/company/component/molecules/settingTab';
import { Breadcrumb } from '@meettry/company/component/molecules/breadcrumb';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';
import { useLoginStatus } from '@meettry/ui-components/utils/firebase';

const tabData = [
  { text: 'ダッシュボード', type: 'Dashboard', path: '/user/dashboard' },
  { text: 'スカウト', type: 'Scout', path: '/user/scout' },
  { text: 'チャット', type: 'Chat', path: '/user/chat' }
];

const settingTabData = [
  { name: '企業情報', type: 'Company', path: '/setting/company' },
  { name: 'メールアドレス', type: 'MailAddress', path: '/setting/change-email' },
  { name: 'パスワード', type: 'Password', path: '/setting/change-password' },
  { name: '請求書・決済', type: 'Payment', path: '/setting/payment' },
  { name: '契約プラン', type: 'Plan', path: '/setting/plan' }
];

const Setting: React.FC<PageCommonProps> = (props) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const { initialized, authorized } = useLoginStatus();
  const isPage =  !initialized && authorized && settingTabData.find((data) => data.path === locationPath);

  return (
    <>
      {isPage ?
        <main className="setting">
          <Breadcrumb />
          <ChangeTab tab={tabData} active={locationPath} />
          <div className="content-wrap">
            <h1 className="heading-icon">登録設定</h1>
            <SettingTab active={locationPath} tab={settingTabData} />
            <Switch>
              {!!props.childRoute &&
                props.childRoute.map((li, i) => {
                  return (
                    <Route
                      key={i}
                      path={props.path + li.path}
                      exact={li.exact}
                      component={li.component}
                    ></Route>
                  );
                })}
            </Switch>
          </div>
        </main>
        : <NotFoundPage/>}
    </>
  );
};

export default Setting;
