import React from 'react';
import styled from 'styled-components';
import { TextField } from '@meettry/engineer/components/atoms/Input';

export const InputPostCode = (props) => {
  const { onChange, error, userPostCode } = props;

  return (
    <StyledListContents>
      <StyledListContent>
        <TextField
          value={userPostCode.first}
          onChange={onChange('first')}
          placeholder="000"
          error={error}
        />
      </StyledListContent>
      <StyledListContent>
        <TextField
          value={userPostCode.second}
          onChange={onChange('second')}
          placeholder="0000"
          error={error}
        />
      </StyledListContent>
    </StyledListContents>
  );
};

export default InputPostCode;

const StyledListContents = styled.div`
  display: flex;
`;

const StyledListContent = styled.div`
  display: flex;
  align-items: center;
  > * {
    width: 100px;
  }
  & + &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 1px;
    margin: 0 10px;
    background-color: #333333;
  }
`;
