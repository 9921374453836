import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import {
  CancelConfirmation,
  DeleteConfirmation,
  MaximumReached,
  ErrorMessage,
  NotificationMessage,
  VisibleRegisterConfirmation,
  ActivateScoutConfirmation,
  ScoutUnableConfirmation,
  ProfileEditConfirmation
} from '@meettry/ui-components/components/molecules/Dialogs';

export const ACTIVATE_SCOUT_CONFIRMATION = 'ACTIVATE_SCOUT_CONFIRMATION';
export const SCOUT_UNABLE_CONFIRMATION = 'SCOUT_UNABLE_CONFIRMATION';
export const PROFILE_EDIT_CONFIRMATION = 'PROFILE_EDIT_CONFIRMATION';
export const VISIBLE_REGISTER_CONFIRMATION = 'VISIBLE_REGISTER_CONFIRMATION';
export const CANCEL_CONFIRMATION = 'CANCEL_CONFIRMATION';
export const DELETE_CONFIRMATION = 'DELETE_CONFIRMATION';
export const MAXIMUM_REACHED = 'MAXIMUM_REACHED';
export const ERROR_MESSAGE = 'ERROR_MESSAGE';
export const NOTIFICATION_MESSAGE = 'NOTIFICATION_MESSAGE';

export const Dialog = () => {
  const {
    state: { dialogs },
    closeDialog
  } = useDialog();

  useEffect(() => {
    if (dialogs.length) {
      document.body.classList.add('dialog-open');
      return;
    }
    document.body.classList.remove('dialog-open');
  }, [dialogs]);

  return dialogs.length ? (
    <StyledDialogWrapper>
      {dialogs.map((dialog) => {
        const { dialogType, id } = dialog;
        switch (dialogType) {
          case ACTIVATE_SCOUT_CONFIRMATION:
            return (
              dialog.onAccept &&
              dialog.onDecline && (
                <ActivateScoutConfirmation
                  key={id}
                  onAccept={dialog.onAccept}
                  onDecline={dialog.onDecline}
                />
              )
            );
          case SCOUT_UNABLE_CONFIRMATION:
            return (
              dialog.onAccept &&
              dialog.onDecline && (
                <ScoutUnableConfirmation
                  key={id}
                  texts={dialog.texts}
                  onAccept={dialog.onAccept}
                  onDecline={dialog.onDecline}
                />
              )
            );
          case PROFILE_EDIT_CONFIRMATION:
            return (
              dialog.onAccept &&
              dialog.onDecline && (
                <ProfileEditConfirmation
                  key={id}
                  texts={dialog.texts}
                  onAccept={dialog.onAccept}
                  onDecline={dialog.onDecline}
                />
              )
            );
          case VISIBLE_REGISTER_CONFIRMATION:
            return (
              dialog.onRegisterVisible &&
              dialog.onRegisterInvisible && (
                <VisibleRegisterConfirmation
                  key={id}
                  texts={dialog.texts}
                  onRegisterVisible={dialog.onRegisterVisible}
                  onRegisterInvisible={dialog.onRegisterInvisible}
                />
              )
            );
          case CANCEL_CONFIRMATION:
            return (
              dialog.texts &&
              dialog.onAccept && (
                <CancelConfirmation
                  key={id}
                  texts={dialog.texts}
                  onAccept={dialog.onAccept}
                  onCancel={closeDialog}
                />
              )
            );
          case DELETE_CONFIRMATION:
            return (
              dialog.texts &&
              dialog.onAccept && (
                <DeleteConfirmation
                  key={id}
                  texts={dialog.texts}
                  onAccept={dialog.onAccept}
                  onCancel={closeDialog}
                />
              )
            );
          case MAXIMUM_REACHED:
            return (
              dialog.texts && <MaximumReached key={id} texts={dialog.texts} onClose={closeDialog} />
            );
          case ERROR_MESSAGE:
            return (
              dialog.texts && <ErrorMessage key={id} texts={dialog.texts} onClose={closeDialog} />
            );
          case NOTIFICATION_MESSAGE:
            return (
              dialog.texts &&
              dialog.onOk && (
                <NotificationMessage key={id} texts={dialog.texts} onOk={dialog.onOk} />
              )
            );
          default:
            return null;
        }
      })}
    </StyledDialogWrapper>
  ) : null;
};

export default Dialog;

const StyledDialogWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  & > * {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;
