import React from 'react';
import styled from 'styled-components';
import { DialogBase } from '@meettry/ui-components/components/atoms/DialogBase';
import { PrimaryButton, LineButton } from '@meettry/ui-components/components/atoms/Button';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type VisibleRegisterConfirmationType = {
  texts?: Array<string>;
  onRegisterVisible: () => void;
  onRegisterInvisible: () => void;
};

export const VisibleRegisterConfirmation: React.FC<VisibleRegisterConfirmationType> = (props) => {
  const { texts, onRegisterVisible, onRegisterInvisible } = props;

  return (
    <StyledVisibleRegisterConfirmation>
      <StyledVisibleRegisterConfirmationContainer>
        {texts
          ? texts.map((text, i) => <StyledConfirmationText key={i}>{text}</StyledConfirmationText>)
          : null}
        <StyledConfirmationButtons>
          <LineButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onRegisterInvisible}>
            非公開のまま登録
          </LineButton>
          <PrimaryButton size={STYLE_TYPE.SIZE.MEDIUM} onClick={onRegisterVisible}>
            公開して登録
          </PrimaryButton>
        </StyledConfirmationButtons>
      </StyledVisibleRegisterConfirmationContainer>
    </StyledVisibleRegisterConfirmation>
  );
};

export default VisibleRegisterConfirmation;

const StyledVisibleRegisterConfirmation = styled(DialogBase)`
  position: relative;
`;

const StyledVisibleRegisterConfirmationContainer = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledConfirmationText = styled.p`
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  :not(:first-child) {
    margin-top: 10px;
  }
`;

const StyledConfirmationButtons = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  & > * {
    max-width: 160px;
    :not(:first-child) {
      margin-left: 10px;
    }
  }
`;
