import React from 'react';
import styled from 'styled-components';

// 青背景、青文字 クリッカブル(経歴の技術スタックなどの表示に使用するもの)
//MEMO(aida) StackTag -> SkillTagの方が好ましいかも
export const StackTag = (props) => {
  const { children, className } = props;
  return <StyledStackTag className={className}>{children}</StyledStackTag>;
};

const StyledStackTag = styled.div`
  user-select: none;
  display: inline-block;
  font-size: 12px;
  color: #2459bf;
  background-color: #e8eef9;
  border-radius: 3px;
  padding: 3px 10px;
`;
