import React from 'react';
import styled from 'styled-components';

export const IMAGE_TYPE = {
  CIRCLE: 'CIRCLE',
  MAIN_LOGO: 'MAIN_LOGO',
  BACKGROUND_IMAGE: 'BACKGROUND_IMAGE'
};

// const ImageBase = props => (
//   <StyledImage {...props}>
//     <img {...props} />
//   </StyledImage>
// );

// ImageBase.defaultProps = {
//   width: 'auto',
//   height: 'auto',
// };

// export default ImageBase;

// const getStyleType = (props) => {
//   const {
//     type, width, height, src,
//   } = props;
//   switch (type) {
//     case IMAGE_TYPE.CIRCLE: {
//       return `
//         width: ${width};
//         height: 0;
//         padding-top: ${height};
//         overflow: hidden;
//         border-radius: 50%;
//         background-image: url(${src});
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-position: center;

//         & > img {
//           display: none;
//         }
//       `;
//     }
//     case IMAGE_TYPE.MAIN_LOGO: {
//       return `
//         width: ${width};
//         height: auto;
//         `;
//     }
//     case IMAGE_TYPE.BACKGROUND_IMAGE: {
//       return `
//         width: ${width};
//         height: ${height};
//         background-image: url(${src});
//         background-repeat: no-repeat;
//         background-size: cover;
//         background-position: center;
//       `;
//     }
//     default:
//       return '';
//   }
// };

// const StyledImage = styled.div`
//   display: inline-block;
//   width: ${({ width }) => width};
//   height: ${({ height }) => height};

//   > img {
//     width: ${({ width }) => width};
//     height: ${({ height }) => height};
//     vertical-align: middle;
//   }

//   ${props => getStyleType(props)}
// `;

type StyledImageProps = {
  height?: string;
  width?: string;
  isCenter?: boolean;
};

type ImageBaseProps = {
  source: any;
  alt: string;
  isCenter?: boolean;
} & StyledImageProps;

const ImageBase: React.FC<ImageBaseProps> = (props) => {
  const { source } = props;
  return <StyledImage src={source} {...props} />;
};

export default ImageBase;

const StyledImage = styled.img<StyledImageProps>(
  ({ height, width, isCenter }) => `
&& {
  height: ${height ? `${height}` : 'auto'};
  width: ${width ? `${width}` : 'auto'};
  ${
    isCenter &&
    `display: block;
    margin: 0 auto;`
  }
}
`
);
