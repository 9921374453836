import React, { createContext, Dispatch, useReducer } from 'react';
import iconNoAvatarPath from '@meettry/ui-components/images/avatar/icon_default_avatar.png';
import { ProviderComponent } from '@meettry/ui-components/types/context';

//プロフィール画像のURLを設定するアクション（更新したかも判定）
export const SET_PROFILE_IMAGE_URL = 'SET_PROFILE_IMAGE_URL';

export const setProfileImageUrl = (profileImageUrl: string) => ({
  type: SET_PROFILE_IMAGE_URL,
  payload: {
    profileImageUrl
  }
});

const initState = () => ({
  profileImageUrl: iconNoAvatarPath
});

const initialState = initState();

//reducer
const loginUserReducer = (
  state = initialState,
  action: { type: string; payload: { [key: string]: string } }
) => {
  switch (action.type) {
    case SET_PROFILE_IMAGE_URL:
      const { profileImageUrl } = action.payload;
      return { ...state, profileImageUrl };
    default:
      return state;
  }
};
type LoginUserState = {
  profileImageUrl: string;
};
type Action<T, U> = { type: T; payload: U };

type LoginUserOption = { [key: string]: string };

type LoginUserAction = Action<string, LoginUserOption>;

type LoginUserDispatch = Dispatch<LoginUserAction>;
//context
type LoginUserStore = {
  state: LoginUserState;
  dispatch: LoginUserDispatch;
};
const defaultStore: LoginUserStore = {
  state: initialState,
  dispatch: () => initialState
};
export const LoginUserContext = createContext<LoginUserStore>(defaultStore);

// Provider
export const LoginUserProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(loginUserReducer, initialState);
  return (
    <LoginUserContext.Provider value={{ state, dispatch }}>{children}</LoginUserContext.Provider>
  );
};
