import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import NavLinkBase from '@meettry/ui-components/components/atoms/NavLinkBase';
import HeaderMenu from '@meettry/ui-components/components/molecules/HeaderMenu';
import { TopBtn, headerMenuList } from '@meettry/ui-components/components/organisms/Tops/utils';
import { HeaderStateType } from '@meettry/ui-components/types/location';

import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

type HeaderNoMemberType = {
  closeMenu?: (cb: any) => false | void;
};

const HeaderNoMember: React.FC<HeaderNoMemberType> = ({ closeMenu }) => {
  const location = useLocation<HeaderStateType>();
  const enterprise = location.pathname === '/enterprise';
  const isEnterpriseContact = location.pathname === '/enterprise/contact';

  return (
    <StyledDivWrapper>
      {['/', '/enterprise'].includes(location.pathname) ? (
        <HeaderMenuList>
          {headerMenuList
            .filter((menu) => menu.target !== location.pathname)
            .map((list, i) => {
              return <HeaderMenu key={i} list={list} closeMenu={closeMenu} />;
            })}
        </HeaderMenuList>
      ) : null}
      <StyledDivGrow />
      {!isEnterpriseContact && (!location.state || !location.state.fromEnterprise) ? (
        <StyledDivSignWrapper>
          <NavLinkBase
            toPath="/login"
            fromEnterprise={enterprise}
            children={
              <LoginBtn color={enterprise ? 'containedEnterprise' : 'outlined'} size="small">
                ログイン
              </LoginBtn>
            }
          />
          <NavLinkBase
            toPath={enterprise ? '/enterprise/contact' : '/signup'}
            blank={enterprise}
            children={
              enterprise ? (
                <SignupBtn color="outlinedEnterprise" size="small">
                  お問い合わせはこちら
                </SignupBtn>
              ) : (
                <SignupBtn color="contained" size="small">
                  ユーザー登録
                </SignupBtn>
              )
            }
          />
        </StyledDivSignWrapper>
      ) : null}
    </StyledDivWrapper>
  );
};

export default HeaderNoMember;

// styled-components
const StyledDivWrapper = styled.div`
  width: 100%;
  ${mediaQuery.greaterThan('breakPoint')`
  display: flex;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  display: block;
  `}
`;

const HeaderMenuList = styled.ul`
  ${mediaQuery.greaterThan('breakPoint')`
  display: flex;
  align-items: center;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  display: block;
  `}
`;

const StyledDivGrow = styled.div`
  flex-grow: 1;
`;

const StyledDivSignWrapper = styled.div`
  ${mediaQuery.greaterThan('breakPoint')`
  display: flex;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  display: block;
  `}
`;

const SignupBtn = styled(TopBtn)`
  margin-top: 0;
  ${mediaQuery.greaterThan('basePCWidth')`
padding: 10px 40px;
`}
  ${mediaQuery.between('breakPointPC', 'basePCWidth')`
  padding: 10px 16px;
  `}
  ${mediaQuery.lessThan('breakPoint')`
  margin: 0 auto;
  `}
`;

const LoginBtn = styled(SignupBtn)`
  ${mediaQuery.greaterThan('breakPoint')`
margin: 0 10px 0 0;
`}
  ${mediaQuery.lessThan('breakPoint')`
margin: 0 auto 20px auto;
`}
`;
