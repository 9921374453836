import React, { useState, useMemo, useEffect } from 'react';
import styled from 'styled-components';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import ChatRoomList from '@meettry/ui-components/components/organisms/ChatRoomList';
import useChatRooms from '@meettry/ui-components/hooks/chat/useChatRooms';
import useServerCursors from '@meettry/ui-components/hooks/chat/useServerCursors';
import { useLoginStatus } from '@meettry/ui-components/utils/firebase';
import firebase from '@meettry/ui-components/utils/firebase';
import { ChatRoomWithMessages } from '@meettry/ui-components/types/chat';
import ChatMessages from '@meettry/ui-components/components/organisms/ChatMessages';
import ChatMessageInput from '@meettry/ui-components/components/organisms/ChatMessageInput';
import { useQuery, useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  CREATE_SCOUT_CHAT_ROOM_MUTATION,
  RATE_SCOUTEE_MUTATION,
  SEND_CHAT_MESSAGE,
  UPDATE_SCOUT_CHAT_ROOM_MUTATION
} from '@meettry/ui-components/queries/chat';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { UserInfoType } from '@meettry/ui-components/types/location';
import useChatRoom from '@meettry/ui-components/hooks/chat/useChatRoom';
import useChatNotifications from '@meettry/ui-components/hooks/chat/useChatNotifications';
import {
  FETCH_SCOUTS_BY_IDS_QUERY,
  BOOKMARK_USER_BY_ENTERPRISE_QUERY
} from '@meettry/ui-components/queries';
import ChatEvaluation from '@meettry/ui-components/components/organisms/ChatEvaluation';
import { EditModal } from '@meettry/ui-components/components/organisms/Modal';
import ScoutChatAlertBox, {
  ScoutChatAlertCampaign
} from '@meettry/ui-components/components/molecules/ScoutChatAlertBox';
import ScoutChatSelectBox from '@meettry/ui-components/components/molecules/ScoutChatSelectBox';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import useScoutList from '@meettry/ui-components/hooks/scout/useScoutList';
import { Scout } from '@meettry/ui-components/types/scout';
import { FETCH_USER_BY_NICKNAMES_QUERY } from '@meettry/ui-components/queries/user';
import { getErrorCodes } from '@meettry/ui-components/utils/graphql';
import { FETCH_PAYMENT_INFO_QUERY } from '@meettry/ui-components/queries/payment';
import { CAMPAIGN_TYPE } from '@meettry/ui-components/utils/Constant';
import { FetchPaymentInfoQuery } from '@meettry/ui-components/types/payment';
import { DATE_FORMAT, formatDate } from '@meettry/ui-components/utils';

type ShowModalType = 'scoutAlert' | 'selectScout' | 'selectScoutBeforeSendMessage' | '';

/**
 * チャットページ
 */
const EnterpriseChatPage: React.FC<PageCommonProps> = () => {
  /**
   * ログイン情報
   */
  const { user: myself } = useLoginStatus();
  const uid = firebase.auth.currentUser?.uid ?? '';

  /**
   * Local State
   */
  const [initialized, setInitialized] = useState(false);
  const [bookmarked, setBookmarked] = useState(false);
  const [searchChatText] = useState('');
  const [selectedRoomId, setSelectedRoomId] = useState<string>('');
  const [showModal, setShowModal] = useState<ShowModalType>('');
  const [chatRating, setChatRating] = useState<number>(0);
  const [messageData, setMessageData] = useState<{ roomId: string; message: string } | null>(null);

  /**
   * Hooks
   */
  const history = useHistory();
  const location = useLocation<UserInfoType>();
  const match = useRouteMatch<{ roomId: string }>();
  const { showErrorPopup, showSuccessPopup } = usePopup();
  const [rooms, loadingRooms, errorRooms] = useChatRooms(firebase, uid);
  const [cursors, loadingCursors, errorCursors] = useServerCursors(firebase, uid, rooms);
  const {
    room,
    messages,
    getPrev,
    loadingOlders,
    errorOlders,
    loadingNewers,
    errorNewers
  } = useChatRoom(selectedRoomId, cursors);
  const { counts, markAsRead } = useChatNotifications();
  const { scoutList, loading: scoutListLoading } = useScoutList();

  /**
   * Query
   */
  const [scoutByIdsData, setScoutByIdsData] = useState<any>(null);
  const [
    fetchScoutByIdsQuery,
    { loading: scoutByIdsLoading, error: scoutByIdsError }
  ] = useLazyQuery(FETCH_SCOUTS_BY_IDS_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) setScoutByIdsData(data);
    }
  });

  const [userByNicknamesData, setUserByNicknamesData] = useState<any>(null);
  const [
    fetchUserByNicknamesQuery,
    { loading: userByNicknamesLoading, error: userByNicknamesError }
  ] = useLazyQuery(FETCH_USER_BY_NICKNAMES_QUERY, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) => {
      if (data) setUserByNicknamesData(data);
    }
  });

  const [
    fetchPaymentInfoQuery,
    { data: paymentInfoData, loading: paymentInfoLoading, error: paymentInfoError }
  ] = useLazyQuery(FETCH_PAYMENT_INFO_QUERY, {
    fetchPolicy: 'no-cache'
  });

  /**
   * Mutation
   */
  const [
    mutateSendChatMessage,
    { data: sendMessageData, error: sendMessageError, loading: sendMessageLoading }
  ] = useMutation(SEND_CHAT_MESSAGE);
  const [
    mutateCreateChatRoom,
    { data: createChatRoomData, error: createChatRoomError, loading: createChatRoomLoading }
  ] = useMutation(CREATE_SCOUT_CHAT_ROOM_MUTATION);
  const [
    mutateUpdateChatRoom,
    { data: updateChatRoomData, error: updateChatRoomError, loading: updateChatRoomLoading }
  ] = useMutation(UPDATE_SCOUT_CHAT_ROOM_MUTATION);
  const [
    mutateRateScoutee,
    { data: rateScouteeData, error: rateScouteeError, loading: rateScouteeLoading }
  ] = useMutation(RATE_SCOUTEE_MUTATION);
  const [
    mutateBookmark,
    { data: bookmarkMutationResult, loading: bookmarkLoading, error: bookmarkError }
  ] = useMutation(BOOKMARK_USER_BY_ENTERPRISE_QUERY, {
    onCompleted: ({ bookmarkUser }) => {
      if (!bookmarkUser?.ok) {
        showErrorPopup(`${selectedRoomUser.nickname ?? ''}をお気に入りに追加できませんでした`);
      } else {
        showSuccessPopup(`${selectedRoomUser.nickname ?? ''}をお気に入りに追加しました`);
      }
    },
    onError: () => {
      showErrorPopup(`${selectedRoomUser.nickname ?? ''}をお気に入りに追加できませんでした`);
    }
  });
  /**
   * Variables
   */
  const { nickname, userId, scoutId } = location?.state ?? {
    nickname: '',
    userId: '',
    scoutId: ''
  };
  const loading = loadingRooms || loadingCursors;
  const error = errorRooms || errorCursors;
  const selectedRoom = useMemo(() => rooms?.find((r) => r.id === selectedRoomId) ?? null, [
    rooms,
    selectedRoomId
  ]);
  const selectedRoomScout = useMemo(
    () =>
      scoutByIdsData?.loginSession?.user?.organization?.scouts?.all?.find(
        (scout: Scout) => String(scout?.id) === String(selectedRoom?.scoutId)
      ),
    [scoutByIdsData, selectedRoom]
  );
  const selectedRoomUser = useMemo(
    () =>
      userByNicknamesData?.users?.find(
        (user: { nickname: string }) => user.nickname === selectedRoom?.targetName
      ) ?? null,
    [userByNicknamesData, selectedRoom]
  );

  const roomList = useMemo(() => {
    return rooms.map((room) => {
      const user =
        userByNicknamesData?.users?.find(
          (user: { nickname: string }) => user.nickname === room.targetName
        ) ?? null;
      return {
        chat: room,
        name: user?.nickname ?? '',
        image: user?.profileImageThumbnail?.url ?? '',
        text:
          (scoutByIdsData?.loginSession?.user?.organization?.scouts?.all ?? []).find(
            (scout: { id: string }) => String(scout.id) === String(room.scoutId)
          )?.title ?? (selectedRoomUser?.isBookmarked ? 'お気に入りのエンジニア' : '')
      };
    });
  }, [rooms, scoutByIdsData, userByNicknamesData]);

  const showChatEvaluation = selectedRoom?.hasScoutResponse && selectedRoomUser?.scouteeRating < 3;
  const activeFreeScoutCampaign: ScoutChatAlertCampaign = useMemo(() => {
    const activeCampaigns =
      (paymentInfoData as FetchPaymentInfoQuery)?.loginSession?.user?.organization
        ?.activeCampaigns ?? [];
    const payment = (paymentInfoData as FetchPaymentInfoQuery)?.loginSession?.user?.organization
      ?.payment;
    return (
      activeCampaigns
        .filter((camp) => camp.type === CAMPAIGN_TYPE.FREE_SCOUT)
        // MEMO(oomura, 2021/06/03)Amount & AmountUsedを合算。 EndAtは早く終わる方に合わせる
        .reduce<ScoutChatAlertCampaign>(
          (result, current) => {
            if (current.freeScoutAmount) result.freeScoutAmount += current.freeScoutAmount;
            if (current.freeScoutAmountUsed)
              result.freeScoutAmountUsed += current.freeScoutAmountUsed;

            if (!result.freeScoutEndAt) {
              result.freeScoutEndAt = current.freeScoutEndAt;
            } else if (
              current.freeScoutEndAt &&
              new Date(result.freeScoutEndAt).getTime() > new Date(current.freeScoutEndAt).getTime()
            ) {
              result.freeScoutEndAt = current.freeScoutEndAt;
            }
            return result;
          },
          {
            paidScoutCount: payment?.billing?.paidScoutCount ?? 0,
            scoutUnitPrice: payment?.billing?.scoutUnitPrice ?? 0,
            freeScoutAmount: 0,
            freeScoutAmountUsed: 0,
            freeScoutEndAt: ''
          }
        )
    );
  }, [paymentInfoData]);

  /**
   * selectedScoutIdState
   */
  const [selectedScoutId, setSelectedScoutId] = useState<string>(scoutId);

  /**
   * Func: selectRoom
   */
  const selectRoom = (room: ChatRoomWithMessages) => history.replace(`/enterprise/chat/${room.id}`);

  /**
   * Func:
   */
  const rateScoutee = () => {
    if (!selectedRoomUser?.id) return;
    mutateRateScoutee({ variables: { userId: selectedRoomUser.id, newRating: chatRating } });
  };

  /**
   * Func:
   */
  const handleSearchChatSubmit = (e: Event) => {
    e.preventDefault();
    //TODO(aida)検索処理の追加
    console.log('search for', searchChatText);
  };

  /**
   * Func:
   */
  const sendMessage = (roomId: string, message: string) => {
    if (!roomId || !message) return;
    if (!messages || Object.keys(messages).length === 0) {
      setMessageData({ roomId, message });
      if (!selectedRoom?.scoutId) {
        setShowModal('selectScoutBeforeSendMessage');
      } else {
        setShowModal('scoutAlert');
      }
    } else {
      mutateSendChatMessage({ variables: { chatRoomId: roomId, message } });
    }
  };

  /**
   * 選択されたユーザーが変更されたら、レートの値を変える
   */
  useEffect(() => {
    setChatRating(selectedRoomUser?.scouteeRating ?? 0);
  }, [selectedRoomUser]);

  /**
   * チャットメッセージ送信後の処理
   */
  useEffect(() => {
    if (sendMessageData && !sendMessageLoading) {
      if (sendMessageData?.sendChatMessage?.ok) {
        showSuccessPopup('メッセージを送信しました');
        setShowModal('');
        setMessageData(null);
      } else {
        showErrorPopup('メッセージを送信できませんでした');
      }
    }
  }, [sendMessageData, sendMessageLoading, sendMessageError]);

  /**
   * コミュニケーション満足度送信後の処理
   */
  useEffect(() => {
    if (rateScouteeData && !rateScouteeLoading) {
      if (rateScouteeData?.rateScoutee?.ok) {
        showSuccessPopup('コミュニケーション満足度を回答しました');
      } else {
        if (getErrorCodes(rateScouteeError).includes('E-001-1000')) {
          showErrorPopup('コミュニケーション満足度が現状より低い評価は回答できません');
        } else {
          showErrorPopup('コミュニケーション満足度を回答できませんでした');
        }
      }
    }
  }, [rateScouteeData, rateScouteeError, rateScouteeLoading]);

  /**
   * :roomId が存在しなかった場合、 location state から userId を取得し rooms で該当のある部屋を :roomId にセット
   * roomが存在していなかった場合は、ルームを作成
   */
  useEffect(() => {
    if (match.params.roomId) {
      setSelectedRoomId(match.params.roomId);
      setInitialized(true);
      return;
    }
    if (initialized) return;
    if (!nickname) return;

    const target = (rooms ?? []).find((room) => room.targetName === nickname);
    if (target) {
      history.replace(`/enterprise/chat/${target.id}`);
    } else {
      mutateCreateChatRoom({ variables: { userId } });
    }
    setInitialized(true);
  }, [
    initialized,
    rooms,
    userId,
    nickname,
    match.params.roomId,
    setSelectedRoomId,
    setInitialized
  ]);

  /**
   * roomsが更新されたら、user, scout情報を取得する
   * @param room
   */
  const scoutIds = useMemo(() => rooms.map((room) => room.scoutId).filter((id) => id), [rooms]);
  const nicknames = useMemo(
    () => rooms.map((room) => room.targetName).filter((nickname) => nickname),
    [rooms]
  );
  useEffect(() => {
    // if (rooms.length > 0) {
    // scout情報の取得

    if (scoutIds.length > 0) {
      fetchScoutByIdsQuery({ variables: { idList: scoutIds, field: 'updated', order: 'ASC' } });
    }

    // user情報の取得
    if (nicknames.length > 0) {
      fetchUserByNicknamesQuery({ variables: { nicknames } });
    }
    // }
  }, [scoutIds, nicknames]);

  /**
   * ルーム作成エラー
   */
  useEffect(() => {
    if (createChatRoomError && !createChatRoomLoading) {
      showErrorPopup('チャットルームを作成できませんでした');
    }
  }, [createChatRoomError, createChatRoomLoading]);

  /**
   * チャットにスカウト紐づけた結果（成功）
   */
  useEffect(() => {
    if ((!updateChatRoomLoading && updateChatRoomData?.updateScoutChatRoom?.ok) || bookmarked) {
      showSuccessPopup('スカウトを紐付けました');
      setShowModal((prev) => {
        if (prev === 'selectScout') return '';
        else if (prev === 'selectScoutBeforeSendMessage') {
          setSelectedScoutId('');
          setBookmarked(false);
          return 'scoutAlert';
        }
        return prev;
      });
    }
  }, [
    updateChatRoomData,
    updateChatRoomLoading,
    setShowModal,
    setSelectedScoutId,
    bookmarked,
    setBookmarked
  ]);

  /**
   * モーダル表示で実行したいもの
   */
  useEffect(() => {
    switch (showModal) {
      case 'scoutAlert': {
        fetchPaymentInfoQuery({
          variables: { yearMonth: Number(formatDate(new Date(), DATE_FORMAT.YEAR_MONTH_INT)) }
        });
      }
    }
  }, [showModal]);

  /**
   * チャットにスカウト紐づけた結果（エラー）
   */
  useEffect(() => {
    if (!updateChatRoomLoading && updateChatRoomError) {
      showErrorPopup('スカウトの紐付けに失敗しました');
    }
  }, [updateChatRoomError, updateChatRoomLoading]);

  /**
   * チャット作成後にチャットルームに遷移
   */
  useEffect(() => {
    createChatRoomData?.createScoutChatRoom?.roomId &&
      history.replace({
        pathname: `/enterprise/chat/${createChatRoomData.createScoutChatRoom.roomId}`,
        state: location.state
      });
  }, [createChatRoomData]);

  /**
   * エンジニアのブックマーク
   */
  const bookmarkEngineer = (userId: string, isUserBookmark: boolean) => {
    if (bookmarkLoading) return;
    if (!isUserBookmark) {
      mutateBookmark({
        variables: { userId }
      });
    }
    setBookmarked(true);
  };

  /**
   * URLのroomIdがログインユーザのものでない場合、NotFound
   */
  useEffect(() => {
    if (!rooms || !myself || !match.params.roomId) return;
    const isRoom = !!rooms.find((room) => room.id === match.params.roomId) ?? null;
    if (rooms.length > 0 && !isRoom) history.replace('/notfound');
  }, [myself, rooms, match.params.roomId]);

  return (
    <>
      <StyledEnterpriseChatPage>
        <div>
          <ChatRoomList
            myself={myself}
            rooms={roomList}
            onSelectRoom={selectRoom}
            selectedRoomId={selectedRoomId}
            counts={counts}
          />
        </div>
        <StyledChatBody>
          <StyledChatBodyHeader>
            {selectedRoom && (
              <>
                <span>{selectedRoom?.targetName}</span>
                <StyledChatScoutSelectButton onClick={() => setShowModal('selectScout')}>
                  {selectedRoomScout?.title ??
                    (selectedRoomUser?.isBookmarked
                      ? 'お気に入りのエンジニア'
                      : 'スカウトに紐付ける')}
                </StyledChatScoutSelectButton>
              </>
            )}
          </StyledChatBodyHeader>
          {selectedRoom && (
            <>
              <ChatMessages
                messages={messages}
                ownId={uid}
                targetImageUrl={selectedRoomUser?.profileImageThumbnail?.url}
                markAsRead={markAsRead}
                getPrev={getPrev}
                loadingOlders={loadingOlders}
                errorOlders={errorOlders}
                loadingNewers={loadingNewers}
                errorNewers={errorNewers}
                hasNewMessagesOlders={selectedRoom?.hasNewMessagesOlders ?? null}
              />
              {showChatEvaluation && (
                <ChatEvaluation
                  username={selectedRoom?.targetName ?? ''}
                  chatRating={chatRating}
                  changeRating={setChatRating}
                  submit={rateScoutee}
                  disabledSubmit={selectedRoomUser?.scouteeRating === 3}
                />
              )}
            </>
          )}
          <ChatMessageInput
            roomId={selectedRoom?.id ?? ''}
            sendMessage={sendMessage}
            sendResult={sendMessageData}
            loading={sendMessageLoading}
            error={!!sendMessageError}
          />
        </StyledChatBody>
      </StyledEnterpriseChatPage>
      <EditModal
        show={['selectScout', 'selectScoutBeforeSendMessage'].includes(showModal)}
        displayModal={(flag) => setShowModal((prev) => (flag ? prev : ''))}
        okClickText="決定"
        okClick={() => {
          Number(selectedScoutId) === 0
            ? bookmarkEngineer(selectedRoomUser.id, selectedRoomUser.isBookmarked)
            : mutateUpdateChatRoom({
                variables: { chatRoomId: selectedRoomId, scoutId: selectedScoutId }
              });
        }}
        disabledOkButton={updateChatRoomLoading || !selectedScoutId}
        cancelClickText="キャンセル"
        cancelClick={() => setShowModal('')}
        disabledCancelButton={updateChatRoomLoading}
        buttonWidth="120"
      >
        <ScoutChatSelectBox
          targetName={selectedRoom?.targetName}
          scouts={scoutList}
          loading={scoutListLoading}
          selectedScoutId={selectedScoutId || selectedRoomScout?.id}
          selectScout={setSelectedScoutId}
        />
      </EditModal>
      <EditModal
        show={showModal === 'scoutAlert'}
        displayModal={(flag) => setShowModal(flag ? 'scoutAlert' : '')}
        okClick={() => {
          if (!messageData) return;
          mutateSendChatMessage({
            variables: { chatRoomId: messageData.roomId, message: messageData.message }
          });
        }}
        okClickText="送信"
        disabledOkButton={sendMessageLoading}
        cancelClickText="キャンセル"
        cancelClick={() => setShowModal('')}
        disabledCancelButton={sendMessageLoading}
        buttonWidth="120"
      >
        <ScoutChatAlertBox
          loading={paymentInfoLoading}
          error={!!paymentInfoError}
          campaignData={activeFreeScoutCampaign}
        />
      </EditModal>
    </>
  );
};
export default EnterpriseChatPage;

const StyledEnterpriseChatPage = styled.div`
  min-height: 800px;
  display: flex;

  & > *:first-child {
    width: 260px;
    display: flex;
  }

  & > *:last-child {
    width: calc(100% - 260px);
  }
`;

const StyledChatBody = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-left: 1px solid #eeeeee;
`;

const StyledChatBodyHeader = styled.div`
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #eeeeee;

  & > *:first-child {
    font-size: 24px;
    font-weight: bold;
  }
`;

const StyledChatScoutSelectButton = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: #0fafa9;
  text-decoration: underline;
  max-width: 280px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:visited {
    color: #0fafa9;
  }
`;
