import React, { useEffect, useMemo, useState } from 'react';
import { Pagination } from '@meettry/ui-components/components/molecules/Pagination';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import { StyledContentsWrapper } from '@meettry/engineer/components/pages/EnterpriseDashboard';
import { BookmarkedListItem, ScoutListItem } from '@meettry/company/component/molecules/ScoutList';
import { CheckBox, SearchBar } from '@meettry/company/component/atoms/input';
import { SortButton } from '@meettry/company/component/atoms/btn';
import { LineButton, PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import ScoutResultTitleIcon from '@meettry/company/images/icon/icon_resume_black.png';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import useScoutList, { SCOUT_LIST_FIELDS } from '@meettry/ui-components/hooks/scout/useScoutList';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import ScoutEditorBox, {
  DefaultScoutEditModel,
  isValidScoutEditModel,
  SCOUT_EDIT_TYPE,
  ScoutEditModel
} from '@meettry/ui-components/components/molecules/ScoutEditorBox';
import { EditModal } from '@meettry/ui-components/components/organisms/Modal';
import { useQuery } from '@apollo/react-hooks';
import { GET_TAG_LIST } from '@meettry/company/graphql/query/scout';
import { Tag } from '@meettry/ui-components/types/tag';
import { useHistory } from 'react-router-dom';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';

type ModalType = 'createScout' | 'deleteScout' | '';

/**
 * EnterpriseScout Page Component
 */
const EnterpriseScout: React.FC<PageCommonProps> = () => {
  const history = useHistory();
  const { showCancelConfirmationDialog, closeDialog } = useDialog();
  /**
   * State
   */
  const [showModal, setShowModal] = useState<ModalType>('');
  const [scoutEditModel, setScoutEditModel] = useState<ScoutEditModel>(DefaultScoutEditModel());
  const [scoutEditError, setScoutEditError] = useState(false);
  const [deleteTargetScout, setDeleteScoutTarget] = useState({ title: '', id: '' });
  const [dirty, setDirty] = useState(false);

  /**
   * Query
   */
  const { data: tagData } = useQuery(GET_TAG_LIST);

  /**
   * Custom Hook
   */
  const {
    scoutList,
    scoutCount,
    matchedCounts,
    loading,
    error,
    setSearchText,
    orders,
    offset,
    paging,
    setPaging,
    changeOrder,
    includeInactive,
    setIncludeInactive,
    mutateCreateScout,
    createScoutData,
    createScoutLoading,
    mutateDeleteScout,
    deleteScoutLoading,
    deleteScoutData,
    allBookmarkCount,
    updateActiveScout
  } = useScoutList();

  /**
   * Func: createScout
   * スカウトの作成
   */
  const createScout = () => {
    const isValid = isValidScoutEditModel(scoutEditModel);
    setScoutEditError(!isValid);
    if (!isValid) return;
    const {
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive,
      requiredGeneralTags,
      requiredSkillTags,
      expectedGeneralTags,
      expectedSkillTags
    } = scoutEditModel;
    const createModel = {
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount: Number(recruitmentCount),
      isActive,
      scoutTags: [
        ...requiredGeneralTags,
        ...requiredSkillTags,
        ...expectedGeneralTags,
        ...expectedSkillTags
      ]
    };
    mutateCreateScout({ variables: { input: createModel } });
  };

  /**
   * Func: ShowCreateScoutModal
   * スカウト作成モーダルの表示
   */
  const showCreateScoutModal = () => {
    setScoutEditModel(DefaultScoutEditModel());
    setShowModal('createScout');
    setScoutEditError(false);
  };

  /**
   * Func: deleteScout
   * スカウトの削除
   */
  const deleteScout = () => {
    mutateDeleteScout({ variables: { id: deleteTargetScout.id } });
  };

  /**
   * スカウト作成が成功したらモーダルを閉じて、詳細画面を表示する
   */
  useEffect(() => {
    if (!createScoutLoading && createScoutData) {
      setShowModal('');
      history.push(`/enterprise/scout/${createScoutData.createScout.scout.id}`);
    }
  }, [createScoutData, createScoutLoading, setShowModal]);

  /**
   * スカウト削除が成功したらモーダルを閉じる
   */
  useEffect(() => {
    if (!deleteScoutLoading && deleteScoutData) {
      setShowModal('');
    }
  }, [deleteScoutLoading, deleteScoutData, setShowModal]);

  /**
   * variables
   */
  const tags: Tag[] = tagData?.tagList ?? [];
  const OrderSelectOptions = useMemo(
    () =>
      Object.keys(SCOUT_LIST_FIELDS).map((key) => ({
        key: SCOUT_LIST_FIELDS[key].key,
        name: SCOUT_LIST_FIELDS[key].name
      })),
    []
  );

  const onCancel = () => {
    //MEMO(aida) 内容が編集されている場合に確認モーダルを表示する
    if (dirty) {
      showCancelConfirmationDialog(
        ['保存されていない変更を破棄しますか?'],
        () => {
          closeDialog();
          setShowModal('');
          setScoutEditModel(DefaultScoutEditModel());
          setDirty(false);
        },
        () => {
          closeDialog();
        }
      );
      return;
    }
    setShowModal('');
  };

  return (
    <StyledContentsWrapper>
      <div>
        <StyledScoutResultWrap>
          <StyledScoutResultTitle>スカウト</StyledScoutResultTitle>
          <StyledScoutResult>
            {matchedCounts.map((matchedCount, index) => (
              <StyledScoutResultContent key={index}>
                <StyledScoutResultHeading>{matchedCount.name}</StyledScoutResultHeading>
                <StyledScoutResultNumber>
                  {matchedCount.count}
                  <StyledScoutResultNumberSmall>人</StyledScoutResultNumberSmall>
                </StyledScoutResultNumber>
              </StyledScoutResultContent>
            ))}
          </StyledScoutResult>
        </StyledScoutResultWrap>
        <StyledScoutList>
          <StyledScoutListHead>
            <StyledScoutListTitle>スカウトリスト</StyledScoutListTitle>
            <StyledScoutListSort>
              <CheckBox
                checked={includeInactive}
                onChange={setIncludeInactive}
                name="scout_list_inactive_filter"
              />
              <SearchBar
                margin={[0, 0, 0, 12]}
                placeHolder="スカウトリストを検索"
                onSubmit={setSearchText}
              />
              <StyledScoutListDesc>表示順</StyledScoutListDesc>
              <SortButton
                margin={[0, 0, 0, 12]}
                options={OrderSelectOptions}
                onChange={changeOrder}
                value={orders.key}
              />
              <LineButton
                margin={[0, 0, 0, 20]}
                size={STYLE_TYPE.SIZE.SMALL}
                width="100"
                onClick={() => setShowModal('createScout')}
              >
                新規作成
                <StyledCreateBtnIcon iconName="plus" iconColor="primary" />
              </LineButton>
            </StyledScoutListSort>
          </StyledScoutListHead>
          <StyledScoutListWrap>
            {loading ? (
              <StyledScoutListLoader>
                <Loader />
              </StyledScoutListLoader>
            ) : scoutList.length > 0 ? (
              <>
                {scoutList.map((scout) => (
                  <ScoutListItem
                    key={scout.id}
                    scout={scout}
                    updateActiveScout={updateActiveScout}
                    deleteScout={(id, title) => {
                      setDeleteScoutTarget({ id, title });
                      setShowModal('deleteScout');
                    }}
                  />
                ))}
                <BookmarkedListItem title="お気に入りのエンジニア" count={allBookmarkCount} />
              </>
            ) : (
              <>
                <BookmarkedListItem title="お気に入りのエンジニア" count={allBookmarkCount} />
                <StyledNothingScoutList>
                  <StyledNothingScoutTitle>スカウトリストを作成しよう</StyledNothingScoutTitle>
                  <StyledNothingScoutDesc>
                    スカウトリストを作成し、
                    <br />
                    条件に合うエンジニアをスカウトしましょう！
                    <br />
                    お気に入り登録やコメントの追加も可能です
                  </StyledNothingScoutDesc>
                  <PrimaryButton
                    size={STYLE_TYPE.SIZE.MEDIUM}
                    width="180"
                    margin={[30, 'auto', 0, 'auto']}
                    onClick={() => setShowModal('createScout')}
                  >
                    <StyledNothingScoutCreateBtnIcon
                      iconName="plus"
                      iconColor="white"
                      fileType="png"
                    />
                    さっそく作る
                  </PrimaryButton>
                </StyledNothingScoutList>
              </>
            )}
          </StyledScoutListWrap>
        </StyledScoutList>
      </div>
      {scoutCount > offset && (
        <Pagination
          maxCount={scoutCount}
          viewCount={offset}
          currentPage={paging}
          setCurrentPage={setPaging}
          marginTop={30}
        />
      )}
      <EditModal
        title={['スカウト新規作成']}
        show={showModal === 'createScout'}
        displayModal={(flag) =>
          !createScoutLoading && (flag ? showCreateScoutModal() : setShowModal(''))
        }
        okClick={() => createScout()}
        okClickText="作成"
        disabledOkButton={createScoutLoading}
        cancelClickText="キャンセル"
        cancelClick={() => {
          onCancel();
        }}
        disabledCancelButton={createScoutLoading}
        buttonWidth="120"
      >
        <ScoutEditorBox
          type={SCOUT_EDIT_TYPE.CREATE}
          model={scoutEditModel}
          setModel={setScoutEditModel}
          error={scoutEditError}
          tags={tags}
          setDirty={setDirty}
          dirty={dirty}
        />
      </EditModal>
      <EditModal
        show={showModal === 'deleteScout'}
        displayModal={(flag) => setShowModal(flag ? 'deleteScout' : '')}
        okClick={() => deleteScout()}
        okClickText="削除する"
        disabledOkButton={deleteScoutLoading}
        cancelClickText="キャンセル"
        cancelClick={() => {
          setDeleteScoutTarget({ id: '', title: '' });
          setShowModal('');
        }}
        disabledCancelButton={deleteScoutLoading}
        buttonWidth="120"
      >
        <StyledConfirmScoutDelete>
          「{deleteTargetScout.title ?? ''}」 を削除しますか？
        </StyledConfirmScoutDelete>
      </EditModal>
    </StyledContentsWrapper>
  );
};
export default EnterpriseScout;

// NothingScoutList
const StyledNothingScoutList = styled.div`
  width: 100%;
  padding: 50px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  box-sizing: border-box;
  background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
`;

const StyledNothingScoutTitle = styled.span`
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 20px;
  font-weight: bold;
`;

const StyledNothingScoutDesc = styled.p`
  margin-top: 21px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 14px;
  font-weight: normal;
  line-height: 24px;
`;

const StyledNothingScoutCreateBtnIcon = styled(Icon)`
  margin-right: 8px;
`;

// ScoutListDetail

const StyledScoutResultWrap = styled.div`
  margin: 0 auto;
  max-width: 800px;
  width: 100%;
`;

const StyledScoutResultTitle = styled.h2`
  font-size: 18px;
  color: ${COLOR_DEFINITIONS.BG.FOOTER};
  font-weight: bold;
  &::before {
    content: '';
    display: inline-block;
    margin-right: 5px;
    width: 18px;
    height: 18px;
    background: url(${ScoutResultTitleIcon}) no-repeat;
    background-size: cover;
  }
`;

const StyledScoutList = styled.div`
  margin: 40px auto 0 auto;
  max-width: 800px;
  width: 100%;
`;

const StyledScoutListHead = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledScoutListTitle = styled.h2`
  font-size: 18px;
  color: ${COLOR_DEFINITIONS.BG.FOOTER};
  font-weight: bold;
`;

const StyledScoutListSort = styled.div`
  display: flex;
  align-items: center;
  height: 30px;
`;

const StyledScoutListDesc = styled.span`
  display: block;
  margin-left: 12px;
  font-size: 12px;
  font-family: ${FONT_FAMILY.COMMON};
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-weight: 500;
`;

const StyledScoutListWrap = styled.div`
  margin-top: 15px;
`;

const StyledCreateBtnIcon = styled(Icon)`
  margin-left: 8px;
`;

const StyledScoutResult = styled.ul`
  margin-top: 25px;
  display: flex;
  width: 100%;
  max-width: 800px;
  padding-left: 0;
`;

const StyledScoutResultContent = styled.li`
  width: calc(100% / 4);
  padding: 20px 30px;
  box-sizing: border-box;
  list-style: none;
  text-align: center;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: bold;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  & + & {
    border-left: none;
  }
`;

const StyledScoutResultHeading = styled.span`
  display: block;
  font-size: 12px;
`;

const StyledScoutResultNumber = styled.span`
  display: block;
  font-size: 32px;
  margin-top: 8px;
`;

const StyledScoutResultNumberSmall = styled.span`
  font-size: 20px;
`;

const StyledScoutListLoader = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const StyledConfirmScoutDelete = styled.div`
  padding-top: 30px;
  padding-bottom: 30px;
  text-align: center;
`;
