import React from 'react';
import styled from 'styled-components';
import HighlightedText from '@meettry/ui-components/components/atoms/HighlightedText';
import Icon, { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import {
  PrimaryIconButton,
  SecondaryIconButton,
  TertiaryIconButton
} from '@meettry/ui-components/components/atoms/Button/IconButton';
import defaultProfile from '@meettry/ui-components/images/avatar/icon_default_avatar.png';

type Props = {
  userInfo: any;
  buttonStyle: any;
  isFollow?: boolean;
  isBookmark?: boolean;
  onClick: (username: string) => void;
  onClickFollow?: (userId: string) => void;
  onClickBookmark?: (userId: string, nickname: string, isBookmarkUser: boolean) => void;
  onClickMessage?: (scoutRoomId: string, userId: string, nickname: string) => void;
  isLoginUserCompany: boolean;
  searchWord?: string;
};

/**
 *
 * @param props
 * @constructor
 */
const CassetteUser: React.FC<Props> = (props) => {
  const {
    userInfo,
    buttonStyle,
    onClick,
    onClickFollow,
    onClickMessage,
    onClickBookmark,
    isFollow = false,
    isBookmark = false,
    isLoginUserCompany,
    searchWord = ''
  } = props;

  const handleClickFollow = () => {
    onClickFollow && onClickFollow(userInfo.id);
  };

  const handleClickBookmark = () => {
    onClickBookmark && onClickBookmark(userInfo.id, userInfo.nickname, isBookmark);
  };

  const handleClickMessage = () => {
    onClickMessage && onClickMessage(userInfo.chat?.scoutRoomId, userInfo.id, userInfo.nickname);
  };

  const followIconInfo: IconPropsType = {
    iconName: 'followers',
    iconColor: isFollow ? 'gray' : 'white'
  };
  const FollowButton = () =>
    isFollow ? (
      <TertiaryIconButton
        size={buttonStyle.size}
        width={buttonStyle.width}
        iconProps={followIconInfo}
        onClick={handleClickFollow}
      >
        フォロー中
      </TertiaryIconButton>
    ) : (
      <PrimaryIconButton
        size={buttonStyle.size}
        width={buttonStyle.width}
        iconProps={followIconInfo}
        onClick={handleClickFollow}
      >
        フォローする
      </PrimaryIconButton>
    );

  const bookmarkIconInfo: IconPropsType = {
    iconName: 'heart',
    iconColor: isBookmark ? 'gray' : 'white'
  };
  const BookmarkButton = () =>
    isBookmark ? (
      <TertiaryIconButton
        size={buttonStyle.size}
        width={buttonStyle.width}
        iconProps={bookmarkIconInfo}
        onClick={handleClickBookmark}
      >
        お気に入り済
      </TertiaryIconButton>
    ) : (
      <PrimaryIconButton
        size={buttonStyle.size}
        width={buttonStyle.width}
        iconProps={bookmarkIconInfo}
        onClick={handleClickBookmark}
      >
        お気に入り
      </PrimaryIconButton>
    );

  const PrimaryButton = isLoginUserCompany ? BookmarkButton : FollowButton;

  const messageIconInfo: IconPropsType = {
    iconName: 'chatIcon',
    iconColor: userInfo?.userDetail?.enableAcceptingScout !== 1 ?? true ? 'gray' : 'primary'
  };

  return (
    <>
      <StyledCassetteUser onClick={() => onClick(userInfo.nickname)}>
        <div>
          <CircleAvatar
            src={
              userInfo.profileImage && userInfo.profileImage.url
                ? userInfo.profileImage.url
                : defaultProfile
            }
            size="md"
          />
        </div>
        <div>
          <StyledCassetteUserInfo>
            <div>
              <StyledCassetteUserName>
                <HighlightedText keyword={searchWord}>{userInfo.nickname}</HighlightedText>
              </StyledCassetteUserName>
              <StyledCassetteUserSubInfo>
                {userInfo.location && (
                  <StyledIconWrapper>
                    <Icon iconName="map" iconColor="gray" width="17px" />
                    {userInfo.location}
                  </StyledIconWrapper>
                )}
                {userInfo.job && <StyledJopWrapper>{userInfo.job}</StyledJopWrapper>}
              </StyledCassetteUserSubInfo>
            </div>
            <StyledCassetteButtons>
              <PrimaryButton />
              {isLoginUserCompany ? (
                <SecondaryIconButton
                  size={buttonStyle.size}
                  width={buttonStyle.width}
                  iconProps={messageIconInfo}
                  onClick={handleClickMessage}
                  disabled={userInfo?.userDetail?.enableAcceptingScout !== 1 ?? true}
                >
                  メッセージ
                </SecondaryIconButton>
              ) : null}
            </StyledCassetteButtons>
          </StyledCassetteUserInfo>
          <StyledCassetteUserDescription>
            <HighlightedText keyword={searchWord}>{userInfo.bio}</HighlightedText>
          </StyledCassetteUserDescription>
        </div>
      </StyledCassetteUser>
    </>
  );
};

export default CassetteUser;

const StyledCassetteUser = styled.div`
  display: flex;
  padding: 30px;
  color: inherit;
  text-decoration: none;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 130px;
    padding-right: 30px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 130px);
  }
`;

const StyledCassetteUserInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCassetteUserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 220px;
  line-height: 1.5;
`;

const StyledCassetteUserSubInfo = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;
  & img {
    vertical-align: middle;
  }
  & > * + * {
    margin-left: 10px;
  }
`;

const StyledIconWrapper = styled.div`
  font-size: 12px;
  & > * {
    margin-right: 5px;
  }
`;

const StyledJopWrapper = styled.div`
  font-size: 12px;
  font-weight: bold;
`;

const StyledCassetteButtons = styled.div`
  display: inline-flex;
  min-width: 280px;
  height: 100%;
  white-space: nowrap;
  & > * + * {
    margin-left: 10px;
  }
`;

const StyledCassetteUserDescription = styled.p`
  font-size: 14px;
  color: #333333;
  line-height: 1.75;
  word-break: break-word;
  margin-top: 10px;
`;
