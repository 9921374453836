import React from 'react';
import styled, { css } from 'styled-components';
import defaultAvatarIcon from '@meettry/ui-components/images/avatar/icon_default_avatar.png';
import iconCompanyGray from '@meettry/ui-components/images/avatar/icon_company_gray.png';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type AvatarType = {
  src: string;
  size: string;
  alt: string;
  circle: boolean;
};

export const Avatar: React.FC<AvatarType> = (props) => {
  const { src, size = 'lg', alt = '', circle = false, ...restProps } = props;
  return (
    <StyledAvatar size={size} src={src} circle={circle} {...restProps}>
      <img src={src} alt={alt} />
    </StyledAvatar>
  );
};

type AvatarSizeType = {
  src: string;
  circle?: boolean;
  size: string;
};

const avatarSizePicker = ({ size }: AvatarSizeType) => {
  switch (size) {
    case 'sm':
      return css`
        width: 30px;
        min-height: 30px;
      `;
    case 'smx':
      return css`
        width: 40px;
        min-height: 40px;
      `;
    case 'md':
      return css`
        max-width: 100px;
        min-height: 100px;
      `;
    case 'lg':
      return css`
        max-width: 200px;
        min-height: 200px;
      `;
  }
};

const StyledAvatar = styled.div<{ src: string; circle: boolean; size: string }>`
  width: 100%;
  background-position: ${({ src }) => `${src === iconCompanyGray ? 'center' : 'top'}`};
  background-size: ${({ src }) => `${src === iconCompanyGray ? '60%' : 'cover'}`};
  background-repeat: no-repeat;
  background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ src }) => `url(${src})`};
  border-radius: ${({ circle }) => (circle ? '50%' : '0')};
  overflow: hidden;
  ${(props) => avatarSizePicker(props)}
  && {
    img {
      display: none;
      width: 100%;
    }
  }
`;

type CircleAvatarType = {
  name?: string;
  src?: string;
  size?: string;
  bordered?: boolean;
  style?: { [key: string]: string };
};

export const CircleAvatar: React.FC<CircleAvatarType> = (props) => {
  const { name = '', src = '', size = 'lg', bordered = false, ...rest } = props;
  return (
    <Avatar size={size} alt={name} src={src ? src : defaultAvatarIcon} circle={true} {...rest} />
  );
};
