import gql from 'graphql-tag';

// プロフィール画像取得クエリ
export const GET_AVATAR_IMAGE_PATH = gql`
  query {
    loginSession {
      user {
        profileImage {
          url
          updated
        }
        organization {
          logoImage {
            url
            updated
          }
        }
      }
    }
  }
`;
