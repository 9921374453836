import React from 'react';
import styled, { css } from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type TextareaType = {
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  defaultValue?: string;
  placeholder: string;
  error?: boolean;
  value?: string;
};

export const Textarea: React.FC<TextareaType> = (props) => {
  const { onChange, defaultValue, placeholder, error, value } = props;
  return (
    <StyledTextarea
      onChange={onChange}
      defaultValue={defaultValue}
      value={value}
      placeholder={placeholder}
      error={error}
    />
  );
};

export default Textarea;

const StyledTextarea = styled.textarea<{ error?: boolean }>`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  box-sizing: border-box;
  appearance: none;
  font-size: 16px;
  font-family: sans-serif;
  padding: 16px 20px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  border: ${COLOR_DEFINITIONS.LINE.SECONDARY} solid 1px;
  border-radius: 4px;
  height: 170px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
  ${({ error }) =>
    error &&
    css`
      background-color: ${COLOR_DEFINITIONS.BG.MODAL.MESSAGE};
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      :focus {
        outline: none;
        border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      }
    `}
`;
