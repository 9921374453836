import React, { useState } from 'react';
import styled from 'styled-components';
import * as Formatter from '@meettry/company/utils/format';
import { Calendar } from '@meettry/company/component/atoms/btn/calendar';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
/* TODO(minami): β版以降での実装
import {
  Pagination,
  DEFAULT_PAGINATION_DISPLAY_ITEM
} from '@meettry/company/component/molecules/pagination';
import { Modal } from '@meettry/company/component/organisms/modal';
import { BillModal } from '@meettry/company/component/organisms/billModal';*/

const tableHeading = [
  { type: 'date', text: '請求日' },
  { type: 'content', text: '契約内容' },
  { type: 'period', text: '契約期間' },
  { type: 'price', text: '請求額' }
  // TODO(minami): β版以降での実装{ type: 'pdf', text: 'pdf' }
];

const tableContent = [
  {
    date: '2019-12-01',
    content: 'プラン名が入ります',
    termStart: '2019-12-01',
    termEnd: '12-01',
    price: 2000,
    confirm: false
  },
  {
    date: '2019-12-01',
    content: 'プラン名が入ります',
    termStart: '2019-12-01',
    termEnd: '12-01',
    price: 35000,
    confirm: false
  },
  {
    date: '2019-12-01',
    content: 'プラン名が入ります',
    termStart: '2019-12-01',
    termEnd: '12-01',
    price: 35000,
    confirm: true
  }
];

export const RefineSearch: React.FC = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  return (
    <StyledBillListContents>
      <Calendar
        labelText="絞り込み検索"
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        showBtn={true}
      />
    </StyledBillListContents>
  );
};

export const BillList: React.FC = () => {
  /* TODO(minami): ベータ版以降での実装
  const [current, setCurrent] = useState(1);
  const [show, displayModal] = useState(false);
  const billModal = tableContent[2].confirm ? (
    <div
      onClick={() => {
        displayModal(true);
      }}
    >
      {tableContent[2].hasBill}
    </div>
  ) : (
    tableContent[2].hasBill
   ); */
  return (
    <div>
      <RefineSearch />
      <StyledBillListTable>
        <thead>
          <tr>
            {tableHeading.map((item, index) => (
              <StyledBillListTableHeading key={index}>{item.text}</StyledBillListTableHeading>
            ))}
          </tr>
        </thead>
        <tbody>
          {tableContent.map((item, index) => (
            <tr key={index}>
              <StyledBillListTableData>
                {Formatter.convertDate(item.date, 'YYYY/MM/DD')}
                {!item.confirm ? <StyledUnsettled>未確定</StyledUnsettled> : null}
              </StyledBillListTableData>
              <StyledBillListTableData>{item.content}</StyledBillListTableData>
              <StyledBillListTableData>
                {Formatter.convertDate(item.termStart, 'YYYY/MM/DD')}〜
                {Formatter.convertDate(item.termEnd, 'MM/DD')}
              </StyledBillListTableData>
              <StyledBillListTableData>
                {Formatter.insertComma(item.price, '')}
              </StyledBillListTableData>
              {/* TODO(minami): β版以降での実装
              <td data-confirm={item.hasBill}>
                {item.confirm ? (
                  <div
                    onClick={() => {
                      displayModal(true);
                    }}
                  >
                    {item.hasBill}
                  </div>
                ) : (
                  item.hasBill
                )}
              </td> */}
            </tr>
          ))}
          {Array.from({ length: 7 }, (_, index) => (
            <tr key={index}>
              <StyledBillListTableData>
                {Formatter.convertDate(tableContent[2].date, 'YYYY/MM/DD')}
              </StyledBillListTableData>
              <StyledBillListTableData>{tableContent[2].content}</StyledBillListTableData>
              <StyledBillListTableData>
                {Formatter.convertDate(tableContent[2].termStart, 'YYYY/MM/DD')}〜
                {Formatter.convertDate(tableContent[2].termEnd, 'MM/DD')}
              </StyledBillListTableData>
              <StyledBillListTableData>
                {Formatter.insertComma(tableContent[2].price, '')}
              </StyledBillListTableData>
              {/* TODO(minami): β版以降での実装
              <td data-confirm={tableContent[2].hasBill}>{billModal}</td> */}
            </tr>
          ))}
        </tbody>
      </StyledBillListTable>
      {/* TODO(minami): β版以降でコメントアウト解除
      <Pagination
        maxLength={8}
        current={current}
        setCurrent={setCurrent}
        displayNumber={DEFAULT_PAGINATION_DISPLAY_ITEM}
      />
      <Modal show={show} displayModal={displayModal} title="" cls="bill-modal">
        <BillModal />
      </Modal> */}
    </div>
  );
};

const StyledBillListContents = styled.div`
  margin-top: 34px;
`;

const StyledTable = StyledBillListContents.withComponent('table');
const StyledBillListTable = styled(StyledTable)`
  width: 100%;
  max-width: 702px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledBillListTableHeading = styled.th`
  height: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  text-align: center;
  vertical-align: middle;
  height: 40px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  font-weight: normal;
`;

const StyledBillListTableData = styled.td`
  height: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  text-align: center;
  vertical-align: middle;
`;

const StyledUnsettled = styled.span`
  display: block;
  width: 40px;
  height: 14px;
  line-height: 14px;
  margin: 5px auto 0;
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  font-size: 10px;
`;
