import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@meettry/engineer/components/atoms/Input';
import { SEX_LIST } from '@meettry/ui-components/constants';

export const InputSex = (props) => {
  const { value, onChange } = props;
  return (
    <StyledInputSex>
      {SEX_LIST.map((sex) => (
        <RadioButton
          key={sex.value}
          type="radio"
          value={sex.value}
          id={`sex_${sex.value}`}
          name="sex"
          label={sex.content}
          checked={value === sex.value}
          onChange={onChange}
        />
      ))}
    </StyledInputSex>
  );
};

const StyledInputSex = styled.div`
  > *:not(:first-child) {
    margin-left: 50px;
  }
`;
