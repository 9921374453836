import React from 'react';
import styled from 'styled-components';
import AdditionalTagInput from '@meettry/ui-components/components/organisms/Forms/AdditionalTagInput';
import { FormItem } from '@meettry/ui-components/components/molecules/Form';
import { RadioButton } from '@meettry/ui-components/components/atoms/Input';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import { TagType } from '@meettry/ui-components/types/userPage';

type ResumeProjectProps = {
  tags: Array<TagType>;
  onChangeTags: (value: any) => void;
  isVisible: boolean;
  onChangeIsVisible: (value: boolean) => void;
};
export const ResumeProject = (props: ResumeProjectProps) => {
  const { tags, onChangeTags, isVisible, onChangeIsVisible } = props;
  const {
    state: { tagList }
  } = useEngineer();

  return (
    <>
      <StyledFormTitle>プロジェクト環境の詳細</StyledFormTitle>
      <FormItem label="言語">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'LANGUAGE' && techStackId
          )}
          tagType="skill"
          placeholder="言語を入力"
          value={tags}
          currentCategory="LANGUAGE"
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem label="フレームワーク">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'FRAMEWORK' && techStackId
          )}
          tagType="skill"
          placeholder="フレームワークを入力"
          value={tags}
          currentCategory="FRAMEWORK"
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem label="OS">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'OS' && techStackId
          )}
          tagType="skill"
          placeholder="OSを入力"
          value={tags}
          currentCategory="OS"
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem label="データベース">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'DATABASE' && techStackId
          )}
          tagType="skill"
          placeholder="データベースを入力"
          value={tags}
          currentCategory="DATABASE"
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem label="インフラ環境">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'INFRASTRUCTURE' && techStackId
          )}
          tagType="skill"
          placeholder="インフラ環境を入力"
          value={tags}
          currentCategory="INFRASTRUCTURE"
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem label="エディター・ツール">
        <AdditionalTagInput
          tagList={tagList.filter(
            ({ category, techStackId }: TagType) => category === 'TOOL' && techStackId
          )}
          tagType="skill"
          placeholder="エディター・ツールを入力"
          value={tags}
          currentCategory="TOOL"
          onChange={onChangeTags}
        />
      </FormItem>
      <StyledFormTitle>公開状態</StyledFormTitle>
      <FormItem label="ステータス">
        <StyledRadioGroup>
          <RadioButton
            label="公開"
            name="status"
            value={true}
            checked={isVisible}
            onChange={onChangeIsVisible}
          />
          <RadioButton
            label="非公開"
            name="status"
            value={false}
            checked={!isVisible}
            onChange={onChangeIsVisible}
          />
        </StyledRadioGroup>
      </FormItem>
    </>
  );
};

export default ResumeProject;

const StyledFormTitle = styled.p`
  font-size: 18px;
  font-weight: bold;
`;

const StyledRadioGroup = styled.div`
  > *:not(:first-child) {
    margin-left: 50px;
  }
`;
