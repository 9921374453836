import React from 'react';
import styled from 'styled-components';
import { flexibleDateString } from '@meettry/ui-components/utils';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import icons from '@meettry/ui-components/images/icons';
import {
  ChatMessageCounts,
  ChatRoom,
  ChatRoomWithMessages
} from '@meettry/ui-components/types/chat';

const chatIcon = icons.primary.chat;

/**
 * ChatRoomListItem
 */
type ChatRoomListItemProps = {
  myself: any;
  name: string;
  image: string;
  room: ChatRoom;
  text: string;
  count: number;
  onClick: () => void;
  isActive: boolean;
};
const ChatRoomListItem: React.FC<ChatRoomListItemProps> = (props) => {
  const { myself, room, count, onClick, isActive, text, name, image } = props;
  const date = room.updatedAt ? flexibleDateString(room.updatedAt) : null;
  const isUnSeen = !!count;

  return (
    <StyledChatListItem unSeen={isUnSeen} active={isActive} key={room.id} onClick={onClick}>
      <StyledOpponentAvatar src={image} size="sm" />
      <StyledChatListItemName>
        <span>{text}</span>
        <span>{name}</span>
      </StyledChatListItemName>
      <StyledChatListItemLeft>
        <StyledChatListItemDate>{date}</StyledChatListItemDate>
        {isUnSeen && <StyledCount>{count}</StyledCount>}
      </StyledChatListItemLeft>
    </StyledChatListItem>
  );
};

/**
 * ChatRoomList
 */
type ChatRoomListProps = {
  myself: any;
  rooms: { chat: ChatRoomWithMessages; text: string; name: string; image: string }[];
  onSelectRoom: (room: ChatRoomWithMessages) => void;
  selectedRoomId: string;
  counts: ChatMessageCounts;
  scouts?: any[];
};
const ChatRoomList: React.FC<ChatRoomListProps> = (props) => {
  const { myself, rooms, onSelectRoom, selectedRoomId, counts, scouts = [] } = props;
  const handleClickRoom = (room: ChatRoomWithMessages) => () => {
    onSelectRoom(room);
  };

  return !!rooms.length ? (
    <StyledChatList>
      {rooms.map((room) => (
        <ChatRoomListItem
          name={room.name}
          image={room.image}
          isActive={selectedRoomId === room.chat.id}
          key={room.chat.id}
          myself={myself}
          text={room.text}
          room={room.chat}
          count={counts[room.chat.id]}
          onClick={handleClickRoom(room.chat)}
        />
      ))}
    </StyledChatList>
  ) : (
    <StyledNoRooms>
      <div>
        <StyledNoRoomChatIcon />
        <StyledNoRoomsHeading>メッセージはありません</StyledNoRoomsHeading>
        <StyledNoRoomTextContainer>
          <StyledNoRoomsText>気になる人や企業に</StyledNoRoomsText>
          <StyledNoRoomsText>メッセージを送りましょう</StyledNoRoomsText>
        </StyledNoRoomTextContainer>
      </div>
    </StyledNoRooms>
  );
};

export default ChatRoomList;

const StyledChatList = styled.ul`
  && {
    width: 100%;
    overflow-y: scroll;
  }
`;

const StyledChatListItem = styled.li<{ unSeen: boolean; active: boolean }>`
  && {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 60px;
    cursor: pointer;
    padding: 0 15px;
    background-color: ${({ unSeen, active }) =>
      active ? '#0FAFA9' : unSeen ? '#FFFFE5' : '#ffffff'};
    color: ${({ active }) => (active ? '#ffffff' : '#999999')};
    border-bottom: 1px solid #eeeeee;
  }
`;

const StyledOpponentAvatar = styled(CircleAvatar)`
  && {
    width: 30px;
    height: 30px;
    min-width: 30px;
  }
`;

const StyledChatListItemName = styled.span`
  && {
    margin-left: 10px;
    overflow: hidden;

    & > span {
      font-weight: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 1.25;
      display: block;

      &:first-child {
        font-size: 12px;
      }

      &:last-child {
        margin-top: 5px;
        font-weight: bold;
      }
    }

    & > .span:last-child {
      display: block;
    }
  }
`;

const StyledChatListItemLeft = styled.div`
  && {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    padding-left: 10px;
  }
`;

const StyledChatListItemDate = styled.span`
  && {
    display: block;
    font-size: 10px;
    margin-right: 6px;
  }
`;

const StyledCount = styled.div`
  && {
    box-sizing: border-box;
    min-width: 26px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    background-color: #fee8d4;
    color: #f4233c;
    padding: 4px 0;
    font-weight: bold;
    font-size: 12px;
  }
`;

const StyledNoRooms = styled.div`
  && {
    width: 100%;
    background-color: #f2fbfb;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  }
`;

const StyledNoRoomChatIcon = styled.div`
  && {
    width: 100%;
    max-width: 60px;
    min-height: 60px;
    background-image: url(${chatIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: 0 auto;
    opacity: 0.4;
  }
`;

const StyledNoRoomsHeading = styled.p`
  && {
    font-size: 18px;
    font-weight: bold;
    color: #0fafa9;
    margin-top: 20px;
  }
`;

const StyledNoRoomTextContainer = styled.div`
  && {
    margin-top: 20px;
  }
`;

const StyledNoRoomsText = styled.p`
  && {
    font-size: 14px;
    color: #333333;
    :not(:first-of-type) {
      margin-top: 10px;
    }
  }
`;
