import shared from '@meettry/engineer/constants/shared.json';

// TODO: JSONファイルをクライアント/サーバーで共有したい
export default {
  ...shared,

  KEYCODE: {
    ENTER: 13,
    IME_ENTER: 229
  },

  URL_SEARCH_PARAM_KEY: {
    SEARCH_QUERY: 'q',
    SEARCH_TYPE: 'type',
    SEARCH_PAGE: 'page',
    SEARCH_DISPLAY_NUMBER: 'd'
  }
};
