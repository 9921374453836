import React from 'react';
import styled from 'styled-components';

import logoImage from '@meettry/ui-components/images/mainLogo/main_logo.png';

export default () => {
  return (
    <StyledMaintenance>
      <StyledMaintenanceLogo>
        <img src={logoImage} alt="meettryのロゴマーク" />
      </StyledMaintenanceLogo>
      <StyledMaintenanceTextMain>メンテナンス中です</StyledMaintenanceTextMain>
      <StyledMaintenanceTextSub>
        システムメンテナンスのためご利用いただけません
      </StyledMaintenanceTextSub>
      <StyledMaintenanceSmall>© meettry All rights reserved.</StyledMaintenanceSmall>
    </StyledMaintenance>
  );
};

const StyledMaintenance = styled.div`
  margin: 0 auto;
  padding: 120px 0;
  width: 460px;
  font-size: 14px;
  color: $text_main_color;
  text-align: center;
  span {
    display: block;
  }
`;

const StyledMaintenanceLogo = styled.div`
  height: 40px;
  margin: 0 auto;
  img {
    height: 100%;
  }
`;

const StyledMaintenanceTextMain = styled.span`
  font-size: 32px;
  font-weight: bold;
  color: $primary_color;
  margin-top: 40px;
`;

const StyledMaintenanceTextSub = styled.span`
  margin-top: 20px;
`;

const StyledMaintenanceSmall = styled.div`
  display: block;
  margin-top: 30px;
  font-size: 12px;
`;
