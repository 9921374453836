/**
 * getQueryErrorMessage
 * ApolloErrorからエラ〜メッセージを取得
 */
export const getQueryErrorMessage = (error: any) => {
  return getErrorCodes(error).map((code) => getErrorPhrase(code));
};

/**
 * getErrorCodes
 * ApolloErrorから GraphQL のエラーコードを取得
 */
export const getErrorCodes = (error: any): string[] => {
  if (!error) {
    return [];
  }
  if (!error.graphQLErrors) {
    return [];
  }
  if (!error.graphQLErrors.length) {
    return [];
  }

  return error.graphQLErrors
    .map((x: any) => {
      if (!x.extensions) {
        return null;
      }
      return x.extensions.code;
    })
    .filter((x: any) => x);
};

/**
 * getErrorPhrase
 * GraphQL Error Code からメッセージを取得
 */
// https://leihauoli.atlassian.net/wiki/spaces/XTEC/pages/198606852/API#%E3%82%A8%E3%83%A9%E3%83%BC%E3%82%B3%E3%83%BC%E3%83%89
export const getErrorPhrase = (error: string) => {
  switch (error) {
    /**
     * CommonError
     */
    case 'E-001-0101': //E-001-0101 : ログイントークンが無効、または空
      return 'ログイントークンが無効です。';
    case 'E-001-0100': //E-001-0100 : ログインしていない
      return 'ログインしていないです。';
    case 'E-001-0002': //E-001-0002 : 何かが見つからない
      return '存在していないです。';
    case 'E-001-0003': //E-001-0003 : 許可されていない処理
      return '許可されていない行為です。';
    case 'E-001-1000': //E-001-1000 : 値が範囲外
      return '値が範囲外です。';
    case 'E-001-1001': //E-001-1001 : 処理対象として指定されたデータが既に処理済みである
      return 'すでに実行済みです。';
    case 'E-001-0001': //E-001-0001 : 予期せぬエラー
      return '予期せぬエラーです。';

    /**
     * UserError
     */
    case 'E-002-0014': // E-002-0014 : ニックネームが重複しています
      return '既に存在するニックネームです。';
    case 'E-002-0011': // E-002-0011 : Emailが未認証
      return 'Emailが未認証';
    case 'E-002-0012': // E-002-0012 : ニックネームが空です
      return 'ニックネームが空です';
    case 'E-002-0001': // E-002-0001 : 既に使用されているメールアドレス
      return '既に使用されているメールアドレス';
    case ' E-002-0004': // E-002-0004 : 既に使用されているユーザー名
      return '既に使用されているユーザー名';
    case 'E-002-0009 ': // E-002-0009 : 無効な本登録用トークン
      return '無効な本登録用トークン';
    case 'E-002-0007': // E-002-0007 : 無効なリクエスト
      return '無効なリクエストです';
    case 'E-002-0006': // E-002-0006 : 無効な処理対象ユーザー
      return '無効な処理対象ユーザーです';
    case 'E-002-0013': // E-002-0013 : 対象ユーザーはスカウトを拒否している
      return '対象ユーザーはスカウトを拒否しています';
    case 'E-002-0008': // E-002-0008 : 不正なリクエスト
      return '不正なリクエストです';
    case 'E-002-0010': // E-002-0010 : 本登録済みユーザー
      return '本登録済みユーザー';
    case 'E-002-0005': // E-002-0005 : 指定されたユーザーが存在しない
      return '指定されたユーザーが存在しません';

    /**
     * ChallengeError
     */
    case 'E-003-0001': //E-003-0001 : 指定されたレジュメが存在しない
      return '指定されたレジュメが存在しない';

    /**
     * ResumeError
     */
    case 'E-004-0001': //E-004-0001 : 指定されたチャレンジが存在しない
      return '指定されたチャレンジが存在しない';

    /**
     * OrganizationError
     */
    case 'E-005-0003': //E-005-0003 : 既に使用されている組織名
      return '既に使用されている組織名';
    case 'E-005-0001': //E-005-0001 : 指定された組織が存在しない
      return '指定された組織が存在しない';
    case 'E-005-0002': //E-005-0002 : ユーザーの所属する組織が存在しない(多くの場合でサーバー側のデータ不整合が原因)
      return 'ユーザーの所属する組織が存在しません';

    /**
     * ChatError
     */
    case 'E-006-0004': //E-006-0004 : チャットメッセージが空
      return 'チャットメッセージが空';
    case 'E-006-0001': //E-006-0001 : 作成しようとしたチャットルームと同じパラメータのチャットルームがすでに存在している
      return '作成しようとしたチャットルームと同じパラメータのチャットルームがすでに存在している';
    case 'E-006-0002': //E-006-0002 : 対象としたチャットルームが存在していない
      return '対象としたチャットルームが存在していない';
    case 'E-006-0003': //E-006-0003 : 対象としたチャットルームの参加者ではない
      return '対象としたチャットルームの参加者ではない';

    /**
     * FileError
     */
    case 'E-007-0001': //E-007-0001 : ファイルサイズが定められた範囲を超えている
      return 'ファイルサイズが定められた範囲を超えている';
    default:
      return error;
  }
};
