import React from 'react';
import styled, { css } from 'styled-components';
import defaultAvatarIcon from '@meettry/engineer/images/icon_default_avatar.png';

export const Avatar = (props) => {
  const { src, size = 'lg', alt = '', circle = false, ...restProps } = props;
  return (
    <StyledAvatar size={size} src={src} circle={circle} {...restProps}>
      <img src={src} alt={alt} />
    </StyledAvatar>
  );
};

const avatarSizePicker = ({ size }) => {
  switch (size) {
    case 'sm':
      return css`
        width: 30px;
        min-height: 30px;
      `;
    case 'md':
      return css`
        max-width: 100px;
        min-height: 100px;
      `;
    case 'lg':
      return css`
        max-width: 200px;
        min-height: 200px;
      `;
  }
};

const StyledAvatar = styled.div`
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #eeeeee;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-image: ${({ src }) => `url(${src})`};
  border-radius: ${({ circle }) => (circle ? '50%' : '0')};
  overflow: hidden;
  ${(props) => avatarSizePicker(props)}
  && {
    img {
      display: none;
      width: 100%;
    }
  }
`;

export const CircleAvatar = (props) => {
  const { name = '', src = '', size = 'lg', bordered = false, ...rest } = props;
  return (
    <Avatar size={size} alt={name} src={src ? src : defaultAvatarIcon} circle={true} {...rest} />
  );
};
