import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';

const POPUP_TYPE = {
  SUCCESS: 'success',
  ERROR: 'error'
};

export const PopupItem = (props) => {
  const { text, type, onClick, onHide, className, timeout = 3000 } = props;
  const onClickHandle = (e) => {
    e.preventDefault();
    typeof onClick === 'function' && onClick();
  };

  useEffect(() => {
    setTimeout(() => {
      onHide();
    }, timeout);
  }, []);

  return (
    <StyledPopupWrapper onClick={onClickHandle} type={type} className={className}>
      {text}
    </StyledPopupWrapper>
  );
};

export const SuccessPopup = (props) => <PopupItem {...props} type={POPUP_TYPE.SUCCESS} />;

export const ErrorPopup = (props) => <PopupItem {...props} type={POPUP_TYPE.ERROR} />;

const StyledPopupWrapper = styled.div`
  width: 100%;
  max-width: 360px;
  padding: 10px;
  border-radius: 5px;
  font-size: 12px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  ${({ type }) => {
    switch (type) {
      case POPUP_TYPE.SUCCESS:
        return css`
          background-color: #0fafa9;
        `;
      case POPUP_TYPE.ERROR:
        return css`
          background-color: #f4233c;
        `;
      default:
        return;
    }
  }}
`;
