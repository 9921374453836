import React from 'react';
import styled, { css } from 'styled-components';
import { useHistory } from 'react-router-dom';
import HighlightedText from '@meettry/engineer/components/atoms/HighlightedText';
import { CircleAvatar } from '@meettry/engineer/components/atoms/Images/Avatars';
import iconPathResumeUserMap from '@meettry/engineer/images/icon-resume-user-map.png';
import Icon from '@meettry/engineer/components/atoms/Icon/Icon';

/**
 * CassetteUser Component
 * MEMO(パク) : メッセージ機能ができるようになったら、コメントしたメッセージボタン使用する
 */
const CassetteUser = (props) => {
  const {
    data,
    onClickFollow,
    onClickChat,
    isLoginUserCompany = false,
    isUserFollowed = false,
    searchWord
  } = props;
  const history = useHistory();
  const { id, nickname, profileImage, location, bio, job } = data;
  const userPagePath = `/user/${nickname}`;

  const onClickCassetteUser = () => {
    history.push(userPagePath);
  };

  return (
    <StyledCassetteUser onClick={() => onClickCassetteUser()}>
      <div>
        <CircleAvatar name="user_avatar" size="md" src={profileImage ? profileImage.url : null} />
      </div>
      <div>
        <StyledCassetteUserInfo>
          <div>
            <StyledCassetteUserName>
              <HighlightedText keyword={searchWord}>{nickname}</HighlightedText>
            </StyledCassetteUserName>
            <StyledCassetteUserSubInfo>
              {location ? (
                <div>
                  <StyledLocationText>
                    <img
                      src={iconPathResumeUserMap}
                      width="17"
                      alt="map"
                      style={{ verticalAlign: '-4px', marginRight: '5px' }}
                    />
                    {location}
                  </StyledLocationText>
                </div>
              ) : null}
              {job ? (
                <div style={{ marginLeft: '10px', fontWeight: 'bold' }}>{data.job || ''}</div>
              ) : null}
            </StyledCassetteUserSubInfo>
          </div>
          <StyledCassetteButtons>
            <StyledPrimaryMiddleButton
              following={isUserFollowed}
              onClick={onClickFollow(id, nickname, isUserFollowed)}
            >
              <StyledIcon
                width="20px"
                imgName={isUserFollowed ? 'followers_gray' : 'followers_white'}
              />
              {isUserFollowed ? 'フォロー中' : 'フォローする'}
            </StyledPrimaryMiddleButton>
            {/* MEMO(パク) : メッセージ機能ができるようになったら、コメントしたメッセージボタン使用する */}
            {/* {isLoginUserCompany ? (
              <StyledSecondaryMiddleButton onClick={onClickChat(id, nickname)}>
                <StyledIcon width="15px" imgName="message_green" />
                メッセージ
              </StyledSecondaryMiddleButton>
            ) : null} */}
          </StyledCassetteButtons>
        </StyledCassetteUserInfo>
        {bio ? (
          <StyledCassetteUserDescription>
            <HighlightedText keyword={searchWord}>{bio}</HighlightedText>
          </StyledCassetteUserDescription>
        ) : null}
      </div>
    </StyledCassetteUser>
  );
};

export default CassetteUser;

const StyledCassetteUser = styled.div`
  display: flex;
  padding: 30px;
  text-decoration: none;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 130px;
    padding-right: 30px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 130px);
  }
`;

const StyledCassetteUserInfo = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledCassetteUserDescription = styled.p`
  font-size: 14px;
  color: #333333;
  line-height: 1.75;
  word-break: break-word;
  margin-top: 10px;
`;

const StyledCassetteUserName = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
  word-break: break-word;
  line-height: 1.2;
`;

const StyledCassetteUserSubInfo = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 8px;

  & img {
    vertical-align: middle;
  }
`;

const StyledCassetteButtons = styled.div`
  display: flex;
  & > * + * {
    margin-left: 10px;
  }
`;

const StyledPrimaryMiddleButton = styled.button`
  && {
    min-width: 145px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 16px;
    box-sizing: border-box;
    outline: none;
    height: 40px;
    line-height: 1;
    font-size: 14px;
    color: #ffffff;
    background-color: #0fafa9;
    border: 1px solid #0fafa9;
    border-radius: 5px;
    transition: background 0.15s, border 0.15s;
    &:hover {
      background-color: #008681;
      border-color: #008681;
    }
    ${(props) =>
      props.following
        ? css`
            background-color: #ffffff;
            color: #cccccc;
            border: 1px solid #cccccc;
            &:hover {
              background-color: #f7f7f7;
              border-color: #cccccc;
            }
          `
        : null}
  }
`;

// MEMO(パク) : メッセージ機能ができるようになったら、コメントしたメッセージボタン使用する
// const StyledSecondaryMiddleButton = styled.button`
//   display: flex;
//   align-items: center;
//   cursor: pointer;
//   padding: 0 16px;
//   box-sizing: border-box;
//   outline: none;
//   height: 40px;
//   line-height: 1;
//   font-size: 14px;
//   color: #0fafa9;
//   background-color: #ffffff;
//   border: 1px solid #0fafa9;
//   border-radius: 5px;
//   transition: opacity 0.15s;
//   &:hover {
//     opacity: 0.7;
//   }
// `;

const StyledLocationText = styled.p`
  color: #333333;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;
