import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import auth, { authError, useLoginUser } from '@meettry/ui-components/utils/auth';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import FormItem from '@meettry/engineer/components/molecules/Form/FormItem';
import TextField from '@meettry/engineer/components/atoms/Input/TextField';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import { VALIDATION } from '@meettry/engineer/utils/Constant';
import { isValidatePassword } from '@meettry/engineer/utils';

const ChangePasswordForm = () => {
  const history = useHistory();
  const {
    user: { nickname },
    isEnterprise
  } = useLoginUser();
  const { showErrorDialog, showNotificationDialog, closeAllDialog } = useDialog();
  const [passwordModel, setPasswordModel] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  });
  const [passwordError, setPasswordError] = useState('');
  const [newPasswordError, setNewPasswordError] = useState('');
  const [isCheckCurrentPassword, setIsCheckCurrentPassword] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChangePassword = (name) => (e) => {
    setPasswordModel({ ...passwordModel, [name]: e.target.value });
    if (name === 'newPassword' || name === 'currentPassword') {
      setPasswordError('');
      return;
    }
    if (name === 'newPasswordConfirm') {
      setNewPasswordError('');
      return;
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const { newPassword, newPasswordConfirm } = passwordModel;

    if (isValidateForm(newPassword, newPasswordConfirm)) {
      return;
    }

    if (newPassword !== newPasswordConfirm) {
      showErrorDialog(['入力されたパスワードが一致しません。再度入力してください。'], () => {
        closeAllDialog();
      });
      setLoading(false);
      return;
    }
    auth
      .updatePassword(passwordModel.newPassword)
      .then(() => {
        showNotificationDialog(['パスワードを変更しました。'], () => {
          history.push(isEnterprise ? `/enterprise/scout` : `user/${nickname}`);
          closeAllDialog();
        });
        setLoading(false);
      })
      .catch((err) => {
        const errorMsg = authError(err);
        showErrorDialog([errorMsg], () => {
          closeAllDialog();
        });
        setLoading(false);
      });
  };

  const handleSubmitCurrentPassword = (e) => {
    e.preventDefault();
    setLoading(true);

    if (passwordModel.currentPassword === '') {
      setPasswordError(VALIDATION.PASSWORD.EMPTY);
      setLoading(false);
      return;
    }

    reauthenticate(passwordModel.currentPassword)
      .then(() => {
        setIsCheckCurrentPassword(true);
        setLoading(false);
      })
      .catch((err) => {
        const errorMsg = authError(err);
        showErrorDialog([errorMsg], () => {
          closeAllDialog();
        });
        setLoading(false);
      });
  };

  const isValidateForm = (newPassword, newPasswordConfirm) => {
    let isValidate = false;

    if (newPassword === '') {
      setPasswordError(VALIDATION.PASSWORD.EMPTY);
      isValidate = true;
    }

    if (newPasswordConfirm === '') {
      setNewPasswordError(VALIDATION.PASSWORD.EMPTY);
      isValidate = true;
    }

    if (newPassword !== '' && isValidatePassword(newPassword)) {
      setPasswordError(VALIDATION.PASSWORD.INCORRECT);
      isValidate = true;
    }

    if (newPasswordConfirm !== '' && isValidatePassword(newPasswordConfirm)) {
      setNewPasswordError(VALIDATION.PASSWORD.INCORRECT);
      isValidate = true;
    }

    setLoading(false);
    return isValidate;
  };

  const reauthenticate = (currentPassword) => {
    return auth.reauthenticate(currentPassword);
  };

  return (
    <StyledForm onSubmit={isCheckCurrentPassword ? handleSubmit : handleSubmitCurrentPassword}>
      <StyledFormHeader>
        <StyledFormTitle>パスワード変更</StyledFormTitle>
      </StyledFormHeader>
      <StyledFormBody>
        <StyledList>
          {!isCheckCurrentPassword ? (
            <FormItem
              label={'現在のパスワード'}
              error={passwordError.length > 0}
              errorText={passwordError}
            >
              <TextField
                type={'password'}
                onChange={handleChangePassword('currentPassword')}
                placeholder={'現在のパスワードを入力'}
                defaultValue={passwordModel.currentPassword}
                error={passwordError.length > 0}
              />
            </FormItem>
          ) : (
            <>
              <StyledListItem>
                <FormItem
                  label={'新しいパスワード'}
                  error={passwordError.length > 0}
                  errorText={passwordError}
                >
                  <TextField
                    type={'password'}
                    onChange={handleChangePassword('newPassword')}
                    placeholder={'新しいパスワードを入力'}
                    defaultValue={passwordModel.newPassword}
                    error={passwordError.length > 0}
                  />
                </FormItem>
              </StyledListItem>
              <StyledListItem>
                <FormItem
                  label={'パスワードの確認'}
                  error={newPasswordError.length > 0}
                  errorText={newPasswordError}
                >
                  <TextField
                    type={'password'}
                    onChange={handleChangePassword('newPasswordConfirm')}
                    placeholder={'新しいパスワードを再度入力'}
                    defaultValue={passwordModel.newPasswordConfirm}
                    error={newPasswordError.length > 0}
                  />
                </FormItem>
              </StyledListItem>
              <StyledSupplement>
                パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、
                <br />
                記号の全てを含んだものを指定してください。
              </StyledSupplement>
            </>
          )}
        </StyledList>
      </StyledFormBody>
      <StyledButtonWrapper>
        <StyledSubmitButton size="small" type="submit" disabled={loading}>
          {isCheckCurrentPassword ? '変更する' : '次へ'}
        </StyledSubmitButton>
      </StyledButtonWrapper>
    </StyledForm>
  );
};
export default ChangePasswordForm;

const StyledForm = styled.form`
  padding: 40px 50px;
  margin-bottom: 120px;
  width: 100%;
  max-width: 800px;
  background-color: #ffffff;
`;

const StyledFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledFormTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  color: #0fafa9;
`;

const StyledFormBody = styled.div`
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
`;

const StyledList = styled.ul`
  & > li:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
`;

const StyledSubmitButton = styled(PrimaryButton)`
  max-width: 100px;
  min-height: 40px;
`;

const StyledSupplement = styled.div`
  padding-left: 170px;
  margin-top: 30px;
  font-size: 14px;
`;
