import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { JobTag, ExperienceTag } from '@meettry/company/component/atoms/tags';
import avatarImage from '@meettry/company/images/avatar.png';
import H from 'history';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { LineTag, GrayTag } from '@meettry/ui-components/components/atoms/Tag';

const jobList: Array<string> = ['20人開発体制', '10万/秒トラフィック'];
const experienceList: Array<{ lang: string; year: number }> = [
  { lang: 'Python2.x', year: 5.5 },
  { lang: 'C#', year: 5 }
];

const linkToUserPage = (history: H.History) => {
  history.push('/user_name/dashboard');
  window.scrollTo(0, 0);
};

export const Recommends: React.FC = () => {
  const history = useHistory();
  return (
    <StyledRecommendsUl>
      {Array.from({ length: 4 }, (_, i) => (
        <StyledRecommendsLi key={i} onClick={() => linkToUserPage(history)}>
          <StyledRecommendsIcon src={avatarImage} alt="エンジニアのアイコン" />
          <StyledRecommendsUserName>yoppei_lei</StyledRecommendsUserName>
          <StyledRecommendsTechStacks>
            {experienceList.map((item, index) => (
              <StyledGrayTag size="S" key={index}>
                <span>{item.lang}</span>
                <StyledTagYear>{item.year}</StyledTagYear>
              </StyledGrayTag>
            ))}
          </StyledRecommendsTechStacks>
          <StyledRecommendsTag>
            {jobList.map((item, index) => (
              <StyledLineTagEx size="S" key={index}>
                {item}
              </StyledLineTagEx>
            ))}
          </StyledRecommendsTag>
        </StyledRecommendsLi>
      ))}
    </StyledRecommendsUl>
  );
};

const StyledRecommendsUl = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-top: 22px;
`;

const StyledRecommendsLi = styled.li`
  box-sizing: border-box;
  width: calc(100% / 4 - 3px);
  padding: 20px 16px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  text-align: center;
`;

const StyledRecommendsIcon = styled.img`
  display: block;
  width: 80px;
  margin: 0 auto;
`;

const StyledRecommendsUserName = styled.span`
  display: inline-block;
  margin-top: 12px;
  font-size: 16px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
`;

const StyledRecommendsTag = styled.div`
  text-align: left;
`;
const StyledRecommendsTechStacks = styled(StyledRecommendsTag)`
  margin-top: 16px;
`;

const StyledTagYear = styled.span`
  margin-left: 4px;
`;

const StyledGrayTag = styled(GrayTag)`
  display: inline-block;
  margin-top: 4px;
  & + span {
    margin-left: 4px;
  }
`;

const StyledLineTag = StyledGrayTag.withComponent(LineTag);
const StyledLineTagEx = styled(StyledLineTag)`
  & + span {
    margin-left: 0;
  }
`;
