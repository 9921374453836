import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';

type TabType = {
  text: string;
  type: string;
  path?: string;
};

type ChangeTabType = {
  setCurrent?: Function;
  tab: Array<TabType>;
  active?: string;
  isBottom?: boolean;
};
export const ChangeTab: React.FC<ChangeTabType> = (props) => {
  const { setCurrent, tab, active = '', isBottom = false } = props;
  return (
    <StyledChangeTabs>
      {tab.map((item, index) =>
        item.path ? (
          <StyledChangeTab
            to={item.path}
            key={index}
            active={active.indexOf(item['path']) > -1 ? 'true' : ''}
            tablength={tab.length}
          >
            {item.text}
          </StyledChangeTab>
        ) : (
          <StyledChangeTabSpan
            key={index}
            onClick={() => {
              if (setCurrent && active !== item.type) setCurrent(item.type);
            }}
            active={active.indexOf(item['type']) > -1 ? 'true' : ''}
            isBottom={isBottom}
            tablength={tab.length}
          >
            {item.text}
          </StyledChangeTabSpan>
        )
      )}
    </StyledChangeTabs>
  );
};

const StyledChangeTabs = styled.div`
  display: flex;
  max-width: 900px;
  width: 100%;
  margin: 37px auto 0;
  text-align: center;
`;

const StyledChangeTab = styled<any & { active: string; tablength: number }>(Link)`
  display: block;
  width: ${({ tablength }) => `calc(100% / ${!!tablength ? tablength : 3})`};
  padding: 20px 0;
  font-size: 18px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  line-height: 1;
  cursor: pointer;

  ${({ active }) =>
    active === 'true'
      ? `
      	border-top: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
		    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    `
      : ''}
`;

const StyledChangeTabSpan = styled.span<{ active: string; tablength: number; isBottom?: boolean }>`
  display: block;
  width: ${({ tablength }) => `calc(100% / ${tablength})`};
  padding: 20px 0;
  font-size: 18px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  line-height: 1;
  cursor: pointer;

  ${({ isBottom, active }) =>
    isBottom && active === 'true'
      ? `
      	border-bottom: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
		    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    `
      : isBottom && active === 'true'
      ? `
      	border-top: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
		    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    `
      : null}
`;
