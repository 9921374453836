import React from 'react';

import TopMainVisual from '@meettry/engineer/components/organisms/Tops/TopMainVisual';
import TopCompany from '@meettry/ui-components/components/organisms/Tops/TopCompany';
import TopFeature from '@meettry/ui-components/components/organisms/Tops/TopFeature';
import TopFlow from '@meettry/ui-components/components/organisms/Tops/TopFlow';
import TopAbout from '@meettry/ui-components/components/organisms/Tops/TopAbout';
import TopMedia from '@meettry/ui-components/components/organisms/Tops/TopMedia';
import TopRegister from '@meettry/ui-components/components/organisms/Tops/TopRegister';

import Features1 from '@meettry/engineer/images/features-01.png';
import Features2 from '@meettry/engineer/images/features-02.png';
import Features3 from '@meettry/engineer/images/features-03.png';

import Flow1 from '@meettry/engineer/images/flow-01.png';
import Flow2 from '@meettry/engineer/images/flow-02.png';
import Flow3 from '@meettry/engineer/images/flow-03.png';

const featuresList = [
  {
    img: Features1,
    title: ['レジュメを魅力的に', ''],
    content: '自身の技術スタックがバブルチャートで可視化。自分の強みがよりダイレクトに伝わる',
    note: ''
  },
  {
    img: Features2,
    title: ['エンジニア同士で', '自由にチャット'],
    content: '技術スタックで、ユーザー検索。先輩エンジニアに気軽にチャットで相談',
    note: '※現在開発中'
  },
  {
    img: Features3,
    title: ['企業とのチャットで', 'リワード獲得'],
    content: 'エンジニアを求める企業とのチャットへの回答で様々なことに利用できるリワードを獲得',
    note: '※現在開発中'
  }
];

const flowList = [
  {
    img: Flow1,
    msg: 'ニックネームや、職歴を登録するとレジュメが完成'
  },
  {
    img: Flow2,
    msg:
      '企業チャットを受け取るために、プロフィールを登録して、企業とのチャットを受け取り、コミュニケーション開始'
  },
  {
    img: Flow3,
    msg: '何か技術で困ったら、技術スタックを検索して先輩エンジニアを見つけて、相談してみよう！'
  }
];

const registerMessages = ['まずは気軽に登録！', 'たくさんのスカウトがあなたを待っています！'];

const Top = () => (
  <>
    <TopMainVisual />
    {/* <TopCompany /> */}
    <TopFeature featuresList={featuresList} />
    <TopFlow flowList={flowList} />
    <TopAbout />
    {/* <TopMedia bg="transparent" /> */}
    <TopRegister messages={registerMessages} btnMessage="ユーザー登録はこちら" />
  </>
);

export default Top;
