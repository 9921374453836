import React from 'react';
import styled, { css } from 'styled-components';
import { BUTTON } from '@meettry/engineer/utils/Constant';

export const Button = (props) => {
  const {
    children,
    className,
    onClick,
    theme,
    size = 'medium',
    disabled,
    type = 'button',
    ...restProps
  } = props;
  const onClickHandle = (e) => {
    if (type !== 'submit') e.preventDefault();
    e.stopPropagation();
    typeof onClick === 'function' && onClick();
  };

  return (
    <StyledButton
      {...restProps}
      className={className}
      onClick={onClickHandle}
      disabled={disabled}
      type={type}
      size={size}
      theme={theme}
    >
      {children}
    </StyledButton>
  );
};

export const PrimaryButton = (props) => <Button {...props} theme={BUTTON.THEME.PRIMARY} />;
export const SecondaryButton = (props) => <Button {...props} theme={BUTTON.THEME.SECONDARY} />;
export const TertiaryButton = (props) => <Button {...props} theme={BUTTON.THEME.TERTIARY} />;

// TODO(aida) カラーコードを定数化する
const StyledButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  border-radius: 5px;
  transition: all 0.4s ease;
  line-height: 1;
  ${({ theme }) => {
    switch (theme) {
      case BUTTON.THEME.PRIMARY:
        return css`
          color: #ffffff;
          background-color: #0fafa9;
          &:hover {
            background-color: #008681;
          }
        `;
      case BUTTON.THEME.SECONDARY:
        return css`
          color: #0fafa9;
          background-color: #ffffff;
          border: 1px solid #0fafa9;
          &:hover {
            background-color: #e6f7f6;
          }
        `;
      case BUTTON.THEME.TERTIARY:
        return css`
          color: #333333;
          background-color: #ffffff;
          border: 1px solid #cccccc;
          &:hover {
            background-color: #eeeeee;
          }
        `;
      default:
        return null;
    }
  }}
  ${({ size }) => {
    switch (size) {
      case BUTTON.SIZE.LARGE:
        return css`
          font-size: 18px;
          font-weight: bold;
          padding: 20px;
        `;
      case BUTTON.SIZE.MEDIUM:
        return css`
          font-size: 14px;
          padding: 12px;
        `;
      case BUTTON.SIZE.SMALL:
        return css`
          font-size: 14px;
          padding: 8px;
        `;
      default:
        return null;
    }
  }}
  ${({ disabled }) =>
    disabled
      ? css`
          cursor: default;
          color: #cccccc;
          background-color: #f7f7f7;
          border: 1px solid #dddddd;
          &:hover {
            color: #cccccc;
            background-color: #f7f7f7;
          }
        `
      : null}
`;
