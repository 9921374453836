import { useEffect, useState, useRef } from 'react';

//MEMO(aida) コンポーネントの要素外がクリックされた際の表示状態を管理するhooks
export const useOutsideClickVisible = (initialVisibleState: boolean) => {
  const [isVisible, setIsVisible] = useState(initialVisibleState);
  const ref = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (e: any) => {
    if (ref.current && !ref.current.contains(e.target)) {
      setIsVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => document.removeEventListener('click', handleClickOutside, true);
  }, []);

  return {
    ref, // 観測対象に設定するref。これが設定された要素の外側がクリックされたかをチェックする
    isVisible, // 表示状態
    setIsVisible // 表示状態の切り替え手段を提供する
  };
};

export default useOutsideClickVisible;
