import constants from '@meettry/ui-components/constants';
export * from '@meettry/ui-components/utils/datetime';
export * from '@meettry/ui-components/utils/number';
export * from '@meettry/ui-components/utils/animation';
import { useLoginUser } from '@meettry/ui-components/utils/auth';

export const getTagCategory = (categoryName: string) =>
  Object.keys(constants.TAG_CATEGORIES).find((key) => key === categoryName.toUpperCase());

export function getBreadcrumbsPath(
  selectType: string,
  nickname: string,
  myNickname: string | null,
  isEnterprise: boolean
) {
  switch (selectType) {
    case 'search':
      return `/search`;
    case nickname:
      return `/user/${nickname}`;
    case 'resume':
    case 'challenge':
    case 'profile':
    case 'chat':
    case 'followers':
    case 'following':
      return `/user/${nickname}/${selectType}`;
    default:
      return isEnterprise ? '/enterprise/scout' : `/user/${myNickname}`;
  }
}

export function getTextJP(selectType: string, nickname: string) {
  switch (selectType) {
    case nickname:
      return nickname;
    case 'resume':
      return '経歴';
    case 'challenge':
      return 'チャレンジ';
    case 'profile':
      return 'プロフィール編集';
    case 'chat':
      return 'チャット';
    case 'followers':
      return 'フォロワー一覧';
    case 'following':
      return 'フォロー一覧';
    case 'search':
      const searchParams = new URLSearchParams(window.location.search);
      const queryString = searchParams.get('q') || '';
      return queryString;
    default:
      return 'TOP';
  }
}

export const getPathArray = (url: string, nickname: string, myNickname: string | null) => {
  const pathSplit = url.split('/');
  // search画面以外
  const items = pathSplit.length !== 2 ? pathSplit.slice(1, pathSplit.length) : pathSplit;

  const { isEnterprise } = useLoginUser();

  const formattedItems =
    nickname === myNickname
      ? items
          .filter((item) => item !== myNickname)
          .map((value) => ({
            text: getTextJP(value, nickname),
            path: getBreadcrumbsPath(value, nickname, myNickname, isEnterprise)
          }))
      : items.map((value) => ({
          text: getTextJP(value, nickname),
          path: getBreadcrumbsPath(value, nickname, myNickname, isEnterprise)
        }));

  return formattedItems;
};
