import { formatDateToGQLDateType } from '@meettry/engineer/utils';
import { ResumeType } from '@meettry/ui-components/types/userPage';

export const makeHandleSubmit = (input: ResumeType, mutation: Function) => () => {
  const inputData = { ...input };
  if (!!inputData.__typename) delete inputData.__typename;
  delete inputData.inputTag;
  delete inputData.created;
  inputData.tags.map((item) => {
    delete item.__typename;
    delete item.techStackId;
    delete item.relations;
  });
  //MEMO(aida) workStartDateをgraphqlのDate型の形式に変換する。
  if (typeof inputData.workStartDate === 'string') {
    inputData.workStartDate = formatDateToGQLDateType(new Date(inputData.workStartDate));
  }
  if (inputData.workStartDate) {
    inputData.workStartDate = formatDateToGQLDateType(inputData.workStartDate);
  }
  //MEMO(aida) workEndDateをgraphqlのDate型の形式に変換する。
  if (typeof inputData.workEndDate === 'string') {
    inputData.workEndDate = formatDateToGQLDateType(new Date(inputData.workEndDate));
  }
  if (inputData.workEndDate) {
    inputData.workEndDate = formatDateToGQLDateType(inputData.workEndDate);
  }
  // console.log('level 3 last');
  // console.log(inputData);
  mutation({ variables: { input: inputData } });
};
