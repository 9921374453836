import { useEffect, useState } from 'react';

const predicateEqualDate = (a: any, b: any) => a - b !== 0;

/**
 * useChatCursor
 */
const useChatCursor = (serverCursor: any, initialPredicate: any): [
  any,
  (value: any) => any
] => {
  const [cursor, setCursor] = useState(null);

  const setter = (value: any, predicate: any) => {
    const notEqual = cursor !== value;
    const isEmpty = !cursor;
    const isValid = cursor && value && predicate(cursor, value);

    if (notEqual && (isEmpty || isValid)) {
      setCursor(value);
    }
  };

  useEffect(() => {
    setter(serverCursor, initialPredicate);
  }, [serverCursor]);

  return [cursor, (value: any) => setter(value, predicateEqualDate)];
};

export default useChatCursor;
