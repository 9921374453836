import React, { createContext, Dispatch, useReducer } from 'react';
import {
  TagType,
  UserTagsType,
  EngineerType,
  ResumeType,
  ChallengeType
} from '@meettry/ui-components/types/userPage';
import { ProviderComponent } from '@meettry/ui-components/types/context';

// action constants
// MEMO(aida) エンジニアを設定するアクション
export const SET_ENGINEER = 'SET_ENGINEER';
// MEMO(aida)　経歴リストを設定するアクション
export const SET_ENGINEER_RESUME_LIST = 'SET_ENGINEER_RESUME_LIST';
// MEMO(aida)　チャレンジリストを設定するアクション
export const SET_ENGINEER_CHALLENGE_LIST = 'SET_ENGINEER_CHALLENGE_LIST';
//MEMO(aida) ユーザのタグを更新するアクション
export const SET_ENGINEER_USER_TAGS = 'UPDATE_ENGINEER_USER_TAGS';
//MEMO(aida) 選択中の技術スタックサマリタグを更新するアクション
export const SET_SELECTED_TECH_STACK_SUMMARY_TAGS = 'SET_SELECTED_TECH_STACK_SUMMARY_TAGS';
//MEMO(aida) 選択中のタグを更新するアクション
export const SET_SELECTED_OTHER_TAGS = 'SET_SELECTED_OTHER_TAGS';
//MEMO(aida) 存在しているtagListを更新するアクション
export const SET_TAG_LIST = 'SET_TAG_LIST';

// actions
export const setEngineer = (engineer: EngineerType) => ({
  type: SET_ENGINEER,
  payload: {
    ...engineer
  }
});

export const setResumeList = (resumeList: Array<ResumeType>) => ({
  type: SET_ENGINEER_RESUME_LIST,
  payload: {
    resumeList
  }
});

export const setChallengeList = (challengeList: Array<ChallengeType>) => ({
  type: SET_ENGINEER_CHALLENGE_LIST,
  payload: {
    challengeList
  }
});

export const setUserTags = (userTags: Array<UserTagsType>) => ({
  type: SET_ENGINEER_USER_TAGS,
  payload: {
    userTags
  }
});

export const setSelectedTechStackSummaryTags = (techStackSummaryTags: Array<string>) => ({
  type: SET_SELECTED_TECH_STACK_SUMMARY_TAGS,
  payload: {
    techStackSummaryTags
  }
});

export const setSelectedOtherTags = (otherTags: Array<string>) => ({
  type: SET_SELECTED_OTHER_TAGS,
  payload: {
    otherTags
  }
});

export const setTagList = (tagList: Array<TagType>) => ({
  type: SET_TAG_LIST,
  payload: {
    tagList
  }
});

// initial state
// TODO(aida) ステートで保持する値は見直す.プロパティ毎にコメント残す。
const initState = () => ({
  id: null, // ユーザのid
  nickname: null, // ユーザのニックネーム
  totalView: 0, // ユーザのビュー数
  bio: null, // ユーザのプロフィール詳細
  location: null, // ユーザの活動拠点
  url: null, // ユーザのurl
  scouteeRating: null,
  profileImage: {
    url: null,
    updated: null
  }, // ユーザのプロフィール画像 null or profileImage.url
  challengeList: { count: null, items: [] }, // ユーザのチャレンジリスト
  resumeList: { count: null, items: [] }, // ユーザの経歴リスト
  activities: [], // ユーザの更新履歴
  userTags: [], // ユーザのタグ
  followers: [], // ユーザのフォロワー
  followees: [], // ユーザのフォロー中
  points: null, //ユーザーが獲得したPoint
  scoutCount: 0, // スカウトチャットを受け取った回数
  workStyles: [], // 希望の働き方
  //MEMO(aida) タグのマスタデータ
  tagList: [],
  //MEMO(aida) タグでフィルター用
  selectedTechStackSummaryTags: [], //選択中の技術スタックサマリタグ,displayNameのみが格納される。
  selectedOtherTags: [], //選択中のタグ,選択中の技術スタックサマリタグ,displayNameのみが格納される。
  lastUpdated: '',
  userDetail: {
    jobSeekingLevel: 0,
    enableAcceptingScout: 0
  }
});

export const initialState = initState();

const reducer = (state = initialState, action: { type: string; payload: any }) => {
  switch (action.type) {
    case SET_ENGINEER:
      const { id } = action.payload;
      if (!id) return state;
      return { ...state, ...action.payload };
    case SET_ENGINEER_USER_TAGS:
      const { userTags } = action.payload;
      return { ...state, userTags };
    case SET_ENGINEER_RESUME_LIST:
      const { resumeList } = action.payload;
      return { ...state, resumeList: { count: resumeList.length, items: resumeList } };
    case SET_ENGINEER_CHALLENGE_LIST:
      const { challengeList } = action.payload;
      return { ...state, challengeList: { count: challengeList.length, items: challengeList } };
    case SET_TAG_LIST:
      const { tagList } = action.payload;
      return { ...state, tagList };
    case SET_SELECTED_TECH_STACK_SUMMARY_TAGS:
      const { techStackSummaryTags } = action.payload;
      return { ...state, selectedTechStackSummaryTags: [...techStackSummaryTags] };
    case SET_SELECTED_OTHER_TAGS:
      const { otherTags } = action.payload;
      return { ...state, selectedOtherTags: [...otherTags] };
    default:
      return state;
  }
};

export default reducer;

type EngineerState = {
  tagList: Array<TagType>;
  selectedTechStackSummaryTags: Array<string>;
  selectedOtherTags: Array<string>;
  badges?: Array<{ name: string }>;
  socialLinks?: Array<{ [key: string]: string }>;
  organizations?: Array<{ [key: string]: string }>;
};

type Action<T, U> = { type: T; payload: U };

type EngineerOption = { [key: string]: any };

type EngineerAction = Action<string, EngineerOption>;

type EngineerDispatch = Dispatch<EngineerAction>;

type EngineerStore = {
  state: EngineerType & EngineerState;
  dispatch: EngineerDispatch;
};

const defaultStore: EngineerStore = {
  state: initialState,
  dispatch: () => initialState
};

export const EngineerContext = createContext<EngineerStore>(defaultStore);

export const EngineerProvider: ProviderComponent = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  return (
    <EngineerContext.Provider value={{ state, dispatch }}>{children}</EngineerContext.Provider>
  );
};
