import { useState, useCallback, useEffect } from 'react';

import fb from '@meettry/ui-components/utils/auth';
import { ChatMessage, ChatMessageFieldType } from '@meettry/ui-components/types/chat';
import { MESSAGE_LOAD_LIMIT } from '@meettry/ui-components/utils/Constant';

const buildQuery = (roomId: string, cursor: any, isInitialLoad: boolean) => {
  if (!roomId || !cursor) {
    return null;
  }

  const query = fb.db
    .collection(`/rooms/${roomId}/messages`)
    .orderBy('createdAt', 'desc')
    .limit(MESSAGE_LOAD_LIMIT);

  // 初期ロード時はカーソル位置にあるメッセージも取得しなければならない
  if (isInitialLoad) {
    return query.startAt(cursor);
  }

  return query.startAfter(cursor);
};

/**
 * convertChatMessage
 * firebaseの型から、JavaScriptで扱える型に変換
 */
const convertChatMessage = (
  chatMessageFieldValue: ChatMessageFieldType
): Omit<ChatMessage, 'id'> => ({
  ...chatMessageFieldValue,
  createdAt: chatMessageFieldValue.createdAt.toDate()
});

/**
 * useMessagesBackward
 * より古いチャットメッセージを取得する
 */
const useMessagesBackward = (
  roomId: string,
  serverCursor: Date | null,
  lastMessages: any | null = null,
  prependMessages: any
) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);

  const getPrev = useCallback(() => {
    if (!loading) {
      setError(null);

      let cursor = serverCursor;
      if (lastMessages !== null) {
        if (lastMessages.length > 0) {
          cursor = lastMessages[0].createdAt;
        } else if (lastMessages.length === 0) {
          return;
        }
      }

      const query = buildQuery(roomId, cursor, lastMessages === null);

      if (query && !loading) {
        setLoading(true);

        query
          .get()
          .then((snapshot) => {
            if (!snapshot.empty) {
              const values: any[] = [];
              snapshot.forEach((doc) =>
                values.push({
                  id: doc.id,
                  ...convertChatMessage(<ChatMessageFieldType>doc.data())
                })
              );

              // desc で取得しているので反転させる
              values.reverse();
              prependMessages(values);
            } else {
              prependMessages([]);
            }
          })
          .catch((e) => {
            setError(e);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  }, [lastMessages, loading, setLoading, serverCursor, roomId]);

  useEffect(() => {
    if (roomId && serverCursor && lastMessages === null) {
      getPrev();
    }
  }, [lastMessages, getPrev]);

  return { getPrev, loading, error };
};

export default useMessagesBackward;
