import React, { useMemo } from 'react';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import ApplicationPage from '@meettry/engineer/components/templates/ApplicationPage';
import ChangeEmailForm from '@meettry/engineer/components/organisms/ChangeEmailForm';

const ChangeEmailPage = () => {
  const {
    user: { nickname }
  } = useLoginUser();
  // ぱんくずリスト
  const breadcrumbs = useMemo(
    () => [
      { path: `/user/${nickname}`, text: 'TOP' },
      { path: '', text: 'メールアドレス変更' }
    ],
    [nickname]
  );
  return (
    <ApplicationPage pageType="slim" breadcrumbs={breadcrumbs}>
      <ChangeEmailForm />
    </ApplicationPage>
  );
};
export default ChangeEmailPage;
