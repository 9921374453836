import React, { useState } from 'react';
import { LangTag } from '@meettry/company/component/atoms/tags';

type ActivityTimeLineItemProps = {
  listItem: {
    history: string;
    resume: {
      title: string;
      commits: string;
      langTag: Array<string>;
    }[];
  }[];
};

const ActivityTimeLineItem: React.FC<ActivityTimeLineItemProps> = (props) => {
  const { listItem } = props;
  const [seeMore, setSeeMore] = useState(true);
  return (
    <div className="user-timeline-item">
      <div className="user-timeline-container">
        <div className="user-timeline-left">
          <span className="current-time">May 2019</span>
          <ul className="timeline-log">
            {listItem.map((item, i) => {
              return (
                <li key={i}>
                  <span className="history">{item.history}</span>
                  {item.resume.map((i, j) => {
                    return (
                      <div key={j}>
                        <span className="title">{i.title}</span>
                        {i.commits ? <span className="commits">{i.commits}commits</span> : null}
                        <div className="lang-tags">
                          {i.langTag
                            ? i.langTag.map((lang, k) => {
                                return <LangTag item={lang} key={k} />;
                              })
                            : null}
                        </div>
                      </div>
                    );
                  })}
                </li>
              );
            })}
          </ul>
        </div>
        <ul className="user-timeline-right">
          <li className="active">2021</li>
        </ul>
      </div>
      <span
        className={seeMore ? 'see-more' : 'see-more close'}
        onClick={() => {
          setSeeMore(!seeMore);
        }}
      >
        {seeMore ? 'もっと見る' : '閉じる'}
      </span>
    </div>
  );
};

export default ActivityTimeLineItem;
