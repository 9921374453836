import React from 'react';
import Button from '@meettry/ui-components/components/atoms/Button/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

export type ButtonType = {
  onClick?: any;
  width?: string;
  size?: string;
  // styleType?: 'primaryRound' | 'line' | 'gray' | 'white';
  styleType?:
    | typeof STYLE_TYPE.ROLE.ROUND
    | typeof STYLE_TYPE.ROLE.SECONDARY
    | typeof STYLE_TYPE.ROLE.THIRDLY
    | typeof STYLE_TYPE.ROLE.GRAY
    | typeof STYLE_TYPE.ROLE.WHITE;
  children?: React.ReactNode;
  disabled?: boolean;
  icon?: string;
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
  addStyle?: React.CSSProperties;
  type?: 'submit' | 'reset' | 'button';
};

const buttonGenerator = (options: ButtonType) => {
  const { styleType } = options;
  return (props: ButtonType) => {
    return <Button {...props} styleType={!!styleType ? styleType : props.styleType} />;
  };
};

export const PrimaryButton = buttonGenerator({});
export const PrimaryRoundButton = buttonGenerator({ styleType: STYLE_TYPE.ROLE.ROUND });
export const LineButton = buttonGenerator({ styleType: STYLE_TYPE.ROLE.SECONDARY });
export const MonochromeButton = buttonGenerator({ styleType: STYLE_TYPE.ROLE.THIRDLY });
export const GrayButton = buttonGenerator({ styleType: STYLE_TYPE.ROLE.GRAY });
export const WhiteButton = buttonGenerator({ styleType: STYLE_TYPE.ROLE.WHITE });
