export const SIGN = {
  EMAIL_ALREADY_USE: 'このメールアドレスは使用されています',
  EMAIL_UNAVAILABLE: 'このメールアドレスを使用することはできません。\n変更する場合は他のメールアドレスをご入力ください。',
  WRONG_EMAIL_OR_PASSWORD: 'メールアドレスまたはパスワードが違います',
  INVALID_EMAIL: 'メールアドレスの形式が正しくありません',
  USER_DISABLED: 'サービスの利用が停止されています',
  USER_MISMATCH: '認証されているユーザーと異なるアカウントが選択されました',
  WEAK_PASSWORD:
    'パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、記号の全てを含んだものを指定してください',
  POPUP_BLOCKED:
    '認証ポップアップがブロックされました。\nポップアップブロックをご利用の場合は設定を解除してください',
  TOO_MANY_REQUESTS: '同じ要請を繰り返しました。もう一度お試しください。',
  UNAVAILABLE_AUTHORIZED: '現在この認証方法はご利用頂けません',
  REQUIRES_RECENT_LOGIN: '認証の有効期限が切れています',
  FAULT_AUTHORIZED: '認証に失敗しました。\nしばらく時間をおいて再度お試しください',
  OCCUR_ERROR: 'エラーが発生しました。\nしばらく時間をおいてお試しください',
  NO_ORG_USER: 'こちらの画面からログインしてください。'
};

export const SIGNUP = {
  EMPTY: 'パスワードを入力してください'
};

export const LOGIN = {
  EMPTY: 'この項目は必須入力項目です'
};

export const REGISTER = {
  EMPTY: 'この項目は必須入力項目です',
  INVALID_TEL: '電話番号の形式が正しくありません'
};

export const FORGOT_PASS = {
  EMPTY: 'メールアドレスを入力ください'
};

export const ACTION = {
  INVALID_CODE: 'この処理は無効です。もう一度初めからやり直してください。'
};
