import primary from '@meettry/ui-components/images/icons/primary';
import black from '@meettry/ui-components/images/icons/black';
import gray from '@meettry/ui-components/images/icons/gray';
import red from '@meettry/ui-components/images/icons/red';
import white from '@meettry/ui-components/images/icons/white';

export const primaryIcons = { ...primary };
export const blackIcons = { ...black };
export const grayIcons = { ...gray };
export const redIcons = { ...red };
export const whiteIcons = { ...white };

const icons = {
  primary: primaryIcons,
  gray: grayIcons,
  black: blackIcons,
  red: redIcons,
  white: whiteIcons
};

export default icons;
