import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SEARCH_TYPE, URL_SEARCH_PARAM_KEY } from '@meettry/ui-components/constants';

const DEFAULT_QUERY = '';
const DEFAULT_PAGE = 1;
const DEFAULT_USER_TYPE = SEARCH_TYPE.USER;
const DEFAULT_DISPLAY_NUMBER = 10;

export const SEARCH_SORT_TYPE = {
  UPDATED: 'updated',
  CREATED: 'created'
};

/**
 * 検索クエリーを制御するフック
 */
const useSearchQueryManager = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [query, setQuery] = useState({
    [URL_SEARCH_PARAM_KEY.SEARCH_QUERY]:
      searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_QUERY) ?? DEFAULT_QUERY,
    [URL_SEARCH_PARAM_KEY.SEARCH_TYPE]:
      searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_TYPE) ?? DEFAULT_USER_TYPE,
    [URL_SEARCH_PARAM_KEY.SEARCH_PAGE]: parseInt(
      searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_PAGE) ?? DEFAULT_PAGE.toString(),
      10
    ),
    [URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER]: parseInt(
      searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER) ??
        DEFAULT_DISPLAY_NUMBER.toString(),
      10
    )
  });
  const [sortType, setSortType] = useState(SEARCH_SORT_TYPE.UPDATED);

  /**
   * location.searchが更新されたら内容更新
   */
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setQuery({
      [URL_SEARCH_PARAM_KEY.SEARCH_QUERY]:
        searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_QUERY) ?? DEFAULT_QUERY,
      [URL_SEARCH_PARAM_KEY.SEARCH_TYPE]:
        searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_TYPE) ?? DEFAULT_USER_TYPE,
      [URL_SEARCH_PARAM_KEY.SEARCH_PAGE]: parseInt(
        searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_PAGE) ?? DEFAULT_PAGE.toString(),
        10
      ),
      [URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER]: parseInt(
        searchParams.get(URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER) ??
          DEFAULT_DISPLAY_NUMBER.toString(),
        10
      )
    });
  }, [location.search]);

  const changeQuery = (searchQueryType: string) => (value: string) => {
    if (query[searchQueryType] == null) return;
    switch (searchQueryType) {
      // type を切り替えるときはページナンバーもリセットする
      case URL_SEARCH_PARAM_KEY.SEARCH_TYPE: {
        setQuery({
          ...query,
          [URL_SEARCH_PARAM_KEY.SEARCH_TYPE]: value,
          [URL_SEARCH_PARAM_KEY.SEARCH_PAGE]: DEFAULT_PAGE
        });
        break;
      }
      default: {
        setQuery({
          ...query,
          [searchQueryType]: value
        });
      }
    }
  };

  const changeSortType = (type: string) => {
    setSortType(type);
  };

  /**
   * クエリー情報をリセットする
   */
  const resetQuery = (searchQueryType: string = '') => {
    switch (searchQueryType) {
      case URL_SEARCH_PARAM_KEY.SEARCH_QUERY:
        setQuery({
          ...query,
          [searchQueryType]: DEFAULT_QUERY
        });
        break;
      case URL_SEARCH_PARAM_KEY.SEARCH_TYPE:
        setQuery({
          ...query,
          [searchQueryType]: DEFAULT_USER_TYPE
        });
        break;
      case URL_SEARCH_PARAM_KEY.SEARCH_PAGE:
        setQuery({
          ...query,
          [searchQueryType]: DEFAULT_PAGE
        });
        break;
      case URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER:
        setQuery({
          ...query,
          [searchQueryType]: DEFAULT_DISPLAY_NUMBER
        });
        break;
      default:
        setQuery({
          [URL_SEARCH_PARAM_KEY.SEARCH_QUERY]: DEFAULT_QUERY,
          [URL_SEARCH_PARAM_KEY.SEARCH_TYPE]: DEFAULT_USER_TYPE,
          [URL_SEARCH_PARAM_KEY.SEARCH_PAGE]: DEFAULT_PAGE,
          [URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER]: DEFAULT_DISPLAY_NUMBER
        });
    }
  };

  /**
   * 検索クエリーつきのhistoryContentを作成
   */
  const createHistoryContentWithSearchQuery = (pathname: string, isReset = false): any => {
    if (
      query[URL_SEARCH_PARAM_KEY.SEARCH_QUERY] &&
      query[URL_SEARCH_PARAM_KEY.SEARCH_QUERY] !== ''
    ) {
      Object.keys(query).forEach((key) =>
        query[key] ? searchParams.set(key, String(query[key])) : searchParams.delete(key)
      );
      return { ...location, pathname, search: searchParams.toString() };
    }
  };

  return {
    queryString: query[URL_SEARCH_PARAM_KEY.SEARCH_QUERY],
    displayNumber: query[URL_SEARCH_PARAM_KEY.SEARCH_DISPLAY_NUMBER],
    page: query[URL_SEARCH_PARAM_KEY.SEARCH_PAGE],
    type: query[URL_SEARCH_PARAM_KEY.SEARCH_TYPE],
    sortType,
    resetQuery,
    changeQuery,
    changeSortType,
    createHistoryContentWithSearchQuery
  };
};
export default useSearchQueryManager;
