import React from 'react';
import {
  SkillTagArea,
  JobTagArea
} from '@meettry/company/component/organisms/userPage/SkillBox/SkillTagArea';

const SkillBox: React.FC = () => {
  return (
    <div className="user-skill-box-wrap">
      <div className="bubble-chart" />
      <div className="skill-tags-area">
        <SkillTagArea />
        <JobTagArea />
      </div>
    </div>
  );
};

export default SkillBox;
