import React from 'react';
import styled from 'styled-components';
import { useLocation, Switch, Route } from 'react-router-dom';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import Breadcrumbs from '@meettry/ui-components/components/atoms/Breadcrumbs';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';
import { Alert } from '@meettry/company/component/atoms/alert';
import { useCreateEnterpriseBreadcrumbs } from '@meettry/engineer/hooks/useBreadcrumbs';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const tabData = [
  // { text: 'ダッシュボード', type: 'Dashboard', path: '/enterprise/dashboard' },
  { text: 'スカウト', type: 'Scout', path: '/enterprise/scout' },
  { text: 'チャット', type: 'Chat', path: '/enterprise/chat' }
];

const EnterpriseBase: React.FC<PageCommonProps> = (props) => {
  const { path, childRoute } = props;
  const location = useLocation();
  const locationPath = location.pathname;
  const breadcrumbs = useCreateEnterpriseBreadcrumbs();

  return (
    <StyledMain>
      <Alert message={null} />
      <Breadcrumbs items={breadcrumbs} />
      <ChangeTab tab={tabData} active={locationPath} />
      <StyledContentWrap>
        <Switch>
          {!!childRoute &&
            childRoute.map((li, i) => (
              <Route key={i} path={path + li.path} exact={li.exact} component={li.component} />
            ))}
        </Switch>
      </StyledContentWrap>
    </StyledMain>
  );
};

export default EnterpriseBase;

const StyledMain = styled.main`
  padding-bottom: 120px;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  min-width: 1280px;
`;

const StyledContentWrap = styled.div`
  max-width: 900px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;
