import React, { useEffect, FormEvent, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import auth, {
  LoginStatus,
  useLoginUser,
  authError,
  useLogin
} from '@meettry/ui-components/utils/auth';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import NoticeFormTemplate from '@meettry/engineer/components/molecules/Template/NoticeFormTemplate';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import Checkbox from '@meettry/engineer/components/atoms/Checkbox';
import { isValidateEmail, isValidatePassword } from '@meettry/engineer/utils';
import { VALIDATION } from '@meettry/engineer/utils/Constant';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { useMutation } from '@apollo/react-hooks';
import { SIGNUP_USER_QUERY } from '@meettry/ui-components/queries/signup';

/**
 * Definition
 */
const NOTICE_PHRASE = {
  stress: 'メールアドレス確認用のメールを送信しました。',
  normal: '内容をご確認頂きリンクをクリックして\n認証を完了してください。'
};

type Model = {
  email: string;
  password: string;
};

/**
 * Input Reducer
 */
type InputStareType = {
  model: { email: string; password: string };
  error: { email: string; password: string };
  isCheck: boolean;
  finished: boolean;
};
type InputActionType = {
  type: 'CHANGE_FORM' | 'CHANGE_INPUT';
  item: keyof InputStareType | '';
  value: string | boolean | { email: string; password: string };
  target: string;
};

const initialState: InputStareType = {
  model: { email: '', password: '' },
  error: { email: '', password: '' },
  isCheck: false,
  finished: false
};

const changeInput: React.Reducer<InputStareType, InputActionType> = (
  state: InputStareType,
  action: InputActionType
) => {
  switch (action.type) {
    case 'CHANGE_INPUT':
      return { ...state, [action.item]: action.value };
    case 'CHANGE_FORM':
      const errorValue =
        String(action.value).length > 0
          ? { ...state.error, [action.target]: '' }
          : { ...state.error };
      return {
        ...state,
        error: errorValue,
        model: { ...state.model, [action.target]: action.value }
      };
    default:
      return state;
  }
};

/**
 * SignUp Component
 */
const SignUp: React.FC = () => {
  const history = useHistory();
  const { showErrorDialog, showNotificationDialog, closeAllDialog } = useDialog();
  const [login, { loading: loginLoading, error: errLogin }] = useLogin();
  const { status, user } = useLoginUser();
  const [state, dispatch] = useReducer(changeInput, initialState);
  const { model, error, isCheck, finished } = state;
  // MEMO（中山）：ベータ版ではスクロール感知を使用しないため、コメントアウトで対応
  // const [isDisplay, setIsDisplay] = useState(true);

  /**
   * signup mutation
   */
  const [signupMutation, { loading: LoadingSignUp, error: errSignUp }] = useMutation(
    SIGNUP_USER_QUERY,
    {
      onCompleted: async (res) => {
        if (res?.registerUser?.ok) {
          dispatch({ type: 'CHANGE_INPUT', value: true, item: 'finished', target: '' });
          login(model.email, model.password);
        } else {
          showErrorDialog(['登録に失敗しました']);
        }
      },
      onError: (err) => {
        showErrorDialog(['登録に失敗しました']);
      }
    }
  );

  /**
   * signupの実行
   */
  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidateForm(model.email, model.password)) return;

    if (!isCheck) {
      showErrorDialog(['個人情報の取り扱いに同意してください']);
      return;
    }

    try {
      const isUsedEmail = await auth.isUsedEmail(model.email);
      if (isUsedEmail) {
        showErrorDialog(['すでに登録されているようです。']);
        return;
      }
      await signupMutation({
        variables: { email: model.email, password: model.password }
      });
      dispatch({
        type: 'CHANGE_INPUT',
        value: { email: '', password: '' },
        item: 'error',
        target: ''
      });
    } catch (error) {
      const errorMsg = authError(error, 'signin');
      showErrorDialog([errorMsg ?? '']);
    }
  };

  /**
   * 入力されたメールとパスワードのバリデーション
   */
  const isValidateForm = (email: string, password: string) => {
    let isValidate = false;
    let emailErrorMessage = '';
    let passwordErrorMessage = '';
    if (email === '') {
      emailErrorMessage = VALIDATION.EMAIL.EMPTY;
      isValidate = true;
    }
    if (email !== '' && !isValidateEmail(email)) {
      emailErrorMessage = VALIDATION.EMAIL.INCORRECT;
      isValidate = true;
    }
    if (password === '') {
      passwordErrorMessage = VALIDATION.PASSWORD.EMPTY;
      isValidate = true;
    }
    if (password !== '' && isValidatePassword(password)) {
      passwordErrorMessage = VALIDATION.PASSWORD.INCORRECT;
      isValidate = true;
    }
    dispatch({
      type: 'CHANGE_INPUT',
      value: { email: emailErrorMessage, password: passwordErrorMessage },
      item: 'error',
      target: ''
    });
    return isValidate;
  };

  /**
   * ユーザーのステータスによって変化
   */
  useEffect(() => {
    switch (status) {
      case LoginStatus.NotInitialized:
      case LoginStatus.NotLogin:
      case LoginStatus.LoginNotEmailVerified:
        break;
      case LoginStatus.LoginEmailVerified:
        showNotificationDialog(
          ['ユーザー登録しました。', '続いてニックネームの登録をお願いします。'],
          () => {
            history.replace({ pathname: '/signup/nickname' });
            closeAllDialog();
          }
        );
        break;
      case LoginStatus.LoginNicknameRegistered:
        showNotificationDialog(['登録が完了しました。'], () => {
          history.replace({ pathname: `/user/${user.nickname}` });
          closeAllDialog();
        });
        break;
      default:
        throw new Error('Unexpected route');
    }
  }, [status, finished]);

  const onChangeForm = (target: string, value: string) => {
    if (target == 'checkbox' && value) {
      dispatch({ type: 'CHANGE_INPUT', value: Boolean(value), item: 'isCheck', target: '' });
      return;
    }
    dispatch({
      type: 'CHANGE_FORM',
      value: value,
      item: '',
      target: target
    });
  };

  // MEMO（中山）：ベータ版ではプラポリが埋め込みではなくなったため、コメントアウトで対応
  //下までスクロールしたときの感知
  /*   const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const PADDING_BOTTOM = 30;
    const isBottom = scrollHeight - scrollTop <= clientHeight + PADDING_BOTTOM;

    if (isBottom) {
      setIsDisplay(false);
      return;
    }
  }; */

  return (
    <>
      {status === LoginStatus.LoginNotEmailVerified ? (
        <NoticeFormTemplate
          stressPhrase={NOTICE_PHRASE.stress}
          normalPhrase={NOTICE_PHRASE.normal}
        />
      ) : (
        <StyledSignupForm onSubmit={onSubmit}>
          <StyledSignupFormRow data-error={error.email.length > 0}>
            <StyledLabel htmlFor="email">メールアドレス</StyledLabel>
            <StyledInput
              onChange={(e) => onChangeForm('email', e.target.value)}
              name="email"
              type="text"
              placeholder="メールアドレスを入力"
            />
            <StyledErrorText>{error.email}</StyledErrorText>
          </StyledSignupFormRow>
          <StyledSignupFormRow data-error={error.password.length > 0}>
            <StyledLabel htmlFor="password">パスワード</StyledLabel>
            <StyledInput
              onChange={(e) => onChangeForm('password', e.target.value)}
              name="password"
              type="password"
              placeholder="パスワードを入力"
            />
            <StyledErrorText>{error.password}</StyledErrorText>
            <StyledPasswordExplainText>
              パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、記号の全てを含んだものを指定してください。
            </StyledPasswordExplainText>
          </StyledSignupFormRow>

          {/* MEMO（中山）：ベータ版ではプラポリ画面に遷移になったため、コメントアウトで対応 */}
          {/* <TextScrollForm onScroll={handleScroll}>
        <h1>tag tag tag</h1>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
        <p>個人情報の取り扱いが入ります。</p>
      </TextScrollForm> */}
          <StyledCheckboxRow>
            <Checkbox
              onClick={() => {
                isCheck
                  ? dispatch({ type: 'CHANGE_INPUT', value: !isCheck, item: 'isCheck', target: '' })
                  : onChangeForm('checkbox', String(!isCheck));
              }}
              isCheck={isCheck}
              isDisplay={false}
            >
              <span>
                <StyledLink
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open('/terms', 'terms');
                  }}
                >
                  利用規約
                </StyledLink>
                及び
                <StyledLink
                  onClick={(e) => {
                    e.stopPropagation();
                    window.open('/privacy', 'privacy');
                  }}
                >
                  プライバシーポリシー
                </StyledLink>
                に同意する
              </span>
            </Checkbox>
          </StyledCheckboxRow>
          <StyledFormButtons>
            <PrimaryButton size="large" type="submit" disabled={LoadingSignUp || loginLoading}>
              アカウントを作成
            </PrimaryButton>
          </StyledFormButtons>
        </StyledSignupForm>
      )}
    </>
  );
};

export default SignUp;

const StyledSignupForm = styled.form`
  position: relative;
  width: 100%;
  max-width: 560px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 40px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  box-sizing: border-box;
  ${mediaQuery.lessThan('minTablet')`
    padding: 50px 40px 40px;
  `}
`;

const StyledCheckboxRow = styled.div`
  text-align: center;
  * + & {
    margin-top: 25px;
    > div {
      > img {
        align-self: flex-start;
        margin-top: 2px;
      }
      > span {
        text-align: left;
      }
    }
  }
`;

const StyledPasswordExplainText = styled.p`
  font-size: 14px;
  line-height: 1.5;
  * + & {
    margin-top: 15px;
  }
`;

const StyledErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;

  ${StyledLabel} + & {
    margin-top: 10px;
  }
`;

const StyledSignupFormRow = styled.div`
  & + & {
    margin-top: 15px;
  }

  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }

  &[data-error='true'] ${StyledErrorText} {
    display: block;
  }
`;

const StyledFormButtons = styled.div`
  margin-top: 20px;
`;

const StyledLink = styled.a`
  cursor: pointer;
  position: relative;
  line-height: 1.2;
  display: inline-block;
  border-bottom: 1px solid #f4233c;
  padding-bottom: 2px;
  &:hover {
    opacity: 0.8;
  }
`;
