import { useContext } from 'react';
import { setProfileImageUrl, LoginUserContext } from '@meettry/ui-components/contexts/loginUser';

export const useLoginUser = () => {
  const context = useContext(LoginUserContext);
  if (context === undefined) throw new Error(`No provider for PopupContext given`);
  const { state, dispatch } = context;
  const setStoreProfileImageUrl = (path: string) => {
    dispatch(setProfileImageUrl(path));
  };

  return {
    state,
    setStoreProfileImageUrl
  };
};

export default useLoginUser;
