import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLoginUser, LoginStatus } from '@meettry/ui-components/utils/auth';
import ApplicationPage from '@meettry/engineer/components/templates/ApplicationPage';
import NotFoundPage from '@meettry/engineer/components/pages/NotFound';
import firebase from '@meettry/ui-components/utils/firebase';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import ResetPasswordForm from '@meettry/engineer/components/pages/ActionPage/ResetPasswordForm';
import VarifyingCard from '@meettry/engineer/components/pages/ActionPage/VarifyingCard';
import { PageCommonProps } from '@meettry/ui-components/types/route';

/**
 * Definition
 */
type ActionMode = 'resetPassword' | 'recoverEmail' | 'verifyEmail' | 'signIn';

const ACTION_MODE: { [key in string]: ActionMode } = {
  RESET_PASSWORD: 'resetPassword',
  RECOVER_EMAIL: 'recoverEmail',
  VERIFY_EMAIL: 'verifyEmail',
  SIGN_IN: 'signIn'
};

type InitState = {
  mode: ActionMode | null;
  actionCode: string | null;
  continueUrl: string | null;
};

const initState: InitState = {
  mode: null,
  actionCode: null,
  continueUrl: null
};

/**
 * getParameterByName
 * URLから指定のqueryのパラメータを取得
 */
const getParameterByName = (name = '') => {
  const url = window.location.href;
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  const value = decodeURIComponent(results[2].replace(/\+/g, ' '));
  return value;
};

/**
 * ActionPage
 * Firebaseの認証カスタムUIページ
 * メール認証・パスワードリセットのカスタム画面
 */
const ActionPage: React.FC<PageCommonProps> = () => {
  const history = useHistory();
  const { user, status } = useLoginUser();
  const { showErrorPopup, showSuccessPopup } = usePopup();
  const [state, setState] = useState<InitState>(initState);

  /**
   * ログインステータスがメール認証済みならニックネーム画面に飛ばす
   */
  useEffect(() => {
    switch (status) {
      case LoginStatus.LoginEmailVerified: {
        history.push('/signup/nickname');
        return;
      }
      default: {
        return;
      }
    }
  }, [status]);

  /**
   * URLから認証ModeとActionCodeを取得しセット
   */
  useEffect(() => {
    const mode = getParameterByName('mode');
    const actionCode = getParameterByName('oobCode');
    const continueUrl = getParameterByName('continueUrl');
    if (!mode || !actionCode) {
      history.push('/login');
      return;
    }
    setState({ ...state, mode: mode as ActionMode, actionCode, continueUrl });
  }, []);

  // actionCodeでsignInとverifyEmailの認証をする
  /**
   * query情報を元に認証
   */
  useEffect(() => {
    const { mode, actionCode, continueUrl } = state;
    if (!mode || mode === ACTION_MODE.RESET_PASSWORD) return;
    if (!actionCode) {
      showErrorPopup('URLが不正です');
      history.push('/login');
      return;
    }
    firebase
      .applyActionCode(actionCode)
      .then(async () => {
        if (mode === ACTION_MODE.VERIFY_EMAIL) showSuccessPopup('メールアドレスを認証しました');
        if (mode === ACTION_MODE.SIGN_IN) showSuccessPopup('ログインしました');
        if (mode === ACTION_MODE.RECOVER_EMAIL)
          showSuccessPopup('以前登録されていたメールアドレスに戻しました');
        await firebase.refreshUser();
        await firebase.refreshToken();
        if (continueUrl) {
          if (continueUrl.indexOf('http') > -1) {
            window.location.href = continueUrl;
          } else {
            history.replace(continueUrl);
          }
        } else {
          history.replace('/login');
        }
      })
      .catch(() => {
        showErrorPopup('認証できませんでした。');
        setTimeout(() => history.replace('/login'), 1000);
      });
  }, [state]);

  /**
   * Render
   */
  return (
    <ApplicationPage pageType="slim">
      {state &&
        (state.mode === ACTION_MODE.RESET_PASSWORD ? (
          <ResetPasswordForm actionCode={state.actionCode ?? undefined} />
        ) : state.mode === ACTION_MODE.SIGN_IN ? (
          <VarifyingCard text="サインイン中..." />
        ) : state.mode === ACTION_MODE.VERIFY_EMAIL ? (
          <VarifyingCard text="メールアドレスの認証中..." />
        ) : (
          <NotFoundPage />
        ))}
    </ApplicationPage>
  );
};

export default ActionPage;
