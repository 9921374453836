import { useEffect, useMemo, useReducer, useState } from 'react';

import firebase, { LoginStatus, firebaseError } from '@meettry/ui-components/utils/firebase';

// dispatch types
// sendEmail
const SEND_VERIFICATION = 'SEND_VERIFICATION' as const;
const SET_ERROR = 'SET_ERROR' as const;
const SET_URL = 'SET_URL' as const;
// sign
const COMPLETED = 'COMPLETED' as const;
const END_LOADING = 'END_LOADING' as const;
const REQUEST_SIGNIN = 'REQUEST_SIGNIN' as const;
// checkLogin
const GET_LOGIN_STATES = 'GET_LOGIN_STATES' as const;

declare namespace SendEmailVerification {
  export interface StateType {
    loading: boolean;
    error: string | null;
    sended: boolean;
    url: string | null;
  }
  export interface ActionType {
    type: typeof SEND_VERIFICATION | typeof SET_ERROR | typeof SET_URL;
    error?: string | null;
    url?: string | null;
  }
}

declare namespace Sign {
  export interface StateType {
    loading: boolean;
    error: string | null;
    completed: boolean;
    email: string | null;
    password: string | null;
  }
  export interface ActionType {
    type: typeof COMPLETED | typeof SET_ERROR | typeof END_LOADING | typeof REQUEST_SIGNIN;
    error?: string | null;
    email?: string | null;
    password?: string | null;
  }

  export interface SignOutStateType {
    loading: boolean;
    error: string | null;
    completed: boolean;
  }
  export interface SignOutActionType {
    type: typeof COMPLETED | typeof SET_ERROR | typeof END_LOADING;
    error?: string | null;
    loading?: boolean;
  }
}

declare namespace UserLoginStatus {
  export interface UserType {
    id: string | null;
    nickname: string | null;
    email: string | null;
    organizationId?: string | null;
  }

  export interface StateType {
    initialized: boolean;
    authorized: boolean;
    status: number;
    idToken: string | null;
    user: UserType;
  }

  export interface ActionType {
    type: typeof GET_LOGIN_STATES;
    user?: UserType;
    token?: string | null;
    authorized: boolean;
    status: number;
  }
}

const sendEmailInitState: SendEmailVerification.StateType = {
  loading: false,
  error: '',
  sended: false,
  url: null
};

export const useMaintenance = () => {
  const [isMaintenance, setIsMaintenance] = useState(false);
  useEffect(() => {
    const unsubscribe = firebase.subscribeMaintenance(setIsMaintenance);
    return () => {
      unsubscribe();
    };
  }, []);
  return { isMaintenance };
};

const sendEmailReducer = (
  state: SendEmailVerification.StateType,
  action: SendEmailVerification.ActionType
) => {
  switch (action.type) {
    case SEND_VERIFICATION:
      return { ...state, sended: true };
    case SET_ERROR:
      return { ...state, error: action.error || '' };
    case SET_URL:
      return { ...state, loading: true, url: action.url || '' };
    default:
      return state;
  }
};

export const useSendEmailVerification = (): [
  (url: string) => void,
  SendEmailVerification.StateType
] => {
  const [state, dispatch] = useReducer(sendEmailReducer, sendEmailInitState);
  const sendEmailVerification = async () => {
    if (!state.url) return;
    try {
      await firebase.sendEmailVerification(state.url);
      dispatch({ type: SEND_VERIFICATION });
    } catch (e) {
      const errorMsg = firebaseError(e);
      dispatch({ type: SET_ERROR, error: errorMsg });
    }
  };

  useEffect(() => {
    if (state.loading) {
      sendEmailVerification();
    }
  }, [state.loading]);
  return [
    (url: string) => {
      dispatch({ type: SET_URL, url: url });
    },
    state
  ];
};

export const initSignState: Sign.StateType = {
  loading: false,
  error: '',
  completed: false,
  email: null,
  password: null
};

export const initSignOutState: Sign.SignOutStateType = {
  loading: false,
  error: '',
  completed: false
};

const signReducer = (state: Sign.StateType, action: Sign.ActionType) => {
  switch (action.type) {
    case COMPLETED:
      return { ...state, completed: true };
    case SET_ERROR:
      return { ...state, error: action.error || '' };
    case END_LOADING:
      return { ...state, loading: false };
    case REQUEST_SIGNIN:
      return {
        ...state,
        loading: true,
        email: action.email || '',
        password: action.password || ''
      };
    default:
      return state;
  }
};

const signOutReducer = (
  state: Sign.SignOutStateType,
  action: Sign.SignOutActionType
): Sign.SignOutStateType => {
  switch (action.type) {
    case COMPLETED:
      return { ...state, completed: true };
    case SET_ERROR:
      return { ...state, error: action.error || '' };
    case END_LOADING:
      return { ...state, loading: action.loading || false };
    default:
      return state;
  }
};

export const useSignIn = (): [(email: string, password: string) => void, Sign.StateType] => {
  const [state, dispatch] = useReducer(signReducer, initSignState);
  const noOrgUser = 'noOrgUser';
  const signIn = async () => {
    if (!state.email || !state.password) return;
    try {
      await firebase.signIn(state.email, state.password);
      await firebase.getIdTokenResult();
      dispatch({ type: COMPLETED });
    } catch (e) {
      const errorMsg = firebaseError(e, e === noOrgUser ? noOrgUser : 'signin');
      dispatch({ type: SET_ERROR, error: errorMsg });
    }
    dispatch({ type: END_LOADING });
  };

  useEffect(() => {
    if (state.loading) signIn();
  }, [state.loading]);

  return [
    (email: string, password: string) => {
      dispatch({ type: REQUEST_SIGNIN, email: email, password: password });
    },
    state
  ];
};

export const useSignUp = () => {
  const [state, dispatch] = useReducer(signReducer, initSignState);
  const signUp = async () => {
    if (!state.email || !state.password) return;
    try {
      await firebase.signUp(state.email, state.password);
      dispatch({ type: COMPLETED });
    } catch (e) {
      const errorMsg = firebaseError(e, 'signin');
      dispatch({ type: SET_ERROR, error: errorMsg });
    }
    dispatch({ type: END_LOADING });
  };

  useEffect(() => {
    if (state.loading) signUp();
  }, [state.loading]);

  return [
    (email: string, password: string) => {
      dispatch({ type: REQUEST_SIGNIN, email: email, password: password });
    },
    state
  ];
};

export const useSignOut = (): [() => void, Sign.SignOutStateType] => {
  const [state, dispatch] = useReducer(signOutReducer, initSignOutState);
  useEffect(() => {
    let unmounted: boolean = false;
    if (state.loading) {
      (async () => {
        try {
          await firebase.signOut();
          if (!unmounted) dispatch({ type: COMPLETED });
        } catch (e) {
          if (!unmounted) {
            const errorMsg = firebaseError(e);
            dispatch({ type: SET_ERROR, error: errorMsg });
          }
        }
        if (!unmounted) dispatch({ type: END_LOADING, loading: false });
      })();
    }
    return () => {
      unmounted = true;
    };
  }, [state.loading]);

  return [
    () => {
      dispatch({ type: END_LOADING, loading: true });
    },
    state
  ];
};

const initLoginStatusState: UserLoginStatus.StateType = {
  initialized: false,
  authorized: false,
  status: LoginStatus.NotInitialized,
  idToken: null,
  user: {
    id: null,
    nickname: null,
    email: null,
    organizationId: null
  }
};

const loginStatusReducer = (
  state: UserLoginStatus.StateType,
  action: UserLoginStatus.ActionType
) => {
  switch (action.type) {
    default:
      return {
        ...state,
        authorized: action.authorized,
        status: action.status,
        idToken: action.token ? action.token : state.idToken,
        user: action.user ? action.user : state.user
      };
  }
};

export const useLoginStatus = () => {
  const [state, dispatch] = useReducer(loginStatusReducer, initLoginStatusState);

  const getLoginStatus = async () => {
    let isLogin = false;
    const loginStatus = firebase.getLoginStatus();

    // ニックネームまで登録完了している場合のみログイン済みとする
    // 登録完了していない場合はログインページにて各々の処理を行う
    switch (loginStatus) {
      case LoginStatus.LoginNicknameRegistered:
        isLogin = true;
        break;
      case LoginStatus.NotInitialized:
      case LoginStatus.NotLogin:
      case LoginStatus.LoginNotEmailVerified:
      case LoginStatus.LoginEmailVerified:
      default:
        break;
    }

    const idTokenResult = await firebase.getIdTokenResult();
    const commonSetting = {
      type: GET_LOGIN_STATES,
      authorized: isLogin,
      status: loginStatus
    };
    if (isLogin && idTokenResult) {
      dispatch({
        ...commonSetting,
        token: idTokenResult.token ? idTokenResult.token : state.idToken,
        user: idTokenResult.claims
          ? {
              ...state.user,
              id: idTokenResult.claims._user_id,
              nickname: idTokenResult.claims._nickname,
              email: idTokenResult.claims.email,
              organizationId: idTokenResult.claims._organization_id
            }
          : state.user
      });
    } else {
      dispatch({
        ...commonSetting,
        token: state.idToken,
        user: state.user
      });
    }
  };

  useEffect(() => {
    const unsubscribe = firebase.onIdTokenChanged(() => {
      getLoginStatus();
    });
    return unsubscribe;
  }, []);

  const isEnterprise = useMemo(() => state.user.organizationId !== null, [state.user]);

  return {
    ...state,
    isEnterprise,
    getLoginStatus
  };
};
