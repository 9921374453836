import React from 'react';
import { useHistory } from 'react-router-dom';
import ResumeItem from '@meettry/company/component/organisms/userPage/Resume/ResumeList';
import EmptyListParts from '@meettry/company/component/organisms/userPage/Dashboard/EmptyListParts';

export const ResumeListItem = [
  {
    id: '1',
    isPin: true,
    resumeLikes: 200,
    start: '2009/04/01',
    end: '2009/04/01',
    total: '6年3ヶ月',
    title: 'ベンチャー企業でのソーシャルゲーム開発運用',
    officeName: '株式会社パクレゼルヴ',
    jobList: [
      '20人開発体制',
      '10万/秒トラフィック',
      '要件定義',
      '設計',
      '実装',
      'テスト',
      '運用保守'
    ],
    langList: ['Python', 'C#', 'Ruby', 'Actionscrip', 'Lua'],
    libraryList: ['Flask', 'JQuery'],
    serversideList: ['MySQL', 'Tagtagtag', 'Redis', 'SQLite'],
    infraList: ['Amazon Web Service'],
    osList: ['Linux', 'iOS', 'Android'],
    toolList: ['GitHub'],
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloud Runも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  },
  {
    id: '2',
    isPin: false,
    resumeLikes: 120,
    start: '2009/04/01',
    end: '2009/04/01',
    total: '6年3ヶ月',
    title: 'ベンチャー企業でのソーシャルゲーム開発運用',
    officeName: '株式会社パクレゼルヴ',
    jobList: [
      '20人開発体制',
      '10万/秒トラフィック',
      '要件定義',
      '設計',
      '実装',
      'テスト',
      '運用保守'
    ],
    langList: ['Python', 'C#', 'Ruby', 'Actionscrip', 'Lua'],
    libraryList: ['Flask', 'JQuery'],
    serversideList: ['MySQL', 'Tagtagtag', 'Redis', 'SQLite'],
    infraList: ['Amazon Web Service'],
    osList: ['Linux', 'iOS', 'Android'],
    toolList: ['GitHub'],
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloud Runも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  },
  {
    id: '3',
    isPin: true,
    resumeLikes: 80,
    start: '2009/04/01',
    end: '2009/04/01',
    total: '6年3ヶ月',
    title: 'ベンチャー企業でのソーシャルゲーム開発運用',
    officeName: '株式会社パクレゼルヴ',
    jobList: [
      '20人開発体制',
      '10万/秒トラフィック',
      '要件定義',
      '設計',
      '実装',
      'テスト',
      '運用保守'
    ],
    langList: ['Python', 'C#', 'Ruby', 'Actionscrip', 'Lua'],
    libraryList: ['Flask', 'JQuery'],
    serversideList: ['MySQL', 'Tagtagtag', 'Redis', 'SQLite'],
    infraList: ['Amazon Web Service'],
    osList: ['Linux', 'iOS', 'Android'],
    toolList: ['GitHub'],
    text:
      'Kubernetesを活用した〇〇なDevOpsを構築できるように実践中。KnativeやCloud Runも気になっているが、まずは↓のような構成で、構築していきたいと思っている。テキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入りますテキストがここに入ります'
  }
];

export const ResumeParts = () => {
  const history = useHistory();

  const onClickResumeTitle = (id: string) => () => {
    history.push('/user_name/resume', { selectedResumeId: id });
    window.scrollTo(0, 0);
  };
  return (
    <React.Fragment>
      {ResumeListItem.length ? (
        <ul className="resume-parts-wrap">
          {ResumeListItem.map((item, i) => {
            return (
              <React.Fragment key={i}>
                {item.isPin ? (
                  <li>
                    <ResumeItem listItem={item} onClick={onClickResumeTitle(item.id)} />
                  </li>
                ) : null}
              </React.Fragment>
            );
          })}
        </ul>
      ) : (
        <EmptyListParts text="経歴" />
      )}
    </React.Fragment>
  );
};
