import React from 'react';
import styled from 'styled-components';
import { TextField } from '@meettry/engineer/components/atoms/Input';

export const InputName = (props) => {
  const { userDetail, onChangeFirstName, onChangeLastName } = props;
  return (
    <StyledInputName>
      <StyledInputItem>
        <StyledInputItemLabel>姓</StyledInputItemLabel>
        <TextField
          defaultValue={userDetail.lastName}
          onChange={onChangeLastName}
          placeholder="山田"
        />
      </StyledInputItem>
      <StyledInputItem>
        <StyledInputItemLabel>名</StyledInputItemLabel>
        <TextField
          defaultValue={userDetail.firstName}
          onChange={onChangeFirstName}
          placeholder="太郎"
        />
      </StyledInputItem>
    </StyledInputName>
  );
};

const StyledInputName = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

const StyledInputItem = styled.div`
  display: flex;
  align-items: center;
  > *:not(:first-child) {
    margin-left: 5px;
  }
`;

const StyledInputItemLabel = styled.p`
  min-width: 34px;
`;
