import React, { useRef, useEffect, useState, ChangeEvent } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import icons from '@meettry/ui-components/images/icons';

const ERROR_MESSAGE = {
  COMPANY_NAME: [{ code: 'empty', message: 'を入力してください' }],
  POSTAL_CODE: [
    { code: 'empty', message: '郵便番号を入力してください' },
    { code: 'fault', message: '正しい形式で郵便番号を入力してください' }
  ],
  STREET_ADDRESS: [{ code: 'empty', message: '住所を入力してください' }],
  PHONE_NUMBER: [
    { code: 'empty', message: '電話番号を入力してください' },
    { code: 'fault', message: '正しい形式で電話番号を入力してください' }
  ],
  INDUSTRY_TYPE: [{ code: 'empty', message: '業種形態を入力してください' }],
  URL: [
    { code: 'empty', message: 'URLを入力してください' },
    { code: 'fault', message: '正しい形式でURLを入力してください' }
  ],
  MAIL_ADDRESS: [
    { code: 'empty', message: 'メールアドレスを入力してください' },
    { code: 'fault', message: '正しい形式でメールアドレスを入力してください' }
  ],
  PASSWORD: [
    { code: 'empty', message: 'パスワードを入力してください' },
    { code: 'fault', message: '正しい形式でパスワードを入力してください' },
    { code: 'different', message: 'パスワードが異なります' },
    {
      code: 'auth/too-many-requests',
      message: 'パスワードを数回間違えました。少し待ってから再試行してください。'
    }
  ],
  DEPARTMENT_NAME: [{ code: 'empty', message: '部署名を入力してください' }],
  REPRESENTATIVE_NAME: [{ code: 'empty', message: '担当者を入力してください' }]
};

type InputCommonProps<T> = {
  require?: boolean;
  state: T;
  stateKey: string;
  dispatch: Function;
};

type ErrorProps = {
  error?: string;
};

type CompanyNameProps = {
  companyNameText?: string;
};

export const CompanyName: React.FC<InputCommonProps<string> & CompanyNameProps & ErrorProps> = (
  props
) => {
  const { companyNameText, require, error, state, dispatch, stateKey } = props;
  const companyNameErrorFind = ERROR_MESSAGE.COMPANY_NAME.find((item) => item.code === error);
  const companyNameError = !!companyNameErrorFind && companyNameText + companyNameErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="company-name">
        {companyNameText}
        {require ? <StyledRequiredLabel>必須</StyledRequiredLabel> : null}
      </StyledInputLabel>
      <StyledInputWrapper>
        <StyledInput
          name="company-name"
          type="text"
          placeholder="株式会社 メトリィ"
          value={state}
          error={!!companyNameErrorFind}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: stateKey })
          }
        />
        {!!companyNameErrorFind ? (
          <StyledErrorMessage>{companyNameError}</StyledErrorMessage>
        ) : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const PostalCode: React.FC<InputCommonProps<Array<string>> & ErrorProps> = (props) => {
  const { state, stateKey, dispatch, error } = props;
  const placeHolder = ['123', '4567'];
  const postalCodeErrorFind = ERROR_MESSAGE.POSTAL_CODE.find((item) => item.code === error);
  const postalCodeError = !!postalCodeErrorFind && postalCodeErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="postal-code">郵便番号</StyledInputLabel>
      <StyledInputWrapper>
        {placeHolder.map((item, i) => (
          <React.Fragment key={i}>
            <StyledInput
              key={i}
              name="postal-code"
              type="number"
              placeholder={item}
              value={state[i]}
              error={!!postalCodeErrorFind}
              maxWidth="100px"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: 'CHANGE_TEXT_NUMBER',
                  text: event.target.value,
                  item: stateKey,
                  stateArray: 'postalCode',
                  num: i
                })
              }
            />
            {i < placeHolder.length - 1 ? <StyledHyphen>ー</StyledHyphen> : null}
          </React.Fragment>
        ))}
        {!!postalCodeErrorFind ? <StyledErrorMessage>{postalCodeError}</StyledErrorMessage> : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const StreetAddress: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const { state, stateKey, dispatch, require, error } = props;
  const streetAddressErrorFind = ERROR_MESSAGE.STREET_ADDRESS.find((item) => item.code === error);
  const streetAddressError = !!streetAddressErrorFind && streetAddressErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="street-address">
        住所
        {require ? <StyledRequiredLabel>必須</StyledRequiredLabel> : null}
      </StyledInputLabel>
      <StyledInputWrapper>
        <StyledInput
          name="street-address"
          type="text"
          placeholder="東京都渋谷区渋谷 000  （県名までは全てのユーザーに表示されます）"
          value={state}
          error={!!streetAddressErrorFind}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: stateKey })
          }
        />
        {!!streetAddressErrorFind ? (
          <StyledErrorMessage>{streetAddressError}</StyledErrorMessage>
        ) : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const PhoneNumber: React.FC<
  InputCommonProps<Array<string>> & ErrorProps & { isBill?: boolean }
> = (props) => {
  const { state, stateKey, dispatch, require, error, isBill = false } = props;
  const placeHolder = isBill ? ['00', '0000', '0000'] : ['090', '0000', '0000'];
  const phoneNumberErrorFind = ERROR_MESSAGE.PHONE_NUMBER.find((item) => item.code === error);
  const phoneNumberError = !!phoneNumberErrorFind && phoneNumberErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="phone-number">
        {isBill ? '会社電話番号' : '電話番号'}
        {require ? <StyledRequiredLabel>必須</StyledRequiredLabel> : null}
      </StyledInputLabel>
      <StyledInputWrapper>
        {placeHolder.map((item, i) => (
          <React.Fragment key={i}>
            <StyledInput
              key={i}
              name="phone-number"
              type="tel"
              placeholder={item}
              value={state[i]}
              error={!!phoneNumberErrorFind}
              maxWidth="100px"
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                dispatch({
                  type: 'CHANGE_TEXT_NUMBER',
                  text: event.target.value,
                  item: stateKey,
                  stateArray: 'phoneNumber',
                  num: i
                })
              }
            />
            {i < placeHolder.length - 1 ? <StyledHyphen>ー</StyledHyphen> : null}
          </React.Fragment>
        ))}
        {!!phoneNumberErrorFind ? (
          <StyledErrorMessage>{phoneNumberError}</StyledErrorMessage>
        ) : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const IndustryType: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const industryTypeErrorFind = ERROR_MESSAGE.INDUSTRY_TYPE.find(
    (item) => item.code === props.error
  );
  const industryTypeError = !!industryTypeErrorFind && industryTypeErrorFind.message;
  const industryTypeErrorCls = !!industryTypeErrorFind ? 'error' : '';
  return (
    <div className="input-area industry-type">
      <label htmlFor="industry-type">業種形態</label>
      <div>
        <div className="select-wrap">
          <select
            name="industry-type"
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) =>
              props.dispatch({
                type: 'CHANGE_TEXT',
                text: event.target.value,
                item: props.stateKey
              })
            }
            value={props.state}
            className={industryTypeErrorCls}
          >
            <option value="選択してください" hidden>
              選択してください
            </option>
            <option value="自社サービス">自社サービス</option>
            <option value="受託制作">受託制作</option>
            <option value="Sler">Sler</option>
            <option value="SES">SES</option>
          </select>
        </div>
        {!!industryTypeErrorFind ? <span>{industryTypeError}</span> : null}
      </div>
    </div>
  );
};

export const Url: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const { state, stateKey, dispatch, error } = props;
  const urlErrorFind = ERROR_MESSAGE.URL.find((item) => item.code === error);
  const urlError = !!urlErrorFind && urlErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="url">URL</StyledInputLabel>
      <StyledInputWrapper>
        <StyledInput
          name="url"
          type="url"
          placeholder="http://www.portfolio.com"
          value={state}
          error={!!urlErrorFind}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: stateKey })
          }
        />
        {!!urlErrorFind ? <StyledErrorMessage>{urlError}</StyledErrorMessage> : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const MailAddress: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const mailAddressErrorFind = ERROR_MESSAGE.MAIL_ADDRESS.find((item) => item.code === props.error);
  const mailAddressError = !!mailAddressErrorFind && mailAddressErrorFind.message;
  const mailAddressErrorCls = !!mailAddressErrorFind ? 'error' : '';
  return (
    <div className="input-area email">
      <label htmlFor="email">
        メールアドレス<span className={props.require ? '' : 'unshow'}>必須</span>
      </label>
      <div>
        <input
          name="email"
          type="email"
          placeholder="meettry@meettry.com"
          value={props.state}
          className={mailAddressErrorCls}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.dispatch({
              type: 'CHANGE_TEXT',
              text: event.target.value,
              item: props.stateKey
            })
          }
        />
        {!!mailAddressErrorFind ? <span>{mailAddressError}</span> : null}
      </div>
    </div>
  );
};

type PasswordProps = {
  passwordText?: string;
  cls?: string;
};

export const Password: React.FC<InputCommonProps<string> & PasswordProps & ErrorProps> = (
  props
) => {
  const passwordErrorFind = ERROR_MESSAGE.PASSWORD.find((item) => item.code === props.error);
  const passwordError = !!passwordErrorFind && passwordErrorFind.message;
  const passwordErrorCls = !!passwordErrorFind ? 'error' : '';
  return (
    <div className="input-area password">
      <label htmlFor="password">
        {props.passwordText}
        <span className={props.require ? '' : 'unshow'}>必須</span>
      </label>
      <div>
        <input
          name="password"
          type="password"
          className={passwordErrorCls}
          value={props.state}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: props.stateKey })
          }
        />
        {!!passwordErrorFind ? <span>{passwordError}</span> : null}
      </div>
    </div>
  );
};

export const DepartmentName: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const { state, stateKey, dispatch, error } = props;
  const departmentNameErrorFind = ERROR_MESSAGE.DEPARTMENT_NAME.find((item) => item.code === error);
  const departmentNameError = !!departmentNameErrorFind && departmentNameErrorFind.message;

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="department-name">部署名</StyledInputLabel>
      <StyledInputWrapper>
        <StyledInput
          name="department-name"
          type="text"
          maxWidth="400px"
          value={state}
          error={!!departmentNameErrorFind}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: stateKey })
          }
        />
        {!!departmentNameErrorFind ? (
          <StyledErrorMessage>{departmentNameError}</StyledErrorMessage>
        ) : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

export const RepresentativeName: React.FC<InputCommonProps<string> & ErrorProps> = (props) => {
  const { state, stateKey, dispatch, require, error } = props;
  const representativeNameErrorFind = ERROR_MESSAGE.REPRESENTATIVE_NAME.find(
    (item) => item.code === error
  );
  const representativeNameError =
    !!representativeNameErrorFind && representativeNameErrorFind.message;

  return (
    <StyledInputArea className="input-area representative-name">
      <StyledInputLabel htmlFor="representative-name">
        ご担当者様
        {require ? <StyledRequiredLabel>必須</StyledRequiredLabel> : null}
      </StyledInputLabel>
      <StyledInputWrapper>
        <StyledInput
          name="representative-name"
          type="text"
          maxWidth="400px"
          value={state}
          error={!!representativeNameErrorFind}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: stateKey })
          }
        />
        {!!representativeNameErrorFind ? (
          <StyledErrorMessage>{representativeNameError}</StyledErrorMessage>
        ) : null}
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

type CheckboxProps = {
  checked: boolean;
  onChange: (value: boolean) => void;
  name: string;
};
export const CheckBox: React.FC<CheckboxProps> = (props) => {
  const { checked, onChange, name } = props;
  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  return (
    <div>
      <StyledCheckboxWrapper htmlFor={name}>
        <StyledCheckboxHeading>終了案件を含む</StyledCheckboxHeading>
        <StyledCheckboxInput type="checkbox" checked={checked} onChange={onChangeInput} id={name} />
      </StyledCheckboxWrapper>
    </div>
  );
};

type SearchBarProps = {
  placeHolder?: string;
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
  onChange?: (text: string) => void;
  onSubmit?: (text: string) => void;
  value?: string;
};
export const SearchBar: React.FC<SearchBarProps> = (props) => {
  const { margin, placeHolder, onChange, value, onSubmit } = props;
  const [internalValue, setInternalValue] = useState('');
  const ref = useRef<HTMLInputElement | null>(null);

  const onChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    console.log('onChange', value);

    if (value) {
      onChange && onChange(e.target.value);
    } else {
      console.log('internalValue', e.target.value);
      setInternalValue(e.target.value);
    }
  };

  const onSubmitInput = () => {
    if (!ref.current) return;
    onSubmit && onSubmit(ref.current.value);
  };

  return (
    <StyledSearchBar margin={margin}>
      <StyledSearchBarInput
        type="text"
        placeholder={placeHolder}
        onChange={onChangeInput}
        value={value || internalValue}
        ref={ref}
        onKeyPress={(e) => e.key === 'Enter' && onSubmitInput()}
      />
      <StyledSearchIcon iconName="search" iconColor="primary" onClick={onSubmitInput} />
    </StyledSearchBar>
  );
};

type InputScoutProps<T> = {
  state: T;
  stateKey: string;
  dispatch: Function;
};

type TextareaProps = {
  labelText?: string;
  require?: boolean;
  placeholder?: string;
};

export const CommentText: React.FC<TextareaProps & InputScoutProps<string>> = (props) => {
  return (
    <StyledCommentTextAreaWrap>
      <StyledLabelText>{props.labelText}</StyledLabelText>
      {props.require ? <StyledInputAreaRequire>必須</StyledInputAreaRequire> : ''}
      <StyledCommentTextArea
        isRequire={props.require}
        value={props.state}
        onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
          props.dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: props.stateKey })
        }
        placeholder={props.placeholder}
      />
    </StyledCommentTextAreaWrap>
  );
};

type TextInputProps = {
  labelText?: string;
  placeHolder?: string;
  cls?: string;
  require?: boolean;
  type: string;
  setEl?: Function;
  onKeyDown?: Function;
  width?: number | string;
  padding?: [number, number, number, number];
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
};

export const TextInput: React.FC<TextInputProps & InputScoutProps<string>> = (props) => {
  const inputEl = useRef<HTMLInputElement>(null);
  const [isConfirmed, changeConfirmed] = useState(true);
  useEffect(() => {
    !!props.setEl && props.setEl(inputEl.current);
  }, []);
  return (
    <StyledInputTextArea padding={props.padding} margin={props.margin} width={props.width}>
      {props.require ? <StyledInputAreaRequire>必須</StyledInputAreaRequire> : ''}
      <StyledTextAreaInput
        isRequire={props.require}
        type={props.type}
        placeholder={props.placeHolder}
        value={props.state}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
          props.dispatch({ type: 'CHANGE_TEXT', text: event.target.value, item: props.stateKey })
        }
        onKeyDown={(e) => {
          if (isConfirmed && !!props.onKeyDown) props.onKeyDown(e);
          if (e.key === 'Enter') changeConfirmed(true);
        }}
        onCompositionStart={() => changeConfirmed(false)}
        onCompositionEnd={() => changeConfirmed(true)}
        ref={inputEl}
      />
    </StyledInputTextArea>
  );
};

type RadioButtonProps = {
  labelText?: string;
  cls?: string;
  name?: string;
};

export const RadioButton: React.FC<RadioButtonProps & InputScoutProps<boolean>> = (props) => {
  return (
    <StyledRadioButtonWrap>
      <StyledRadioButtonInput
        type="radio"
        name={props.name}
        id={props.labelText}
        checked={props.state}
        onChange={() => props.dispatch({ type: 'CHANGE_RADIO' })}
      />
      <StyledRadioButtonLabel htmlFor={props.labelText}>{props.labelText}</StyledRadioButtonLabel>
    </StyledRadioButtonWrap>
  );
};

export const CorporateLogo: React.FC<InputCommonProps<string>> = (props) => {
  const { state, stateKey, dispatch, require } = props;
  const reader = new FileReader();

  const setLogo = (event: any) => {
    const file = event.target.files[0];
    reader.readAsDataURL(file);
    dispatch({ type: 'SET_IMAGE_FILE', file: file });
  };

  reader.onload = () => {
    dispatch({ type: 'CHANGE_TEXT', text: reader.result, item: stateKey });
  };
  reader.onerror = () => {
    alert('エラー：ファイルをロードできません。');
  };

  return (
    <StyledInputArea>
      <StyledInputLabel htmlFor="corporate-info">
        企業ロゴ
        {require ? <StyledRequiredLabel>必須</StyledRequiredLabel> : null}
      </StyledInputLabel>
      <StyledInputWrapper>
        {state === '' ? (
          <StyledCorporateDefaultImage />
        ) : (
          <StyledCorporateImage src={state} alt="ロゴ画像" />
        )}
        <StyledImageInputLabel htmlFor="upload-logo">画像をアップロード</StyledImageInputLabel>
        <StyledImageInput
          name="upload-logo"
          id="upload-logo"
          type="file"
          accept="image/png,image/jpeg"
          onChange={setLogo}
        />
      </StyledInputWrapper>
    </StyledInputArea>
  );
};

const StyledInputArea = styled.div`
  display: flex;
`;

const StyledInputLabel = styled.label`
  display: flex;
  width: 100%;
  max-width: 172px;
  font-size: 16px;
  line-height: 48px;
  justify-content: space-between;
  cursor: default;
`;

const StyledRequiredLabel = styled.span`
  display: inline-block;
  width: 30px;
  height: 14px;
  border-radius: 30px;
  margin-right: 11px;
  background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
  font-size: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  text-align: center;
  line-height: 14px;
  margin-top: 0;
  align-self: center;
`;

const StyledInput = styled.input<{ error?: boolean; maxWidth?: string }>`
  width: 100%;
  max-width: ${({ maxWidth }) => maxWidth ?? '100%'};
  height: 48px;
  padding: 0 20px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  font-size: 16px;
  &::placeholder {
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  }
  &[type='number']::-webkit-outer-spin-button,
  &[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    margin: 0;
  }

  ${({ error }) =>
    error
      ? `
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
    `
      : null}
`;

const StyledImage = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 167px;
  border-radius: 50%;
  object-fit: cover;
`;

const StyledCorporateImage = StyledImage.withComponent('img');

const StyledCorporateDefaultImage = styled(StyledImage)`
  background-color: ${COLOR_DEFINITIONS.LINE.SECONDARY};
  &::before {
    content: '';
    display: inline-block;
    width: 70px;
    height: 70px;
    background-image: url(${icons.gray.company});
    background-size: cover;
  }
`;

const StyledImageInputLabel = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 167px;
  height: 30px;
  margin-top: 15px;
  border: 1px solid ${COLOR_DEFINITIONS.TEXT.SUB};
  border-radius: 4px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  &::before {
    content: '';
    display: inline-block;
    width: 14px;
    height: 14px;
    margin-right: 5px;
    background-image: url(${icons.gray.camera});
    background-size: cover;
  }
`;

const StyledImageInput = styled.input`
  display: none;
`;

const StyledHyphen = styled.span`
  margin: 0 10px;
`;

const StyledInputWrapper = styled.div`
  width: 100%;
  max-width: 630px;
`;

const StyledErrorMessage = styled.span`
  display: block;
  margin-top: 8px;
  font-size: 12px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
`;

const StyledCheckboxWrapper = styled.label`
  cursor: pointer;
`;

const StyledCheckboxHeading = styled.span`
  color: ${COLOR_DEFINITIONS.BG.FOOTER};
  font-size: 12px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: 500;
  line-height: 21px;
`;

const StyledCheckboxInput = styled.input`
  border: none;
  outline: none;
  appearance: none;
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    margin-left: 5px;
    width: 21px;
    height: 21px;
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-radius: 4px;
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  }
  &:checked::before {
    content: '';
    display: block;
    width: 9px;
    height: 4px;
    border-bottom: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-left: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    position: absolute;
    top: 7px;
    left: 11px;
    transform: rotate(-45deg);
    z-index: 10;
  }
`;

const StyledSearchBar = styled.div<{ margin: SearchBarProps['margin'] }>`
  display: flex;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  box-sizing: border-box;
  width: 200px;
  height: 30px;
  border-radius: 15px;
  font-size: 12px;
  padding: 0 0 0 12px;

  ${(props) =>
    props.margin
      ? `margin:${props.margin
          .map((margin) => (margin === 'auto' || margin === 0 ? margin : margin + 'px'))
          .join(' ')};`
      : null}
`;

const StyledSearchBarInput = styled.input`
  border: none;
  outline: none;
  appearance: none;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  width: 100%;
  height: 100%;
  &::placeholder {
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  }
`;

const StyledSearchIcon = styled(Icon)`
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  transition: opacity 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

// TextInput

const StyledInputTextArea = styled.div<{
  padding?: [number, number, number, number];
  width?: number | string;
  margin?: [number | 'auto', number | 'auto', number | 'auto', number | 'auto'];
}>`
  display: flex;
  width: ${({ width }) =>
    !!width && typeof width === 'string' ? width : !!width ? `${width}px` : `100%`};
  ${({ padding }) =>
    padding
      ? `padding:${padding.map((padding) => (padding === 0 ? padding : padding + 'px')).join(' ')};`
      : null}
  ${(props) =>
    props.margin
      ? `margin:${props.margin
          .map((margin) => (margin === 'auto' || margin === 0 ? margin : margin + 'px'))
          .join(' ')};`
      : null}
`;

const StyledInputAreaRequire = styled.span`
  display: inline-block;
  width: 30px;
  height: 14px;
  border-radius: 30px;
  margin-right: 10px;
  background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
  font-size: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  text-align: center;
  line-height: 14px;
  margin-top: 17px;
`;

const StyledTextAreaInput = styled.input<{ isRequire?: boolean }>`
  width: ${({ isRequire }) => (isRequire ? `calc(100% - 40px)` : `100%`)};
  height: 48px;
  padding: 0 10px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  font-size: 16px;
  &::placeholder {
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  }
`;

// RadioButton

const StyledRadioButtonWrap = styled.div`
  display: flex;
  align-items: center;
  & + & {
    margin-left: 8px;
  }
`;

const StyledRadioButtonInput = styled.input`
  border: none;
  outline: none;
  appearance: none;
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  &::after {
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-radius: 20px;
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
  &:checked::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-radius: 10px;
    background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    position: absolute;
    top: 50%;
    left: 5px;
    z-index: 10;
    transform: translateY(-50%);
  }
`;

const StyledRadioButtonLabel = styled.label`
  margin-left: 8px;
`;

// CommentText
const StyledCommentTextAreaWrap = styled.label`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledLabelText = styled.span`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 16px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: 500;
`;

const StyledCommentTextArea = styled.textarea<{ isRequire?: boolean }>`
  padding: 20px;
  box-sizing: border-box;
  width: calc(100% - 40px);
  height: 170px;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  resize: none;
  &::placeholder {
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  }
`;
