import React from 'react';
import styled from 'styled-components';
import InputFormTemplate from '@meettry/engineer/components/molecules/Template/InputFormTemplate';
import LogoIcon from '@meettry/engineer/images/bird_green.png';

const NoticeFormTemplate = ({ stressPhrase = '', normalPhrase = '' }) => {
  return (
    <InputFormTemplate>
      <StyledImg>
        <img src={LogoIcon} alt="meettry" />
      </StyledImg>
      <StyledStressPhrase>{stressPhrase}</StyledStressPhrase>
      <StyledNormalPhrase>{normalPhrase}</StyledNormalPhrase>
    </InputFormTemplate>
  );
};

export default NoticeFormTemplate;

const StyledImg = styled.div``;

const StyledStressPhrase = styled.p`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #0fafa9;
  margin-top: 30px;
  white-space: pre-wrap;
  line-height: 1.5;
`;

const StyledNormalPhrase = styled.p`
  margin-top: 20px;
  text-align: center;
  white-space: pre-wrap;
  line-height: 1.5;
`;
