import React from 'react';
import styled, { css } from 'styled-components';

export const Textarea = (props) => {
  const { onChange, defaultValue, placeholder, error } = props;
  return (
      <StyledTextarea
        onChange={onChange}
        defaultValue={defaultValue}
        placeholder={placeholder}
        error={error}
      />
  );
};

export default Textarea;

const StyledTextarea = styled.textarea`
  color: #333333;
  box-sizing: border-box;
  appearance: none;
  font-size: 16px;
  font-family: sans-serif;
  padding: 16px 20px;
  background-color: #f7f7f7;
  border: #eeeeee solid 1px;
  border-radius: 4px;
  height: 170px;
  width: 100%;
  resize: none;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid #0fafa9;
  }
  ${({ error }) =>
    error &&
    css`
      background-color: #FEE8EB;
      border: 1px solid #f4233c;
      :focus {
        outline: none;
        border: 1px solid #f4233c;
      }
    `}
`;