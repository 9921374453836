import React from 'react';
import { useLocation } from 'react-router-dom';
import { SettingTab } from '@meettry/company/component/molecules/settingTab';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { StyledContentsWrapper } from '@meettry/engineer/components/pages/EnterpriseDashboard';

const settingTabData = [
  { name: '企業情報', type: 'Company', path: '/enterprise/settings/company' },
  { name: '請求書・決済', type: 'Payment', path: '/enterprise/settings/payment' }
  // TODO(minami): 正式版での実装{ name: '契約プラン', type: 'Plan', path: '/enterprise/settings/plan' }
];

type SettingType = {
  children: React.ReactNode;
};

const Setting: React.FC<SettingType> = ({ children }) => {
  const location = useLocation();
  const locationPath = location.pathname;

  return (
    <StyledContentsWrapper>
      <StyledSettingTitle className="heading-icon">
        <StyledIcon iconName="resume" iconColor="black" width="18px" />
        登録設定
      </StyledSettingTitle>
      <StyledSettingTab active={locationPath} tab={settingTabData} />
      {children}
    </StyledContentsWrapper>
  );
};

export default Setting;

const StyledSettingTitle = styled.h2`
  padding-bottom: 23px;
  margin: 0;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
`;

const StyledSettingTab = styled(SettingTab)`
  margin-top: 25px;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
`;
