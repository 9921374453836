import React from 'react';
import styled from 'styled-components';

const Icon = (props) => {
  const { imgName = 'os_dark', width = '12px', ...restProps } = props;

  return <StyledImg width={width} src={require(`@meettry/engineer/images/icon_${imgName}.png`)} {...restProps} />;
};

export default Icon;

const StyledImg = styled.img`
  height: ${(props) => props.height || 'auto'};
  width: ${(props) => props.width};
  ${(props) => (props.marginRight ? `margin-right: ${props.marginRight}` : null)}
`;
