import React from 'react';
import Tag from '@meettry/ui-components/components/atoms/Tag/Tag';

type TagType = {
  type?: string;
  size?: string;
  children?: React.ReactNode;
  className?: string;
  active?: boolean;
  onClick?: () => void;
  period?: string | void;
};

const tagGenerator = (options: TagType) => {
  const { type } = options;
  return (props: TagType) => {
    return <Tag {...props} type={type} />;
  };
};

export const BasicTag = tagGenerator({});
export const LineTag = tagGenerator({ type: 'line' });
export const GrayTag = tagGenerator({ type: 'gray' });
export const GrayRoundTag = tagGenerator({ type: 'grayRound' });
export const RequiredTag = tagGenerator({ type: 'required' });
