import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const useCreateEnterpriseBreadcrumbs = () => {
  const [breadcrumbs, setBreadcrumbs] = useState<Array<{ text: string; path: string }>>([]);
  const location = useLocation();
  const locationPath = location.pathname;
  const breadcrumbsArray: Array<{ text: string; path: string }> = [];

  //MEMO(minami): locationPathを/区切りの配列にする
  const locationDisassembly = locationPath.split('/');
  locationDisassembly.shift();
  const locationArray = locationDisassembly.map((li) => {
    return '/' + li;
  });

  const breadcrumbsList = [
    { text: 'TOP', detectPath: '/enterprise', path: '/enterprise/scout' },
    // { text: 'ダッシュボード', detectPath: '/dashboard', path: '/enterprise/dashboard' },
    { text: 'スカウト管理', detectPath: '/scout', path: '/enterprise/scout' },
    { text: '詳細ページ', detectPath: '/:scoutId', path: '/enterprise/scout/:scoutId' },
    { text: 'チャット', detectPath: '/chat', path: '/enterprise/chat' },
    {
      text: '設定',
      detectPath: '/settings',
      path: '/enterprise/settings/company'
    }
  ];

  //MEMO(minami): Breadcrumbsコンポーネントに渡すパンくずをArray<{ text: string; path: string}> で生成する
  const createBreadcrumbs = (locationArray: Array<string>) => {
    locationArray.forEach((location) => {
      const breadcrumb = breadcrumbsList.find((breadcrumb) => breadcrumb.detectPath === location);
      breadcrumb && breadcrumbsArray.push({ text: breadcrumb.text, path: breadcrumb.path });
    });
    setBreadcrumbs(breadcrumbsArray);
  };

  useEffect(() => {
    createBreadcrumbs(locationArray);
  }, [location]);

  return breadcrumbs;
};
