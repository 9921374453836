import { useState, useEffect, useCallback } from 'react';
// import firebase from 'firebase';
import firebase from '@meettry/ui-components/utils/firebase';
import { useCollection } from 'react-firebase-hooks/firestore';
import useHooksMasterFlag from '@meettry/ui-components/hooks/useHooksMasterFlag';
import { setNotifications, useNotificationContext } from '@meettry/ui-components/contexts/notification.context';
import { Notification, NotificationExpansion, NotificationFieldValue } from '@meettry/ui-components/types/notification';
import { setChatMessageCounts, useChatContext } from '@meettry/ui-components/contexts/chat.context';
import { ChatMessageCounts } from '@meettry/ui-components/types/chat';

const doNothing = () => {};
const getUid = () => firebase.getCurrentUser()?.uid ?? null;

/**
 * useUnreadNotificationsQuery
 */
const useUnreadNotificationsQuery = (uid: string | null, isMaster: boolean) => {
  const [query, _setQuery] = useState(null);
  const setQuery = useCallback((v) => (isMaster ? _setQuery : doNothing)(v), [isMaster]);

  useEffect(() => {
    if (uid) {
      setQuery(
        firebase.db
          .collection('/notifications')
          .where('type', '==', 'chat')
          .where('isRead', '==', false)
          .where('userId', '==', uid)
          .orderBy('messageContent.createdAt', 'desc')
      );
    } else {
      setQuery(null);
    }
  }, [uid, setQuery]);

  return query;
};

const convertNotificationData = (notificationFieldValue: NotificationFieldValue): Notification => ({
  ...notificationFieldValue,
  messageContent: {
    ...notificationFieldValue.messageContent,
    createdAt: notificationFieldValue.messageContent.createdAt.toDate()
  }
});


/**
 * useChatNotifications
 */
const useChatNotifications = () => {
  const uid = getUid();
  const { state: chatState, dispatch: dispatchChatContext } = useChatContext();
  const { state: notificationsState, dispatch: dispatchNotificationContext } = useNotificationContext();
  const { notifications } = notificationsState;
  const isMaster = useHooksMasterFlag('useChatNotifications');

  const query = useUnreadNotificationsQuery(uid, isMaster);

  const [snapshot, loading, error] = useCollection(query);

  const setNotificationsToContext = useCallback((v: NotificationExpansion[]) => (isMaster ? dispatchNotificationContext(setNotifications(v)) : doNothing()), [
    isMaster,
    dispatchNotificationContext
  ]);

  const setCounts = useCallback((v) => (isMaster ? dispatchChatContext(setChatMessageCounts(v)) : doNothing()), [isMaster]);

  useEffect(() => {
    if (!loading && !error && snapshot) {
      const _notifications:NotificationExpansion[] = snapshot.docs.map((x) => ({
          id: x.id,
          ...convertNotificationData(<NotificationFieldValue>x.data()),
          _meta: {
            ref: x.ref,
            timeoutId: null
          }
      }));

      const countMessages: ChatMessageCounts = _notifications.reduce<ChatMessageCounts>((acc, x) => {
        if (!acc[x.roomId]) {
          acc[x.roomId] = 0;
        }
        acc[x.roomId] += 1;
        return acc;
      }, {});
      setCounts(countMessages);
      setNotificationsToContext(_notifications);
    }
  }, [snapshot, loading, error, setNotificationsToContext, setCounts]);

  const markAsRead = useCallback(
    async (messageId) => {
      const notification = notifications.find(
        // eslint-disable-next-line no-underscore-dangle
        (x) => x.messageId === messageId && !x.isRead && x._meta.timeoutId === null
      );

      if (notification) {
        // eslint-disable-next-line no-underscore-dangle
        const ntfMeta = notification._meta;

        // eslint-disable-next-line no-underscore-dangle
        ntfMeta.timeoutId = setTimeout(async () => {
          await ntfMeta.ref.update({
            isRead: true
          });
          notification.isRead = true;

          ntfMeta.timeoutId = null;
        }, 0);
      }
    },
    [notifications]
  );

  return {
    notifications,
    counts: chatState.chatMessageCounts,
    loading,
    error,
    markAsRead
  };
};

export default useChatNotifications;
