import React from 'react';
import styled from 'styled-components';
import SearchResult from '@meettry/engineer/components/organisms/SearchResult';

export default () => (
  <StyledContents>
    <StyledContentInner>
      <SearchResult />
    </StyledContentInner>
  </StyledContents>
);

const StyledContents = styled.div`
  && {
    padding: 50px;
    background-color: #f7f7f7;
    flex: 1;
  }
`;

const StyledContentInner = styled.div`
  && {
    max-width: 1200px;
    min-width: 900px;
    margin-left: auto;
    margin-right: auto;
  }
`;
