import React from 'react';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';

const UserProfileAvatar = () => {
  return (
    <div className="avatar-box">
      <CircleAvatar name="user_avatar" size="lg" />
    </div>
  );
};

export default UserProfileAvatar;
