import React from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { Scout } from '@meettry/ui-components/types/scout';
import { RadioButton } from '@meettry/ui-components/components/atoms/Input';
import Loader from '@meettry/ui-components/components/atoms/Loader';

type Props = {
  targetName?: string;
  scouts: Scout[];
  selectedScoutId: string;
  selectScout: (scoutId: string) => void;
  loading?: boolean;
};

/**
 * ScoutChatSelectBox
 */
const ScoutChatSelectBox: React.FC<Props> = (props) => {
  const { targetName = '', scouts, selectedScoutId, selectScout, loading = false } = props;

  return (
    <>
      <StyledTitle>
        {targetName}（スカウト対象者）さんにリンクするスカウトリストを選択する
      </StyledTitle>
      <StyledScoutChatSelectBox>
        {loading ? (
          <StyledLoader>
            <Loader />
          </StyledLoader>
        ) : (
          <StyledScoutList>
            <li>
              <RadioButton
                label="お気に入りのエンジニア"
                name={'select_scout'}
                value={'0'}
                checked={String(0) === String(selectedScoutId)}
                onChange={() => selectScout('0')}
              />
            </li>
            {scouts.map((scout) => (
              <li key={scout.id}>
                <RadioButton
                  label={scout.title}
                  name={'select_scout'}
                  value={scout.id}
                  checked={String(scout.id) === String(selectedScoutId)}
                  onChange={() => selectScout(scout.id)}
                />
              </li>
            ))}
          </StyledScoutList>
        )}
      </StyledScoutChatSelectBox>
    </>
  );
};
export default ScoutChatSelectBox;

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  line-height: 1.4;
`;

const StyledScoutChatSelectBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledScoutList = styled.ul`
  height: 400px;
  overflow-y: scroll;

  & > li + li {
    margin-top: 30px;
  }
`;

const StyledScoutLabel = styled.label``;

const StyledLoader = styled.div`
  display: flex;
  height: 400px;
  justify-content: center;
  align-items: center;
`;
