import {
  CREATE_SCOUT,
  DELETE_SCOUT,
  SEARCH_SCOUTS_QUERY,
  UPDATE_SCOUT
} from '@meettry/ui-components/queries';
import { useLazyQuery, useMutation, useQuery } from '@apollo/react-hooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Scout } from '@meettry/ui-components/types/scout';
import usePopup from '@meettry/ui-components/hooks/usePopup';

/**
 * Constants
 */
type Order = 'ASC' | 'DESC';
const DEFAULT_PAGE = 1;
const DEFAULT_VIEW_COUNT = 10;
export const SCOUT_LIST_FIELDS: any = {
  UPDATED: {
    key: 'ud',
    name: '更新日最新順',
    field: 'updated',
    order: 'DESC'
  },
  CREATED_DESC: {
    key: 'cd',
    name: '作成日最新順',
    field: 'created',
    order: 'DESC'
  }
};

/**
 * useScoutList
 * スカウト一覧の管理
 */
const useScoutList = () => {
  /**
   * State
   */
  const [paging, setPaging] = useState(DEFAULT_PAGE);
  const [offset, setOffset] = useState(DEFAULT_VIEW_COUNT);
  const [orders, setOrders] = useState(SCOUT_LIST_FIELDS.UPDATED);
  const [searchText, setSearchText] = useState('');
  const [includeInactive, setIncludeInactive] = useState(false);

  /**
   * Query
   */
  const [searchScoutListQuery, { data, loading, error, refetch }] = useLazyQuery(
    SEARCH_SCOUTS_QUERY,
    {
      fetchPolicy: 'no-cache' // MEMO(oomura): ページ遷移して戻ってくると undefined になるから no-cacheを設定
    }
  );

  /**
   * Mutation
   */
  const [
    mutateCreateScout,
    { data: createScoutData, loading: createScoutLoading, error: createScoutError }
  ] = useMutation(CREATE_SCOUT);
  const [
    mutateUpdateScout,
    { data: updateScoutData, loading: updateScoutLoading, error: updateScoutError }
  ] = useMutation(UPDATE_SCOUT);
  const [
    mutateDeleteScout,
    { data: deleteScoutData, loading: deleteScoutLoading, error: deleteScoutError }
  ] = useMutation(DELETE_SCOUT);

  /**
   * Custom Hook
   */
  const { showSuccessPopup, showErrorPopup } = usePopup();

  /**
   * Variables
   */
  const allBookmarkCount = data?.loginSession?.user?.organization?.bookmarks?.users?.count ?? 0;
  const scoutList: Scout[] = useMemo(
    () => data?.loginSession?.user?.organization?.scouts?.results ?? [],
    [data, loading]
  );
  const scoutCount: number = useMemo(
    () => data?.loginSession?.user?.organization?.scouts?.count ?? 0,
    [data, loading]
  );
  const matchedCounts: { name: string; count: number }[] = useMemo(() => {
    const scoutUsers = data?.loginSession?.user?.organization?.scoutUsers ?? null;
    return [
      { name: '条件に合ったエンジニア', count: scoutUsers?.matched?.count ?? 0 },
      { name: 'お気に入りのエンジニア', count: scoutUsers?.matchedInBookmarks?.count ?? 0 },
      { name: 'スカウトメール送信済', count: scoutUsers?.chatSent?.count ?? 0 },
      { name: 'スカウトメール返信有', count: scoutUsers?.chatReplied.count ?? 0 }
    ];
  }, [data, loading]);

  /**
   * Func: changeOrder
   */
  const changeOrder = useCallback(
    (key: string) => {
      const orderType = Object.keys(SCOUT_LIST_FIELDS).find(
        (k) => SCOUT_LIST_FIELDS[k].key === key
      );
      setOrders(orderType ? SCOUT_LIST_FIELDS[orderType] : SCOUT_LIST_FIELDS.UPDATED);
    },
    [setOrders]
  );

  /**
   * Func: inactiveScout
   */
  const updateActiveScout = (scout: Scout) => {
    const {
      id,
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive,
      scoutTags
    } = scout;
    const updateModel = {
      id,
      title,
      comment,
      recruitmentStartAt,
      recruitmentEndAt,
      recruitmentCount,
      isActive: isActive === 1 ? 0 : 1,
      scoutTags: scoutTags.map((tag) => ({
        isRequired: tag.isRequired,
        years: tag.years,
        tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
      }))
    };
    mutateUpdateScout({ variables: { input: updateModel } });
  };

  /**
   * 検索条件が変更されたら、再びスカウトリストを更新
   */
  useEffect(() => {
    searchScoutListQuery({
      variables: {
        first: DEFAULT_VIEW_COUNT,
        offset: Number(offset) * (Number(paging) - 1),
        field: orders.field,
        order: orders.order as Order,
        includeInactive: includeInactive,
        searchText: searchText
      }
    });
  }, [orders, paging, includeInactive, searchText]);

  /**
   * スカウト作成した結果
   */
  useEffect(() => {
    if (!createScoutLoading && createScoutError) {
      showErrorPopup('スカウトを作成できませんでした');
    } else if (!createScoutLoading && createScoutData?.createScout?.scout) {
      showSuccessPopup('スカウトを作成しました');
    }
  }, [createScoutLoading, createScoutError]);

  /**
   * スカウト情報を変更（募集終了 / 募集再開）した結果
   */
  useEffect(() => {
    if (!updateScoutLoading && updateScoutData?.updateScout?.scout?.isActive === 1) {
      showSuccessPopup('スカウト募集を再開しました');
      refetch();
    } else if (!updateScoutLoading && updateScoutData?.updateScout?.scout?.isActive === 0) {
      showSuccessPopup('スカウト募集を終了しました');
      refetch();
    } else if (!updateScoutLoading && updateScoutError) {
      showErrorPopup('スカウト情報を変更できませんでした');
    }
  }, [updateScoutData, updateScoutLoading, updateScoutError]);

  /**
   * スカウトを削除した結果
   */
  useEffect(() => {
    if (!deleteScoutLoading && deleteScoutError) {
      showErrorPopup('スカウトを削除できませんでした');
    } else if (!deleteScoutLoading && deleteScoutData?.deleteScout?.id) {
      showSuccessPopup('スカウトを削除しました');
      refetch();
    }
  }, [deleteScoutLoading, deleteScoutError, refetch]);

  return {
    scoutList,
    scoutCount,
    matchedCounts,
    loading,
    error,
    paging,
    setPaging,
    offset,
    orders,
    changeOrder,
    searchText,
    setSearchText,
    includeInactive,
    setIncludeInactive,
    mutateCreateScout,
    createScoutData,
    createScoutLoading,
    mutateDeleteScout,
    deleteScoutLoading,
    deleteScoutData,
    allBookmarkCount,
    updateActiveScout
  };
};
export default useScoutList;
