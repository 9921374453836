import React from 'react';
import { map } from 'lodash';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

const BreadcrumbsItem = ({ item }) => {
  const { text, path } = item;
  return (
    <StyledBreadcrumbsItem>
      <Link to={path}>{text}</Link>
    </StyledBreadcrumbsItem>
  );
};

const Breadcrumbs = (props) => {
  const { items, className } = props;
  return (
    <div className={className}>
      <StyledBreadcrumbs>
        {map(items, (item, i) => (
          <BreadcrumbsItem item={item} key={i} />
        ))}
      </StyledBreadcrumbs>
    </div>
  );
};

const StyledBreadcrumbsItem = styled.li`
  && {
    & + * {
      margin-left: 2px;
      display: flex;
      align-items: center;
    }
    & + *:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
      border: solid #0fafa9;
      border-width: 1px 1px 0 0;
      transform: rotate(45deg);
    }
    &:not(:first-child) + *:before {
      border: solid #999999;
      border-width: 1px 1px 0 0;
    }
    a {
      font-size: 12px;
      color: #0fafa9;
      align-items: center;
      text-decoration: none;
    }
    &:not(:first-child) a {
      color: #999999;
      padding-left: 5px;
    }
  }
`;
const StyledBreadcrumbs = styled.ol`
  && {
    max-width: 1200px;
    margin: 0 auto;
    padding: 10px 50px 0 50px;
    display: flex;
    ${mediaQuery.lessThan('breakPoint')`
        padding: 10px 40px 0;
        `}
  }
`;
// const StyledBreadcrumbsWrapper = styled.div`
//   && {
//   }
// `;

export default Breadcrumbs;
