import React from 'react';
import styled from 'styled-components';

const GoogleForm = () => {
  return (
    <StyledIframe
      src="https://docs.google.com/forms/d/e/1FAIpQLSf45tXhZkOA5ce1OqJ8TviXq0ewWAelztbSLx-QFlK9uoR-NA/viewform?embedded=true"
      frameBorder="0"
      marginHeight={0}
      marginWidth={0}
    >
      読み込んでいます…
    </StyledIframe>
  );
};

export default GoogleForm;

const StyledIframe = styled.iframe`
  width: 100%;
  height: calc(100vh - 96px);
`;
