import React from 'react';
import styled, { css } from 'styled-components';

export const TextField = (props) => {
  const {
    type = 'text',
    onChange,
    placeholder,
    defaultValue,
    error,
    className,
    ...restProps
  } = props;
  return (
    <StyledTextfield
      type={type}
      className={className}
      onChange={onChange}
      placeholder={placeholder}
      defaultValue={defaultValue}
      error={error}
      {...restProps}
    />
  );
};

export default TextField;

const StyledTextfield = styled.input`
  width: 100%;
  max-height: 48px;
  font-size: 16px;
  padding: 16px 20px;
  background-color: #f7f7f7;
  border: #eeeeee solid 1px;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid #0fafa9;
    border-radius: 4px;
  }
  ${({ error }) =>
    error &&
    css`
      background-color: #fee8eb;
      border: 1px solid #f4233c;
      :focus {
        outline: none;
        border: 1px solid #f4233c;
      }
    `}
`;
