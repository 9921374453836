import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';

/**
 * ScoutCommentBox Component
 * スカウトでユーザーに対しての申し送りコメントを入力するコンポーネント
 */
type Props = {
  text: string;
  setText: (text: string) => void;
};
const ScoutCommentBox: React.FC<Props> = (props) => {
  const { text, setText } = props;
  const ref = useRef<HTMLTextAreaElement>(null);

  return (
    <StyledScoutCommentBox>
      <StyledScoutCommentContent>
        <StyledScoutCommentTitle>コメント</StyledScoutCommentTitle>
        <StyledScoutCommentTextArea
          value={text}
          ref={ref}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) => setText(event.target.value)}
        />
      </StyledScoutCommentContent>
    </StyledScoutCommentBox>
  );
};
export default ScoutCommentBox;

const StyledScoutCommentBox = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
`;

const StyledScoutCommentContent = styled.label`
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  padding-top: 30px;
  padding-bottom: 30px;
`;

const StyledScoutCommentTitle = styled.span`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 16px;
  font-family: ${FONT_FAMILY.COMMON};
  font-weight: 500;
  min-width: 110px;
`;

const StyledScoutCommentTextArea = styled.textarea`
  padding: 20px;
  box-sizing: border-box;
  width: 550px;
  height: 170px;
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  resize: none;
  outline: none;
  &:focus {
    border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
  &::placeholder {
    color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  }
`;
