import React, { useState, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Formatter from '@meettry/company/utils/format';
import { useLazyQuery } from '@apollo/react-hooks';
import { GET_BILLINGS } from '@meettry/ui-components/queries/corporateInfo';
import { Calendar } from '@meettry/ui-components/components/atoms/Calendar';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import {
  Pagination,
  DEFAULT_PAGINATION_DISPLAY_ITEM
} from '@meettry/company/component/molecules/pagination';
import SearchNoResult from '@meettry/ui-components/components/atoms/SearchNoResult';
/* TODO(minami): β版以降での実装
import { Modal } from '@meettry/company/component/organisms/modal';
import { BillModal } from '@meettry/company/component/organisms/billModal';*/

const tableHeading = [
  { type: 'date', text: '利用月' },
  { type: 'content', text: '契約内容' },
  { type: 'period', text: '対象期間' },
  { type: 'price', text: '請求額' }
  // TODO(minami): β版以降での実装{ type: 'pdf', text: 'pdf' }
];

export const BillList: React.FC = () => {
  const [current, setCurrent] = useState(1);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [getBillings, { data, loading, error }] = useLazyQuery(GET_BILLINGS);

  useEffect(() => {
    getBillings({
      variables: {
        page: {
          first: 10,
          offset: current - 1
        }
      }
    });
  }, []);

  const created = useMemo(() => {
    const createdDate = new Date(data?.loginSession?.user?.organization?.created ?? '');
    return new Date(createdDate.getFullYear(), createdDate.getMonth() - 1, createdDate.getDate());
  }, [data]);
  const currentPlan = useMemo(
    () => data?.loginSession?.user?.organization?.currentPlan?.name ?? null,
    [data]
  );
  const billingsLength = useMemo(
    () => data?.loginSession?.user?.organization?.payment?.billings?.count ?? null,
    [data]
  );
  const billings = useMemo(
    () => data?.loginSession?.user?.organization?.payment?.billings?.results ?? null,
    [data]
  );

  const billingSearch = () => {
    getBillings({
      variables: {
        filter: {
          fromYearMonth: Number(Formatter.convertDate(startDate, 'YYYYMM')),
          toYearMonth: Number(Formatter.convertDate(endDate, 'YYYYMM'))
        },
        page: {
          first: 10,
          offset: current - 1
        }
      }
    });
  };

  /* TODO(minami): ベータ版以降での実装
  const [show, displayModal] = useState(false);
  const billModal = tableContent[2].confirm ? (
    <div
      onClick={() => {
        displayModal(true);
      }}
    >
      {tableContent[2].hasBill}
    </div>
  ) : (
    tableContent[2].hasBill
   ); */
  return (
    <div>
      <StyledBillListContents>
        <Calendar
          labelText="絞り込み検索"
          startDate={startDate}
          setStartDate={setStartDate}
          endDate={endDate}
          setEndDate={setEndDate}
          showBtn={true}
          onClick={billingSearch}
          created={new Date(created)}
        />
      </StyledBillListContents>
      {loading ? (
        <Loader />
      ) : (
        <StyledBillListTable>
          {!!billings ? (
            <>
              <thead>
                <tr>
                  {tableHeading.map((item, index) => (
                    <StyledBillListTableHeading key={index}>{item.text}</StyledBillListTableHeading>
                  ))}
                </tr>
              </thead>
              <tbody>
                {billings.map((item: any, index: number) => (
                  <tr key={index}>
                    <StyledBillListTableData>
                      {Formatter.convertDate(Formatter.insertHyphen(item.yearMonth), 'YYYY/MM')}
                    </StyledBillListTableData>
                    <StyledBillListTableData>{currentPlan}プラン</StyledBillListTableData>
                    <StyledBillListTableData>
                      {Formatter.convertDate(Formatter.insertHyphen(item.yearMonth), 'YYYY/MM/DD')}
                      〜{Formatter.convertEndDate(Formatter.insertHyphen(item.yearMonth), 'MM/DD')}
                    </StyledBillListTableData>
                    <StyledBillListTableData>
                      {Formatter.insertComma(item.subtotalAmount, '')}
                    </StyledBillListTableData>
                    {/* TODO(minami): β版以降での実装
              <td data-confirm={item.hasBill}>
                {item.confirm ? (
                  <div
                    onClick={() => {
                      displayModal(true);
                    }}
                  >
                    {item.hasBill}
                  </div>
                ) : (
                  item.hasBill
                )}
              </td> */}
                  </tr>
                ))}
              </tbody>
            </>
          ) : null}
        </StyledBillListTable>
      )}
      {error ? <SearchNoResult /> : null}
      {billingsLength > 10 ? (
        <Pagination
          maxLength={8}
          current={current}
          setCurrent={setCurrent}
          displayNumber={DEFAULT_PAGINATION_DISPLAY_ITEM}
          marginTop={30}
        />
      ) : null}
      {/* TODO(minami): β版以降でコメントアウト解除
      <Modal show={show} displayModal={displayModal} title="" cls="bill-modal">
        <BillModal />
      </Modal> */}
    </div>
  );
};

const StyledBillListContents = styled.div`
  margin-top: 34px;
`;

const StyledTable = StyledBillListContents.withComponent('table');
const StyledBillListTable = styled(StyledTable)`
  width: 100%;
  max-width: 702px;
  margin-left: auto;
  margin-right: auto;
`;

const StyledBillListTableHeading = styled.th`
  height: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  text-align: center;
  vertical-align: middle;
  height: 40px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  font-weight: normal;
`;

const StyledBillListTableData = styled.td`
  height: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  text-align: center;
  vertical-align: middle;
`;
