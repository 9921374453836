import React from 'react';
import { TextField } from '@meettry/engineer/components/atoms/Input';

export const InputAddress = (props) => {
  const { userDetail, handleChangeUserDetail } = props;
  return (
    <TextField
      type="text"
      defaultValue={userDetail.address}
      onChange={handleChangeUserDetail('address')}
      placeholder="東京都渋谷区渋谷 000"
    />
  );
};
