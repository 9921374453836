import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { StyledContentsWrapper } from '@meettry/engineer/components/pages/EnterpriseDashboard';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import useBookmark from '@meettry/ui-components/hooks/useBookmark';
import ScoutUserList from '@meettry/ui-components/components/organisms/CassetteScoutUser';
import { Pagination } from '@meettry/ui-components/components/molecules/Pagination';
import { useHistory } from 'react-router-dom';
import useScoutComment from '@meettry/ui-components/hooks/useScoutComment';
import { ScoutUser, ScoutUserWithComment } from '@meettry/ui-components/types/scout';
import ScoutCommentBox from '@meettry/ui-components/components/molecules/ScoutCommentBox';
import { EditModal } from '@meettry/ui-components/components/organisms/Modal';

const DEFAULT_PAGE = 1;
const DEFAULT_VIEW_COUNT = 10;

type ModalType = 'addScoutComment' | '';

/**
 * EnterpriseScoutBookmark Page
 * スカウト詳細画面の「お気に入りされたエンジニアリスト」版
 * スカウトに紐づいていないため、スカウト情報は載せない
 */
const EnterpriseScoutBookmark: React.FC = () => {
  const history = useHistory();
  /**
   * State
   */
  const [paging, setPaging] = useState(DEFAULT_PAGE);
  const [offset, setOffset] = useState(DEFAULT_VIEW_COUNT);
  const [showModal, setShowModal] = useState<ModalType>('');

  /**
   * Custom Hook
   */
  const {
    fetchBookmarkUsers,
    bookmarkUsers,
    bookmarkUserCount,
    bookmarkUsersLoading,
    toggleBookmark,
    bookmarkLoading,
    newBookmarkUserIds,
    newUnBookmarkUserIds
  } = useBookmark();
  const {
    comments,
    fetchScoutComments,
    mutateAddScoutComment,
    addScoutCommentLoading,
    tempComment,
    setTempComment,
    newAddComments
  } = useScoutComment();

  /**
   *  goToChatPage
   *  チャットページへ飛ぶ
   */
  const goToChatPage = (scoutRoomId: string | null, userId: string, nickname: string) => {
    if (scoutRoomId) {
      history.push(`/enterprise/chat/${scoutRoomId}`);
    } else {
      history.push(`/enterprise/chat`, { userId, nickname });
    }
  };

  /**
   * ブックマークユーザーの取得（ページネーションによる変更）
   */
  useEffect(() => {
    fetchBookmarkUsers({
      variables: {
        page: { first: DEFAULT_VIEW_COUNT, offset: Number(offset) * (Number(paging) - 1) },
        orders: []
      }
    });
  }, [paging, offset]);

  /**
   * ユーザー郡を取得したら、それらのidからcommentを取得
   */
  useEffect(() => {
    const ids = bookmarkUsers.map((user: any) => user.id);
    if (ids.length === 0) return;
    fetchScoutComments({ variables: { userIds: ids } });
  }, [bookmarkUsers]);

  /**
   * 新しくコメントしたらモーダルを消す
   */
  useEffect(() => {
    setShowModal('');
  }, [newAddComments, setShowModal]);

  /**
   * Variables
   */
  // commentとbookmarkの情報をbookmarkUserにマージ
  const scoutUsers = bookmarkUsers.map(
    (user: ScoutUser): ScoutUserWithComment => ({
      ...user,
      isBookmarked: (() => {
        if (newBookmarkUserIds.includes(user.id)) return true;
        else if (newUnBookmarkUserIds.includes(user.id)) return false;
        else return user.isBookmarked;
      })(),
      comments: comments.filter((c) => c.targetUserId === parseInt(user.id, 10))
    })
  );

  return (
    <StyledContentsWrapper>
      <StyledEngineerListWrapper>
        <StyledEngineerListTitle>
          <StyledIcon iconName="resume" iconColor="black" width="18px" />
          スカウト
        </StyledEngineerListTitle>
        <StyledBookmarkTitle>お気に入りのエンジニア</StyledBookmarkTitle>
        <StyledEngineerListContainer>
          {bookmarkUsersLoading ? (
            <StyledLoader>
              <Loader />
            </StyledLoader>
          ) : (
            <>
              <StyledEngineerList>
                <ScoutUserList
                  scoutUsers={scoutUsers ?? []}
                  addComment={(userId) => {
                    setTempComment({ userId, text: '' });
                    setShowModal('addScoutComment');
                  }}
                  sendMessage={goToChatPage}
                  toggleBookmark={toggleBookmark}
                  enabledBookmark={!bookmarkLoading}
                />
              </StyledEngineerList>
              {bookmarkUserCount > 0 && (
                <Pagination
                  maxCount={bookmarkUserCount}
                  viewCount={offset}
                  currentPage={paging}
                  setCurrentPage={setPaging}
                  marginTop={30}
                />
              )}
            </>
          )}
        </StyledEngineerListContainer>
      </StyledEngineerListWrapper>
      <EditModal
        title={['コメント追加']}
        show={showModal === 'addScoutComment'}
        displayModal={(flag) => setShowModal(flag ? 'addScoutComment' : '')}
        okClick={() => {
          if (!!tempComment.text) {
            mutateAddScoutComment();
            setShowModal('');
          }
        }}
        okClickText="コメントする"
        disabledOkButton={addScoutCommentLoading}
        cancelClickText="キャンセル"
        cancelClick={() => {
          setShowModal('');
        }}
        disabledCancelButton={addScoutCommentLoading}
        buttonWidth="140"
      >
        <ScoutCommentBox
          text={tempComment.text}
          setText={(text) => setTempComment({ ...tempComment, text })}
        />
      </EditModal>
    </StyledContentsWrapper>
  );
};
export default EnterpriseScoutBookmark;

// engineer-list-wrapper
const StyledEngineerListWrapper = styled.div`
  font-family: ${FONT_FAMILY.COMMON};
`;

const StyledEngineerListTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  display: flex;
  align-items: center;
`;

const StyledEngineerListContainer = styled.div`
  margin-top: 20px;
`;

const StyledIcon = styled(Icon)`
  display: inline-block;
  margin-right: 5px;
`;

const StyledLoader = styled.div`
  padding-top: 50px;
  padding-bottom: 50px;
`;

const StyledEngineerList = styled.div`
  margin-top: 20px;
`;

const StyledBookmarkTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  margin-top: 30px;
`;
