import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import BubbleChart from '@meettry/ui-components/components/molecules/BubbleChart';
import { useTagFilter, useEngineer } from '@meettry/ui-components/hooks/useUserPage';

type UserTechStackListType = {
  techStack: {
    name: string;
    categoryId: number;
    image: {
      url: string;
    };
  };
  days: number;
};

type TechStackType = {
  userTechStackList: Array<UserTechStackListType>;
  techStackCategoryList: Array<{ id: string; color: string; name: string }>;
};

type StacksType = {
  data: Array<{
    name: string;
    categoryId: number;
    image: {
      url: string;
    };
    radius: number;
  } | null>;
  categories: {
    [key: string]: {
      id?: string;
      color?: string;
      name?: string;
    };
  };
};

const TechStack: React.FC<TechStackType> = (props) => {
  const { userTechStackList, techStackCategoryList } = props;
  const history = useHistory();
  const {
    selectedTechStackSummaryTags,
    addSelectedTechStackSummaryTags,
    removeSelectedTechStackSummaryTags
  } = useTagFilter();
  const {
    state: { nickname }
  } = useEngineer();
  const [stacks, setStacks] = useState<StacksType | null>(null);

  useEffect(() => {
    if (userTechStackList && techStackCategoryList) {
      const maxDays = userTechStackList.reduce((prev, { days }) => (prev < days ? days : prev), 0);
      const list = userTechStackList
        .map((x) => {
          const radius = (x.days / maxDays) * 60;
          if (radius < 5) {
            return null;
          }
          return {
            name: x.techStack.name,
            categoryId: x.techStack.categoryId,
            image: {
              url: x.techStack.image?.url ?? null
            },
            radius: (x.days / maxDays) * 60
          };
        })
        .filter((x) => x);
      const categories = techStackCategoryList.reduce((acc, x) => ({ ...acc, [x.id]: x }), {});

      setStacks({
        data: list,
        categories
      });
    }
  }, [userTechStackList, techStackCategoryList]);

  const onClickNode = (name: string) => {
    const isSelected = selectedTechStackSummaryTags.includes(name);
    if (isSelected) {
      removeSelectedTechStackSummaryTags(name);
      return;
    }
    addSelectedTechStackSummaryTags(name);
    history.push(`/user/${nickname}/resume`);
  };

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <StyledContainer>
        <BubbleChart onClickNode={onClickNode} stacks={stacks || { data: [], categories: {} }} />
        <StyledLegend>
          {stacks &&
            stacks.categories &&
            Object.keys(stacks.categories).map((key) => (
              <StyledLegendItem key={key}>
                <StyledLegendColor style={{ backgroundColor: stacks.categories[key].color }} />
                <div>{stacks.categories[key].name}</div>
              </StyledLegendItem>
            ))}
        </StyledLegend>
      </StyledContainer>
    </div>
  );
};

export default TechStack;

TechStack.propTypes = {
  userTechStackList: PropTypes.arrayOf(PropTypes.any).isRequired,
  techStackCategoryList: PropTypes.arrayOf(PropTypes.any).isRequired
};

const StyledContainer = styled.div`
  position: relative;
  user-select: none;
  width: 100%;
  height: 100%;
`;
const StyledLegend = styled.ul`
  position: absolute;
  left: 15px;
  top: 15px;
  background: rgba(255, 255, 255, 0.6);
  padding: 5px;
  border-radius: 5px;
  & > * + * {
    margin-top: 8px;
  }
`;
const StyledLegendItem = styled.li`
  display: flex;
  font-size: 10px;
  & > * + * {
    margin-left: 5px;
    display: flex;
    align-items: center;
  }
`;
const StyledLegendColor = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
`;
