import React from 'react';
import { useHistory } from 'react-router-dom';
import { Button, ConnectionButton } from '@meettry/company/component/atoms/btn';

const UserProfileBottom = () => {
  const history = useHistory();

  return (
    <div className="user-profile-bottom">
      <div className="user-profile-content">
        <span className="bottom-title">Organization</span>
        <div className="organization-wrap">
          {Array.from({ length: 3 }, (_, i) => (
            <span key={i} />
          ))}
        </div>
      </div>
      <div className="user-profile-content">
        <span className="bottom-title">Connection</span>
        <div className="connection-wrap">
          <Button
            cls="btn second connection-btn"
            buttonText="フォロワー"
            onClick={() => {
              history.push('/follow/followed');
            }}
          />
          <ConnectionButton
            cls="btn second connection-following-btn"
            buttonText="フォロー中"
            folowCount={823}
            onClick={() => {
              history.push('/follow/following');
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default UserProfileBottom;
