import React from 'react';
import styled from 'styled-components';

import KeyVisual from '@meettry/engineer/images/kv.png';

import TopMain from '@meettry/ui-components/components/organisms/Tops/TopMainVisual';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

const topMainMessages = ['エンジニアが挑戦できる', '企業や仲間と出会える', 'レジュメコミュニティ'];
const TopMainVisual = () => {
  return (
    <TopMain
      messages={topMainMessages}
      registerBtnMessage="ユーザー登録はこちら"
      registerBtnLocate="/signup"
      bgColor="#dbf3f2"
      keyVisual={<TopKeyVisual src={KeyVisual} />}
    />
  );
};

export default TopMainVisual;

const TopKeyVisual = styled.img`
  left: 50%;
  ${mediaQuery.lessThan('breakPoint')`
  width: 100vw;
  padding-left: 40px;
`};
`;
