import React from 'react';
import { Textarea } from '@meettry/engineer/components/atoms/Input';

export const InputBio = (props) => {
  const { user, handleChangeUser, size, error } = props;
  return (
    <Textarea
      onChange={handleChangeUser('bio')}
      placeholder="某メガベンチャー企業にてサーバーサイドを4年ほど経験。フロントエンドに興味があり、フルスタックを目標にしています。来年までにサービスを一つリリースする事にチャレンジ中！"
      defaultValue={user.bio}
      error={error}
      size={size}
    />
  );
};
