import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { getPathArray } from '@meettry/engineer/utils';
import { SEARCH_TYPE } from '@meettry/engineer/utils/Constant';
import { useSearchQuery } from '@meettry/engineer/components/organisms/SearchResult/SearchResult';
import Breadcrumbs from '@meettry/engineer/components/atoms/Breadcrumbs';
import CassetteUser from '@meettry/engineer/components/organisms/SearchResult/SearchResult/CassetteUser';
import CassetteResume from '@meettry/engineer/components/organisms/SearchResult/SearchResult/CassetteResume';
import CassetteChallenge from '@meettry/engineer/components/organisms/SearchResult/SearchResult/CassetteChallenge';
import iconPathNoData from '@meettry/engineer/images/icon-search-nodata.png';
import SearchResult from '@meettry/ui-components/components/organisms/SearchResult';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const TAB_SETTINGS = {
  USER: {
    type: SEARCH_TYPE.USER,
    label: 'ユーザー',
    component: CassetteUser,
    order: 0
  },
  RESUME: {
    type: SEARCH_TYPE.RESUME,
    label: '経歴',
    component: CassetteResume,
    order: 1
  },
  CHALLENGE: {
    type: SEARCH_TYPE.CHALLENGE,
    label: 'チャレンジ',
    component: CassetteChallenge,
    order: 2
  }
};

const TAB_NAMES = Object.keys(TAB_SETTINGS).sort(
  (a, b) => TAB_SETTINGS[a].order - TAB_SETTINGS[b].order
);

const SearchResultForm = (props) => {
  const { loginUserFollowee, loadingFollowee, onClickUserFollow, onClickUserChat } = props;
  const history = useHistory();
  const location = useLocation();
  const {
    queryString,
    type,
    data: searchData,
    loading,
    refreshQueryParams,
    sortType,
    onChangeSort
  } = useSearchQuery(location, history);
  const {
    user: { nickname: loginNickname, organizationId }
  } = useLoginUser();
  const searchCategory = TAB_NAMES.map((name) => {
    const tab = TAB_SETTINGS[name];
    return {
      type: tab.type,
      label: tab.label,
      count: searchData ? searchData['searchFor'][`${tab.type}s`]['count'] : 0
    };
  });

  const handleChangeTab = (type) => {
    refreshQueryParams({ type, page: 1 });
  };

  const searchResults = {
    component: TAB_SETTINGS[type.toUpperCase()].component,
    data: (searchData && searchData['searchFor'][`${type}s`]['results']) || [],
    count: searchData ? searchData['searchFor'][`${type}s`]['count'] : 0
  };

  const pathArray = getPathArray(history.location.pathname, loginNickname);

  const SearchResultContent = () => {
    return (
      <>
        {!(loading || loadingFollowee) && !!searchResults.data.length && (
          <StyledSearchResultList>
            {searchResults.data.map((item) => {
              const { component: ListItem } = searchResults;
              const isUserFollowed = loginUserFollowee.includes(item.nickname);
              return (
                <li key={item.id}>
                  <ListItem
                    data={item}
                    searchWord={queryString}
                    onClickChat={onClickUserChat}
                    onClickFollow={onClickUserFollow}
                    isLoginUserCompany={!!organizationId}
                    isUserFollowed={isUserFollowed}
                  />
                </li>
              );
            })}
          </StyledSearchResultList>
        )}
        {!loading && searchResults.data.length === 0 && (
          <StyledSearchNoData>
            <img src={iconPathNoData} width="60" alt="NO DATA" />
            <p>条件に一致する検索結果はありません</p>
            <p>キーワードを確認・変更して検索し直してください。</p>
          </StyledSearchNoData>
        )}
      </>
    );
  };

  return (
    <>
      <StyledBreadcrumbs items={pathArray} />
      <SearchResult
        searchCategory={searchCategory}
        type={type}
        handleChangeTab={handleChangeTab}
        queryString={queryString}
        sortType={sortType}
        onChangeSort={onChangeSort}
        isLoading={loading || loadingFollowee}
        onPaginationClick={(i) => {
          refreshQueryParams({ page: i });
          window.scrollTo(0, 0);
        }}
        searchResultsCount={searchResults.count}
        SearchResult={<SearchResultContent />}
      />
    </>
  );
};

export default SearchResultForm;

const StyledBreadcrumbs = styled(Breadcrumbs)`
  position: relative;
  width: 100%;
  top: -50px;
  left: -50px;
`;

const StyledSearchResultList = styled.ul`
  cursor: pointer;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  margin-top: 10px;

  & > li {
    transition: background 0.15s;
  }

  & > li:hover {
    background-color: ${COLOR_DEFINITIONS.HOVER.OUTLINED};
  }

  & > li + li {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledSearchNoData = styled.div`
  padding: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
  margin-top: 40px;

  & > *:nth-child(2) {
    font-size: 20px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    font-weight: bold;
    margin-top: 30px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    margin-top: 20px;
  }
`;
