import React, { useState } from 'react';
import * as Formatter from '@meettry/company/utils/format';
import { BillDestination } from '@meettry/company/component/organisms/billDestination';
import { BillList } from '@meettry/company/component/organisms/billList';
import { ChangeTab } from '@meettry/company/component/organisms/changeTab';

const tabData = [
  { text: '請求書一覧', type: 'bill-list', component: <BillList></BillList> },
  {
    text: '請求書送付先',
    type: 'sending-bill-destination',
    component: <BillDestination></BillDestination>
  }
];

const estimatePrice = {
  type: '請求予定額',
  price: 12000
};

const breakDown = [
  { type: 'スカウト課金', detail: '単価×数', price: 0 },
  { type: '月額利用料', detail: 'プラン名が入ります', price: 0 }
];

const EstimatePrice: React.FC = () => {
  return (
    <div className="estimate-price">
      <span>{estimatePrice.type}</span>
      <span>{Formatter.insertComma(estimatePrice.price, 'yen')}</span>
    </div>
  );
};

const BreakDown: React.FC = () => {
  return (
    <table className="breakdown-table">
      <tbody>
        {breakDown.map((item, index) => (
          <tr key={index}>
            <td>{item.type}</td>
            <td>{item.detail}</td>
            <td>{Formatter.insertComma(item.price, 'yen')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};

const Price: React.FC = () => {
  return (
    <div className="price-container">
      <EstimatePrice></EstimatePrice>
      <BreakDown></BreakDown>
    </div>
  );
};

const BillSettlement: React.FC = () => {
  const [currentTabType, setCurrentTabType] = useState(tabData[0].type);
  const findBillContent = tabData.find((item) => currentTabType === item.type);
  const price = findBillContent && findBillContent.type === 'bill-list' ? <Price></Price> : null;
  return (
    <div className="setting-container bill-settlement">
      {price}
      <h2>請求書・決済</h2>
      <div className="bill-settlement-contents">
        <ChangeTab active={currentTabType} setCurrent={setCurrentTabType} tab={tabData}></ChangeTab>
        {!!findBillContent ? findBillContent.component : null}
      </div>
    </div>
  );
};

export default BillSettlement;
