import { useEffect, useState } from 'react';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useChatContext, updateChatRooms } from '@meettry/ui-components/contexts/chat.context';
import {
  ChatRoom,
  ChatRoomFieldType,
  ChatRoomWithMessages
} from '@meettry/ui-components/types/chat';

/**
 * firestoreからのデータをTypeScriptで扱えるように変換
 * @param chatRoom
 */
const convertChatRoomResponse = (chatRoom: ChatRoomFieldType): ChatRoom => ({
  ...chatRoom,
  createdAt: chatRoom?.createdAt?.toDate() ?? null,
  updatedAt: chatRoom?.updatedAt?.toDate() ?? null
});

/**
 * buildRoomsQuery
 */
const buildRoomsQuery = (fb: any, uid: string) =>
  fb.db.collection('/rooms').where(`members.${uid}`, '==', true);

/**
 * useChatRooms
 */
const useChatRooms = (
  fb: any,
  uid: string
): [ChatRoomWithMessages[], boolean, Error | undefined] => {
  const [query, setQuery] = useState(buildRoomsQuery(fb, uid));
  const { state, dispatch } = useChatContext();

  /**
   * チャットルームのsubscribe
   */
  const [snapshot, loading, error] = useCollection(query);

  /**
   * uidの変更でqueryを発行
   */
  useEffect(() => {
    setQuery(buildRoomsQuery(fb, uid));
  }, [uid]);

  /**
   * チャットルームの更新
   */
  useEffect(() => {
    if (snapshot && !snapshot.empty) {
      const chatRooms: ChatRoom[] = snapshot.docs.map((doc) => {
        return convertChatRoomResponse({ ...(<ChatRoomFieldType>doc.data()), id: doc.id });
      });
      dispatch(updateChatRooms(chatRooms));
    }
  }, [snapshot]);

  return [state.chatRooms, loading, error];
};
export default useChatRooms;
