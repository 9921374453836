import React, { useState, useEffect, FormEvent } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/react-hooks';
import styled from 'styled-components';
import { getErrorCodes, getErrorPhrase, checkCharacterType } from '@meettry/engineer/utils';
import auth, { LoginStatus, useLoginUser } from '@meettry/ui-components/utils/auth';
import { VALIDATION, NICKNAME_INPUT } from '@meettry/engineer/utils/Constant';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import { SIGNUP_NICKNAME_QUERY } from '@meettry/ui-components/queries/signup';
import NoticeFormTemplate from '@meettry/engineer/components/molecules/Template/NoticeFormTemplate';
import { PrimaryButton } from '@meettry/engineer/components/atoms/Button';
import { BANNED_WORD_LIST } from '@meettry/ui-components/constants';
import { GraphQLError } from 'graphql';

const NOTICE_PHRASE = {
  complete: {
    stress: '登録完了しました。'
  },
  check: {
    stress: 'メールアドレス確認用のメールを送信しました',
    normal: '内容をご確認頂きリンクをクリックして\n認証を完了してください。'
  }
};

type Model = {
  nickname: string;
};

/**
 * Nickname Component
 */
const Nickname = () => {
  const [signUp, { error: signUpError, loading: signUpLoading }] = useMutation(
    SIGNUP_NICKNAME_QUERY
  );
  const history = useHistory();
  const { status, user, isEnterprise } = useLoginUser();
  const { showErrorDialog, closeAllDialog } = useDialog();
  const [model, setModel] = useState<Model>({ nickname: '' });
  const [error, setError] = useState<Model>({ nickname: '' });

  /**
   * ログイン状況により処理を切り分け
   * （Emailの認証まで終わった場合のみニックネーム登録に進める）
   */
  useEffect(() => {
    switch (status) {
      case LoginStatus.NotInitialized:
        return;
      case LoginStatus.NotLogin:
        history.replace({ pathname: '/login' });
        return;
      case LoginStatus.LoginNotEmailVerified:
        return;
      case LoginStatus.LoginNicknameRegistered:
        // MEMO(oomura): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
        const pathname = isEnterprise ? '/enterprise/scout' : `/user/${user.nickname}`;
        history.replace({ pathname });
        return;
      default:
        return;
    }
  }, [status]);

  useEffect(() => {
    const errorCodes: string[] = getErrorCodes(
      signUpError
    ).map((code: GraphQLError['extensions']) => getErrorPhrase(code));
    if (errorCodes.length) {
      showErrorDialog(errorCodes);
    }
  }, [signUpError]);

  const onSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (isValidateForm(model.nickname)) return;

    const { errors } = await signUp({
      variables: { nickname: model.nickname }
    });

    if (errors && errors.length > 0) {
      return;
    }

    await auth.refreshUser();
    await auth.refreshToken();
    setModel({ nickname: '' });
  };

  const isValidateForm = (nickname: string) => {
    let isValidate = false;
    if (nickname === '') {
      setError({ nickname: VALIDATION.NICKNAME.EMPTY });
      isValidate = true;
    }
    if (nickname.length > NICKNAME_INPUT.LENGTH.NICKNAME) {
      setError({ nickname: VALIDATION.NICKNAME.OVER_LENGTH });
      isValidate = true;
    }
    if (!checkCharacterType(nickname)) {
      setError({ nickname: VALIDATION.NICKNAME.INCORRECT });
      isValidate = true;
    }
    if (BANNED_WORD_LIST.includes(nickname)) {
      setError({ nickname: VALIDATION.NICKNAME.FORBIDDEN });
      isValidate = true;
    }
    return isValidate;
  };

  const onChangeForm = (value: string) => {
    if (value.length <= 0) return;
    setModel({ nickname: value });
    setError({ nickname: '' });
  };

  if (status === LoginStatus.LoginNicknameRegistered) {
    return <NoticeFormTemplate stressPhrase={NOTICE_PHRASE.complete.stress} />;
  }

  if (status === LoginStatus.LoginNotEmailVerified) {
    return (
      <NoticeFormTemplate
        stressPhrase={NOTICE_PHRASE.check.stress}
        normalPhrase={NOTICE_PHRASE.check.normal}
      />
    );
  }

  return (
    <StyledSignupForm onSubmit={onSubmit}>
      <StyleTitle>ニックネーム登録</StyleTitle>
      <StyledNoticeText>ニックネームは公開されますのでご注意ください</StyledNoticeText>
      <StyledSignupFormRow data-error={error.nickname.length > 0}>
        <StyledLabel htmlFor="nickname">ニックネーム</StyledLabel>
        <StyledInput
          onChange={(e) => onChangeForm(e.target.value)}
          name="nickname"
          type="text"
          placeholder="ニックネームを入力"
        />
        <StyledErrorText>{error.nickname}</StyledErrorText>
      </StyledSignupFormRow>
      <StyledFormButtons>
        <PrimaryButton size="large" type="submit" disabled={signUpLoading}>
          決定
        </PrimaryButton>
      </StyledFormButtons>
    </StyledSignupForm>
  );
};

export default Nickname;

const StyleTitle = styled.h2`
  font-size: 20px;
  font-weight: bold;
  text-align: center;
  color: #0fafa9;
  padding-bottom: 20px;
  border-bottom: 1px solid #dddddd;
`;

const StyledNoticeText = styled.p`
  margin-top: 40px;
  text-align: center;
  color: #f4233c;
`;

const StyledSignupForm = styled.form`
  position: relative;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 40px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;

const StyledErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;

  ${StyledLabel} + & {
    margin-top: 10px;
  }
`;

const StyledFormButtons = styled.div`
  margin-top: 20px;
`;

const StyledSignupFormRow = styled.div`
  margin-top: 30px;

  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }

  &[data-error='true'] ${StyledErrorText} {
    display: block;
  }
`;
