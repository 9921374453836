import React from 'react';
import styled from 'styled-components';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import useSearchQueryManager from '@meettry/ui-components/hooks/useSearchQueryManager';
import Pagination from '@meettry/ui-components/components/organisms/Pagination';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type SearchResultType = {
  searchCategory: Array<{
    type: string;
    label: string;
    count: number;
  }>;
  type: string | number;
  handleChangeTab: (type: string) => void;
  queryString: string | number;
  sortType: string;
  onChangeSort: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  isLoading: boolean;
  SearchResult: JSX.Element | null;
  onPaginationClick: (pageNumber: number) => void;
  searchResultsCount: number;
};

const SearchResult: React.FC<SearchResultType> = (props) => {
  const {
    searchCategory,
    type,
    handleChangeTab,
    queryString,
    sortType,
    onChangeSort,
    isLoading,
    onPaginationClick,
    searchResultsCount,
    SearchResult
  } = props;

  const { displayNumber, page } = useSearchQueryManager();
  return (
    <>
      <StyledResultLayout>
        <StyledSearchCategoryList>
          {searchCategory.map((r) => (
            <li key={r.type}>
              <StyledSearchCategory
                data-active={type == r.type}
                data-count={r.count}
                onClick={() => handleChangeTab(r.type)}
              >
                <span>{r.label}</span>
                <span>{r.count}</span>
              </StyledSearchCategory>
            </li>
          ))}
        </StyledSearchCategoryList>
        <div>
          <StyledSearchContent>
            <StyledSearchHeader>
              <StyledSearchHeading>
                <StyledSearchWord>{queryString}</StyledSearchWord>
                <StyledSearchWordUnit>の検索結果</StyledSearchWordUnit>
              </StyledSearchHeading>
              <div>
                <label htmlFor="sort-search">表示順</label>
                <StyledSortSelect style={{ marginLeft: '10px' }}>
                  <select defaultValue={sortType} id="sort-search" onChange={onChangeSort}>
                    <option value="updated">更新日順</option>
                    <option value="created">作成日順</option>
                  </select>
                </StyledSortSelect>
              </div>
            </StyledSearchHeader>
            {isLoading && <Loader />}
            {SearchResult}
          </StyledSearchContent>
          {searchResultsCount > 10 && (
            <Pagination
              count={searchResultsCount}
              showCount={Number(displayNumber)}
              onClick={onPaginationClick}
              offset={Number(page)}
            />
          )}
        </div>
      </StyledResultLayout>
    </>
  );
};
export default SearchResult;

const StyledResultLayout = styled.div`
  position: relative;
  display: flex;
  align-items: flex-start;

  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 240px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 300px);
    margin-left: 60px;
  }
`;

const StyledSearchCategoryList = styled.ul`
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};

  & > li + li {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  }
`;

const StyledSearchCategory = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 50px;
  padding-left: 20px;
  padding-right: 10px;
  transition: background 0.15s;

  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
  }

  & > *:nth-child(2) {
    padding: 4px 10px;
    background-color: ${COLOR_DEFINITIONS.BG.MODAL.MESSAGE};
    color: ${COLOR_DEFINITIONS.TEXT.ALERT};
    font-size: 14px;
    font-weight: bold;
    line-height: 1;
    border-radius: 20px;
  }

  &[data-active='true'] {
    background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    & > *:nth-child(1) {
      color: ${COLOR_DEFINITIONS.TEXT.WHITE};
    }
  }

  &[data-count='0'] {
    & > *:nth-child(2) {
      background-color: ${COLOR_DEFINITIONS.LINE.MAIN};
      color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
    }
  }

  &:not([data-active='true']):hover {
    cursor: pointer;
    background-color: ${COLOR_DEFINITIONS.HOVER.OUTLINED};
  }
`;

const StyledSearchContent = styled.div`
  padding: 40px 50px 60px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const StyledSearchHeader = styled.header`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const StyledSearchHeading = styled.h1`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  span {
    display: inline-block;
  }
`;

const StyledSearchWord = styled.span`
  font-size: 24px;
  overflow-x: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  max-width: 420px;
  line-height: 1.5;
`;

const StyledSearchWordUnit = styled.span`
  font-size: 16px;
  margin-left: 10px;
  line-height: 1;
`;

const StyledSelect = styled.div`
  position: relative;
  display: inline-block;

  & > select {
    appearance: none;
    outline: none;
    box-sizing: border-box;
    display: block;
    width: 100%;
    font-size: 16px;
    line-height: 1;
    border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    padding: 8px 30px 8px 10px;
  }

  &:after {
    pointer-events: none;
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-right: 1px solid ${COLOR_DEFINITIONS.LINE.THIRDLY};
    border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.THIRDLY};
    position: absolute;
    top: 50%;
    right: 14px;
    transform: translate(0, -75%) rotate(45deg);
  }
`;

const StyledSortSelect = styled(StyledSelect)`
  & > select {
    font-size: 14px;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
`;
