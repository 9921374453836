import { useEffect, useState, useCallback } from 'react';

import { useCollection } from 'react-firebase-hooks/firestore';
import fb from '@meettry/ui-components/utils/auth';

import useChatCursor from '@meettry/ui-components/hooks/chat/useChatCursor';
import { ChatMessage, ChatMessageFieldType } from '@meettry/ui-components/types/chat';
import { MESSAGE_LOAD_LIMIT } from '@meettry/ui-components/utils/Constant';

const buildQuery = (roomId: string, cursor: any) => {
  if (!roomId || !cursor) {
    return null;
  }

  return fb.db
    .collection(`/rooms/${roomId}/messages`)
    .orderBy('createdAt', 'asc')
    .limit(MESSAGE_LOAD_LIMIT)
    .startAfter(cursor);
};

/**
 * convertChatMessage
 * firebaseの型から、JavaScriptで扱える型に変換
 */
const convertChatMessage = (
  chatMessageFieldValue: ChatMessageFieldType
): Omit<ChatMessage, 'id'> => ({
  ...chatMessageFieldValue,
  createdAt: chatMessageFieldValue.createdAt.toDate()
});

/**
 * useMessagesForward
 */
const useMessagesForward = (roomId: string, serverCursor: Date | null, appendMessages: any) => {
  const [query, setQuery] = useState(buildQuery(roomId, null));
  const [messages, setMessages] = useState<any[]>([]);

  const predicate = useCallback(
    (newCursor: Date) => (serverCursor?.getTime() ?? 0) - newCursor.getTime() > 0,
    [serverCursor]
  );
  const [cursor, setCursor] = useChatCursor(serverCursor, predicate);

  // カーソルが更新されたら、クエリを更新
  useEffect(() => {
    setQuery(buildQuery(roomId, cursor));
  }, [roomId, cursor]);

  const [snapshot, loading, error] = useCollection(query);

  useEffect(() => {
    if (!loading && !error && snapshot && !snapshot.empty) {
      const values: ChatMessage[] = [];
      snapshot.forEach((doc) =>
        values.push({
          id: doc.id,
          ...convertChatMessage(<ChatMessageFieldType>doc.data())
        })
      );
      if (values.length >= MESSAGE_LOAD_LIMIT) {
        // 取得したメッセージの長さが上限に達した場合、次のページに移行する

        // カーソル位置を最新メッセージに更新する
        // これで、より新しいメッセージが購読されるようになる
        const edgeCursor = values[values.length - 1].createdAt;
        setCursor(edgeCursor);

        // 取得したメッセージは外部の配列にマージして、内部メッセージ配列は空にする
        appendMessages(values);

        setMessages([]);
      } else {
        const edgeCursor = values[values.length - 1].createdAt;
        setCursor(edgeCursor);
        appendMessages(values);
        setMessages([]);
      }
    }
  }, [snapshot, loading, error]);

  return {
    messages,
    loading,
    error
  };
};

export default useMessagesForward;
