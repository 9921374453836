import React from 'react';
import styled from 'styled-components';

type CheckBoxProps = Omit<JSX.IntrinsicElements['input'], 'ref'>;

type Props = CheckBoxProps & {
  checkboxSize?: 'small' | 'medium' | 'large';
  text?: string;
  id: string;
};

export const CheckBox: React.FC<Props> = (props) => {
  const { text = '', checkboxSize = 'medium', ...restCheckboxProps } = props;
  return (
    <StyledCheckLabel htmlFor={restCheckboxProps.id}>
      <StyledCheckboxInput {...restCheckboxProps} type="checkbox" />
      <StyledCheckbox />
      {text && <StyledCheckboxText>{text}</StyledCheckboxText>}
    </StyledCheckLabel>
  );
};

export default CheckBox;

const StyledCheckLabel = styled.label`
  display: inline-flex;
`;

const StyledCheckboxInput = styled.input`
  display: none;
  position: relative;
  vertical-align: middle;
`;

const StyledCheckbox = styled.div`
  display: inline-block;
  position: relative;

  &::before {
    content: '';
    display: block;
    box-sizing: border-box;
    height: 20px;
    width: 20px;
    background: #f7f7f7;
    border: 1px solid #eeeeee;
    border-radius: 4px;
  }

  &::after {
    content: '';
    display: none;
    position: absolute;
    box-sizing: border-box;
    left: 5px;
    top: 50%;
    transform: translateY(-75%) rotate(-45deg);
    height: 5px;
    width: 10px;
    border-bottom: 2px solid #0fafa9;
    border-left: 2px solid #0fafa9;
  }

  ${StyledCheckboxInput}:checked + & {
    &::after {
      display: block;
    }
  }
`;

const StyledCheckboxText = styled.div`
  margin-left: 10px;
`;
