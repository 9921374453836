import React from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import Breadcrumbs from '@meettry/engineer/components/atoms/Breadcrumbs';
import { MarkdownArea } from '@meettry/ui-components/utils/loadMarkdown';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';

const ContentWrapper = (props) => {
  return (
    <StyledContentWrapper>
      <MarkdownArea markdownFile={props.file} />
    </StyledContentWrapper>
  );
};

const breadcrumbs = [
  { text: 'プライバシーポリシー', path: '/privacy' },
  { text: '利用規約', path: '/terms' },
  { text: '特定商取引に関する表記', path: '/tokutei' }
];

export const TermsLayout = (props) => {
  const location = useLocation();
  const locationPath = location.pathname;
  const breadcrumb = breadcrumbs.find((item) => item.path === locationPath);
  return (
    <StyledTermsLayout>
      <main>
        <Breadcrumbs items={[breadcrumb]} />
        <h1>{breadcrumb.text}</h1>
        <ContentWrapper file={props.file} />
      </main>
    </StyledTermsLayout>
  );
};

const StyledContentWrapper = styled.div`
  && {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 60px;
    padding: 50px;
    box-sizing: border-box;
    background-color: #ffffff;
    border: 1px solid #eeeeee;
    color: #333333;
    ${mediaQuery.lessThan('minTablet')`
        padding: 50px 40px;
        `}
  }
`;

const StyledTermsLayout = styled.div`
  && {
    padding: 30px 70px 60px;
    ${mediaQuery.lessThan('minTablet')`
        padding: 30px 0 60px;
        `}
    h1 {
      margin-top: 38px;
      text-align: center;
      color: #0fafa9;
      font-size: 40px;
      ${mediaQuery.lessThan('minTablet')`
      font-size: 20px;
      font-weight:bold;
        `}
    }
  }
`;
