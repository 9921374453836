import { useMemo } from 'react';
import React, { setGlobal, useEffect } from 'reactn';
import reset from 'styled-reset';
import { BrowserRouter as Router, Switch, useLocation } from 'react-router-dom';
import { ApolloProvider } from 'react-apollo';
import { ApolloProvider as ApolloHooksProvider } from '@apollo/react-hooks';
import { appClient } from '@meettry/engineer/graphql/client';
import styled, { createGlobalStyle } from 'styled-components';

import Header from '@meettry/ui-components/components/organisms/Header';
import Footer from '@meettry/ui-components/components/organisms/Footers';
import { LoginStatus, useLoginUser } from '@meettry/ui-components/utils/auth';
import { appRoutes } from '@meettry/engineer/utils/route';

import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

import Maintenance from '@meettry/engineer/components/pages/Maintenance';
import { useMaintenance } from '@meettry/ui-components/utils/firebase/hooks';
import { Dialog } from '@meettry/ui-components/components/organisms/Dialog';
import { Loading } from '@meettry/ui-components/components/organisms/Loading';
import { Popup } from '@meettry/ui-components/components/organisms/Popup';

import { PopupProvider } from '@meettry/ui-components/contexts/popup';
import { DialogProvider } from '@meettry/ui-components/contexts/dialog';
import { LoadingProvider } from '@meettry/ui-components/contexts/loading';
import { LoginUserProvider } from '@meettry/ui-components/contexts/loginUser';
import AuthRoute from '@meettry/ui-components/components/atoms/AuthRoute';
import PreparingPage from '@meettry/ui-components/components/pages/Preparing';
import NotFoundPage from '@meettry/ui-components/components/pages/NotFound';
import { ChatProvider } from '@meettry/ui-components/contexts/chat.context';
import { HooksMasterProvider } from '@meettry/ui-components/contexts/hooksMaster.context';
import { NotificationProvider } from '@meettry/ui-components/contexts/notification.context';

const GlobalStyle = createGlobalStyle`
  ${reset}
  /* other styles */
  /* TODO(aida) 追加のresetを別で切り出す */
  body {
    font-family: ${FONT_FAMILY.COMMON};
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    background-color: ${COLOR_DEFINITIONS.LINE.SUB};
    *{
      -ms-overflow-style: none;
      scrollbar-width: none;
    }
    *::-webkit-scrollbar{
      display: none;
    }
    /* MEMO(aida) ダイアログやローディングを表示中にスクロールを無効にする*/
    &.dialog-open, &.loading-open, &.modal-open{
      overflow: hidden;
    }
  }
  a {
    text-decoration: none;
  }
  button{
    font-family: inherit;
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
  } 
`;

setGlobal({
  // src/utils/hooks/useHooksMasterFlag.js
  hooksMasterId: {},
  // src/utils/hooks/firebase/useChatNotifications.js
  chatNotifications: [],
  chatNotificationCounts: {}
});

const Pages = () => {
  const { authorized, initialized, isEnterprise, status } = useLoginUser();
  const location = useLocation();
  const { isMaintenance } = useMaintenance();
  useEffect(() => {
    const $root = document.getElementById('root');
    //ひとまず非同期的に動いてくれないため、処理を数ミリ秒ずらしてます
    setTimeout(() => $root?.scrollIntoView(), 10);
  }, [location.pathname]);
  const isTop = ['/', '/enterprise'].includes(location.pathname);
  const widthDynamicPage = [
    '/',
    '/privacy',
    '/terms',
    '/tokutei',
    '/contact',
    '/signup',
    '/login',
    '/enterprise',
    '/enterprise/contact'
  ];
  const isWidthDynamic = widthDynamicPage.includes(location.pathname);
  //MEMO(aida) footerを表示させないページを指定する。
  const hideFooter = ['/chat', '/enterprise/chat'].reduce(
    (result, path) => result || location.pathname.indexOf(path) > 0,
    false
  );
  const isEnterpriseContact = ['/enterprise/contact'].includes(location.pathname);

  const routingAuth = useMemo(() => (status === LoginStatus.NotInitialized ? null : authorized), [
    status,
    authorized
  ]);

  if (isMaintenance) {
    return (
      <StyledPageWrapper className="App" isWidthDynamic={isWidthDynamic}>
        <Maintenance />
      </StyledPageWrapper>
    );
  }

  return (
    <>
      <Header isTop={isTop} isWidthDynamic={isWidthDynamic} />
      <StyledPageWrapper
        className="App"
        isWidthDynamic={isWidthDynamic}
        isEnterpriseContact={isEnterpriseContact}
      >
        <Switch>
          {!initialized &&
            appRoutes(routingAuth, isEnterprise).map((route) => (
              <AuthRoute
                key={route.name}
                exact={route.exact}
                path={route.path}
                publicRoute={route.public}
                auth={route.auth}
                allow={route.allow}
                PreparingViewComponent={<PreparingPage />}
                DisallowViewComponent={<NotFoundPage />}
                render={(props) => (
                  <route.component {...props} path={route.path} childRoute={route.childRoute} />
                )}
              />
            ))}
        </Switch>
      </StyledPageWrapper>
      {!hideFooter && <Footer isWidthDynamic={isWidthDynamic} />}
    </>
  );
};

const compose = (contexts, Content) => {
  return contexts.reduce(
    (acc, ContextProvider) => <ContextProvider>{acc}</ContextProvider>,
    Content
  );
};

const Content = () => (
  <>
    <GlobalStyle />
    <Router>
      <Pages />
      <Dialog />
      <Popup />
      <Loading />
    </Router>
  </>
);

const App = () => {
  const contextProviders = [
    LoginUserProvider,
    PopupProvider,
    DialogProvider,
    LoadingProvider,
    ChatProvider,
    HooksMasterProvider,
    NotificationProvider
  ];
  return (
    <ApolloProvider client={appClient}>
      <ApolloHooksProvider client={appClient}>
        {compose(contextProviders, <Content />)}
      </ApolloHooksProvider>
    </ApolloProvider>
  );
};

export default App;

const StyledPageWrapper = styled.div`
  min-width: ${(props) => (props.isWidthDynamic ? 'none' : '1200px')};
  //MEMO(aida) 96px => headerの高さ
  min-height: ${(props) => (props.isEnterpriseContact ? 'none' : 'calc(100vh - 96px)')};
  display: flex;
  flex-direction: column;
`;
