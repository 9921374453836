import React from 'react';
import ChatMessage, {
  ChatMessageProps
} from '@meettry/ui-components/components/atoms/ChatMessage/ChatMessage';

export type ChatMessageType = 'user' | 'friend' | null;
export const CHAT_MESSAGE_TYPE_USER: ChatMessageType = 'user';
export const CHAT_MESSAGE_TYPE_FRIEND: ChatMessageType = 'friend';

const chatMessageGenerator = (options: { type: ChatMessageType }) => {
  const { type } = options;
  return (props: Omit<ChatMessageProps, 'type'>) => <ChatMessage {...props} type={type} />;
};

export const FriendMessage = chatMessageGenerator({ type: CHAT_MESSAGE_TYPE_FRIEND });
export const UserMessage = chatMessageGenerator({ type: CHAT_MESSAGE_TYPE_USER });
