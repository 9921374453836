import React, { useMemo } from 'react';
import styled from 'styled-components';
import {
  RadioButton,
  Datepicker,
  TextField,
  Textarea
} from '@meettry/ui-components/components/atoms/Input';
import TagTextField, {
  TagTextFieldItem
} from '@meettry/ui-components/components/atoms/Input/TagTextField';
import { Tag, TagCategory } from '@meettry/ui-components/types/tag';
import { ScoutDetail } from '@meettry/ui-components/types/scout';
import { BasicTag, LineTag } from '@meettry/ui-components/components/atoms/Tag';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export type ScoutEditType = 'CREATE' | 'EDIT';
export const SCOUT_EDIT_TYPE: { [key in ScoutEditType]: ScoutEditType } = {
  CREATE: 'CREATE',
  EDIT: 'EDIT'
};

type EditScoutTag = {
  tag: {
    id?: string;
    displayName?: string;
    category?: TagCategory;
  };
  years?: number;
  isRequired?: number;
};

export type ScoutEditModel = {
  id: string | null;
  isActive: number;
  title: string;
  comment: string;
  recruitmentStartAt: Date | null;
  recruitmentEndAt: Date | null;
  recruitmentCount: number | string;
  requiredGeneralTags: EditScoutTag[];
  expectedGeneralTags: EditScoutTag[];
  requiredSkillTags: EditScoutTag[];
  expectedSkillTags: EditScoutTag[];
};

/**
 * スカウト作成・編集のモデルの初期値を取得する
 * @return ScoutEditModel スカウト編集用のモデル
 */
export const DefaultScoutEditModel = (): ScoutEditModel => ({
  id: null,
  isActive: 1,
  title: '',
  comment: '',
  recruitmentStartAt: null,
  recruitmentEndAt: null,
  recruitmentCount: 0,
  requiredGeneralTags: [],
  expectedGeneralTags: [],
  requiredSkillTags: [],
  expectedSkillTags: []
});

/**
 * フェッチしたscoutDetailから編集用のmodelに必要な要素を抽出
 * @param ScoutDetail スカウト詳細情報
 * @return ScoutEditModel スカウト編集用のモデル
 */
export const extractScoutEditModel = (scoutDetail: ScoutDetail): ScoutEditModel => ({
  id: scoutDetail?.id ?? null,
  isActive: scoutDetail?.isActive ?? 1,
  title: scoutDetail?.title ?? '',
  comment: scoutDetail?.comment ?? '',
  recruitmentStartAt: scoutDetail?.recruitmentStartAt
    ? new Date(scoutDetail?.recruitmentStartAt)
    : null,
  recruitmentEndAt: scoutDetail?.recruitmentEndAt ? new Date(scoutDetail?.recruitmentEndAt) : null,
  recruitmentCount: scoutDetail?.recruitmentCount ?? 0,
  requiredGeneralTags: scoutDetail.scoutTags
    .filter((tag) => tag.isRequired && tag.tag.category === null)
    .map((tag) => ({
      isRequired: tag.isRequired,
      years: tag.years,
      tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
    })),
  expectedGeneralTags: scoutDetail.scoutTags
    .filter((tag) => !tag.isRequired && tag.tag.category === null)
    .map((tag) => ({
      isRequired: tag.isRequired,
      years: tag.years,
      tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
    })),
  requiredSkillTags: scoutDetail.scoutTags
    .filter((tag) => tag.isRequired && tag.tag.category !== null)
    .map((tag) => ({
      isRequired: tag.isRequired,
      years: tag.years,
      tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
    })),
  expectedSkillTags: scoutDetail.scoutTags
    .filter((tag) => !tag.isRequired && tag.tag.category !== null)
    .map((tag) => ({
      isRequired: tag.isRequired,
      years: tag.years,
      tag: { id: tag.tag.id, displayName: tag.tag.displayName, category: tag.tag.category }
    }))
});

/**
 * モデルのバリデーション
 * @params ScoutEditModel 編集対象のモデル
 * @return boolean モデルが有効かどうか
 */
export const isValidScoutEditModel = (model: ScoutEditModel) => {
  const isValid = model.title && model.comment && model.requiredSkillTags.length > 0;
  return isValid;
};

/**
 * ScoutEditorBox Component
 * スカウトの新規作成・編集の入力コンポーネント
 */

type Props = {
  model: ScoutEditModel;
  setModel: Function;
  type: ScoutEditType;
  tags: Tag[];
  error?: boolean;
  setDirty: React.Dispatch<React.SetStateAction<boolean>>;
  dirty: boolean;
};
const ScoutEditorBox: React.FC<Props> = (props) => {
  const { tags = [], model, setModel, error = false, setDirty, dirty } = props;

  /**
   * Func: onChange
   */
  const onChange = (modelType: keyof ScoutEditModel) => (value: any) => {
    dirty || setDirty(true);
    switch (modelType) {
      case 'recruitmentCount': {
        if (value.match(/^([1-9]\d*|0)$/) || value === '') {
          setModel({ ...model, [modelType]: value });
        }
        break;
      }
      default: {
        setModel({ ...model, [modelType]: value });
      }
    }
  };

  const onAddTag = (
    tagType:
      | 'requiredGeneralTags'
      | 'expectedGeneralTags'
      | 'requiredSkillTags'
      | 'expectedSkillTags'
  ) => (value: { item: TagTextFieldItem; year: string | null }, required: boolean) => {
    const existsTag = tags.find(
      (tag) => tag.id === value.item.key || tag.displayName === value.item.value
    );
    if (existsTag) {
      setModel({
        ...model,
        [tagType]: [
          ...model[tagType],
          {
            years: value?.year ? Number(value.year) : null,
            isRequired: required ? 1 : 0,
            tag: {
              id: existsTag.id,
              displayName: existsTag.displayName,
              category: existsTag.category
            }
          }
        ]
      });
    } else {
      dirty || setDirty(true);
      setModel({
        ...model,
        [tagType]: [
          ...model[tagType],
          {
            years: Number(value.year) ?? null,
            isRequired: required ? 1 : 0,
            tag: {
              displayName: value.item.value
            }
          }
        ]
      });
    }
  };

  /**
   * Func: onRemoveTag
   */
  const onRemoveTag = (
    tagType:
      | 'requiredGeneralTags'
      | 'expectedGeneralTags'
      | 'requiredSkillTags'
      | 'expectedSkillTags'
  ) => (tag: EditScoutTag) => {
    dirty || setDirty(true);
    setModel({
      ...model,
      [tagType]: model[tagType].filter(
        (t) =>
          !(
            t.tag.displayName === tag.tag.displayName &&
            t.tag.id === tag.tag.id &&
            t.tag.category === tag.tag.category
          )
      )
    });
  };

  /**
   * Variables
   */
  const generalTags = useMemo(
    () =>
      tags
        .filter((tag) => tag.category === null)
        .map((tag) => ({ key: tag.id, value: tag.displayName })),
    [tags]
  );
  const skillTags = useMemo(
    () =>
      tags
        .filter((tag) => tag.category !== null)
        .map((tag) => ({ key: tag.id, value: tag.displayName })),
    [tags]
  );

  return (
    <StyledScoutEditorBox>
      <StyledForm>
        <StyledFormItem>
          <StyledFormTitle>
            <span>タイトル</span>
            <span>
              <StyledRequiredLabel>必須</StyledRequiredLabel>
            </span>
          </StyledFormTitle>
          <StyledFormContent>
            <TextField
              placeholder="タイトルを入力してください"
              value={model.title}
              onChange={(e) => onChange('title')(e.target.value)}
            />
          </StyledFormContent>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>
            <span>説明</span>
            <span>
              <StyledRequiredLabel>必須</StyledRequiredLabel>
            </span>
          </StyledFormTitle>
          <StyledFormContent>
            <Textarea
              placeholder="どういった目的や、募集要項でスカウトするのかの説明を入力してください"
              value={model.comment}
              onChange={(e) => onChange('comment')(e.target.value)}
            />
          </StyledFormContent>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>募集期間</StyledFormTitle>
          <StyledFormContent>
            <StyledDatepickerGroup>
              <Datepicker
                locale="ja"
                selectsStart
                dateFormat="yyyy/MM/dd"
                selected={model.recruitmentStartAt}
                startDate={model.recruitmentStartAt}
                endDate={model.recruitmentEndAt}
                maxDate={model.recruitmentEndAt}
                onChange={(date: Date) => onChange('recruitmentStartAt')(date)}
              />
              <span>〜</span>
              <Datepicker
                locale="ja"
                selectsEnd
                dateFormat="yyyy/MM/dd"
                selected={model.recruitmentEndAt}
                startDate={model.recruitmentStartAt}
                endDate={model.recruitmentEndAt}
                minDate={model.recruitmentStartAt}
                onChange={(date: Date) => onChange('recruitmentEndAt')(date)}
              />
            </StyledDatepickerGroup>
          </StyledFormContent>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>採用人数</StyledFormTitle>
          <StyledFormContent>
            <StyledRecruitCountTextField
              placeholder=""
              type="text"
              value={String(model.recruitmentCount)}
              onChange={(e) => onChange('recruitmentCount')(e.target.value)}
              pattern="[0-9]+"
            />
            人
          </StyledFormContent>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>
            <span>必須スキル</span>
            <span>
              <StyledRequiredLabel>必須</StyledRequiredLabel>
            </span>
          </StyledFormTitle>
          <StyledFormContentTags>
            <div>
              <TagTextField
                items={skillTags}
                placeholder="必須スキルの言語・開発環境を入力"
                onChange={(selection) => onAddTag('requiredSkillTags')(selection, true)}
                enableYear={true}
              />
              {model.requiredSkillTags.map((tag, index) => (
                <StyledBasicTag key={tag.tag.displayName}>
                  {`${tag.tag.displayName}${tag?.years ? ` ${tag.years}年` : ''}`}
                  <StyledCrossMark
                    iconColor="blue"
                    iconName="cross_mark"
                    onClick={() => onRemoveTag('requiredSkillTags')(tag)}
                  />
                </StyledBasicTag>
              ))}
            </div>
            <div>
              <TagTextField
                items={generalTags}
                placeholder="必須スキルのキーワード・タグを入力"
                onChange={(selection) => onAddTag('requiredGeneralTags')(selection, true)}
                enableYear={true}
              />
              {model.requiredGeneralTags.map((tag, index) => (
                <StyledLineTag key={tag.tag.displayName}>
                  {`${tag.tag.displayName}${tag?.years ? ` ${tag.years}年` : ''}`}
                  <StyledCrossMark
                    iconColor="blue"
                    iconName="cross_mark"
                    onClick={() => onRemoveTag('requiredGeneralTags')(tag)}
                  />
                </StyledLineTag>
              ))}
            </div>
          </StyledFormContentTags>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>歓迎スキル</StyledFormTitle>
          <StyledFormContentTags>
            <div>
              <TagTextField
                items={generalTags}
                placeholder="歓迎スキルのキーワード・タグを入力"
                onChange={(selection) => onAddTag('expectedGeneralTags')(selection, false)}
                enableYear={false}
              />
              {model.expectedGeneralTags.map((tag, index) => (
                <StyledLineTag key={tag.tag.displayName}>
                  {`${tag.tag.displayName}`}
                  <StyledCrossMark
                    iconColor="blue"
                    iconName="cross_mark"
                    onClick={() => onRemoveTag('expectedGeneralTags')(tag)}
                  />
                </StyledLineTag>
              ))}
            </div>
            <div>
              <TagTextField
                items={skillTags}
                placeholder="歓迎スキルの言語・開発環境を入力"
                onChange={(selection) => onAddTag('expectedSkillTags')(selection, false)}
                enableYear={false}
              />
              {model.expectedSkillTags.map((tag, index) => (
                <StyledBasicTag key={tag.tag.displayName}>
                  {`${tag.tag.displayName}`}
                  <StyledCrossMark
                    iconColor="blue"
                    iconName="cross_mark"
                    onClick={() => onRemoveTag('expectedSkillTags')(tag)}
                  />
                </StyledBasicTag>
              ))}
            </div>
          </StyledFormContentTags>
        </StyledFormItem>

        <StyledFormItem>
          <StyledFormTitle>ステータス</StyledFormTitle>
          <StyledFormContent>
            <StyledStatusRadioGroup>
              <span>
                <RadioButton
                  label="募集中"
                  name="scout_status"
                  value={1}
                  checked={model.isActive === 1}
                  onChange={onChange('isActive')}
                />
              </span>
              <span>
                <RadioButton
                  label="募集終了"
                  name="scout_status"
                  value={0}
                  checked={model.isActive === 0}
                  onChange={onChange('isActive')}
                />
              </span>
            </StyledStatusRadioGroup>
          </StyledFormContent>
        </StyledFormItem>
      </StyledForm>
      {error && <StyledError>必須項目が入力されていません</StyledError>}
    </StyledScoutEditorBox>
  );
};
export default ScoutEditorBox;

const StyledScoutEditorBox = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  width: 700px;
`;

const StyledForm = styled.div``;

const StyledFormItem = styled.div`
  display: flex;

  &:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledFormTitle = styled.div`
  display: flex;
  justify-content: space-between;
  min-width: 170px;
  width: 170px;
  padding-top: 15px;
  padding-right: 10px;
`;

const StyledFormContent = styled.div`
  width: 100%;
`;

const StyledDatepickerGroup = styled.div`
  display: inline-flex;
  align-items: center;

  & > span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

const StyledRecruitCountTextField = styled(TextField)`
  width: 80px;
  margin-right: 10px;
`;

const StyledStatusRadioGroup = styled.div`
  padding-top: 15px;
  display: flex;

  & > *:not(:first-child) {
    margin-left: 15px;
  }
`;

const StyledFormContentTags = styled.div`
  width: 100%;

  & > * + * {
    margin-top: 20px;
  }
`;

const StyledCrossMark = styled(Icon)`
  margin-left: 4px;
  cursor: pointer;
`;

const StyledLineTag = styled(LineTag)`
  display: inline-block;
  margin: 4px 4px 0 0;
`;

const StyledBasicTag = StyledLineTag.withComponent(BasicTag);

const StyledError = styled.div`
  margin-top: 30px;
  padding: 4px 10px;
  display: block;
  font-size: 12px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  background-color: rgba(244, 35, 60, 0.1);
`;

const StyledRequiredLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 14px;
  font-size: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  background-color: ${COLOR_DEFINITIONS.BG.ALERT};
  border-radius: 14px;
`;
