import React from 'react';
import styled from 'styled-components';
import Icon, { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import { PrimaryButton, ButtonType } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

/**
 * アイコンボタン
 * MEMO(PARK)：countを表示することもできる
 * @param {*} props
 * @param {String} buttonType
 * @param {Object} iconProps
 */

type IconButtonProps = ButtonType & {
  iconProps: IconPropsType;
};

export const IconButton = (props: IconButtonProps) => {
  const { iconProps, children, ...restProps } = props;
  const { marginRight } = iconProps;

  return (
    <PrimaryButton {...restProps}>
      <Icon marginRight={!!marginRight ? marginRight : '4px'} {...iconProps} />
      <span>{children}</span>
    </PrimaryButton>
  );
};

export const PrimaryIconButton = (props: IconButtonProps) => <IconButton {...props} />;
export const SecondaryIconButton = (props: IconButtonProps) => (
  <IconButton {...props} styleType={STYLE_TYPE.ROLE.SECONDARY} />
);
export const TertiaryIconButton = (props: IconButtonProps) => (
  <IconButton {...props} styleType={STYLE_TYPE.ROLE.THIRDLY} />
);

type FollowButtonProps = ButtonType & {
  count?: number;
  following?: boolean;
};

export const FollowButton = (props: FollowButtonProps) => {
  const { count, following, ...restProps } = props;
  const iconProps: {
    iconColor: 'gray' | 'white';
    iconName: string;
  } = {
    iconColor: following ? 'gray' : 'white',
    iconName: 'followers'
  };

  return (
    <IconButton iconProps={iconProps} {...restProps}>
      {following ? 'フォロー中' : 'フォローする'}
      {count ? <span>{count}</span> : null}
    </IconButton>
  );
};

export type ConnectionButtonProps = IconButtonProps & {
  count: number;
};

export const ConnectionButton = (props: ConnectionButtonProps) => {
  const { children, count, ...restProps } = props;
  return (
    <IconButton {...restProps}>
      {children}
      {count ? <StyledConnectionCount>{count}</StyledConnectionCount> : null}
    </IconButton>
  );
};

const StyledConnectionCount = styled.span`
  font-weight: bold;
  margin-left: 5px;
`;
