import React from 'react';
import styled from 'styled-components';
import avatarImage from '@meettry/company/images/avatar.png';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const Message: React.FC = () => {
  return (
    <StyledMessageUl>
      {Array.from({ length: 3 }, (_, i) => (
        <StyledMessageLi key={i}>
          <StyledMessageProfile>
            <StyledMessageProfileImg src={avatarImage} alt="エンジニアのアイコン" />
            <StyledMessageProfileName marginLeft={12}>Name</StyledMessageProfileName>
            <StyledMessageProfileName marginLeft={6}>name</StyledMessageProfileName>
          </StyledMessageProfile>
          <StyledMessageDesc>
            Djangoの経験10年以上のマネージャー経験のあるリータ広報のエンジ…
          </StyledMessageDesc>
          <StyledMessageDate>
            <span>00:00</span>
            <StyledMessageQuantity>2</StyledMessageQuantity>
          </StyledMessageDate>
        </StyledMessageLi>
      ))}
    </StyledMessageUl>
  );
};

const StyledMessageUl = styled.ul`
  margin-top: 24px;
  padding: 0;
`;

const StyledMessageLi = styled.li`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  box-sizing: border-box;
  color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};
  &:first-of-type {
    border: solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-width: 1px 0px;
  }
  & + li {
    border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledMessageProfile = styled.span`
  display: inline-flex;
  width: 204px;
  align-items: center;
`;

const StyledMessageProfileImg = styled.img`
  width: 30px;
`;

const StyledMessageProfileName = styled.span<{ marginLeft?: number }>`
  font-weight: bold;
  ${({ marginLeft }) => (marginLeft ? `margin-left: ${marginLeft}px;` : null)}
`;

const StyledMessageDesc = styled.span`
  display: inline-block;
  width: 380px;
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledMessageDate = styled.span`
  display: inline-block;
  margin-left: auto;
  font-size: 10px;
`;

const StyledMessageQuantity = styled.span`
  display: inline-block;
  width: 26px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 20px;
  margin-left: 6px;
  font-size: 12px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.TEXT.ALERT};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
`;
