import React from 'react';
import { useLocation } from 'react-router-dom';
import { JobTag, LangTag } from '@meettry/company/component/atoms/tags';

type ResumeItemProps = {
  listItem: {
    id: string;
    isPin: boolean;
    resumeLikes: number;
    start: string;
    end: string;
    total: string;
    title: string;
    officeName: string;
    jobList: Array<string>;
    langList: Array<string>;
    libraryList: Array<string>;
    serversideList: Array<string>;
    infraList: Array<string>;
    osList: Array<string>;
    toolList: Array<string>;
    text: string;
  };
  onClick?: () => void;
};

const ResumeItem: React.FC<ResumeItemProps> = (props) => {
  const { listItem, onClick } = props;
  const location = useLocation();
  const locationPath = location.pathname;

  const langListItems = [
    { className: 'resume-lang-icon', list: listItem.langList },
    { className: 'resume-library-icon', list: listItem.libraryList },
    { className: 'resume-server-icon', list: listItem.serversideList },
    { className: 'resume-infra-icon', list: listItem.infraList },
    { className: 'resume-os-icon', list: listItem.osList },
    { className: 'resume-tool-icon', list: listItem.toolList }
  ];

  return (
    <div className="user-resume-item">
      {listItem.isPin ? <div className="pin" /> : null}
      <div className="resume-item-wrap">
        <div className="resume-period">
          <span className="start">{listItem.start}</span>
          <span className="hyphen">|</span>
          <span className="end">{listItem.end}</span>
          <span className="total-period">{listItem.total}</span>
        </div>
        <div className="resume-contents">
          <span
            className={locationPath === '/user_name/resume' ? 'resume-title' : 'resume-title click'}
            onClick={onClick}
          >
            {listItem.title}
          </span>
          <div className="job-tag-wrap">
            {listItem.jobList.map((item, index) => (
              <JobTag item={item} key={index} />
            ))}
          </div>
          <div className="resume-tag-wrap">
            {langListItems.map((items, i) => {
              return (
                <React.Fragment key={i}>
                  <i className={items.className} />
                  {items.list.map((item, j) => {
                    return <LangTag item={item} key={j} />;
                  })}
                </React.Fragment>
              );
            })}
          </div>
          {locationPath === '/user_name/resume' ? (
            <p className="resume-text">{listItem.text}</p>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ResumeItem;
