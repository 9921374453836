import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { SearchBar } from '@meettry/company/component/atoms/input';
import { SortButton } from '@meettry/company/component/atoms/btn';
import ChallengeItem from '@meettry/company/component/organisms/userPage/Challenge/ChallengeItem';
import EmptyListParts from '@meettry/company/component/organisms/userPage/Dashboard/EmptyListParts';
import { challengeListItem } from '@meettry/company/component/organisms/userPage/Dashboard/ChallengeParts';

type StateType = {
  selectedChallengeId: string;
};

const Challenge: React.FC = () => {
  const location = useLocation<StateType>();
  const [challengeId, setChallengeId] = useState('');
  const selectedChallengeRef = useRef<HTMLLIElement>(null);

  useEffect(() => {
    setTimeout(() => {
      if (!selectedChallengeRef.current) return;
      const targetRect = selectedChallengeRef.current.getBoundingClientRect();
      window.scrollTo({
        top: targetRect.top - 96,
        behavior: 'smooth'
      });
      setChallengeId('');
    }, 300);
  }, [selectedChallengeRef.current]);

  useEffect(() => {
    if (!location.state || !location.state.selectedChallengeId) return;
    setChallengeId(location.state.selectedChallengeId);
  }, []);

  return (
    <div className="user-challenge-wrap">
      <div className="title-wrap">
        <span className="title">チャレンジ</span>
        <div className="btn-area">
          <SearchBar placeHolder="チャレンジを検索" />
          <span className="sort-text">表示順</span>
          <SortButton options={['最新順', '古い順', '更新日順', '年数順']} />
        </div>
      </div>
      {challengeListItem.length ? (
        <ul className="user-challenge-list">
          {challengeListItem.map((item, i) => {
            const isSelectedFromDashboard = challengeId === item.id;
            return (
              <li key={i} ref={isSelectedFromDashboard ? selectedChallengeRef : null}>
                <ChallengeItem listItem={item} />
              </li>
            );
          })}
        </ul>
      ) : (
        <EmptyListParts text="チャレンジ" />
      )}
    </div>
  );
};

export default Challenge;
