import React from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';

type SetIconTextType = {
  iconName: string;
  iconColor?: 'black' | 'gray' | 'primary' | 'red' | 'white' | 'blue';
  fontSize: string;
  children: React.ReactNode;
};

const SetIconText: React.FC<SetIconTextType> = (props) => {
  const { iconName, iconColor, children, fontSize } = props;

  return (
    <>
      <StyledIcon iconName={iconName} iconColor={iconColor} />
      {children && <StyledSpan fontSize={fontSize}>{children}</StyledSpan>}
    </>
  );
};

export default SetIconText;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const StyledSpan = styled.span<{ fontSize: string }>`
  font-size: ${({ fontSize }) => (fontSize ? fontSize : '12px')};
`;
