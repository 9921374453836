import React from 'react';
import styled from 'styled-components';

const HighlightedText = ({ children, keyword = '', isAllSame = false }) => {
  const parts = isAllSame ? children : (children || '').split(new RegExp(`(${keyword})`, 'gi'));

  const checkSameWord = (word = '') => {
    return word.toString().toLowerCase() === keyword.toLowerCase();
  };

  return (
    <span>
      {isAllSame ? (
        <StyledHighlight isHighlight={checkSameWord(parts)}>{parts}</StyledHighlight>
      ) : (
        parts.map((part, index) => {
          return (
            <StyledHighlight key={index} isHighlight={checkSameWord(part)}>
              {part}
            </StyledHighlight>
          );
        })
      )}
    </span>
  );
};

export default HighlightedText;

const StyledHighlight = styled.span`
  background-color: ${(props) => (props.isHighlight ? '#FFFF00' : '')};
`;
