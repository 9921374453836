import React from 'react';

import { PageCommonProps } from '@meettry/ui-components/types/route';

import TopMainVisual from '@meettry/ui-components/components/organisms/Tops/TopMainVisual';
import TopMedia from '@meettry/ui-components/components/organisms/Tops/TopMedia';
import TopFeature from '@meettry/ui-components/components/organisms/Tops/TopFeature';
import TopFlow from '@meettry/ui-components/components/organisms/Tops/TopFlow';
import TopAbout from '@meettry/ui-components/components/organisms/Tops/TopAbout';
import TopRegister from '@meettry/ui-components/components/organisms/Tops/TopRegister';

import Kv from '@meettry/company/images/LP_kv.png';

import Features1 from '@meettry/company/images/features1.png';
import Features2 from '@meettry/company/images/features2.png';
import Features3 from '@meettry/company/images/features3.png';

import Flow1 from '@meettry/company/images/flow1.png';
import Flow2 from '@meettry/company/images/flow2.png';
import Flow3 from '@meettry/company/images/flow3.png';

const featuresList = [
  {
    img: Features1,
    title: ['検索条件は', '自由に設定可能！'],
    content: 'フリーワードでの検索も可能となり、様々な軸での検索が可能！',
    note: ''
  },
  {
    img: Features2,
    title: ['エンジニアと', '直接チャットでやりとり'],
    content:
      'チャットでエンジニアと直接やりとりできることによって、気軽なコミュニケーションを実現。',
    note: ''
  },
  {
    img: Features3,
    title: ['成功報酬は', '一切かかりません'],
    content:
      '多くのAgent紹介やスカウトサービスでは成約時に成功報酬がかかりますが、Meettryでは成功報酬は０円！',
    note: ''
  }
];

const flowList = [
  {
    img: Flow1,
    msg: '様々な検索条件を設定して、求めているエンジニアを探し出します。'
  },
  {
    img: Flow2,
    msg: 'エンジニアを見つけたら気軽にチャットでコミュニケーションを開始！'
  },
  {
    img: Flow3,
    msg: '様々な条件でのエンジニア探しをプロセス管理'
  }
];

const mainVisualMessage = [
  'チャットで気軽に始める',
  'エンジニア探し',
  '採用コスト削減と',
  '出会いの増加を同時に実現'
];

const EnterpriseTop: React.FC<PageCommonProps> = () => {
  return (
    <>
      <TopMainVisual
        messages={mainVisualMessage}
        registerBtnMessage="お問い合わせはこちら"
        registerBtnLocate="/enterprise/contact"
        keyVisual={
          <div className="hero">
            <img src={Kv} alt="meettryのキービジュアル" />
          </div>
        }
        bgColor="#0FAFA9"
        mode="company"
      />
      {/* MEMO(oomura): ベータ版ではトルツメ */}
      {/*<TopMedia bg="#F5F5F5" />*/}
      <TopFeature featuresList={featuresList} mode="company" />
      <TopFlow flowList={flowList} />
      <TopAbout />
      <TopRegister
        messages={['成功報酬を気にした', '採用活動はもう終わりです。']}
        btnMessage="お問い合わせはこちら"
        btnPath="/enterprise/contact"
        mode="company"
      >
        <p className="bottom-content-text">
          Meettryでは、様々な条件を組み合わせて希望のエンジニアを見つけることができます。
          <br />
          手軽なコミュニケーションで多くの候補者と繋がれます。
          <br />
          まずはお問い合わせください。
        </p>
      </TopRegister>
    </>
  );
};

export default EnterpriseTop;
