import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import UserProfileTop from '@meettry/ui-components/components/organisms/UserPage/Common/UserProfile/UserProfileTop';
import {
  ConnectionButton,
  ConnectionButtonProps
} from '@meettry/ui-components/components/atoms/Button/IconButton';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

/* MEMO(PARK)：β版では一旦実装不要　*/
// import OrganizationItem from 'components/molecules/List/OrganizationItem';
// import { Avatar } from 'components/atoms/images/Avatars';

const UserProfile = () => {
  const history = useHistory();
  const { state } = useEngineer();
  const { badges, socialLinks, organizations, nickname, followers, followees } = state;

  const onClickConnection = (target: string) => () => {
    history.push({
      pathname: `/user/${nickname}/${target}`
    });
  };
  /* MEMO(PARK)：β版では一旦実装不要（リンク表示、Organization、badgeList） */
  // const socialLinkList = (socialLinks || []).map(({ name, avatarSrc, url }) => (
  //   <ListItemHorizontal key={name}>
  //     <Avatar size="sm" name={name} src={avatarSrc} />
  //   </ListItemHorizontal>
  // ));
  // const organizationList = (organizations || []).map(({ name, avatarSrc }) => (
  //   <ListItemHorizontal key={name}>
  //     <Avatar size="sm" name={name} src={avatarSrc} />
  //   </ListItemHorizontal>
  // ));
  // const badgeList = (badges || []).map(({ name }) => <li key={name}>{name}</li>);
  const connectionButton: Array<ConnectionButtonProps & { text: string }> = [
    {
      size: STYLE_TYPE.SIZE.SMALL,
      styleType: STYLE_TYPE.ROLE.SECONDARY,
      onClick: onClickConnection('followers'),
      iconProps: {
        iconColor: 'primary',
        iconName: 'followers',
        marginRight: '2px'
      },
      text: 'フォロワー',
      count: followers.length
    },
    {
      size: STYLE_TYPE.SIZE.SMALL,
      styleType: STYLE_TYPE.ROLE.SECONDARY,
      onClick: onClickConnection('following'),
      iconProps: {
        iconColor: 'primary',
        iconName: 'following',
        marginRight: '2px'
      },
      text: 'フォロー中',
      count: followees.length
    }
  ];

  return (
    <>
      <UserProfileTop />
      {/* MEMO(PARK)：β版では一旦実装不要 */}
      {/* {organizations && (
        <StyledContainer>
          <StyledTitle>Organization</StyledTitle>
          <OrganizationItem data={organizationList} />
        </StyledContainer>
      )} */}
      <StyledContainer>
        <StyledTitle>Connection</StyledTitle>
        <StyledConnectionButtonWrapper>
          {connectionButton.map((item, index) => (
            <ConnectionButton
              key={index}
              onClick={item.onClick}
              size={item.size}
              iconProps={item.iconProps}
              count={item.count}
              styleType={item.styleType}
            >
              {item.text}
            </ConnectionButton>
          ))}
        </StyledConnectionButtonWrapper>
      </StyledContainer>
    </>
  );
};

export default UserProfile;

const StyledContainer = styled.div`
  width: 100%;
  height: auto;
  padding-top: 10px;
  margin-top: 40px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
`;

const StyledTitle = styled.h3`
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  text-align: left;
  :first-letter {
    font-size: 18px;
  }
`;
/* MEMO(PARK)：β版では一旦実装不要　*/
// const ListItemHorizontal = styled.li`
//   && {
//     display: inline-block;
//     width: auto;
//     padding: 0;
//   }
// `;

const StyledConnectionButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  & > * {
    display: inline-flex;
    width: auto;
  }
  & > *:not(:first-child) {
    margin-top: 10px;
  }
`;
