import { popupActions } from '@meettry/company/redux/popup';
import uid from 'uuid';
import { useDispatch } from 'react-redux';

/**
 * usePopup
 * ポップアップの制御フック
 */
const usePopup = () => {
  const dispatch = useDispatch();

  const showSuccessPopup = (text: string) => {
    dispatch(popupActions.showPopup({
      id: uid.v4(),
      type: 'success',
      text
    }));
  };

  const showErrorPopup = (text: string) => {
    dispatch(popupActions.showPopup({
      id: uid.v4(),
      type: 'error',
      text
    }));
  };

  const removePopup = (id: string) => {
    dispatch(popupActions.removePopup({ id }));
  }

  return {
    showSuccessPopup,
    showErrorPopup,
    removePopup
  }
};
export default usePopup;
