import React from 'react';
import styled from 'styled-components';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { useTopPath } from '@meettry/ui-components/hooks/useTopPath';

import ImageLogoLink from '@meettry/ui-components/components/atoms/Images/ImageLogoLink';
import HeaderNoMember from '@meettry/ui-components/components/molecules/HeaderNoMember';
import HeaderMember from '@meettry/ui-components/components/molecules/HeaderMember';
import { HamburgerHeaderMember } from '@meettry/ui-components/components/molecules/HeaderMember/HeaderMember';
import HeaderWrapper from '@meettry/ui-components/components/organisms/Header/HeaderWrapper';
import {
  useHamburgerOpen,
  HamburgerMenu,
  HamburgerMenuButton
} from '@meettry/ui-components/components/organisms/Header/Hamburger';
import HeaderSearch from '@meettry/ui-components/components/organisms/HeaderSearch';

type HeaderType = {
  isTop: boolean;
  isWidthDynamic: boolean;
};

const Header: React.FC<HeaderType> = (props) => {
  const { authorized, initialized } = useLoginUser();
  const [isOpenHamburger, displayOpenHamburger] = useHamburgerOpen();
  const isTop = props.isTop || !authorized;
  const topPath = useTopPath();
  const enterprise = topPath === '/enterprise';
  const mode = enterprise ? 'company' : null;
  const theme = enterprise ? 'white' : 'primary';
  return (
    <HeaderWrapper
      isScrolled={isOpenHamburger}
      isWidthDynamic={props.isWidthDynamic}
      hamburgerMenu={
        <HamburgerMenu isOpen={isOpenHamburger} mode={mode}>
          {!initialized &&
            (authorized ? (
              <HamburgerHeaderMember
                closeMenu={(cb) => isOpenHamburger && displayOpenHamburger(false, cb)}
              />
            ) : (
              <HeaderNoMember
                closeMenu={(cb) => isOpenHamburger && displayOpenHamburger(false, cb)}
              />
            ))}
        </HamburgerMenu>
      }
    >
      <StyledHeaderContent
        onClick={() =>
          window.scrollTo({
            top: 0,
            behavior: 'smooth'
          })
        }
      >
        <ImageLogoLink size="normal" toPath={topPath} />
        {authorized ? <HeaderSearch /> : null}
      </StyledHeaderContent>
      <StyledHeaderContent isMenu={true} isTop={isTop}>
        {!initialized && (authorized ? <HeaderMember /> : <HeaderNoMember />)}
      </StyledHeaderContent>
      {isTop ? (
        <HamburgerMenuButton
          onClick={(changedOpen) => displayOpenHamburger(changedOpen)}
          isOpen={isOpenHamburger}
          theme={theme}
        />
      ) : null}
    </HeaderWrapper>
  );
};

const StyledHeaderContent = styled.div<{ isMenu?: boolean; isTop?: boolean }>`
  display: flex;
  align-items: center;
  > form {
    margin-left: 20px;
  }
  ${({ isMenu, isTop }) =>
    mediaQuery.lessThan('breakPoint')`
    display: ${isMenu && isTop ? 'none' : 'flex'};
    `.join('')};
`;

export default Header;
