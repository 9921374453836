import React, { MouseEvent, TouchEvent } from 'react';
import styled from 'styled-components';
import { useOutsideClickVisible } from '@meettry/ui-components/hooks/useOutsideClickVisible';

type HoverableHeaderIconType = {
  onClick: () => void;
  children: React.ReactNode;
  HoverComponent: React.ReactNode;
  hideHoverOnClick?: boolean;
  hoverVisible?: boolean;
};

const HoverableHeaderIcon: React.FC<HoverableHeaderIconType> = (props) => {
  const { onClick, children, HoverComponent, hideHoverOnClick = true } = props;
  const { ref, isVisible, setIsVisible } = useOutsideClickVisible(false);

  const onClickHandler = (e: MouseEvent) => {
    e.preventDefault();
    typeof onClick === 'function' && onClick();
    setIsVisible(false);
  };

  const handleMouseEnter = (e: MouseEvent) => {
    e.preventDefault();
    setIsVisible(true);
  };

  const handleTouchStart = () => {
    setIsVisible(true);
  };

  const onClickHoverComponent = (e: MouseEvent) => {
    e.preventDefault();
    //MEMO(aida) hideHoverOnClickがfalseの場合は何もしない
    if (!hideHoverOnClick) return;
    setIsVisible(false);
  };

  return (
    <StyledHoverableHeaderIcon>
      <StyledHeaderIcon
        onMouseEnter={handleMouseEnter}
        onTouchStart={handleTouchStart}
        onClick={onClickHandler}
      >
        {children}
      </StyledHeaderIcon>
      {isVisible && (
        <StyledHoverComponentContainer onClick={onClickHoverComponent} ref={isVisible && ref}>
          {HoverComponent}
        </StyledHoverComponentContainer>
      )}
    </StyledHoverableHeaderIcon>
  );
};

export default HoverableHeaderIcon;

const StyledHoverableHeaderIcon = styled.div`
  position: relative;
`;

const StyledHeaderIcon = styled.div`
  cursor: pointer;
`;

const StyledHoverComponentContainer = styled.div`
  background-color: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
  position: absolute;
  top: 110%;
  transform: translateX(-55%);
`;
