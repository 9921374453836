import React, { FormEvent, useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { useMutation } from '@apollo/react-hooks';
import { INIT_ORG_USER } from '@meettry/company/graphql/query/signup';
import * as Validation from '@meettry/company/utils/validation';
import * as FindError from '@meettry/company/utils/findError';
import { PageCommonProps } from '@meettry/ui-components/types/route';
import { SIGN, SIGNUP } from '@meettry/ui-components/constants/error';
import { PrimaryButton } from '@meettry/ui-components/components/atoms/Button';
import { NoticeModal } from '@meettry/ui-components/components/organisms/Modal';
import Checkbox from '@meettry/engineer/components/atoms/Checkbox';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

type PasswordType = {
  password: string;
  setPassword: React.Dispatch<React.SetStateAction<string>>;
  passwordError: string;
  setPasswordError: React.Dispatch<React.SetStateAction<string>>;
  alreadyValidate: boolean;
};

type AgreeType = {
  agree: boolean;
  setAgree: React.Dispatch<React.SetStateAction<boolean>>;
};

type AgreeModalType = {
  show: boolean;
  displayModal: React.Dispatch<React.SetStateAction<boolean>>;
};

/**
 * PasswordArea Component
 */
const PasswordArea: React.FC<PasswordType> = ({
  password,
  setPassword,
  passwordError,
  setPasswordError,
  alreadyValidate
}) => {
  const passwordErrorFind = FindError.findError(!password ? SIGNUP : SIGN, passwordError);
  const passwordErrorDesc = !!passwordErrorFind ? (
    <StyledErrorText>{passwordErrorFind}</StyledErrorText>
  ) : null;
  return (
    <StyledPasswordArea data-error={!!passwordErrorDesc}>
      <StyledLabel htmlFor="password">パスワード</StyledLabel>
      <StyledInput
        name="password"
        type="password"
        placeholder="パスワードを入力"
        value={password}
        onChange={(event) => {
          setPassword(event.target.value);
          if (!alreadyValidate) return;
          Validation.signUp(password, setPasswordError);
        }}
        onBlur={() => {
          if (!alreadyValidate) return;
          Validation.signUp(password, setPasswordError);
        }}
      />
      {passwordErrorDesc}
      <p>
        パスワードは8〜15文字以内で、半角の英小文字・大文字、半角数字、記号の全てを含んだものを指定してください。
      </p>
    </StyledPasswordArea>
  );
};

/**
 * AgreeArea Component
 */
const AgreeArea: React.FC<AgreeType> = ({ agree, setAgree }) => {
  return (
    <StyledAgreeArea className="agree">
      <Checkbox isCheck={agree} onClick={() => setAgree(!agree)}>
        <span>
          <StyledLink
            onClick={(e) => {
              e.preventDefault();
              window.open('/terms', 'terms');
            }}
          >
            利用規約
          </StyledLink>
          及び
          <StyledLink
            onClick={(e) => {
              e.preventDefault();
              window.open('/privacy', 'privacy');
            }}
          >
            プライバシーポリシー
          </StyledLink>
          に同意する
        </span>
      </Checkbox>
    </StyledAgreeArea>
  );
};

/**
 * AgreeModal Component
 */
const AgreeModal: React.FC<AgreeModalType> = ({ show, displayModal }) => {
  return (
    <NoticeModal
      show={show}
      displayModal={displayModal}
      okClickText="OK"
      okClick={() => displayModal(false)}
    >
      <StyledModalMessage>利用規約及びプライバシーポリシーに同意してください</StyledModalMessage>
    </NoticeModal>
  );
};

/**
 * EnterpriseSignup Page Component
 */
const EnterpriseSignup: React.FC<PageCommonProps> = () => {
  const { showSuccessPopup } = usePopup();
  const [password, setPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [agree, setAgree] = useState(false);
  const [show, displayModal] = useState(false);
  const [alreadyValidate, setAlreadyValidate] = useState(false);
  const [isValidate, setIsValidate] = useState(false);
  const [validateDone, setValidateDone] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const [initOrgUser, { data, called, loading }] = useMutation(INIT_ORG_USER);
  const token = location.search.replace('?token=', '');

  const signUp = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!alreadyValidate) setAlreadyValidate(true);
    setIsValidate(true);
    setValidateDone(false);
  };

  useEffect(() => {
    if (!isValidate) return;
    Validation.signUp(password, setPasswordError);
    setValidateDone(true);
  }, [isValidate]);

  useEffect(() => {
    if (!isValidate) return;
    if (!agree && passwordError === 'none') {
      displayModal(true);
    }
    if (agree && passwordError === 'none') {
      initOrgUser({
        variables: {
          input: {
            token: token,
            password: password
          }
        }
      });
    }
    setIsValidate(false);
  }, [validateDone]);

  useEffect(() => {
    if (called && !loading) {
      showSuccessPopup('パスワードを設定しました');
      history.push('/login');
    }
  }, [called, loading]);

  return (
    <StyledEnterpriseSignup>
      <StyledPageTitle>初期設定</StyledPageTitle>
      <StyledForm onSubmit={signUp}>
        <PasswordArea
          password={password}
          setPassword={setPassword}
          passwordError={passwordError}
          setPasswordError={setPasswordError}
          alreadyValidate={alreadyValidate}
        />
        {/* MEMO（minami）：エンジニア側に合わせるためコメントアウト
      <PrivacyHandling /> */}
        <AgreeArea agree={agree} setAgree={setAgree} />
        <PrimaryButton type="submit" size={STYLE_TYPE.SIZE.LARGE}>
          アカウントを作成
        </PrimaryButton>
        {!agree && show ? <AgreeModal show={show} displayModal={displayModal} /> : null}
      </StyledForm>
    </StyledEnterpriseSignup>
  );
};

export default EnterpriseSignup;

const StyledEnterpriseSignup = styled.main`
  background-color: #f7f7f7;
  padding: 100px 0 120px;
  font-family: '游ゴシック体', YuGothic, '游ゴシック Medium', 'Yu Gothic Medium', '游ゴシック',
    'Yu Gothic', sans-serif;
  color: #333333;
`;

const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #0fafa9;
  text-align: center;
`;

const StyledForm = styled.form`
  background-color: #ffffff;
  width: 560px;
  margin: 40px auto 0;
  padding: 50px 50px 40px;
`;

const StyledInput = styled.input`
  margin-top: 10px;
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding: 0 20px;
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
`;

const StyledAgreeArea = styled.div`
  text-align: center;
  margin-top: 25px;
  margin-bottom: 25px;
  input {
    display: none;
    &:checked + label::before {
      background-color: $alert_text_color;
    }
  }
  label {
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    position: relative;
    &::before {
      content: '';
      display: block;
      width: 16px;
      height: 16px;
      background-color: $text_sub_color;
      border-radius: 50%;
      margin: 9px;
    }
    &::after {
      content: '';
      display: block;
      width: 3px;
      height: 7px;
      border-bottom: 2px solid $back_and_text_color;
      border-right: 2px solid $back_and_text_color;
      position: absolute;
      top: 50%;
      left: 13px;
      transform: rotate(45deg) translateY(-50%) translateX(-50%);
    }
    > span {
      font-weight: normal;
      font-size: 16px;
      color: $alert_text_color;
      a {
        cursor: pointer;
        position: relative;
        line-height: 1.2;
        display: inline-block;
        border-bottom: 1px solid $alert_text_color;
        padding-bottom: 2px;
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`;

const StyledErrorText = styled.p`
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;

const StyledPasswordArea = styled.div`
  & > p {
    font-size: 14px;
    line-height: 24px;
    margin: 15px 0 0;
  }
  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }
`;

const StyledLink = styled.a`
  cursor: pointer;
  position: relative;
  line-height: 1.2;
  display: inline-block;
  border-bottom: 1px solid #f4233c;
  padding-bottom: 2px;
  &:hover {
    opacity: 0.8;
  }
`;

const StyledModalMessage = styled.div`
  padding-bottom: 50px;
`;
