import React, { useState, useReducer, useEffect, useMemo } from 'react';
import styled from 'styled-components';
import * as Validation from '@meettry/company/utils/validation';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import {
  GET_BILLING_ADDRESS,
  UPDATE_BILLING_ADDRESS
} from '@meettry/ui-components/queries/corporateInfo';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import {
  CompanyName,
  PostalCode,
  StreetAddress,
  DepartmentName,
  RepresentativeName,
  PhoneNumber
} from '@meettry/company/component/atoms/input';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import {
  StyledButtonContainer,
  StyledFormItem
} from '@meettry/engineer/components/pages/EnterpriseSettingCompany';

type BillDestinationInfo = {
  name: string;
  postCode: string;
  address: string;
  department: string;
  representative: string;
  phone: string;
};

type BillDestinationTableType = {
  setCurrent: React.Dispatch<React.SetStateAction<boolean>>;
  info: BillDestinationInfo | null;
};

export type InputBillProps = {
  companyName: string;
  postalCode: Array<string>;
  streetAddress: string;
  phoneNumber: Array<string>;
  departmentName: string;
  representativeName: string;
};

const initialState: InputBillProps = {
  companyName: '',
  postalCode: ['', ''],
  streetAddress: '',
  phoneNumber: ['', '', ''],
  departmentName: '',
  representativeName: ''
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;
const CHANGE_TEXT_NUMBER = 'CHANGE_TEXT_NUMBER' as const;

type InputAction = {
  type: typeof CHANGE_TEXT | typeof CHANGE_TEXT_NUMBER;
  text: string;
  item: keyof InputBillProps;
  stateArray: 'postalCode' | 'phoneNumber' | null;
  num: number;
};

const changeInput: React.Reducer<InputBillProps, InputAction> = (
  state: InputBillProps,
  action: InputAction
) => {
  switch (action.type) {
    case 'CHANGE_TEXT':
      return { ...state, [action.item]: action.text };
    case 'CHANGE_TEXT_NUMBER':
      if (!action.stateArray) return state;
      const itemCopy = state[action.stateArray];
      itemCopy[action.num] = action.text;
      return { ...state, [action.item]: itemCopy };
    default:
      return state;
  }
};

export const BillDestinationTable: React.FC<BillDestinationTableType> = (props) => {
  const { setCurrent, info } = props;
  const billCorporateInfo = [
    { type: '企業名', text: info?.name ?? '' },
    {
      type: '郵便番号',
      text: info?.postCode ?? ''
    },
    {
      type: '住所',
      text: info?.address ?? ''
    },
    {
      type: '部署名',
      text: info?.department ?? ''
    },
    {
      type: 'ご担当者様',
      text: info?.representative ?? ''
    },
    {
      type: '会社電話番号',
      text: info?.phone ?? ''
    }
  ];

  return (
    <div>
      <StyledBillDestinationTableContainer>
        <StyledBillDestinationTable>
          <tbody>
            {billCorporateInfo.map((item, index) => (
              <StyledBillDestinationTableRow key={index}>
                <StyledBillDestinationTableHeading>{item.type}</StyledBillDestinationTableHeading>
                <StyledBillDestinationTableData>{item.text}</StyledBillDestinationTableData>
              </StyledBillDestinationTableRow>
            ))}
          </tbody>
        </StyledBillDestinationTable>
      </StyledBillDestinationTableContainer>
      <StyledButtonContainer justifyContent="flex-end">
        <PrimaryButton
          width="120"
          onClick={() => {
            setCurrent(true);
          }}
        >
          変更する
        </PrimaryButton>
      </StyledButtonContainer>
    </div>
  );
};

export const BillDestinationInput: React.FC<BillDestinationTableType> = ({ setCurrent, info }) => {
  const [state, dispatch] = useReducer(changeInput, initialState);
  const [companyNameError, setCompanyNameError] = useState('none');
  const [postalCodeError, setPostalCodeError] = useState('none');
  const [streetAddressError, setStreetAddressError] = useState('none');
  const [representativeNameError, setRepresentativeNameError] = useState('none');
  const [phoneNumberError, setPhoneNumberError] = useState('none');
  const [isSubmit, setIsSubmit] = useState(false);
  const [updateBillingAddress, { called, loading }] = useMutation(UPDATE_BILLING_ADDRESS);

  const submit = () => {
    if (
      companyNameError === 'none' &&
      postalCodeError === 'none' &&
      streetAddressError === 'none' &&
      representativeNameError === 'none' &&
      phoneNumberError === 'none'
    ) {
      updateBillingAddress({
        variables: {
          input: {
            name: state.companyName,
            postCode:
              state.postalCode[0].length === 0 && state.postalCode[1].length === 0
                ? ''
                : state.postalCode.join('-'),
            address: state.streetAddress,
            department: state.departmentName,
            representative: state.representativeName,
            phone: state.phoneNumber.join('-')
          }
        }
      });
    }
    setIsSubmit(false);
  };

  const dispatchArray = (array: Array<string>) => {
    if (array.length === 1) return;
    array.forEach((li, i) => {
      dispatch({
        type: 'CHANGE_TEXT_NUMBER',
        text: li,
        item: array.length === 2 ? 'postalCode' : 'phoneNumber',
        stateArray: array.length === 2 ? 'postalCode' : 'phoneNumber',
        num: i
      });
    });
  };

  useEffect(() => {
    if (!info) return;
    const phone = info.phone.split('-');
    const postCode = info.postCode.split('-');
    dispatch({
      type: 'CHANGE_TEXT',
      text: info.name,
      item: 'companyName',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: info.address,
      item: 'streetAddress',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: info.department,
      item: 'departmentName',
      stateArray: null,
      num: 0
    });
    dispatch({
      type: 'CHANGE_TEXT',
      text: info.representative,
      item: 'representativeName',
      stateArray: null,
      num: 0
    });
    dispatchArray(postCode);
    dispatchArray(phone);
  }, []);

  useEffect(() => {
    if (!isSubmit) return;
    submit();
  }, [isSubmit]);

  useEffect(() => {
    if (!called || loading) return;
    setCurrent(false);
  }, [called, loading]);

  return (
    <>
      {called && loading ? (
        <Loader />
      ) : (
        <>
          <StyledBillDestinationTableContainer>
            <StyledFormItem marginTop="0">
              <CompanyName
                companyNameText="企業名"
                require={true}
                state={state.companyName}
                stateKey="companyName"
                dispatch={dispatch}
                error={companyNameError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <PostalCode
                state={state.postalCode}
                stateKey="postalCode"
                dispatch={dispatch}
                error={postalCodeError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <StreetAddress
                require={true}
                state={state.streetAddress}
                stateKey="streetAddress"
                dispatch={dispatch}
                error={streetAddressError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <DepartmentName
                state={state.departmentName}
                stateKey="departmentName"
                dispatch={dispatch}
              />
            </StyledFormItem>
            <StyledFormItem>
              <RepresentativeName
                require={true}
                state={state.representativeName}
                stateKey="representativeName"
                dispatch={dispatch}
                error={representativeNameError}
              />
            </StyledFormItem>
            <StyledFormItem>
              <PhoneNumber
                require={true}
                state={state.phoneNumber}
                stateKey="phoneNumber"
                dispatch={dispatch}
                error={phoneNumberError}
                isBill={true}
              />
            </StyledFormItem>
          </StyledBillDestinationTableContainer>
          <StyledButtonContainer>
            <MonochromeButton
              width="120"
              onClick={() => {
                setCurrent(false);
              }}
            >
              キャンセル
            </MonochromeButton>
            <PrimaryButton
              width="120"
              onClick={() => {
                Validation.bill(
                  state,
                  setCompanyNameError,
                  setPostalCodeError,
                  setStreetAddressError,
                  setRepresentativeNameError,
                  setPhoneNumberError
                );
                setIsSubmit(true);
              }}
            >
              変更する
            </PrimaryButton>
          </StyledButtonContainer>
        </>
      )}
    </>
  );
};

export const BillDestination: React.FC = () => {
  const [current, setCurrent] = useState(false);
  const [getBillingAddress, { data, loading }] = useLazyQuery(GET_BILLING_ADDRESS);

  useEffect(() => {
    if (current) return;
    getBillingAddress();
  }, [current]);

  const info = useMemo(() => data?.loginSession?.user?.organization?.billingAddress ?? null, [
    data
  ]);

  return (
    <div>
      {loading ? (
        <Loader />
      ) : current ? (
        <BillDestinationInput setCurrent={setCurrent} info={info}></BillDestinationInput>
      ) : (
        <BillDestinationTable setCurrent={setCurrent} info={info}></BillDestinationTable>
      )}
    </div>
  );
};

/* --------------------------------
  BillDestinationTableのスタイル
---------------------------------*/
const StyledBillDestinationTableContainer = styled.div`
  padding-top: 50px;
  padding-bottom: 30px;
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledBillDestinationTable = styled.table`
  width: 100%;
`;

const StyledBillDestinationTableRow = styled.tr`
  height: 70px;
  line-height: 70px;
`;

const StyledBillDestinationTableData = styled.td`
  padding-left: 30px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
`;

const StyledBillDestinationTableHeading = styled(StyledBillDestinationTableData)`
  background-color: ${COLOR_DEFINITIONS.LINE.SUB};
  width: 261px;
  box-sizing: border-box;
`;
