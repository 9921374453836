import React from 'react';
import styled from 'styled-components';
import 'dayjs/locale/ja';
import dayjs from 'dayjs';

export const insertComma = (price: number, cls?: string, unit: string = '円') => {
  if (!!cls) {
    return (
      <>
        {price.toLocaleString()}
        <StyledUnit cls={cls}>{unit}</StyledUnit>
      </>
    );
  } else {
    return <>{price.toLocaleString() + unit}</>;
  }
};

export const insertHyphen = (value: number | null) => {
  if (!value) return '';
  const date = value.toString();
  const month = `${date.slice(0, 4)}-${date.slice(4)}`;
  return month;
};

export const convertDate = (date: string | Date, format: string) => {
  return dayjs(date).format(format);
};

export const convertEndDate = (date: string | Date, format: string) => {
  return dayjs(date).endOf('month').format(format);
};

const StyledUnit = styled.span<{ cls?: string }>`
  ${({ cls }) =>
    `${
      cls === 'yen'
        ? `margin-left: 3px;
          font-size: 20px;`
        : null
    }`}
`;
