import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import auth, { authError, useLoginUser } from '@meettry/ui-components/utils/auth';

const ForgotPassInput = () => {
  const [stateNewPass1, setNewPass1] = useState('');
  const [stateNewPass2, setNewPass2] = useState('');

  const [errorMessage, setErrorMessage] = useState(null);
  const [validateMessage, setValidateMessage] = useState(null);

  const [isChangeOk, setIsChangeOk] = useState(false);

  const { authorized, initialized, user } = useLoginUser();
  const history = useHistory();

  if (initialized) {
    return <p>Loading ...</p>;
  }

  if (!authorized) {
    history.replace({ pathname: '/' });
    return;
  }

  const onChangeNewPassword1 = (e) => {
    setNewPass1(e.target.value || '');
  };
  const onChangeNewPassword2 = (e) => {
    setNewPass2(e.target.value || '');
  };

  const onSubmitNewPassword = (e) => {
    e.preventDefault();

    setErrorMessage(null);
    setValidateMessage(null);

    // パスワード入力項目１と２が一致しなければエラー
    if (stateNewPass1 !== stateNewPass2) {
      setValidateMessage('入力されたパスワードが一致しません');
      return null;
    }

    const password = stateNewPass1;

    return auth
      .updatePassword(password)
      .then(() => {
        setIsChangeOk(true);
      })
      .catch((error) => {
        const errorMsg = authError(error);
        setErrorMessage(errorMsg);
      });
  };

  const onSubmitMoveLoginPage = (e) => {
    e.preventDefault();
    history.push('/login');
  };

  const isDisplayErrorMessage = () => {
    return errorMessage !== undefined && errorMessage !== null;
  };

  const isDisplayValidateMessage = () => {
    return validateMessage !== undefined && validateMessage !== null;
  };

  return (
    <>
      {!isChangeOk && (
        <>
          <StyledPageTitle>パスワードの変更</StyledPageTitle>
          <StyledFormBox onSubmit={onSubmitNewPassword}>
            <StyledText>
              アカウント（<span style={{ fontWeight: 'bold' }}>{user.email}</span>
              ）の新しいパスワードを入力してください。
            </StyledText>
            <StyledFormItem
              data-error={isDisplayErrorMessage() || isDisplayValidateMessage()}
              style={{ marginTop: '20px' }}
            >
              <StyledLabel htmlFor="newPassword">新しいパスワード</StyledLabel>
              <StyledInput
                placeholder="新しいパスワード"
                type="password"
                onChange={onChangeNewPassword1}
                id="newPassword"
              ></StyledInput>
              {isDisplayErrorMessage() && <StyledErrorText>{errorMessage}</StyledErrorText>}
              {isDisplayValidateMessage() && <StyledErrorText>{validateMessage}</StyledErrorText>}
            </StyledFormItem>
            <StyledFormItem data-error={false}>
              <StyledLabel htmlFor="newPasswordConfirm">新しいパスワードを再入力</StyledLabel>
              <StyledInput
                placeholder="新しいパスワードを再入力"
                type="password"
                onChange={onChangeNewPassword2}
                id="newPasswordConfirm"
              ></StyledInput>
              <StyledErrorText>エラーです</StyledErrorText>
            </StyledFormItem>
            <StyledPrimaryBigButton style={{ marginTop: '20px' }}>
              パスワードを変更する
            </StyledPrimaryBigButton>
          </StyledFormBox>
        </>
      )}
      {isChangeOk && (
        <>
          <StyledPageTitle>パスワードの変更完了</StyledPageTitle>
          <StyledFormBox onSubmit={onSubmitMoveLoginPage}>
            <StyledText>
              パスワードの変更が完了しました。新しいパスワードでログインを行ってください。
            </StyledText>
            <StyledPrimaryBigButton style={{ marginTop: '20px' }}>
              ログインページへ戻る
            </StyledPrimaryBigButton>
          </StyledFormBox>
        </>
      )}
    </>
  );
};

export default ForgotPassInput;

// styled-components
const StyledFormBox = styled.form`
  position: relative;
  width: 460px;
  margin-left: auto;
  margin-right: auto;
  padding: 50px 50px 40px;
  background-color: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
`;

const StyledPageTitle = styled.h1`
  font-size: 32px;
  font-weight: bold;
  color: #0fafa9;
  text-align: center;

  & + * {
    margin-top: 40px;
  }
`;

const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  appearance: none;
  outline: none;
  width: 100%;
  height: 48px;
  font-size: 16px;
  background-color: #f7f7f7;
  border: 1px solid #eeeeee;
  border-radius: 4px;
  padding-left: 20px;
  padding-right: 20px;

  ${StyledLabel} + & {
    margin-top: 10px;
  }
`;

const StyledErrorText = styled.p`
  display: none;
  font-size: 12px;
  color: #f4233c;

  * + & {
    margin-top: 8px;
  }
`;

const StyledFormItem = styled.div`
  & + & {
    margin-top: 15px;
  }

  &[data-error='true'] ${StyledInput} {
    background-color: rgba(244, 35, 60, 0.1);
    border-color: #f4233c;
  }

  &[data-error='true'] ${StyledErrorText} {
    display: block;
  }
`;

const StyledPrimaryBigButton = styled.button`
  width: 100%;
  height: 60px;
  font-size: 18px;
  background-color: #0fafa9;
  border: 1px solid #0fafa9;
  color: #ffffff;
  transition: background 0.15s, color 0.15s, border 0.15s;
  border-radius: 5px;

  &:hover {
    cursor: pointer;
    background-color: #008681;
  }

  &[disabled] {
    cursor: default;
    color: #999999;
    background-color: #cccccc;
    border: 1px solid #cccccc;
  }
`;

const StyledText = styled.p`
  font-size: 14px;
  line-height: 1.75;
  color: #333333;
`;
