import React, { useState } from 'react';
import { Button } from '@meettry/company/component/atoms/btn';
import { PricePlan } from '@meettry/company/component/molecules/pricePlan';
import { Modal } from '@meettry/company/component/organisms/modal';

const contractStatus = {
  underContract: { text: '契約中', cls: 'second under-contract' },
  unContracted: { text: '契約する', cls: 'primary' },
  applyingChange: { text: '変更申請中', cls: 'applying-change' },
  receptionCompleted: { text: '受付完了翌月適用開始', cls: 'reception-completed' }
};

const PLAN_LIST = [
  {
    price: 0,
    name: 'プラン',
    text: 'テキストが入ります',
    contractStatus: contractStatus.underContract,
    recommend: false
  },
  {
    price: 0,
    name: 'プラン',
    text: 'テキストが入ります',
    contractStatus: contractStatus.applyingChange,
    recommend: false
  },
  {
    price: 0,
    name: 'プラン',
    text: 'テキストが入ります',
    contractStatus: contractStatus.unContracted,
    recommend: true
  }
];

type ModalProps = {
  show: boolean;
  displayModal: Function;
};

const ChangePlanModal: React.FC<ModalProps> = (props) => {
  return (
    <Modal
      show={props.show}
      displayModal={props.displayModal}
      title="プラン変更"
      cls="contract-modal"
    >
      <p className="change-plan-desc">
        有料プランから変更の場合、月末20日以降は翌々月の適用となります
      </p>
      <div className="change-plan-contents">
        <div className="change-plan-content">
          <span>フリープラン</span>
          <span className="active">ベーシックプラン</span>
        </div>
        <span className="desc">への変更を希望します</span>
      </div>
      <div className="change-plan-button">
        <Button
          buttonText="キャンセル"
          cls="btn third middle"
          onClick={() => {
            props.displayModal(false);
          }}
        />
        <Button
          buttonText="決定"
          cls="btn primary middle"
          onClick={() => {
            props.displayModal(false);
          }}
        />
      </div>
    </Modal>
  );
};

const ApplyingModal: React.FC<ModalProps> = (props) => {
  const [cancelApplication, changeCancelApplication] = useState(false);
  return (
    <Modal
      show={props.show}
      displayModal={props.displayModal}
      title={!cancelApplication ? 'プラン変更（変更申請中）' : 'プラン変更申請をキャンセルしました'}
      cls="contract-modal"
      modalBackClick={!!cancelApplication ? () => changeCancelApplication(false) : null}
    >
      {!cancelApplication ? (
        <>
          <p className="change-plan-desc">
            <span>20日までの申請は翌月1日からの適用になります。</span>
            <span>21日以降の申請は翌々月からの適用になります。</span>
          </p>
          <div className="change-plan-contents">
            <div className="change-plan-content">
              <span>フリープラン</span>
              <span className="active">ベーシックプラン</span>
            </div>
            <span className="desc">への変更を申請中です</span>
          </div>
        </>
      ) : null}
      <div className="change-plan-button applying-change-button">
        <Button
          buttonText={!cancelApplication ? '変更せず閉じる' : '閉じる'}
          cls="btn third middle"
          onClick={() => {
            props.displayModal(false);
            !!cancelApplication && changeCancelApplication(false);
          }}
        />
        {!cancelApplication ? (
          <Button
            buttonText="変更申請をキャンセル"
            cls="btn primary middle"
            onClick={() => {
              changeCancelApplication(true);
            }}
          />
        ) : null}
      </div>
    </Modal>
  );
};

const ContractPlan: React.FC = () => {
  const [showChangePlan, displayChangePlanModal] = useState(false);
  const [showApplying, displayApplyingModal] = useState(false);
  return (
    <div className="setting-container contract-plan">
      <h2>契約プラン</h2>
      <div className="contract-plan-contents">
        {PLAN_LIST.map((item, index) => (
          <PricePlan
            key={index}
            planList={item}
            displayModal={
              item.contractStatus.text === '契約する'
                ? displayChangePlanModal
                : item.contractStatus.text === '変更申請中'
                ? displayApplyingModal
                : null
            }
          ></PricePlan>
        ))}
      </div>
      <ChangePlanModal show={showChangePlan} displayModal={displayChangePlanModal} />
      <ApplyingModal show={showApplying} displayModal={displayApplyingModal} />
    </div>
  );
};

export default ContractPlan;
