import React from 'react';
import { TextField } from '@meettry/engineer/components/atoms/Input';

export const InputLocation = (props) => {
  const { user, handleChangeUser } = props;
  return (
    <TextField
      type="text"
      defaultValue={user.location}
      onChange={handleChangeUser('location')}
      placeholder="東京都/渋谷"
    />
  );
};
