import React, { useState, useMemo } from 'react';
import styled from 'styled-components';
import { TagType, ChallengeCreateValueType } from '@meettry/ui-components/types/userPage';
import BaseModal from '@meettry/ui-components/components/organisms/Modal/BaseModal';
import AdditionalTagInput from '@meettry/ui-components/components/organisms/Forms/AdditionalTagInput';
import {
  Textarea,
  TextField,
  RadioButton,
  ProgressInput
} from '@meettry/ui-components/components/atoms/Input';
import { FormItem } from '@meettry/ui-components/components/molecules/Form';
import { PrimaryButton, MonochromeButton } from '@meettry/ui-components/components/atoms/Button';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import { CHALLENGE_FORM_INPUT } from '@meettry/ui-components/constants';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type ChallengeCreateType = {
  onSubmit: (newChallenge: ChallengeCreateValueType) => void;
  onClose: () => void;
  loading: boolean;
};

const initNewState = {
  title: '',
  progressRate: 0,
  challengeDetail: '',
  tags: [],
  isPinned: false,
  isVisible: false
};

export const ChallengeCreate: React.FC<ChallengeCreateType> = (props) => {
  const { onClose, onSubmit, loading } = props;
  const {
    showCancelConfirmationDialog,
    showVisibleRegisterConfirmationDialog,
    closeDialog
  } = useDialog();
  const [dirty, setDirty] = useState(false);
  const [progressRate, changeProgressRate] = useState(0);
  const [challengeInput, setChallengeInput] = useState<ChallengeCreateValueType>(initNewState);
  //MEMO(aida) 必須入力項目のチェック
  const [formFillError, setFormFillError] = useState({
    title: false,
    challengeDetail: false
  });
  //MEMO(aida) 入力文字数をチェックする
  const [formInputError, setFormInputError] = useState({
    title: false,
    challengeDetail: false
  });
  const inputCheckSubjects = Object.keys(formFillError);

  const onChangeProgressRate = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    dirty || setDirty(true);
    changeProgressRate(parseInt(e.target.value));
  };
  const onClickAchieveButton = (add: boolean) => (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    dirty || setDirty(true);
    if (!progressRate && !add) return;
    if (progressRate === 100 && add) return;
    const val = add ? progressRate + 1 : progressRate - 1;
    changeProgressRate(val);
  };
  const onChangeValue = (target: string) => (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    dirty || setDirty(true);
    const { value } = e.target;
    if (inputCheckSubjects.includes(target)) {
      setFormFillError({ ...formFillError, [target]: !value });
    }
    if (target === 'title') {
      if (!(value.length > CHALLENGE_FORM_INPUT.LENGTH.TITLE)) {
        setFormInputError({ ...formInputError, [target]: false });
        setChallengeInput({ ...challengeInput, [target]: value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    if (target === 'challengeDetail') {
      if (!(value.length > CHALLENGE_FORM_INPUT.LENGTH.CHALLENGE_DETAIL)) {
        setFormInputError({ ...formInputError, [target]: false });
        setChallengeInput({ ...challengeInput, [target]: value });
        return;
      }
      setFormInputError({ ...formInputError, [target]: true });
    }
    setChallengeInput({ ...challengeInput, [target]: value });
  };

  const onChangeTags = (value: Array<TagType>) => {
    dirty || setDirty(true);
    setChallengeInput({ ...challengeInput, tags: value });
  };
  const onChangeRadioButton = (target: string) => (value: boolean) => {
    dirty || setDirty(true);
    setChallengeInput({ ...challengeInput, [target]: value });
  };

  const onCancelHandle = () => {
    if (dirty) {
      showCancelConfirmationDialog(['保存されていない変更を破棄しますか？'], onClose, closeDialog);
      return;
    }
    onClose();
  };
  const onSubmitHandle = () => {
    const { title, challengeDetail } = challengeInput;
    if (!title || !challengeDetail) {
      setFormFillError({ title: !title, challengeDetail: !challengeDetail });
      return;
    }
    if (Object.values(formInputError).find((val) => val === true)) return;
    const newChallenge = {
      ...challengeInput,
      progressRate,
      isPinned: false
    };
    if (!newChallenge.isVisible) {
      showVisibleRegisterConfirmationDialog(
        [
          '登録されたチャレンジを他のユーザーが閲覧・検索することができないため、',
          'スカウト等が受け取りにくくなりますがよろしいでしょか？'
        ],
        () => {
          closeDialog();
          newChallenge.isVisible = !newChallenge.isVisible;
          onSubmit(newChallenge);
        },
        () => {
          closeDialog();
          onSubmit(newChallenge);
        }
      );
      return;
    }
    onSubmit(newChallenge);
  };

  const titleErrorText = useMemo(
    () =>
      formFillError.title
        ? '項目が未入力です'
        : formInputError.title
        ? 'タイトルは50文字までです'
        : '',
    [formFillError.title, formInputError.title]
  );
  const detailErrorText = useMemo(
    () =>
      formFillError.challengeDetail
        ? '項目が未入力です'
        : formInputError.challengeDetail
        ? 'チャレンジ説明は400文字までです'
        : '',
    [formFillError.challengeDetail, formInputError.challengeDetail]
  );

  const Header = <StyledChallengeCreateHeader>チャレンジの新規作成</StyledChallengeCreateHeader>;
  const Content = (
    <StyledChallengeCreateContent>
      <FormItem
        label="タイトル"
        required={true}
        error={formFillError.title || formInputError.title}
        errorText={titleErrorText}
      >
        <TextField
          width="100%"
          placeholder="チャレンジのタイトルを入力してください"
          onChange={onChangeValue('title')}
          defaultValue={challengeInput.title}
          error={formFillError.title || formInputError.title}
        />
      </FormItem>
      <FormItem label="達成率">
        <ProgressInput
          onClickMinus={onClickAchieveButton(false)}
          onClickPlus={onClickAchieveButton(true)}
          progressRate={progressRate}
          onChangeProgressRate={onChangeProgressRate}
        />
      </FormItem>
      <FormItem label="言語・環境">
        <AdditionalTagInput
          placeholder="言語・環境を入力"
          value={challengeInput.tags}
          onChange={onChangeTags}
        />
      </FormItem>
      <FormItem
        label="チャレンジ説明"
        required={true}
        error={formFillError.challengeDetail || formInputError.challengeDetail}
        errorText={detailErrorText}
      >
        <Textarea
          placeholder="現在自身のスキルアップのためにチャレンジしている内容の説明を記載ください。(400文字)"
          onChange={onChangeValue('challengeDetail')}
          error={formFillError.challengeDetail || formInputError.challengeDetail}
        />
      </FormItem>
      <FormItem label="ステータス">
        <StyledRadioGroup>
          <RadioButton
            label="公開"
            name="status"
            checked={challengeInput.isVisible === true}
            value={true}
            onChange={onChangeRadioButton('isVisible')}
          />
          <RadioButton
            label="非公開"
            name="status"
            checked={challengeInput.isVisible === false}
            value={false}
            onChange={onChangeRadioButton('isVisible')}
          />
        </StyledRadioGroup>
      </FormItem>
    </StyledChallengeCreateContent>
  );
  const Footer = (
    <StyledChallengeCreateFooter>
      <MonochromeButton onClick={onCancelHandle}>キャンセル</MonochromeButton>
      <PrimaryButton disabled={loading} onClick={onSubmitHandle}>
        新規作成
      </PrimaryButton>
    </StyledChallengeCreateFooter>
  );

  return (
    <form onSubmit={onSubmitHandle}>
      <BaseModal Header={Header} Footer={Footer}>
        {Content}
      </BaseModal>
    </form>
  );
};

export default ChallengeCreate;

const StyledRadioGroup = styled.div`
  > *:not(:first-child) {
    margin-left: 50px;
  }
`;

const StyledChallengeCreateHeader = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding: 40px 0 20px;
  margin: 0 40px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const StyledChallengeCreateContent = styled.div`
  padding: 30px 40px 30px;
  > *:not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledChallengeCreateFooter = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  padding: 20px 0 30px;
  margin: 0 40px;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  > * {
    max-width: 120px;
  }
`;
