import React from 'react';
import GoogleForm from '@meettry/ui-components/components/organisms/ContactForm';

const Contact = () => {
  return (
    <GoogleForm />
    // MEMO(中山):MTY-1415で対応
    // <ContactForm
    //   /* TODO(中山)titleは変える予定 */
    //   title="問い合わせ"
    //   actionName="https://docs.google.com/forms/u/0/d/e/1FAIpQLSf45tXhZkOA5ce1OqJ8TviXq0ewWAelztbSLx-QFlK9uoR-NA/formResponse"
    //   nameInputKey="entry.2099059095"
    //   mailInputKey="entry.1496580416"
    //   inquiryTypeInputKey="entry.1697989023"
    //   inquiryContentInputKey="entry.1532249611"
    //   agreeInputKey="entry.157995062"
    //   optionArray={['会員登録、ログインについて', 'meettry（メトリィ）について', 'その他']}
    // />
  );
};

export default Contact;
