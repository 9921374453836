import React from 'react';
import styled from 'styled-components';
import ActivityTimeline from '@meettry/ui-components/components/organisms/UserPage/Overview/ActivityTimeline';
import ChallengeParts from '@meettry/ui-components/components/organisms/UserPage/Overview/OverviewParts/ChallengeParts';
import ResumeParts from '@meettry/ui-components/components/organisms/UserPage/Overview/OverviewParts/ResumeParts';
import icons from '@meettry/ui-components/images/icons';

type OverViewType = {
  isOwner: boolean;
};

const Overview: React.FC<OverViewType> = (props) => {
  const { isOwner } = props;
  return (
    <>
      <StyledContentsContainer>
        <StyledContentsTitle>
          <StyledIcon src={icons.black.challenge} />
          <span>チャレンジ</span>
        </StyledContentsTitle>
        <ChallengeParts />
      </StyledContentsContainer>
      <StyledContentsContainer>
        <StyledContentsTitle>
          <StyledIcon src={icons.black.resume} />
          <span>経 歴</span>
        </StyledContentsTitle>
        <ResumeParts />
      </StyledContentsContainer>
      {isOwner ? (
        <StyledContentsContainer>
          <StyledContentsTitle>更新履歴</StyledContentsTitle>
          <ActivityTimeline />
        </StyledContentsContainer>
      ) : null}
    </>
  );
};

export default Overview;

const StyledContentsContainer = styled.div`
  position: relative;
  :not(:first-child) {
    margin-top: 50px;
  }
`;

const StyledContentsTitle = styled.p`
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: bold;
  > *:not(:first-child) {
    margin-left: 10px;
  }
`;

const StyledIcon = styled.img`
  width: 20px;
`;
