import React, { useEffect } from 'react';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/react-hooks';
import { useLogout, useLoginUser } from '@meettry/ui-components/utils/auth';
import { useHistory, useLocation } from 'react-router-dom';
import { useLoginUser as useLoginUserHooks } from '@meettry/ui-components/hooks/useLoginUser';
import { GET_AVATAR_IMAGE_PATH } from '@meettry/ui-components/queries/header';
import HoverableHeaderIcon from '@meettry/ui-components/components/molecules/HoverableHeaderIcon';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import iconCompanyGray from '@meettry/ui-components/images/avatar/icon_company_gray.png';

export const HeaderUserProfile = () => {
  const history = useHistory();
  const location = useLocation();
  const [logout] = useLogout();
  const {
    isEnterprise,
    user: { nickname }
  } = useLoginUser();
  const { setStoreProfileImageUrl, state: loginUserState } = useLoginUserHooks();
  const { data, loading: avatarLoading } = useQuery(GET_AVATAR_IMAGE_PATH);

  const onClickLogout = (e: any) => {
    e.preventDefault();
    logout();
    history.push(location.pathname.includes('/enterprise') ? '/enterprise' : '/');
  };

  useEffect(() => {
    if (
      !avatarLoading &&
      data &&
      data.loginSession.user &&
      data.loginSession.user.organization &&
      !data.loginSession.user.organization.logoImage
    ) {
      setStoreProfileImageUrl(iconCompanyGray);
    } else if (
      !avatarLoading &&
      data &&
      data.loginSession.user &&
      data.loginSession.user.organization &&
      data.loginSession.user.organization.logoImage
    ) {
      setStoreProfileImageUrl(data.loginSession.user.organization.logoImage.url);
    } else if (
      !avatarLoading &&
      data &&
      data.loginSession.user &&
      data.loginSession.user.profileImage
    ) {
      setStoreProfileImageUrl(data.loginSession.user.profileImage.url);
    }
  }, [data, avatarLoading]);

  const onClickIcon = () => {
    // MEMO(oomura): 企業βリリース時はダッシュボードページを外すため、scoutページに変更してます。
    history.push(isEnterprise ? '/enterprise/scout' : `/user/${nickname}`);
  };

  const onClickMenuItem = (path: string) => (e: any) => {
    e.preventDefault();
    history.push(path);
  };

  const profile = (
    <StyledProfileMenu>
      {isEnterprise ? (
        <StyledProfileMenuItem>
          <p onClick={onClickMenuItem('/enterprise/settings/company')}>設定</p>
        </StyledProfileMenuItem>
      ) : (
        <StyledProfileMenuItem>
          <p onClick={onClickMenuItem('/profile')}>プロフィール編集</p>
        </StyledProfileMenuItem>
      )}
      <StyledProfileMenuItem>
        <p onClick={onClickMenuItem('/change-email')}>メールアドレス変更</p>
      </StyledProfileMenuItem>
      <StyledProfileMenuItem>
        <p onClick={onClickMenuItem('/change-password')}>パスワード変更</p>
      </StyledProfileMenuItem>
      <StyledProfileMenuItem warning={true} onClick={onClickLogout}>
        ログアウト
      </StyledProfileMenuItem>
    </StyledProfileMenu>
  );

  return (
    <HoverableHeaderIcon hoverVisible HoverComponent={profile} onClick={onClickIcon}>
      <CircleAvatar
        name="user_avatar"
        style={{ width: '40px', minHeight: '40px' }}
        src={loginUserState.profileImageUrl}
      />
    </HoverableHeaderIcon>
  );
};

export default HeaderUserProfile;

const StyledProfileMenu = styled.ul`
  width: 100%;
  min-width: 180px;
`;

const StyledProfileMenuItem = styled.li<{ warning?: boolean }>`
  color: #333333;
  display: flex;
  align-items: center;
  cursor: pointer;
  min-height: 50px;
  padding: 0 10px;
  transition: background-color ease 0.3s;
  :not(:first-child) {
    border-top: 1px solid #eeeeee;
  }
  :hover {
    background-color: #f7f7f7;
  }
  ${({ warning }) =>
    warning &&
    css`
      :hover {
        background-color: #f7e4e6;
        color: #f4233c;
      }
    `}
  p {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 50px;
    color: #333333;
  }
`;
