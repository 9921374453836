import { createMuiTheme } from '@material-ui/core/styles';

export default createMuiTheme({
  themeName: 'Sick Theme',
  palette: {
    primary: {
      light: '#484848',
      main: '#212121',
      dark: '#000000',
      contrastText: '#ffffff'
    },
    secondary: {
      light: '#ffffff',
      main: '#fafafa',
      dark: '#c7c7c7',
      contrastText: '#000000'
    }
  },
  typography: {
    // fontFamily: 'Jaldi, "游ゴシック体", YuGothic, "游ゴシック Medium", "Yu Gothic Medium", "游ゴシック", "Yu Gothic", sans-serif',
    button: {
      textTransform: 'none'
    }
  },
  mixins: {
    toolbar: {
      minHeight: '42px',
      width: '80%',
      maxWidth: '1280px',
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  }
});
