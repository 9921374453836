import React, { ChangeEvent, useState, useRef } from 'react';
import styled, { css } from 'styled-components';
import Downshift from 'downshift';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { TextField } from '@meettry/ui-components/components/atoms/Input';

/**
 * TagTextField Component
 * テキスト入力で、該当するタグをオートコンプリートで表示させる入力コンポーネント
 *
 */
export type TagTextFieldItem = {
  key: string;
  value: any;
};
type Props = {
  items: TagTextFieldItem[];
  placeholder?: string;
  onChange: (result: { item: TagTextFieldItem; year: string | null }) => void;
  onKeyDown?: () => void;
  enableYear?: boolean;
};

const TagTextField: React.FC<Props> = (props) => {
  const { items, placeholder = '', onChange, onKeyDown, enableYear = false } = props;
  const [year, setYear] = useState('');
  const yearFieldRef = useRef<HTMLInputElement | null>(null);

  const addTag = (selectedItem: TagTextFieldItem, clearSelection: Function) => {
    if (selectedItem === null) return;
    onChange({ item: selectedItem, year: year ?? null });
    clearSelection();
    setYear('');
  };

  const onChangeYearWithPatternCheck = (e: ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value.match(/^([1-9]\d*|0)(\.\d+)?$/) || value === '') {
      setYear(value);
    }
  };

  return (
    <Downshift itemToString={(item) => item?.value ?? ''} defaultHighlightedIndex={0}>
      {({
        isOpen,
        getRootProps,
        getInputProps,
        getMenuProps,
        getItemProps,
        inputValue,
        selectedItem,
        clearSelection,
        highlightedIndex
      }) => (
        <div {...getRootProps({ refKey: 'ref' }, { suppressRefError: true })}>
          <StyledInputContainer>
            <StyledTextfieldContainer>
              <StyledTextfield
                {...getInputProps({
                  placeholder: placeholder,
                  onKeyPress: (e) => {
                    if (e.key === 'Enter') {
                      !!enableYear
                        ? yearFieldRef.current?.focus()
                        : addTag(selectedItem, clearSelection);
                    }
                  }
                })}
              />
              {isOpen && (
                <StyledTagCandidate {...getMenuProps()}>
                  {items
                    .filter((filterItem) => !inputValue || filterItem.value.includes(inputValue))
                    .map((item, index) => (
                      <StyledTagCandidateListItem
                        {...getItemProps({ key: item.key, index, item })}
                        active={selectedItem === item}
                        highlight={highlightedIndex === index}
                      >
                        {item.value}
                      </StyledTagCandidateListItem>
                    ))}
                </StyledTagCandidate>
              )}
            </StyledTextfieldContainer>
            {enableYear && (
              <StyledYearTextFieldContainer>
                <StyledYearField
                  type="text"
                  placeholder="年数"
                  value={year}
                  onChange={onChangeYearWithPatternCheck}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter') addTag(selectedItem, clearSelection);
                  }}
                  ref={yearFieldRef}
                />
                年
              </StyledYearTextFieldContainer>
            )}
            <div>
              <LineButton
                size={STYLE_TYPE.SIZE.MEDIUM}
                addStyle={{ width: '80px', height: '40px' }}
                onClick={() => addTag(selectedItem, clearSelection)}
              >
                追加
              </LineButton>
            </div>
          </StyledInputContainer>
        </div>
      )}
    </Downshift>
  );
};
export default TagTextField;

const StyledInputContainer = styled.div`
  display: flex;

  & > * + * {
    margin-left: 10px;
  }
`;

const StyledTagCandidate = styled.ul`
  z-index: 1;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.MAIN};
  overflow-x: hidden;
  overflow-y: scroll;
  max-height: 10rem;
`;

const StyledTagCandidateListItem = styled.li<{ active: boolean; highlight: boolean }>`
  padding: 5px 10px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  ${({ active }) => active && `color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};`}
  ${({ highlight }) => highlight && `background-color: ${COLOR_DEFINITIONS.BG.SUB};`}
  
  &:hover {
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
  }

  & + & {
    border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SUB};
  }
`;

const StyledTextfield = styled.input<{ error?: boolean }>`
  width: 100%;
  max-height: 40px;
  font-size: 16px;
  padding: 16px 20px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  border: ${COLOR_DEFINITIONS.LINE.SECONDARY} solid 1px;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border: 1px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-radius: 4px;
  }
  ${({ error }) =>
    error &&
    css`
      background-color: ${COLOR_DEFINITIONS.BG.MODAL.MESSAGE};
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      :focus {
        outline: none;
        border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      }
    `}
`;

const StyledTextfieldContainer = styled.div`
  position: relative;
  width: 100%;
`;

const StyledYearTextFieldContainer = styled.div`
  display: flex;
  align-items: center;
`;

const StyledYearField = styled(TextField)`
  width: 80px;
  height: 40px;
  padding-left: 20px;
  padding-right: 10px;
  margin-right: 10px;
`;
