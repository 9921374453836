import React, { ReactElement, useRef } from 'react';
import styled from 'styled-components';

type ChildProps = {
  title?: string;
};

type Props = {
  children: React.ReactElement<ChildProps>,
  onChange?: (index: number) => void;
  activeIndex: number;
  className?: string;
};

const Accordion: React.FC<Props> = (props) => {
  const { className, children, activeIndex, onChange = () => {} } = props;

  /**
   * State
   */
  const refs = useRef<(HTMLDivElement | null)[]>([]);

  /**
   * Func: getHeight
   */
  const getHeight = (index: number) => {
    if (index === activeIndex && refs?.current) {
      return refs.current.length > index ? refs.current[index]?.lastElementChild?.lastElementChild?.clientHeight : 'auto'
    }
    return 0;
  };

  return (
    <StyledAccordion className={className}>
      {
        React.Children.map<ReactElement, ReactElement<ChildProps>>(children, (child, index) => (
          <div ref={(elm) => refs.current[index] = elm}>
            { child.props.title && <StyledAccordionHeader onClick={() => onChange(index)}>{
              child.props.title
            }</StyledAccordionHeader> }
            <StyledAccordionBody style={{ height: getHeight(index) }}>
              <div>
                { child }
              </div>
            </StyledAccordionBody>
          </div>
        ))
      }
    </StyledAccordion>
  );
};
export default Accordion;

const StyledAccordion = styled.div`
`;

const StyledAccordionHeader = styled.div`
`;

const StyledAccordionBody = styled.div`
  overflow: hidden;
  transition: height .5s;
`
