import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { useMutation } from '@apollo/react-hooks';
import { ResumeType } from '@meettry/ui-components/types/userPage';
import ResumeBox from '@meettry/ui-components/components/organisms/UserPage/Resume/ReumeBox';
import { ResumeEdit } from '@meettry/ui-components/components/organisms/UserPage/Resume/ResumeForm';
import { makeHandleSubmit } from '@meettry/ui-components/components/organisms/UserPage/Resume/util';
import { NoItemNoticeBox } from '@meettry/ui-components/components/organisms/NoticeBox';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { LineButton } from '@meettry/ui-components/components/atoms/Button';
import { ResumePinNotation } from '@meettry/ui-components/components/organisms/UserPage/Overview/OverviewParts/PinNotation';
import { useResume, useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import {
  UPDATE_RESUME,
  UPDATE_RESUME_PINNED,
  UPDATE_RESUME_VISIBLE,
  DELETE_RESUME
} from '@meettry/ui-components/queries/user_page';
import { ListAnimation } from '@meettry/ui-components/utils/animation';
import { STYLE_TYPE } from '@meettry/ui-components/constants';

const ResumeParts = () => {
  const { state, isOwner } = useEngineer();
  const history = useHistory();
  const location = useLocation();
  const { pathname } = location;
  const { resumeList } = state;
  const { startLoading, endLoading } = useLoading();
  const isPinnedResumeList = resumeList.items.filter((item) => item.isPinned);
  const { updateResume, removeResume, pinResume, visibleResume } = useResume();
  const { showDeleteConfirmationDialog, closeDialog } = useDialog();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const [isEditMode, setIsEditMode] = useState(false);
  const [editingItemId, setEditingItemId] = useState<string | null>(null);

  const [
    mutateUpdateResume,
    { error: updateResumeError, loading: updateResumeLoading }
  ] = useMutation(UPDATE_RESUME, {
    onCompleted: (data) => {
      if (!data) return;
      const { updateResume: resUpdateResume } = data;
      if (!resUpdateResume) return;
      const { resume } = resUpdateResume;
      updateResume(resume);
      setIsEditMode(false);
      setEditingItemId(null);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  const [
    mutateDeleteResume,
    { loading: deleteResumeLoading, error: deleteResumeError }
  ] = useMutation(DELETE_RESUME, {
    onCompleted: ({ deleteResume }) => {
      if (!deleteResume) return;
      if (!deleteResume.id) return;
      removeResume(deleteResume.id);
      endLoading();
      showSuccessPopup('経歴を削除しました');
    }
  });

  const [
    mutateUpdateResumePinned,
    { loading: updateResumePinnedLoading, error: updateResumePinnedError }
  ] = useMutation(UPDATE_RESUME_PINNED, {
    onCompleted: ({ updateResume }) => {
      if (!updateResume) return;
      const { resume } = updateResume;
      pinResume(resume);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  const [
    mutateUpdateResumeVisible,
    { loading: updateResumeVisibleLoading, error: updateResumeVisibleError }
  ] = useMutation(UPDATE_RESUME_VISIBLE, {
    onCompleted: ({ updateResume }) => {
      if (!updateResume) return;
      const { resume } = updateResume;
      visibleResume(resume);
      endLoading();
      showSuccessPopup('経歴を更新しました');
    }
  });

  //MEMO(aida) 経歴更新系のエラーハンドリング。onErrorが動かないのでuseEffectでErrorStateを監視する
  useEffect(() => {
    const isError =
      deleteResumeError || updateResumeVisibleError || updateResumePinnedError || updateResumeError;
    if (!isError) return;
    endLoading();
    if (deleteResumeError) showErrorPopup('経歴を削除できませんでした');
    if (updateResumeVisibleError) showErrorPopup('経歴を更新できませんでした');
    if (updateResumePinnedError) showErrorPopup('経歴を更新できませんでした');
    if (updateResumeError) showErrorPopup('経歴を更新できませんでした');
  }, [deleteResumeError, updateResumePinnedError, updateResumeVisibleError, updateResumeError]);

  const onUpdateResume = (resume: ResumeType) => {
    if (updateResumeLoading) return;
    startLoading('経歴を更新しています。');
    makeHandleSubmit(resume, mutateUpdateResume)();
  };

  const updateResumePinned = (resume: ResumeType) => {
    if (updateResumePinnedLoading) return;
    mutateUpdateResumePinned({ variables: { id: resume.id, isPinned: !resume.isPinned } });
    startLoading('経歴を更新しています。');
  };

  const updateResumeVisible = (resume: ResumeType) => {
    if (updateResumeVisibleLoading) return;
    mutateUpdateResumeVisible({ variables: { id: resume.id, isVisible: !resume.isVisible } });
    startLoading('経歴を更新しています。');
  };

  const onUpdateResumeVisible = (resume: ResumeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    updateResumeVisible(resume);
  };

  const onUpdateResumePinned = (resume: ResumeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    updateResumePinned(resume);
  };

  const onDeleteResume = (resume: ResumeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    const deleteResume = () => {
      if (deleteResumeLoading) return;
      closeDialog();
      mutateDeleteResume({ variables: { input: { id: resume.id } } });
      startLoading('経歴を削除しています。');
    };
    showDeleteConfirmationDialog(
      [
        `${resume.title}の経歴詳細を削除いたします。`,
        `削除されたデータは、復元できませんがよろしいでしょうか？`
      ],
      deleteResume,
      closeDialog
    );
  };

  const toResumePage = () => {
    history.push(`${pathname}/resume`);
  };

  const onCreateNewResume = () => {
    history.push(`${pathname}/resume`, { addMode: true });
  };

  const onEditResume = (resume: ResumeType) => (e: React.MouseEvent<HTMLLIElement>) => {
    e.preventDefault();
    setIsEditMode(true);
    setEditingItemId(resume.id);
  };

  const onClickResumeTitle = (id: string) => () => {
    history.push(`${pathname}/resume`, { selectedResumeId: id });
  };

  const onCloseEditMode = () => {
    setIsEditMode(false);
    setEditingItemId(null);
    closeDialog();
  };

  return (
    <>
      {isOwner ? (
        <StyledAddButtonWrapper>
          <LineButton size={STYLE_TYPE.SIZE.SMALL} onClick={onCreateNewResume}>
            <StyledIcon iconColor="primary" iconName="plus" />
            新規作成
          </LineButton>
        </StyledAddButtonWrapper>
      ) : null}
      {!isPinnedResumeList.length ? (
        isOwner ? (
          <div style={{ marginTop: '20px' }}>
            <ResumePinNotation onClick={toResumePage} />
          </div>
        ) : (
          <NoItemNoticeBox contents={['ピン留めされた経歴はまだありません']} />
        )
      ) : null}
      {isPinnedResumeList.length ? (
        <StyledWrapper>
          {isPinnedResumeList.map((resume) => {
            const isItemEditMode = isEditMode && editingItemId === resume.id;
            return (
              <CSSTransition
                key={resume.id}
                timeout={ListAnimation.timeout}
                classNames={ListAnimation.classNames}
              >
                <StyledResumeItemWrapper key={resume.id}>
                  {!isItemEditMode && (
                    <ResumeBox
                      onClickTitle={onClickResumeTitle(resume.id)}
                      resume={resume}
                      isOwner={isOwner}
                      onEdit={onEditResume}
                      onVisible={onUpdateResumeVisible}
                      onPinned={onUpdateResumePinned}
                      onDelete={onDeleteResume}
                    />
                  )}
                  {isItemEditMode && (
                    <ResumeEdit
                      onClose={onCloseEditMode}
                      onSubmit={onUpdateResume}
                      resume={resume}
                      loading={updateResumeLoading}
                    />
                  )}
                </StyledResumeItemWrapper>
              </CSSTransition>
            );
          })}
        </StyledWrapper>
      ) : null}
    </>
  );
};

export default ResumeParts;

const StyledAddButtonWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
`;

const StyledIcon = styled(Icon)`
  margin-right: 5px;
`;

const StyledResumeItemWrapper = styled(ListAnimation.animation)`
  position: relative;
  :not(:first-child) {
    margin-top: 20px;
  }
`;

const StyledWrapper = styled(TransitionGroup)`
  margin-top: 20px;
`;
