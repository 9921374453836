import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import { useLoginUser as useLoginUserHook } from '@meettry/ui-components/hooks/useLoginUser';
import { useEngineer } from '@meettry/ui-components/hooks/useUserPage';
import useLoading from '@meettry/ui-components/hooks/useLoading';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

// MEMO(aida) 開発環境によってpathを切り分け
const getUploadProfileImagePath = () => {
  const isDevelopment = process.env.NODE_ENV === 'development';
  return isDevelopment
    ? `http://${process.env.REACT_APP_APIHOST}/upload/profile_image`
    : `https://${process.env.REACT_APP_APIHOST}/upload/profile_image`;
};

const UserProfileAvatar = () => {
  const {
    state: { profileImage },
    isOwner
  } = useEngineer();
  const { authorized, idToken } = useLoginUser();
  const { startLoading, endLoading } = useLoading();
  const { setStoreProfileImageUrl } = useLoginUserHook();
  const { showSuccessPopup, showErrorPopup } = usePopup();
  const [profileImageSrc, setProfileImageSrc] = useState('');

  // ファイルをエンコードして送信
  const onChangeImage = ({ target }: { target: HTMLInputElement }) => {
    const url = getUploadProfileImagePath();
    const file = target.files && target.files[0];
    const xhr = new XMLHttpRequest();
    xhr.open('POST', url, true);
    xhr.setRequestHeader('Authorization', `Bearer ${idToken}`);
    xhr.onload = () => {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const res = JSON.parse(xhr.responseText);
          const newProfileImageSrc = res.url_large;
          setProfileImageSrc(newProfileImageSrc);
          setStoreProfileImageUrl(newProfileImageSrc);
          endLoading();
          showSuccessPopup('プロフィールを更新しました');
        } else {
          console.error(xhr.statusText);
          endLoading();
          showErrorPopup('プロフィールを更新できませんでした');
        }
      }
    };
    xhr.send(file);
    startLoading('プロフィール画像を更新中...');
  };

  useEffect(() => {
    setProfileImageSrc(!!profileImage && profileImage.url ? profileImage.url : '');
  }, [profileImage]);

  return (
    <StyledAvatarBox>
      <CircleAvatar name="user_avatar" size="lg" src={profileImageSrc} />
      {authorized && isOwner ? (
        <StyledEditLabel>
          <input type="file" onChange={onChangeImage} accept="image/*" />
          <Icon iconName="camera" iconColor="gray" />
        </StyledEditLabel>
      ) : null}
    </StyledAvatarBox>
  );
};

export default UserProfileAvatar;

const StyledEditLabel = styled.label`
  position: absolute;
  top: 150px;
  left: 158px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  cursor: pointer;
  input {
    display: none;
  }
  img {
    position: absolute;
    top: 10px;
    right: 10px;
    width: 18px !important;
    height: 18px !important;
  }
  :hover {
    opacity: 0.7;
  }
`;

const StyledAvatarBox = styled.div`
  position: relative;
`;
