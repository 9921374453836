import React, { useEffect } from 'react';
import styled from 'styled-components';
import { withRouter } from 'react-router-dom';
import notFound404 from '@meettry/engineer/images/404.png';
import { useTopPath } from '@meettry/ui-components/hooks/useTopPath';

const NotFound = (props) => {
  const { history } = props;
  const topPath = useTopPath();
  const onClickTop = (e) => {
    e.preventDefault();
    history.push(topPath);
  };

  //MEMO (minami) 表示後10秒後にリダイレクト
  useEffect(() => {
    if (topPath === '') return;
    const timer = setTimeout(() => history.push(topPath), 10000);
    return () => {
      clearTimeout(timer);
    };
  }, [topPath]);

  return (
    <StyledNotFoundContainer>
      <StyledNotFoundImage src={notFound404}>
        <img src={notFound404} alt="page not found" />
      </StyledNotFoundImage>
      <StyledNotFoundHeading>お探しのページは見つかりませんでした</StyledNotFoundHeading>
      <StyledNotFoundTextWrapper>
        <StyledNotFoundText>一時的にアクセスできない状況にあるか、</StyledNotFoundText>
        <StyledNotFoundText>移動もしくは削除された可能性があります。</StyledNotFoundText>
      </StyledNotFoundTextWrapper>
      <StyledButton onClick={onClickTop}>トップページへ</StyledButton>
    </StyledNotFoundContainer>
  );
};

export default withRouter(NotFound);

const StyledNotFoundContainer = styled.div`
  && {
    width: 100%;
    max-width: 360px;
  }
`;

const StyledNotFoundImage = styled.div`
  && {
    width: 100%;
    max-width: 165px;
    min-height: 144px;
    margin: 0 auto;
    ${({ src }) => (src ? `background-image: url(${src});` : null)}
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    img {
      display: none;
    }
  }
`;

const StyledNotFoundHeading = styled.h1`
  && {
    text-align: center;
    color: #0fafa9;
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
  }
`;

const StyledNotFoundTextWrapper = styled.div`
  && {
    margin-top: 20px;
  }
`;

const StyledNotFoundText = styled.p`
  && {
    text-align: center;
    color: #333333;
    font-size: 16px;
    :not(:first-child) {
      margin-top: 15px;
    }
  }
`;

const StyledButton = styled.button`
  && {
    cursor: pointer;
    display: block;
    margin: 0 auto;
    margin-top: 30px;
    appearance: none;
    outline: none;
    border: none;
    background-color: #0fafa9;
    color: #ffffff;
    font-size: 18px;
    font-weight: bold;
    border-radius: 5px;
    width: 100%;
    max-width: 360px;
    min-height: 60px;
    transition: opacity ease 0.1s;
    :hover {
      opacity: 0.8;
    }
  }
`;
