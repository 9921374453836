import React from 'react';
import styled from 'styled-components';
import { TagType } from '@meettry/ui-components/types/userPage';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type SearchTagListProps = {
  searchWord: string;
  tagList: Array<TagType>;
  onSelectTag: (item: TagType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  className?: string;
  getMenuProps: () => void;
  getItemProps: Function;
  highlightedIndex: number | null;
};

const SearchTagList = (props: SearchTagListProps) => {
  const {
    searchWord = '',
    tagList = [],
    onSelectTag,
    className,
    getMenuProps = () => {},
    getItemProps = () => {},
    highlightedIndex = null
  } = props;
  // MEMO(aida)検索ワードでユーザのタグをフィルター
  const filteredTagList = tagList.filter(({ displayName }) => {
    // MEMO(aida)空文字は含まない
    if (searchWord === '') return false;
    return displayName.toLowerCase().includes(searchWord.toLowerCase());
  });

  const showingTagList: Array<TagType> = Array.from(new Set(filteredTagList));
  return showingTagList.length ? (
    <StyledSearchTagListWrapper className={className}>
      <StyledSearchTagList {...getMenuProps()}>
        {showingTagList.map((item, i) => (
          <StyledSearchTagListItem
            {...getItemProps({ key: `tag-item_${item.id}`, index: i, item })}
            key={`tag-item_${item.id}`}
            onClick={onSelectTag(item)}
            isActive={highlightedIndex === i}
          >
            {item.displayName}
          </StyledSearchTagListItem>
        ))}
      </StyledSearchTagList>
    </StyledSearchTagListWrapper>
  ) : null;
};

export default SearchTagList;

const StyledSearchTagListWrapper = styled.div`
  width: 100%;
  max-width: 200px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.16);
`;

const StyledSearchTagList = styled.ul`
  width: 100%;
`;

const StyledSearchTagListItem = styled.li<{ isActive: boolean }>`
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding: 10px;
  min-height: 26px;
  font-size: 12px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  transition: background-color ease 0.5s;
  cursor: pointer;
  :hover {
    background-color: ${COLOR_DEFINITIONS.BG.SUB};
  }
  ${({ isActive }) => (isActive ? `background-color: ${COLOR_DEFINITIONS.BG.SUB}` : null)};
`;
