import React from 'react';
import styled, { css } from 'styled-components';
import { getTagCategory } from '@meettry/ui-components/utils';
import { ResumeType } from '@meettry/ui-components/types/userPage';
import EditMenuComponent from '@meettry/ui-components/components/molecules/EditMenuComponent';
import { BasicTag, LineTag } from '@meettry/ui-components/components/atoms/Tag';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import HighlightedText from '@meettry/ui-components/components/atoms/HighlightedText';
import { TagType } from '@meettry/ui-components/types/userPage';
import { IconPropsType } from '@meettry/ui-components/components/atoms/Icon';
import { diffDate, flexibleDateString } from '@meettry/ui-components/utils/datetime';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const filterResumeTags = (tagArray: Array<TagType>, tagKind: string) =>
  tagArray.filter((tag: TagType) => tag.category === getTagCategory(tagKind));

type ResumeBoxProps = {
  resume: ResumeType;
  isOwner?: boolean;
  onDelete: (resume: any) => (e: any) => void;
  onPinned: (resume: any) => (e: any) => void;
  onVisible: (resume: any) => (e: any) => void;
  onEdit: (resume: any) => (e: any) => void;
  onClickTitle?: () => void;
  searchWord?: string;
};

const ResumeBox = (props: ResumeBoxProps) => {
  const {
    resume,
    isOwner,
    onDelete,
    onPinned,
    onVisible,
    onEdit,
    onClickTitle,
    searchWord
  } = props;
  const workStartDate = new Date(resume.workStartDate);
  const workEndDate = resume.workEndDate ? new Date(resume.workEndDate) : new Date();
  const { isVisible, isPinned } = resume;

  const resumeLanguageTags = filterResumeTags(resume.tags, 'language');
  const resumeFrameworkTags = filterResumeTags(resume.tags, 'framework');
  const resumeDatabaseTags = filterResumeTags(resume.tags, 'database');
  const resumeInfraTags = filterResumeTags(resume.tags, 'infrastructure');
  const resumeOsTags = filterResumeTags(resume.tags, 'os');
  const resumeToolTags = filterResumeTags(resume.tags, 'tool');

  const onClickTitleHandle = () => {
    typeof onClickTitle === 'function' && onClickTitle();
  };

  const menuList: (
    resume: ResumeType
  ) => Array<{
    icon?: IconPropsType;
    text?: string;
    onClick?: (e?: MouseEvent) => void;
  }> = (resume) => {
    const ICON_SIZE = '18px';
    const ICON_MARGIN_RIGHT = '5px';
    const { isPinned, isVisible } = resume;
    return [
      {
        icon: {
          iconName: 'edit',
          iconColor: 'gray',
          width: ICON_SIZE,
          marginRight: ICON_MARGIN_RIGHT
        },
        text: '編集',
        onClick: onEdit(resume)
      },
      {
        icon: {
          iconName: isVisible ? 'hidden' : 'view',
          iconColor: 'gray',
          width: ICON_SIZE,
          marginRight: ICON_MARGIN_RIGHT
        },
        text: isVisible ? '非公開にする' : '公開する',
        onClick: onVisible(resume)
      },
      isVisible
        ? {
            icon: {
              iconName: 'pin',
              iconColor: 'gray',
              width: ICON_SIZE,
              marginRight: ICON_MARGIN_RIGHT
            },
            text: isPinned ? 'ピン留め解除' : 'ピン留めする',
            onClick: onPinned(resume)
          }
        : {},
      {
        icon: {
          iconName: 'delete',
          iconColor: 'gray',
          width: ICON_SIZE,
          marginRight: ICON_MARGIN_RIGHT
        },
        text: '削除',
        onClick: onDelete(resume)
      }
    ];
  };

  return (
    <StyledResumeWrapper>
      {!isVisible ? (
        <StyledVisibleStatus>
          <StyledVisibleStatusIcon iconName="hidden" iconColor="black" width="16" fileType="png" />
        </StyledVisibleStatus>
      ) : null}
      {isPinned ? (
        <StyledPinnedStatus>
          <StyledPinnedStatusIcon iconName="pin" iconColor="white" width="16" />
        </StyledPinnedStatus>
      ) : null}
      {isOwner && (
        <StyledEditButtonArea>
          <EditMenuComponent
            EditMenuList={menuList(resume)}
            toggleIcon={<Icon iconName="pencil_gray" iconColor="gray" fileType="png" />}
          />
        </StyledEditButtonArea>
      )}
      <StyledCassetteResume isVisible={isVisible ? true : false}>
        <div>
          <StyledResumeProjectPeriod>
            <span>{flexibleDateString(workStartDate)}</span>
            <span>|</span>
            <span>{flexibleDateString(workEndDate)}</span>
            <span>{diffDate(workStartDate, workEndDate)}</span>
          </StyledResumeProjectPeriod>
        </div>
        <div>
          <StyledResumeProjectName isClickable={typeof onClickTitle === 'function'}>
            <span onClick={onClickTitleHandle}>
              <HighlightedText keyword={searchWord}>{resume.title}</HighlightedText>
            </span>
            <HighlightedText keyword={searchWord}>{resume.companyName}</HighlightedText>
          </StyledResumeProjectName>
          <div style={{ marginTop: '20px' }}>
            <StyledTagList>
              {resume.tags
                .filter((tag) => tag && tag.category == null)
                .map((tag) => (
                  <li key={tag.name}>
                    <StyledLineTag>{tag.displayName}</StyledLineTag>
                  </li>
                ))}
            </StyledTagList>
          </div>
          <div style={{ marginTop: '10px' }}>
            <StyledSkillList>
              {resumeLanguageTags.length ? (
                <>
                  <dt>
                    <Icon iconName="code" iconColor="black" width="14" />
                  </dt>
                  {resumeLanguageTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}
              {resumeFrameworkTags.length ? (
                <>
                  <dt>
                    <Icon iconName="framework" iconColor="black" width="14" />
                  </dt>
                  {resumeFrameworkTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}

              {resumeDatabaseTags.length ? (
                <>
                  <dt>
                    <Icon iconName="skill_database" iconColor="black" fileType="png" width="14" />
                  </dt>
                  {resumeDatabaseTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}

              {resumeInfraTags.length ? (
                <>
                  <dt>
                    <Icon iconName="server" iconColor="black" width="14" />
                  </dt>
                  {resumeInfraTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}

              {resumeOsTags.length ? (
                <>
                  <dt>
                    <Icon iconName="pc" iconColor="black" width="14" />
                  </dt>
                  {resumeOsTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}

              {resumeToolTags.length ? (
                <>
                  <dt>
                    <Icon iconName="othercode" iconColor="black" width="14" />
                  </dt>
                  {resumeToolTags.map((tag) => (
                    <dd key={tag.name}>
                      <StyledBasicTag>
                        <HighlightedText keyword={searchWord}>{tag.displayName}</HighlightedText>
                      </StyledBasicTag>
                    </dd>
                  ))}
                </>
              ) : null}
            </StyledSkillList>
          </div>
          <div style={{ marginTop: '20px' }}>
            <StyledResumeProjectDescription>
              <HighlightedText keyword={searchWord}>{resume.workDetail}</HighlightedText>
            </StyledResumeProjectDescription>
          </div>
        </div>
      </StyledCassetteResume>
    </StyledResumeWrapper>
  );
};

export default ResumeBox;

const StyledEditButtonArea = styled.div`
  opacity: 0;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 100;
  transition: opacity ease 0.2s;
`;

const StyledResumeWrapper = styled.div`
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  position: relative;
  padding: 30px;
  padding-left: 0;
  &:hover ${StyledEditButtonArea} {
    opacity: 1;
  }
`;

const StyledVisibleStatus = styled.div`
  z-index: 100;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: 0;
  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
`;

const StyledVisibleStatusIcon = styled(Icon)`
  display: block;
  position: absolute;
  left: 20%;
  top: 20%;
  transform: translate(-20%, -20%);
`;

const StyledPinnedStatus = styled.div`
  z-index: 100;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: 0;
  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 22px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
`;

const StyledPinnedStatusIcon = styled(Icon)`
  display: block;
  position: absolute;
  left: 20%;
  top: 20%;
  transform: translate(-20%, -20%);
  width: 16px;
`;

const StyledCassetteResume = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 100%;
  & > *:nth-child(1) {
    box-sizing: border-box;
    width: 150px;
  }

  & > *:nth-child(2) {
    width: calc(100% - 150px);
    padding-left: 30px;
    border-left: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }

  ${({ isVisible }) =>
    !isVisible &&
    css`
      ::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SUB};
        z-index: 50;
        top: 0;
        left: 0;
      }
    `}
`;

const StyledResumeProjectPeriod = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};

  & > *:nth-child(1) {
    font-size: 16px;
  }

  & > *:nth-child(2) {
    margin-top: 8px;
  }
  & > *:nth-child(3) {
    font-size: 16px;
    margin-top: 8px;
  }

  & > *:nth-child(4) {
    font-size: 12px;
    margin-top: 13px;
  }
`;

const StyledResumeProjectName = styled.div<{ isClickable: boolean }>`
  & > * {
    display: block;
  }

  & > *:nth-child(1) {
    font-size: 18px;
    font-weight: bold;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    word-break: break-all;
    line-height: 1.2;
    ${(props) =>
      props.isClickable
        ? css`
            cursor: pointer;
          `
        : ''}
  }

  & > *:nth-child(2) {
    font-size: 14px;
    color: ${COLOR_DEFINITIONS.TEXT.MAIN};
    margin-top: 10px;
  }
`;

const StyledTagList = styled.ul`
  display: flex;
  flex-wrap: wrap;
`;

const StyledLineTag = styled(LineTag)`
  display: inline-block;
  margin-right: 4px;
  margin-top: 4px;
`;

const StyledBasicTag = StyledLineTag.withComponent(BasicTag);

const StyledSkillList = styled.dl`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  & > dt {
    margin-right: 8px;
  }

  & > * + dt {
    padding-left: 10px;
  }

  & img {
    vertical-align: middle;
  }
`;

const StyledResumeProjectDescription = styled.p`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 14px;
  line-height: 1.75;
  word-break: break-all;
`;
