import React from 'react';
import styled from 'styled-components';
import { RadioButton } from '@meettry/engineer/components/atoms/Input';

export const InputEnableAcceptingScout = (props) => {
  const { value, isEnableScout, onChange } = props;
  return (
    <StyledInputEnableAccepttingScount>
      <RadioButton
        value={1}
        id="scout_enable"
        name="enableAcceptingScout"
        label="受け取る"
        checked={value === 1}
        disabled={!isEnableScout}
        onChange={onChange}
      />
      <RadioButton
        type="radio"
        value={0}
        label="受け取らない"
        id="scout_disable"
        name="enableAcceptingScout"
        checked={value === 0}
        disabled={!isEnableScout}
        onChange={onChange}
      />
    </StyledInputEnableAccepttingScount>
  );
};

const StyledInputEnableAccepttingScount = styled.div`
  > *:not(:first-child) {
    margin-left: 50px;
  }
`;
