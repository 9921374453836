import React from 'react';
import styled from 'styled-components';
import SearchBox from '@meettry/ui-components/components/molecules/SearchBox';
import { map } from 'lodash';
import { useHistory } from 'react-router-dom';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { CircleAvatar } from '@meettry/ui-components/components/atoms/Images/Avatars';
import { useLoginUser } from '@meettry/ui-components/utils/auth';
import icons from '@meettry/ui-components/images/icons';
import { FollowButton } from '@meettry/ui-components/components/atoms/Button/IconButton';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

type UserType = {
  user: UsersType;
  followed: boolean;
  onClickFollow: (id: string, followed: boolean, nickname: string) => void;
};

/*
 * MEMO(パク) : メッセージ機能ができるようになったら、メッセージボタン使用する
 */
const User: React.FC<UserType> = ({ user, followed, onClickFollow }) => {
  const history = useHistory();
  const { id, nickname, location, job, bio, profileImage } = user;
  const { user: myselfInfo, initialized, isEnterprise } = useLoginUser();
  const userPagePath = `/user/${nickname}`;

  const followButtonHandler = (id: string, followed: boolean, nickname: string) => () => {
    onClickFollow(id, followed, nickname);
  };

  const onClickUserItemWrapper = () => {
    history.push(userPagePath);
  };

  //メッセージボタンイベントハンドラ
  // const messageButtonHandler = (e) => {
  //   e.preventDefault();
  //   console.log(`message ${nickname}`);
  // };
  return (
    <StyledUserItemWrapper onClick={onClickUserItemWrapper}>
      <StyledUserImageWrapper>
        <CircleAvatar name="user_avatar" size="md" src={profileImage ? profileImage.url : ''} />
      </StyledUserImageWrapper>
      <StyledUserDetailWrapper>
        <StyledUserHeaderWrapper>
          <StyledUserInfo>
            <StyledUserName>{nickname}</StyledUserName>
            <StyledUserSubDetailWrapper>
              {location && (
                <StyledUserLocation>
                  <img src={icons.gray.map} height="18" />
                  <span>{location}</span>
                </StyledUserLocation>
              )}
              {job && <StyledUserJob>{job}</StyledUserJob>}
            </StyledUserSubDetailWrapper>
          </StyledUserInfo>
          {nickname !== myselfInfo.nickname && !initialized && !isEnterprise ? (
            <StyledUserButtonWrapper>
              <FollowButton
                styleType={followed ? STYLE_TYPE.ROLE.GRAY : STYLE_TYPE.ROLE.PRIMARY}
                size={STYLE_TYPE.SIZE.MEDIUM}
                following={followed}
                onClick={followButtonHandler(id, followed, nickname)}
              />
            </StyledUserButtonWrapper>
          ) : null}
        </StyledUserHeaderWrapper>
        <StyledUserBio>{bio}</StyledUserBio>
      </StyledUserDetailWrapper>
    </StyledUserItemWrapper>
  );
};

type UsersType = {
  id: string;
  nickname: string;
  bio: string;
  location: string;
  profileImage: {
    url: string;
  };
  url: string;
  job: string;
};

type LoginUserType = {
  user: {
    id: string;
    followers: {
      id: string;
      nickname: string;
    };
    followees: {
      id: string;
      nickname: string;
    };
  };
};

type UserCardType = {
  users: Array<UsersType>;
  onSearch: (e: React.FormEvent<HTMLFormElement>) => void;
  searchWord: string;
  onChangeSearchWord: (value: string) => void;
  isLoading: boolean;
  loginUser: LoginUserType;
  onClickFollow: (id: string, followed: boolean, nickname: string) => void;
  type: string;
  loginUserFollowee: Array<string>;
  isSearch: boolean;
  isOwner: boolean;
};

const UserCard: React.FC<UserCardType> = (props) => {
  const {
    users = [],
    onSearch,
    searchWord,
    onChangeSearchWord,
    isLoading,
    loginUser,
    onClickFollow,
    type,
    loginUserFollowee,
    isSearch,
    isOwner
  } = props;

  return (
    <StyledUsersWrapper>
      <StyledSearchBox
        onSubmit={onSearch}
        onChange={onChangeSearchWord}
        searchWord={searchWord}
        placeholder={`${type === 'followers' ? 'フォロワー' : 'フォロー中'}を検索`}
      />
      <StyledUsersList>
        {isLoading ? (
          <Loader />
        ) : users && users.length ? (
          map(users, (user) => {
            const followed = loginUserFollowee.includes(user.nickname);
            return (
              <User
                onClickFollow={onClickFollow}
                key={user.nickname}
                user={user}
                followed={followed}
              />
            );
          })
        ) : isSearch ? (
          <StyledSearchNoData>
            <img src={icons.primary.noData} width="60" alt="NO DATA" />
            <StyledSearchNoDataText>条件に一致する検索結果はありません</StyledSearchNoDataText>
            <StyledSearchNoDataCaution>
              キーワードを確認・変更して検索し直してください。
            </StyledSearchNoDataCaution>
          </StyledSearchNoData>
        ) : (
          <StyledSearchNoData>
            <StyledSearchNoDataText>
              {type === 'followers' ? 'フォロワーがいません。' : 'フォロー中のユーザーがいません。'}
            </StyledSearchNoDataText>
            {isOwner ? (
              <StyledSearchNoDataText>
                検索して気になる人をフォローしてみましょう。
              </StyledSearchNoDataText>
            ) : null}
          </StyledSearchNoData>
        )}
      </StyledUsersList>
    </StyledUsersWrapper>
  );
};

const StyledUsersWrapper = styled.div`
  padding: 30px 50px 60px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
`;

const StyledSearchBox = styled(SearchBox)`
  margin-left: auto;
`;

const StyledUsersList = styled.div`
  margin-top: 10px;
`;

const StyledUserItemWrapper = styled.div`
  padding: 30px;
  cursor: pointer;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  transition: background 0.15s;
  display: flex;
  + * {
    border-bottom: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
  :hover {
    background-color: ${COLOR_DEFINITIONS.HOVER.OUTLINED};
  }
`;

const StyledUserImageWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 100px;
`;

const StyledUserHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledUserButtonWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  > * {
    :not(:first-child) {
      margin-left: 5px;
    }
  }
`;

const StyledUserDetailWrapper = styled.div`
  width: 100%;
  margin-left: 30px;
`;

const StyledUserInfo = styled.div`
  max-width: 450px;
  word-break: break-all;
`;

const StyledUserName = styled.p`
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-size: 20px;
  font-weight: bold;
  line-height: 1.6;
  text-decoration: none;
`;

const StyledUserSubDetailWrapper = styled.p`
  display: flex;
  align-items: center;
`;

const StyledUserLocation = styled.span`
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 12px;
  display: flex;
  align-items: center;

  & img {
    margin-right: 5px;
  }
`;

const StyledUserJob = styled.span`
  margin-left: 10px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  font-size: 12px;
  font-weight: bold;
`;
const StyledUserBio = styled.p`
  max-width: 100%;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.7;
  min-height: 40px;
  word-break: break-all;
`;

const StyledSearchNoData = styled.div`
  padding: 60px;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  text-align: center;
`;

const StyledSearchNoDataText = styled.p`
  font-size: 20px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  font-weight: bold;
  :not(:first-child) {
    margin-top: 30px;
  }
`;

const StyledSearchNoDataCaution = styled.p`
  font-size: 16px;
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  margin-top: 20px;
`;

export default UserCard;
