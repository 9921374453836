import React from 'react';
import UserPage from '@meettry/ui-components/components/organisms/UserPage';
import { EngineerProvider } from '@meettry/ui-components/contexts/engineer';

export default () => {
  return (
    <EngineerProvider>
      <UserPage />
    </EngineerProvider>
  );
};
