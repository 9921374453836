import React from 'react';

type LangProps = {
  item?: string;
  crossMark?: boolean;
};

export const LangTag: React.FC<LangProps> = (props) => {
  return (
    <span className="tag lang-tag">
      {props.item}
      {props.crossMark ? <span className="cross-mark" /> : ''}
    </span>
  );
};
type LangExperienceProps = {
  lang?: string;
  year?: number | null;
  crossMark?: boolean;
  clickCrossMark?: Function;
};
export const LangExperienceTag: React.FC<LangExperienceProps> = (props) => {
  return (
    <span className="tag lang-ex-tag ">
      <span>{props.lang}</span>
      {!!props.year ? <span className="year">{props.year}年</span> : null}
      {props.crossMark ? (
        <span
          className="cross-mark"
          onClick={() => !!props.clickCrossMark && props.clickCrossMark()}
        />
      ) : (
        ''
      )}
    </span>
  );
};

type JobDescProps = {
  item: string;
  crossMark?: boolean;
};

export const JobTag: React.FC<JobDescProps> = (props) => {
  return <span className="job-tag tag">{props.item}</span>;
};

type JobExperienceProps = {
  job?: string;
  year?: number | null;
  crossMark?: boolean;
  clickCrossMark?: Function;
};
export const JobExperienceTag: React.FC<JobExperienceProps> = (props) => {
  return (
    <span className="tag job-ex-tag">
      <span>{props.job}</span>
      {!!props.year ? <span className="year">{props.year}年</span> : null}
      {props.crossMark ? (
        <span
          className="cross-mark"
          onClick={() => !!props.clickCrossMark && props.clickCrossMark()}
        />
      ) : (
        ''
      )}
    </span>
  );
};

type ExperienceTagProps = {
  item: { lang: string; year: number };
};

export const ExperienceTag: React.FC<ExperienceTagProps> = (props) => {
  return (
    <span className="tag experience-tag">
      <span>{props.item.lang}</span>
      <span>{props.item.year}年</span>
    </span>
  );
};
