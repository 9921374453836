import { useEffect, useState } from 'react';
import uuid from 'uuid';
import { setHooksMasterId, useHooksMasterContext } from '@meettry/ui-components/contexts/hooksMaster.context';

/**
 * useHooksMasterFlag
 * 「複数箇所でHooksは呼び出されるが、Hooksの内部処理は複数回呼び出されたくない」という時に使用する
 * 「複数回呼び出されたくない」処理は、このHooksがtrueを返す時にだけ実行されるようにすればOK
 *
 * @param {String} name Hooksの名称
 */

const useHooksMasterFlag = (name: string) => {
  const { state, dispatch } = useHooksMasterContext();
  const [id] = useState(uuid.v4());

  useEffect(() => {
    if (!state[name]) {
      dispatch(setHooksMasterId(name, id ));
    }

    return () => {
      dispatch(setHooksMasterId(name, null ));
    };
  }, [state, name, id]);

  return state[name] === id;
};

export default useHooksMasterFlag;
