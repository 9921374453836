import React from 'react';
import styled from 'styled-components';
import Loader from '@meettry/ui-components/components/atoms/Loader';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { ActiveCampaign, Billing } from '@meettry/ui-components/types/payment';
import { DATE_FORMAT, formatDate } from '@meettry/ui-components/utils';

export type ScoutChatAlertCampaign = Pick<
  ActiveCampaign,
  'freeScoutAmountUsed' | 'freeScoutAmount' | 'freeScoutEndAt'
> &
  Pick<Billing, 'scoutUnitPrice' | 'paidScoutCount'>;

type Props = {
  loading: boolean;
  error: boolean;
  campaignData: ScoutChatAlertCampaign;
};

const Loading = () => (
  <StyledWrapperInner>
    <Loader />
  </StyledWrapperInner>
);

const Error = () => <StyledWrapperInner>情報を取得できませんでした</StyledWrapperInner>;

const ScoutChatAlertBox: React.FC<Props> = (props) => {
  const { campaignData, loading, error } = props;
  const isCampaign = !!campaignData.freeScoutEndAt;
  const isUseUpCampaign = campaignData.freeScoutAmount === campaignData.freeScoutAmountUsed;

  return (
    <StyledScoutChatAlertBox>
      {loading ? (
        <Loading />
      ) : error ? (
        <Error />
      ) : (
        <>
          <StyledTitle>
            スカウトチャットを送るには
            <br />
            以下の金額が発生しますがよろしいですか?
          </StyledTitle>
          <StyledCostBox>
            <div>
              金額
              {isCampaign && !isUseUpCampaign && (
                <StyledChampaignLabel>キャンペーン適用</StyledChampaignLabel>
              )}
            </div>
            <div>
              <StyledCost isCampaign={isCampaign && !isUseUpCampaign}>
                <span>
                  {campaignData.scoutUnitPrice}
                  <StyledUnit>円</StyledUnit>
                  <StyledSubUnit>（税抜）</StyledSubUnit>
                </span>
                {isCampaign && !isUseUpCampaign && (
                  <span>
                    0<StyledUnit>円</StyledUnit>
                  </span>
                )}
              </StyledCost>
            </div>
          </StyledCostBox>
          {isCampaign && !isUseUpCampaign && (
            <StyledFreeTicketBox>
              <div>無料枠</div>
              <StyledFreeTicketInfo>
                <div>
                  <span>{campaignData.freeScoutAmount - campaignData.freeScoutAmountUsed}</span>/
                  {campaignData.freeScoutAmount} （-1）
                </div>
                <div>{formatDate(campaignData.freeScoutEndAt, DATE_FORMAT.DATE_JP)}まで有効</div>
              </StyledFreeTicketInfo>
            </StyledFreeTicketBox>
          )}
          <StyledCurrentAmount>
            現状のスカウト課金額
            <span>{campaignData.paidScoutCount * campaignData.scoutUnitPrice}</span>円
          </StyledCurrentAmount>
        </>
      )}
    </StyledScoutChatAlertBox>
  );
};
export default ScoutChatAlertBox;

const StyledTitle = styled.p`
  font-size: 20px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  line-height: 1.4;
  text-align: center;
`;

const StyledWrapperInner = styled.div`
  width: 400px;
  text-align: center;
`;

const StyledScoutChatAlertBox = styled.div`
  padding: 30px 50px;
`;

const StyledCostBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 400px;
  height: 80px;
  padding: 30px 25px;
  align-items: center;
  background-color: rgba(15, 175, 169, 0.05);
  border: 1px solid #eeeeee;
  color: #0fafa9;
  font-size: 18px;
  font-weight: bold;
  margin-top: 30px;
  & > * {
    display: flex;
    align-items: center;
    width: 50%;
  }
  & > * + * {
    align-items: baseline;
  }
`;

const StyledChampaignLabel = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 14px;
  color: #f4233c;
  background-color: #fee8eb;
  border-radius: 14px;
  font-size: 10px;
  font-weight: normal;
  line-height: 14px;
  margin-left: 5px;
`;

const StyledCost = styled.div<{ isCampaign: boolean }>`
  display: inline-flex;
  & > *:first-child {
    position: relative;
  }

  & > * + * {
    margin-left: 18px;
  }
  ${({ isCampaign }) =>
    isCampaign
      ? `
    & > *:first-child::after {
      content: '';
      position: absolute;
      display: block;
      width: 95%;
      height: 2px;
      background-color: #0FAFA9;
      top: 50%;
      left: -2%
    }
  `
      : null}
`;

const StyledUnit = styled.span`
  font-size: 20px;
`;

const StyledSubUnit = styled.span`
  font-size: 12px;
`;

const StyledFreeTicketBox = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 400px;
  height: 80px;
  padding: 30px 25px;
  align-items: center;
  font-size: 14px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  & > * {
    width: 50%;
  }
`;

const StyledFreeTicketInfo = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;

  & > * {
    & > span {
      font-size: 16px;
      font-weight: bold;
    }
  }

  & > * + * {
    margin-top: 12px;
    color: #999999;
  }
`;

const StyledCurrentAmount = styled.div`
  margin-top: 30px;
  font-size: 16px;
  text-align: center;

  & > * {
    margin-left: 16px;
    font-weight: bold;
  }
`;
