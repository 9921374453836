import React from 'react';
import styled, { css } from 'styled-components';

import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

export const DEFAULT_PAGINATION_DISPLAY_ITEM = 3;

type PaginationProps = {
  maxLength: number;
  current: number;
  setCurrent: Function;
  displayNumber: number;
  marginTop?: number;
};

type StyledPaginationProps = {
  marginTop?: number;
};

type StyledArrowProps = {
  isDisabled: boolean;
};

type StyledPaginationContentProps = {
  isCurrent: boolean;
};

const range = (start: number, max: number) => {
  const lst = [];
  for (var i = start; i <= max; i++) {
    lst.push(i);
  }
  return lst;
};

export const Pagination: React.FC<PaginationProps> = (props) => {
  const { maxLength, current, setCurrent, displayNumber, marginTop } = props;
  const displayNumberCenter = Math.ceil(displayNumber / 2);
  const isCurrentLeftEdge = current <= displayNumberCenter;
  const isCurrentRightEdge = current >= maxLength - displayNumberCenter;
  const startNum: number = isCurrentRightEdge // currentが数字の表示個数以内で数字の最大値より左にある時の数字の表示条件
    ? maxLength - (displayNumber - 1)
    : isCurrentLeftEdge // currentが数字の表示個数より左にある時の数字の表示条件
    ? 1
    : current - (displayNumberCenter - 1); // 数字の表示が「…」の中央にある時の数字の表示条件

  const itemNumbers = range(startNum, startNum + displayNumber - 1);

  return (
    <StyledPagination marginTop={marginTop}>
      <StyledArrow
        isDisabled={current === 1}
        onClick={() => {
          if (current !== 1) {
            setCurrent(current - 1);
          }
        }}
      />
      <StyledPaginationNumbers>
        {isCurrentLeftEdge || isCurrentRightEdge ? <StyledPaginationDecoration /> : null}
        {!isCurrentLeftEdge || (isCurrentLeftEdge && isCurrentRightEdge) ? (
          <StyledPaginationDecoration>…</StyledPaginationDecoration>
        ) : null}
        {itemNumbers.map((itemNumber, i) => {
          return (
            <StyledPaginationContent
              isCurrent={current === itemNumber}
              key={i}
              onClick={() => {
                if (current !== itemNumber) setCurrent(itemNumber);
              }}
            >
              {itemNumber}
            </StyledPaginationContent>
          );
        })}
        {!isCurrentRightEdge ? (
          <React.Fragment>
            <StyledPaginationDecoration>…</StyledPaginationDecoration>
            <StyledPaginationContent
              isCurrent={current === maxLength}
              onClick={() => {
                if (current !== maxLength) setCurrent(maxLength);
              }}
            >
              {maxLength}
            </StyledPaginationContent>
          </React.Fragment>
        ) : null}
        {isCurrentRightEdge ? <StyledPaginationDecorationLast /> : null}
      </StyledPaginationNumbers>
      <StyledArrow
        isDisabled={current === maxLength}
        onClick={() => {
          if (current !== maxLength) {
            setCurrent(current + 1);
          }
        }}
      />
    </StyledPagination>
  );
};

export const StyledPagination = styled.div<StyledPaginationProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  ${(props) => (!!props.marginTop ? `margin-top:${props.marginTop}px` : ``)}
`;

const StyledArrow = styled.span<StyledArrowProps>`
  line-height: 32px;
  &:first-child,
  &:last-child {
    cursor: pointer;
    display: block;
    width: 10px;
    height: 10px;
    border-top: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-left: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    transition: border-color 0.1s;
    &:hover {
      border-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY_DARK};
    }
  }
  &:first-child {
    transform: rotate(-45deg);
  }
  &:last-child {
    transform: rotate(135deg);
  }
  ${(props) =>
    props.isDisabled
      ? `
      &&{
        cursor: default;
        border-color: ${COLOR_DEFINITIONS.LINE.MAIN};
        &:hover {
          border-color: ${COLOR_DEFINITIONS.LINE.MAIN};
        }
      }
    `
      : ``}
`;

const StyledPaginationNumbers = styled.ol`
  margin: 0 20px;
  padding: 0;
  list-style: none;
  display: flex;
`;

const PaginationContentStyle = css`
  cursor: pointer;
  line-height: 32px;
  width: 32px;
  height: 32px;
  box-sizing: border-box;
  text-decoration: none;
  font-size: 16px;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  display: block;
  border-radius: 50%;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  transition: background-color 0.1s;
  text-align: center;
  &:hover {
    background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.MAIN};
  }
  & + li {
    margin-left: 8px;
  }
`;

const StyledPaginationContent = styled.li<StyledPaginationContentProps>`
  ${PaginationContentStyle}
  ${(props) =>
    props.isCurrent
      ? `
      &&{
        background-color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        color: ${COLOR_DEFINITIONS.TEXT.WHITE};
        cursor: default;
      }
    `
      : ``}
`;

const PaginationDecorationStyle = css`
  width: 16px;
  cursor: default;
  background-color: transparent;
  border-radius: 0;
  border: none;
`;

const StyledPaginationDecoration = styled.li`
  ${PaginationContentStyle}
  ${PaginationDecorationStyle}
`;

const StyledPaginationDecorationLast = styled.li`
  width: 32px;
`;
