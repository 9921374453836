import React from 'react';
import styled from 'styled-components';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { SuccessPopup, ErrorPopup } from '@meettry/engineer/components/atoms/PopupItem';
import usePopup from '@meettry/ui-components/hooks/usePopup';
import { SlideInRightFadeAnimation } from '@meettry/engineer/utils/Animation';

export const Popup = (props) => {
  const { className } = props;
  const {
    state: { popups },
    hidePopup
  } = usePopup();

  //TODO(aida) popup自身に持たせるべきか考える。
  const onClickPopup = (targetId) => () => {
    hidePopup(targetId);
  };

  const Animation = {
    props: {
      timeout: 300,
      classNames: SlideInRightFadeAnimation.classNames
    },
    Wrapper: SlideInRightFadeAnimation.animation
  };

  return (
    <StyledPopupWrapper className={className}>
      {popups.map((popup) => {
        const { type, text, id } = popup;
        switch (type) {
          case 'success':
            return (
              <CSSTransition key={id} {...Animation.props}>
                <Animation.Wrapper>
                  <SuccessPopup text={text} onClick={onClickPopup(id)} onHide={onClickPopup(id)} />
                </Animation.Wrapper>
              </CSSTransition>
            );
          case 'error':
            return (
              <CSSTransition key={id} {...Animation.props}>
                <Animation.Wrapper>
                  <ErrorPopup text={text} onClick={onClickPopup(id)} onHide={onClickPopup(id)} />
                </Animation.Wrapper>
              </CSSTransition>
            );
          default:
            return null;
        }
      })}
    </StyledPopupWrapper>
  );
};

const StyledPopupWrapper = styled(TransitionGroup)`
  position: fixed;
  z-index: 9999;
  top: 20px;
  right: 20px;
  transform: translateX(-20px);
  transition: all ease 0.5s;
  & > *:not(:first-child) {
    margin-top: 5px;
  }
`;
