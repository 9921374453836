import React, { useReducer, useEffect } from 'react';
import styled from 'styled-components';
import * as Validation from '@meettry/company/utils/validation';
import * as FindError from '@meettry/company/utils/findError';
import { SIGN, REGISTER } from '@meettry/ui-components/constants/error';
import { PrimaryRoundButton } from '@meettry/ui-components/components/atoms/Button';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';
import { FONT_FAMILY } from '@meettry/ui-components/styles/font';
import { mediaQuery } from '@meettry/ui-components/styles/mediaQuery';
import { STYLE_TYPE } from '@meettry/ui-components/constants';
import { useMutation } from '@apollo/react-hooks';
import { SEND_CONTACT_MAIL } from '@meettry/ui-components/queries/contact';
import { useDialog } from '@meettry/ui-components/hooks/useDialog';
import useLoading from '@meettry/ui-components/hooks/useLoading';

type InputStateType<T> = {
  value: T;
  error?: string;
};

type InitialStateType = {
  companyName: InputStateType<string>;
  lastName: InputStateType<string>;
  firstName: InputStateType<string>;
  email: InputStateType<string>;
  tel: InputStateType<string>;
  employees: InputStateType<string>;
  contactContentsArray: InputStateType<Array<string>>;
  contactDetail: InputStateType<string>;
  howToKnowMeettry: InputStateType<string>;
  page: boolean;
  pressedSubmit: boolean;
  entireError: boolean;
  agree: boolean;
  disabled: boolean;
};

const initialState: InitialStateType = {
  companyName: {
    value: '',
    error: ''
  },
  lastName: {
    value: '',
    error: ''
  },
  firstName: {
    value: '',
    error: ''
  },
  email: {
    value: '',
    error: ''
  },
  tel: {
    value: '',
    error: ''
  },
  employees: {
    value: '',
    error: ''
  },
  contactContentsArray: {
    value: [],
    error: ''
  },
  contactDetail: {
    value: '',
    error: ''
  },
  howToKnowMeettry: {
    value: ''
  },
  agree: false,
  pressedSubmit: false,
  entireError: true,
  page: false,
  disabled: false
};

const CHANGE_TEXT = 'CHANGE_TEXT' as const;
const SET_CHECK_ITEM = 'SET_CHECK_ITEM' as const;
const VALIDATE_TEXT = 'VALIDATE_TEXT' as const;
const VALIDATE_CHECKBOX = 'VALIDATE_CHECKBOX' as const;
const TOGGLE_AGREE = 'TOGGLE_AGREE' as const;
const SET_TRUE = 'SET_TRUE' as const;
const SET_FALSE = 'SET_FALSE' as const;
const CHECK_ERROR = 'CHECK_ERROR' as const;
const PRESSED_SUBMIT = 'PRESSED_SUBMIT' as const;
const SET_DISABLED = 'SET_DISABLED' as const;

export type StateActionType = {
  type:
    | typeof CHANGE_TEXT
    | typeof SET_CHECK_ITEM
    | typeof VALIDATE_TEXT
    | typeof VALIDATE_CHECKBOX
    | typeof TOGGLE_AGREE
    | typeof SET_TRUE
    | typeof SET_FALSE
    | typeof CHECK_ERROR
    | typeof PRESSED_SUBMIT
    | typeof SET_DISABLED;
  key?: string;
  text?: string;
  errorText?: string;
  array?: Array<string>;
  state?: {
    value: string | Array<string>;
    error?: string;
  };
  bool?: boolean;
};

const stateAction: React.Reducer<InitialStateType, StateActionType> = (
  state: InitialStateType,
  action: StateActionType
) => {
  switch (action.type) {
    case CHANGE_TEXT:
      if (!!action.key) {
        return { ...state, [action.key]: { value: action.text, error: action.errorText } };
      }
      return state;
    case VALIDATE_TEXT:
      if (!!action.key) {
        return { ...state, [action.key]: { value: action.text, error: action.errorText } };
      }
      return state;
    case SET_CHECK_ITEM:
      if (!!action.array) {
        return {
          ...state,
          contactContentsArray: { ...state.contactContentsArray, value: action.array }
        };
      }
      return state;
    case VALIDATE_CHECKBOX:
      if (!!action.array) {
        return { ...state, contactContentsArray: { value: action.array, error: action.errorText } };
      }
      return state;
    case TOGGLE_AGREE:
      return { ...state, agree: !state.agree };
    case SET_TRUE:
      if (!!action.key) {
        return { ...state, [action.key]: true };
      }
      return state;
    case SET_FALSE:
      if (!!action.key) {
        return { ...state, [action.key]: false };
      }
      return state;
    case CHECK_ERROR:
      if (!!action.bool) {
        return { ...state, entireError: action.bool, pressedSubmit: true };
      }
      return state;
    case PRESSED_SUBMIT:
      if (!!action.key) {
        return {
          ...state,
          [action.key]: { ...action.state, error: action.errorText },
          entireError: action.errorText !== 'none'
        };
      }
      return state;
    default:
      return state;
  }
};

type OptionsType = {
  value: string;
  text: string;
};

type InputItemType = {
  component?: string;
  state: InputStateType<string> | InputStateType<Array<string>>;
  stateKey: string;
  required: boolean;
  name: string;
  type?: string;
  placeholder?: string;
  options?: Array<OptionsType>;
};

type InputType<T> = {
  item: T;
  dispatch: React.Dispatch<StateActionType>;
  pressedSubmit: boolean;
};

type CheckboxType = {
  array: Array<string>;
};

//MEMO(minami): １回目のバリデーション実行後の場合(pressedSubmit === true)、バリデーションしつつ値のセット。falseの場合、値のセットのみする
const eventHandle = (
  item: InputItemType,
  dispatch: (value: StateActionType) => void,
  value: string,
  isValidate: boolean
) => {
  const error = Validation.register(
    value,
    item.type === 'email' || item.type === 'tel' ? item.type : 'common'
  );
  if (isValidate) {
    dispatch({
      type: VALIDATE_TEXT,
      key: item.stateKey,
      text: value,
      errorText: error
    });
  } else {
    dispatch({ type: CHANGE_TEXT, key: item.stateKey, text: value });
  }
};

const InputContents: React.FC<InputType<Array<InputItemType>>> = (props) => {
  const { item, dispatch, pressedSubmit } = props;
  const errorArray: Array<string> = [];
  item.forEach((li) => {
    li.state.error &&
      errorArray.push(
        FindError.findError(!li.state.value || li.type === 'tel' ? REGISTER : SIGN, li.state.error)
      );
  });

  return (
    <>
      <StyledInputWrapper multiple={item.length !== 1}>
        {item.map((li, i) => (
          <StyledInputLabel key={i} multiple={item.length !== 1}>
            <StyledItemName required={li.required}>{li.name}</StyledItemName>
            <StyledFormInput
              type={li.type}
              value={li.state.value}
              placeholder={li.placeholder}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                eventHandle(li, dispatch, event.target.value, false)
              }
              onBlur={(event: React.FocusEvent<HTMLInputElement>) =>
                eventHandle(li, dispatch, event.target.value, true)
              }
              already={!!li.state.value}
              error={li.state.error}
            />
            {li.required && errorArray !== [] ? (
              <StyledErrorMessage>{errorArray[i]}</StyledErrorMessage>
            ) : null}
          </StyledInputLabel>
        ))}
      </StyledInputWrapper>
    </>
  );
};

const SelectContents: React.FC<InputType<InputItemType>> = (props) => {
  const { item, dispatch, pressedSubmit } = props;
  const errorContent =
    item.state.error === 'EMPTY' && item.required ? (
      <StyledErrorMessage>{REGISTER.EMPTY}</StyledErrorMessage>
    ) : null;

  return (
    <>
      <div>
        <label>
          <StyledItemName required={item.required}>{item.name}</StyledItemName>
          <StyledSelectBoxWrapper>
            <StyledFormSelectBox
              onChange={(event) => eventHandle(item, dispatch, event.target.value, true)}
              onBlur={(event) => eventHandle(item, dispatch, event.target.value, true)}
              value={item.state.value}
              already={!!item.state.value}
              error={item.required ? item.state.error : ''}
            >
              <option value="" disabled hidden>
                選択してください
              </option>
              {item.options &&
                item.options.map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.text}
                  </option>
                ))}
            </StyledFormSelectBox>
          </StyledSelectBoxWrapper>
        </label>
        {errorContent}
      </div>
    </>
  );
};

const CheckboxContents: React.FC<InputType<InputItemType> & CheckboxType> = (props) => {
  const { item, dispatch, array, pressedSubmit } = props;
  const errorContent =
    item.state.error === 'EMPTY' && item.required ? (
      <StyledErrorMessageCheckbox>{REGISTER.EMPTY}</StyledErrorMessageCheckbox>
    ) : null;

  //MEMO(minami) チェックボックスで選択したアイテムを配列に格納する
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newArrayValue = array.includes(event.target.value)
      ? array.filter((li) => li !== event.target.value)
      : [...array, event.target.value];
    const error = Validation.register(newArrayValue);

    if (error !== item.state.error) {
      dispatch({
        type: VALIDATE_CHECKBOX,
        array: newArrayValue,
        errorText: error
      });
    } else {
      dispatch({ type: SET_CHECK_ITEM, array: newArrayValue });
    }
  };

  return (
    <div>
      <div>
        <label>
          <StyledItemName required={item.required}>{item.name}</StyledItemName>
        </label>
        {item.options &&
          item.options.map((li, i) => (
            <StyledCheckboxEx key={i}>
              <StyledCheckboxInput
                type="checkbox"
                id={`parts${i}`}
                value={li.value}
                onChange={(event) => handleChange(event)}
                checked={array.includes(li.value)}
              />
              <StyledCheckboxLabel htmlFor={`parts${i}`}>
                <StyledCheckboxItem>{li.text}</StyledCheckboxItem>
              </StyledCheckboxLabel>
            </StyledCheckboxEx>
          ))}
      </div>
      {errorContent}
    </div>
  );
};

const TextAreaContents: React.FC<InputType<InputItemType>> = (props) => {
  const { item, dispatch, pressedSubmit } = props;
  const errorContent =
    item.state.error === 'EMPTY' && item.required ? (
      <StyledErrorMessage>{REGISTER.EMPTY}</StyledErrorMessage>
    ) : null;

  return (
    <>
      <label>
        <StyledItemName required={item.required}>{item.name}</StyledItemName>
        <StyledFormTextArea
          value={item.state.value}
          placeholder={item.placeholder}
          onChange={(event: React.ChangeEvent<HTMLTextAreaElement>) =>
            eventHandle(item, dispatch, event.target.value, false)
          }
          onBlur={(event: React.FocusEvent<HTMLTextAreaElement>) =>
            eventHandle(item, dispatch, event.target.value, true)
          }
          already={!!item.state.value}
          error={item.required ? item.state.error : ''}
        />
      </label>
      {errorContent}
    </>
  );
};

const EnterpriseContact = () => {
  const [state, dispatch] = useReducer(stateAction, initialState);
  const { showErrorDialog } = useDialog();
  const { startLoading, endLoading } = useLoading();

  const registerItems = [
    [
      {
        component: 'input',
        state: state.companyName,
        stateKey: 'companyName',
        required: true,
        name: '会社名',
        type: 'text',
        placeholder: '株式会社〇〇'
      }
    ],
    [
      {
        component: 'input',
        state: state.lastName,
        stateKey: 'lastName',
        required: true,
        name: '姓',
        type: 'text',
        placeholder: '山田'
      },
      {
        component: 'input',
        state: state.firstName,
        stateKey: 'firstName',
        required: true,
        name: '名',
        type: 'text',
        placeholder: '太郎'
      }
    ],
    [
      {
        component: 'input',
        state: state.email,
        stateKey: 'email',
        required: true,
        name: 'メールアドレス',
        type: 'email',
        placeholder: 'exmple@meettry.com'
      }
    ],
    [
      {
        component: 'input',
        state: state.tel,
        stateKey: 'tel',
        required: true,
        name: '電話番号',
        type: 'tel',
        placeholder: '000-0000-0000'
      }
    ],
    {
      component: 'select',
      state: state.employees,
      stateKey: 'employees',
      required: true,
      name: '従業員数',
      options: [
        { value: 'From1To30', text: '1名以上 - 30名以下' },
        { value: 'From31To50', text: '31名以上 - 50名以下' },
        { value: 'From51To300', text: '51名以上 - 300名以下' },
        { value: 'From301To1000', text: '301名以上 - 1000名以下' },
        { value: 'From1001To2000', text: '1001名以上 - 2000名以下' },
        { value: 'Over2000', text: '2000名以上' }
      ]
    },
    {
      component: 'checkbox',
      state: state.contactContentsArray,
      stateKey: 'contactContentsArray',
      required: true,
      name: 'お問い合わせ内容',
      options: [
        { value: 'Introduction', text: 'サービスの導入について' },
        { value: 'Features', text: '機能について' },
        { value: 'Payment', text: '料金・お見積りについて' },
        { value: 'NeedInformationSession', text: '一度、サービス説明を聞きたい' },
        { value: 'Others', text: 'その他のお問い合わせ' }
      ]
    },
    {
      component: 'textArea',
      state: state.contactDetail,
      stateKey: 'contactDetail',
      required: true,
      name: 'お問い合わせ詳細',
      placeholder: '最短でどれくらいのスピードで利用開始することができますか？'
    },
    {
      component: 'select',
      state: state.howToKnowMeettry,
      stateKey: 'howToKnowMeettry',
      required: false,
      name: 'メトリィをどこで知りましたか？',
      options: [
        { value: 'Facebook', text: 'Facebook' },
        { value: 'Twitter', text: 'Twitter' },
        { value: 'Google', text: 'Google検索' },
        { value: 'Yahoo', text: 'Yahoo' },
        { value: 'Acquaintance', text: '知人からの紹介' },
        { value: 'PressRelease', text: 'プレスリリース' },
        { value: 'Others', text: 'その他' }
      ]
    }
  ];

  const validationList = [
    { name: 'companyName', state: state.companyName },
    { name: 'lastName', state: state.lastName },
    { name: 'firstName', state: state.firstName },
    { name: 'email', state: state.email },
    { name: 'tel', state: state.tel },
    { name: 'employees', state: state.employees },
    { name: 'contactContentsArray', state: state.contactContentsArray },
    { name: 'contactDetail', state: state.contactDetail }
  ];

  const checkError = () => {
    return validationList.map((li) => li.state.error === 'none');
  };

  const submit = (e: React.MouseEvent<HTMLButtonElement>) => {
    Validation.registerAll(validationList, dispatch);
    const checkErrorList = checkError();

    if ([...checkErrorList].includes(false)) {
      !state.pressedSubmit
        ? dispatch({ type: CHECK_ERROR, bool: true })
        : dispatch({ type: SET_TRUE, key: 'entireError' });
    } else {
      !state.pressedSubmit
        ? dispatch({ type: CHECK_ERROR, bool: false })
        : dispatch({ type: SET_FALSE, key: 'entireError' });
      mutateSendContactMail()
        .then(() => {
          window.scrollTo({ top: 0 });
          state.agree && dispatch({ type: SET_TRUE, key: 'page' });
        })
        .catch(() => {
          showErrorDialog(['問題が発生いたしました。', 'もう一度やり直してください']);
        });
    }
  };

  const [mutateSendContactMail, { loading: sendContactMailLoading }] = useMutation(
    SEND_CONTACT_MAIL,
    {
      variables: {
        input: {
          companyName: state.companyName.value,
          firstName: state.firstName.value,
          lastName: state.lastName.value,
          email: state.email.value,
          phone: state.tel.value,
          numberOfEmployees: state.employees.value,
          categories: state.contactContentsArray.value,
          detail: state.contactDetail.value,
          knowBy: !!state.howToKnowMeettry.value ? state.howToKnowMeettry.value : null
        }
      }
    }
  );

  useEffect(() => {
    !!sendContactMailLoading ? startLoading() : endLoading();
  }, [sendContactMailLoading]);

  const isDisabledSubmit =
    validationList.map((li) => li.state.error === 'none').includes(false) || !state.agree;
  useEffect(() => {
    dispatch({
      type: isDisabledSubmit ? SET_TRUE : SET_FALSE,
      key: 'disabled'
    });
  }, [isDisabledSubmit]);

  const formItems = registerItems.map((item, index) => (
    <StyledItemWrapper key={index}>
      {Array.isArray(item) ? (
        <InputContents item={item} dispatch={dispatch} pressedSubmit={state.pressedSubmit} />
      ) : item.component === 'select' ? (
        <SelectContents item={item} dispatch={dispatch} pressedSubmit={state.pressedSubmit} />
      ) : item.component === 'checkbox' ? (
        <CheckboxContents
          item={item}
          dispatch={dispatch}
          array={state.contactContentsArray.value}
          pressedSubmit={state.pressedSubmit}
        />
      ) : item.component === 'textArea' ? (
        <TextAreaContents item={item} dispatch={dispatch} pressedSubmit={state.pressedSubmit} />
      ) : null}
    </StyledItemWrapper>
  ));

  return (
    <StyledEnterpriseContact page={state.page}>
      <StyledEnterpriseContactTitleWrapper>
        <StyledEnterpriseContactTitleEn>CONTACT</StyledEnterpriseContactTitleEn>
        <StyledEnterpriseContactTitleJp>お問い合わせ</StyledEnterpriseContactTitleJp>
      </StyledEnterpriseContactTitleWrapper>
      {state.page ? (
        <StyledFormSended>
          <p>
            お問い合わせありがとうございました。
            <br />
            内容を確認の上、
            <br />
            後日担当者よりご連絡いたします。
          </p>
          <StyledButtonWrapper>
            <PrimaryRoundButton size={STYLE_TYPE.SIZE.LARGE} onClick={() => window.close()}>
              閉じる
            </PrimaryRoundButton>
          </StyledButtonWrapper>
        </StyledFormSended>
      ) : (
        <>
          <StyledEnterpriseContactDesc>
            お問い合わせ内容を入力してください。
          </StyledEnterpriseContactDesc>
          <StyledForm>
            {formItems}
            <StyledCheckbox>
              <StyledCheckboxInput
                type="checkbox"
                id="agree-check"
                checked={state.agree}
                onChange={() => dispatch({ type: TOGGLE_AGREE })}
              />
              <StyledCheckboxLabel htmlFor="agree-check">
                <StyledCheckboxLabelLink
                  onClick={() => {
                    window.open('/privacy', 'privacy_policy');
                    dispatch({ type: TOGGLE_AGREE });
                  }}
                >
                  プライバシーポリシー
                </StyledCheckboxLabelLink>
                <StyledCheckboxLabelDesc>に同意する</StyledCheckboxLabelDesc>
              </StyledCheckboxLabel>
            </StyledCheckbox>
            <StyledButtonWrapper>
              <PrimaryRoundButton
                size={STYLE_TYPE.SIZE.LARGE}
                onClick={(event: React.MouseEvent<HTMLButtonElement>) => submit(event)}
                disabled={state.disabled}
              >
                送信
              </PrimaryRoundButton>
            </StyledButtonWrapper>
          </StyledForm>
        </>
      )}
    </StyledEnterpriseContact>
  );
};

export default EnterpriseContact;

const sizeCalc = (pxSize: number) => (pxSize / 750) * 100;

const StyledEnterpriseContact = styled.main<{ page: boolean }>`
  padding-top: 60px;
  padding-bottom: 100px;
  background-color: ${COLOR_DEFINITIONS.BG.MAIN};
  color: ${COLOR_DEFINITIONS.TEXT.MAIN};
  min-height: ${({ page }) => (page ? 'calc(100vh - 548px)' : 'none')};
  ${mediaQuery.lessThan('minTablet')`
    padding-top: ${sizeCalc(60)}vw;;
    padding-bottom: ${sizeCalc(100)}vw;;
  `}
`;

const StyledEnterpriseContactTitleWrapper = styled.div`
  text-align: center;
`;

const StyledEnterpriseContactTitle = styled.h1`
  font-weight: bold;
  font-size: 36px;

  ${mediaQuery.lessThan('breakPoint')`
    font-size: ${sizeCalc(48)}vw;
  `}
`;

const StyledEnterpriseContactDesc = styled(StyledEnterpriseContactTitleWrapper)`
  margin-top: 40px;
  font-size: 16px;
  ${mediaQuery.lessThan('breakPoint')`
    margin-top: ${sizeCalc(60)}vw;
    font-size: ${sizeCalc(28)}vw;
  `}
`;

const StyledEnterpriseContactTitleEn = styled(StyledEnterpriseContactTitle)`
  font-family: ${FONT_FAMILY.ENG};
  font-size: 14px;
  ${mediaQuery.lessThan('breakPoint')`
    font-size: ${sizeCalc(20)}vw;
  `}
`;

const StyledEnterpriseContactTitleJp = styled(StyledEnterpriseContactTitle)`
  margin-top: ${sizeCalc(20)}vw;
  ${mediaQuery.lessThan('breakPoint')`
    margin-top: ${sizeCalc(16)}vw;
  `}
`;

const StyledForm = styled.form`
  max-width: 750px;
  margin: 40px auto 0;
  padding: 38px 40px;
  box-sizing: border-box;
  background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  font-size: 14px;

  ${mediaQuery.lessThan('breakPoint')`
    margin-top: ${sizeCalc(60)}vw;
    padding: ${sizeCalc(40)}vw;
    font-size: ${sizeCalc(32)}vw;
  `}
`;

const StyledFormSended = styled(StyledForm)`
  text-align: center;
  font-size: 16px;
  line-height: 1.75;
  ${mediaQuery.lessThan('breakPoint')`
    font-size: 28px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    font-size: 14px;
  `}
`;

const StyledItemWrapper = styled.div`
  margin: 0 auto;

  ${mediaQuery.lessThan('breakPoint')`
    width: 100%;
    max-width: 670px;
  `}

  & + & {
    margin-top: 26px;
    ${mediaQuery.lessThan('breakPoint')`
      margin-top: ${sizeCalc(60)}vw;
    `}
  }
`;

const StyledInputWrapper = styled.div<{ multiple?: boolean }>`
  ${({ multiple }) =>
    multiple
      ? `
    display: flex;
    justify-content: space-between;
  `
      : null}
`;

const StyledInputLabel = styled.label<{ multiple?: boolean }>`
  ${({ multiple }) =>
    multiple
      ? `
    width: calc(50% - 4px);
    & + label {
      margin-left: 8px;
    `
      : null}
`;

const StyledItemName = styled.span<{ error?: boolean; required: boolean }>`
  font-weight: bold;

  ${({ error }) => (error ? `font-weight: normal;` : null)}

  ${({ required }) =>
    required
      ? `
    &::after {
      content: '必須';
      display: inline-block;
      padding: 0 5px;
      margin-left: 8px;
      font-size: 10px;
      line-height: 16px;
      font-weight: normal;
      border-radius: 30px;
      vertical-align: middle;
      color: ${COLOR_DEFINITIONS.TEXT.ALERT};
      background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
    }
  `
      : null}
  ${mediaQuery.lessThan('breakPoint')`
    &::after {
      font-size: ${sizeCalc(20)}vw;
      line-height: 1.4;
      padding: 0 ${sizeCalc(10)}vw;
    }
  `}
`;

const StyledFormItem = styled.input<{ already?: boolean; error?: string }>`
  box-sizing: border-box;
  padding: 0 14px;
  width: 100%;
  height: 48px;
  font-family: ${FONT_FAMILY.COMMON};
  font-size: 16px;
  background-color: ${COLOR_DEFINITIONS.BG.SUB};
  outline: none;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  border-radius: 4px;
  ${mediaQuery.lessThan('breakPoint')`
    padding: 0 ${sizeCalc(16)}vw;
    height: ${sizeCalc(80)}vw;
    font-size: ${sizeCalc(32)}vw;
  `}
  ${({ already }) =>
    already
      ? `
        color: ${COLOR_DEFINITIONS.TEXT.MAIN};
        background-color: ${COLOR_DEFINITIONS.BG.WHITE};
      `
      : null}
  ${({ error }) =>
    !error || error === '' || error === 'none'
      ? ``
      : `
      border: 1px solid ${COLOR_DEFINITIONS.TEXT.ALERT};
      background: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.ALERT};
    `}
`;

const StyledErrorMessage = styled.span<{ error?: string }>`
  ${({ error }) =>
    !error || error === '' || error === 'none'
      ? `
      display: inline-block;
      margin-top: ${sizeCalc(10)}vw;
      font-size: 12px;
      color: ${COLOR_DEFINITIONS.TEXT.ALERT};
      `
      : ``}
  ${mediaQuery.lessThan('breakPoint')`
    font-size: ${sizeCalc(20)}vw;
  `}
`;

const StyledErrorMessageCheckbox = styled(StyledErrorMessage)`
  margin-top: 8px;
`;

const StyledFormInput = styled(StyledFormItem)`
  margin-top: 10px;

  &:focus {
    border: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  }

  ${mediaQuery.lessThan('breakPoint')`
    margin-top: ${sizeCalc(16)}vw;
  `}
`;

const StyledSelectBox = StyledFormItem.withComponent('select');
const StyledFormSelectBox = styled(StyledSelectBox)`
  appearance: none;

  ${({ already }) => (!already ? `color: ${COLOR_DEFINITIONS.TEXT.SECONDARY};` : null)}
`;
const StyledSelectBoxWrapper = styled.div`
  margin-top: 10px;
  position: relative;
  cursor: pointer;
  ${mediaQuery.lessThan('breakPoint')`
    margin-top: 16px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    margin-top: 8px;
  `}
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    width: 0;
    height: 0;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    border-top: 10px solid ${COLOR_DEFINITIONS.LINE.SELECTBOX};
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
    pointer-events: none;
    ${mediaQuery.lessThan('breakPoint')`
      border-top: 16px solid ${COLOR_DEFINITIONS.LINE.SELECTBOX};
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    `}
    ${mediaQuery.lessThan('minTablet')`
      border-top: 8px solid ${COLOR_DEFINITIONS.LINE.SELECTBOX};
      border-right: 4px solid transparent;
      border-left: 4px solid transparent;
      right: 8px;
    `}
  }
`;

const StyledTextArea = StyledFormItem.withComponent('textarea');
const StyledFormTextArea = styled(StyledTextArea)`
  margin-top: 10px;
  height: 144px;
  padding-top: 15px;
  line-height: 1.4;
  resize: none;

  &:focus {
    border: solid 2px ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    background-color: ${COLOR_DEFINITIONS.BG.WHITE};
  }
  ${mediaQuery.lessThan('breakPoint')`
    margin-top: 16px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    margin-top: 8px;
  `}
`;

const StyledCheckbox = styled.div`
  margin-top: 36px;
  text-align: center;
  ${mediaQuery.lessThan('breakPoint')`
      margin-top: ${sizeCalc(80)}vw;
  `}
`;

const StyledCheckboxEx = styled(StyledCheckbox)`
  text-align: left;
  margin-top: 10px;
  margin-left: 28px;
  ${mediaQuery.lessThan('breakPoint')`
    margin-top: 16px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    margin-top: 8px;
    margin-left: 14px;
  `}
`;

const StyledCheckboxItem = styled.span`
  margin-left: 8px;
  font-weight: normal;
`;

const StyledCheckboxInput = styled.input`
  display: none;

  &[type='checkbox']:checked {
    & + label::after {
      content: '';
      position: absolute;
      display: block;
      top: calc(50% - 5px);
      right: calc(100% + 3px);
      width: 10px;
      height: 4px;
      border-bottom: 3px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
      border-left: 3px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
      transform: rotate(-45deg);

      ${mediaQuery.lessThan('breakPoint')`
        right: calc(100% + 7px);
        border-bottom: 4px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        border-left: 4px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
      `}
      ${mediaQuery.lessThan('minTablet')`
        width: 6px;
        height: 2px;
        border-bottom: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        border-left: 2px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
        right: calc(100% + 3px);
        top: calc(50% - 3px);
      `}
    }
  }
`;

const StyledCheckboxLabel = styled.label`
  position: relative;

  &::before {
    content: '';
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 1px ${COLOR_DEFINITIONS.BG.TRANSLUCENT.BLACK};
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translateY(-50%);

    ${mediaQuery.lessThan('breakPoint')`
      width: 28px;
      height: 28px;
      border-width: 2px;
    `}
    ${mediaQuery.lessThan('minTablet')`
      width: 14px;
      height: 14px;
      border-width: 1px;
    `}
  }
`;

const StyledCheckboxLabelDesc = styled.span`
  font-size: 16px;
  ${mediaQuery.lessThan('breakPoint')`
    font-size: 28px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    font-size: 14px;
  `}
`;

const StyledCheckboxLabelLink = styled.span`
  display: inline-block;
  margin-left: 8px;
  cursor: pointer;
  text-decoration: underline;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};

  ${mediaQuery.lessThan('breakPoint')`
    margin-left: 20px;
  `}
  ${mediaQuery.lessThan('minTablet')`
    margin-left: 10px;
  `}
`;

const StyledButtonWrapper = styled.div`
  width: 300px;
  margin: 30px auto 0;

  ${mediaQuery.lessThan('breakPoint')`
    width: 100%;
    max-width: 670px;
    font-size: ${sizeCalc(28)}vw;
    margin-top: ${sizeCalc(60)}vw;
  `}
`;
