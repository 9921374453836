import React from 'react';
import SelectBox from '@meettry/engineer/components/atoms/SelectBox';
import { JOB_SEEKING_LEVEL_LIST } from '@meettry/ui-components/constants';

export const InputJobSeekingLevel = (props) => {
  const { userDetail, handleChangeUserDetail, size = '' } = props;
  return (
    <SelectBox
      onChange={handleChangeUserDetail('jobSeekingLevel')}
      selectedValue={userDetail.jobSeekingLevel}
      placeholder="選択"
      values={JOB_SEEKING_LEVEL_LIST}
      size={size}
    />
  );
};
