import React, { useEffect, useState } from 'react';
import { PageCommonProps } from '@meettry/company/route/route';
import { Button } from '@meettry/company/component/atoms/btn';
import { SignWrapper } from '@meettry/company/component/organisms/signWrapper';
import { Modal } from '@meettry/company/component/organisms/modal';
import * as Validation from '@meettry/company/utils/validation';
import * as FindError from '@meettry/company/utils/findError';
import auth from '@meettry/ui-components/utils/firebase';
import { FORGOT_PASS, SIGN } from '@meettry/ui-components/constants/error';

type SendStateType = {
  haveAccount: null | boolean;
  isSendMail: boolean;
  isSendCompleted: boolean;
};

const ForgotPass: React.FC<PageCommonProps> = () => {
  const [btnDisabled, changeDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [sendState, setSendState] = useState<SendStateType>({
    haveAccount: null,
    isSendMail: false,
    isSendCompleted: false
  });
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const emailErrorFind = FindError.findError(!email ? FORGOT_PASS : SIGN, emailError);
  const showEmailError = !!emailErrorFind ? <span>{emailErrorFind}</span> : null;

  const sendLinkMail = () => {
    setSendState({ ...sendState, isSendMail: true });
  };

  useEffect(() => {
    if (!sendState.isSendMail) return;
    auth
      .sendPasswordResetEmail(email)
      .then(() => {
        setSendState({ ...sendState, haveAccount: true, isSendCompleted: true });
      })
      .catch(() => {
        setSendState({ ...sendState, haveAccount: false, isSendCompleted: true });
      });
  }, [sendState.isSendMail]);

  useEffect(() => {
    if (!sendState.isSendMail || !sendState.isSendCompleted) return;
    setShowModal(true);
  }, [sendState.isSendCompleted]);

  useEffect(() => {
    if (!showModal) return;
    setSendState({ ...sendState, isSendMail: false, isSendCompleted: false });
  }, [showModal]);

  return (
    <SignWrapper cls="forgot-pass" title="パスワードをお忘れの方へ">
      <p>パスワードをリセットするには、メール アドレスを入力してください。</p>
      <p>
        メールが届かない場合、迷惑メールのフォルダを確認するか、noreply@meettry.com
        のブロックを解除する必要がある場合があります。
      </p>
      <div>
        <label htmlFor="email">メールアドレス</label>
        <input
          placeholder="メールアドレス"
          type="text"
          id="email"
          onChange={(e) => {
            changeDisabled(e.target.value === '' ? true : false);
            setEmail(e.target.value);
          }}
          onBlur={() => Validation.login(email, setEmailError, 'email')}
        />
        {showEmailError}
      </div>
      <Modal
        show={showModal}
        displayModal={() => {
          setShowModal(false);
        }}
        cls="forgot-pass-modal"
      >
        <p>
          {sendState.haveAccount
            ? 'パスワード再設定用のリンクをメールに送信しました。'
            : '入力いただいたメールアドレスに一致するアカウントが存在しません。'}
        </p>
        <Button
          buttonText="OK"
          cls="btn primary middle"
          onClick={() => {
            setShowModal(false);
          }}
        />
      </Modal>
      <Button
        buttonText="送信する"
        cls="btn primary large"
        disabled={btnDisabled || sendState.isSendMail}
        onClick={() => {
          sendLinkMail();
        }}
      />
    </SignWrapper>
  );
};

export default ForgotPass;
