import React, { useState, useRef, useEffect } from 'react';
import styled, { css } from 'styled-components';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ChallengeType } from '@meettry/ui-components/types/userPage';
import Icon from '@meettry/ui-components/components/atoms/Icon';
import { BasicTag } from '@meettry/ui-components/components/atoms/Tag';
import EditMenu from '@meettry/ui-components/components/organisms/UserPage/Common/EditMenu';
import ChallengeItemLog from '@meettry/ui-components/components/organisms/UserPage/Challenge/ChallengeItemLog';
import HighlightedText from '@meettry/ui-components/components/atoms/HighlightedText';
import icons from '@meettry/ui-components/images/icons';
import { COLOR_DEFINITIONS } from '@meettry/ui-components/styles/color';

const MAX_HEIGHT = 300;

type ChallengeItemType = {
  onClickTitle?: () => void;
  challenge: ChallengeType;
  isOwner: boolean;
  onDelete: (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  onPinned: (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  onVisible: (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  onEdit: (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  onHistoryEdit: (challenge: ChallengeType) => (e: React.MouseEvent<HTMLLIElement>) => void;
  isOverview?: boolean;
  searchWord?: string;
};

const ChallengeItem: React.FC<ChallengeItemType> = (props) => {
  const {
    onClickTitle,
    challenge,
    isOwner,
    onDelete,
    onPinned,
    onVisible,
    onEdit,
    onHistoryEdit,
    isOverview = false,
    searchWord = ''
  } = props;
  const historyListRef = useRef<HTMLUListElement>(null);
  const [showTransparentBox, setShowTransparentBox] = useState(false);
  const { isPinned, isVisible } = challenge;

  useEffect(() => {
    if (historyListRef.current) {
      if (MAX_HEIGHT <= historyListRef.current.offsetHeight) {
        setShowTransparentBox(true);
      }
    }
  }, [challenge]);

  const handleScroll = (event: any) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;
    const isBottom = scrollHeight - scrollTop <= clientHeight;
    setShowTransparentBox(!isBottom);
  };

  const onClickTitleHandle = () => {
    typeof onClickTitle === 'function' && onClickTitle();
  };

  const menuList = (challenge: ChallengeType) => {
    const ICON_SIZE = '18px';
    const ICON_MARGIN_RIGHT = '5px';
    const { isPinned, isVisible } = challenge;

    return [
      onHistoryEdit
        ? {
            Icon: (
              <Icon
                iconName="update"
                iconColor="gray"
                width={ICON_SIZE}
                marginRight={ICON_MARGIN_RIGHT}
              />
            ),
            text: '進捗の更新',
            onClick: onHistoryEdit(challenge)
          }
        : {},
      onEdit
        ? {
            Icon: (
              <Icon
                iconName="edit"
                iconColor="gray"
                width={ICON_SIZE}
                marginRight={ICON_MARGIN_RIGHT}
              />
            ),
            text: '編集',
            onClick: onEdit(challenge)
          }
        : {},
      onVisible
        ? {
            Icon: (
              <Icon
                iconName={isVisible ? 'hidden' : 'view'}
                iconColor="gray"
                width={ICON_SIZE}
                marginRight={ICON_MARGIN_RIGHT}
              />
            ),
            text: isVisible ? '非公開にする' : '公開する',
            onClick: onVisible(challenge)
          }
        : {},
      isVisible
        ? {
            Icon: (
              <Icon
                iconName="pin"
                iconColor="gray"
                width={ICON_SIZE}
                marginRight={ICON_MARGIN_RIGHT}
              />
            ),
            text: isPinned ? 'ピン留め解除' : 'ピン留めする',
            onClick: onPinned(challenge)
          }
        : {},
      {
        Icon: (
          <Icon
            iconName="delete"
            iconColor="gray"
            width={ICON_SIZE}
            marginRight={ICON_MARGIN_RIGHT}
          />
        ),
        text: '削除',
        onClick: onDelete(challenge)
      }
    ];
  };

  return (
    <StyledItemWrapper>
      {!isVisible ? <StyledVisibleStatus /> : null}
      {isPinned ? <StyledPinnedStatus /> : null}
      <StyledEditButtonContainer>
        {isOwner && (
          <EditMenu
            list={menuList(challenge)}
            toggleIcon={<Icon iconName="editing_btn" iconColor="gray" width={'15px'} />}
          />
        )}
      </StyledEditButtonContainer>
      <StyledItemBox isVisible={isVisible}>
        <StyledDetailWrapper>
          <StyledDetailContainer>
            <StyledItemHeader
              isClickable={typeof onClickTitle === 'function'}
              onClick={onClickTitleHandle}
            >
              <HighlightedText keyword={searchWord}>{challenge.title}</HighlightedText>
            </StyledItemHeader>
            <div>
              {!!challenge.tags.length && (
                <StyledTagListWrapper>
                  <StyledTagListContainer>
                    {challenge.tags.map(({ id, displayName }) => (
                      <BasicTag key={id}>
                        <HighlightedText keyword={searchWord}>{displayName}</HighlightedText>
                      </BasicTag>
                    ))}
                  </StyledTagListContainer>
                </StyledTagListWrapper>
              )}
              {!!challenge.challengeDetail && (
                <StyledItemRow>
                  <HighlightedText keyword={searchWord}>
                    {challenge.challengeDetail}
                  </HighlightedText>
                </StyledItemRow>
              )}
            </div>
          </StyledDetailContainer>
          <StyledProgressContainer>
            <StyledBackProgressMeter variant="determinate" size={110} thickness={4.2} value={100} />
            <StyledTopProgressMeter
              variant="determinate"
              size={110}
              thickness={4.2}
              value={challenge.progressRate}
            />
            <StyledProgressMeterTextBox>
              <StyledProgressMeterAchievementText>達成率</StyledProgressMeterAchievementText>
              <br />
              <StyledProgressMeterRateText>{challenge.progressRate}</StyledProgressMeterRateText>%
            </StyledProgressMeterTextBox>
          </StyledProgressContainer>
        </StyledDetailWrapper>
        {!isOverview && challenge.challengeLogList && challenge.challengeLogList.length > 0 && (
          <StyledHistoryWrapper>
            <StyledUpdatedTitle>{`${
              challenge.challengeLogList.length + 1
            } Updated or Activity`}</StyledUpdatedTitle>
            <StyledHistoryContainer onScroll={handleScroll}>
              {showTransparentBox && <StyledTransparentBox />}
              <ChallengeItemLog
                item={challenge.challengeLogList}
                historyListRef={historyListRef}
                created={challenge.created}
              />
            </StyledHistoryContainer>
          </StyledHistoryWrapper>
        )}
      </StyledItemBox>
    </StyledItemWrapper>
  );
};

export default ChallengeItem;

const StyledVisibleStatus = styled.div`
  z-index: 100;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: 0;
  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 20%;
    top: 20%;
    transform: translate(-20%, -20%);
    width: 16px;
    height: 16px;
    background-image: url(${icons.gray.hidden});
    background-position: center;
    background-size: cover;
  }
`;

const StyledPinnedStatus = styled.div`
  z-index: 100;
  position: absolute;
  width: 44px;
  height: 44px;
  top: 0;
  left: 0;
  ::before {
    content: '';
    display: block;
    width: 0;
    height: 0;
    border-top: 22px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
    border-right: 22px solid transparent;
    border-bottom: 22px solid transparent;
    border-left: 22px solid ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
  ::after {
    content: '';
    display: block;
    position: absolute;
    left: 20%;
    top: 20%;
    transform: translate(-20%, -20%);
    width: 16px;
    height: 16px;
    background-image: url(${icons.white.pin});
    background-position: center;
    background-size: cover;
  }
`;

const StyledItemHeader = styled.p<{ isClickable: boolean }>`
  font-size: 18px;
  font-weight: bold;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  word-break: break-all;
  line-height: 1.2;
  ${({ isClickable }) =>
    isClickable
      ? css`
          cursor: pointer;
        `
      : ''}
`;

const StyledEditButtonContainer = styled.div`
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 100;
  transition: opacity ease 0.2s;
`;

const StyledItemWrapper = styled.div`
  position: relative;
  :not(:first-child) {
    margin-top: 20px;
  }
  &:hover ${StyledEditButtonContainer} {
    opacity: 1;
  }
`;

const StyledDetailWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: end;
`;
const StyledDetailContainer = styled.div`
  width: 100%;
  max-width: 80%;
`;

const StyledItemBox = styled.div<{ isVisible: number }>`
  box-sizing: border-box;
  display: flex;
  padding: 30px;
  flex-direction: column;
  border: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};

  ${({ isVisible }) =>
    !isVisible &&
    css`
      ::before {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: ${COLOR_DEFINITIONS.BG.TRANSLUCENT.SUB};
        z-index: 50;
        top: 0;
        left: 0;
        pointer-events: none;
      }
    `}
`;

const StyledProgressContainer = styled.div`
  position: relative;
  min-height: 110px;
  width: 100%;
  max-width: 110px;
  color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  flex: 1;
`;

const StyledTopProgressMeter = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    color: ${COLOR_DEFINITIONS.MAIN.PRIMARY};
  }
`;
const StyledBackProgressMeter = styled(CircularProgress)`
  && {
    position: absolute;
    top: 0;
    left: 0;
    color: ${COLOR_DEFINITIONS.BG.SUB};
  }
`;

const StyledProgressMeterTextBox = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: auto;
  width: 100%;
  text-align: center;
`;

const StyledProgressMeterAchievementText = styled.span`
  font-size: 12px;
  font-weight: bold;
`;

const StyledProgressMeterRateText = styled.span`
  font-size: 32px;
  font-weight: bold;
`;

const StyledUpdatedTitle = styled.h3`
  color: ${COLOR_DEFINITIONS.MAIN.SECONDARY};
  font-weight: bold;
  padding: 5px 0;
`;

const StyledItemRow = styled.p`
  word-break: break-all;
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  line-clamp: 3;
  text-overflow: ellipsis;
  font-size: 14px;
  margin-top: 10px;
  line-height: 1.7;
`;

const StyledHistoryWrapper = styled.div`
  position: relative;
  border-top: 1px solid ${COLOR_DEFINITIONS.LINE.SECONDARY};
  margin-top: 30px;
  padding-top: 25px;
`;

const StyledHistoryContainer = styled.div`
  max-height: ${`${MAX_HEIGHT}px`};
  padding: 0 40px;
  overflow-y: scroll;
`;
const StyledTransparentBox = styled.div`
  position: absolute;
  width: 100%;
  max-width: 740px;
  min-height: 80px;
  left: 0;
  bottom: 0;
  z-index: 10;
  background: linear-gradient(
    to bottom,
    ${COLOR_DEFINITIONS.BG.TRANSLUCENT.TRANSPARENT},
    ${COLOR_DEFINITIONS.BG.WHITE} 50%
  );
`;

const StyledTagListWrapper = styled.div`
  margin-top: 12px;
`;

const StyledTagListContainer = styled.div`
  margin-left: -5px;
  margin-top: -5px;
  > * {
    margin-top: 5px;
    margin-left: 5px;
  }
`;
