import React from 'react';
import { useLocation } from 'react-router-dom';
import { ROUTE } from '@meettry/company/route/route';
import { Breadcrumb } from '@meettry/company/component/molecules/breadcrumb';
import { MarkdownArea } from '@meettry/ui-components/utils/loadMarkdown';

type TermsLayoutProps = {
  file: string;
};

const TermsLayoutTitle: React.FC = () => {
  const location = useLocation();
  const locationPath = location.pathname;
  const title = ROUTE.find((item) => item.path === locationPath);
  return title ? <h1>{title.name}</h1> : null;
};

export const TermsLayout: React.FC<TermsLayoutProps> = (props) => {
  return (
    <main className="terms-layout-wrapper">
      <Breadcrumb />
      <TermsLayoutTitle />
      <div className="content-wrap">
        <MarkdownArea markdownFile={props.file} />
      </div>
    </main>
  );
};
