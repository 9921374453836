import React from 'react';
import styled from 'styled-components';
import NotFound from '@meettry/engineer/components/organisms/NotFound';

const NotFoundPage = (props) => {
  return (
    <StyledNotFoundWrapper>
      <NotFound />
    </StyledNotFoundWrapper>
  );
};

export default NotFoundPage;

const StyledNotFoundWrapper = styled.div`
  && {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1000px;
    margin: 0 auto;
  }
`;
